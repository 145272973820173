import {
  CLEAR_MODAL_CANCEL,
  FETCH_MODAL_AUTH_PROD,
  FETCH_MODAL_BALCONY,
  FETCH_MODAL_BKNBANK,
  FETCH_MODAL_CANCEL,
  FETCH_MODAL_CONFIRM,
  FETCH_MODAL_CONFIRM_FILE_UPLOAD,
  FETCH_MODAL_CONTEST,
  FETCH_MODAL_DELIVERY_STEPS,
  FETCH_MODAL_EDITOR,
  FETCH_MODAL_FILES_AUTH,
  FETCH_MODAL_FUTURE_RELEASES,
  FETCH_MODAL_LIST_ADDRESS,
  FETCH_MODAL_LOGIN,
  FETCH_MODAL_LOGIN_RECOVERY,
  FETCH_MODAL_MESSAGE,
  FETCH_MODAL_MESSAGE_FEEDBACK,
  FETCH_MODAL_ORDER_ERRORS,
  FETCH_MODAL_PAYMENT,
  FETCH_MODAL_PIX,
  FETCH_MODAL_PRIVACY,
  FETCH_MODAL_REFILE,
  FETCH_MODAL_REGISTER,
  FETCH_MODAL_REGISTER_ADDRESS,
  FETCH_MODAL_REOPEN_MESSAGE,
  FETCH_MODAL_RESEND_FILES,
  FETCH_MODAL_SHIPPING_CONTACT,
  FETCH_MODAL_TERMS_OF_USE,
  FETCH_MODAL_WITHDRAWAL,
} from '../actions/types';

const INITIAL_STATE = {
  modalListAddress: false,
  modalLogin: false,
  modalMessage: false,
  modalPayment: false,
  modalRegister: false,
  modalTermsOfUse: false,
  modalResendFiles: false,
  modalRegisterAddress: {
    open: false,
    callback: null,
  },
  modalLoginRecovery: false,
  modalWithdrawal: false,
  modalFilesAuth: false,
  modalBknbank: {
    open: false,
    title: '',
    text: '',
    payload: null,
  },
  modalPix: {
    open: false,
    title: '',
    text: '',
    payload: null,
    link: '',
  },
  modalConfirm: {
    open: false,
    title: '',
    text: '',
    payload: null,
  },
  modalConfirmFileUpload: {
    open: false,
    title: '',
    text: '',
    payload: null,
  },
  modalCancel: {
    open: false,
    data: {
      pk_pedido: '',
      title: '',
      pk_pedido_item: '',
    },
  },
  modalAuthProd: {
    open: false,
    data: {
      pk_pedido: '',
      title: '',
      pk_pedido_item: '',
    },
  },
  modalOrderErrors: {
    open: false,
    data: '',
  },
  modalReopenMessage: { open: false, data: '' },
  modalMessageFeedback: { open: false, data: '' },
  modalRefile: { open: false, data: '' },
  modalEditor: false,
  modalContest: {
    open: false,
    title: '',
    pedido: '',
    inputs: [0],
    changed: '',
    pedido_item: '',
  },
  modalFutureReleases: false,
  modalBalcony: {
    open: false,
    data: {},
  },
  modalDeliverySteps: {
    open: false,
    title: '',
    text: '',
    payload: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_MODAL_LIST_ADDRESS:
      return { ...state, modalListAddress: !state.modalListAddress };
    case FETCH_MODAL_PRIVACY:
      return { ...state, modalPrivacy: !state.modalPrivacy };
    case FETCH_MODAL_LOGIN:
      return { ...state, modalLogin: !state.modalLogin };
    case FETCH_MODAL_MESSAGE:
      return { ...state, modalMessage: !state.modalMessage };
    case FETCH_MODAL_PAYMENT:
      return { ...state, modalPayment: !state.modalPayment };
    case FETCH_MODAL_REGISTER:
      return { ...state, modalRegister: !state.modalRegister };
    case FETCH_MODAL_REGISTER_ADDRESS:
      return {
        ...state,
        modalRegisterAddress: {
          open: !state.modalRegisterAddress.open,
          callback: action.payload,
        },
      };
    case FETCH_MODAL_LOGIN_RECOVERY:
      return { ...state, modalLoginRecovery: !state.modalLoginRecovery };
    case FETCH_MODAL_WITHDRAWAL:
      return { ...state, modalWithdrawal: !state.modalWithdrawal };
    case FETCH_MODAL_CONFIRM:
      const data = { ...action.payload, open: !state.modalConfirm.open };
      return { ...state, modalConfirm: { ...data } };
    case FETCH_MODAL_CONFIRM_FILE_UPLOAD:
      const response = {
        ...action.payload,
        open: !state.modalConfirmFileUpload.open,
      };
      return { ...state, modalConfirmFileUpload: { ...response } };
    case FETCH_MODAL_RESEND_FILES:
      return { ...state, modalResendFiles: !state.modalResendFiles };

    case FETCH_MODAL_BKNBANK:
      return {
        ...state,
        modalBknbank: {
          open: !state.modalBknbank.open,
          data: action.payload,
        },
      };
    case FETCH_MODAL_PIX:
      return {
        ...state,
        modalPix: {
          open: !state.modalPix.open,
          data: action.payload,
        },
      };
    case FETCH_MODAL_SHIPPING_CONTACT:
      return { ...state, modalShippingContact: !state.modalShippingContact };
    case FETCH_MODAL_FILES_AUTH:
      return { ...state, modalFilesAuth: !state.modalFilesAuth };
    case FETCH_MODAL_CANCEL:
      return {
        ...state,
        modalCancel: {
          open: !state.modalCancel.open,
          data: action.payload,
        },
      };
    case FETCH_MODAL_AUTH_PROD:
      return {
        ...state,
        modalAuthProd: {
          open: !state.modalAuthProd.open,
          data: action.payload,
        },
      };
    case FETCH_MODAL_ORDER_ERRORS:
      return {
        ...state,
        modalOrderErrors: {
          open: !state.modalOrderErrors.open,
          data: action.payload,
        },
      };
    case FETCH_MODAL_REOPEN_MESSAGE:
      return {
        ...state,
        modalReopenMessage: {
          open: !state.modalReopenMessage.open,
          data: action.payload,
        },
      };
    case FETCH_MODAL_MESSAGE_FEEDBACK:
      return {
        ...state,
        modalMessageFeedback: {
          open: !state.modalMessageFeedback.open,
          data: action.payload,
        },
      };
    case FETCH_MODAL_REFILE:
      return {
        ...state,
        modalRefile: {
          open: !state.modalRefile.open,
          data: action.payload,
        },
      };
    case FETCH_MODAL_EDITOR:
      return { ...state, modalEditor: !state.modalEditor };
    case FETCH_MODAL_CONTEST:
      const modalContest = {
        ...action.payload,
        open: !state.modalContest.open,
      };

      return { ...state, modalContest };

    case FETCH_MODAL_FUTURE_RELEASES:
      return { ...state, modalFutureReleases: !state.modalFutureReleases };
    case CLEAR_MODAL_CANCEL:
      return {
        ...state,
        modalCancel: {
          data: {},
        },
      };
    case FETCH_MODAL_BALCONY:
      return {
        ...state,
        modalBalcony: {
          open: !state.modalBalcony.open,
          data: action.payload,
        },
      };
    case FETCH_MODAL_TERMS_OF_USE:
      return { ...state, modalTermsOfUse: !state.modalTermsOfUse };
    case FETCH_MODAL_DELIVERY_STEPS:
      return {
        ...state,
        modalDeliverySteps: {
          ...state.modalDeliverySteps,
          callback: action.payload,
          open: !state.modalDeliverySteps.open,
        },
      };
    default:
      return state;
  }
};
