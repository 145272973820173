import styled from 'styled-components';

// Style
import {
  StyleForm,
  Wrapper,
  BtnGroup,
  Button,
  Label,
} from '../../../styles/forms';
import { colors } from '../../../styles/variables';

/**
 * Formata o Container do Filtro de Creditos
 *
 * @extends {StyleForm}
 *
 * Herda estilo para formatar o interior do container
 * @extends {Wrapper}
 * @extends {Label}
 * @extends {BtnGroup}
 */

export const StyleFilter = styled(StyleForm)`
  padding: 1rem;

  h2 {
    padding: 0;
  }

  ${Wrapper} {
    ${Label} {
      padding-left: 0;
      color: ${colors.primary};
      font-weight: 700;
    }
  }

  ${BtnGroup} {
    justify-content: center;
    padding: 1rem 0;
  }
`;

/**
 * Formata o container do Botão de lançamentos futuros
 * @extends {BtnGroup}
 *
 * Herda estilo para formatar o interior do container
 * @extends {Button}
 */

export const FeatureReleases = styled(BtnGroup)`
  ${Button} {
    min-width: fit-content;
  }
`;
