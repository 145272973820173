import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

// styles
import { ContainerCard, Parceria, LinkUseCupom } from './style';

// actions
import { modalLogin } from '../../../actions/modals';

// utils
import { SUCCESS } from '../../../utils/constMessage';

let Card = props => {
  const handleUseCupom = () => {
    toast.success(SUCCESS);
    props.history.push('/');
    props.modalLogin();
    return false;
  };

  return (
    <>
      <ContainerCard>
        <Parceria>
          <h2>Agradecemos a parceria!</h2>
          <p>
            Ao escolher seus itens, é só colar este cupom no espaço dedicado no
            carrinho de compras. E
          </p>
          <p>
            não se preocupe, enviaremos também no seu e-mail para não perder o
            desconto.
          </p>
          <p>
            Esse é o começo de uma história de qualidade. O final a gente já
            sabe: Muitas vendas!
          </p>
          <LinkUseCupom title="Usar agora seu cupom de desconto!">
            <button onClick={() => handleUseCupom()} type="button">
              {'Usar agora seu cupom de desconto!'}
            </button>
          </LinkUseCupom>
        </Parceria>
      </ContainerCard>
    </>
  );
};

Card = connect(null, {
  modalLogin,
})(Card);

export default withRouter(Card);
