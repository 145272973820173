import styled from 'styled-components';

// Style
import { Container } from '../style';
import { StyleBalcony } from '../../forms/Balcony/style';
import { colors } from '../../styles/variables';

export const ModalBalcony = styled(Container)`
  ${StyleBalcony} {
    min-width: 100%;
    max-width: 100%;
    border: none;
    border-bottom: 1px solid ${colors.grey.lightSecondary};
  }
`;
