import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, reduxForm, reset } from 'redux-form';
import config from '../../../config';
import {
  fetchFilterOnChange,
  activeRequest,
  clearSimulateDelivery,
} from '../../../actions/filter';

// node-modules
import _ from 'lodash';

// Components
import BookSize from '../../../components/Filter/FilterBook/FilterBookSize';
import BookOptions from '../../../components//Filter/FilterBook/FilterBookOptions';
import BookQuantity from '../../../components//Filter/FilterBook/FilterBookQuantity';
import BookContent from '../../../components//Filter/FilterBook/FilterBookContent';
import BookFinishes from "../../../components/Filter/FilterBook/FilterBookFinishes";
import BookServices from '../../../components//Filter/FilterBook/FilterBookServices';
import FilterBookMandatory from '../../../components/Filter/FilterBook/FilterBookMandatory';
import Loader from '../../../components/Loader';

//Style
import { Row } from '../../../styles/align';
import { ContainerCardBook } from './style';
import { TitleCard } from '../style';
import { CardFilter } from '../style';

import {
  FilterContainer,
  Loading,
} from '../style';

// Utils
import { objValuesToString } from '../../../utils';
import {
  getFinishesSelected,
  finishesBlocked,
  checkAdditional,
  checkAdditionalService,
  getServiceSelected,
  getMenuAmigavel,
} from '../../../services/filter';

// Validate
// import renderField from '../../../formValidate/renderField';

class FilterBook extends Component {
  constructor(props) {
    super(props);

    const {
      paginas_preto_branco,
      paginas_coloridas,
    } = props.dataFilter.atributos_selecionados;

    this.state = {
      loading: false,
      quantity: parseInt(paginas_preto_branco) + parseInt(paginas_coloridas),
      acabamentos_opcionais_preco: 0.0,
      acabamentos_opcionais_prazo: 0,
      servicos_adicionais_preco: 0.0,
      servicos_adicionais_prazo: 0,
      finishesBlocked: [],
    };
  }

  updateService = fk_service => {
    let serviceSelected = getServiceSelected(
      this.props.filterProductsOnChange.values,
      fk_service
    );

    const additionalService = checkAdditionalService(
      this.props.dataFilter.servicos,
      serviceSelected
    );

    this.setState({
      servicos_adicionais_preco: additionalService.price,
      servicos_adicionais_prazo: additionalService.deadline,
    });
    const additionalPrice =
      additionalService.price + this.state.acabamentos_opcionais_preco;
    const additionalDeadline =
      additionalService.deadline + this.state.acabamentos_opcionais_prazo;
    this.props.onAdditional(additionalPrice, additionalDeadline);
  };

  updateFinishBlocked = pk_acabamento => {
    // verifica os acabamentos que devem ser bloqueados [inicio]
    let finishesSelected = getFinishesSelected(
      this.props.filterProductsOnChange.values,
      pk_acabamento
    );

    const newFinishesBlocked = finishesBlocked(
      this.props.dataFilter.acabamentos_bloqueados,
      finishesSelected
    );
    // verifica os acabamentos que devem ser bloqueados [fim]

    const additional = checkAdditional(
      this.props.dataFilter.acabamentos_opcionais,
      finishesSelected,
      newFinishesBlocked
    );

    this.setState({
      finishesBlocked: newFinishesBlocked,
      acabamentos_opcionais_preco: additional.price,
      acabamentos_opcionais_prazo: additional.deadline,
    });

    const additionalPrice =
      additional.price + this.state.servicos_adicionais_preco;

    const additionalDeadline =
      additional.deadline + this.state.servicos_adicionais_prazo;

    this.props.onAdditional(additionalPrice, additionalDeadline);
  };

  handleChangeSubmit = (event, newValue, previousValue, name) => {
    this.props.activeRequest(true);
    this.props.clearSimulateDelivery();
    this.setState({
      loading: true,
    });

    let url = undefined;
    let orientation = null;

    this.props.onAdditional(0.0, 0);

    if (this.props.dataFilter['orientacao'].length < 2) {
      orientation = this.props.dataFilter['orientacao'][0]
        .pk_dependencia_atributo;
    }

    if (this.props.dataFilter[name]) {
      // Captura a Key para encontrar o Atributo e definir o menu
      let key = _.findKey(this.props.dataFilter[name].opcoes, {
        pk_dependencia_atributo: parseInt(newValue),
      });

      if (!key) {
        key = _.findKey(this.props.dataFilter[name].opcoes, {
          fk_sub_tipo_produto: parseInt(newValue),
        });
      }

      if (this.props.dataFilter[name].opcoes) {
        url = this.props.dataFilter[name].opcoes[key].url_amigavel;
      }
    }

    // Define o Menu
    const menu = url && url !== this.props.menu ? url : this.props.menu;
    const menu_amigavel = getMenuAmigavel(menu);
    // remove o acabamento opcional do formulario que sera enviado
    // para buscar as novas opções
    const omit = [
      'papel',
      'tipos_papel_miolo',
      'orientacao',
      'paginas_preto_branco',
      'exemplares',
      'paginas_coloridas',
      'opcoes_quantidade',
      'dependencias_adicionais_cores',
      'servicos',
    ];

    const valuesChange = {
      ...this.props.filterProductsOnChange.values,
      [name]: newValue,
    };

    const opcoes_marcadas = _.omit(valuesChange, ...omit);

    let dataSubmit = {
      papel: valuesChange.papel,
      url_amigavel: menu_amigavel,
      orientacao: orientation ? orientation : valuesChange.orientacao,
      dependencias_adicionais_cores: valuesChange.dependencias_adicionais_cores,
      paginas_preto_branco: valuesChange.paginas_preto_branco,
      paginas_coloridas: valuesChange.paginas_coloridas,
      exemplares: valuesChange.exemplares,
      tipos_papel_miolo: valuesChange.tipos_papel_miolo,
      opcoes_quantidade: valuesChange.opcoes_quantidade,
      opcoes_marcadas: {
        ...opcoes_marcadas,
        papel: valuesChange.papel
      },
    };

    if (this.props.dataFilter['dependencias_adicionais_cores'].length > 2) {
      dataSubmit = _.omit(dataSubmit, 'dependencias_adicionais_cores');
    }

    this.props.fetchFilterOnChange(dataSubmit).then(() => {
      this.setState({
        loading: false,
      });
      this.props.activeRequest(false);

      //reset Necessário para atualizar a view caso o state do redux nao seja alterado
      this.props.dispatch(reset('filterProductsOnChange'));
    });
  };

  handleChangeFinishType(item) {
    this.setState({
      acabamento_obrigatorio_preco: item.valor_acabamento,
      acabamento_obrigatorio_prazo: item.qtd_dias_acabamento,
    });

    const additionalPrice =
      this.state.acabamentos_opcionais_preco + item.valor_acabamento;

    const additionalDeadline =
      this.state.acabamentos_opcionais_prazo + item.qtd_dias_acabamento;

    this.props.onAdditional(additionalPrice, additionalDeadline);
    this.props.onFinishSelect(item);
  }

  getTitleFinishingMandatory() {
    let descFinishing = "";
    this.props.dataFilter["acabamentos_obrigatorios"].forEach((value) => {
      if (value.desc_tipo_acabamento !== "" && descFinishing === "") {
        descFinishing = value.desc_tipo_acabamento;
      }
    });

    return descFinishing;
  }

  render() {
    const { handleSubmit } = this.props;

    if (!this.props.dataFilter) {
      return <Loader />;
    }

    let hasQuantityBoneco = parseInt(this.props.dataFilter.atributos_selecionados.opcoes_quantidade) === parseInt(config.TYPE_BONECO) &&
      parseInt(this.props.dataFilter.dados_produto.fk_tipo_produto) === 7;

    let incrementer = 0;
    return (
      <Form onSubmit={handleSubmit}>
        <FilterContainer>
          {this.state.loading ? (
            <Loading>
              <Loader />
            </Loading>
          ) : (
            ''
          )}
          <Row>
            <BookSize
              title={'Tamanho'}
              order={++incrementer}
              data={this.props.dataFilter}
              onChangeSubmit={this.handleChangeSubmit}
            />
            {!hasQuantityBoneco ? (
              <BookOptions
                title={'Opções de Capa'}
                order={++incrementer}
                data={this.props.dataFilter}
                onChangeSubmit={this.handleChangeSubmit}
                updateFinishBlocked={this.updateFinishBlocked}
                finishesBlocked={this.state.finishesBlocked}
              />
            ) : (
              ''
            )}
          </Row>
          <Row>
            <BookQuantity
              title={'Quantidade de páginas do miolo'}
              order={++incrementer}
              data={this.props.dataFilter}
              onChangeSubmit={this.handleChangeSubmit}
              onChangeValues={this.props.change}
              activeRequest={this.props.activeRequest}
            />
            <ContainerCardBook>
              <BookContent
                title={'Opções de Miolo'}
                order={++incrementer}
                data={this.props.dataFilter}
                onChangeSubmit={this.handleChangeSubmit}
              />
              {this.props.dataFilter['servicos'].length > 0 ? (
                <BookServices
                  title={'Serviços Opcionais'}
                  order={++incrementer}
                  data={this.props.dataFilter}
                  onChangeSubmit={this.handleChangeSubmit}
                  updateService={this.updateService}
                />
              ) : (
                ''
              )}
            </ContainerCardBook>
          </Row>
          {<Row>
            {this.props.dataFilter["acabamentos_obrigatorios"].length > 0 ?
              (
                <CardFilter className={'row-padding'}>
                  <TitleCard>
                    <span>{++incrementer}</span>Acabamentos Obrigatórios
                  </TitleCard>
                  <FilterBookMandatory
                    title={this.getTitleFinishingMandatory()}
                    data={this.props.dataFilter}
                    updateFinishBlocked={(item) => {
                      this.handleChangeFinishType.apply(this, [item])
                    }
                    }
                  />
                </CardFilter>
              ) : (
                ""
              )
            }
          </Row>}
          {
            <Row>
              {this.props.dataFilter["acabamentos_opcionais"].length > 0 ? (
                <BookFinishes
                  title={"Acabamentos Opcionais"}
                  order={++incrementer}
                  data={this.props.dataFilter}
                  onChangeSubmit={this.handleChangeSubmit}
                  updateFinishBlocked={(item) => this.updateFinishBlocked(item.pk_acabamento)}
                  finishesBlocked={this.state.finishesBlocked}
                />
              ) : (
                ""
              )}
            </Row>
          }
        </FilterContainer>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  const test = {
    dataFilter: state.filter.filter,
    filterProductsOnChange: (state.form && state.form.filterProductsOnChange)
      ? state.form.filterProductsOnChange
      : () => {}, // Fallback para função vazia se undefined
    initialValues: objValuesToString(
      state.filter.filter.atributos_selecionados || {}
    ),
  };

  const selectedOrelhaID = (state.filter.filter.atributos_selecionados && state.filter.filter.atributos_selecionados.orelhas_disponiveis)
    ? state.filter.filter.atributos_selecionados.orelhas_disponiveis
    : null;

  const hasOrelhasDisponiveis = (state.filter.filter.orelhas_disponiveis && state.filter.filter.orelhas_disponiveis.length > 0)
    ? true
    : false;

  if (hasOrelhasDisponiveis && selectedOrelhaID && !state.filter.filter.orelhas_disponiveis.some(orelha => orelha.pk_dependencia_atributo == selectedOrelhaID)) {
    test.initialValues.orelhas_disponiveis = state.filter.filter.orelhas_disponiveis[0].pk_dependencia_atributo.toString();
  }

  return test;
};




FilterBook = reduxForm({
  form: 'filterProductsOnChange',
  enableReinitialize: true,
  destroyOnUnmount: true,
})(FilterBook);

FilterBook = connect(mapStateToProps, {
  fetchFilterOnChange,
  activeRequest,
  clearSimulateDelivery,
})(FilterBook);

export default FilterBook;
