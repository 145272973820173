import React, { Component } from 'react';
import { connect } from 'react-redux';

// Node
import { toast } from 'react-toastify';

// hocs
import Modal from '../../hocs/Modal';

// actions
import { modalContest } from '../../actions/modals';

// partials
import SendFiles from './partials/SendFiles';
import SendContact from './partials/SendContact';

// services
import { sendContest } from '../../services/order';

// Styles
import { StyleContest } from './style';

class Contest extends Component {
  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.state = {
      page: 1,
      inputs: [0],
      changed: '',
    };
  }

  submit = async value => {
    const { pedido_item, callback, pedido } = this.props;
    let arquivos = [];

    this.state.inputs.forEach(item => {
      arquivos = [...arquivos, value.arquivos[item]];
    });
    value = { ...value, arquivos };

    const data = {
      ...value,
      pedido_item,
    };

    const response = await sendContest(data);

    if (response.status === 200) {
      this.props.modalContest();
      callback(pedido);
      toast.success(response.data.message);
    } else {
      toast.error(response.data.message);
    }
  };

  onUpdateState = value => {
    this.setState({
      ...value,
    });
  };

  nextPage = () => {
    this.setState({ page: this.state.page + 1 });
  };

  previousPage = () => {
    this.setState({ page: this.state.page - 1 });
  };

  render() {
    const { page } = this.state;

    return (
      <StyleContest>
        {page === 1 && (
          <SendFiles
            onClose={() => this.props.modalContest()}
            onSubmit={this.nextPage}
            onUpdateState={this.onUpdateState}
            onState={this.state}
          />
        )}
        {page === 2 && (
          <SendContact
            previousPage={this.previousPage}
            onSubmit={this.submit}
          />
        )}
      </StyleContest>
    );
  }
}

const mapStateToProps = state => {
  return {
    formSendFiles: state.form.formContest,
    pedido_item: state.modals.modalContest.pedido_item,
    callback: state.modals.modalContest.callback,
    pedido: state.modals.modalContest.pedido,
  };
};

Contest = connect(mapStateToProps, {
  modalContest,
})(Contest);

Contest = Modal(Contest);

export default Contest;
