import React from 'react';
import { Field } from 'redux-form';

// hocs
import CardFilter from '../../../../hocs/CardFilter';

// config
import config from '../../../../config';

// validate
import renderField from '../../../../formValidate/renderField';

// utils
import { numberFormat } from '../../../../utils';

// Styles
import { Col } from '../../../../styles/align';
import { Label } from '../../../../styles/forms';
import { FilterMandatory } from './style';

const FilterBookMandatory = ({ data, updateFinishBlocked }) => {
  const { acabamentos_obrigatorios } = data;
  const classFilter = data.dados_produto.fk_tipo_produto === config.TYPE_BOOK ? 'limit__box-50' : '';
  const classFilter180 = data.dados_produto.fk_tipo_produto === config.TYPE_BOOK ? 'limit__box-180' : '';

  return (
    <Col className={classFilter180}>
      {Object.entries(acabamentos_obrigatorios).length > 0
        ? Object.values(acabamentos_obrigatorios).map((item, i) => {
            return (
              <FilterMandatory key={i} className={classFilter}>
                <Field
                    name={'acabamento_obrigatorio'}
                    id={`acabamento_obrigatorio[${i}]`}
                    type={'radio'}
                    typefield={'radio'}
                    component={renderField}
                    value={`${item.pk_acabamento}`}
                    onChange={() => updateFinishBlocked(item)}
                />
                <Label htmlFor={`acabamento_obrigatorio[${i}]`}>
                    <span>{item.desc_acabamento}</span>
                    {item.valor_acabamento > 0 ? (
                    <span>
                        {' '}
                        R$ {numberFormat(item.valor_acabamento, 2)}
                    </span>
                    ) : (
                    <span></span>
                    )}
                </Label>
              </FilterMandatory>
            );
          })
      : ''}
    </Col>
  );
};

export default CardFilter(FilterBookMandatory);
