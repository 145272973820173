import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, Form, reduxForm, SubmissionError } from 'redux-form';
import { toast } from 'react-toastify';

// api
import loja from '../../../apis/loja';

// utils
import {
  CONNECTION_ERROR,
  ERROR,
  SIGN_UP_SUCCESS,
} from '../../../utils/constMessage';

// styles
import {
  StyledFormWrapper,
  HeaderForm,
  SeparatorForm,
  Label,
  BtnGroup,
  Button,
  H5,
} from './style';
import { Wrapper } from '../../../styles/forms';

import Loader from '../../../components/Loader';

// Validatef
import renderField from '../../../formValidate/renderField';
import {
  email as emailValidate,
  required,
  lengthPhone,
  lastname,
  onlyNumbers,
} from '../../../formValidate';
import { maskCelPhone } from '../../../formValidate/masks';

import { mapErrors } from '../../../formValidate/handleErrors';

// actions
import {
  fetchStates,
  fetchCities,
  clearCities,
} from '../../../actions/address';
import { landingRegister } from '../../../actions/landingPage';

//options
import { LANDING_PAGE } from '../option';

let FormHome = props => {
  const { handleSubmit, submitting } = props;
  const [errorsMail, setErrorsMail] = useState('');

  useEffect(() => {
    const { states } = props;
    if (!states) {
      props.fetchStates();
    }
  });

  if (submitting) {
    return <Loader />;
  }

  const onBlurState = e => {
    // caso selecione a opção "0" limpa o select das cidades
    if (e.target.value === '0') {
      props.clearCities();
      props.change('pk_cidade', '0');
    } else {
      props.fetchCities(e.target.value);
    }
  };

  const renderStates = () => {
    const { states } = props;
    if (!states) {
      return '';
    }

    return (
      <>
        {Object.values(states).map(state => {
          return (
            <option key={state.pk_estado} value={state.pk_estado}>
              {state.desc_estado}
            </option>
          );
        })}
      </>
    );
  };

  const renderCities = () => {
    const { cities } = props;
    if (!cities) {
      return '';
    }

    return (
      <>
        {Object.values(cities).map(city => {
          return (
            <option key={city.pk_cidade} value={city.pk_cidade}>
              {city.desc_cidade}
            </option>
          );
        })}
      </>
    );
  };

  const renderTitles = () => {
    return (
      <>
        <option key={1} value="Menos de 5">
          Menos de 5
        </option>
        <option key={2} value="Entre 5 e 10">
          Entre 5 e 10
        </option>
        <option key={3} value="Mais de 10">
          Mais de 10
        </option>
      </>
    );
  };

  const handleErrors = response => {
    if (response.errosValidacao) {
      const errors = mapErrors(response.errosValidacao);
      setErrorsMail(errors);
    }
    if (response.message) {
      toast.warn(response.message);
    } else {
      toast.error(ERROR);
    }
  };

  const submit = async formValues => {
    if (formValues.fk_estado === '0') {
      toast.warn('Selecione Um Estado');
      throw new SubmissionError({
        _error: 'Selecione Um Estado',
      });
    }
    if (formValues.pk_cidade === '0') {
      toast.warn('Selecione Uma Cidade');
      throw new SubmissionError({
        _error: 'Selecione Uma Cidade',
      });
    }
    if (formValues.pk_titulo === '0' || formValues.pk_tiragem === '') {
      toast.warn('Selecione o Título e Tiragem');
      throw new SubmissionError({
        _error: 'Selecione o Título e Tiragem',
      });
    }

    // verifica se email já esta cadastrado
    let email_usuario = formValues.email_usuario_landing;
    try {
      await loja.post('/usuario/verificar-email', {
        email_usuario,
      });
    } catch (error) {
      if (error.response) {
        handleErrors(error.response.data);
        throw new SubmissionError({
          _error: errorsMail.email_usuario,
        });
      } else {
        toast.error(CONNECTION_ERROR);
        throw new SubmissionError({
          _error: CONNECTION_ERROR,
        });
      }
    }

    let telefone_register = {};
    const aCelPhone = formValues.telefone_landing.split(' ');
    telefone_register.ddd_celular = aCelPhone[0].replace(/[^\d]+/g, '');
    telefone_register.nr_celular = aCelPhone[1].replace(/[^\d]+/g, '');

    return props
      .landingRegister(
        formValues.nome_landing,
        formValues.email_usuario_landing,
        telefone_register.ddd_celular,
        telefone_register.nr_celular,
        formValues.fk_estado,
        formValues.pk_cidade,
        formValues.pk_titulo,
        formValues.pk_tiragem
      )
      .then(resp => {
        if (resp.status === 200) {
          toast.success(SIGN_UP_SUCCESS);
          props.history.push(LANDING_PAGE.five);
        } else {
          toast.error(CONNECTION_ERROR);
        }
      });
  };

  return (
    <StyledFormWrapper>
      <HeaderForm>
        <h3>Responda abaixo e receba na hora seu</h3>
        <h3>CUPOM DE DESCONTO</h3>
      </HeaderForm>
      <SeparatorForm />
      <Form id={'form_landing'} onSubmit={handleSubmit(submit)}>
        <Wrapper>
          <Label htmlFor={'nome_landing'}>Nome Completo *</Label>
          <Field
            name={'nome_landing'}
            type={'text'}
            id={'nome_landing'}
            component={renderField}
            placeholder={'Nome Completo'}
            validate={[required, lastname]}
            maxLength={60}
          />
        </Wrapper>
        <SeparatorForm />
        <Wrapper>
          <Label htmlFor={'email_usuario_landing_landing'}>Email *</Label>
          <Field
            name={'email_usuario_landing'}
            type={'email'}
            id={'email_usuario_landing'}
            component={renderField}
            placeholder={'Email'}
            validate={[required, emailValidate]}
            maxLength={100}
            responseError={errorsMail.email_usuario}
            onFocus={e => setErrorsMail({ errorsMail: { email_usuario: '' } })}
          />
        </Wrapper>
        <SeparatorForm />
        <Wrapper>
          <Label htmlFor={'telefone_landing'}>Telefone *</Label>
          <Field
            name={'telefone_landing'}
            type={'text'}
            typefield={'mask'}
            id={'telefone_landing'}
            component={renderField}
            placeholder={'(99) 99999-9999'}
            mask={maskCelPhone}
            validate={[required, lengthPhone]}
          />
        </Wrapper>
        <SeparatorForm />
        <Wrapper>
          <Label htmlFor={'fk_estado'}>UF *</Label>
          <Field
            name="fk_estado"
            id={'fk_estado'}
            typefield={'select'}
            component={renderField}
            onChange={e => onBlurState(e)}
            validate={required}
            firstOption={
              <option key={0} value="0">
                Selecione um Estado
              </option>
            }
            options={renderStates()}
          />
        </Wrapper>
        <SeparatorForm />
        <Wrapper>
          <Label htmlFor={'pk_cidade'}>Cidade *</Label>
          <Field
            name="pk_cidade"
            id={'pk_cidade'}
            typefield={'select'}
            component={renderField}
            validate={required}
            firstOption={
              <option key={0} value="0">
                Selecione uma Cidade
              </option>
            }
            options={renderCities()}
          />
        </Wrapper>
        <SeparatorForm />
        <Wrapper>
          <Label htmlFor={'pk_titulo'}>Quantos Títulos já publicou? *</Label>
          <Field
            name="pk_titulo"
            id={'pk_titulo'}
            typefield={'select'}
            component={renderField}
            validate={required}
            firstOption={
              <option key={0} value="0">
                Selecione uma opção
              </option>
            }
            options={renderTitles()}
          />
        </Wrapper>
        <SeparatorForm />
        <Wrapper>
          <Label htmlFor={'pk_tiragem'}>
            Qual a tiragem mínima que você produz? *
          </Label>
          <Field
            name={'pk_tiragem'}
            type={'text'}
            id={'pk_tiragem'}
            component={renderField}
            placeholder={'Qual a tiragem mínima que você produz? *'}
            validate={[required]}
            maxLength={6}
            normalize={onlyNumbers}
          />
        </Wrapper>
        <BtnGroup>
          <Button type={'submit'} disabled={submitting}>
            Próximo{submitting ? <Loader /> : ''}
          </Button>
        </BtnGroup>
      </Form>
      <H5>
        Campos marcados com asterisco (*) são de preenchimento obrigatório.
      </H5>
    </StyledFormWrapper>
  );
};

const mapStateToProps = store => {
  return {
    states: store.address.states,
    cities: store.address.cities,
  };
};

FormHome = reduxForm({
  form: 'FormHome',
})(FormHome);

FormHome = connect(mapStateToProps, {
  fetchStates,
  fetchCities,
  clearCities,
  landingRegister,
})(FormHome);

export default withRouter(FormHome);
