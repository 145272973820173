import React from 'react';

// Styles
import * as Align from '../../styles/align';
import * as Form from '../../styles/forms';
import * as Cards from '../../styles/cards';
import * as Style from './style';

// services
import { reducerDataPayment } from '../../services/payment';

// componets
import Loader from '../../components/Loader';

/**
 * Função que abre a janela do shopline
 * [onSubmit] Form shopline
 */
const carregabrw = () => {
  window.open(
    '',
    'shopline',
    'toolbar=no,menubar=no,resizable=no,status=no,scrollbars=no,width=815,height=575'
  );
};

const DataPayment = Component => {
  const DataPayment = ({
    payment,
    status,
    updatePayment,
    isPagamentoAdicionalPendente,
    payByCard,
    receipt,
    shopline,
    handleSendReceipt,
    handlePayment,
    btnBknBank,
    btnPix,
    handleFileReceipt,
    isTwoCards
  }) => (
    <React.Fragment>
      {/**
       * Component render Informações de Pagamento
       */}
      <Component payment={reducerDataPayment(payment)}></Component>

      {/**
       * Verifica flag enviarComprovante
       */}
      {payment.fk_tipo_pagamento === 1 ? (
        <Align.Col>
          {payment.fk_tipo_pagamento === 1 ? (
            <>
              <Cards.Info>
                <Cards.Desc>Banco: </Cards.Desc>
                <Cards.Value>
                  {
                    payment.dependenciaTipoPagamento
                      .desc_dependencia_tipo_pagamento
                  }
                </Cards.Value>
              </Cards.Info>
              <Cards.Info>
                <Cards.Value>
                  {
                    payment.dependenciaTipoPagamento
                      .info_dependencia_tipo_pagamento
                  }
                </Cards.Value>
              </Cards.Info>
            </>
          ) : (
            ''
          )}
          <Cards.Info>
            <Cards.Desc>Titular: </Cards.Desc>
            <Cards.Value>{receipt.titular}</Cards.Value>
          </Cards.Info>
          <Cards.Info>
            <Cards.Desc>CNPJ: </Cards.Desc>
            <Cards.Value>{receipt.cnpj}</Cards.Value>
          </Cards.Info>
          {receipt.data &&
          receipt.enviarComprovante &&
          payment.fk_tipo_pagamento === 1 ? (
            <Align.Col>
              <Style.FileContainer>
                <Form.Label htmlFor={'envio_comprovante'}>
                  Enviar Comprovante
                </Form.Label>
                <Style.StyleFiles
                  onChange={e => handleFileReceipt(e.target.files[0])}
                  id="envio_comprovante"
                  name="envio_comprovante"
                />
                <span>
                  Arquivos Permitidos:
                  <strong>{receipt.data.extensoes.join(', ')}</strong>
                </span>
              </Style.FileContainer>
              <Form.BtnGroup>
                <Form.Button onClick={handleSendReceipt}>Enviar</Form.Button>
              </Form.BtnGroup>
            </Align.Col>
          ) : (
            ''
          )}
        </Align.Col>
      ) : (
        ''
      )}

      {/**
       * Verifica status do pedido Outros
       * [12] => Boleto Pendente
       */}
      {status === 12 && payment.fk_tipo_pagamento !== 4 && payment.fk_tipo_pagamento !== 10 && payment.fk_tipo_pagamento !== 13 ? (
        <Align.Col>
          <Cards.Info>
            <Cards.Desc>Boleto:</Cards.Desc>
            <Cards.Value>
              Não é necessário enviar comprovante de pagamento.
            </Cards.Value>
          </Cards.Info>
          <form
            method="post"
            onSubmit={carregabrw}
            target="shopline"
            action={shopline.url}
          >
            <input type="hidden" name="DC" value={shopline.retorno} />
            <Form.BtnGroup>
              <Form.Button type={'submit'}>Imprimir Boleto</Form.Button>
            </Form.BtnGroup>
          </form>
        </Align.Col>
      ) : (
        ''
      )}

      {/**
       * Verifica status do pedido BKN BANK Exclusivo
       * [12] => Boleto Pendente
       */}
      {status === 12 && payment.fk_tipo_pagamento === 10 ? (
        <Align.Col>
          <Cards.Info>
            <Cards.Desc>Boleto:</Cards.Desc>
            <Cards.Value>
              Não é necessário enviar comprovante de pagamento.
            </Cards.Value>
          </Cards.Info>
          <form
            method="post"
          >
            <Form.BtnGroup>
              <Form.Button type={'button'} disabled={btnBknBank.disabledButton} onClick={() => btnBknBank.handlePaymentBknBank()}>Imprimir Boleto</Form.Button>
            </Form.BtnGroup>
            {btnBknBank.isLoader ? (
              <Form.BtnGroup>
                <Loader
                  className={'no-space'}
                />
              </Form.BtnGroup>
            ) : (
              ''
            )}
          </form>
        </Align.Col>
      ) : (
        ''
      )}

      {/**
       * Verifica status do pedido PIX
       * [46] => Pix Pendente
       */}
      {status === 46 && payment.fk_tipo_pagamento === 13 && !payment.comprovante_pagamento ? (
        <Align.Col>
          <Cards.Info>
            <p>
            Para prosseguir com o pagamento, clique em “Gerar QR CODE”, realize a leitura do mesmo
utilizando o aplicativo do seu banco ou o caixa eletrônico e aguarde a confirmação de
pagamento que é feita em instantes.
            </p>
            <p>&nbsp;</p>
            <p>
            <strong>Liberação Financeira Imediata</strong>
            </p>
            <p>&nbsp;</p>
            <p>
            <strong>Não é necessário o envio de comprovante.</strong>
            </p>
          </Cards.Info>
          <form
            method="post"
          >
            <Form.BtnGroup>
              <Form.Button type={'button'} disabled={btnPix.disabledButton} onClick={() => btnPix.handlePaymentPix()}>Gerar Qr Code</Form.Button>
            </Form.BtnGroup>
            {btnPix.isLoader ? (
              <Form.BtnGroup>
                <Loader
                  className={'no-space'}
                />
              </Form.BtnGroup>
            ) : (
              ''
            )}
            {(process.env.NODE_ENV !== 'production' ? (
                <Form.BtnGroup>
                  <Form.Button type={'button'} onClick={() => btnPix.handlePaymentPixConfirmDev()}>Confirmar Pagamento</Form.Button>
                </Form.BtnGroup>
              ) : (
                  ''
              )
            )}
          </form>
        </Align.Col>
      ) : (
        ''
      )}

      {/**
       * Verifica Flag pagar com cartão e status do pedido
       * [16] => Pagamento Cartão não Finalizado
       */}
      {!isTwoCards && payByCard && status === 16 && payment.fk_tipo_pagamento !== 4 ? (
        <Align.Col>
          <Form.BtnGroup>
            <Form.Button onClick={() => handlePayment(true)}>
              Pagar com Cartão
            </Form.Button>
          </Form.BtnGroup>
        </Align.Col>
      ) : (
        ''
      )}

      {/**
       * Verifica Flag de alteração do pagamento
       */}
      {!isTwoCards && updatePayment && !isPagamentoAdicionalPendente && payment.fk_tipo_pagamento !== 4 ? (
        <Align.Col>
          <Form.BtnGroup>
            <Form.Button onClick={() => handlePayment()}>
              Mudar Pagamento
            </Form.Button>
          </Form.BtnGroup>
        </Align.Col>
      ) : (
        ''
      )}
    </React.Fragment>
  );

  return DataPayment;
};

export default DataPayment;
