//import { Field } from 'redux-form';
import styled from 'styled-components';

import { Col, Row } from '../../styles/align';
import { BtnGroup, Button, Input, Label, Radio, Wrapper } from '../../styles/forms';
import { TitleCards } from '../../styles/titles';
// Style
import { colors, fontSize } from '../../styles/variables';

export const Anchor = styled.a`
  cursor: pointer;
  color: ${colors.primary};
  text-decoration: none;
`;

/**
 * Formata da Modal Cadastro de Usuario
 * @extends {Col}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Row}
 * @extends {Wrapper}
 * @extends {Radio}
 * @extends {Label}
 * @extends {Col}
 * @extends {TitleCards}
 * @extends {Input}
 * @extends {BtnGroup}
 * @extends {Button}
 * @extends {colors}
 */
export const RegisterStyle = styled(Col)`
	padding: 0.5rem;
	background-color: ${colors.grey.light};

	${Row} {
		${Wrapper} {
			min-width: 50%;

			${Radio} {
				&:checked {
					& ~ span {
						&::after {
							background-color: ${colors.secondary};
						}
					}
				}

				& ~ ${Label} {
					color: ${colors.primary};
					font-weight: 700;
				}
			}
		}
	}

	${Col} {
		background-color: ${colors.grey.lightPrimary};
		margin-bottom: 1rem;
		padding: 1rem 0;
	
		${TitleCards} {
			padding-left: 0.5rem;
		}

		${Row} {
			width: 100%;

			${Wrapper} {
				padding: 0 .5rem;
				min-width: 50%;
				width: 100%;
			
				${Label} {
					padding-left: 0;
					text-transform: uppercase;
					color: ${colors.primary};
					font-weight: 700;
					font-size: ${fontSize.sm};
				
					strong {
						margin-left: .5rem;
						color: ${colors.secondary};
					}
				}

				${Input} {
					border: 1px solid ${colors.grey.darkSecondary};
				}
			}
		}

		&.content__data-check {
			padding: 0;
			background-color: transparent;

			${Wrapper} {
				${Label} {
					color: ${colors.primary}
				}
			}
		}
	}

	${BtnGroup} {
		flex-direction: row;
		justify-content: space-between;
		padding: 1rem 0;

		span {
			&:first-of-type {
				
				color: ${colors.secondary};
				font-weight: 700;
			}

			&:last-of-type {
				display: flex;
				align-items: center;
				p {
					color: ${colors.primary};
					text-decoration: underline;
					cursor: pointer;
				}

				${Button} {
					text-decoration: none;
					background-color: ${colors.secondary};
					color: ${colors.default};
					font-weight: 700;
				}
			}
		}
	}
`;

// Form Omie
export const TitleRadio = styled.div`
  	width: 51%;
`;

export const TitleCard = styled.h2`
	color: ${colors.primary};
	font-weight: bold;
	font-size: ${fontSize.md};
	text-transform: uppercase;
	padding: ${props => (props.order ? '0' : '0.5rem 1rem')};
	margin-bottom: 0.5rem;

	& > span {
		color: ${colors.secondary};
		font-size: 10px;
	}
`;

export const OmieStyle = styled(Col)`	
	${Row} {
		${Wrapper} {
			span {
				margin-left: 2.5rem;
				cursor: pointer;
			}			
		}
	}	
`;
