import React, { useEffect, useState } from "react";
import { Field } from "redux-form";

// Validate
import { cnpj, lengthCel, lengthPhone, required } from "../../../formValidate";
import { maskCelPhone, maskCnpj, maskPhone } from "../../../formValidate/masks";
import renderField from "../../../formValidate/renderField";

// Styles
import { Col, Row } from "../../../styles/align";
import { Label, Wrapper } from "../../../styles/forms";
import { TitleCards } from "../../../styles/titles";

// api
import loja from '../../../apis/loja';
const RenderDataBusiness = ({ onHandleCnpj, disabled, errors, onHandleClearErrors, loading, }) => {  
  const [segmentos, setSegmentos] = useState([]);

  useEffect(() => {
    const fetchSegmentos = async () => {
      try {
        const response = await loja.get('/usuario/buscar-segmento'); //Rota
        const segmentosData = response.data.data[1]; //Tipo "Pessoa Jurídica"

        setSegmentos(segmentosData); 
      } catch (error) {
        console.error('Erro ao buscar segmentos:', error);
      }
    };

    fetchSegmentos();
  }, []);

  return (
    <Col>
      <div>
        <TitleCards>
          <span>{"Dados Empresariais"}</span>
        </TitleCards>
      </div>
      <Row>
        <Wrapper>
          <Label htmlFor={"cpf_cnpj_usuario"}>
            CNPJ<strong>*</strong>
          </Label>
          <Field
            name={"cpf_cnpj_usuario"}
            id={"cpf_cnpj_usuario"}
            type={"text"}
            component={renderField}
            typefield={"mask"}
            placeholder={"Digite o CNPJ"}
            validate={[required, cnpj]}
            mask={maskCnpj}
            onChange={onHandleCnpj}
            disabled={disabled.cpf_cnpj_usuario}
            responseError={errors.cpf_cnpj_usuario}
            onFocus={e => onHandleClearErrors(e.target.name)}
            loading={loading}
          />
        </Wrapper>
        <Wrapper>
          <Label htmlFor={"contato_usuario"}>
            Contato<strong>*</strong>
          </Label>
          <Field
            name={"contato_usuario"}
            id={"contato_usuario"}
            type={"text"}
            component={renderField}
            placeholder={"Nome Completo"}
            validate={required}
            responseError={errors.contato_usuario}
            onFocus={e => onHandleClearErrors(e.target.name)}
            format={value => value ? value.replace(/[^\w\s.&áéíóúàèìòùâêîôûãẽĩõũäëïöüýÁÉÍÓÚÀÈÌÒÙÂÊÎÔÛÃẼĨÕŨÄËÏÖÜÝ&]/g, '') : ''}
          />
        </Wrapper>
      </Row>
      <Row>
        <Wrapper>
          <Label htmlFor={"nome_usuario"}>
            Razão Social<strong>*</strong>
          </Label>
          <Field
            name={"nome_usuario"}
            id={"nome_usuario"}
            type={"text"}
            component={renderField}
            placeholder={"Razão Social da Empresa"}
            validate={required}
            responseError={errors.nome_usuario}
            onFocus={e => onHandleClearErrors(e.target.name)}
            format={value => value ? value.replace(/[^\w\s.&áéíóúàèìòùâêîôûãẽĩõũäëïöüýÁÉÍÓÚÀÈÌÒÙÂÊÎÔÛÃẼĨÕŨÄËÏÖÜÝ&]/g, '') : ''}
            loading={loading}
          />
        </Wrapper>
      </Row>
      <Row>
        <Wrapper>
          <Label htmlFor={"nr_celular"}>
            Celular<strong>*</strong>
          </Label>
          <Field
            dark
            name={"nr_celular"}
            id={"nr_celular"}
            type={"text"}
            typefield={"mask"}
            component={renderField}
            placeholder={"(99) 99999-9999"}
            validate={[required, lengthCel]}
            mask={maskCelPhone}
            responseError={errors.nr_celular}
            onFocus={e => onHandleClearErrors(e.target.name)}
          />
        </Wrapper>

        
          
        <Wrapper>
          <Label htmlFor={"nr_fone"}>
            Telefone
          </Label>
          <Field
            name={"nr_fone"}
            id={"nr_fone"}
            type={"text"}
            typefield={"mask"}
            component={renderField}
            placeholder={"(99) 9999-9999"}
            mask={maskPhone}
            validate={lengthPhone}
            responseError={errors.nr_fone}
            onFocus={e => onHandleClearErrors(e.target.name)}
          />
        </Wrapper>
      </Row>
      <Row>
        <Wrapper>
            <Label htmlFor={'segmento'}>
              Segmento<strong>*</strong>
            </Label>
            <Field
              name={'segmento'}
              id={'segmento'}
              type={'select'}
              typefield={'select'}
              component={renderField}
              validate={required}
              required
              getGlobalFormStyle={true}
              //disabled={disabled.segmento}
              firstOption={
                <option value='' disabled>
                  Selecione seu segmento
                </option>
              }
              options={segmentos.map(segmento => (
                <option key={segmento.id} value={segmento.id}>
                  {segmento.desc}
                </option>
              ))}
            >
    
            </Field>
          </Wrapper>
      </Row>
    </Col>
  );
};

export default RenderDataBusiness;
