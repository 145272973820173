import React from 'react';
import { connect } from 'react-redux';

// node
import Icon from '@mdi/react';
import { mdiHelpCircle } from '@mdi/js';

// Components
import Tooltip from '../Tooltip';

// utils
import { numberFormat } from '../../utils';

// styles
import { Summary, Price } from './style';

const SummaryCart = ({
  summary,
  getSaldoPayment,
  verificaMultiploPagamento,
  getTypePayment,
}) => {
  const isCreditApplied = verificaMultiploPagamento.multiplePayments;
  const verifyTypePayment = getTypePayment.tipo_pagamento === "4";

  if (!summary) {
    return '';
  }

  function getTotal() {
    if (getSaldoPayment.saldo >= summary.total) {
      return 0;
    }
    if (summary.total > getSaldoPayment.saldo) {
      return summary.total - getSaldoPayment.saldo;
    }
  }

  function showDiscountCredit() {
    if (getSaldoPayment.saldo >= summary.total) {
      return summary.total;
    } else if (getSaldoPayment.saldo < summary.total) {
      return getSaldoPayment.saldo;
    }
  }

  return (
    <Summary>
      <ul>
        {summary.impressao ? (
          <li>
            <span>SubTotal</span>
            <span>R$ {numberFormat(summary.impressao)}</span>
          </li>
        ) : (
          ''
        )}
        {summary.acrescimo.valor ? (
          <li>
            <span>
              Acréscimo
              <Tooltip
                dataId={summary.acrescimo.tipo}
                text={summary.acrescimo.descricao}
                icon={<Icon path={mdiHelpCircle} size={0.8} />}
              />
            </span>
            <span>R$ {numberFormat(summary.acrescimo.valor)}</span>
          </li>
        ) : (
          ''
        )}
        {summary.acabamentos ? (
          <li>
            <span>Acabamentos</span>
            <span>R$ {numberFormat(summary.acabamentos)}</span>
          </li>
        ) : (
          ''
        )}
        {summary.servicos ? (
          <li>
            <span>Serviços Opcionais</span>
            <span>R$ {numberFormat(summary.servicos)}</span>
          </li>
        ) : (
          ''
        )}
        {summary.peso_total ? (
          <li>
            <span>Peso Total</span>
            <span>
              {numberFormat(summary.peso_total)} {summary.unidade_peso}
            </span>
          </li>
        ) : (
          ''
        )}
        {summary.entrega ? (
          <li>
            <span>Frete</span>
            <span>R$ {numberFormat(summary.entrega)}</span>
          </li>
        ) : (
          ''
        )}
        {summary.cupom ? (
          <li>
            <span>Desconto do cupom</span>
            <span>- R$ {numberFormat(summary.cupom)}</span>
          </li>
        ) : (
          ''
        )}
        {verifyTypePayment || isCreditApplied ? (
          <li>
            <span>Desconto do saldo da carteira</span>
            <span>
              - R$ {numberFormat(showDiscountCredit())}
            </span>
          </li>
        ) : (
          ''
        )}
      </ul>

      <Price>
        <span>Total</span>
        {verifyTypePayment || isCreditApplied ? (
          <span>R$ {numberFormat(getTotal())}</span>
        ) : (
          <span>R$ {numberFormat(summary.total)}</span>
        )}
      </Price>

      <a
        target="_blank"
        without
        rel="noopener noreferrer"
        href="https://instrucoes.fabricadolivro.com.br/perguntas-frequentes/como-contar-o-prazo-ate-receber-os-meus-produtos/"
      >
        Como calcular o prazo
      </a>
    </Summary>
  );
};

const mapStateToProps = state => {
  return {
    summary: state.cart.summaryCart,
    getSaldoPayment: state.payment.payments,
    getTypePayment: state.payment.payment,
    verificaMultiploPagamento: state.payment,
  };
};

export default connect(mapStateToProps, null)(SummaryCart);
