import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PaymentTypes } from '../../enums/payment';

// Node
import { toast } from 'react-toastify';

// hocs
import Modal from '../../hocs/Modal';

// components
import Payments from '../../components/Payments';
import Loader from "../../components/Loader"

// actions
import { fetchOrder } from '../../actions/orders';
import { clearPayment, clearDataCard } from '../../actions/payment';
import { modalPayment } from '../../actions/modals';

// services
import { changePayment } from '../../services/cart';
import { validateCreditCard } from '../../services/payment';

// utils
import {
  CONNECTION_ERROR,
  CHOOSE_PAYMENT,
  SELECT_DEPOSIT,
  SUCCESS,
} from '../../utils/constMessage';
import * as constMessage from '../../utils/constMessage';
import { buildPayloadPaymentTwoCards } from '../../utils';

// styles
import { ModalPayment } from './style';
import { BtnGroup, Button } from '../../styles/forms';

class Payment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    }
  }

  componentWillUnmount() {
    this.props.clearPayment();
  }

  handleSubmitPayment = async () => {
    this.setState({ loading: true });
    let dataPayment = {};
    const { pk_pedido } = this.props.order;
    const { payment, multiplePayments, twoCards, clearDataCard } = this.props;

    if (payment.tipo_pagamento === '') {
      toast.warn(CHOOSE_PAYMENT);
      return false;
    }

    switch (payment.tipo_pagamento) {
      case '1':
        if (!payment.pagamento) {
          toast.warn(constMessage.SELECT_DEPOSIT);
          return false;
        }
        dataPayment = {
          tipo_pagamento: payment.tipo_pagamento,
          pagamento: payment.pagamento,
        };
        break;
      case PaymentTypes.CREDIT_CARD:
        const validateResult = validateCreditCard(payment);
        if (validateResult.status !== 'success') {
          toast[validateResult.status](validateResult.message);
          clearDataCard();
          return false;
        }
        dataPayment = {
          ...payment,
          pagamento: payment.dependency, // precisa verificar onde tem que setar isso no redux
        };
        break;
      case '4':
        dataPayment = {
          tipo_pagamento: payment.tipo_pagamento,
          pagamento: '13',
        };
        break;
      case '6':
        dataPayment = {
          tipo_pagamento: payment.tipo_pagamento,
          pagamento: '22', // precisa verificar onde tem que setar isso no redux
        };
        break;
      case '10':
        dataPayment = {
          tipo_pagamento: payment.tipo_pagamento,
          pagamento: '32', // precisa verificar onde tem que setar isso no redux
        };
        break;
      case '13':
        dataPayment = {
          tipo_pagamento: payment.tipo_pagamento,
          pagamento: '44', // precisa verificar onde tem que setar isso no redux
        };
        break;
      // no pagamento, só add as infos que o back vai precisar
      // Mudar pra um numero inteiro (verificar qual numero colocar, Cleber falou)
      case 'twoCards':
        const twoCardsValidData =
          validateCreditCard(twoCards.firstCard) &&
          validateCreditCard(twoCards.secondCard);
        if (twoCardsValidData.status !== 'success') {
          toast[twoCardsValidData.status](twoCardsValidData.message);
          return false;
        }
        dataPayment = {
          // tipo_pagamento: payment.tipo_pagamento,
          // verificar qual numero colocar (talvez vai ser necessário criar tabela no banco, Cleber falou)
          tipo_pagamento: 3,
          ...buildPayloadPaymentTwoCards({
            primeiroCartao: twoCards.firstCard,
            segundoCartao: twoCards.secondCard,
          }),
        };
        break;
      default:
        toast.warn(constMessage.CHOOSE_PAYMENT);
        break;
    }

    if (multiplePayments) {
      dataPayment = {
        ...dataPayment,
        tipo_pagamento_credito: '4',
        pagamento_credito: '13',
      };
    }

    const response = await changePayment(pk_pedido, dataPayment);

    if (response.status === 200) {

      toast.success(SUCCESS);
      this.props.modalPayment();
      this.props.fetchOrder(pk_pedido);
      this.setState({ loading: false });

    } else if (response.errors) {

      this.props.modalPayment();
      this.props.fetchOrder(pk_pedido);
      this.setState({ loading: false });
      toast.error(`${response.errors.message}. Aguarde um momento...`);

      setTimeout(() => {
        window.location.href = `/pedido/${pk_pedido}`
      }, 2000);

    } else {

      this.setState({ loading: false });
      toast.error(CONNECTION_ERROR);

    }
  };

  render() {
    return (
      <ModalPayment>
        {this.state.loading ? <Loader /> :
          <>
          {/* Receber 'order' para tratar lógica dos componentes em Meus Pedidos /pedido/id */}
            <Payments order={this.props.order}/>
            <BtnGroup>
              <Button onClick={this.props.modalPayment}>Cancelar</Button>
              <Button onClick={() => this.handleSubmitPayment()}>Enviar</Button>
            </BtnGroup>
          </>
        }
      </ModalPayment>
    );
  }
}

const mapStateToProps = state => {
  return {
    order: state.orders.order,
    payment: state.payment.payment,
    twoCards: state.payment.twoCards,
  };
};

Payment = connect(mapStateToProps, { fetchOrder, clearPayment, modalPayment, clearDataCard })(
  Payment
);

Payment = Modal(Payment);

export default Payment;
