/**
 * Configurações gerais do projeto
 * para discriminar produção/desenvolvimento
 * verificar os respectivos arquivos
 */

import config_development from './development';
import config_production from './production';

// objeto config geral do projeto
let config = {
  ZONA_CAROUSEL: 142,
  TYPE_BOOK: 7,
  TYPE_QUANTITY: [
    {
      pk: '375',
      name: 'paginas_preto_branco',
    },
    {
      pk: '14',
      name: 'paginas_coloridas',
    },
  ],
  TYPE_QUANTITY_COPIES: 1,
  TYPE_BONECO: '61',
  TYPE_PAGES: 9631,
  CONTATOS: 10,
  pathImg: 'https://files.fabricadolivro.com.br',
};

// faz o merge com o arquivo do ambiente
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  config = { ...config, ...config_development };
} else {
  config = { ...config, ...config_production };
}

export default config;
