import React from 'react';
import Icon from '@mdi/react';
import { mdiAlertCircle } from '@mdi/js';
import { connect } from 'react-redux';

const StatusItem = ({ data }) => {
  if (!data || !data.statusPedidoItem) {
    return null; // Retorna nada se os dados forem indefinidos ou incompletos
  }

  // Acessar a descrição do status do item
  const descStatusPedidoItem = data.statusPedidoItem.desc_status_pedido_item;

  // Verificar se o status é "Arquivo C/ Problemas"
  if (descStatusPedidoItem === "Arquivo C/ Problemas") {
    return (
      <div className="status-container">
        <div style={{ textAlign: 'left' }}>
          ATENÇÃO: VERIFICAR SEU ARQUIVO COM PROBLEMAS EM OBSERVAÇÕES.
        </div>
        <div className="warning" style={{ marginLeft: '6em' }}>
          <span className="status-label">STATUS:</span>
          <Icon
            path={mdiAlertCircle}
            size={0.8}
            color={'#ff0606'}
            style={{ verticalAlign: 'middle' }}
          />
          {descStatusPedidoItem}
        </div>
      </div>
    );
  }

  // Caso o status seja diferente de "Arquivo C/ Problemas"
  return (
    <div className="status-container">
      <div className="status-label">STATUS:</div>
      <div className="status-details">
        <span>{descStatusPedidoItem}</span>
      </div>
    </div>
  );
};

export default StatusItem;
