import React from 'react';
// node-modules
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';

// hocs
import GroupCards from '../../hocs/GroupCards';
// icons
import { IconSearch } from '../../shared/icons';
// utils
import { numberFormat, parseHtmlFromString } from '../../utils';
// style
import { Icon, ProductStyle, ProductsWrapper } from './style';

const Products = ({ data, base, otherPath }) => {
  return (
    <ProductsWrapper>
      {data.map(product => {
        const url = product.url_produto ? product.url_produto : '/';
        return (
          <ProductStyle key={product.pk_produto}>
            <Link
              to={{ pathname: `${otherPath ? '/' : ''}${url}`, query: { url } }}
              title={product.desc_menu}
            >
              <header>
                <div>
                  <img
                    alt={product.nome_menu_imagem}
                    src={`${base}${product.nome_menu_imagem}`}
                    title={product.nome_menu_imagem}
                  />
                </div>
                <h1>{product.desc_menu}</h1>
                {product.keywords_tags ? (
                  <p>
                    {ReactHtmlParser(
                parseHtmlFromString(product.keywords_tags)
              )}</p>
                ) : (
                  <p>{product.desc_categoria}</p>

                )}
              </header>
              <footer>                
                <div>
                {/* Valor Total */}
                {product.vlr_preco ? (
                  <>
                    A partir de{' '}
                    <strong>R$ {numberFormat(product.vlr_preco, 2)}</strong>
                  </>
                ) : (
                  ''
                )}
                </div>    
                <div> 
                {/* Valor Unitario */}                 
                {product.vlr_unitario ? (
                  <>
                    Valor Unitário{' '}
                    <strong>R$ {numberFormat(product.vlr_unitario, 2)}</strong>
                  </>
                ) : (
                  ''
                )}
                </div>
               
              </footer>
              <Icon>
                <IconSearch />
              </Icon>
            </Link>
          </ProductStyle>
        );
      })}
    </ProductsWrapper>
  
     
  );
};


export default GroupCards(Products);
