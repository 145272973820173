import loja from '../apis/loja';

import { FETCH_BALCONIES, CLEAR_BALCONY } from './types';
import { toast } from 'react-toastify';

/**
 * Busca os balcoes de acordo com o filtro
 * @param {integer} pk_estado
 * @param {integer} pk_cidade
 * @param {boolean} frete_gratis [0/1]
 */
export const fetchBalconies = data => async dispatch => {
  try {
    const response = await loja.post('/balcao/listar', data);

    dispatch({ type: FETCH_BALCONIES, payload: response.data });
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

export const clearBalcony = () => {
  return {
    type: CLEAR_BALCONY,
  };
};
