import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// actions
import { closeMenu } from '../../actions/menu';
import {
  modalListAddress,
  modalLogin,
  modalPrivacy,
  modalRegister,
} from '../../actions/modals';
import { fetchUser, signOut } from '../../actions/user';

// services
import { isAuthenticated, logout } from '../../services/user';

// Shared
import { IconUser } from '../../shared/icons';

// Styles
import { ContainerLogin, StyleAccount, StyleMyAccount } from './style';

class Controls extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email_usuario: '',
    };
  }

  componentDidMount() {
    // caso o usuário esteja logado na sessão do navegador
    // verifica a validade do token
    if (isAuthenticated()) {
      this.props.fetchUser();
    }
  }

  handleAction = action => {
    /**
     * Processa a ação dependendo do item do menu minha conta
     */
    action();
    this.props.closeMenu();
  };

  handleLogout = () => {
    logout();
    this.props.signOut();
  };

  renderLogin = () => {
    return (
      <StyleAccount>
        <IconUser />
        <span>
          Faça seu
          <span>
            <strong className="action-link" onClick={this.props.modalLogin}>
              LOGIN
            </strong>{' '}
            ou{' '}
            <strong className="action-link" onClick={this.props.modalLogin}>
              CADASTRO
            </strong>
          </span>
        </span>
      </StyleAccount>
    );
  };

  renderMyAccount = () => {
    return (
      <StyleMyAccount className="action-link">
        <div style={{ justifyContent: "flex-start", paddingLeft: "0.8rem" }}>
          <IconUser />
          <span>Minha Conta</span>
        </div>
        <ul>
          <li>Cod. Cliente: {this.props.user.user.codigo_usuario}</li>
          <li onClick={this.props.closeMenu}>
            <Link to={'/pedidos'} title={'Meus Pedidos'}>
              Meus Pedidos
            </Link>
          </li>
          <li>
            <Link to={'/mensagem'} title={'Minhas Mensagens'}>
              Minhas Mensagens
            </Link>
          </li>
          <li>
            <Link to={'/credito'} title={'Meus Créditos'}>
              Meus Créditos
            </Link>
          </li>
          <li onClick={() => this.handleAction(() => this.props.modalRegister(true))}>
            Meus Dados
          </li>
          <li onClick={() => this.handleAction(this.props.modalListAddress)}>
            Meus Endereços
          </li>
          <li onClick={() => this.handleAction(this.props.modalPrivacy)}>
            Central de Privacidade
          </li>
          <li onClick={() => this.handleAction(this.handleLogout)}>Sair</li>
        </ul>
      </StyleMyAccount>
    );
  };

  render() {
    const { user } = this.props;

    return (
      <>
        <ContainerLogin>
          {user.user && user.isAuthenticated
            ? this.renderMyAccount()
            : this.renderLogin()}
        </ContainerLogin>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    addresses: state.address.listAddress
  };
};

export default connect(mapStateToProps, {
  closeMenu,
  signOut,
  fetchUser,
  modalListAddress,
  modalLogin,
  modalRegister,
  modalPrivacy,
})(Controls);
