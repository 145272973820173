/**
 * Ordena um array e acordo com o calback recebido sem alterar o array original
 *
 * @param {array} arr - array que será ordenado
 * @param {function} cb - função de comparação dos valores
 * @return {array}
 *
 */
const sort = (arr, cb) => arr.concat().sort(cb);

/**
 * Ordena um array de objetos em orden alfabetica
 *
 * @param {array} arr - array que será ordenado
 * @param {string} prop - propriedade utilizada para comparação
 * @return {array}
 *
 */
export const alf = (arr, prop) => sort(arr, (a, b) => {
  let result;

  if (a[prop] === b[prop]) {
    result = 0;
  } else {
    if (a[prop] < b[prop]) {
      result = -1;
    } else {
      result = 1;
    }
  }

  return result;
});

/**
 * Ordena um array de objetos em orden crescente
 *
 * @param {array} arr - array que será ordenado
 * @param {string} prop - propriedade utilizada para comparação
 * @return {array}
 *
 */
export const asc = (arr, prop) => sort(arr, (a, b) => a[prop] - b[prop]);

/**
 * Ordena um array de objetos em orden decrescente
 *
 * @param {array} arr - array que será ordenado
 * @param {string} prop - propriedade utilizada para comparação
 * @return {array}
 *
 */
export const desc = (arr, prop) => sort(arr, (a, b) => b[prop] - a[prop]);
