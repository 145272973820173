import styled from "styled-components";

// Style
import { Col } from "../../../styles/align";
import { colors } from "../../../styles/variables";

/**
 * Formata o Card que tem mais de um tipo de opção [BookContent | BookServices]
 * @extends {Col}
 *
 * Herda estilo para formatar o interior do Card
 * @extends {colors}
 */
export const ContainerCardBook = styled(Col)`
  padding: 0.8rem;
  background-color: ${colors.grey.lightPrimary};

  & > div {
    padding: 0;

    &:first-of-type {
      margin-bottom: 1rem;
    }
  }
`;
