import styled from 'styled-components';

import { colors, fontSize } from '../../../styles/variables';

import { Container as ContainerHome, ContainerForm } from '../Home/style';
import { ColGrid, getWidthGrid } from '../../../styles/align';

export const Container = styled(ContainerHome)`
  background-position: center;

  @media only screen and (max-width: 768px) {
    display: block;
    background-size: cover;
    height: 15rem;
  }
`;

export const Obrigado = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  p {
    padding: 1rem;
    font-size: 1.25rem;
    color: ${colors.default};
  }
`;

export const H2 = styled.h2`
  color: ${colors.secondary};
  font-size: 3rem;
`;

export const H3 = styled.h3`
  color: ${colors.secondary};
`;

export const Cupom = styled.div`
  border: dashed 4px ${colors.default};
  padding: 0.5rem;
  margin-bottom: 2rem;
  display: inline-block;
  background-color: ${colors.blueDark};

  h1 {
    color: ${colors.default};
  }
`;

export const ContainerCard = styled(ContainerForm)`
  background: linear-gradient(
    90deg,
    rgba(2, 110, 98, 255) 0%,
    rgba(12, 29, 74, 255) 100%
  );
`;

export const Row = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ColText = styled(ColGrid)`
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 768px) {
    ${() => getWidthGrid(12)}
  }

  @media only screen and (min-width: 768px) {
    ${() => getWidthGrid(6)}
  }

  @media only screen and (min-width: 1000px) {
    ${() => getWidthGrid(6)}
  }
`;

export const ColCard = styled(ColGrid)`
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 768px) {
    ${() => getWidthGrid(12)}
  }

  @media only screen and (min-width: 768px) {
    ${() => getWidthGrid(6)}
  }

  @media only screen and (min-width: 1000px) {
    ${() => getWidthGrid(6)}
  }
`;

export const Dobro = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h3 {
    color: ${colors.secondary};
    font-size: ${fontSize.xl};
    font-weight: 700;
  }

  h3:nth-child(2) {
    margin-bottom: 1rem;
  }

  p {
    color: ${colors.default};
    line-height: 2rem;
  }

  @media only screen and (max-width: 768px) {
    h3:first-child {
      margin-top: 2rem;
    }
  }
`;

export const MiniCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
  margin: 2rem 2rem;
  background-color: ${colors.default};
  border-radius: 1rem;

  p:first-child {
    text-align: center;
  }

  ul {
    align-items: flex-start;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  @media only screen and (min-width: 768px) {
    width: 100%;
  }

  @media only screen and (min-width: 1000px) {
    width: 60%;
  }
`;

export const Pcard = styled.p`
  &:first-child {
    margin-top: 2rem;
  }

  color: ${colors.secondary};
  font-size: 1.5rem;
  font-weight: 700;
  padding: 0.5rem;
`;

export const List = styled.ul`
  list-style: none;
  margin: 1rem auto 0 0;

  li:before {
    content: '✓';
    padding: 0.5rem;
  }

  li {
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 1.2rem;
  }

  p {
    padding: 1rem;
  }
`;

export const LinkSure = styled.div`
  width: 60%;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.1rem;
  border-radius: 4px;
  border: none;
  text-transform: uppercase;
  margin: 0.5rem;
  height: 40px;
  font-weight: 600;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  background-color: ${colors.secondary};
  cursor: pointer;

  a {
    text-decoration: none;
    color: ${colors.default};
    padding: 12px 1rem;
    display: flex;
    justify-content: center;
  }

  a:hover {
    transform: scale(1.1);
    background-color: ${colors.secondary};
    color: ${colors.default};
    border-radius: 5px;
    transition: all 0.2s ease-out;
  }
`;

export const LinkNot = styled.div`
  button {
    background-color: transparent;
    color: ${colors.default};
    padding: 12px 1rem;
    display: flex;
    justify-content: center;
    border: none;
    cursor: pointer;
    text-decoration: underline;
  }
`;
