/**
 * Objeto de configuração do Carousel
 *
 * @type {Object}
 */

export const settings = {
  arrows: true,
  autoplay: true,
  autoplaySpeed: 3500,
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
};
