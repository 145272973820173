import React, { Component } from 'react';
import { connect } from 'react-redux';

// Node
import Loader from '../../components/Loader';

// actions
import { signOut } from '../../actions/user';

// service
import { simulateLogin } from '../../services/user';

//styles
import { ContainerLoading } from '../../styles/loader';

class SimulateClient extends Component {
  componentDidMount() {
    this.handleLogin();
  }

  handleLogin = async () => {
    const { params } = this.props.match;
    this.props.signOut();

    try {
      await simulateLogin(params);
      window.location.href = '/';
    } catch (error) {
      this.props.history.push('/nao-encontrado');
    }
  };

  render() {
    return (
      <ContainerLoading>
        <Loader />
      </ContainerLoading>
    );
  }
}

export default connect(null, { signOut })(SimulateClient);
