import React from 'react';

// styles
import {
  StyledWrapper,
  TitleCard,
  SeparatorHalf,
  ColCard,
  ColText,
  ColCardR,
  StyleImageCard,
  RowCard,
  RowImage,
  RowText,
  TextCard,
  TitleContent,
  SeparatorCard,
  H2,
} from './style';

// images
import ImageParque from '../../../assets/images/parque-proprio-250.jpg';
import ImageQualidade from '../../../assets/images/qualidade-impressao-250.jpg';
import ImageInteligente from '../../../assets/images/impressao-inteligente-250.jpg';
import ImageLider from '../../../assets/images/lider-logistica.jpg';

const Card = () => {
  return (
    <StyledWrapper>
      <TitleCard>Que tal sua editora independente contar com:</TitleCard>
      <SeparatorHalf />
      <RowCard>
        <ColCard>
          <RowText>
            <TitleContent>
              <h2>Parque Gráfico</h2>
              <h2>Próprio</h2>
              <TextCard>Parque gráfico próprio com produção ágil,</TextCard>
              <TextCard>com máxima qualidade e o menor preço.</TextCard>
            </TitleContent>
          </RowText>
        </ColCard>
        <ColText>
          <RowImage>
            <StyleImageCard
              src={ImageParque}
              alt={'Parque Própio'}
              title={'Parque Própio'}
            />
          </RowImage>
        </ColText>
      </RowCard>
      <SeparatorCard />
      <RowCard>
        <ColCard>
          <RowImage>
            <StyleImageCard
              src={ImageQualidade}
              alt={'Qualidade'}
              title={'Qualidade'}
            />
          </RowImage>
        </ColCard>
        <ColCardR>
          <RowText>
            <TitleContent>
              <h2>Sistema de qualidade</h2>
              <h2>de Impressão</h2>
              <TextCard>Impressão em alto padrão gráfico.</TextCard>
            </TitleContent>
          </RowText>
        </ColCardR>
      </RowCard>
      <SeparatorCard />
      <RowCard>
        <ColCard>
          <RowText>
            <TitleContent>
              <H2>Produção Inteligente</H2>
              <TextCard>Impressão em pequenas ou grandes tiragem</TextCard>
              <TextCard>de acordo com a necessidade da sua editora.</TextCard>
            </TitleContent>
          </RowText>
        </ColCard>
        <ColText>
          <RowImage>
            <StyleImageCard
              src={ImageInteligente}
              alt={'Impressão Inteligente'}
              title={'Impressão Inteligente'}
            />
          </RowImage>
        </ColText>
      </RowCard>
      <SeparatorCard />
      <RowCard>
        <ColCard>
          <RowImage>
            <StyleImageCard
              src={ImageLider}
              alt={'Lider em Logística'}
              title={'Lider em Logística'}
            />
          </RowImage>
        </ColCard>
        <ColCardR>
          <RowText>
            <TitleContent>
              <h2>Logística Líder de</h2>
              <h2>Mercado</h2>
              <TextCard>Milhares de Balcões de Retirada espalhado</TextCard>
              <TextCard>por todo o território nacional e com Retirada</TextCard>
              <TextCard>Gratuita.*</TextCard>
            </TitleContent>
          </RowText>
        </ColCardR>
      </RowCard>
    </StyledWrapper>
  );
};

export default Card;
