import React from 'react';

// Node
import MaskedInput from 'react-text-mask';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Components
import Tooltip from '../components/Tooltip';


// Styles
import {
  Input,
  Checkbox,
  Radio,
  Select,
  Textarea,
  InputMult,
} from '../styles/forms';
import { FiInfo } from "react-icons/fi";

// tratamento de data e locale
import brasil from 'date-fns/locale/pt-BR';
import LoaderForm from '../components/Loader/loaderForm';
import { colors } from '../styles/variables';
registerLocale('pt-BR', brasil);

const verifyErrors = (errors, errorMessage) => {
  return (
    <>
      {errors.meta.touched &&
        ((errorMessage && (
          <>
            {errors.typefield !== 'checkbox' ? (
              <div className="text-error">
                <Tooltip
                  text={errorMessage}
                  dataId={errors.input.name}
                  icon={
                    <FiInfo title={'error'} size={4} />
                  }
                />
              </div>
            ) : (
              <div className="text-error-message">{errorMessage}</div>
            )}
          </>
        )) ||
          (errors.meta.warning && (
            <div className="text-warning">{errors.meta.warning}</div>
          )))}
      {errors.loading && errors.typefield !== 'checkbox' ? (
        <div className="input-loading">
          <LoaderForm color={colors.primary} />
        </div>
      ) : (
        ''
      )}
    </>
  );
};

const renderField = field => {
  const errorMessage = field.responseError
    ? field.responseError
    : field.meta.error;
    
  switch (field.typefield) {
    //modificado para setar o cursor no primeiro campo do formulario
    //para que ao teclar enter não feche a modal 26836
    case 'firstField':
    return (
        <>
           <MaskedInput
            {...field.input}
            dark={field.dark}
            disabled={field.disabled}
            placeholder={field.placeholder}
            type={field.type}
            mask={field.mask}
            readOnly={field.readonly}
            render={(ref, props) => (
              <Input
              autoFocus
                {...props}
                ref={ref}
                className={
                  field.meta.touched &&
                  ((errorMessage && 'input__error') ||
                    (field.meta.warning && 'input__error'))
                }
              />
            )}
          />
          {verifyErrors(field, errorMessage)}
        </>

      );
    //modificado para setar o cursor no primeiro campo do formulario
    //para que ao teclar enter não feche a modal 26836
    case 'firstFieldEndereco':
    return (
        <>
          <Input
            autoFocus
            {...field.input}
            disabled={field.disabled}
            placeholder={field.placeholder}
            type={field.type}
            id={field.id}
            readOnly={field.readonly}
            maxLength={field.maxLength}
            className={
              field.meta.touched &&
              ((errorMessage && 'input__error') ||
                (field.meta.warning && 'input__error'))
            }
          />
          {verifyErrors(field, errorMessage)}
        </>
      );
    case 'checkbox':
      return (
        <>
          <Checkbox
            {...field.input}
            placeholder={field.placeholder}
            disabled={field.disabled}
            id={field.id}
            type={field.type}
          />
          <span></span>
          {verifyErrors(field, errorMessage)}
        </>
      );
    case 'radio':
      return (
        <>
          <Radio
            {...field.input}
            disabled={field.disabled}
            secondary={field.secondary}
            id={field.id}
            type={field.type}
            placeholder={field.placeholder}
          />
          <span></span>
          {verifyErrors(field, errorMessage)}
        </>
      );
    case 'select':
      return (
        <>
          <Select
            id={field.id}
            disabled={field.disabled}
            {...field.input}
            dark={field.dark}
            getGlobalFormStyle={field.getGlobalFormStyle}
          >
            {field.firstOption ? field.firstOption : ''}
            {field.options}
          </Select>
          {verifyErrors(field, errorMessage)}
        </>
      );
    case 'date':
      return (
        <>
          <DatePicker
            {...field.input}
            autoComplete="off"
            className={'date__picker-input'}
            dateFormat="dd/MM/yyyy" 
            //dateFormat={field.formatDate}
            placeholderText={field.placeholderText}
            locale={brasil}
          />
          {verifyErrors(field, errorMessage)}
        </>
      );
    case 'mask':
      return (
        <>
          <MaskedInput
            {...field.input}
            dark={field.dark}
            disabled={field.disabled}
            placeholder={field.placeholder}
            type={field.type}
            mask={field.mask}
            readOnly={field.readonly}
            render={(ref, props) => (
              <Input
                {...props}
                ref={ref}
                className={
                  field.meta.touched &&
                  ((errorMessage && 'input__error') ||
                    (field.meta.warning && 'input__error'))
                }
              />
            )}
          />
          {verifyErrors(field, errorMessage)}
        </>
      );
    case 'textarea':
      return (
        <>
          <Textarea {...field.input} heightContent={field.heightContent} />
          {verifyErrors(field, errorMessage)}
        </>
      );
    case 'multiply':
      return (
        <>
          <InputMult {...field.input} readOnly={field.readonly} />
        </>
      );
    default:
      return (
        <>
          <Input
            {...field.input}
            disabled={field.disabled}
            placeholder={field.placeholder}
            type={field.type}
            id={field.id}
            readOnly={field.readonly}
            maxLength={field.maxLength}
            className={
              field.meta.touched &&
              ((errorMessage && 'input__error') ||
                (field.meta.warning && 'input__error'))
            }
          />
          {verifyErrors(field, errorMessage)}
        </>
      );
  }
};

export default renderField;
