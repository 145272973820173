import styled from 'styled-components';
import { colors, fontSize } from './variables';

export const ContainerLoading = styled.div`
  background-color: ${colors.default};
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;

  p {
    color: ${colors.primary};
    font-size: ${fontSize.md};
    font-weight: 700;
  }

  .loader {
    &:after {
      content: ${props => (props.text ? props.text : 'Redirecionando...')};
  }
`;
