import React, { Component } from "react";
import { connect } from "react-redux";

// Actions
import { modalConfirm } from "../../actions/modals";

// Styles
import { Row } from "../../styles/align";
import { BtnGroup, Button } from "../../styles/forms";
import { Loading } from "../style";
import { StyleConfirm } from "./style";
// node-modules
import ReactHtmlParser from "react-html-parser";

// Hocs
import Modal from "../../hocs/Modal";
import Loader from "../../components/Loader";

class Confirm extends Component {
  state = {
    loading: false
  };

  handleCallback = () => {
    this.setState({
      loading: true
    });
    if (this.props.callback !== undefined) {
      this.props.callback(this.props.data).then(() => {
        this.props.modalConfirm();
      });
    } else {
      this.props.modalConfirm();
    }
  };

  render() {
    return (
      <StyleConfirm>
        {this.state.loading ? (
          <Loading>
            <Loader />
          </Loading>
        ) : (
          ""
        )}
        <Row>
          <p>{ReactHtmlParser(this.props.text)}</p>
        </Row>
        <Row>
          <BtnGroup>
            <Button onClick={this.props.modalConfirm}>Cancelar</Button>
            <Button onClick={this.handleCallback}>Confirmar</Button>
          </BtnGroup>
        </Row>
      </StyleConfirm>
    );
  }
}

const mapStateToProps = state => {
  return {
    callback: state.modals.modalConfirm.callback,
    data: state.modals.modalConfirm.data,
    text: state.modals.modalConfirm.text
  };
};

Confirm = connect(mapStateToProps, { modalConfirm })(Confirm);

Confirm = Modal(Confirm);

export default Confirm;
