import React from 'react';

// hocs
import GroupCards from '../../hocs/GroupCards';

// style
import * as Style from './style';

const Feature = ({ data }) => {

  return (
    <>
      {data.map((feature, index) => {
        // Removido o indice 0 pois o mesmo é o primeiro item do array e não deve ser exibido até o momento que o editor possa fazer edição.
        if (index >= 1) {
          return (
          <Style.FeatureStyle key={index}>
            <div className="feature__content">
              <div>{<feature.icon />}</div>
              <div>
                <header>
                  <h1>{feature.title}</h1>
                </header>
                <p>{feature.desc}</p>
                <span>{feature.text}</span>
              </div>
            </div>
          </Style.FeatureStyle>
        );
      }
        return null
      })}
    </>
  );
};

export default GroupCards(Feature);
