/* eslint-disable no-unused-vars */
// utils
import {
  CHANGE_NUMBER,
  CHOOSE_NAME_CARD,
  CHOOSE_MONTH,
  CHOOSE_YEAR,
  CHOOSE_CVC,
  INVALID_CVC,
  CHOOSE_PARCELS,
  INVALID_NUMBER,
} from '../utils/constMessage';
import { numberFormat } from '../utils';
import loja from '../apis/loja';

// Função de init para validar o cartão
export const validateCard = value => {
  const flag = identify(value);
  const valid = validate(value);
  const number = onlyNumbers(value);
  const dependency = dep(flag, cards);

  return { valid, flag, number, dependency };
};

const dep = (flag, cards) => {
  let $dep = 2; // default Master

  cards.forEach(card => {
    if (card.name === flag) {
      $dep = card.dep;
    }
  });
  return $dep;
};

// remove tudo que não e numero
const onlyNumbers = data => {
  if (typeof data !== 'string') return false;

  return data.replace(/[^\d]+/g, '');
};

// Identifica o pattern do cartão
const identifyCard = (cardNumber, cards) => {
  let name = '';
  cards.every(card => {
    if (card.pattern.test(cardNumber)) {
      if (!card.active) {
        return false;
      }

      name = card.name;
      return false;
    }
    return true;
  });

  return name;
};

/**
 * identifica a bandeira do cartão
 *
 * @param {string} cardNumber - número do cartão
 * @return {string}
 */
const identify = cardNumber => {
  if (typeof cardNumber !== 'string') return false;

  const numbers = onlyNumbers(cardNumber);
  const name = identifyCard(numbers, cards);

  return name;
};

/**
 * valida cartão de crédito
 *
 * @param {string} cardNumber - número do cartão
 * @param {number} cvcNumber - código de segurança
 * @param {string} cardDate - data de validade
 * @return {boolean}
 */
const validate = cardNumber => {
  // todos os parâmetros devems er strings
  if (typeof cardNumber !== 'string') {
    return false;
  }

  // valida se os campos estão vazios
  if (isEmpty(cardNumber)) {
    return false;
  }

  // limpa a string deixando apenas do números do cartão
  const numbers = onlyNumbers(cardNumber);

  // descobre o nome da bandeira do cartão
  const name = identifyCard(numbers, cards);

  // verifica se a quantidade de números do cartão é válida
  if (!checkLength(numbers, name, 'length', cards)) return false;

  if (!luhn(numbers)) return false;

  return true;
};

/**
 * valida cvc do cartão de crédito
 * se tem 3 ou 4 dígitos
 *
 * @param  {string} cardCvc - número do cvc
 * @return {boolean}
 */
const validateCvc = cardCvc => {
  const lengthCvc = cardCvc.split('*').length;
  if (lengthCvc < 4) {
    return false;
  }
  return true;
};

/**
 * valida se o padrão de números do cartão
 * bate com o padrão luhn utilizado pelas
 * operadoras para criar os números dos cartões
 */
const luhn = value => {
  let sum = 0;
  const weightedArr = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];
  let even = false;

  for (let i = value.length - 1; i >= 0; i--) {
    const digit = parseInt(value.charAt(i), 10);
    if (even) {
      sum += weightedArr[digit];
    } else {
      sum += digit;
    }
    even = !even;
  }

  return sum % 10 === 0;
};

/**
 * Verifica se o valor recebido está vazio
 * @param {string} str - valor verificado
 * @return {boolean} resultado
 */
const isEmpty = str => {
  return str === '';
};

/**
 * valida se a quantidade de números do cartão bate com
 * os padrão de números da bandeira
 */
const checkLength = (numbers, name, type, cards) => {
  let test = false;
  const card = cards.filter(el => el.name === name);
  if (card.length < 1) return false;

  // loop nas quantidades de
  // caracteres permitidos pela bandeira
  card[0][type].every(len => {
    if (numbers.length === +len) {
      test = true;

      return false;
    }

    return true;
  });

  return test;
};

// patters das bandeiras do cartão de Credito.
const cards = [
  {
    name: 'cabal',
    pattern: /^((627170)|(589657)|(603522)|(604((20[1-9])|(2[1-9][0-9])|(3[0-9]{2})|(400))))/,
    length: [16, 19],
    cvc: [3],
    active: true,
    dep: 35,
  },
  {
    name: 'hipercard',
    pattern: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/,
    length: [13, 16, 19],
    cvc: [3],
    active: true,
    dep: 21,
  },
  {
    name: 'dinersclub',
    pattern: /^(36|38[0-3]|38[5-9]|30[0-5])/,
    length: [14, 16],
    cvc: [3],
    active: true,
    dep: 3,
  },
  {
    name: 'jcb',
    pattern: /^(35)/,
    length: [16, 19],
    cvc: [3],
    active: true,
    dep: 25,
  },
  {
    name: 'mastercard',
    pattern: /^((2[1-5][0-9]{14})|(5[1-5][0-9]{14}))/,
    length: [16],
    cvc: [3],
    active: true,
    dep: 2,
  },
  {
    name: 'elo',
    /* eslint max-len: ["error", 1000] */
    pattern: /^((509091)|(636368)|(636297)|(504175)|(438935)|(451416)|(4576)|(4011)|(40117[8-9])|(45763[1-2])|(457393)|(431274)|(50990[0-2])|(5099[7-9][0-9])|(50996[4-9])|(509[1-8][0-9][0-9])|(5090(0[0-2]|0[4-9]|1[2-9]|[24589][0-9]|3[1-9]|6[0-46-9]|7[0-24-9]))|(5067(0[0-24-8]|1[0-24-9]|2[014-9]|3[0-379]|4[0-9]|5[0-3]|6[0-5]|7[0-8]))|(6504(0[5-9]|1[0-9]|2[0-9]|3[0-9]))|(6504(8[5-9]|9[0-9])|6505(0[0-9]|1[0-9]|2[0-9]|3[0-8]))|(6505(4[1-9]|5[0-9]|6[0-9]|7[0-9]|8[0-9]|9[0-8]))|(6507(0[0-9]|1[0-8]))|(65072[0-7])|(6509(0[1-9]|1[0-9]|20))|(6516(5[2-9]|6[0-9]|7[0-9]))|(6550(0[0-9]|1[0-9]))|(6550(2[1-9]|3[0-9]|4[0-9]|5[0-8])))\d+$/,
    length: [16],
    cvc: [3],
    active: true,
    dep: 24,
  },
  {
    name: 'visa',
    pattern: /^4/,
    length: [13, 16],
    cvc: [3],
    active: true,
    dep: 17,
  },
  {
    name: 'aura',
    pattern: /^(50)/,
    length: [16],
    cvc: [3],
    active: false,
    dep: 0,
  },
  {
    name: 'amex',
    pattern: /^(34|37)/,
    length: [15],
    cvc: [4],
    active: true,
    dep: 30,
  },
  {
    name: 'discover',
    pattern: /^(6011|65|64[4-9]|622)/,
    length: [16],
    cvc: [3],
    active: false,
    dep: 0,
  },
  {
    name: 'soro-cred',
    pattern: /^(62789201)|(6364142)\d+$/,
    length: [16],
    cvc: [3],
    active: true,
    dep: 33,
  },
  {
    name: 'credsystem',
    pattern: /^(62802810)\d+$/,
    length: [16],
    cvc: [3],
    active: false,
    dep: 0,
  },
  {
    name: 'unionpay',
    pattern: /^(62|88[0-9]{14,17})/,
    length: [16, 17, 18, 19],
    cvc: [3],
    active: true,
    dep: 29,
  },
];

/**
 * valida se os campos foram preenchidos
 * @param {object} creditCard
 */
export const validateCreditCard = creditCard => {
  if (!creditCard.numero_cartao) {
    return { status: 'warn', message: CHANGE_NUMBER };
  }

  if (!validateCard(creditCard.numero_cartao).valid) {
    return { status: 'warn', message: INVALID_NUMBER };
  }

  if (!creditCard.portador) {
    return { status: 'warn', message: CHOOSE_NAME_CARD };
  }

  if (!creditCard.mes) {
    return { status: 'warn', message: CHOOSE_MONTH };
  }

  if (!creditCard.ano) {
    return { status: 'warn', message: CHOOSE_YEAR };
  }

  if (!creditCard.cvc) {
    return { status: 'warn', message: CHOOSE_CVC };
  }

  if (!validateCvc(creditCard.cvc)) {
    return { status: 'warn', message: INVALID_CVC };
  }

  if (!creditCard.num_parcelas) {
    return { status: 'warn', message: CHOOSE_PARCELS };
  }

  return { status: 'success', message: '' };
};

export const reducerDataPayment = data => {
  let newData = {};

  newData = {
    ...newData,
    [data.tipoPagamento.desc_tipo_pagamento]: `R$ ${numberFormat(
      data.vlr_pagamento
    )}`,
  };

  if (data.fk_tipo_pagamento === 3) {
    const dataCard = {
      Parcelamento: `${data.parcela_pagamento} X R$ ${numberFormat(
        data.vlr_pagamento / data.parcela_pagamento
      )}`,
      Bandeira: data.dependenciaTipoPagamento.desc_dependencia_tipo_pagamento,
    };

    newData = {
      ...newData,
      ...dataCard,
    };
  }

  return newData;
};

/**
 * Cria um caso de solicitação de nota fiscal
 * @param {integer} pkPedido
 * @param {integer} flag
 */
export const generateTicket = async (pkPedido, flag) => {
  try {
    return await loja.get(
      `/pedido/gerar-boleto-bkn-bank/pedido/${pkPedido}/${flag}`
    );
  } catch (error) {
    return {
      error:
        error.response.data.message || 'Ocorreu um erro ao consultar o boleto.',
    };
  }
};

/**
 * Cria um caso de solicitação de PIX
 * @param {integer} pkPedido
 * @param {integer} flag
 */
export const generatePix = async (pkPedido, flag) => {
  try {
    return await loja.get(`/pedido/gerar-pix/pedido/${pkPedido}/${flag}`);
  } catch (error) {
    return {
      error:
        error.response.data.message || 'Ocorreu um erro ao consultar o pix.',
    };
  }
};
