import {
  FETCH_FILTER,
  FETCH_FILTER_ON_CHANGE,
  CLEAR_FILTER,
  LAST_PRODUCT,
  ACTIVE_REQUEST,
  FETCH_DEPENDECIES,
  FETCH_SIMULATE_DELIVERY,
  CLEAR_SIMULATE_DELIVERY,
} from '../actions/types';
import config from '../config';

// service
import { gabarito } from '../services/filter';

const INITIAL_STATE = {
  activeRequest: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_FILTER:
      return { ...state, filter: action.payload.data, gabaritos: gabarito(action.payload.data.gabaritos) };
    case FETCH_FILTER_ON_CHANGE:
      return { ...state, filter: action.payload.data, gabaritos: gabarito(action.payload.data.gabaritos) };
    case CLEAR_FILTER:
      return { ...state, filter: undefined };
    case LAST_PRODUCT:
      return { ...state, lastProduct: action.payload };
    case ACTIVE_REQUEST:
      return { ...state, activeRequest: action.payload };
    case FETCH_DEPENDECIES:
      return { ...state, dependeciesCalc: action.payload };
    case FETCH_SIMULATE_DELIVERY:
      return { ...state, simulateDelivery: action.payload };
    case CLEAR_SIMULATE_DELIVERY:
      return { ...state, simulateDelivery: undefined };
    default:
      if (
        state.filter &&
        state.filter !== 'Not Found' &&
        state.filter.dados_produto.fk_tipo_produto === config.TYPE_BOOK
      ) {
        const initialColor =
          state.filter.atributos_selecionados.dependencias_adicionais_cores;
        const nameQuantity = config.TYPE_QUANTITY.filter(
          type => parseInt(type.pk) === initialColor
        );

        state.filter = {
          ...state.filter,
          name_quantity:
            nameQuantity.length > 0
              ? nameQuantity[0].name
              : 'paginas_preto_branco',
        };
      }
      return state;
  }
};
