import loja from '../apis/loja';

import { FETCH_PUBSITES, FETCH_FEATURED_PRODUCTS } from './types';

import config from '../config';
import { toast } from 'react-toastify';

/**
 * Busca os banners do pubsites para o carousel da Home
 */
export const fetchPubsites = () => async dispatch => {
  try {
    const response = await loja.get(`/pubsites/${config.ZONA_CAROUSEL}`);

    dispatch({ type: FETCH_PUBSITES, payload: response.data });
  } catch (error) {
    //toast.error(error.response.data.message);
  }
};

/**
 * Busca as categorias e os produtos que devem aparecer na Home
 */
export const fetchFeaturedProducts = () => async dispatch => {
  try {
    const response = await loja.get('/produtos-em-destaque');

    dispatch({ type: FETCH_FEATURED_PRODUCTS, payload: response.data });
  } catch (error) {
    toast.error(error.response.data.message);
  }
};
