import styled from 'styled-components';

/**
 * Formata o container para simulação do Cliente
 *
 * @extends {div} Tag div nativa do HTML
 */
export const SimulateContent = styled.div`
  padding: 1.5rem;
  background-color: gold;
  font-weight: 700;
  color: #0e4e96;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 9999;
`;
