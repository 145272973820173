import styled from 'styled-components';

// Variables
import { colors } from '../../styles/variables';
import { Wrapper, Label, Radio, Select, Checkbox } from '../../styles/forms';
import { Row, Col } from '../../styles/align';

/**
 * Formata o container do Pagamento
 *
 * @extends {div} Tag div Nativa do HTML
 */
export const PaymentContainer = styled.div`
  width: 100%;
`;

/**
 * Formata o Content Credit Card
 *
 * @extends {Row}
 *
 * Herda estilo para formatar o interior do Content
 * @extends {Wrapper}
 * @extends {Label}
 */
export const CreditCardStyle = styled(Row)`
  width: 100%;
  display: flex;
  gap: 14px;

  ${Wrapper} {
    width: 100%;

    ${Label} {
      padding: 0.5rem 0;
    }

    ${Select} {
      &:disabled {
        opacity: 0.4;
      }
    }

    .invalid {
      border: 1px solid ${colors.error};
    }
  }
`;

/**
 * Formata o Content  da Forma de Pagamento
 *
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do Content
 * @extends {Row}
 * @extends {Col}
 * @extends {Wrapper}
 * @extends {Label}
 * @extends {Radio}
 * @extends {colors}
 */
export const PaymentItemContent = styled.div`
  width: 100%;
  max-height: 0;
  overflow: hidden;
  background-color: ${colors.grey.light};
  display: none;

  ${Col} {
    width: 100%;
    padding: 1rem;
    align-items: center;
    margin: 1rem;
    border-radius: 5px;

    @media (max-width: 768px) {
      margin: 0;
      flex-wrap: wrap;
    }

    &.deposit {
      display: flex;
      align-items: flex-start;

      ${Row} {
        margin-bottom: 2rem;
        flex-wrap: wrap;
        ${Col} {
          padding: 0;
          flex: 1 1;

          ${Wrapper} {
            width: 100%;
            height: 100%;
            justify-content: center;

            span {
              width: 40px;
              height: 26px;
              visibility: visible;
              margin: 0 1rem;

              &::after {
                display: none;
              }

              &::before {
                display: none;
              }
            }

            ${Label} {
              height: 100%;
              padding: 1rem 1rem 1rem 5rem;
              text-align: left;
              margin-bottom: 0;
            }

            ${Radio} {
              &:checked ~ ${Label} {
                background-color: ${colors.primary};
                color: ${colors.default};
              }
            }
          }
        }
      }
    }

    ${Row} {
      ${Col} {
        width: fit-content;
        border: 1px solid ${colors.grey.lightSecondary};
        padding: 1rem;

        ${Wrapper} {
          padding: 0;
        }

        @media (max-width: 768px) {
          width: 100%;
          margin-bottom: 1rem;
        }

        div {
          &:last-of-type {
            text-align: center;

            @media (max-width: 768px) {
              text-align: left;
            }
          }
        }
      }
    }

    .titular {
      width: 100%;
      text-align: left;
      border: none;

      p {
        color: ${colors.grey.darkPrimary};
        margin-bottom: 1rem;

        strong {
          color: ${colors.grey.dark};
        }
      }
    }

    .bkn-bank {
      width: 100%;
      text-align: left;
      border: none;

      p {
        color: ${colors.grey.darkPrimary};
        margin-bottom: 1rem;

        strong {
          color: ${colors.grey.dark};
        }
      }
    }

    .pix {
      width: 100%;
      text-align: left;
      border: none;

      p {
        color: ${colors.grey.darkPrimary};
        margin-bottom: 1rem;

        strong {
          color: ${colors.grey.dark};
        }
      }
    }

    ${Wrapper} {
      width: 100%;
      display: flex;
      align-items: initial;
      width: auto;
      padding: 1rem 0 0 0;
      min-width: 200px;
    }
  }
`;

/**
 * Formata o Container do Tipo de Pagamento
 *
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Wrapper}
 * @extends {Checkbox}
 * @extends {Radio}
 * @extends {PaymentItemContent}
 * @extends {Label}
 * @extends {colors}
 */
export const PaymentItem = styled.div`
  width: 100%;
  background-color: ${colors.grey.lightPrimary};
  margin-bottom: 1rem;

  ${Wrapper} {
    width: 100%;
    padding: 1rem 0 0 0;
    height: fit-content;

    @media (max-width: 768px) {
      margin: 0;
    }

    &:nth-child(5),
    &:nth-child(6) {
      @media (max-width: 768px) {
        width: 100%;
      }
    }

    ${Checkbox},
    ${Radio} {
      &:checked {
        & ~ ${PaymentItemContent} {
          overflow: inherit;
          max-height: 100%;
          transition: max-height 400ms ease-in-out;
          display: flex;

          @media (max-width: 768px) {
            max-height: fit-content;
          }
        }
      }
    }

    ${Label} {
      margin-bottom: 1rem;
      color: ${colors.primary};
    }
  }
`;
/**
 * Formata o Item no interior do PaymentItemContent
 *
 * @extends {div} Tag div nativa do HTML
 */
export const Item = styled.div`
  width: 100%;
  margin: 1rem;

  @media (max-width: 768px) {
    flex-direction: row;
  }
`;

/**
 * Formata o Wrapper de Parcelas do Cartão
 *
 * @extends {Wrapper}
 */
export const WrapperParcels = styled(Wrapper)`
  max-width: 150px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

/**
 * Formata o Wrapper de Data do Cartão
 *
 * @extends {Wrapper}
 */
export const WrapperDate = styled(Wrapper)`
  max-width: 170px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

/**
 * Formata a Lista de Bandeiras
 *
 * @extends {Row}
 */
export const ListCards = styled(Row)`
  padding: 1rem;
  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    li {
      margin: 0.5rem;

      &.disabled {
        filter: grayscale(1);
      }

      &.active {
        filter: grayscale(0);
      }
    }
  }
`;

export const ContainerNumberCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin: 0;
    max-width: 100%;
  }
`;
