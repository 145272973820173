/**
 * Options = Duvidas Rodapé
 * @type {Object}
 */

export const duvidas = {
  list: [
    {
      desc: 'Quando recebo meu pedido?',
      a: 'https://instrucoes.fabricadolivro.com.br/quando-recebo-meu-pedido',
      target: '_blank',
      rel: 'noreferrer',
    },
    {
      desc: 'Cálculo de Lombada',
      link: '/calculo-de-lombada',
    },
  ],
};

/**
 * Options = institucional Rodapé
 * @type {Object}
 */

export const institucional = {
  list: [
    {
      desc: 'Contato',
      link: '/contato',
    },
    {
      desc: 'Formas de Pagamento',
      link: '/formas-de-pagamento',
    },
    {
      desc: 'Entrega',
      link: '/entrega',
    },
    {
      desc: 'Instruções',
      a: 'https://instrucoes.fabricadolivro.com.br',
      target: '_blank',
      rel: 'noreferrer',
    },
  ],
};

/**
 * Options = contato Rodapé
 * @type {Object}
 */

export const contato = {
  list: [
    {
      desc: 'Tel.: (41) 3333-3333',
      link: '/',
      icon: '',
    },
    {
      desc: 'contato@fabricadolivro.com.br',
      link: '/',
      icon: '',
    },
  ],
};

/**
 * Texto publicitario no Rodapé
 * @type {Array}
 */

export const texto_publicitario = [
  {
    title:
      'A melhor solução em impressão de livros, editoriais e materiais de divulgação',
    text: `<p>A <strong>Fábrica do Livro</strong> apresenta uma solução rápida, prática e com o <strong>menor preço em 
    produção de livros</strong>, revistas e diversos outros materiais editoriais, de acordo com a sua 
    necessidade. Tenha a <strong>liberdade de montar o seu material</strong> definindo desde o tipo de papel 
    para o miolo e o modelo de capa, até a quantidade de páginas e a tiragem desejada. 
    Aproveite os materiais de divulgação para alavancar ainda mais a visibilidade do seu 
    trabalho com máxima qualidade!</p>`,
  },
  {
    title: 'Impressão Sob Demanda e Gráfica Online',
    text: `<p>Tenha exatamente o que precisa e na quantidade certa. 
    Afinal, a impressão de livros, livretos, trabalhos acadêmicos, 
    manuais e demais materiais editoriais são <strong>impressos sob demanda</strong>.</p>
    <p>Além da seção editorial, a <strong>Fábrica do Livro</strong> conta com a categoria de
    Materiais de Divulgação que auxilia o autor e a editora na divulgação publicitária em
    eventos, livrarias e apresentações, <strong>produzindo materiais gráficos</strong> como cartão de visita, folder,
    marcador de página, porta copos, banners e mais. Confira!</p>`,
  },
  {
    title: 'O melhor Custo-benefício está aqui',
    text: `<p>Somente na <strong>Fábrica do Livro</strong> você encontra todos os produtos que
    precisa em um <strong>processo de compra seguro e totalmente online</strong>, com orçamento
    instantâneo e uma grande variedade de materiais. Somando as vantagens do <strong>menor prazo de
    impressão</strong>, insumos e matéria prima de primeira linha, moderno parque gráfico e uma equipe de
    atendimento qualificada, o resultado é o <strong>melhor custo-benefício do mercado editorial
    gráfico</strong> que alia qualidade, agilidade e preço justo!</p>`,
  },
];
