import loja from '../apis/loja';

import {
  FETCH_MESSAGES,
  MESSAGE_HISTORY,
  TYPE_MESSAGE,
  PAGE_MESSAGE,
  SHOW_MESSAGE,
} from './types';
import { toast } from 'react-toastify';

/**
 * Busca todas as mensagens do usuário logado
 * caso tenha parametros busca com o filtro
 */
export const fetchMessages = data => async dispatch => {
  try {
    const response = await loja.post('/mensagem/listar', data);

    dispatch({ type: FETCH_MESSAGES, payload: response.data });
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

/**
 * Busca o histórico de mensagens do caso
 */
export const fetchHistory = data => async dispatch => {
  try {
    const response = await loja.post('/mensagem/historico', data);

    dispatch({ type: MESSAGE_HISTORY, payload: response.data });
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

/**
 * status do filtro da mensagem
 */
export const type = value => {
  return {
    type: TYPE_MESSAGE,
    payload: value,
  };
};

/**
 * numero atual do paginator
 */
export const page = value => {
  return {
    type: PAGE_MESSAGE,
    payload: value,
  };
};

/**
 * Detalhe da mensagem
 */
export const show = value => {
  return {
    type: SHOW_MESSAGE,
    payload: value,
  };
};
