import React from 'react';

// styles
import {
  ContainerForm,
  ContainerCard,
  Separator,
  ContainerFooter,
} from './style';

// components
import Header from './header';
import FormHome from './form';
import Card from './card';
import Footer from './footer';

const Home = () => {
  return (
    <>
      <Header />
      <Separator />
      <ContainerForm>
        <FormHome />
      </ContainerForm>
      <ContainerCard>
        <Card />
      </ContainerCard>
      <Separator />
      <ContainerFooter>
        <Footer />
      </ContainerFooter>
      <Separator />
    </>
  );
};

export default Home;
