import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// images
import ImageNotFound from '../../assets/images/404.png';
// styles
import { StyleNotFound } from './style';
import Seo from '../../helpers/Seo';

class NotFound extends Component {
  render() {
    return (
      <>
        <Seo
          props={{
            title: 'Página não encontrada',
            meta: [
              {
                name: 'robots',
                content: 'noindex',
              },
            ],
          }}
        />
        <StyleNotFound>
          <img src={ImageNotFound} alt={''} title={'Página não encontrada'} />
        </StyleNotFound>
      </>
    );
  }
}

export default withRouter(NotFound);
