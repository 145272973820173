import styled from 'styled-components';

// Style
import { Container, Row } from '../../styles/align';
import {
  Wrapper,
  WrapperFiles,
  Label,
  Button,
  BtnGroup,
  Textarea
} from '../../styles/forms';
import { colors, fontSize } from '../../styles/variables';

/**
 * Formata o Container da página Envio
 * @extends {Container}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {BtnGroup}
 * @extends {Button}
 * @extends {colors}
 */
export const Files = styled(Container)`
  position: relative;
  overflow-x: hidden;

  ${BtnGroup} {
    padding: 1rem 0;

    @media (max-width: 768px) {
      justify-content: center;
    }

    ${Button} {
      background-color: ${colors.primary};
      color: ${colors.default};
      text-decoration: none;
    }
  }
`;

/**
 * Formata o Loading da página
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do Loading
 * @extends {colors}
 */
export const Loading = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: ${colors.default};
  opacity: 0.8;
  z-index: 100;
  pointer-events: stroke;
  left: 0;

  .loader {
    &:after {
      width: max-content;
      content: 'Enviando os arquivos...';
    }
  }
`;

/**
 * Formata o Container dos Inputs de Envio
 * @extends {Container}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Button}
 * @extends {Row}
 * @extends {Wrapper}
 * @extends {WrapperFiles}
 * @extends {Label}
 * @extends {colors}
 * @extends {fontSize}
 */
export const FilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.grey.light};
  padding: 1rem 0;
  margin-top: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2rem;

  ${Button} {
    background-color: ${colors.primary};
    color: ${colors.default};
    font-size: ${fontSize.sm};
  }

  ${Row} {
    padding: 0;
  }

  ${Wrapper} {
    width: 100%;

    ${WrapperFiles} {
      background-color: ${colors.default};
    }

    ${Label} {
      padding: 0;
      margin-bottom: 1rem;
      text-transform: uppercase;
      font-weight: 600;

      strong {
        margin-left: 0.5rem;
        color: ${colors.secondary};
      }
    }
  }

  & > div {
    align-items: flex-end;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1rem;
  }
`;

/**
 * Formata o Container de Ações [Gabaritos | Obs]
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do Container
 * @extends {colors}
 */
export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.grey.light};
  padding: 1rem;

  label {
    padding: 0;

    span {
      margin-bottom: 1rem;
      text-transform: uppercase;
      font-weight: 600;
    }

    select {
      max-width: 300px;
    }
  }

  & > div {
    padding: 1rem;

    label,
    span {
      margin-bottom: 1rem;
      color: ${colors.primary};
    }

    ${Textarea} {
      max-width: 100%;
      min-width: 100%;
      max-height: 100px;
      min-height: 100px;
      font-family: 'Open Sans', sans-serif;
      font-size: 0.875rem;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

/**
 * Formata o Bloco individual do Input de envio
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do Container
 * @extends {colors}
 */
export const File = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 49%;
  background-color: ${colors.grey.lightPrimary};
  margin-bottom: 1rem;
  padding: 1rem;

  @media (max-width: 768px) {
    margin: 0;
    max-width: 100%;
  }
`;

/**
 * Formata o Wrapper do Input de envio
 * @extends {Wrapper}
 *
 * Herda estilo para formatar o interior do Wrapper
 * @extends {colors}
 */

export const FileContainer = styled(Wrapper)`
  & > span {
    margin: 0.5rem;
    color: #000;

    strong {
      color: ${colors.primary};
    }
  }
`;
