import styled from 'styled-components';

// Styles
import { colors, borderRadius } from '../../styles/variables';

/**
 * Container Icon Cart
 *
 * @extends {div} Tag div Nativa Html
 */

export const cart = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${colors.secondary};
  border-radius: ${borderRadius};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  position: relative;

  @media (max-width: 768px) {
    display: none;
  }

  svg {
    width: 1.4rem;
    height: 1.4rem;
    .cls-1 {
      fill: ${colors.default};
    }
  }

  span {
    width: 20px;
    height: 20px;
    position: absolute;
    top: -8px;
    right: -8px;
    text-align: center;
    font-weight: bold;
    line-height: 1.6;
    background-color: ${colors.primary};
    color: ${colors.default};
    border-radius: ${borderRadius};
  }
`;
