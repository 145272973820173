/* eslint-disable no-unused-vars */
/* recebe os erros enviados pelo backend
  e retorna um objeto com os valores
  existentes no state do componente que chamou
*/
export const mapErrors = (errors = null) => {
  let obj = {};

  for (let [key, value] of Object.entries(errors)) {
    let str = "";
    for (let [chave, valor] of Object.entries(value)) {
      str += valor + " ";
    }
    obj = { ...obj, [key]: str };
  }

  return obj;
};
