/**
 * Lista de ordenação da pesquisa
 * @type {Array}
 */
export const order = [
  {
    value: 'menor',
    text: 'Menor Valor',
  },
  {
    value: 'maior',
    text: 'Maior Valor',
  },
  {
    value: 'alf',
    text: 'Ordem Alfabética',
  },
];
