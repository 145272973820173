import styled from 'styled-components';

// Variables
import { colors, fontSize, borderRadius } from '../../styles/variables';

export const ListStyle = styled.div`
  min-width: 25%;
  padding: 0 2rem;
  border-right: 1px solid ${colors.grey.lightPrimary};

  @media (max-width: 768px) {
    border-right: 0;
    padding: 1rem 0;
  }

  h3 {
    color: ${colors.grey.darkPrimary};
    border-bottom: 1px solid ${colors.grey.lightPrimary};
    font-size: ${fontSize.lg};
    margin-bottom: 0.5rem;
    padding-bottom: 1rem;
  }

  ul {
    font-size: ${fontSize.md};
    list-style: none;
    margin-top: 1rem;

    li {
      line-height: 1.5;
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      span {
        color: ${colors.grey.darkSecondary};
        margin-left: 0.5rem;
      }
    }

    a {
      text-decoration: none;
      color: ${colors.grey.dark};

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &:last-of-type {
    border-right: none;
  }
`;

export const FeatureStyle = styled.article`
  font-family: 'Open Sans Condensed';
  font-weight: bold;
  width: 100%;

  a {
    text-decoration: none;
  }

  &:last-of-type {
    .feature__content {
      border-right: none;
    }
  }

  .feature__content {
    display: flex;
    flex-direction: row;
    margin: 4rem 0;
    justify-content: center;
    border-right: 2px solid ${colors.primary};

    @media (max-width: 768px) {
      border: none;
    }

    div {
      max-width: 180px;
      &:first-of-type {
        width: 100px;
        height: 100px;
        background-color: ${colors.secondary};
        padding: 1rem;
        border-radius: ${borderRadius};
      }

      &:last-of-type {
        header {
          display: flex;

          h1 {
            font-size: ${fontSize.xl};
            color: ${colors.secondary};
            border-bottom: 2px solid ${colors.secondary};
            text-transform: uppercase;
            line-height: 1.2;
            padding-left: 1rem;
          }
        }

        p {
          font-weight: 700;
          color: ${colors.primary};
          font-size: ${fontSize.lg};
          margin: 0.5rem 1rem;
        }

        span {
          font-weight: 300;
          color: ${colors.primary};
          font-size: ${fontSize.md};
          margin: 0.5rem 1rem;
        }
      }
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const OptionStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  color: ${colors.grey.dark};

  a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.5s;

    &:hover {
      transform: scale(1.05);
    }
  }

  div {
    width: 70px;
    height: 70px;
    border-radius: ${borderRadius};
    background-color: ${colors.secondary};
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 3rem;
      height: 3rem;
      fill: ${colors.default};
    }
  }

  p {
    font-size: ${fontSize.sm};
    font-weight: 900;
    text-transform: uppercase;
    color: ${colors.grey.dark};
    text-align: center;
    margin-top: 1rem;
    line-height: 1.5;
  }
`;

export const ListRow = styled.ul`
  display: flex;
  flex-wrap: wrap;

  li {
    margin-right: 0.6rem;
  }
`;
