import React from 'react';

// styles
import { Col } from '../../styles/align';

const PixSlip = () => {
    return (
      <Col className={'pix'}>
        <div className={'pix'}>
          <p>
            Liberação Financeira Imediata
          </p>
          <p>
          Utilize o aplicativo do seu banco ou o caixa eletrônico para fazer a leitura do QR Code gerado
durante sua compra, a aprovação será realizada em instantes. <strong>Não é necessário o envio de comprovante.</strong>
          </p>
          <p>
          <strong>Obs.:</strong> caso haja alguma falha no envio da notificação do pagamento, será realizada uma
segunda consulta (esta feita de 15 em 15 minutos) que realizará a aprovação. A Fábrica do
Livro será informada pelo banco sobre o pagamento realizado.
          </p>
          <p>
            <strong>Você tem 48h para efetuar o pagamento, se não for identificado, seu pedido será cancelado automaticamente.</strong>
          </p>
        </div>
      </Col>
    );
  };

  export default PixSlip;
