import styled from 'styled-components';

// Styles
import { colors, fontSize, radiusInputs } from '../../styles/variables';
import {
  Wrapper,
  Label,
  BtnGroup,
  Button,
  Radio,
  ButtonSecondary,
  Textarea,
} from '../../styles/forms';
import { Container, Row } from '../../styles/align';

/**
 * Formata o Container da Pagina do Carrinho
 * @extends {Container}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {BtnGroup}
 * @extends {Button}
 * @extends {ButtonSecondary}
 */
export const CartStyle = styled(Container)`
  min-height: 400px;
  overflow-x: hidden;
  position: relative;

  ${BtnGroup} {
    padding: 1rem 0;

    a {
      text-decoration: none;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;

      ${Button} {
        min-width: 200px;
        margin-bottom: 1rem;
      }
    }

    ${ButtonSecondary} {
      max-width: 220px;
    }

    @media (max-width: 768px) {
      justify-content: center;
    }
  }
`;

/**
 * Formata o loading do Carrinho
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do loading
 * @extends {colors}
 */

export const Loading = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: ${colors.default};
  opacity: 0.8;
  z-index: 100;
  position: absolute;
  pointer-events: stroke;
  left: 0;
`;

/**
 * Formata o Container dos Items do Carrinho
 * @extends {div} Tag div nativa do HTML
 */
export const ItemsContainer = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;

/**
 * Formata o Header dos Items do Carrinho
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do Header
 * @extends {colors}
 */
export const ItemsHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: ${colors.grey.lightPrimary};

  @media (max-width: 768px) {
    display: none;
  }

  & > div {
    text-align: center;
    font-weight: 600;
    color: ${colors.primary};

    &:nth-child(1) {
      width: 30%;
    }

    &:nth-child(2) {
      width: 15%;
    }

    &:nth-child(3) {
      width: 15%;
    }

    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7) {
      width: 10%;
    }
  }
`;

/**
 * Formata o Container do Item do Carrinho
 * @extends {div} Tag div nativa do HTML
 */
export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

/**
 * Formata o Content do Item do Carrinho
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do Content
 * @extends {colors}
 */
export const ItemContent = styled.div`
  max-height: 0;
  overflow: hidden;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }

  &.active {
    max-height: 800px;
    transition: max-height 400ms ease-in-out;
  }

  &.hidden {
    max-height: 0;
    overflow: hidden;
  }

  ul {
    list-style: none;

    li {
      padding: 0 1.5rem;

      &:nth-child(odd) {
        background-color: ${colors.grey.lightPrimary};
      }

      span {
        border-left: 1px dashed ${colors.grey.darkSecondary};
        padding: 0.5rem 0;
        display: flex;
        align-items: center;

        &:before {
          content: '';
          width: 15px;
          border-bottom: 1px dashed ${colors.grey.darkSecondary};
          margin-right: 0.5rem;
        }
      }
    }
  }
`;

/**
 * Formata o Item do Carrinho
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do Item
 * @extends {colors}
 */
export const Item = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
  background-color: ${colors.grey.light};

  @media (max-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  & > div {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: ${colors.primary};
    flex-direction: column;

    .title_action {
      display: none;
    }

    @media (max-width: 768px) {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      padding: 1rem 0;
      border: 1px solid ${colors.grey.lightPrimary};
      background-color: ${colors.grey.light};

      .title_action {
        display: block;
        margin-bottom: 1rem;
      }
    }

    &:nth-child(1) {
      width: 30%;
      justify-content: flex-start;
      flex-direction: row;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }

      @media (max-width: 768px) {
        margin-bottom: 1rem;
        width: 100%;
        flex-direction: row;
        border: none;
        border-bottom: 1px solid ${colors.grey.darkSecondary};
        padding: 1rem 0;
        background-color: ${colors.grey.light};

        svg {
          width: 3rem;
          height: 3rem;
        }
      }
    }

    &:nth-child(2) {
      width: 15%;

      @media (max-width: 768px) {
        width: 50%;
      }
    }

    &:nth-child(3) {
      width: 15%;

      @media (max-width: 768px) {
        width: 50%;
      }
    }

    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7) {
      width: 10%;

      @media (max-width: 768px) {
        width: 50%;
      }
    }
  }

  svg {
    width: 1rem;
    height: 1rem;
    background-color: ${colors.grey.lightPrimary};
    fill: ${colors.error};
    cursor: pointer;
  }
`;

/**
 * Formata o Container do Delivery Options
 * @extends {Wrapper}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Radio}
 * @extends {Label}
 */
export const WrapperOptionDelivery = styled(Wrapper)`
  border: 1px solid ${colors.grey.darkSecondary};
  border-radius: ${radiusInputs};
  margin-bottom: 1rem;
  justify-content: center;

  ${Radio} {
    & ~ span {
      margin: 0;
    }
  }

  ${Label} {
    display: flex;
    padding: 0 0 0 3rem;
    background-color: transparent;

    span {
      width: 33%;
    }
  }
`;

/**
 * Formata o Container do Delivery
 * @extends {Wrapper}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Radio}
 * @extends {Label}
 */

export const DeliveryContainer = styled.div`
  min-width: 65%;
  padding-right: 1rem;

  @media (max-width: 768px) {
    padding-right: 0;
    min-width: 100%;
    margin-bottom: 1rem;
  }

  h3 {
    color: ${colors.primary};
  }

  ${BtnGroup} {
    justify-content: flex-start;

    ${Button} {
      display: flex;
      max-width: fit-content;
      align-items: center;
      background-color: ${colors.secondary};
      color: ${colors.default};
      margin-left: 0;

      svg {
        fill: ${colors.default};
        margin-right: 0.5rem;
      }
    }
  }

  label {
    background-color: transparent;
    padding: 1rem;
  }
`;

/**
 * Formata o Bloco do Endereço padrão
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do Bloco
 * @extends {BtnGroup}
 * @extends {Button}
 * @extends {fontSize}
 * @extends {colors}
 */

export const DefaultAddress = styled.div`
  width: 40%;
  display: block;
  margin: 2rem;

  @media (max-width: 768px) {
    width: 100%;
  }

  h3 {
    font-size: ${fontSize.lg};
    color: ${colors.primary};
    margin-bottom: 1rem;
  }

  strong {
    color: ${colors.primary};
    margin-bottom: 1rem;
  }

  p {
    color: ${colors.primary};
    line-height: 1.5;
  }

  ${BtnGroup} {
    padding: 1rem 0;
    justify-content: flex-start;
    flex-direction: row;

    ${Button} {
      max-width: max-content;
      background-color: ${colors.secondary};
      color: ${colors.default};
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-of-type {
        margin-left: 0;
      }

      svg {
        fill: ${colors.default};
      }
    }
  }
`;

/**
 * Formata o Bloco de opções de Entrega [Delivery | Balcony]
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do Bloco
 * @extends {Wrapper}
 * @extends {Radio}
 * @extends {Label}
 * @extends {WrapperOptionDelivery}
 * @extends {colors}
 */
export const DeliveryContent = styled.div`
  ${Wrapper} {
    padding: 1rem;
    background-color: ${colors.grey.lightPrimary};

    ${Radio} {
      & ~ span {
        margin: 0;
      }
    }

    ${Label} {
      padding: 0 0 0 2rem;
    }
  }

  ${WrapperOptionDelivery} {
    padding: 0.6rem;
    background-color: transparent;
  }

  .content__wrapper {
    overflow: hidden;
    padding: 0;
  }

  &.active {
    & > label {
      background-color: ${colors.primary};

      span {
        color: ${colors.default};
      }
    }

    .content__wrapper {
      max-height: 800px;
      transition: max-height 400ms ease-in-out;
    }

    input[type='radio']:checked {
      &:after {
        background-color: ${colors.secondary};
      }
    }
  }

  &.hidden {
    .content__wrapper {
      overflow: hidden;
      max-height: 0;
      padding: 0;
    }
  }
`;

/**
 * Formata o Bloco de opções de Entrega do Delivery [Sedex | Pac]
 * @extends {div} Tag div nativa do HTML
 */
export const DeliveryOptions = styled.div`
  width: 50%;
  margin: 2rem;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

/**
 * Formata o Header do Bloco de opções de Entrega do Delivery [Sedex | Pac]
 * @extends {div} Tag div nativa do HTML
 * 
 * Herda estilo para formatar o interior do Bloco
 * @extends {colors}
 */
export const OptionDeliveryHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 0.7rem 0 2.6rem;
  margin-bottom: 1rem;

  span {
    font-weight: 600;
    color: ${colors.primary};
    width: 33%;
  }
`;

/**
 * Formata o container que lista os balcoes no carrinho
 * @extends {div} Tag div nativa do HTML
 * 
 * Herda estilo para formatar o interior do container
 * @extends {colors}
 */
export const ListBalconyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${colors.grey.light};
`;

/**
 * Formata o Form de busca dos balcoes
 * @extends {div} Tag div nativa do HTML
 * 
 * Herda estilo para formatar o interior do Form
 * @extends {Wrapper}
 * @extends {Label}
 * @extends {colors}
 * 
 */
export const ListBalconyForm = styled.div`
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: ${colors.grey.light};

  @media (max-width: 768px) {
    flex-direction: column;
  }

  ${Wrapper} {
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    ${Label} {
      padding: 0 0 0.5rem 0;
    }

    &:nth-child(3) {
      align-items: flex-start;
      justify-content: flex-end;
      span {
        margin: 0;
      }

      ${Label} {
        padding: 0 0 0 2rem;
      }
    }
  }
`;

/**
 * Formata o Content do Container Lista Balcoes
 * @extends {div} Tag div nativa do HTML
 * 
 */
export const ListBalconyContent = styled.div`
  margin: 2rem;
  height: 300px;
  overflow: auto;
`;

/**
 * Formata o Item Balcão
 * @extends {div} Tag div nativa do HTML
 * 
 * Herda estilo para formatar o interior do Item
 * @extends {colors}
 */
export const Balcony = styled.div`
  background-color: ${colors.grey.lightPrimary};
  margin-bottom: 1rem;

  label {
    display: flex;
    align-items: center;

    & > span {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      svg {
        fill: ${colors.grey.lightSecondary};
      }
    }
  }
`;

/**
 * Formata o Conteudo do Item Balcão
 * @extends {div} Tag div nativa do HTML
 * 
 * Herda estilo para formatar o interior do Conteudo
 * @extends {colors}
 */
export const BalconyContent = styled.div`
  color: ${colors.primary};
  padding: 1rem;

  p,
  strong {
    line-height: 1.5;
  }
`;

/**
 * Formata o Titulo do Item Balcão
 * @extends {div} Tag div nativa do HTML
 * 
 * Herda estilo para formatar o interior do Titulo
 * @extends {colors}
 */
export const BalconyTitle = styled(Label)`
  background-color: transparent;
  align-items: flex-start;
  margin-bottom: 1rem;
  padding-left: 2rem;

  p {
    min-width: fit-content;
    margin-right: 1rem;
  }

  span {
    color: ${colors.secondary};
    font-weight: 700;
  }
`;

/**
 * Formata o Blocos de Ações do Carrinho [Cupom | Nome do Pedido]
 * @extends {div} Tag div nativa do HTML
 * 
 * Herda estilo para formatar o interior do Titulo
 * @extends {Row}
 * @extends {Button}
 * @extends {fontSize}
 * @extends {colors}
 * @extends {CardAction}
 */
export const CardAction = styled.div`
  padding: 1rem;
  background-color: ${colors.grey.light};
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

         span {
           min-width: 140px;
           margin-right: 1rem;
           font-weight: 700;
           color: ${colors.primary};
         }

         ${Label} {
             width: 100%;
             margin-right: 1rem;
             font-weight: 700;
             color: ${colors.primary};
           }

         ${Wrapper} {
            width: 100%;
         }

  ${Row} {
    align-items: center;

    p {
      color: red;
      font-weight: 700;
      font-size: ${fontSize.sm};
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    span {
      min-width: 100%;
      margin-bottom: 1rem;
    }
  }

  ${Button} {
    min-width: 80px;
    font-size: ${fontSize.sm};
  }
       `;


/**
 * Formata o Container da Modal de Informações de Nota Fiscal
 * @extends {div} Tag div nativa do HTML
 * 
 * Herda estilo para formatar o interior do Container
 * @extends {BtnGroup}
 * @extends {fontSize}
 * @extends {colors}
 * @extends {Checkbox}
 */
export const ModalCard = styled.div`
  padding: 1rem;
  width: 100%;
  background-color: ${colors.grey.light};
  display: flex;

  ${BtnGroup} {
    width: 100%;
  }

  ${Textarea} {
      font-family: 'Open Sans', sans-serif;
      height: 105px;
      font-size: 1rem;
      color: ${colors.grey.darkPrimary};
    }
  
  
`;
