import styled from 'styled-components';

// Style
import { Container, Col, Row } from '../../styles/align';
import { colors, fontSize } from '../../styles/variables';

/**
 * Formata o Container da Página de Pagamento
 * @extends {Container}
 *
 */
export const PaymentStyle = styled(Container)`
  flex-direction: column;
`;

/**
 * Formata o Container do Tipo de Pagamento
 * @extends {Col}
 *
 */
export const PaymentContainer = styled(Col)`
  margin-bottom: 1rem;
`;

/**
 * Formata o Header do Tipo de Pagamento
 * @extends {Row}
 *
 * Herda estilo para formatar o interior do Header
 * @extends {Col}
 * @extends {colors}
 *
 */
export const PaymentHeader = styled(Row)`
  background-color: ${colors.grey.light};
  padding: 1rem;

  ${Col} {
    &:first-of-type {
      width: 40%;
      color: ${colors.secondary};

      @media (max-width: 768px) {
        padding-left: 2rem;
        width: 100%;
        margin-bottom: 1rem;
      }
    }

    &:last-of-type {
      span {
        font-weight: bold;
        margin-bottom: 1rem;
      }

      @media (max-width: 768px) {
        padding-left: 2rem;
      }

      p {
        font-weight: 700;
        color: ${colors.grey.darkPrimary};
        line-height: 1.3;
        margin-top: 0.5rem;

        strong {
          color: #000;
        }
      }
    }
  }
`;

/**
 * Formata a descrição do tipo pagamento
 * @extends {Div} nativo do HTML
 *
 */
export const ContentTypePayment = styled.div`
  display: flex;
  border-radius: none;
  flex-direction: column;
  width: 200px;

  @media (max-width: 768px) {
    text-align: center;
  }

  p {
    line-height: 1.5;
  }
`;

/**
 * Formata o Conteudo do Tipo de Pagamento
 * @extends {Row}
 *
 * Herda estilo para formatar o interior do Conteudo
 * @extends {fontSize}
 * @extends {colors}
 *
 */
export const PaymentContent = styled(Row)`
  padding: 0.5rem;

  border-left: 1px dashed ${colors.secondary};
  border-bottom: 1px dashed ${colors.secondary};
  border-right: 1px dashed ${colors.secondary};

  ul {
    list-style: none;
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    li {
      width: 50%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 2rem;

      @media (max-width: 768px) {
        width: 100%;
        flex-direction: column;

        div {
          margin-bottom: 2rem;
        }
      }

      p {
        font-size: ${fontSize.md};
        font-weight: 700;
        color: ${colors.primary};
        margin-right: 1rem;
        max-width: 200px;
      }

      span {
        color: ${colors.grey.darkSecondary};

        p {
          margin-bottom: 0.5rem;
          &:last-of-type {
            font-weight: 400;
          }
        }
      }
    }
  }
`;

export const IconTypePayment = styled.div`
  width: 60px;
  height: 60px;
  box-shadow: 1px 1px 1px ${colors.grey.dark};
  border-radius: 50%;
  margin-right: 1rem;
  overflow: hidden;
  svg {
    width: 100%;
    height: 100%;
  }
`;

/**
 * Formatação especifica para o tipo Credito
 * @extends {PaymentContent}
 *
 * Herda estilo para formatar o interior
 * @extends {colors}
 *
 */

export const PaymentContentCredit = styled(PaymentContent)`
  svg {
    padding: 0.5rem;
    box-shadow: 1px 1px 1px ${colors.grey.dark};
    border-radius: 50%;
    margin-right: 1rem;
  }
`;

/**
 * Formatação especifica para o tipo Deposito
 * @extends {PaymentContent}
 *
 * Herda estilo para formatar o interior
 * @extends {colors}
 *
 */
export const PaymentContentDeposit = styled(PaymentContent)`
  svg {
    width: 5rem;
    height: auto;
    box-shadow: 1px 1px 1px ${colors.grey.dark};
    border-radius: 50%;
    margin-right: 1rem;
  }
`;
