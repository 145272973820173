import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, submit } from 'redux-form';

// Node
import { toast } from 'react-toastify';

// api
import loja from '../../apis/loja';

// hocs
import Modal from '../../hocs/Modal';

// Actions
import { isCreateOnline, isSubmitForm } from '../../actions/files';
import {
  modalEditor,
  modalLogin,
  modalOrderErrors,
  modalRegister,
  toggleRecovery,
} from '../../actions/modals';
import { emailRegister, signIn } from '../../actions/user';

// Validate
import { email as emailValidate, required } from '../../formValidate';
import { mapErrors } from '../../formValidate/handleErrors';
import renderField from '../../formValidate/renderField';

// services
import { verifyOrderErrors } from '../../services/order';
import { login as loginService } from '../../services/user';

// utils
import {
  CONNECTION_ERROR,
  EMAIL_NEW_PASS,
  ERROR,
  FINALIZE_REGISTER,
  FORM_SUBMIT_BLANK,
  LOGIN_SUCCESS
} from '../../utils/constMessage';

// Styles
import { Row } from '../../styles/align';
import { BtnGroup, Button, Label, Wrapper } from '../../styles/forms';
import { TitleCards } from '../../styles/titles';
import {
  LoginContent,
  LoginRecoverContent,
  LoginRegisterContent,
  LoginStyle,
} from './style';

class Login extends Component {
  state = {
    login: '',
    senha: '',
    email_usuario: '',
    recuperar_login: '',
    showRegisterModal: false,
    errors: {
      login: '',
      senha: '',
      email_usuario: '',
      recuperar_login: '',
    },
  };

  componentWillUnmount() {
    this.props.isSubmitForm(false);
  }

  toggleRegister = email_usuario => {
    let newState = {
      isOpenLogin: false,
      isOpenRegister: !this.state.isOpenRegister,
    };

    if (typeof email_usuario === 'string') {
      newState = { ...newState, email_usuario };
    }
    this.setState(newState);
  };

  handleErrors = response => {
    if (response.errosValidacao) {
      const errors = mapErrors(response.errosValidacao);
      this.setState({ errors });
    }

    if (response.message) {
      toast.warn(response.message);
    } else {
      toast.error(ERROR);
    }
  };

  handleSignIn = e => {
    e.preventDefault();
    const { login, senha } = this.state;

    if (login === '' || senha === '') {
      toast.warn(FORM_SUBMIT_BLANK);
      return false;
    }
    // salva o login na sessão e fecha a modal
    loginService(login, senha).then(response => {
      const { dispatch, isSubmitFiles, CreateOnline } = this.props;
      if (response.status === 200) {
        this.props.signIn(response.data.data);
         //window.location.reload()

        verifyOrderErrors().then(response => {
          if (response.status === 200) {
            if (
              window.location.pathname !== '/envio' &&
              (response.data.data.aprovacao || response.data.data.problema)
            ) {
              this.props.modalOrderErrors(response.data.data);
            }
          } else {
            toast.error(response.data.message);
          }
        });

        this.props.modalLogin();
        
        if(this.props.componentName !== 'SendFiles'){
          setTimeout(() => {
          if (
            response.data.data.user_data.consumidor_final === null || 
              response.data.data.user_data.icms_st === null || 
              response.data.data.industrializacao === null || 
              response.data.data.user_data.comercializacao === null
            ) {

            toast.warn(FINALIZE_REGISTER);
            this.props.modalRegister();

          } else {
            toast.success(LOGIN_SUCCESS);
          }
        }, 1500);
        }

        setTimeout(() => {
          if (isSubmitFiles) {
            dispatch(submit('sendFiles'));
          }

          if (CreateOnline) {
            this.props.modalEditor();
            dispatch(submit('createOnline'));
          }
        }, 1500);
      } else if (response.errors) {
        this.handleErrors(response.errors);
      } else {
        toast.error(CONNECTION_ERROR);
      }
    });
  };

  handleRegister = async e => {
    e.preventDefault();
    const { email_usuario } = this.state;
    if (email_usuario === '') {
      toast.warn('O campo email não pode ser vazio');
      return false;
    }

    const resultValidate = emailValidate(email_usuario);

    if (resultValidate === undefined) {
      try {
        await loja.post('/usuario/verificar-email', {
          email_usuario,
        });
        this.props.emailRegister(email_usuario);
        this.props.modalRegister();
      } catch (error) {
        if (error.response) {
          this.handleErrors(error.response.data);
        } else {
          toast.error(CONNECTION_ERROR);
        }
      }
    } else {
      toast.warn('Insira um email válido');
    }
  };

  handleRecoverSubmit = async e => {
    e.preventDefault();
    const { recuperar_login } = this.state;

    if (recuperar_login === '') {
      toast.warn(FORM_SUBMIT_BLANK);
      return false;
    }

    try {
      await loja.post('/recuperar-senha', {
        login: recuperar_login,
      });
      // fecha a modal e mostra um toast com a mensagem de sucesso
      this.props.modalLogin();
      toast.success(EMAIL_NEW_PASS);
    } catch (error) {
      if (error.response) {
        // como esse caso só tem o login de campo no formulário
        // foi tratado individualmente por ter dois campos login
        // sendo necessário diferenciar por "login" e "recuperar_login"
        if (error.response.data.errosValidacao) {
          const errors = {
            recuperar_login: error.response.data.errosValidacao.login,
          };
          this.setState({ errors });
        }

        if (error.response.data.message) {
          toast.warn(error.response.data.message);
        } else {
          toast.error(ERROR);
        }
      } else {
        toast.error(CONNECTION_ERROR);
      }
    }
  };

  renderLogin = () => {
    return (
      <LoginContent>
        <form onSubmit={this.handleSignIn}>
          <TitleCards>
            <span>{'Já Tenho Cadastro'}</span>
          </TitleCards>
          <Wrapper>
            <Label htmlFor={'login'}>E-mail, CPF ou CNPJ</Label>
            <Field
              name={'login'}
              type={'text'}
              id={'login'}
              component={renderField}
              placeholder={'E-mail ou Apenas números'}
              validate={[required]}
              onChange={e => this.setState({ login: e.target.value })}
              responseError={this.state.errors.login}
              onFocus={e => this.setState({ errors: { login: '' } })}
            />
          </Wrapper>
          <Wrapper>
            <Label htmlFor={'senha'}>Senha</Label>
            <Field
              name={'senha_usuario'}
              type={'password'}
              id={'senha'}
              component={renderField}
              placeholder={'*******'}
              validate={required}
              onChange={e => this.setState({ senha: e.target.value })}
              responseError={this.state.errors.senha}
              onFocus={e => this.setState({ errors: { senha: '' } })}
            />
          </Wrapper>
          <BtnGroup>
            <Button type="submit">Entrar</Button>
            <span onClick={this.props.toggleRecovery}>Esqueci Minha Senha</span>
          </BtnGroup>
        </form>
      </LoginContent>
    );
  };

  renderCadastro = () => {
    return (
      <LoginRegisterContent>
        <form onSubmit={this.handleRegister}>
          <TitleCards>
            <span>{'Não Tenho Cadastro'}</span>
          </TitleCards>
          <p>
            Efetue seu cadastro clicando no botão abaixo e aproveite todas as
            vantagens de imprimir seu material com qualidade na Fábrica do
            Livro.
          </p>
          <Wrapper>
            <Label htmlFor={'email'}>E-mail</Label>
            <Field
              name={'email_usuario'}
              type={'email'}
              id={'email'}
              component={renderField}
              validate={[required, emailValidate]}
              onChange={e => this.setState({ email_usuario: e.target.value })}
              placeholder={'E-mail'}
              responseError={this.state.errors.email_usuario}
              onFocus={e => this.setState({ errors: { email_usuario: '' } })}
            />
          </Wrapper>
          <BtnGroup>
            <Button secondary margin type="submit">
              Cadastre-se Aqui!
            </Button>
            <p onClick={this.props.modalLogin}>Cancelar</p>
          </BtnGroup>
        </form>
      </LoginRegisterContent>
    );
  };

  renderRecover = () => {
    return (
      <LoginRecoverContent>
        <TitleCards>
          <span>{'Esqueci minha Senha'}</span>
        </TitleCards>
        <Row>
          <Wrapper>
            <Label htmlFor={'recuperar'}>E-mail, CPF ou CNPJ</Label>
            <Field
              dark
              id={'recuperar'}
              name={'recuperar_login'}
              type={'text'}
              component={renderField}
              placeholder={'E-mail ou Apenas números'}
              validate={[required]}
              onChange={e => this.setState({ recuperar_login: e.target.value })}
              responseError={this.state.errors.recuperar_login}
              onFocus={e => this.setState({ errors: { recuperar_login: '' } })}
            />
          </Wrapper>
          <BtnGroup>
            <Button
              maxwidth="max-content"
              primary
              onClick={this.handleRecoverSubmit}
            >
              Recuperar a Senha
            </Button>
          </BtnGroup>
        </Row>
      </LoginRecoverContent>
    );
  };

  render() {
    return (
      <>
        <LoginStyle>
          <Row>
            {this.renderLogin()}
            {this.renderCadastro()}
          </Row>
          {this.props.recovery ? this.renderRecover() : ''}
        </LoginStyle>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    recovery: state.modals.modalLoginRecovery,
    isSubmitFiles: state.files.isSubmitForm,
    CreateOnline: state.files.isCreateOnline,
  };
};

Login = connect(mapStateToProps, {
  signIn,
  emailRegister,
  modalLogin,
  toggleRecovery,
  modalOrderErrors,
  modalRegister,
  modalEditor,
  isSubmitForm,
  isCreateOnline,
})(Login);

Login = reduxForm({
  form: 'loginRegister',
})(Login);

Login = Modal(Login);

export default Login;
