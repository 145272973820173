import { FETCH_CATEGORY } from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_CATEGORY:
      if (action.payload.data === 404) {
        return {
          ...state,
          [action.payload.menu]: action.payload.data
        };
      }
      return {
        ...state,
        [action.payload.data.menu]: action.payload.data
      };
    default:
      return state;
  }
};
