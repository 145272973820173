import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchPrivacyUser, clearPrivacyUser } from '../../actions/user';

import Modal from '../../hocs/Modal';

// actions
import { modalPrivacy } from '../../actions/modals';

// components
import Form from './form';

let Privacy = props => {
  useEffect(() => {
    props.fetchPrivacyUser();
    return () => {
      props.clearPrivacyUser();
    };
    // eslint-disable-next-line
  }, []);

  return <Form />;
};

Privacy = connect(null, {
  fetchPrivacyUser,
  clearPrivacyUser,
  modalPrivacy,
})(Privacy);

Privacy = Modal(Privacy);

export default Privacy;
