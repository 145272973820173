import styled from "styled-components";

// styles
import { colors } from "../../styles/variables";
import { Row } from "../../styles/align";
import { Wrapper, Label, BtnGroup, Button } from "../../styles/forms";
import { Container } from "../style";

/**
 * Formata o Container da Modal Contato de Entrega
 * @extends {Container}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Label}
 * @extends {Row}
 * @extends {Wrapper}
 * @extends {BtnGroup}
 * @extends {Button}
 * @extends {colors}
 */

export const StyleShippingContact = styled(Container)`
  p {
    font-weight: 700;
    color: ${colors.grey.darkPrimary};
    line-height: 1.3;
    margin-top: 0.5rem;
  }

  ${Label} {
    strong {
      color: ${colors.secondary};
    }
  }

  form {
    background-color: ${colors.grey.lightPrimary};
    padding: 1rem;

    ${Row} {
      ${Wrapper} {
        width: 100%;
        padding: 0 1rem;
        flex-direction: column;
        gap: 14px;

        ${Label} {
          padding: 0;
        }
        .text-error {
          & p {
              margin: 1.2rem;
          }
        }
      }

    @media (max-width: 1200px) {
      flex-direction: column;
      gap: 14px;
    }

      padding: 1rem 0;
    }
  }

  ${BtnGroup} {
    padding: 1rem 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    span {
      display: flex;
      flex-direction: row;
      color: ${colors.secondary};

      ${Button} {
        &:last-of-type {
          background-color: ${colors.primary};
          color: ${colors.default};
          text-decoration: none;
        }
      }
    }
  }
`;
