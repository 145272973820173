import React from 'react';

// Node
import Icon from '@mdi/react';
import { mdiCheckCircleOutline } from '@mdi/js';

// services
import { formatCalendar } from '../../services/order';

// style
import * as Style from './style';

const CalendarDelivery = ({ date, status }) => {
  const dateCalendar = formatCalendar(date);

  return (
    <Style.Container>
      <Style.Calendar className={status === 22 ? 'entregue' : ''}>
        <Style.Wireo>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </Style.Wireo>
        <Style.Content>
          {status === 22 ? (
            <>
              <p>Entregue</p>
              <Icon
                path={mdiCheckCircleOutline}
                size={3}
                title={'Produto Entregue'}
              />
            </>
          ) : (
            <>
              <Style.DayDesc>{dateCalendar.dayWeek}</Style.DayDesc>
              <Style.Day>{dateCalendar.day}</Style.Day>
              <Style.Month>{dateCalendar.month}</Style.Month>
            </>
          )}
        </Style.Content>
      </Style.Calendar>
    </Style.Container>
  );
};

export default CalendarDelivery;
