import loja from '../apis/loja';

export const createMessage = async data => {
  try {
    const response = await loja.post('/usuario/mensagem-nova', {
      ...data,
    });

    return response;
  } catch (error) {
    if (error.response) {
      return { errors: error.response.data };
    }
    return { error: 'CONNECTION_ERROR' };
  }
};

/**
 * formata os dados para fazer o submit das mensagens
 * @param {object} data
 */
export const formatSubmit = data => {
  let dataSubmit = {
    ...data,
    where: {},
  };
  if (dataSubmit.data_ini) {
    dataSubmit.where.dataIni = dataSubmit.data_ini;
  }
  if (dataSubmit.data_fim) {
    dataSubmit.where.dataFim = dataSubmit.data_fim;
  }
  if (dataSubmit.numero_pedido) {
    dataSubmit.where.id = dataSubmit.numero_pedido;
  }

  return dataSubmit;
};

/**
 * Envia uma nova mensagem/reabre o caso
 * @param {object} data
 */
export const sendNewMessage = async ({
  fk_pedido = null,
  fk_caso = null,
  mensagem,
}) => {
  try {
    return await loja.post('/mensagem/nova', {
      fk_pedido,
      fk_caso,
      mensagem,
    });
  } catch (error) {
    return { error: error.response.data.message };
  }
};

/**
 * Envia o feedback do atendimento
 * @param {object} data
 */
export const sendFeedback = async (id, rating, mensagem = null) => {
  try {
    return await loja.post('/mensagem/feedback', {
      id,
      rating,
      mensagem,
    });
  } catch (error) {
    return { error: error.response.data.message };
  }
};

/**
 * Cria um caso de solicitação de nota fiscal
 * @param {object} data
 */
export const requestReceipt = async (pkPedido) => {
  try {
    return await loja.post('/pedido/solicitar-nota-fiscal', {
      pkPedido,
    });
  } catch (error) {
    return { error: error.response.data.message };
  }
};
