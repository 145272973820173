import storage from '../utils/Storage';
import { INFO_EDITOR, HASH_JOB } from '../utils/Storage/types';

import loja from '../apis/loja';
import { toast } from 'react-toastify';

// recupera as inforamções do editor da sessão
export const getInfoEditor = () => {
  try {
    return storage.get(INFO_EDITOR);
  } catch (exception) {
    localStorage.removeItem(INFO_EDITOR);
    return null;
  }
};

// insere as informações do editor em sessão
export const setInfoEditor = token => storage.set(INFO_EDITOR, token);

// insere o hash job em sessão
export const setHashJob = hash => storage.set(HASH_JOB, hash);

// recebe o hash job em sessão
export const getHashJob = () => storage.get(HASH_JOB);

// remove o hash job em sessão
export const removeHashJob = () => localStorage.removeItem(HASH_JOB);

// Dispara o Init do Editor para a API da Loja
export const init = async () => {
  try {
    const infoEditor = getInfoEditor();
    const response = await loja.post('/editor/init', { infoEditor });

    setInfoEditor(response.data.data);

    return response;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};
