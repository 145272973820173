import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Field, reduxForm } from 'redux-form';
import _ from 'lodash';

// actions
import { simulateDelivery, clearSimulateDelivery } from '../../actions/filter';
import { modalBalcony } from '../../actions/modals';
import { fetchStates, fetchCities } from '../../actions/address';
// Validate
import renderField from '../../formValidate/renderField';
import { cep } from '../../formValidate/masks';
import { required } from '../../formValidate';

// Services
import { addProduct } from '../../services/filter';

// Styles
import * as Style from './style';
import { Wrapper, Label, ButtonPrimary } from '../../styles/forms';
import { numberFormat } from '../../utils';
import LoaderForm from '../Loader/loaderForm';

export let SimulateDelivery = ({
  handleSubmit,
  submitting,
  filter,
  pk_product,
  simulateDelivery,
  clearSimulateDelivery,
  simulateDeliveryData,
  modalBalcony,
  fetchStates,
  fetchCities,
}) => {
  useEffect(() => {
    clearSimulateDelivery();
  }, [clearSimulateDelivery]);

  /**
   * Submit form simular frete
   * @param {Object} data [form redux form]
   */
  const submit = async data => {
    const product = await reducerData(filter, pk_product);
    data = { ...data, cep: data.cep.replace(/[^\d]+/g, '') };
    data = { ...data, ...product };

    await simulateDelivery(data);
  };

  /**
   * Reducer return data product
   * @param {*} data
   * @param {*} pk_product
   */
  const reducerData = async (data, pk_product) => {
    data = { ...data, useData: true };
    let product = await addProduct(data, pk_product);
    product = _.omitBy(product, _.isNil);

    return product;
  };

  /**
   * Tratamento da Modal Balcoes
   * Busca Estados
   * Busca Cidades
   * reducer data product
   */
  const renderListBalconies = async () => {
    const { pk_estado, pk_cidade } = simulateDeliveryData.data;
    const product = await reducerData(filter, pk_product);
    const data = {
      ...product,
      pk_estado,
      pk_cidade,
      frete_gratis: 0,
    };
    await fetchStates();
    await fetchCities(pk_estado, 1);
    modalBalcony(data);
  };

  /**
   * Render lista de opções de entrega
   * @param {Object} simulateDeliveryData
   */
  const renderDataDelivery = simulateDeliveryData => {
    if (!simulateDeliveryData) {
      return '';
    }

    return (
      <React.Fragment>
        <Style.ListSimulateDelivery>
          {simulateDeliveryData.data.opcoesEntrega.map((item, i) => {
            return (
              <li key={i}>
                <Style.Type>
                  <Style.Desc>{item.tipo}</Style.Desc>({item.tempo})
                </Style.Type>
                <Style.Value>R${numberFormat(item.valor)}</Style.Value>
              </li>
            );
          })}
          {simulateDeliveryData.data.menor_preco_balcao ||
          simulateDeliveryData.data.menor_preco_balcao === 0 ?
            <li>
              <Style.Type>
                <Style.Desc>Balcão</Style.Desc>
              </Style.Type>
              <Style.Value>
                {simulateDeliveryData.data.menor_preco_balcao === 0
                  ? 'Com opções de Frete Grátis'
                  : `A partir de R$ ${numberFormat(
                      simulateDeliveryData.data.menor_preco_balcao
                    )}`}
                <ButtonPrimary
                  type={'button'}
                  onClick={() => renderListBalconies()}
                >
                  Ver
                </ButtonPrimary>
              </Style.Value>
            </li>
          : ''}
        </Style.ListSimulateDelivery>
        <Style.Alert>
          Estimativa de frete para este produto. Pode sofrer alterações na
          finalização do pedido.
        </Style.Alert>
      </React.Fragment>
    );
  };

  return (
    <Style.Simulate>
      <Form onSubmit={handleSubmit(submit)}>
        <Wrapper>
          <Label>Simule seu Frete</Label>
          <Field
            name={'cep'}
            type={'mask'}
            typefield={'mask'}
            component={renderField}
            placeholder={'Digite o seu CEP'}
            mask={cep}
            validate={[required]}
          />
          <ButtonPrimary type={'submit'}>
            Ok{submitting ? <LoaderForm /> : ''}
          </ButtonPrimary>
        </Wrapper>
      </Form>
      {renderDataDelivery(simulateDeliveryData)}
    </Style.Simulate>
  );
};

const mapStateToProps = state => ({
  filter: state.form.filterProductsOnChange.values,
  pk_product: state.filter.filter.dados_produto.pk_produto,
  simulateDeliveryData: state.filter.simulateDelivery,
});

const mapDispatchToProps = {
  simulateDelivery,
  clearSimulateDelivery,
  modalBalcony,
  fetchStates,
  fetchCities,
};

SimulateDelivery = reduxForm({ form: 'simulateDelivery' })(SimulateDelivery);

export default connect(mapStateToProps, mapDispatchToProps)(SimulateDelivery);
