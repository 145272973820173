import styled from 'styled-components';

// styles
import { colors } from '../../styles/variables';
import { Col } from '../../styles/align';
import { Label, Textarea, BtnGroup, Button } from '../../styles/forms';
import { Container } from '../style';

/**
 * Formata o Container da Modal de Cancelamento
 *
 * @extends {Container}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Col}
 * @extends {Label}
 * @extends {Textarea}
 * @extends {BtnGroup}
 * @extends {Button}
 * @extends {colors}
 */

export const StyleAuthProd = styled(Container)`
  background-color: ${colors.grey.lightPrimary};
  ${Col} {
    padding: 1rem;

    ${Label} {
      padding: 1rem 0;
      color: ${colors.primary};
      font-weight: 700;
    }

    ${Textarea} {
      min-height: 10rem;
    }
  }

  ${BtnGroup} {
    padding: 1rem;
    ${Button} {
      &:last-of-type {
        background-color: ${colors.primary};
        color: ${colors.default};
        text-decoration: none;
      }
    }
  }
`;
