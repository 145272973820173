import React from 'react';

// styles
import { Logo as LogoPage, StyleLogo } from '../Home/style';
import { Container, Cupom, H2, Obrigado } from './style';

// images
import ImageLogo from '../../../assets/images/logo.png';

const Header = () => {
  return (
    <>
      <Container>
        <LogoPage>
          <StyleLogo
            src={ImageLogo}
            alt={'Fábrica do Livro'}
            title={'Fábrica do Livro'}
          />
        </LogoPage>
        <Obrigado>
          <H2>Obrigado!</H2>
          <p>
            Você acaba de ganhar <strong>5 % OFF</strong> em sua primeira
            compra.
          </p>
          <Cupom>
            <h1>FDLCINCOOFF</h1>
          </Cupom>
        </Obrigado>
      </Container>
    </>
  );
};

export default Header;
