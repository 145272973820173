import styled from 'styled-components';

// Styles
import { Row, Col } from '../../styles/align';
import { colors, fontSize } from '../../styles/variables';

/**
 * Formata o Container do footer
 *
 * @extends {footer} Tag footer nativa do HTML
 *
 * Herda estilo para formatar o interior do Container
 * @extends {colors}
 */

export const StyleFooter = styled.footer`
  display: flex;
  padding: 3rem 0 0 0;
  background-color: ${colors.default};
  flex-direction: column;

  & > div {
    flex-direction: row;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    &:first-of-type {
      margin-bottom: 3rem;
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
  }
`;

/**
 * Formata o Container do Texto publicitario no Rodapé
 *
 * @extends {Row}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Col}
 * @extends {fontSize}
 * @extends {colors}
 */

export const ContainerText = styled(Row)`
  margin-top: 2rem;
  padding: 2rem 0;
  background-color: ${colors.grey.light};

  ${Col} {
    padding: 0 2rem;
    flex-direction: column;
    margin-bottom: 0;

    h4 {
      font-size: ${fontSize.md};
      line-height: 1.5;
      margin-bottom: 2rem;
      color: ${colors.primary};
      font-weight: 500;
      border-bottom: 1px solid ${colors.grey.lightPrimary};
      min-height: 60px;

      @media (max-width: 768px) {
        margin-top: 2rem;
        margin-bottom: 1rem;
      }
    }

    div {
      padding: 0;
      margin-bottom: 0;

      p {
        text-align: justify;
        font-size: 13px;
        line-height: 1.5;
        color: ${colors.grey.darkSecondary};
      }

      strong {
        font-weight: 700;
        color: ${colors.grey.darkPrimary};
      }
    }
  }
`;
