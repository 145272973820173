import React, { Component } from 'react';
import { connect } from 'react-redux';
// Node
import { toast } from 'react-toastify';

// hocs
import Modal from '../../hocs/Modal';

// actions
import { modalReopenMessage } from '../../actions/modals';
import { fetchMessages, show, page } from '../../actions/messages';

// services
import { formatSubmit, sendNewMessage } from '../../services/message';

// Utils
import { SUCCESS, ERROR, TYPE_A_CASE } from '../../utils/constMessage';

// styles
import { StyleReopenMessage } from './style';
import { Row, Col } from '../../styles/align';
import {
  Wrapper,
  BtnGroup,
  Button,
  ButtonSecondary,
  Label,
} from '../../styles/forms';

class ReopenMessage extends Component {
  state = {
    newMessage: '',
  };

  onSubmit() {
    if (this.state.newMessage === '') {
      toast.warn(TYPE_A_CASE);
      return false;
    }
    sendNewMessage({
      ...this.props.data,
      mensagem: this.state.newMessage,
    }).then(response => {
      if (response.status === 200) {
        this.props.modalReopenMessage();
        this.props.fetchMessages(
          formatSubmit(this.props.filterMessages.values)
        );
        this.props.show('');
        this.props.page(1);
        toast.success(SUCCESS);
      } else {
        toast.error(ERROR);
      }
    });
  }

  render() {
    return (
      <StyleReopenMessage>
        <Col>
          <Row>
            <Wrapper>
              <Label htmlFor={'descricao'}>
                Descrição <strong>*</strong>
              </Label>
              <textarea
                id={'descricao'}
                onChange={e => this.setState({ newMessage: e.target.value })}
                value={this.state.newMessage}
              ></textarea>
            </Wrapper>
          </Row>
        </Col>
        <BtnGroup>
          <span className={'required'}>* Campos Obrigatórios</span>
          <span>
            <Button onClick={this.props.modalReopenMessage}>Cancelar</Button>
            <ButtonSecondary type={'submit'} onClick={() => this.onSubmit()}>
              Enviar
            </ButtonSecondary>
          </span>
        </BtnGroup>
      </StyleReopenMessage>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.modals.modalReopenMessage.data,
    filterMessages: state.form.filterMessage,
  };
};

ReopenMessage = connect(mapStateToProps, {
  modalReopenMessage,
  fetchMessages,
  show,
  page,
})(ReopenMessage);

ReopenMessage = Modal(ReopenMessage);

export default ReopenMessage;
