import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 14px;
  justify-content: center;
  margin-right: 8px;
  margin-left: 3rem;

  @media (max-width: 476px) {
    padding: 14px 0;
  }
  @media (max-width: 768px) {
    flex: auto;
    width: 100%;
  }
`;

export const SocialMediaIcon = styled.a`
  width: ${props => props.width || '24px'};
  height: ${props => props.height || '24px'};
  background-image: url(${props => props.imageUrl});
  background-size: cover;
  background-position: center;
  cursor: pointer;
`;
