export const colors = {
  default: '#FFF',
  primary: '#0e4e96',
  secondary: '#F35922',
  
  grey: {
    light: '#f6f6f6',
    lightPrimary: '#efefef',
    lightSecondary: '#b8c1c7',
    dark: '#333031',
    darkPrimary: '#8c8c8c',
    darkSecondary: '#a1a1a1',
  },

  black: {
    primary: '#000000',
    secondary: '#282828',
    tertiary: '#505050',
    quaternary: '#787878',
    quinary: '#a0a0a0',
  },

  hover: {
    primary900: '#1d4ed8',
    secondary: '#FF8866',
    blue100: '#dbeafe',
  },

  //blue: '#0091d4',
  blueDark: '#131d44',
  success: '#31d187',
  info: '#3187d1',
  error: '#d13131',

  red: {
    primary: '#fa1e0c',
    secondary: '#dc0201',
  },

  blue: {
    light: '#247CE0',
    strong: '#2e14ed',
    blue50: '#eff6ff',
    
  },
};

export const fontSize = {
  sm: '0.875rem',
  md: '1rem',
  lg: '1.2rem',
  xl: '1.8rem',
};

export const borderRadius = '50%';

export const heightInputs = '32px';
export const radiusInputs = '50px';

