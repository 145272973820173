import { mdiCheckboxBlankCircle } from '@mdi/js';
import { differenceInDays, parseISO } from 'date-fns';
// node-modules
import Icon from '@mdi/react';
import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { fetchCart } from '../../../actions/cart';
import { modalResendFiles } from '../../../actions/modals';
// actions
import { fetchOrders } from '../../../actions/orders';
import { repeatOrder } from '../../../services/order';
// Icon
import { IconCart } from '../../../shared/icons';
// styles
import {
  TableContainer,
  TableSpanIcon,
  TableStyle,
  TableTbody,
  TableTd,
  TableTdCart,
  TableTr,
} from '../../../styles/table';
import { colors } from '../../../styles/variables';
// utils
import { dateTransform, numberFormat } from '../../../utils';
import * as constMessage from '../../../utils/constMessage';
// components
import Loader from '../../Loader';

class OrdersTable extends Component {
  state = {
    currentPage: 1,
  };

  componentDidMount() {
    this.props.fetchOrders();
  }

  handlePageClick = data => {
    const nextPage = data.selected + 1;
    console.log(data);

    this.props.fetchOrders({
      ...this.props.filterOrders.values,
      page: nextPage,
    });
    this.setState({ currentPage: nextPage });
  };

  render() {
    if (!this.props.myOrders) {
      return <Loader />;
    }

    if (this.props.myOrders.total === 0) {
      return <span>Você tem 0 pedidos até o momento!</span>;
    }

    return (
      <TableStyle>
        <TableContainer>
          <thead>
            <tr>
              <TableTr>Pedido</TableTr>
              <TableTr>Nome</TableTr>
              <TableTr>Data</TableTr>
              <TableTr>Valor</TableTr>
              <TableTr>Status</TableTr>
              <TableTr>Repetir Compra</TableTr>
            </tr>
          </thead>
          <TableTbody>
            {this.props.myOrders.data.map((order, i) => {
              return (
                <tr
                  key={i}
                  className={'action-link'}
                  onClick={() => {
                    this.props.onSelectOrder(order.pk_pedido);
                  }}
                >
                  <TableTd style={{ width: '10%' }}>{order.pk_pedido}</TableTd>

                  <TableTd>{order.nome_pedido}</TableTd>

                  <TableTd>{dateTransform(order.dta_inc_pedido)}</TableTd>

                  <TableTd>
                    R$ {numberFormat(order.valor_total_pedido, 2)}
                  </TableTd>

                  <TableTd>
                    <Icon
                      path={mdiCheckboxBlankCircle}
                      color={order.cor_status_pedido}
                      text={'Status'}
                      size={0.7}
                    />
                    {order.descricao_status_loja}
                  </TableTd>

                  {differenceInDays(new Date(), parseISO(order.dta_inc_pedido)) <= 60 ? (
                    <TableTdCart
                      onClick={e => {
                        toast.success(constMessage.ADD_ITEM_SUCCESS);
                        repeatOrder(order.pk_pedido, e);
                        this.props.fetchCart(localStorage.getItem('carrinho'));
                        
                      }}
                    >
                      <TableSpanIcon title={'Adicionar ao carrinho'}>
                        <IconCart />
                      </TableSpanIcon>
                    </TableTdCart>
                  ) : (
                    <TableTdCart>
                      <TableSpanIcon
                        title={'Prazo expirado para recompra'}
                        style={{
                          backgroundColor: colors.grey.darkSecondary,
                          opacity: 0.5,
                          cursor: 'not-allowed',
                        }}
                      >
                        <IconCart style={{ opacity: 0.5 }} />
                      </TableSpanIcon>
                    </TableTdCart>
                  )}

                  {/* <Icon
                  path={mdiEmail}
                  color={colors.primary}
                  text={"Enviar Mensagem"}
                  size={1}
                /> */}
                </tr>
              );
            })}
          </TableTbody>
        </TableContainer>
        <ReactPaginate
          previousLabel={'← Anterior'}
          nextLabel={'Próxima →'}
          breakLabel={'...'}
          pageCount={this.props.myOrders.last_page}
          marginPagesDisplayed={3}
          pageRangeDisplayed={3}
          forcePage={this.state.currentPage - 1}
          onPageChange={this.handlePageClick}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
      </TableStyle>
    );
  }
}

const mapStateToProps = state => {
  return {
    myOrders: state.orders.myOrders,
    filterOrders: state.form.filterOrders,
  };
};

OrdersTable = connect(mapStateToProps, {
  fetchOrders,
  fetchCart,
})(OrdersTable);

export default OrdersTable;
