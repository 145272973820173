import React, { Component } from 'react';
import { connect } from 'react-redux';

// node-modules
import Icon from '@mdi/react';
import { mdiPencilCircleOutline } from '@mdi/js';
import { toast } from 'react-toastify';

// components
import Loader from '../../../components/Loader';
import LoaderForm from '../../../components/Loader/loaderForm';

// actions
import {
  fetchStates,
  fetchCities,
  clearCities,
} from '../../../actions/address';
import { fetchBalconies } from '../../../actions/balcony';
import {
  fetchCart,
  setSummary,
  typeDelivery as typeDeliveryAction,
  deliverySelected as deliverySelectedAction,
  balconySelected as balconySelectedAction,
  updateCart,
} from '../../../actions/cart';
import { setPayments } from '../../../actions/payment';
import {
  modalListAddress,
  modalRegisterAddress,
} from '../../../actions/modals';

// services
import {
  getToken,
  selectDelivery,
  selectBalcony,
} from '../../../services/cart';

// utils
import { numberFormat } from '../../../utils';
import {
  CONNECTION_ERROR,
  CHOOSE_STATE,
  CHOOSE_CITY,
} from '../../../utils/constMessage';

// style
import * as Align from '../../../styles/align';
import * as Style from '../style';
import * as Form from '../../../styles/forms';

class Delivery extends Component {
  state = {
    frete_gratis: false,
    pk_estado: '0',
    pk_cidade: '0',
    showOnRender: true,
    submittingFetchBalconies: false,
    shouldRegisterBillingAddress: !this.props.hasBillingAddress && this.props.isCompany

  };

  componentDidMount() {
    this.props.fetchStates();
  }

  handleSelectTypeDelivery = value => {
    this.props.typeDeliveryAction(value);

    if (value === '1') {
      this.props.deliverySelectedAction({ deliverySelected: true });
      this.props.balconySelectedAction({
        balconySelected: false,
        balconyType: false,
      });
    } else {
      this.props.balconySelectedAction({ balconySelected: true });
      this.props.deliverySelectedAction({
        deliverySelected: false,
        deliveryType: false,
      });
    }
  };

  renderHomeDelivery = () => {
    const { endereco, opcoesEntrega } = this.props.deliveryOptions.entrega;

    return (
      <>
        <Form.Wrapper>
          <Form.Radio
            name={'pk_transporte'}
            id={'transportadora'}
            value={'1'}
            checked={this.props.typeDelivery === '1'}
            onChange={e => this.handleSelectTypeDelivery(e.target.value)}
          />
          <span></span>
          <Form.Label htmlFor={'transportadora'}>
            Receber no endereço cadastrado
          </Form.Label>
        </Form.Wrapper>
        <Align.Row className="content__wrapper">
          <Style.DefaultAddress>
            <h3>Endereço de Entrega</h3>
            <strong>{endereco.desc_usuario_endereco}</strong>
            <p>{`${endereco.logradouro_usuario_endereco}, ${endereco.nr_usuario_endereco} ${endereco.bairro_usuario_endereco}`}</p>
            <p>{`${endereco.desc_cidade}, ${endereco.desc_estado}`}</p>
            <Form.BtnGroup>
              <Form.Button onClick={this.props.modalListAddress}>
                <Icon
                  path={mdiPencilCircleOutline}
                  title={'Meus Endereços'}
                  size={1}
                />
                Meus Endereços
              </Form.Button>
            </Form.BtnGroup>
          </Style.DefaultAddress>
          <Style.DeliveryOptions>
            <Style.OptionDeliveryHeader>
              <span>Serviço</span>
              <span>Prazo *</span>
              <span>Valor</span>
            </Style.OptionDeliveryHeader>
            {opcoesEntrega.map((opcao, i) => {
              return (
                <Style.WrapperOptionDelivery key={i}>
                  <Form.Radio
                    name={'pk_tipo_transporte'}
                    id={`pk_tipo_transporte_${opcao.pk_tipo_transporte}`}
                    value={opcao.pk_tipo_transporte}
                    onChange={() =>
                      this.handleDeliverySelect(opcao.pk_tipo_transporte)
                    }
                    checked={
                      this.props.deliveryType === opcao.pk_tipo_transporte
                        ? true
                        : false
                    }
                  />
                  <span></span>
                  <Form.Label
                    htmlFor={`pk_tipo_transporte_${opcao.pk_tipo_transporte}`}
                  >
                    <span>{opcao.tipo}</span>
                    <span>{opcao.tempo}</span>
                    <span>R$ {numberFormat(opcao.valor, 2)}</span>
                  </Form.Label>
                </Style.WrapperOptionDelivery>
              );
            })}
          </Style.DeliveryOptions>
        </Align.Row>
      </>
    );
  };

  renderBalconyDelivery = () => {
    return (
      <>
        <Form.Wrapper>
          <Form.Radio
            name={'pk_transporte'}
            id={'balcao'}
            value={'2'}
            checked={this.props.typeDelivery === '2'}
            onChange={e => this.handleSelectTypeDelivery(e.target.value)}
          />
          <span></span>
          <Form.Label htmlFor={'balcao'}>
            Receber em um balcão de retirada
          </Form.Label>
        </Form.Wrapper>
        <Style.ListBalconyContainer className={'content__wrapper'}>
          <Style.ListBalconyForm>
            <Form.Wrapper>
              <Form.Label htmlFor={'estados'}>Estado</Form.Label>
              <Form.Select
                id={'estados'}
                name="pk_estado"
                onChange={e => this.handleStateChange(e)}
              >
                <option value="0">Selecione um Estado</option>
                {this.renderStates()}
              </Form.Select>
            </Form.Wrapper>
            <Form.Wrapper>
              <Form.Label htmlFor={'cidades'}>Cidade</Form.Label>
              <Form.Select
                id={'cidades'}
                name="pk_cidade"
                onChange={e => this.setState({ pk_cidade: e.target.value })}
              >
                <option value="0">Selecione uma Cidade</option>
                {this.renderCities()}
              </Form.Select>
            </Form.Wrapper>
            <Form.Wrapper>
              <Form.Checkbox
                name={'frete_gratis'}
                id={'frete_gratis'}
                onChange={e =>
                  this.setState({ frete_gratis: !this.state.frete_gratis })
                }
              />
              <span></span>
              <Form.Label htmlFor={'frete_gratis'}>Frete Grátis</Form.Label>
            </Form.Wrapper>
            <Form.BtnGroup row>
              <Form.Button
                onClick={() => this.submitFetchBalconies()}
                disabled={this.state.submittingFetchBalconies}
                secondary
              >
                Buscar
                {this.state.submittingFetchBalconies ? <LoaderForm /> : ''}
              </Form.Button>
            </Form.BtnGroup>
          </Style.ListBalconyForm>
          {this.renderBalconies()}
        </Style.ListBalconyContainer>
      </>
    );
  };

  renderBalconies() {
    const { balconies } = this.props;
    if (!balconies) {
      return '';
    }

    if (balconies.length === 0) {
      return <p>Não há opções de balcão para essa cidade no momento.</p>;
    }

    return (
      <Style.ListBalconyContent>
        {balconies.map((balcony, i) => {
          return (
            <Style.Balcony key={i}>
              <Form.Wrapper row>
                <Form.Radio
                  name={'balcao'}
                  id={balcony.pk_balcao}
                  value={balcony.pk_balcao}
                  onChange={() => this.handleBalconySelect(balcony.pk_balcao)}
                  checked={
                    this.props.balconyType === balcony.pk_balcao ? true : false
                  }
                />
                <span></span>
                <Style.BalconyTitle htmlFor={balcony.pk_balcao}>
                  <p>{balcony.desc_balcao}</p>
                  {balcony.mais_proximo === true
                      ? <span>
                      <u>Mais próximo</u>
                    </span>
                      : ''}
                  <span>
                    {balcony.vlr_balcao === 0
                      ? ' Frete Grátis'
                      : ` R$ ${numberFormat(balcony.vlr_balcao, 2)}`}
                  </span>
                </Style.BalconyTitle>
              </Form.Wrapper>
              <Style.BalconyContent>
                <p>{`${balcony.logradouro_balcao}, ${balcony.nr_balcao} ${balcony.bairro_balcao}`}</p>
                <p>{`${balcony.desc_cidade} - ${balcony.desc_estado} - ${balcony.cep_balcao}`}</p>
                <strong>
                  Entrega em até {balcony.prazo_balcao}{' '}
                  {balcony.prazo_balcao > 1 ? 'dias úteis' : 'dia útil'}
                </strong>
                <p>Fone: {balcony.fone_balcao}</p>
                <p><strong>Horário de atendimento:</strong> {balcony.complemento_balcao}</p><br/>
                <p>{balcony.info_atendimento}</p>
              </Style.BalconyContent>
            </Style.Balcony>
          );
        })}
      </Style.ListBalconyContent>
    );
  }

  renderStates() {
    const { states } = this.props;
    if (!states) {
      return '';
    }

    return (
      <>
        {Object.values(states).map(state => {
          return (
            <option key={state.pk_estado} value={state.pk_estado}>
              {state.desc_estado}
            </option>
          );
        })}
      </>
    );
  }

  renderCities() {
    const { cities } = this.props;
    if (!cities) {
      return '';
    }
    return (
      <>
        {Object.values(cities).map(city => {
          return (
            <option key={city.pk_cidade} value={city.pk_cidade}>
              {city.desc_cidade}
            </option>
          );
        })}
      </>
    );
  }

  handleDeliverySelect = async pk_tipo_transporte => {
    const { onUpdate } = this.props;
    this.props.toggleLoading();

    const { pk_usuario_endereco } = this.props.deliveryOptions.entrega.endereco;

    this.props.deliverySelectedAction({ deliveryType: pk_tipo_transporte });

    const response = await selectDelivery(
      pk_usuario_endereco,
      pk_tipo_transporte
    );

    if (response.status === 200) {
      this.props.updateCart(response.data);
      this.props.deliverySelectedAction({ deliverySelected: true });
      this.props.balconySelectedAction({ balconySelected: false });
      onUpdate({
        resumo: response.data.data.resumo,
        pagamento: response.data.data.pagamento,
        // só limpa infos de pagamentos se for selecionado dois cartões, pois o valor
        // do pedido vai mudar, e o usuário precisa digitar novamente quanto quer pagar em cada cartão
        cleanTwoCards: this.props.isTwoCardsSelected,
      });
    } else {
      toast.error(CONNECTION_ERROR);
    }

    this.props.toggleLoading();
  };

  handleStateChange(e) {
    const { value } = e.target;
    let objState = { pk_estado: value };
    if (value === '0') {
      this.props.clearCities();
      objState = { pk_cidade: '0' };
      this.setState({ pk_estado: value });
    } else {
      this.props.fetchCities(value, 1);
    }

    this.setState(objState);
  }

  handleBalconySelect = async pk_balcao => {
    const { onUpdate } = this.props;
    this.props.toggleLoading();
    this.props.deliverySelectedAction({ balconyType: pk_balcao });

    const response = await selectBalcony(
      pk_balcao,
      this.state.pk_estado,
      this.state.pk_cidade
    );

    if (response.status === 200) {
      this.props.updateCart(response.data);
      this.props.balconySelectedAction({ balconySelected: true });
      this.props.deliverySelectedAction({ deliverySelected: false });
      onUpdate({
        resumo: response.data.data.resumo,
        pagamento: response.data.data.pagamento,
      });
    } else {
      toast.error(CONNECTION_ERROR);
    }
    this.props.toggleLoading();
  };

  submitFetchBalconies = () => {
    if (this.state.pk_estado === '0') {
      toast.warn(CHOOSE_STATE);
      return false;
    }

    if (this.state.pk_cidade === '0') {
      toast.warn(CHOOSE_CITY);
      return false;
    }

    this.setState({
      submittingFetchBalconies: true,
    });

    const data = {
      pk_cidade: this.state.pk_cidade,
      pk_estado: this.state.pk_estado,
      frete_gratis: this.state.frete_gratis,
      carrinho_token: getToken(),
    };

    this.props.fetchBalconies(data).then(() => {
      this.setState({
        submittingFetchBalconies: false,
      });
    });
  };

  render() {
    const { deliveryOptions } = this.props;

    if (!deliveryOptions) {
      return (
        <Style.DeliveryContainer>
          <Loader />
        </Style.DeliveryContainer>
      );
    }

    if (!deliveryOptions.entrega || this.state.shouldRegisterBillingAddress) {
      if (this.state.showOnRender) {
        this.props.modalRegisterAddress(this.props.fetchCart);
        this.setState({ showOnRender: false });
      }
      return (
        <Style.DeliveryContainer>
          <h3>Cadastre um endereço {this.state.shouldRegisterBillingAddress && ' de Faturamento'}</h3>
          <Form.BtnGroup>
            <Form.Button
              onClick={() =>
                this.props.modalRegisterAddress(this.props.fetchCart)
              }
            >
              Cadastrar Endereço{this.state.shouldRegisterBillingAddress && ' de Faturamento'}
            </Form.Button>
          </Form.BtnGroup>
        </Style.DeliveryContainer>
      );
    }

    return (
      <Style.DeliveryContainer>
        <Style.DeliveryContent
          className={this.props.typeDelivery === '1' ? 'active' : 'hidden'}
        >
          {this.renderHomeDelivery()}
        </Style.DeliveryContent>
        <Style.DeliveryContent
          className={this.props.typeDelivery === '2' ? 'active' : 'hidden'}
        >
          {this.renderBalconyDelivery()}
        </Style.DeliveryContent>
        <br />
        <Form.Label style={{fontWeight: "bold"}}>* Prazo de entrega</Form.Label>
      </Style.DeliveryContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    deliveryOptions: state.cart.deliveryOptions,
    typeDelivery: state.cart.typeDelivery,
    states: state.address.states,
    cities: state.address.cities,
    balconies: state.balcony.balconies,
    deliveryType: state.cart.deliveryType,
    balconyType: state.cart.balconyType,
    isTwoCardsSelected: state.payment.twoCards.selected,
    hasBillingAddress: state.address.address.has_billing_address,
    isCompany: state.user.user.fk_tipo_usuario === 1
  };
};

Delivery = connect(mapStateToProps, {
  fetchCart,
  modalListAddress,
  modalRegisterAddress,
  setSummary,
  setPayments,
  typeDeliveryAction,
  deliverySelectedAction,
  balconySelectedAction,
  fetchStates,
  fetchCities,
  clearCities,
  fetchBalconies,
  updateCart,
})(Delivery);

export default Delivery;
