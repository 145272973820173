import React, { Component } from "react";
import { connect } from "react-redux";

// Actions
import { modalConfirmFileUpload } from "../../actions/modals";
import Loader from "../../components/Loader";
// Hocs
import Modal from "../../hocs/Modal";
// Styles
import { Row } from "../../styles/align";
import { BtnGroup, Button } from "../../styles/forms";
import { Loading } from "../style";
import { StyleConfirmFileUpload } from "./style";

class ConfirmFileUpload extends Component {
  state = {
    loading: false
  };

  handleCallback = () => {
    this.setState({
      loading: true
    });
    if (this.props.callback !== undefined) {
      this.props.callback(this.props.data).then(() => {
        this.props.modalConfirmFileUpload();
      });
    } else {
      this.props.modalConfirmFileUpload();
    }
  };

  render() {
    return (
      <StyleConfirmFileUpload>
        {this.state.loading ? (
          <Loading>
            <Loader />
          </Loading>
        ) : (
          ""
        )}
        <Row>
          <p>
            Nos materiais com miolo de páginas em PB (preto e branco), os arquivos que estiverem com todos os canais da paleta 
            CMYK ativos terão uma alteração para a escala de tons de cinza. 
            Mesmo imagens em preto, que não estejam apenas com o canal K (preto puro), sofrerão alterações. 
            Este ajuste é necessário para um melhor resultado da impressão.
          </p>
        </Row>
        <Row>
          <p>
            No processo gráfico pode haver variação de corte em todos os lados do material, tanto nas laterais quanto nos cabeçalhos e rodapés. 
            Evite colocar bordas para que essas variações, caso ocorram, não fiquem visíveis. 
          </p>
        </Row>
        <Row>
          <p className="infoText">
            Favor retirar linhas de corte do seu arquivo antes do envio <b>CRUZETA</b>.
          </p>
        </Row>
        <Row>
          <BtnGroup>
            <Button onClick={this.props.modalConfirmFileUpload}>Cancelar</Button>
            <Button onClick={this.handleCallback}>Confirmar</Button>
          </BtnGroup>
        </Row>
      </StyleConfirmFileUpload>
    );
  }
}

const mapStateToProps = state => {
  return {
    callback: state.modals.modalConfirmFileUpload.callback,
    data: state.modals.modalConfirmFileUpload.data,
    text: state.modals.modalConfirmFileUpload.text
  };
};

ConfirmFileUpload = connect(mapStateToProps, { modalConfirmFileUpload })(ConfirmFileUpload);

ConfirmFileUpload = Modal(ConfirmFileUpload);

export default ConfirmFileUpload;

