import React from 'react';

// Styles
import { Files } from '../styles/forms';

const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({
  input: { value: omitValue, onChange, onBlur, ...inputProps },
  meta: omitMeta,
  ...props
}) => (
  <>
    <Files
      onChange={adaptFileEventToValue(onChange)}
      onBlur={adaptFileEventToValue(onBlur)}
      {...inputProps}
      {...props}
    />
  </>
);

export default FileInput;
