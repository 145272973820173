import React, { Component } from 'react';
import { connect } from 'react-redux';
// node-modules
import { toast } from 'react-toastify';
// messages
import { SUCCESS, ERROR, CHOOSE_RATING } from '../../utils/constMessage';
// actions
import { modalMessageFeedback } from '../../actions/modals';
import { fetchMessages, show, page } from '../../actions/messages';
// hocs
import Modal from '../../hocs/Modal';
// styles
import { StyleMessageFeedback } from './style';
import { Col } from '../../styles/align';
import {
  Wrapper,
  BtnGroup,
  Button,
  ButtonSecondary,
  Label,
} from '../../styles/forms';
// services
import { formatSubmit, sendFeedback } from '../../services/message';
// Components
import RatingStars from '../../components/RatingStars';

class MessageFeedback extends Component {
  state = {
    message: '',
    rating: 1,
  };

  onSubmit = data => {
    if (this.state.rating === '') {
      toast.warn(CHOOSE_RATING);
      return false;
    }

    sendFeedback(
      this.props.data.fk_caso,
      this.state.rating,
      this.state.message
    ).then(response => {
      if (response.status === 200) {
        this.props.modalMessageFeedback();
        this.props.fetchMessages(
          formatSubmit(this.props.filterMessages.values)
        );
        this.props.show('');
        this.props.page(1);
        toast.success(SUCCESS);
      } else {
        toast.error(ERROR);
      }
    });
  };

  handleChangeRating = value => {
    this.setState({
      rating: value,
    });
  };

  render() {
    const { data } = this.props;

    return (
      <StyleMessageFeedback>
        <Col>
          <p>
            Prezado Cliente, obrigado por sua avaliação. Se possivel envie um
            feedback mais detalhado do nosso atendimento. Isso nos ajudará a
            obter melhores resultados.
          </p>
          <Wrapper>
            <Label htmlFor={'mensagem'}>Mensagem</Label>
            <textarea
              id={'mensagem'}
              onChange={e => this.setState({ message: e.target.value })}
              value={this.state.message}
            ></textarea>
          </Wrapper>
          <Wrapper>
            <Label htmlFor={'rating'}>
              Avaliação <strong>*</strong>
            </Label>
            <RatingStars
              handleChangeRating={this.handleChangeRating}
              rating={parseInt(this.state.rating)}
              editing={true}
              name={data.fk_caso}
            />
          </Wrapper>
        </Col>
        <BtnGroup row>
          <span className={'required'}>* Campos Obrigatórios</span>
          <span>
            <Button onClick={this.props.modalMessageFeedback}>Cancelar</Button>
            <ButtonSecondary type={'submit'} onClick={() => this.onSubmit()}>
              Enviar
            </ButtonSecondary>
          </span>
        </BtnGroup>
      </StyleMessageFeedback>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.modals.modalMessageFeedback.data,
    filterMessages: state.form.filterMessage,
  };
};

MessageFeedback = connect(mapStateToProps, {
  modalMessageFeedback,
  fetchMessages,
  show,
  page,
})(MessageFeedback);

MessageFeedback = Modal(MessageFeedback);

export default MessageFeedback;
