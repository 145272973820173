import React from 'react';
import SVG from 'react-inlinesvg';

import ImageArrowRight from '../assets/images/icons/arrow-right.svg';
import ImageBanners from '../assets/images/icons/banners.svg';
import ImageBB from '../assets/images/icons/bb.svg';
import ImageBook from '../assets/images/icons/book.svg';
import ImageBradesco from '../assets/images/icons/bradesco.svg';
import ImageCabal from '../assets/images/icons/cabal.svg';
import ImageCadastro from '../assets/images/icons/cadastro.svg';
import ImageCaixa from '../assets/images/icons/caixa.svg';
import ImageCapas from '../assets/images/icons/capas.svg';
import ImageCarDelivery from '../assets/images/icons/car-delivery.svg';
import ImageCart from '../assets/images/icons/cart.svg';
import ImageConsultoria from '../assets/images/icons/consultoria.svg';
import ImageCreateFiles from '../assets/images/icons/create-files.svg';
import ImageDiagramacao from '../assets/images/icons/diagramacao.svg';
import ImageDiners from '../assets/images/icons/diners.svg';
import ImageEdit from '../assets/images/icons/icon-edit.svg';
import ImageElo from '../assets/images/icons/elo.svg';
import ImageError from '../assets/images/icons/error.svg';
import ImageFacebook from '../assets/images/icons/facebook.svg';
import ImageHiper from '../assets/images/icons/hipercard.svg';
import ImageInstagram from '../assets/images/icons/instagram.svg';
import ImageInstrucoes from '../assets/images/icons/instrucoes.svg';
import ImageItau from '../assets/images/icons/itau.svg';
import ImageJcb from '../assets/images/icons/jcb.svg';
import Imagelinkedin from '../assets/images/icons/linkedin.svg';
import ImageMaster from '../assets/images/icons/mastercard.svg';
import ImageCredit from '../assets/images/icons/moeda.svg';
import ImageMousePersonalize from '../assets/images/icons/mouse-personalize.svg';
import ImagePix from '../assets/images/icons/pix.svg';
import ImageRevisao from '../assets/images/icons/revisao.svg';
import ImageSearch from '../assets/images/icons/search.svg';
import ImageSendFiles from '../assets/images/icons/send-files.svg';
import ImageSmartPhone from '../assets/images/icons/smartphone.svg';
import ImageSorocred from '../assets/images/icons/sorocred.svg';
import ImageTwitter from '../assets/images/icons/twitter.svg';
import ImageUnionpay from '../assets/images/icons/unionpay.svg';
import ImageUser from '../assets/images/icons/user.svg';
import ImageVisa from '../assets/images/icons/visa.svg';
import ImageWhatsApp from '../assets/images/icons/whatsapp-icon.svg';
import ImageYoutube from '../assets/images/icons/youtube.svg';

export const IconCart = () => <SVG src={ImageCart} />;
export const IconWhatsApp = () => <SVG src={ImageWhatsApp} />;
export const IconUser = () => <SVG src={ImageUser} />;
export const IconSearch = () => <SVG src={ImageSearch} />;
export const IconMousePersonalize = () => <SVG src={ImageMousePersonalize} />;
export const IconCarDelivery = () => <SVG src={ImageCarDelivery} />;
export const IconSmartPhone = () => <SVG src={ImageSmartPhone} />;
export const IconError = () => <SVG src={ImageError} />;
export const IconArrowRight = () => <SVG src={ImageArrowRight} />;
export const IconBanners = () => <SVG src={ImageBanners} />;
export const IconBook = () => <SVG src={ImageBook} />;
export const IconCadastro = () => <SVG src={ImageCadastro} />;
export const IconCapas = () => <SVG src={ImageCapas} />;
export const IconConsultoria = () => <SVG src={ImageConsultoria} />;
export const IconDiagramacao = () => <SVG src={ImageDiagramacao} />;
export const IconRevisao = () => <SVG src={ImageRevisao} />;
export const IconFacebook = () => <SVG src={ImageFacebook} />;
export const IconTwitter = () => <SVG src={ImageTwitter} />;
export const IconYoutube = () => <SVG src={ImageYoutube} />;
export const IconInstrucoes = () => <SVG src={ImageInstrucoes} />;
export const IconInstagram = () => <SVG src={ImageInstagram} />;
export const IconLinkedin = () => <SVG src={Imagelinkedin} />;
export const IconMaster = () => <SVG src={ImageMaster} />;
export const IconVisa = () => <SVG src={ImageVisa} />;
export const IconPix = () => <SVG src={ImagePix} />;
export const IconDiners = () => <SVG src={ImageDiners} />;
export const IconHiper = () => <SVG src={ImageHiper} />;
export const IconItau = () => <SVG src={ImageItau} />;
export const IconCaixa = () => <SVG src={ImageCaixa} />;
export const IconBB = () => <SVG src={ImageBB} />;
export const IconBradesco = () => <SVG src={ImageBradesco} />;
export const IconCredit = () => <SVG src={ImageCredit} />;
export const IconCreateFiles = () => <SVG src={ImageCreateFiles} />;
export const IconSendFiles = () => <SVG src={ImageSendFiles} />;
export const IconJcb = () => <SVG src={ImageJcb} />;
export const IconElo = () => <SVG src={ImageElo} />;
export const IconSorocred = () => <SVG src={ImageSorocred} />;
export const IconCabal = () => <SVG src={ImageCabal} />;
export const IconUnionpay = () => <SVG src={ImageUnionpay} />;
export const IconEdit = ({ title }) => <SVG src={ImageEdit} title={title} />;
