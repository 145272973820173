import React, { useState } from 'react';
import { connect } from 'react-redux';

// styles
import { AcceptCookies, Text, Button, Anchor } from './style';

// services
import { setCookie } from '../../services/user';

// actions
import { cookiesAction } from '../../actions/user';

let Cookie = props => {
  const [hide, setHide] = useState(true);

  const handleAccept = async () => {
    return props.cookiesAction().then(resp => {
      if (resp) {
        setCookie('true');
        setHide(false);
      }
    });
  };

  return (
    <>
      <AcceptCookies hide={hide}>
        <Text>
          Este site armazena cookies para melhorar a experiência do usuário.
          Você pode desativá-los através do seu navegador, entretanto, algumas
          áreas e funcionalidades não funcionarão corretamente. Para mais
          informações você pode consultar os nossos{' '}
          <Anchor
            href="https://instrucoes.fabricadolivro.com.br/politica-empresa/termos-de-uso/"
            target="_blank"
            title="Política de Privacidade"
          >
            termos de uso
          </Anchor>
          . Ao continuar navegando por este site e utilizando nossos serviços,
          você aceita estes termos.
        </Text>
        <Button title="Fechar e continuar" onClick={handleAccept}>
          Fechar e continuar
        </Button>
      </AcceptCookies>
    </>
  );
};

Cookie = connect(null, { cookiesAction })(Cookie);

export default Cookie;
