import React from 'react';

// Node
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

// styles
import { SkeletonStyle } from './style';

const SkeletonMenu = () => {
  return (
    <SkeletonStyle>
      <SkeletonTheme color="#efefef" highlightColor="#f7f7f7">
        <ul>
          <li>
            <Skeleton />
          </li>
          <li>
            <Skeleton />
          </li>
          <li>
            <Skeleton />
          </li>
          <li>
            <Skeleton />
          </li>
          <li>
            <Skeleton />
          </li>
          <li>
            <Skeleton />
          </li>
          <li>
            <Skeleton />
          </li>
        </ul>
      </SkeletonTheme>
    </SkeletonStyle>
  );
};

export default SkeletonMenu;
