import React, { Component } from 'react';
import { connect } from 'react-redux';

// node
import ReactPaginate from 'react-paginate';

// actions
import { fetchHistoryCredits } from '../../../actions/credits';

// utils
import { numberFormat, dateTransform } from '../../../utils';
import Loader from '../../Loader';

// styles
import {
  TableStyle,
  TableContainer,
  TableTbody,
  TableTr,
  TableTd,
} from '../../../styles/table';
import { Saldo } from './style';

class Table extends Component {
  state = {
    currentPage: 1,
  };

  handlePageClick = data => {
    const nextPage = data.selected + 1;

    this.props.fetchHistoryCredits({
      page: nextPage,
    });
    this.setState({ currentPage: nextPage });
  };

  componentDidMount() {
    this.props.fetchHistoryCredits();
  }

  render() {
    const { credits } = this.props;

    if (!credits) {
      return (
        <TableStyle>
          <Loader />
        </TableStyle>
      );
    }

    return (
      <TableStyle>
        <Saldo>
          Saldo de Crédito atual:{' '}
          <span>R$ {numberFormat(credits.data.saldo)}</span>
        </Saldo>
        <TableContainer>
          <thead>
            <tr>
              <TableTr>Data</TableTr>
              <TableTr>Pedido</TableTr>
              <TableTr>Histórico</TableTr>
              <TableTr>Valor</TableTr>
              <TableTr>Saldo</TableTr>
            </tr>
          </thead>
          <TableTbody>
            {credits.data.data.data.map((credit, i) => {
              return (
                <tr key={i}>
                  <TableTd>{dateTransform(credit.dta_inc)}</TableTd>
                  <TableTd>{credit.fk_pedido}</TableTd>
                  <TableTd>{credit.descricao}</TableTd>
                  <TableTd>R$ {numberFormat(credit.valor)}</TableTd>
                  <TableTd>R$ {numberFormat(credit.total_atualizado)}</TableTd>
                </tr>
              );
            })}
          </TableTbody>
        </TableContainer>
        <ReactPaginate
          previousLabel={'← Anterior'}
          nextLabel={'Próxima →'}
          breakLabel={'...'}
          pageCount={credits.data.data.last_page}
          marginPagesDisplayed={3}
          pageRangeDisplayed={3}
          forcePage={this.state.currentPage - 1}
          onPageChange={this.handlePageClick}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
      </TableStyle>
    );
  }
}

const mapStateToProps = state => {
  return {
    credits: state.credits.credits,
  };
};

Table = connect(mapStateToProps, { fetchHistoryCredits })(Table);

export default Table;
