import React from 'react';

// node-modules
import ReactHtmlParser from 'react-html-parser';

// styles
import { Row } from '../../styles/align';
import { CategoryWrapper, CategoryContent, Image, Text } from './style';

// utils
import { parseHtmlFromString } from '../../utils';

const Category = ({ category }) => {
  return (
    <CategoryWrapper>
      <CategoryContent>
        <Row>
          <Image>
            <img
              alt={category.descricao_imagem}
              src={category.url_base + category.nome_menu_imagem}
              title={category.descricao_imagem}
            />
          </Image>
          <Text>
            <span>
              <h1>{category.desc_menu}</h1>
            </span>
            <span>
              {ReactHtmlParser(
                parseHtmlFromString(category.conteudo_publicitario)
              )}
            </span>
          </Text>
        </Row>
      </CategoryContent>
    </CategoryWrapper>
  );
};

export default Category;
