import styled from 'styled-components';
import { colors, fontSize } from '../../styles/variables';
/**
 * Container Login no Header
 *
 * @extends {div} Tag div Nativa Html
 */

export const ContainerLogin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 178px;

  span {
    color: ${colors.primary};
    font-size: ${fontSize.sm};
    line-height: 1.5;

    span {
      display: block;
    }

    strong {
      font-weight: 400;
    }
  }

  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 0.8rem;

    .cls-1 {
      fill: ${colors.primary};
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

/**
 * Container Login no Header [Usuario Logado]
 * Tratamento dos hovers do menu MyAccount
 *
 * @extends {div} Tag div Nativa Html
 */

export const StyleMyAccount = styled.div`
  width: 100%;
  position: relative;

  & > div {
    display: flex;
    justify-content: center;
    padding: 0.4rem;

    span {
      display: flex;
      align-items: center;
      font-weight: 600;
      color: ${colors.primary};
    }

    @media (max-width: 768px) {
      border: none;
      padding: 0;
    }
  }

  &:hover {
    color: ${colors.secondary};
    
    & > div {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border: 1px solid #0e4e96;
      
      span {
        color: ${colors.primary};
      }

      span {
        color: ${colors.secondary};
        cursor: pointer;
      }

      svg {
        .cls-1 {
          fill: ${colors.secondary};
        }
      }
    }

    ul {
      margin-top: 0;
      background: rgba(243, 89, 34, 0.9);
      max-height: 100vh;
      transition: max-height 200ms ease-in-out;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-left: 1px solid #0e4e96;
      border-bottom: 1px solid #0e4e96;
      border-right: 1px solid #0e4e96;

      a {
        text-decoration: none;
        color: ${colors.default};
        font-weight: 400;

        &:hover {
        color: ${colors.primary};
        font-weight: 600;
      }
    }
  
    }

      li {
        padding: 0.8rem 0.5rem;
        margin: 0 0.80rem;
        
      } 
      
      li:not(:first-child) {
        &:hover {
          color: ${colors.primary};
        }
      }
    }
  

  a:hover {
    color: ${colors.primary};
    font-weight: 600;
  }

  ul {
    width: 100%;
    max-height: 0;
    overflow: auto;
    position: absolute;
    color: ${colors.default};
    z-index: 997;
    list-style: none;
    padding-left: 0;

    li {
      a {
        text-decoration: none;
        color: ${colors.default};
        font-weight: 400;
      }

      &:hover {
        color: ${colors.primary};
        font-weight: 600;
      }

      &:first-of-type {
        cursor: auto;
      }

      padding: 0.5rem;
      text-wrap: nowrap;
    }

    @media (max-width: 768px) {
      position: relative;
      color: ${colors.default};
    }
}
`;

/**
 * Container Login no Header [Usuario Deslogado]
 *
 * @extends {div} Tag div Nativa Html
 */

export const StyleAccount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
`;
