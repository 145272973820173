import { useEffect } from 'react';

function LinkedInInsightTag() {
    useEffect(() => {
        const idInsightLinkedinTag = document.createElement('script');
        idInsightLinkedinTag.type = 'text/javascript';
        idInsightLinkedinTag.text = `
            _linkedin_partner_id = "6753641";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);
        `;
        document.body.appendChild(idInsightLinkedinTag);

        const getLinkedinAdsData = document.createElement('script');
        getLinkedinAdsData.type = 'text/javascript';
        getLinkedinAdsData.text = `
            (function(l) {
                if (!l){
                    window.lintrk = function(a,b){window.lintrk.q.push([a,b])}; 
                    window.lintrk.q=[]
                } 
                var s = document.getElementsByTagName("script")[0]; 
                var b = document.createElement("script"); 
                b.type = "text/javascript";
                b.async = true; 
                b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; 
                s.parentNode.insertBefore(b, s);
            })(window.lintrk);
        `;
        document.body.appendChild(getLinkedinAdsData);

        return () => {
            document.body.removeChild(idInsightLinkedinTag);
            document.body.removeChild(getLinkedinAdsData);
        };
    }, []);

    return null;
}

export default LinkedInInsightTag;

  