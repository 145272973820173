import styled from 'styled-components';

// styles
import { Row, Col } from '../../styles/align';
import { colors } from '../../styles/variables';
import { Wrapper } from '../../styles/forms';
import { StyleSearchBar } from '../../components/SearchBar/style';

/**
 * Formata o Filtro da pesquisa
 * @extends {Col}
 *
 * Herda estilo para formatar o interior do Filtro
 * @extends {Col}
 * @extends {Wrapper}
 * @extends {StyleSearchBar}
 * @extends {colors}
 *
 */
export const Filter = styled(Col)`
  background-color: ${colors.grey.light};
  margin-bottom: 1rem;
  padding: 1rem;

  ${Col} {
    display: flex;
    justify-content: center;

    div {
      margin: 0;
    }

    &:last-of-type {
      align-items: flex-end;
    }
  }

  ${Wrapper} {
    width: 200px;
  }

  @media (max-width: 768px) {
    ${Col} {
      margin-bottom: 1rem;

      ${Wrapper} {
        width: 100%;
      }

      ${StyleSearchBar} {
        display: flex;
        width: auto;

        input {
          display: flex;
        }

        button {
          display: flex;
        }
      }
    }
  }
`;

/**
 * Formata o resultado da pesquisa no filtro
 * @extends {Row}
 *
 * Herda estilo para formatar o interior do resultado
 * @extends {colors}
 *
 */
export const Result = styled(Row)`
  margin: 1rem 0;
  color: ${colors.primary};
`;
