import styled from 'styled-components';

// Styles
import { Row, Col } from '../../styles/align';
import { Wrapper, Label, BtnGroup, Button } from '../../styles/forms';
import { colors, fontSize } from '../../styles/variables';

/**
 * Formata o Wrapper do Checkbox de Frete Gratis
 *
 * @extends {Wrapper}
 *
 * Herda estilo para formatar o interior do Wrapper
 * @extends {Row}
 */
export const WrapperCheckbox = styled(Wrapper)`
  justify-content: center;
  align-items: center;

  ${Row} {
    width: max-content;
    height: max-content;
  }
`;

/**
 * Formata o Form para Busca de Balcoes
 *
 * @extends {Row}
 *
 * Herda estilo para formatar o interior do Form
 * @extends {Wrapper}
 * @extends {Label}
 * @extends {WrapperCheckbox}
 * @extends {BtnGroup}
 * @extends {Button}
 * @extends {colors}
 */
export const Form = styled(Row)`
  ${Wrapper} {
    width: 100%;
    padding: 1rem;
  }

  ${Label} {
    padding: 0.5rem 0;
    color: ${colors.primary};
    font-weight: 600;
  }

  ${WrapperCheckbox} {
    padding-bottom: 1.5rem;
    width: fit-content;
    justify-content: flex-end;

    span {
      margin: 0;
    }

    ${Label} {
      padding: 0 0 0 2rem;
      color: ${colors.primary};
      font-weight: 600;
    }
  }

  ${BtnGroup} {
    padding-bottom: 1rem;
    justify-content: flex-end;

    ${Button} {
      background-color: ${colors.secondary};
      color: ${colors.default};
      text-decoration: none;
    }
  }
`;

/**
 * Formata o Content da Busca de Balcoes
 *
 * @extends {Row}
 *
 * Herda estilo para formatar o interior do Form
 * @extends {Row}
 */
export const ContentBalconies = styled(Row)`
  padding: 1rem;

  ${Row} {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

/**
 * Formata o Balcão
 *
 * @extends {Col}
 *
 * Herda estilo para formatar o interior do Form
 * @extends {colors}
 * @extends {fontSize}
 */
export const StyleBalcony = styled(Col)`
  margin-bottom: 1rem;
  margin-right: 1rem;
  padding: 1rem;
  border: 1px solid ${colors.grey.darkPrimary};
  border-radius: 5px;
  min-width: 22%;
  max-width: 22%;

  @media (max-width: 768px) {
    min-width: 100%;
    max-width: 100%;
  }

  h3 {
    font-size: ${fontSize.md};
    margin-bottom: 1rem;
    font-weight: 700;
    color: ${colors.primary};
  }

  strong {
    color: ${colors.secondary};
    margin-bottom: 1rem;
  }

  p {
    line-height: 1.5;
    font-size: ${fontSize.sm};
  }
`;
