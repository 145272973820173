import styled from 'styled-components';

// styles
import { Container } from '../style';
import { TableContainer } from '../../styles/table';

/**
 * Formata o Container da modal de Lançamentos Futuros*
 * @extends {Container}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {TableContainer}
 */

export const StyleFutureReleases = styled(Container)`
  ${TableContainer} {
    margin: 0 0 1rem 0;
  }
`;
