import styled from 'styled-components';

// Styles
import {
  ContainerLogin,
  StyleMyAccount,
  StyleAccount,
} from '../Controls/style';
import { cart as Cart } from '../Cart/style';
import { StyleSearchBar } from '../SearchBar/style';
import { colors, fontSize } from '../../styles/variables';

/**
 * Formata o Container do Menu
 *
 * @extends {nav} Tag nav Nativa do HTML
 *
 * Herda estilo para formatar o interior do Container
 * @extends {fontSize}
 * @extends {colors}
 */
export const nav = styled.nav`
  width: 100%;
  box-shadow: 1px 0 4px ${colors.grey.lightSecondary};

  ul {
    width: 100%;
    display: flex;
    list-style: none;
    padding: 0.8rem 2rem;
    justify-content: space-between;

    li {
      text-align: center;
      line-height: 1.2;

      &:last-of-type {
        border-right: none;
      }

      a {
        font-size: ${fontSize.md};
        text-decoration: none;
        color: ${colors.primary};
        font-weight: bold;
        width: 100%;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;

      li {
        border: 0;
        margin-bottom: 1rem;
        text-align: left;
      }
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const SeparatorMenu = styled.li`
  border-right: 2px solid ${colors.primary};
`;

/**
 * Formata o cartIcon no Menu Mobile
 *
 * @extends {div} Tag div Nativa do HTML
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Cart}
 * @extends {colors}
 */
export const CartMenu = styled.div`
  display: flex;
  align-items: baseline;

  a {
    text-decoration: none;
    color: ${colors.primary};
    font-weight: 700;
  }

  ${Cart} {
    text-decoration: none;
    border-bottom: 1px solid ${colors.grey.lightSecondary};
  }
`;

/**
 * Formata o Menu Mobile
 *
 * @extends {div} Tag div Nativa do HTML
 *
 * Herda estilo para formatar o interior do Container
 * @extends {ContainerLogin}
 * @extends {StyleAccount}
 * @extends {StyleMyAccount}
 * @extends {StyleSearchBar}
 * @extends {Cart}
 * @extends {colors}
 */
export const NavStyleMobile = styled.div`
  display: none;
  position: fixed;
  height: 100vh;
  width: 70vw;
  background-color: ${colors.grey.lightPrimary};
  z-index: 200;
  top: 0;
  left: -70vw;
  padding: 1rem;

  @media (max-width: 768px) {
    display: block;
  }

  ${ContainerLogin} {
    margin: 0 0 1rem 0;
    display: block;

    ${StyleAccount} {
      display: flex;
      padding: 1rem 1.6rem;

      span {
        font-size: ${fontSize.md};
      }

      svg {
        width: 3rem;
        height: 3rem;
      }
    }

    ${StyleMyAccount} {
      div {
        justify-content: flex-start;
        padding: 1rem 1.6rem;

        span {
          font-size: ${fontSize.md};
        }

        svg {
          width: 3rem;
          height: 3rem;
        }
      }
    }
  }

  ${StyleSearchBar} {
    display: flex;
    width: auto;
    margin-top: 2rem;

    input {
      display: flex;
      border-radius: 5px;
    }

    button {
      display: flex;
      align-items: center;
    }
  }

  ${Cart} {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    margin-right: 1rem;

    a {
      text-decoration: none;
    }

    span {
      color: ${colors.default};
    }
  }

  ul {
    list-style: none;

    li {
      margin-bottom: 1.2rem;
      font-size: ${fontSize.lg};

      a {
        color: ${colors.primary};
        font-weight: 700;
        text-decoration: none;
      }
    }
  }
`;

/**
 * Formata o Skeleton do Menu
 *
 * @extends {div} Tag div Nativa do HTML
 */

export const SkeletonStyle = styled.div`
  width: 100%;

  ul {
    justify-content: initial;

    li {
      border-color: #f4f4f4;
      padding: 0 1rem;
      font-size: 0.875rem;
      width: 100%;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`;

export const ServicesInstructionsContainer = styled.div`
  display:flex;
  width: 100;
  justify-content: space-around;
  margin-bottom: 20px;

  a {
    text-decoration: none;
    color: ${colors.primary};
    font-weight: 700;
  }

`

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: ${colors.primary}
  margin-bottom: 14px;
`

