import styled from 'styled-components';

// Styles
import { Container, Row, Col, TextAlign } from '../../styles/align';
import { colors, fontSize } from '../../styles/variables';

/**
 * Formata o Container da Página Entrega
 * @extends {Container}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {TextAlign}
 * @extends {fontSize}
 * @extends {colors}
 */
export const DeliveryStyle = styled(Container)`
  padding-top: 2rem;
  flex-direction: column;

  ${TextAlign} {
    padding: 1rem 0;
    text-align: center;
    font-size: ${fontSize.lg};
    color: ${colors.primary};
    font-weight: 600;
  }
`;

/**
 * Formata o Container de Chamada principal da Pagina
 * @extends {Row}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {fontSize}
 * @extends {colors}
 */
export const TextFeature = styled(Row)`
  padding: 2rem;
  background-color: ${colors.primary};
  justify-content: center;

  p {
    font-size: ${fontSize.xl};
    line-height: 1.5;
    font-weight: 600;
    color: ${colors.default};
    max-width: 90%;
    text-align: center;
  }
`;

/**
 * Formata o Container da Imagem da Pagina
 * @extends {Col}
 */
export const ImageDelivery = styled(Col)`
  padding: !rem;
  max-width: 40%;

  img {
    width: 100%
    height: fit-content;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 1rem;
  }
`;

/**
 * Formata o Texto da Página
 * @extends {Col}
 */
export const TextDelivery = styled(Col)`
  padding: 0 2rem;

  ul {
    list-style: none;

    li {
      margin-bottom: 1rem;

      p {
        line-height: 1.5;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0;
  }
`;

/**
 * Formata o Container do Filtro de balcoes
 * @extends {Row}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {colors}
 */
export const Filter = styled(Row)`
  padding: 1rem 3rem;
  background-color: ${colors.grey.lightPrimary};
  margin-bottom: 1rem;
`;
