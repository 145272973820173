import React, { Component } from 'react';
import { connect } from 'react-redux';

// actions
import {
  modalAuthProd,
  modalBalcony,
  modalBknbank,
  modalCancel,
  modalConfirm,
  modalConfirmFileUpload,
  modalContest,
  modalDeliverySteps,
  modalEditor,
  modalFilesAuth,
  modalFutureReleases,
  modalListAddress,
  modalLogin,
  modalMessage,
  modalMessageFeedback,
  modalOrderErrors,
  modalPayment,
  modalPix,
  modalPrivacy,
  modalRefile,
  modalRegister,
  modalRegisterAddress,
  modalReopenMessage,
  modalShippingContact,
  modalTermsOfUse,
  modalWithdrawal,
} from '../actions/modals';
import AuthProd from './AuthProd';
import Balcony from './Balcony';
import BknBank from './BknBank';
import Cancel from './Cancel';
import Confirm from './Confirm';
import ConfirmFileUpload from './ConfirmFileUpload';
import Contest from './Contest';
import Editor from './Editor';
import FilesAuth from './FilesAuth';
import FutureReleases from './FutureReleases';
// modals
import ListAddress from './ListAddress';
import Login from './Login';
import Message from './Message';
import MessageFeedback from './MessageFeedback';
import OrderErrors from './OrderErrors';
import Payment from './Payment';
import Pix from './Pix';
import Privacy from './Privacy';
import Refile from './Refile';
import Register from './Register';
import RegisterAddress from './RegisterAddress';
import ReopenMessage from './ReopenMessage';
import ResendFiles from './ResendFiles';
import ShippingContact from './ShippingContact';
import TermsOfUse from './TermsOfUse';
import Withdrawal from './Withdrawal';
import DeliverySteps from './DeliverySteps';

class Modals extends Component {
  render() {
    return (
      <>
        <ListAddress
          width={'60'}
          title={'Lista de Endereços'}
          show={this.props.modal.modalListAddress}
          onClose={this.props.modalListAddress}
        />
        <Login
          width={'50'}
          title={'Login'}
          show={this.props.modal.modalLogin}
          onClose={this.props.modalLogin}
        />
        <Message
          title={'Nova Mensagem'}
          show={this.props.modal.modalMessage}
          onClose={this.props.modalMessage}
        />
        <Payment
          title={'Formas de Pagamento'}
          show={this.props.modal.modalPayment}
          onClose={this.props.modalPayment}
        />
        <Register
          width={'40'}
          title={'Cadastro'}
          show={this.props.modal.modalRegister}
          onClose={shouldHideCloseButton => {
            this.props.modalRegister(shouldHideCloseButton || false);
          }}
          hideCloseButton={false}
        />

        <RegisterAddress
          width={'40'}
          title={'Novo endereço'}
          show={this.props.modal.modalRegisterAddress.open}
          onClose={this.props.modalRegisterAddress}
        />
        <Cancel
          width={'50'}
          title={this.props.modal.modalCancel.data.title}
          show={this.props.modal.modalCancel.open}
          onClose={this.props.modalCancel}
        />
        <AuthProd
          width={'50'}
          title={this.props.modal.modalAuthProd.data.title}
          show={this.props.modal.modalAuthProd.open}
          onClose={this.props.modalAuthProd}
        />
        <Withdrawal
          width={'40'}
          title={'Responsável pela Retirada'}
          show={this.props.modal.modalWithdrawal}
          onClose={this.props.modalWithdrawal}
        />
        <Confirm
          width={'25'}
          title={this.props.modal.modalConfirm.title}
          show={this.props.modal.modalConfirm.open}
          onClose={this.props.modalConfirm}
        />
        {/*<ResendFiles
          width={'50'}
          title={'Reenvio de Arquivos'}
          show={this.props.modal.modalResendFiles}
          onClose={this.props.modalResendFiles}
        />*/}
        <BknBank
          width={'50'}
          title={'Boleto Vencido'}
          show={this.props.modal.modalBknbank.open}
          onClose={this.props.modalBknbank}
        />
        <Pix
          width={'40'}
          title={'Qr Code PIX'}
          show={this.props.modal.modalPix.open}
          onClose={this.props.modalPix}
        />
        <ShippingContact
          width={'40'}
          title={'Contato para Transporte'}
          show={this.props.modal.modalShippingContact}
          onClose={this.props.modalShippingContact}
        />
        <OrderErrors
          width={'40'}
          title={'Atenção'}
          show={this.props.modal.modalOrderErrors.open}
          onClose={this.props.modalOrderErrors}
        />
        <ReopenMessage
          width={'40'}
          title={'Reabrir Chamado'}
          show={this.props.modal.modalReopenMessage.open}
          onClose={this.props.modalReopenMessage}
        />
        <MessageFeedback
          width={'40'}
          title={'Confirmar Feedback'}
          show={this.props.modal.modalMessageFeedback.open}
          onClose={this.props.modalMessageFeedback}
        />
        <FilesAuth
          width={'40'}
          title={'Atenção'}
          show={this.props.modal.modalFilesAuth}
          onClose={this.props.modalFilesAuth}
        />
        <Refile
          width={'30'}
          title={'Acabamento'}
          show={this.props.modal.modalRefile.open}
          onClose={
            this.props.modal.modalRefile.data.callbackCancel ||
            this.props.modalRefile
          }
        />
        <Editor
          width={'50'}
          title={'Arquivos'}
          show={this.props.modal.modalEditor}
        />
        <Contest
          width={'50'}
          title={this.props.modal.modalContest.title}
          show={this.props.modal.modalContest.open}
          onClose={this.props.modalContest}
        />
        <FutureReleases
          width={'50'}
          title={'Lançamentos Futuros'}
          show={this.props.modal.modalFutureReleases}
          onClose={this.props.modalFutureReleases}
        />
        <Balcony
          width={'60'}
          title={'Lista de Balcões'}
          show={this.props.modal.modalBalcony.open}
          onClose={this.props.modalBalcony}
        />
        <Privacy
          width={'40'}
          title={'Central de Privacidade'}
          show={this.props.modal.modalPrivacy}
          onClose={this.props.modalPrivacy}
        />
        <TermsOfUse
          width={'40'}
          title={'Termos de Aceite'}
          show={this.props.modal.modalTermsOfUse}
          onClose={this.props.modalTermsOfUse}
        />
        <DeliverySteps
          width={'50'}
          title={'Atenção!'}
          show={this.props.modal.modalDeliverySteps.open}
          onClose={this.props.modalDeliverySteps}
        />
        <ConfirmFileUpload
          width={'50'}
          title={'Atenção!'}
          show={this.props.modal.modalConfirmFileUpload.open}
          onClose={this.props.modalConfirmFileUpload}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    modal: state.modals,
  };
};

export default connect(mapStateToProps, {
  modalListAddress,
  modalLogin,
  modalMessage,
  modalPayment,
  modalRegister,
  modalRegisterAddress,
  modalWithdrawal,
  modalConfirm,
  modalBknbank,
  modalPix,
  modalShippingContact,
  modalCancel,
  modalAuthProd,
  modalOrderErrors,
  modalReopenMessage,
  modalMessageFeedback,
  modalFilesAuth,
  modalRefile,
  modalEditor,
  modalContest,
  modalFutureReleases,
  modalBalcony,
  modalPrivacy,
  modalTermsOfUse,
  modalConfirmFileUpload,
  modalDeliverySteps,
})(Modals);
