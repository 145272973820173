import {
  FETCH_FILES,
  CLEAR_FILES,
  SUBMIT_FORM_FILES,
  FILES_AUTH,
  SUBMIT_CREATE_ONLINE,
} from '../actions/types';

// service
import { gabarito } from '../services/filter';

const INITIAL_STATE = {
  authMessage: '',
  isCreateOnline: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_FILES:
      return { ...state, data: action.payload.data, gabaritos: gabarito(action.payload.data.gabaritos) };
    case CLEAR_FILES:
      return { ...state, data: undefined };
    case SUBMIT_FORM_FILES:
      return { ...state, isSubmitForm: action.payload };
    case SUBMIT_CREATE_ONLINE:
      return { ...state, isCreateOnline: action.payload };
    case FILES_AUTH:
      return { ...state, authMessage: action.payload };
    default:
      return state;
  }
};
