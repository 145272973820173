import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// Node
import ReactHtmlParser from 'react-html-parser';
import { toast } from 'react-toastify';

// actions
import { fetchMessages, fetchHistory } from '../../actions/messages';
import { modalReopenMessage, modalMessageFeedback } from '../../actions/modals';

// services
import { sendNewMessage, formatSubmit } from '../../services/message';

// utils
import { parseHtmlFromString } from '../../utils';
import { SUCCESS, ERROR, TYPE_A_MESSAGE } from '../../utils/constMessage';

// Style
import {
  MessageContainer,
  HeaderMessage,
  DetailsMessage,
  ContentMessage,
  Header,
  Content,
  Title,
  NewMessageDetails,
  ReopenMessage,
} from './style';
import { Row } from '../../styles/align';
import { BtnGroup, ButtonSecondary, ButtonPrimary } from '../../styles/forms';

class Details extends Component {
  state = {
    newMessage: '',
  };

  async handleNewMessage(message) {
    if (this.state.newMessage === '') {
      toast.warn(TYPE_A_MESSAGE);
      return false;
    }

    const dataSubmit = {
      fk_pedido: message.fk_pedido,
      fk_caso: message.protocoloId,
      mensagem: this.state.newMessage,
    };
    const response = await sendNewMessage(dataSubmit);
    if (response.status === 200) {
      this.setState({ newMessage: '' });
      const dataFetch = formatSubmit(this.props.filterMessages.values);
      this.props.fetchMessages(dataFetch);
      this.props.fetchHistory({ idCaso: message.protocoloId });
      toast.success(SUCCESS);
    } else {
      toast.error(ERROR);
    }
  }

  handleReopenMessage(message) {
    this.props.modalReopenMessage({
      fk_caso: message.protocoloId,
      fk_pedido: message.fk_pedido,
    });
  }

  handleFeedback(message) {
    this.props.modalMessageFeedback({
      fk_caso: message.protocoloId,
    });
  }

  renderActions(message) {
    switch (message.status) {
      case 'Pendente':
        return '';
      case 'Resolvido':
        return (
          <ReopenMessage>
            <h4>Avaliação do Atendimento</h4>
            <p>Por favor, avalie o atendimento que obteve nesta Mensagem.</p>
            <BtnGroup>
              <ButtonSecondary
                onClick={() => this.handleReopenMessage(message)}
              >
                Reabrir Mensagem
              </ButtonSecondary>
              <ButtonPrimary onClick={() => this.handleFeedback(message)}>
                Avaliar Mensagem
              </ButtonPrimary>
            </BtnGroup>
          </ReopenMessage>
        );
      default:
        return (
          <NewMessageDetails>
            <h4>Nova Mensagem</h4>
            <p>Texto da Mensagem</p>
            <textarea
              onChange={e => this.setState({ newMessage: e.target.value })}
              value={this.state.newMessage}
            ></textarea>
            <BtnGroup>
              <ButtonSecondary onClick={() => this.handleNewMessage(message)}>
                Enviar
              </ButtonSecondary>
            </BtnGroup>
          </NewMessageDetails>
        );
    }
  }

  render() {
    const { messages, type, messageSelected, history } = this.props;
    if (messageSelected === '') {
      return '';
    }

    const message = messages[type].data[messageSelected];
    if (!message || !history) {
      return '';
    }

    return (
      <MessageContainer>
        <HeaderMessage>Chamado {message.protocolo}</HeaderMessage>
        <Row>
          <DetailsMessage>
            <h3>Detalhes da Mensagem</h3>
            <ul>
              <li>
                <span>Data</span>
                <span>{message.data_exibir}</span>
              </li>
              {message.fk_pedido ? (
                <li>
                  <span>Pedido</span>
                  <span>
                    #{message.fk_pedido}
                    <Link
                      to={`/pedido/${message.fk_pedido}`}
                      title={message.fk_pedido}
                    >
                      Detalhes »
                    </Link>
                  </span>
                </li>
              ) : (
                <li></li>
              )}
              <li>
                <span>Status</span>
                <span>{message.status}</span>
              </li>
            </ul>
            {this.renderActions(message)}
          </DetailsMessage>
          <ContentMessage>
            <ul>
              {history.dados.mensagens.map((historico, i) => {
                return (
                  <li key={i}>
                    <Header>
                      <Title>{historico.autor}</Title>
                      <p>{historico.data}</p>
                    </Header>
                    <Content>
                      <p>
                        {ReactHtmlParser(
                          parseHtmlFromString(historico.mensagem)
                        )}
                      </p>
                    </Content>
                  </li>
                );
              })}
            </ul>
          </ContentMessage>
        </Row>
      </MessageContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    messages: state.messages.data,
    messageSelected: state.messages.messageSelected,
    type: state.messages.tipo,
    history: state.messages.history,
    filterMessages: state.form.filterMessage,
  };
};

Details = connect(mapStateToProps, {
  fetchMessages,
  fetchHistory,
  modalReopenMessage,
  modalMessageFeedback,
})(Details);

export default Details;
