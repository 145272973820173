/**
 * Colunas do carrinho
 * @type {Array}
 */
export const productColumns = [
  'Produto',
  'Duplicar Item',
  'Reenviar Arquivos',
  'Quantidade',
  'Unitário',
  'Total (R$)',
  'Excluir',
];
