import React from 'react';
import { connect } from 'react-redux';

// services
import { getSimulate } from '../../services/user';

// styles
import { SimulateContent } from './style';

const InfoSimulation = ({ user }) => {
  const dataSimulate = getSimulate();
  if (!dataSimulate.client || !user) {
    return '';
  }

  return (
    <SimulateContent>
      {`${dataSimulate.operator ||
        'Operador não informado'} simulando ${dataSimulate.client ||
        'Cliente não informado'}`}
    </SimulateContent>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps, null)(InfoSimulation);
