import React, { Component } from "react";
import { connect } from 'react-redux';

// styles
import { TitlePage } from "../../styles/titles";
import { Wrapper, Label, Checkbox } from '../../styles/forms';
import { Anchor } from '../../pages/Filter/style';


class Terms extends Component {

  constructor(props) {
    super(props);

    this.state = {
      AmountTermsChecked: 0,
      produtosChecked: false,
      envioArquivosChecked: false,
    };
  }

  componentDidMount() {
    this.AmountTermsChecked = 0;
    if (this.props.envioArquivos) { this.AmountTermsChecked++ }
    if (this.props.produtosChecked) { this.AmountTermsChecked++ }
    this.props.updateChekedTerms(this.AmountTermsChecked);
  } 

  selectApplicant = (event) => {
    const checked = event.target.checked;
    
    if (checked) {
      this.AmountTermsChecked = this.props.termsChecked + 1;
      this.props.updateChekedTerms(this.AmountTermsChecked);
    } else {
      this.AmountTermsChecked = this.props.termsChecked - 1;
      this.props.updateChekedTerms(this.AmountTermsChecked);
    }
  };

  checkedProdutos = (event) => {
    const checked = event.target.checked;
    
    if (checked) {
      this.produtosChecked = true;
      this.props.updateProdutosChecked(this.produtosChecked);
    } else {
      this.produtosChecked = false;
      this.props.updateProdutosChecked(this.produtosChecked);
    }
  };

  checkedArquivos = (event) => {
    const checked = event.target.checked;
    
    if (checked) {
      this.envioArquivosChecked = true;
      this.props.updateArquivosChecked(this.envioArquivosChecked);
    } else {
      this.envioArquivosChecked = false;
      this.props.updateArquivosChecked(this.envioArquivosChecked);
    }
  };

  render() {
    return (
      <>
        <TitlePage>
            <span>Termos de aceite</span>
        </TitlePage>

        <Wrapper>
            <Checkbox 
              id={'produtos'}
              onChange={(e) => {
                this.selectApplicant(e);
                this.checkedProdutos(e);
              }}
              checked={this.props.produtosChecked}
            />
            <span></span>
            <Label htmlFor={'produtos'}>
              Declaro que as informações de contato e de recebimento do(s) produto(s) está correta.
            </Label>
        </Wrapper>

        <Wrapper>
            <Checkbox 
              id={'envio_arquivos'}
              onChange={(e) => {
                this.selectApplicant(e);
                this.checkedArquivos(e);
              }}
              checked={this.props.envioArquivos}
            />
            <span></span>
            <Label htmlFor={'envio_arquivos'}>
            Declaro que estou enviando os arquivos seguindo as normas Pé com Pé,{' '}
            <Anchor
              href="https://instrucoes.fabricadolivro.com.br/tutoriais/montagem-arquivos-frente-e-verso/"
              target="_blank"
              rel="noopener noreferrer"
            >
              rotacionados pé com pé
            </Anchor>
            {' '}e estou ciente de que ao não seguir as normas, acabará resultando em erros de produção, 
            pelos quais serei responsável.
            </Label>
        </Wrapper>
      </>
    );
  };
};

const mapStateToProps = state => {
  return {
    termsChecked: state.cart.terms_signed,
    produtosChecked: state.cart.produtosChecked,
    envioArquivos: state.cart.envioArquivosChecked,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateChekedTerms: (termsChecked) => dispatch({ type: 'UPDATE_TERMS_CHECKED', payload: termsChecked}),
    updateProdutosChecked: (produtosChecked) => dispatch({ type: 'PRODUCTS_TERMS_CHECKED_STATUS', payload: produtosChecked}),
    updateArquivosChecked: (envioArquivos) => dispatch({ type: 'FILES_TERMS_CHECKED_STATUS', payload: envioArquivos}),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Terms);