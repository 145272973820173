import styled from 'styled-components';

// Styles
import { colors } from '../../../styles/variables';

/**
 * Formata o Saldo na tabela de Créditos
 *
 * @extends {div} Tag div nativa do Html
 *
 * Herda estilo para formatar o interior do container
 * @extends {colors}
 */

export const Saldo = styled.div`
  padding: 1rem;
  color: ${colors.primary};
  font-weight: 700;

  span {
    color: ${colors.secondary};
  }
`;
