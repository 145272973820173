import React, { Component } from 'react';
import { Field } from 'redux-form';

// Node
import { toast } from 'react-toastify';

// validate
import renderField from '../../formValidate/renderField';

// utils
import { onlyNumbers } from '../../utils';

// style
import { ButtonMult, Mult } from '../../styles/forms';
import { ContainerMultiply, Message } from './style';
import Tooltip from '../Tooltip';

let timeoutId = null;

class Multiply extends Component {
  constructor(props) {
    super(props);
    this.InputValue = React.createRef();

    this.state = {
      message: '',
    };
  }

  verifyRange = (newValue, event, value) => {
    const { data, identifyMultiply, otherValue, name, type } = this.props;
    const min = `min_${identifyMultiply}`;
    const max = `max_${identifyMultiply}`;
    let returnValue = newValue;

    // [BRAZIL]

    this.setState({
      message: '',
    });

    if (otherValue) {
      let otherVal = otherValue.current.InputValue.value;
      const sum = parseInt(newValue) + parseInt(otherVal);
      const sumOldValue = parseInt(value) + parseInt(otherVal);

      if (sum === data.quantidades[max] && type === 'pages') {
        if (data.regras.menu.dica.livreto) {
          this.setState({
            message: `Limite Máximo alcançado: ${data.quantidades[max]}<br>
                Para mais páginas recomendamos o produto Livro`,
          });
        }
      }

      if (sum === data.quantidades[min] && type === 'pages') {
        if (data.regras.menu.dica.livro) {
          this.setState({
            message: `Limite Mínimo alcançado: ${data.quantidades[min]}<br>
                Para menos páginas recomendamos o produto Livreto`,
          });
        }
      }

      if (sum > data.quantidades[max]) {
        returnValue = parseInt(data.quantidades[max]) - parseInt(otherVal);

        if (event === 'blur') {
          toast.error(
            `A soma do número de páginas é maior que o máximo permitido.(Máximo: ${data.quantidades[max]})`,
            {
              toastId: `multiply_max_${type}`,
            }
          );
        }

        if (event === 'click') {
          toast.error(
            `Quantidade máxima alcançada.(Máximo: ${data.quantidades[max]})`,
            {
              toastId: `multiply_max_${type}`,
            }
          );
        }

        if (data.regras.menu.dica.livreto) {
          this.setState({
            message: `Limite Máximo alcançado: ${data.quantidades[max]}<br>
                Para mais páginas recomendamos o produto Livro`,
          });
        }

        if (sumOldValue === data.quantidades[max]) {
          return false;
        } else {
          return returnValue;
        }
      }

      if (sum < data.quantidades[min]) {
        returnValue = parseInt(data.quantidades[min]) - parseInt(otherVal);

        if (event === 'blur') {
          toast.error(
            `A soma do número de páginas é menor que o mínimo permitido.(Mínimo: ${data.quantidades[min]})`,
            {
              toastId: `multiply_min_${type}`,
            }
          );
        }

        if (event === 'click') {
          toast.error(
            `Quantidade mínima alcançada.(Mínimo: ${data.quantidades[min]})`,
            {
              toastId: `multiply_min_${type}`,
            }
          );
        }

        if (data.regras.menu.dica.livro) {
          this.setState({
            message: `Limite Mínimo alcançado: ${data.quantidades[min]}<br>
                Para menos páginas recomendamos o produto Livreto`,
          });
        }

        if (sumOldValue === data.quantidades[min]) {
          return false;
        } else {
          return returnValue;
        }
      }

      return returnValue;
    } else {
      if (parseInt(newValue) > data.quantidades[max]) {
        if (event === 'blur') {
          toast.error(
            `Quantidade máxima alcançada.(Máximo: ${data.quantidades[max]})`,
            {
              toastId: `multiply_max_${type}`,
            }
          );
        }

        if (event === 'click') {
          toast.error(
            `Quantidade máxima alcançada.(Máximo: ${data.quantidades[max]})`,
            {
              toastId: `multiply_max_${type}`,
            }
          );
        }

        if (type === 'pages') {
          if (data.regras.menu.dica.livreto) {
            this.setState({
              message: `Limite Máximo alcançado: ${data.quantidades[max]}<br>
                Para mais páginas recomendamos o produto Livro`,
            });
          }
        }

        if (parseInt(value) === data.quantidades[max]) {
          return false;
        } else {
          return data.quantidades[max];
        }
      }

      if (parseInt(newValue) < data.quantidades[min]) {
        if (event === 'blur') {
          toast.error(
            `Quantidade mínima alcançada.(Mínimo: ${data.quantidades[min]})`,
            {
              toastId: `multiply_min_${type}`,
            }
          );
        }

        if (event === 'click') {
          toast.error(
            `Quantidade mínima alcançada.(Mínimo: ${data.quantidades[min]})`,
            {
              toastId: `multiply_min_${type}`,
            }
          );
        }

        if (type === 'pages') {
          if (data.regras.menu.dica.livro) {
            this.setState({
              message: `Limite Mínimo alcançado: ${data.quantidades[min]}<br>
                Para menos páginas recomendamos o produto Livreto`,
            });
          }
        }

        if (parseInt(value) === data.quantidades[min]) {
          return false;
        } else {
          return data.quantidades[min];
        }
      }
    }

    if (returnValue === undefined || returnValue === '') {
      returnValue = data.atributos_selecionados[name];
    }

    return returnValue;
  };

  update = (event, newValue, previousValue, name) => {
    const { onChangeSubmit, onChangeValues } = this.props;

    const timeOut = () => {
      onChangeValues(name, newValue);
      onChangeSubmit(event, newValue, previousValue, name);
    };

    if (timeoutId) {
      window.clearTimeout(timeoutId);
    }

    timeoutId = window.setTimeout(timeOut, 1200);
  };

  blur = (e, step, value) => {
    const { data, name, activeRequest } = this.props;
    let newValue = value;

    if (parseInt(data.atributos_selecionados[name]) === parseInt(newValue)) {
      activeRequest(false);
      return false;
    }

    if (value % step !== 0) {
      const NewStep = step - (value % step);
      newValue = parseInt(value) + NewStep;
    }
    newValue = this.verifyRange(newValue, 'blur', value);

    if (newValue || newValue === 0) {
      activeRequest(true);
      this.update(e, newValue, value, name);
    }
  };

  increment = (e, step, value) => {
    const { name, activeRequest } = this.props;
    let newValue = value;
    newValue = parseInt(value) + step;
    newValue = this.verifyRange(newValue, 'click', value);

    if (newValue || newValue === 0) {
      activeRequest(true);
      this.update(e, newValue, value, name);
    }
  };

  decrement = (e, step, value) => {
    const { name, activeRequest } = this.props;
    let newValue = value;

    if (newValue <= 0) {
      newValue = 0;
    } else {
      newValue = parseInt(value) - step;
    }

    newValue = this.verifyRange(newValue, 'click', value);

    if (newValue || newValue === 0) {
      activeRequest(true);
      this.update(e, newValue, value, name);
    }
  };

  handle = (e, cb) => {
    const input = this.InputValue;
    const value = input.value;
    const { step } = this.props;

    cb(e, step, value);
  };

  keyDown = e => {
    const { name } = this.props;
    const fields = document.getElementsByName(name);

    if (fields.length > 0) {
      const field = fields[0];

      const isFocused = document.activeElement === field;

      if (isFocused && e.key === 'Enter') {
        // Caso o campo Field esteja com o foco e Enter é precionado atualiza o filtro
        this.handle(e, this.blur);
      }
    }
  };

  render() {
    const { name, activeRequest } = this.props;

    return (
      <ContainerMultiply>
        <Message>
          {this.state.message ? (
            <Tooltip dataId={name} text={this.state.message} size={0.8} />
          ) : (
            ''
          )}
        </Message>
        <Mult>
          <ButtonMult onClick={e => this.handle(e, this.decrement)}>
            -
          </ButtonMult>
          <Field
            name={name}
            type={'number'}
            typefield={'multiply'}
            component={renderField}
            ref={ref => (this.InputValue = ref)}
            normalize={onlyNumbers}
            onBlur={e => this.handle(e, this.blur)}
            onFocus={activeRequest}
          />
          <ButtonMult onClick={e => this.handle(e, this.increment)}>
            +
          </ButtonMult>
        </Mult>
      </ContainerMultiply>
    );
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDown, false);
  }
}

export default Multiply;
