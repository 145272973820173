import styled from 'styled-components';

// Style
import { Container } from '../../styles/align';
import { colors } from '../../styles/variables';

/**
 * Formata o container do campo de Pesquisa
 *
 * @extends {Container}
 */
export const StyleSearchBar = styled(Container)`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0;
  width: 400px;

  @media (max-width: 768px) {
    display: none;
    position: absolute;
    left: -999px;
  }
`;

/**
 * Formata o Input da Pesquisa
 *
 * @extends {input} Tag input nativa do HTML
 *
 * Herda estilo para formatar o interior do Input
 * @extends {colors}
 */

export const Input = styled.input.attrs(props => ({
  type: 'text',
}))`
  width: 100%;
  height: 40px;
  border: 1px solid ${colors.primary};
  text-indent: 1rem;
  color: ${colors.grey.darkSecondary};

  &::-webkit-input-placeholder {
    color: ${colors.grey.lightSecondary};
    font-style: italic;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

/**
 * Formata o Botão da Pesquisa
 *
 * @extends {button} Tag button nativa do HTML
 *
 * Herda estilo para formatar o interior do button
 * @extends {colors}
 */
export const Button = styled.button`
  position: absolute;
  right: 0;
  height: 40px;
  padding: 0 0.5rem;
  border: none;
  background: transparent;
  cursor: pointer;

  svg {
    width: 1.4rem;
    height: 1.4rem;
    fill: ${colors.primary};
  }

  @media (max-width: 768px) {
    display: none;
  }
`;
