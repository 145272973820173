import React from 'react';

// node
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

// Styles
import { TitleCategory } from '../../styles/titles';
import * as SkeletonStyle from './style';

const SkeletonProducts = props => {
  return (
    <SkeletonTheme color="#efefef" highlightColor="#f7f7f7">
      <SkeletonStyle.Style>
        {props.title ? (
          <TitleCategory>
            <Skeleton />
          </TitleCategory>
        ) : (
          ''
        )}
        <SkeletonStyle.ProductsWrapper>
          {[0, 1, 2].map((item, i) => {
            return (
              <SkeletonStyle.ProductStyle key={i}>
                <header>
                  <div>
                    <SkeletonStyle.Image>
                      <Skeleton />
                    </SkeletonStyle.Image>
                  </div>
                </header>
                <h1>
                  <Skeleton />
                </h1>
                <p>
                  <Skeleton count={3} />
                </p>
                <footer>
                  <p>
                    <Skeleton />
                  </p>
                </footer>
              </SkeletonStyle.ProductStyle>
            );
          })}
        </SkeletonStyle.ProductsWrapper>
      </SkeletonStyle.Style>
    </SkeletonTheme>
  );
};

export default SkeletonProducts;
