import { createGlobalStyle } from 'styled-components';

// Image
import bgCards from '../assets/images/sprite-cards-rodape.png';
import amexCard from '../assets/images/amex-card.png';
// Variables
import { borderRadius, colors, fontSize, heightInputs } from './variables';

import 'react-toastify/dist/ReactToastify.css';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    outline: 0;
  }

  html {
    font-size: 87.5%;
  }

  body {
    font-family: "Open Sans", sans-serif;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    min-height: 100%;
    min-width: 100%;
    font-size: 1rem;
    line-height: 1;
    font-display: swap;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: ${colors.grey.lightPrimary};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${colors.primary};
  }

  .action-link {
    cursor: pointer;
  }

  /*Style DatePicker*/

  .date__picker {
    &-container {

      span {
        color: ${colors.primary};
        margin-bottom: .5rem;
      }
    }

    &-content {
      width: 100%;
      align-items: flex-end;

      @media (max-width: 768px) {
        align-items: flex-start;

        p {
          padding: 0 1rem;
        }

        div {
          width: 100%;
        }
      }

      input {
        border:1px solid ${colors.primary};
        height: ${heightInputs};
        text-indent: 0.5rem;
        width: 100%;

        @media (max-width: 768px) {
          margin-bottom: .5rem;
        }
      }

      &-text {
        display: flex;
        align-items: center;
        padding: 1rem 0;
        color: ${colors.primary};
        margin-bottom: .5rem;
      
      
        @media (max-width: 768px) {
            margin-bottom: .5rem;
            padding: 0;
        }
      }
    }
  }

  .carousel__dot-group {
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    .carousel__dot {
      margin: 1rem;
      padding: .5rem;
      background-color: ${colors.grey.darkSecondary};
      border: none;
      border-radius: ${borderRadius};

    }
  
    .carousel__dot--selected {
      background-color: ${colors.primary};
    }

  }

  .slick-arrow {
    z-index: 50;
    
    &::before {
      color: ${colors.grey.darkPrimary} !important;
      font-size: 2rem !important;
      opacity:1 !important;
    }
  }

  .slick-next {
    right: 25px !important;
  }

  .slick-prev {
    left: 25px !important;
  }

  .no-link {
    text-decoration: none;
  }

  // pagination
  .pagination {
    display: flex;
    justify-content: center;
  }

  .pagination li:hover:not(.active) {
    background-color: #ddd;
    border-radius: 5px;
  }

  .pagination li {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    list-style-type: none;
    cursor: pointer;

    &:first-of-type {
      background-color: ${colors.secondary};
      color: #FFF;
      border-radius: 5px;
    }

    &:last-of-type {
      background-color: ${colors.secondary};
      color: #FFF;
      border-radius: 5px;
    }
  }

  .pagination li.active {
    background-color: ${colors.secondary};
    color: white;
    border-radius: 5px;
  }

  .card-list {
    width: 40px;
    height: 25px;
    background-image: url(${bgCards});
    border:1px solid #f2f2f2;
  }

  .amex {
    background-image: url(${amexCard});
    background-size: cover;
    background-position-y: -7px;
  }

  .mastercard {
    background-position: 0 0;
  }

  .em-moeda {
    background-position: 0 -28px;
  }

  .banco-do-brasil {
    background-position: -43px 81px;
  }

  .caixa-economica-federal {
    background-position: -169px -28px;
  }

  .bradesco {
    background-position: -85px -28px;
  }

  .dinersclub {
    background-position: -85px -1px;
  }

  .itau {
    background-position: -126px -28px;
  }

  .visa {
    background-position: -44px -1px;
  }

  .pix {
    background-position: -86px -82px;
  }

  .hipercard {
    background-position: -127px -1px;
  }

  .itau-shopline {
    background-position: -211px -28px;
  }

  .elo {
    background-position: -86px -57px;
  }

  .jcb {
    background-position: -210px -57px;
  }

  .unionpay {
    background-position: -126px -56px;
  }

  .soro-cred {
    background-position: -170px -56px;
  }

  .cabal {
    background-position: -2px -82px;
  }

  .pagination {
    li {
      margin-right: .5rem;

      &:last-of-type {
        margin-right: 0;
      }

      &.previous {
        background-color: ${colors.primary};

        &:hover {
          background-color: ${colors.primary};
        }
      }

      &.next {
        background-color: ${colors.primary};

        &:hover {
          background-color: ${colors.primary};
        }
      }
    }
  }

  .__react_component_tooltip {
    max-width: 200px;
  }

  .Toastify__toast {
    border-radius: 5px !important; 
    font-weight: normal;
    text-align: center;
  }

  .text-error {
    p {
      font-size: ${fontSize.sm};
      font-weight: bold;
      margin: 0.5rem;
      position: absolute;
      bottom: 0px;
      right: 0;
      color: ${colors.default};

      svg {
        width: 1.2rem !important;
        height: 1.2rem !important;
        fill: ${colors.error};
      }
    }

    span {
      color: ${colors.default};
    }

    &-message {
      position: absolute;
      bottom: -15px;
      color: ${colors.error};
      left: 1rem;
    }

  }

  .text-warning {
    font-size: ${fontSize.sm};
    font-weight: bold;
    color: ${colors.blue};
    margin: 0.5rem;
  }

  .input-loading {
    margin: 0.5rem;
    position: absolute;
    bottom: -2px;
    right: 0.5rem;

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  .text-warning {
    font-size: ${fontSize.sm};
    font-weight: bold;
    margin: 0.5rem;    
  }
`;

export default GlobalStyle;
