import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, Form, reduxForm, submit } from 'redux-form';

// node-modules
import { toast } from 'react-toastify';

// hocs
import Modal from '../../hocs/Modal';

//Components
import LoaderForm from '../../components/Loader/loaderForm';

// validate
import renderField from '../../formValidate/renderField';
import { mapErrors } from '../../formValidate/handleErrors';
import { maskCelPhone, maskCpf } from '../../formValidate/masks';
import { required, lengthPhone, cpf } from '../../formValidate';

// actions
import { modalShippingContact } from '../../actions/modals';
import {
  saveShippingContact as saveShippingContactAction,
  isSubmitForm,
} from '../../actions/cart';
// services
import { saveShippingContact } from '../../services/cart';

// utils
import { ERROR, CONNECTION_ERROR } from '../../utils/constMessage';

// styles
import { FormContainer } from '../style';
import { TitleCards } from '../../styles/titles';
import { Row, Col } from '../../styles/align';
import { Wrapper, BtnGroup, Button, Label } from '../../styles/forms';
import {StyleShippingContact} from './style'

class ShippingContact extends Component {
  state = {
    errors: {
      contato_transporte: '',
      telefone_transporte: '',
      documento_transporte: ''
    },
  };

  componentWillUnmount() {
    this.props.isSubmitForm(false);
  }

  handleErrors = response => {
    if (response.errosValidacao) {
      const errors = mapErrors(response.errosValidacao);
      this.setState({ errors });
      return false;
    }

    if (response.message) {
      toast.warn(response.message);
    } else {
      toast.error(ERROR);
    }
  };

  submit = data => {
    const { dispatch, isSubmitCart } = this.props;
    saveShippingContact(data).then(response => {
      if (response.status === 200) {
        this.props.modalShippingContact();
        this.props.saveShippingContactAction();
        //toast.(SUCCESS);
        if (isSubmitCart) {
          dispatch(submit('cart'));
        }
      } else if (response.errors) {
        this.handleErrors(response.errors);
      } else {
        toast.error(CONNECTION_ERROR);
      }
    });
  };

  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <StyleShippingContact>
        <TitleCards>Dados para contato:</TitleCards>
        <Form onSubmit={handleSubmit(this.submit)}>
          <p>Por favor, nos informe um telefone para contato.</p>
          <p>
            Estas informações serão utilizadas para que não haja falha na
            entrega do seu pedido.
          </p>
          <Col>
            <Row>
              <Wrapper>
                <Label htmlFor={'contato_transporte'}>
                  Contato <strong>*</strong>
                </Label>
                <Field
                  name={'contato_transporte'}
                  id={'contato_transporte'}
                  component={renderField}
                  validate={required}
                  responseError={this.state.errors.contato_transporte}
                />
              </Wrapper>
              <Wrapper>
                <Label htmlFor={'documento_transporte'}>
                  CPF <strong>*</strong>
                </Label>
                <Field
                  name={'documento_transporte'}
                  id={'documento_transporte'}
                  typefield={'mask'}
                  placeholder={'Digite seu CPF'}
                  validate={[required, cpf]}
                  mask={maskCpf}
                  responseError={this.state.errors.documento_transporte}
                  component={renderField}
                  autoFocus
                />
              </Wrapper>
              <Wrapper>
                <Label htmlFor={'telefone_transporte'}>
                  Telefone <strong>*</strong>
                </Label>
                <Field
                  name={'telefone_transporte'}
                  id={'telefone_transporte'}
                  typefield={'mask'}
                  mask={maskCelPhone}
                  validate={[required, lengthPhone]}
                  component={renderField}
                  responseError={this.state.errors.telefone_transporte}
                />
              </Wrapper>
            </Row>
          </Col>
          <BtnGroup row>
            <span className={'required'}>* Campos Obrigatórios</span>
            <span>
              <Button onClick={this.props.modalShippingContact}>
                Cancelar
              </Button>
              <Button secondary type={'submit'} disabled={submitting}>
                Enviar{submitting ? <LoaderForm /> : ''}
              </Button>
            </span>
          </BtnGroup>
        </Form>
      </StyleShippingContact>
    );
  }
}

const mapStateToProps = state => {
  return {
    isSubmitCart: state.cart.isSubmitForm,
  };
};

ShippingContact = reduxForm({
  form: 'formModalShippingContact',
})(ShippingContact);

ShippingContact = connect(mapStateToProps, {
  modalShippingContact,
  saveShippingContactAction,
  isSubmitForm,
})(ShippingContact);

ShippingContact = Modal(ShippingContact);

export default ShippingContact;
