import styled from 'styled-components';

// Style
import { Container } from '../../styles/align';
import { NavStyleMobile } from '../Menu/style';
import { colors, fontSize } from '../../styles/variables';

/**
 * Formata o Container header
 *
 * @extends {header} Tag Nativa do Html
 *
 * Herda estilo para formatar o interior do Container
 * @extends {NavStyleMobile}
 * @extends {Container}
 * @extends {fontSize}
 * @extends {colors}
 *
 * Recebe parametros Para definir o header fixed
 * @param {props.position} "relative || fixed"
 */
export const header = styled.header`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${colors.grey.light};
  padding: ${props => props.position};
  border-bottom: 5px solid ${colors.secondary};
  position: ${props => props.position};
  z-index: 110;
  padding: 0.8rem;

  @media (min-width: 476) {
    flex-direction: row;
  }

  &.active ~ ${NavStyleMobile} {
    left: 0;
    transition: left 400ms ease-in-out;
  }

  &.hidden ~ ${NavStyleMobile} {
    left: -70vw;
    transition: left 400ms ease-in-out;
  }

  ${Container} {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${fontSize.md};

    @media (max-width: 768px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  .header-link {
    font-size: ${fontSize.sm};
    padding: 1rem 0;
    font-weight: 900;

    a {
      display: flex;
      align-items: flex-end;
      text-decoration: none;
      color: ${colors.primary};
      font-weight: bold;
    }
  }
`;

/**
 * Formata o Menu Mobile no Header
 *
 * @extends {div} Tag div do Html
 *
 * Herda estilo para formatar o interior do Container
 * @extends {colors}
 */
export const MenuMobile = styled.div`
  padding: 0.5rem;
  background-color: ${colors.grey.lightSecondary};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }

  svg {
    width: 1rem;
    height: 1rem;
    fill: ${colors.default};
  }
`;

/**
 * Formata o bloco de instruções no Header
 *
 * @extends {div} Tag div do Html
 */
export const Intructions = styled.div`
  text-align: center;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const ContainerInstructions = styled.div`
  align-items: center;
  display: flex;
  gap: 24px;
  margin-right: 2.5rem;
`