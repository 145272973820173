import styled from 'styled-components';

// Styles
import { colors, fontSize } from '../../styles/variables';
import { Wrapper } from '../../styles/forms';

/**
 * Formata o container da NewsLetter
 *
 * @extends {div} Tag div Nativa do HTML
 *
 * Herda estilo para formatar o interior do container
 * @extends {colors}
 */
export const NewsletterWrapper = styled.div`
  width: 100%;
  background-color: ${colors.grey.darkPrimary};
  padding: 1rem 0;
`;

/**
 * Formata o bloco de texto da NewsLetter
 *
 * @extends {div} Tag div Nativa do HTML
 *
 * Herda estilo para formatar o interior do bloco
 * @extends {colors}
 */
export const NewsletterContent = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${colors.default};
  padding: 0 3rem;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  span {
    font-weight: bold;
    margin-right: 1rem;

    @media (max-width: 768px) {
      margin-bottom: 1rem;
    }
  }
`;

/**
 * Formata o bloco do form da NewsLetter
 *
 * @extends {div} Tag div Nativa do HTML
 *
 * Herda estilo para formatar o interior do bloco
 * @extends {Wrapper}
 * @extends {fontSize}
 * @extends {colors}
 */
export const NewsletterForm = styled.div`
  width: 50%;
  padding: 0 3rem;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }

  form {
    background-color: ${colors.default};
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 50px;
    overflow: hidden;
    justify-content: space-between;

    @media (max-width: 768px) {
      width: 100%;
      flex-direction: column;
      background-color: transparent;
      border-radius: 0;
    }

    & > label {
      padding: 0;
    }

    ${Wrapper} {
      @media (max-width: 768px) {
        width: 100%;
      }

      input {
        width: 100%;
        border: none;
        height: 40px;

        @media (max-width: 768px) {
          margin-bottom: 1rem;
        }

        &:last-of-type {
          border-left: 1px solid ${colors.grey.lightSecondary};
          border-radius: 50px;
        }
      }
    }

    button {
      height: 40px;
      background-color: ${colors.secondary};
      color: ${colors.default};
      text-decoration: none;
      font-weight: 700;
      font-size: ${fontSize.sm};
    }
  }
`;
