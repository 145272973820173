import styled from 'styled-components';
import { colors, fontSize } from './variables';

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 1400px;
  flex-direction: row;
  margin: 0 auto;
  padding: 0 2rem;
`;

export const Row = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  justify-content: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CapaRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  p {
    padding: 0.5rem 1rem;
  }
`;

export const Col = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  &.limit__box-180 {
    max-height: 180px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
`;

export const MainPage = styled.div`
  min-height: 400px;
  width: ${props => (props.widthContent ? props.widthContent : '75%')};
  background-color: ${props => (props.bg ? props.bg : colors.grey.light)};
  padding: ${props => (props.padding ? props.padding : '0')};
  display: flex;
  justify-content: space-between;
  height: fit-content;
  flex-direction: column;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Sidebar = styled.div`
  width: ${props => (props.widthContent ? props.widthContent : '25%')};
  padding: ${props => (props.padding ? props.padding : '0')};
  margin: ${props => (props.margin ? props.margin : '0')};
  background-color: ${props =>
    props.dark ? colors.grey.lightPrimary : 'transparent'};
  & > label {
    padding: 2rem 0;
    justify-content: center;

    span {
      font-size: ${fontSize.md};
      font-weight: 600;

      span {
        font-weight: 900;
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

export const TextAlign = styled.span`
  width: 100%;
  text-align: ${props => (props.textalign ? props.textalign : 'left')};
  line-height: 1.5;
  font-size: ${fontSize.md};
  margin: 1rem 0;
  color: ${colors.grey.dark};
`;

export const SeparatorH = styled.hr`
  border: 1px solid #ddd;
  margin-bottom: 1rem;
`;

export const RowCenter = styled.div`
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;

  @media (max-width: 768px) {
    padding: 1rem;
    text-align: center;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ColGrid = styled.div`
  float: left;
  padding: 0.25rem;
  min-height: 1px;
  box-sizing: border-box;

  @media only screen and (max-width: 768px) {
    ${({ mobile }) => mobile && getWidthGrid(mobile)}
  }

  @media only screen and (min-width: 768px) {
    ${({ tablet }) => tablet && getWidthGrid(tablet)}
  }

  @media only screen and (min-width: 1000px) {
    ${({ desktop }) => desktop && getWidthGrid(desktop)}
  }
`;

export const getWidthGrid = value => {
  if (!value) return;

  let width = (value / 12) * 100;
  return `width: ${width}%`;
};
