import React from 'react';

// services
import { downloadFile } from '../../services/files';

// styles
import { Wrapper, Label, Select, Option } from '../../styles/forms';

const TemplateFiles = ({ gabaritos }) => {
  if (gabaritos.length === 0) {
    return '';
  }

  return (
    <Wrapper>
      <Label htmlFor={'gabaritos'}>Gabaritos</Label>
      <Select
        id={'gabaritos'}
        onChange={e => downloadFile(e.target.value)}
        dark
      >
        <Option value="">Download dos Padrões</Option>
        {gabaritos.map((gabarito, i) => {
          return (
            <Option key={i} value={gabarito.value}>
              {gabarito.desc}
            </Option>
          );
        })}
      </Select>
    </Wrapper>
  );
};

export default TemplateFiles;
