import styled from 'styled-components';

// Styles
import { Label, Wrapper } from '../../../../styles/forms';
import { colors } from '../../../../styles/variables';

/**
 * Formata os Wrappers de Serviços do Livro
 * @extends {Wrapper}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Label}
 * @extends {colors}
 */

export const Container = styled.div `
  display: flex;
  justify-content: space-between;
  
`
export const ContainerTooltip = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.2rem;
  gap: 0.8rem
`;
export const BookService = styled(Wrapper)`
  border: 1px solid ${colors.grey.darkPrimary};
  margin-bottom: 0.5rem;
  width: 100%;

  ${Label} {
    display: flex;
    justify-content: space-between;

    span {
      &:last-of-type {
        font-weight: 700;
        color: ${colors.secondary};
      }
    }
  }
`;
