import React, { Component } from 'react';
// node-modules
import { toast } from 'react-toastify';
// services
import loja from '../../apis/loja';
// styles
import { Container, Row } from '../../styles/align';
import { Wrapper, Input, Button } from '../../styles/forms';
import { NewsletterWrapper, NewsletterContent, NewsletterForm } from './style';
// utils
import { SUCCESS, ERROR } from '../../utils/constMessage';

class Newsletter extends Component {
  state = {
    nome_newsletter: '',
    email_newsletter: '',
  };
  baseState = this.state;

  resetForm = () => {
    this.setState(this.baseState);
  };

  handleSignIn = async e => {
    e.preventDefault();
    const { nome_newsletter, email_newsletter } = this.state;
    if (!nome_newsletter || !email_newsletter) {
      toast.warn('Preencha nome e email para continuar!');
    } else {
      try {
        await loja.post('/newsletter/salvar', {
          nome_newsletter,
          email_newsletter,
        });
        this.resetForm();
        toast.success(SUCCESS);
      } catch (err) {
        toast.error(ERROR);
      }
    }
  };

  render() {
    return (
      <>
        <NewsletterWrapper>
          <Container>
            <Row>
              <NewsletterContent>
                <span>NEWSLETTER</span>
                <p>
                  Cadastre-se e receba novidades, ofertas exclusivas e muito
                  mais.
                </p>
              </NewsletterContent>
              <NewsletterForm>
                <form onSubmit={this.handleSignIn}>
                  <Wrapper>
                    <Input
                      type="text"
                      placeholder="Nome"
                      onChange={e =>
                        this.setState({ nome_newsletter: e.target.value })
                      }
                      value={this.state.nome_newsletter}
                      required
                    />
                  </Wrapper>
                  <Wrapper>
                    <Input
                      type="email"
                      placeholder="Email"
                      required
                      onChange={e =>
                        this.setState({ email_newsletter: e.target.value })
                      }
                      value={this.state.email_newsletter}
                    />
                  </Wrapper>
                  <Button secondary type="submit" style={{ alignItems: "center" }}>
                    Receber
                  </Button>
                </form>
              </NewsletterForm>
            </Row>
          </Container>
        </NewsletterWrapper>
      </>
    );
  }
}

export default Newsletter;
