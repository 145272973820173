import styled from 'styled-components';

// Style
import { Col } from '../../styles/align';
import { colors, fontSize } from '../../styles/variables';
import { Wrapper, Label, ButtonPrimary } from '../../styles/forms';

export const Simulate = styled(Col)`
  margin-bottom: 0.5rem;
  background-color: ${colors.grey.light};
  padding: 1rem;

  ${Wrapper} {
    ${Label} {
      padding-left: 0;
      color: ${colors.primary};
      font-weight: 600;
    }

    ${ButtonPrimary} {
      position: absolute;
      bottom: 2px;
      right: 2px;
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
    }
  }
`;

export const Type = styled.span`
  width: 100%;
  color: ${colors.primary};
`;
export const Value = styled.span`
  color: ${colors.secondary};
  font-weight: 700;
`;

export const Desc = styled.span`
  font-weight: 700;
  margin-right: 0.5rem;
`;

export const Alert = styled.span`
  font-size: 0.75rem;
  color: ${colors.primary};
  line-height: 1.5;
  padding: 0 1rem;
`;

export const ListSimulateDelivery = styled.ul`
  list-style: none;

  li {
    padding: 1rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    font-size: ${fontSize.sm};

    & > span {
      display: flex;
      flex-direction: row;
      align-items: center;

      ${ButtonPrimary} {
        padding: 0.4rem;
        font-size: ${fontSize.sm};
        text-transform: Capitalize;
        margin-left: 0.4rem;
      }
    }

    &:last-of-type {
      ${Type} {
        width: auto;
      }
    }
  }
`;
