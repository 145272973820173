import axios from 'axios';

import config from '../config';

import { toast } from 'react-toastify';
import { getToken, logout, setToken } from '../services/user';

/**
 * Url base da api
 */
const apiLoja = axios.create({
  baseURL: config.apis.loja,
});

/**
 * Solicitações feitas através da apiLoja que falharem
 * Seram automaticamente retentadas com delay entre cada. 
 */
// axiosRetry(apiLoja, {
//   retries: 3,
//   retryDelay: (retryCount) => {
//     console.log(`Retentativa número: ${retryCount}`);
//     return retryCount * 200 
//   },
  
//   retryCondition: (error) => {
//      console.log('Retentativa', error);
//       return error.response.status !== 200 || !error.response;
//   }
// })

/**
 * Interceptor de toda requisição que o projeto irá fazer para essa API
 *
 * Envia o token de autenticação em todas as requisições desde que o
 * usuário esteja logado
 */
apiLoja.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

apiLoja.interceptors.response.use(
  function(response) {
    // verifica se a resposta veio com o token de autorização
    // e atualiza o storage
    if (response.headers.authorization) {
      setToken(response.headers.authorization);
    }
    return response;
  },
  function(error) {
    if (error.response) {
      // caso venha o status de "não autorizado" desloga
      if (error.response.status === 401) {
        logout();
        // caso não esteja na home, redireciona para tal
        if (window.location.pathname !== '/') {
          window.location.href = '/';
        }
      }

      if (error.response.status === 422) {
        if (
          error.response.data.bloqueio &&
          window.location.href !== '/desativado'
        ) {
          window.location.href = '/desativado';
        }
      }

      // retorna o error reject
      return Promise.reject(error);
    } else if (error.request) {
      const { message } = JSON.parse(error.request.response);

      toast.error(message);

      if (window.location.pathname !== '/') {
        window.location.href = '/';
      }
    } else {
      // caso não esteja na home, redireciona para tal
      if (window.location.pathname !== '/') {
        window.location.href = '/';
      }
    }
  }
);

export default apiLoja;
