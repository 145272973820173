import loja from '../apis/loja';

import { FETCH_CATEGORY } from './types';

/**
 * Busca as categorias de acordo com a url_amigavel
 * @param {string} url_amigavel
 */
export const fetchCategory = url_amigavel => async dispatch => {
  try {
    const response = await loja.get(`/rpc/categoria/${url_amigavel}`);

    dispatch({ type: FETCH_CATEGORY, payload: response.data });
  } catch (error) {
    dispatch({
      type: FETCH_CATEGORY,
      payload: { menu: url_amigavel, data: error.response.status },
    });
  }
};
