import React, { Component } from "react";
import { connect } from "react-redux";

// Actions
import { modalBknbank } from "../../actions/modals";

// Styles
import { Row } from "../../styles/align";
import { BtnGroup, Button } from "../../styles/forms";
import { Loading } from "../style";
import { StyleBknBank } from "./style";

// Hocs
import Modal from "../../hocs/Modal";
import Loader from "../../components/Loader";

class BknBank extends Component {
  state = {
    loading: false
  };

  handleCallbackTrue = () => {
    this.setState({
      loading: true
    });
    this.props.callbackTrue();
  };

  handleCallbackFalse = () => {
    this.setState({
      loading: true
    });
    this.props.callbackFalse();
  };

  render() {
    return (
      <StyleBknBank>
        {this.state.loading ? (
          <Loading>
            <Loader />
          </Loading>
        ) : (
          ""
        )}
        <Row>
          <p>Caro cliente identificamos que seu boleto está vencido, deseja gerar outro boleto ? Se o boleto já foi pago ignore essa mensagem e aguarde a realização da compensação.</p>
        </Row>
        <Row>
          <BtnGroup>
            <Button onClick={this.handleCallbackFalse}>Fechar</Button>
            <Button onClick={this.handleCallbackTrue}>Sim, gerar outro boleto</Button>
          </BtnGroup>
        </Row>
      </StyleBknBank>
    );
  }
}

const mapStateToProps = state => {
  return {
    callbackTrue: state.modals.modalBknbank.data.callbackTrue,
    callbackFalse: state.modals.modalBknbank.data.callbackFalse
  };
};

BknBank = connect(mapStateToProps, { modalBknbank })(BknBank);

BknBank = Modal(BknBank);

export default BknBank;
