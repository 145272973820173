import axios from "axios";

import config from "../config";

/**
 * Url base do serviço
 */
const baixaArquivos = axios.create({
  baseURL: config.apis.baixaArquivos
});

export default baixaArquivos;
