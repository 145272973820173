import loja from '../apis/loja';
import { insertDot } from '../utils';

import {
  CHANGE_NUMBER,
  CHANGE_NAME_CARD,
  CHANGE_MONTH,
  CHANGE_YEAR,
  CHANGE_CVC,
  CLEAR_CVC,
  CLEAR_DATA_CARD,
  CHANGE_PARCELS,
  CLEAR_PAYMENT,
  TYPE_PAYMENT,
  PAYMENT,
  PAYMENTS,
  HASH_CARD,
  MULTIPLE_PAYMENTS,
  CLEAR_PAYMENTS,
  VIEW_PAYMENTS,
  CLEAR_HASH_CARD,
  CHANGE_DEPENDENCY,
  PAYMENT_WITH_TWO_CARDS,
  FETCH_INSTALLMENTS_WITH_TWO_CARDS,
  UPDATE_TWO_CARDS,
  CLEAN_TWO_CARDS,
  CALCULATE_VALUE_TWO_CARDS,
} from './types';
import { toast } from 'react-toastify';

/**
 * altera o numero do cartao
 */
export const changeNumber = value => {
  return {
    type: CHANGE_NUMBER,
    payload: value,
  };
};

/**
 * altera o nome do cartao
 */
export const changeNameCard = value => {
  return {
    type: CHANGE_NAME_CARD,
    payload: value,
  };
};

/**
 * altera o mes do cartao
 */
export const changeMonth = value => {
  return {
    type: CHANGE_MONTH,
    payload: value,
  };
};

/**
 * altera o ano do cartao
 */
export const changeYear = value => {
  return {
    type: CHANGE_YEAR,
    payload: value,
  };
};

/**
 * altera o cvc do cartao
 */
export const changeCVC = value => {
  return {
    type: CHANGE_CVC,
    payload: value,
  };
};

/**
 * altera as parcelas do cartao
 */
export const changeParcels = value => {
  return {
    type: CHANGE_PARCELS,
    payload: value,
  };
};

/**
 * limpa os dados do pagamento
 */
export const clearPayment = () => {
  return {
    type: CLEAR_PAYMENT,
  };
};

/**
 * limpa o cvc
 */
export const clearCvc = () => {
  return {
    type: CLEAR_CVC,
  };
};

/**
 * limpa os dados do cartão (titular, mes, ano e cvc)
 * caso falte algum dado, limpa no reducer para garantir
 */
export const clearDataCard = () => {
  return {
    type: CLEAR_DATA_CARD,
  };
};

/**
 * altera o tipo de pagamento
 */
export const changeTypePayment = value => {
  return {
    type: TYPE_PAYMENT,
    payload: value,
  };
};

/**
 * altera a dependencia do pagamento
 */
export const changePayment = value => {
  return {
    type: PAYMENT,
    payload: value,
  };
};

/**
 * popula os pagamentos
 */
export const setPayments = value => {
  return {
    type: PAYMENTS,
    payload: value,
  };
};

/**
 * popula os hash de pagamento
 * @param {*} value
 */

export const hashCard = value => {
  return {
    type: HASH_CARD,
    payload: value,
  };
};

/**
 * clear Hash
 */
export const clearHashCard = () => {
  return {
    type: CLEAR_HASH_CARD,
  };
};

/**
 * altera se o pagamento sera multiplo
 */
export const changeMultiplePayment = value => {
  return {
    type: MULTIPLE_PAYMENTS,
  };
};

/**
 * limpa os dados dos pagamentos
 */
export const clearPayments = () => {
  return {
    type: CLEAR_PAYMENTS,
  };
};

export const changeDependency = value => {
  return {
    type: CHANGE_DEPENDENCY,
    payload: value,
  };
};

/**
 * busca os dados para a page dos pagamentos
 */

export const getViewPayments = () => async dispatch => {
  try {
    const response = await loja.get('/view-formas-de-pagamento');

    dispatch({ type: VIEW_PAYMENTS, payload: response.data });
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

export const handlePaymentWithTwoCards = value => {
  return {
    type: PAYMENT_WITH_TWO_CARDS,
    payload: value,
  };
};

export const fetchInstallments = (
  firstCardValue,
  secondCardValue
) => async dispatch => {
  try {
    const response = await loja
      .post('/carrinho/dois-cartoes', {
        valorPrimeiroCartao: insertDot(firstCardValue),
        valorSegundoCartao: insertDot(secondCardValue),
      })
      .then(response => response.data);

    dispatch({
      type: FETCH_INSTALLMENTS_WITH_TWO_CARDS,
      payload: response.data,
    });
  } catch (error) {
    if (typeof error == 'object') {
      toast.error('Tente novamente mais tarde');
      return;
    }
    toast.error(error);
  }
};

export const calculateValueTwoCards = (card, value) => async dispatch => {
  dispatch({
    type: CALCULATE_VALUE_TWO_CARDS,
    payload: { card, value },
  });
};

export const updateTwoCards = (card, field, value) => async dispatch => {
  dispatch({
    type: UPDATE_TWO_CARDS,
    payload: { card, field, value },
  });
};

export const cleanTwoCards = () => async dispatch => {
  dispatch({
    type: CLEAN_TWO_CARDS,
  });
};

export const cleanInstallmentsTwoCards = () => async dispatch => {
  dispatch({
    type: FETCH_INSTALLMENTS_WITH_TWO_CARDS,
    payload: {
      firstCard: [],
      secondCard: [],
    },
  });
};
