import React from 'react';
import { Field } from 'redux-form';

// hocs
import CardFilter from '../../../../hocs/CardFilter';

// Validate
import renderField from '../../../../formValidate/renderField';

// Styles
import { Label } from '../../../../styles/forms';
import { BookService, Container, ContainerTooltip } from './style';

// utils
import { numberFormat } from '../../../../utils';
import Tooltip from '../../../Tooltip';


function renderDescWithDeadline(item) {
  let prazo = '';
  if (item.prazo !== 0) {
    prazo =
      item.prazo > 1
        ? `(+ ${item.prazo} dias úteis)`
        : `(+ ${item.prazo} dia útil)`;
  }

  return (
    <>
      <span>
        {item.descricao} <small> {prazo}</small>
      </span>
      <span>R$ {numberFormat(item.vlr_total, 2)}</span>
    </>
  );
}




const BookServices = ({ data, updateService,  }) => {
  const { servicos } = data;

  return (
    <>
    
      {servicos
        ? servicos.map((item, i) => {
            return (
              <Container>
              <BookService key={i}>
                <Field
                  name={`servicos[${item.fk_servico}]`}
                  id={`servicos[${item.fk_servico}]`}
                  type={'checkbox'}
                  typefield={'checkbox'}
                  component={renderField}
                  value={`${item.fk_servico}`}
                  onChange={e => updateService(item.fk_servico)}
                />
                <Label htmlFor={`servicos[${item.fk_servico}]`}>
                  {renderDescWithDeadline(item)}
                </Label>
              </BookService>
              <ContainerTooltip>
                <Tooltip
                dataId={`${item.fk_servico}`}
                text={ item.descricao === 'Criação de capa'
                    ? `<strong>o que será feito?</strong> <br />
                      <br />
                      <p>Iremos Desenvolver o Layout ou aprimorar o arquivo que o cliente deseja utilizar na Capa e adaptar aos padrões da gráfica.</p>`
                    : item.descricao === 'Diagramação simples'
                    ? `<strong>o que será feito?</strong> <br />
                      <br /> 
                      <p>Ajustes simples da capa e miolo, adaptando ao gabarito e padrão gráfico como 
                      (Tamanho, Sangria , Bordas Brancas, Margem de Segurança). Não temos tratamento 
                      de imagem para baixa qualidade e correção ortográfica, trabalhamos com o arquivo 
                      fechado do cliente deixando próprio para impressão.`
                    : `Texto padrão para outras descrições</p><br />`}
                size={0.8}
                /> 
              </ContainerTooltip>
                </Container>
            );
          })
        : ''}
    </>
  );
};

export default CardFilter(BookServices);
