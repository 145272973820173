import styled from 'styled-components';
import { colors } from '../../../styles/variables';
import { Label } from '../../../styles/forms';

export const WrapperTwoForms = styled.div`
  display: flex;
  width: 100%;
  gap: 1%;

  @media (max-width: 1080px) {
    flex-direction: column;
    width: 100%;
  }
  @media (min-width: 1080px) {
    gap: 8%;
  }
`;

export const WrapperCardForm = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    min-width: 410px;
  }
`;

export const ContainerNumberCard = styled.div`
  max-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin: 0;
    max-width: 100%;
  }
`;

export const TitleCard = styled.h3`
  font-weight: 700;
  color: ${colors.primary};
  margin-top: 1rem;
`;

export const Separator = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${colors.primary};
  margin: 1rem 0;
`;
export const WrapperExpirationDate = styled.div`
  display: flex;
  gap: 16px;
`;

export const WrapperCvcInstallments = styled.div`
  display: flex;
  gap: 16px;
`;

export const LessMarginLabel = styled(Label)`
  margin-bottom: 4px !important;
`;

export const FirstInstallmentsMessage = styled.p`
  font-size: 14px;
  padding: 8px;
  color: ${colors.primary};
  background: ${colors.blue.blue50};
  border-radius: 6px;
  margin-top: 6px;
  margin-left: -2px;
`;

export const Loading = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: ${colors.default};
  opacity: 0.8;
  z-index: 100;
  position: absolute;
  pointer-events: stroke;
  left: 0;
`;
