import loja from '../apis/loja';

import {
  SIGN_IN,
  SIGN_OUT,
  EMAIL_REGISTER,
  FETCH_USER,
  NUMBER_ITEMS,
  COOKIES_SUCCESS,
  FETCH_PRIVACY_USER,
  CLEAR_PRIVACY_USER,
  UPDATE_PRIVACY_USER,
} from './types';
import { toast } from 'react-toastify';

/**
 * Faz o login no redux enviando os dados do usuário
 * (informações sobre o login em services/user)
 * @param {object} data
 */
export const signIn = data => dispatch => {
  if (data.carrinho) {
    dispatch({ type: NUMBER_ITEMS, payload: data.carrinho.qtd_itens });
  }

  dispatch({ type: SIGN_IN, payload: data });
};

/**
 * Desloga o usuário do store
 */
export const signOut = () => dispatch => {
  dispatch({ type: SIGN_OUT });
  dispatch({ type: NUMBER_ITEMS, payload: 0 });
};

/**
 * Email que o usuário digita antes de abrir o formulário de cadastro
 * @param {string} email
 */
export const emailRegister = email => {
  return {
    type: EMAIL_REGISTER,
    payload: email,
  };
};

/**
 * Busca os dados do usuário logado
 */
export const fetchUser = () => async dispatch => {
  try {
    const response = await loja.post('/usuario/buscar');
    if (response.data.data.carrinho) {
      dispatch({
        type: NUMBER_ITEMS,
        payload: response.data.data.carrinho.qtd_itens,
      });
    }

    dispatch({ type: FETCH_USER, payload: response.data });
  } catch (error) {
    //toast.error(error.response.data.message);
  }
};

/**
 * Cookies Action
 */
export const cookiesAction = () => async dispatch => {
  try {
    const response = await loja.post('/cookies', { cookies: true });
    dispatch({ type: COOKIES_SUCCESS, payload: response.data });
    return response.data.data.success;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

/**
 * Busca os dados de privacidade do usuário logado
 */
export const fetchPrivacyUser = () => async dispatch => {
  try {
    const response = await loja.get('/usuario/buscar-privacidade', {
      params: {},
    });

    dispatch({ type: FETCH_PRIVACY_USER, payload: response.data });
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

/**
 * limpa os dados de privacidade do usuário logado
 */
export const clearPrivacyUser = () => dispatch => {
  dispatch({ type: CLEAR_PRIVACY_USER });
};

/**
 * Atualizar os dados de privacidade do usuário logado
 */
export const updatePrivacyUser = (
  pk_usuario,
  avisos,
  news_usuario,
  comerciais
) => async dispatch => {
  try {
    const response = await loja.post('/usuario/atualizar-privacidade', {
      pk_usuario,
      avisos,
      news_usuario,
      comerciais,
    });

    dispatch({ type: UPDATE_PRIVACY_USER, payload: response.data });
    return response.status;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};
