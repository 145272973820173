import styled from 'styled-components';

// Images
import caret from '../assets/images/icons/caret.png';

//Variables
import { borderRadius, colors, fontSize, heightInputs, radiusInputs } from './variables';

export const StyleForm = styled.div`
  h2 {
    padding: 1rem;
    font-size: ${fontSize.lg};
    font-weight: 600;
    color: ${colors.primary};
  }

  label {
    background-color: transparent;
    align-items: flex-start;
    margin-bottom: 1rem;

    span {
      margin-bottom: 0.5rem;
    }
  }

  .labels {
    display: flex;
    padding: 0 1rem;
    align-items: center;
    margin-bottom: 0;
  }
`;

// INPUTS
//Wrapper
export const Wrapper = styled.div`
  padding: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  border: none;
  border-radius: 5px;
  

  span {
    color: ${colors.primary};
    font-weight: 500;

    strong {
      margin-left: 0.5rem;
      color: ${colors.secondary};
    }

    .action-link {
      margin-left: 0;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;



export const WrapperCheckbox = styled.div`
  padding: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  border: none;
  border-radius: 5px;
  
  span {
    color: ${colors.primary};
    font-weight: 500;

    strong {
      margin-left: 0.5rem;
      color: ${colors.secondary};
    }

    .action-link {
      margin-left: 0;
      text-decoration: underline;
      cursor: pointer;
    }
  }  
`;

export const WrapperFiles = styled.div`
  width: 100%;
  position: relative;

  span {
    position: absolute;
    z-index: 50;
    top: 0.5rem;
    right: 0.5rem;
    cursor: pointer;

    &.send {
      padding: 0.5rem 1rem;
      border-radius: ${radiusInputs};
      background-color: ${colors.primary};
      font-size: ${fontSize.sm};
      color: ${colors.default};
      font-weight: 600;
    }

    &.sent {
      padding: 0;
      background-color: transparent;

      svg {
        position: absolute;
        top: 0;
        right: 0;
        width: 1.8rem !important;
        height: 1.8rem !important;
        fill: ${colors.success};
      }

      span {
        position: absolute;
        top: 0;
        right: 0;
        display: none;
        padding: 0.5rem;
        border-radius: ${radiusInputs};
        background-color: ${colors.error};
        font-size: ${fontSize.sm};
        color: ${colors.default};
        font-weight: 600;
      }

      & ~ input {
        &:after {
          display: none;
        }

        &::-webkit-file-upload-button {
          width: 0;
          height: 100%;
          display: block;
          visibility: hidden;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  &.error {
    input {
      border: 1px solid ${colors.error};
    }
  }

  &:hover {
    svg {
      display: none;
    }

    & > span {
      .sent {
        & > span {
          display: block;
        }
      }
    }
  }
`;

// BtnGroup
export const BtnGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  // flex-direction: column;
  
`;

export const Label = styled.label`
  width: 100%;
  padding: 0.6rem 0.6rem 0.6rem 2.6rem;
  background-color: transparent;
  z-index: 98;
  font-size: ${fontSize.md};
`;

// radio
export const Radio = styled.input.attrs(props => ({
  type: 'radio',
}))`
  visibility: hidden;
  display: none;
  align-items: center;
  justify-content: center;

  & ~ span {
    visibility: hidden;
    position: absolute;
    margin: 0.5rem 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    z-index: 97;

    &::before {
      content: '';
      width: 14px;
      height: 14px;
      position: absolute;
      visibility: visible;
      border-radius: ${borderRadius};
      background: ${colors.default};
      border: 1px solid ${colors.grey.dark};
    }
  }

  &:checked {
    & ~ span {
      z-index: 99;

      &::after {
        content: '';
        width: 10px;
        height: 10px;
        background-color: ${colors.primary};
        position: absolute;
        visibility: visible;
        border-radius: ${borderRadius};
      }
    }
  }

  &:disabled {
    & ~ span,
    & ~ label {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
`;

// Checkbox
export const Checkbox = styled.input.attrs(props => ({
  type: 'checkbox',
}))`
  margin-right: 0.5rem;
  visibility: hidden;
  display: none;
  position: relative;
  align-items: center;
  justify-content: center;

  & ~ span {
    visibility: hidden;
    position: absolute;
    margin: 0.5rem 0.9rem;
    width: 14px;
    height: 14px;
    z-index: 98;

    &::before {
      content: '';
      width: 13px;
      height: 13px;
      position: absolute;
      visibility: visible;
      border-radius: 5px;
      background: ${colors.default};
      border: 1px solid ${colors.grey.dark};
    }
  }

  &:checked {
    & ~ span {
      &::after {
        content: '';
        width: 4px;
        height: 14px;
        border-right: 3px solid ${colors.primary};
        border-bottom: 3px solid ${colors.primary};
        position: absolute;
        visibility: visible;
        transform: rotate(45deg);
        top: -4px;
        left: 6px;
      }
    }
  }

  &:disabled {
    & ~ span,
    & ~ label {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
`;

// Number
export const Number = styled.input.attrs(props => ({
  type: 'number',
  maxLength: '4',
}))`
  margin-left: 1rem;
  height: ${heightInputs};
  width: 60px;
  border-radius: ${radiusInputs};
  display: flex;
  text-indent: 1rem;
  color: ${colors.primary};
  font-weight: bold;
  border: 1px solid ${colors.grey.lightSecondary};

  &::-webkit-input-placeholder {
    color: ${colors.grey.lightSecondary};
    font-style: italic;
    text-align: center;
  }
`;

// Text
export const Input = styled.input.attrs(props => ({
  type: props.type || 'text',
}))`
  width: 100%;
  height: ${heightInputs};
  
  border: 1px solid
    ${props => (props.dark ? colors.grey.lightSecondary : colors.primary)};
  text-indent: 0.5rem;
  color: ${colors.grey.darkPrimary};

  &::-webkit-input-placeholder {
    color: ${colors.grey.lightSecondary};
    font-style: italic;
  }

  &.input__error {
    border-color: ${colors.red.primary};
  }
`;

// Select
export const Select = styled.select`
  height: ${heightInputs};
  width: 100%;
  padding: 0 35px 0 0.5rem;
  color: ${colors.grey.darkPrimary};
  border: 1px solid
    ${props => (props.dark ? colors.grey.lightSecondary : colors.primary)};

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url(${caret});
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
  background-color: ${colors.default};

  option {
  height: 35px;
}
`;

// option
export const Option = styled.option``;

// Files
export const Files = styled.input.attrs(props => ({
  type: 'file',
}))`
  width: 100%;
  min-width: 170px;
  border: 1px solid ${colors.grey.lightSecondary};
  display: flex;
  height: 40px;
  position: relative;
  background-color: transparent;
  z-index: 50;
  padding: 2px 5px;

  &::-webkit-file-upload-button {
    display: none;
  }

  &:after {
    content: 'Envie seus arquivos';
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    text-indent: 1rem;
    color: ${colors.grey.lightSecondary};
    font-style: italic;
  }
`;

// textarea
export const Textarea = styled.textarea`
	width: ${props => (props.widthContent ? props.widthContent : '100%')};
	height: ${props => (props.heightContent ? props.heightContent : '100%')};
	border: 1px solid ${colors.primary};
	resize: none;
	padding: 1rem;
`;

// Buttons
export const Button = styled.button`
         max-width: ${props => props.maxWidth || '170px'};
         width: max-content;
         margin-left: 1rem;
         background-color: transparent;
         color: ${colors.primary};
         border-radius: 50px;
         padding: 0.5rem 1rem;
         font-size: ${fontSize.md};
         //text-transform: capitalize;
         font-style: normal;
         margin-bottom: ${props => (props.margin ? '1rem' : '0')};
         cursor: pointer;
         height: fit-content;
         text-decoration: underline;
         font-weight: normal;
         display: flex;
         flex-direction: row;
         border: none;
         transition: background-color 0.2s ease, color 0.3s ease,
          box-shadow 0.2s ease, transform 0.2s ease;
         box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);

         &:hover {
           background-color: ${colors.blue.light};
           color: ${colors.default};
         }

         &:focus {
          outline: none;
          box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
        }

         &:disabled {
           cursor: not-allowed;
           opacity: 0.6;
         }
       `;

export const ButtonPrimary = styled(Button)`
  margin-right: ${props => (props.margin || '0')};
  justify-content: center;
  text-decoration: none;
  background-color: ${colors.primary};
  color: ${colors.default};
`;

export const ButtonSecondary = styled(Button)`
  text-decoration: none;
  background-color: ${colors.secondary};
  color: ${colors.default};
`;

export const Mult = styled.div`
  display: flex;
  align-items: center;
`;

export const InputMult = styled.input`
  width: 38px;
  height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const ButtonMult = styled.span`
  width: 26px;
  height: 26px;
  background-color: ${colors.primary};
  border: 0;
  color: ${colors.default};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: ${fontSize.md};
  cursor: pointer;
`;

export const StyledInput = styled.div`
  display: block;
  width: 100%;

  input {
    background: ${colors.input};
    border: 1px solid red;
    border-radius: 5px;
    padding: 12px 15px;
    margin: 10px 0;
    width: 100%;

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
`;
