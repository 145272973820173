import React from 'react';
import { Link } from 'react-router-dom';

// hocs
import GroupCards from '../../hocs/GroupCards';

// styles
import * as Style from './style';

const Option = ({ data }) => {
  return (
    <>
      {data.map((service, index) => {
        return (
          <Style.OptionStyle key={index}>
            {service.link ? (
              <Link to={service.link} title={service.title}>
                <div>
                  <service.icon />
                </div>
                <p>{service.title}</p>
              </Link>
            ) : (
              <>
                <div>
                  <service.icon />
                </div>
                <p>{service.title}</p>
              </>
            )}
          </Style.OptionStyle>
        );
      })}
    </>
  );
};

export default GroupCards(Option);
