import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// actions
import { fetchBalconies } from '../../actions/balcony';

// Forms
import FormBalcony from '../../forms/Balcony';

// Hocs
import Modal from '../../hocs/Modal';

// Style
import { ModalBalcony } from './style';

export let Balcony = ({ dataForm, fetchBalconies }) => {
  useEffect(() => {
    fetchBalconies(dataForm.data);
  });

  return (
    <ModalBalcony>
      <FormBalcony {...dataForm.data} loader />
    </ModalBalcony>
  );
};

const mapStateToProps = state => ({
  dataForm: state.modals.modalBalcony,
});

const mapDispatchToProps = { fetchBalconies };

Balcony = connect(mapStateToProps, mapDispatchToProps)(Balcony);

Balcony = Modal(Balcony);

export default Balcony;
