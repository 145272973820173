import styled from 'styled-components';

// Style
import { Col, Row } from '../../../styles/align';
import { Wrapper, Label, Radio, Checkbox, Select } from '../../../styles/forms';
import { colors } from '../../../styles/variables';
import { CardFilter } from '../style';

/**
 * Formata o bloco dos Selects do Filtro
 * @extends {CardFilter}
 *
 * Herda estilo para formatar o interior do bloco
 * @extends {Wrapper}
 * @extends {Label}
 * @extends {Select}
 * @extends {colors}
 */
export const CardFilterOptions = styled(CardFilter)`
  ${Wrapper} {
    ${Label} {
      padding: 0.5rem 0;
      color: ${colors.primary};
      font-weight: 700;
    }

    ${Select} {
      border: 1px solid ${colors.grey.darkPrimary};
    }
  }
`;

/**
 * Formata os blocos de listas [Quantidade | Acabamentos]
 * @extends {CardFilter}
 *
 * Herda estilo para formatar o interior do bloco
 * @extends {Col}
 * @extends {Wrapper}
 * @extends {Label}
 * @extends {Radio}
 * @extends {Checkbox}
 * @extends {colors}
 */
export const CardFilterList = styled(CardFilter)`
  ${Col} {
    max-height: 180px;
    flex-wrap: wrap;

    ${Wrapper} {
      min-width: 24%;
      border: 1px solid ${colors.grey.darkSecondary};
      margin-bottom: 0.5rem;
      margin-right: 0.5rem;

      ${Label} {
        display: flex;
        width: 100%;
        justify-content: space-between;

        span {
          &:last-of-type {
            font-weight: 700;
            color: ${colors.secondary};
          }
        }
      }

      ${Radio} {
        &:checked {
          & ~ ${Label} {
            background-color: ${colors.primary};
            span {
              color: ${colors.default};
            }
          }

          & ~ span::after {
            background-color: ${colors.secondary};
          }
        }
      }

      ${Checkbox} {
        &:disabled {
          & ~ span,
          & ~ ${Label} {
            cursor: not-allowed;
            opacity: 0.5;
          }
        }
      }
    }
  }
`;

export const Quantity = styled(Col)`
  ${Row} {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    color: ${colors.secondary};
    font-weight: 600;
    text-transform: uppercase;

    & > span {
      @media (max-width: 768px) {
        margin-bottom: 1rem;
      }
    }

    ${Wrapper} {
      width: 100%;

      span {
        color: ${colors.primary};
      }
    }
  }
`;
