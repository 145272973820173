import React from 'react';

// shared
import { IconError } from '../../shared/icons';

// Style
import { ModalContent, ModalHeader, ModalOverlay, ModalWrapper, } from './style';

const Modal = Component => {
  const Modal = ({ title, show, onClose, width, hideCloseButton }) =>
    show ? (
      <ModalOverlay>
        <ModalWrapper width={width}>
          <ModalHeader>
            <h1>{title}</h1>
            {!hideCloseButton && onClose ? (
              <span className={'action-link'} onClick={onClose}>
                <IconError />
              </span>
            ) : null }
          </ModalHeader>
          <ModalContent>
            <Component />
          </ModalContent>
        </ModalWrapper>
      </ModalOverlay>
    ) : (
      ''
    );

  return Modal;
};

export default Modal;
