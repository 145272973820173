import React, { Component } from 'react';

// Node
import StarRatingComponent from 'react-star-rating-component';

class RatingStars extends Component {
  onStarClick(nextValue, prevValue, name) {
    const { handleChangeRating } = this.props;
    handleChangeRating(nextValue);
  }

  render() {
    const { rating, editing, name } = this.props;

    return (
      <StarRatingComponent
        name={name}
        starCount={5}
        value={rating}
        editing={editing}
        onStarClick={this.onStarClick.bind(this)}
      />
    );
  }
}

export default RatingStars;
