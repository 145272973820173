import React from 'react';
import { connect } from 'react-redux';

// Helpers
import Seo from '../../helpers/Seo';

// style
import { Row, Col } from '../../styles/align';
import { TitlePage } from '../../styles/titles';
import { StyleContact } from './style';

// Options
import { socialMedias, seo } from './options';

const renderContacts = contacts => {
  if (!contacts) {
    return '';
  }

  return contacts.map((contact, index) => {
    return (
      <li key={index}>
        <span>{contact.label}</span>
        <p>{contact.desc}</p>
      </li>
    );
  });
};

const renderSocialMedia = () => {
  return socialMedias.map((socialMedia, index) => {
    return (
      <li key={index}>
        <a href={socialMedia.href} target={'_blank'} rel="noopener">
          {socialMedia.icon}
          <p>{socialMedia.desc}</p>
        </a>
      </li>
    );
  });
};

const Contact = ({ contacts }) => {
  return (
    <>
      <Seo props={seo} />
      <StyleContact>
        <TitlePage>
          <span>Contato</span>
        </TitlePage>
        <Row>
          <Col>
            <h3>Horários de Atendimento</h3>
            <ul>{renderContacts(contacts)}</ul>
          </Col>
          <Col>
            <h3>Conecte-se Conosco</h3>
            <ul>{renderSocialMedia()}</ul>
          </Col>
        </Row>
      </StyleContact>
    </>
  );
};

const mapStateToProps = state => {
  return {
    contacts: state.footer.contacts,
  };
};

export default connect(mapStateToProps, null)(Contact);
