import Icon from '@mdi/react';
import { Wrapper, Label, Input } from '../../../styles/forms';
import React, { useEffect, useState } from 'react';
import Tooltip from '../../Tooltip';
import { mdiAlertOutline } from '@mdi/js';
import { connect } from 'react-redux';
import {
  cleanInstallmentsTwoCards,
  fetchInstallments,
  updateTwoCards,
} from '../../../actions/payment';
import Loading from 'react-loader-spinner';
import { colors } from '../../../styles/variables';

function MaskedValueInput({
  totalValue,
  fetchInstallments,
  updateTwoCards,
  cleanInstallmentsTwoCards,
  card,
  twoCards,
  creditApplied,
  isCreditApplied,
  someInputValueHasError,
  setSomeInputValueHasError,
}) {
  const totalValueMasked = isCreditApplied
    ? totalValue - creditApplied
    : totalValue;
  const [error, setError] = useState({
    invalid: false,
    message: '',
  });
  const [lastInputValue, setLastInputValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const cardValue = twoCards[card].valor;

  useEffect(() => {
    const totalValue = parseInt(totalValueMasked.toFixed(2).replace(/\D/g, ''));
    const cardValueToNumber = parseInt(cardValue);

    setError({ invalid: false, message: '' });
    setSomeInputValueHasError(false);
    if (!cardValueToNumber || cardValueToNumber === 0) {
      setError({ invalid: true, message: 'Campo obrigatório' });
      setSomeInputValueHasError(true);
    }
    // Parcela deve ser maior que 50
    if (cardValueToNumber < 5000) {
      setError({ invalid: true, message: 'Valor não pode ser menor que R$50' });
      setSomeInputValueHasError(true);
    }
    if (cardValueToNumber >= totalValue) {
      setError({
        invalid: true,
        message: 'Valor não pode ser maior ou igual ao valor total do pedido',
      });
      setSomeInputValueHasError(true);
    }
  }, [totalValueMasked, cardValue, card, twoCards, setSomeInputValueHasError]);

  const handlePaymentValue = inputValue => {
    const value = inputValue.replace(/\D/g, '');
    const totalValue = totalValueMasked.toFixed(2).replace(/\D/g, '');

    const oppositeCardType = card === 'firstCard' ? 'secondCard' : 'firstCard';

    setError({ invalid: false, message: '' });

    updateTwoCards(card, 'valor', value);
    updateTwoCards(oppositeCardType, 'valor', totalValue - value);
  };

  const handleBlur = async inputValue => {
    // Verifica se o valor inserido é o mesmo que está no input
    if (lastInputValue === inputValue.replace(/\D/g, '')) return;

    // Verifica se o input atual ou o outro tem erro
    if (error.invalid || someInputValueHasError) {
      // Limpa pois o usuário pode ter inserido um valor correto, e depois, inserir um valor absurdo
      // e se não limpar os campos selecionados, ele vai conseguir finalizar pedido com esses valores errados
      cleanInstallmentsTwoCards();
      updateTwoCards('firstCard', 'num_parcelas', '');
      updateTwoCards('secondCard', 'num_parcelas', '');
      return;
    }
    // Volta ao initial state das parcelas quando der certo o fetch das parcelas
    updateTwoCards('firstCard', 'num_parcelas', '1');
    updateTwoCards('secondCard', 'num_parcelas', '1');
    setIsLoading(true);

    await fetchInstallments(
      twoCards['firstCard'].valor,
      twoCards['secondCard'].valor
    );
    setIsLoading(false);
  };

  return (
    <Wrapper>
      <Label htmlFor={'valor_primeiro_cartao'}>Valor</Label>

      <Input
        id={'valor_primeiro_cartao'}
        className={error.invalid ? 'invalid' : ''}
        onChange={e => handlePaymentValue(e.target.value)}
        value={new Intl.NumberFormat('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }).format(twoCards[card].valor / 100)}
        placeholder="R$ 0,00"
        onBlur={e => handleBlur(e.target.value)}
        // Guarda o valor do input antigo antes de digitar o novo
        onFocus={e => setLastInputValue(e.target.value.replace(/\D/g, ''))}
      />

      {isLoading ? (
        <div className="text-error">
          <Tooltip
            dataId={'invalidPaymentValue'}
            icon={
              <Loading
                className="loader"
                type="Oval"
                color={colors.primary}
                height={50}
                width={50}
              />
            }
          />
        </div>
      ) : error.invalid ? (
        <div className="text-error">
          <Tooltip
            text={error.message}
            dataId={'invalidPaymentValue'}
            icon={<Icon path={mdiAlertOutline} title={'error'} size={1} />}
          />
        </div>
      ) : (
        ''
      )}
    </Wrapper>
  );
}

const mapStateToProps = state => {
  return {
    totalValue: state.payment.payments.totalGeral,
    creditApplied: state.payment.payments.saldo,
    twoCards: state.payment.twoCards,
  };
};

export default connect(mapStateToProps, {
  fetchInstallments,
  updateTwoCards,
  cleanInstallmentsTwoCards,
})(MaskedValueInput);
