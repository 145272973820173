import styled from 'styled-components';

// Variables
import { Row, Col, Sidebar } from '../../styles/align';
import { Wrapper, Label, Radio, BtnGroup, Button } from '../../styles/forms';
import { colors, borderRadius } from '../../styles/variables';
import { TitleCards, TitlePage } from '../../styles/titles';

/**
 * Formata a sidebar do filtro [resumo | next step]
 * @extends {Sidebar}
 *
 * Herda estilo para formatar o interior do sidebar
 * @extends {Wrapper}
 * @extends {BtnGroup}
 * @extends {Button}
 * @extends {colors}
 */
export const SidebarFilter = styled(Sidebar)`
  padding: 0 0 0 1rem;

  @media (max-width: 768px) {
    padding: 0;
    margin-bottom: 1rem;
  }

  ${Wrapper} {
    .action-link {
      font-weight: 700;
    }
  }

  ${BtnGroup} {
    padding: 1rem 0;
    align-items: center;
    justify-content: center;

    ${Button} {
      padding: 1.4rem;
      max-width: max-content;
      color: ${colors.default};
      font-weight: 700;
      text-decoration: none;
      background-color: ${colors.secondary};
    }
  }
`;

/**
 * Formata o Container da página do Filtro
 * @extends {Col}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Row}
 */
export const FilterContainer = styled(Col)`
  padding: 1rem;
  position: relative;

  ${Row} {
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

/**
 * Formata os Card de Opções do Filtro *Filtro normal -> O livro utiliza HOC*
 * @extends {Col}
 *
 * Herda estilo para formatar o interior do Card
 * @extends {Row}
 * @extends {Col}
 * @extends {Radio}
 * @extends {Label}
 * @extends {Wrapper}
 * @extends {colors}
 */
export const CardFilter = styled(Col)`
  background-color: ${colors.grey.lightPrimary};
  padding: 0.8rem;
  margin-right: 1rem;
  height: initial;

  &.row-padding {
    padding: 0;
  }

  ${Row} {
    flex-wrap: wrap;
    justify-content: space-between;

    ${Col} {
      width: 50%;
    }

    ${Radio} {
      &:checked {
        & ~ ${Label} {
          background-color: ${colors.primary};
          color: ${colors.default};
        }

        & ~ span::after {
          background-color: ${colors.secondary};
        }
      }
    }

    ${Wrapper} {
      min-width: 48%;
      margin-bottom: 0.5rem;
      margin-right: 0.5rem;
      flex-direction: row;
      border: 1px solid ${colors.grey.darkSecondary};

      ${Label} {
        color: ${colors.grey.dark};
      }

      &:nth-child(even) {
        margin-right: 0;
      }
    }
  }

  ${Col} {
    ${Radio} {
      &:checked {
        & ~ span::after {
          background-color: ${colors.secondary};
        }
      }
    }
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

/**
 * Formata o Title do Card de Opções do Filtro *Filtro normal -> O livro utiliza HOC*
 * @extends {TitleCards}
 *
 * Herda estilo para formatar o interior do Card
 * @extends {colors}
 */

export const TitleCard = styled(TitleCards)`
  span {
    width: 35px;
    height: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: ${colors.default};
    border-radius: ${borderRadius};
    background-color: ${colors.primary};
    margin-right: 1rem;
  }
`;

/**
 * Formata oa Image do Card de Opções do Filtro *Filtro normal -> O livro utiliza HOC*
 * @extends {figure} Tag figure nativa do HTML
 */
export const Figure = styled.figure`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem 0;

  img {
    width: 300px;
    max-width: 80%;
    height: auto;
  }
`;

/**
 * Formata o link de termos
 * @extends {a} Tag a nativa do HTML
 */
export const Anchor = styled.a`
  font-weight: 700;
  color: ${colors.primary};
  text-decoration: none;
  margin: ${props => (props.margin ? props.margin : 'auto')};
`;

/**
 * Formata o Loading do filtro
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do Card
 * @extends {colors}
 */
export const Loading = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: ${colors.default};
  opacity: 0.8;
  z-index: 100;
  position: absolute;
  pointer-events: stroke;
  left: 0;
  top: 0;
`;

/**
 * Formata o Skeleton do filtro
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do Card
 * @extends {TitlePage}
 * @extends {SidebarFilter}
 * @extends {FilterContainer}
 * @extends {Row}
 *
 */
export const SkeletonStyle = styled.div`
  width: 100%;

  ${TitlePage} {
    &:after {
      border-color: #ccc;
    }

    &:before {
      border-color: #f4f4f4;
    }
  }

  ${SidebarFilter} {
    height: auto;

    span {
      height: 100%;
    }
  }

  ${FilterContainer} {
    ${Row} {
      div {
        min-height: inherit;
        display: flex;
        height: 400px;

        span {
          height: 100%;
        }
      }
    }
  }
`;

/**
 * Formata o Container de Ações [Gabaritos]
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do Container
 * @extends {colors}
 */
export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.grey.light};
  padding: 1rem;
  margin-bottom: 1rem;

  label {
    padding: 0;

    select {
      max-width: 300px;
    }
  }

  & > div {
    label {
      margin-bottom: 1rem;
      color: ${colors.primary};
      padding-left: 0;
      font-weight: 600;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;