import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, Form, reduxForm } from 'redux-form';
// node-modules
import { toast } from 'react-toastify';
// mask
import { maskCelPhone } from '../../../formValidate/masks';
// style
import { Col, Row } from '../../../styles/align';
import { Wrapper, BtnGroup, Button, Label } from '../../../styles/forms';
import { FormBudget } from './style';
// validate
import renderField from '../../../formValidate/renderField';
import { required } from '../../../formValidate';
// services
import { sendBudget } from '../../../services/filter';
// utils
import { PRODUCT_NOT_FOUND, EMAIL_BUDGET } from '../../../utils/constMessage';
import { onlyNumbers } from '../../../utils';

class Budget extends Component {
  submit = async dataBudget => {
    const {
      simulateDelivery,
      filterProductsOnChange,
      data,
      reset,
    } = this.props;

    if (
      filterProductsOnChange.registeredFields.acabamento_obrigatorio &&
      !filterProductsOnChange.values.acabamento_obrigatorio
    ) {
      toast.warn('Necessario Selecionar o tipo do acabamento');
      return false;
    }

    if (simulateDelivery.values) {
      if (onlyNumbers(simulateDelivery.values.cep).length === 8) {
        dataBudget = {
          ...dataBudget,
          cep: onlyNumbers(simulateDelivery.values.cep),
        };
      }
    }

    const result = await sendBudget(
      dataBudget,
      filterProductsOnChange.values,
      data.dados_produto.pk_produto
    );

    if (!result) {
      toast.error(PRODUCT_NOT_FOUND);

      return false;
    }

    toast.success(EMAIL_BUDGET);
    reset();
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <FormBudget>
        <Form onSubmit={handleSubmit(this.submit)}>
          <h3>Receber esse orçamento por email</h3>
          <Col>
            <Row>
              <Wrapper>
                <Field
                  name={'nome_orcamento'}
                  placeholder={'Nome'}
                  type={'text'}
                  validate={[required]}
                  component={renderField}
                />
              </Wrapper>
              <Wrapper>
                <Field
                  name={'email_orcamento'}
                  placeholder={'Email'}
                  type={'text'}
                  validate={[required]}
                  component={renderField}
                />
              </Wrapper>
            </Row>
            <Row>
              <Wrapper>
                <Field
                  name={'telefone_orcamento'}
                  placeholder={'Nº de Telefone'}
                  type={'text'}
                  validate={[required]}
                  component={renderField}
                  typefield={'mask'}
                  mask={maskCelPhone}
                />
              </Wrapper>
              <Wrapper>
                <Field
                  name={'nome_projeto'}
                  placeholder={'Nome do Projeto'}
                  type={'text'}
                  validate={[required]}
                  component={renderField}
                />
              </Wrapper>
            </Row>
            <Wrapper>
              <Field
                name={'news_orcamento'}
                id={'newsletter_budget'}
                type={'checkbox'}
                typefield={'checkbox'}
                component={renderField}
              />
              <Label htmlFor={'newsletter_budget'}>
                Receber novidades, ofertas exclusivas e muito mais
              </Label>
            </Wrapper>
            <BtnGroup>
              <Button onClick={this.onSubmit}>Enviar</Button>
            </BtnGroup>
          </Col>
        </Form>
      </FormBudget>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.filter.filter,
    filterProductsOnChange: state.form.filterProductsOnChange,
    simulateDelivery: state.form.simulateDelivery,
  };
};

Budget = connect(mapStateToProps, null)(Budget);

Budget = reduxForm({
  form: 'budget',
})(Budget);

export default Budget;
