import React from 'react';

// node-modules
import Loading from 'react-loader-spinner';

// styles
import { colors } from '../../styles/variables';
import { LoaderStyle } from './style';

const Loader = props => {
  return (
    <LoaderStyle {...props}>
      <Loading
        className="loader"
        type="Oval"
        color={colors.primary}
        height={50}
        width={50}
      ></Loading>
    </LoaderStyle>
  );
};

export default Loader;
