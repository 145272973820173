import React from 'react';

// Shared
import { IconFacebook, IconInstagram, IconInstrucoes, IconLinkedin, IconYoutube } from '../../shared/icons';

/**
 * Lista de Redes Sociais
 * @type {Array}
 */
export const socialMedias = [
  {
    desc: '/fabricadolivro',
    icon: <IconFacebook />,
    href: 'https://www.facebook.com/Fábrica-do-Livro-101745101238009',
  },
  {
    desc: '/fabricadolivrobrasil',
    icon: <IconYoutube />,
    href: 'https://www.youtube.com/@fabricadolivro',
  },
  {
    desc: '/instrucoes.fabricadolivro.com.br',
    icon: <IconInstrucoes />,
    href: 'https://instrucoes.fabricadolivro.com.br',
  },
  {
    desc: '/fabricadolivrobrasil',
    icon: <IconInstagram />,
    href: 'https://www.instagram.com/fabricadolivro/',
  },
  {
    desc: '/fabrica-do-livro-brasil',
    icon: <IconLinkedin />,
    href: 'https://www.linkedin.com/company/28556033/',
  },
];
