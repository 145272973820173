import React from 'react';

// node-modules
import Loading from 'react-loader-spinner';

// styles
import { colors } from '../../styles/variables';
import { ContainerLoadingButton } from './style';

const LoaderForm = props => {
  return (
    <ContainerLoadingButton>
      <Loading
        className="loader"
        type="ThreeDots"
        color={props.color ? props.color : colors.default}
        height={10}
        width={10}
      ></Loading>
    </ContainerLoadingButton>
  );
};

export default LoaderForm;
