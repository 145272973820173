import styled from 'styled-components';

// Styles
import { colors } from '../../styles/variables';
import { Col } from '../../styles/align';
import { Wrapper, Label, BtnGroup } from '../../styles/forms';
import { Container } from '../style';

/**
 * Formata o Container da Modal Reabrir Caso
 * @extends {Container}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Col}
 * @extends {Wrapper}
 * @extends {Label}
 * @extends {BtnGroup}
 * @extends {colors}
 */

export const StyleReopenMessage = styled(Container)`
  ${Col} {
    margin-bottom: 1rem;

    ${Wrapper} {
      width: 100%;

      ${Label} {
        padding-left: 0;
        color: ${colors.primary};
      }

      textarea {
        min-width: 100%;
        max-width: 100%;
        min-height: 7rem;
        max-height: 7rem;
      }
    }
  }

  ${BtnGroup} {
    align-items: center;
    justify-content: space-between;

    span {
      &:first-of-type {
        color: ${colors.secondary};
        font-weight: 700;
      }
    }
  }
`;
