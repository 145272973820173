import {
  CLEAR_MODAL_CANCEL,
  FETCH_MODAL_AUTH_PROD,
  FETCH_MODAL_BALCONY,
  FETCH_MODAL_BKNBANK,
  FETCH_MODAL_CANCEL,
  FETCH_MODAL_CONFIRM,
  FETCH_MODAL_CONFIRM_FILE_UPLOAD,
  FETCH_MODAL_CONTEST,
  FETCH_MODAL_DELIVERY_STEPS,
  FETCH_MODAL_EDITOR,
  FETCH_MODAL_FILES_AUTH,
  FETCH_MODAL_FUTURE_RELEASES,
  FETCH_MODAL_LIST_ADDRESS,
  FETCH_MODAL_LOGIN,
  FETCH_MODAL_LOGIN_RECOVERY,
  FETCH_MODAL_MESSAGE,
  FETCH_MODAL_MESSAGE_FEEDBACK,
  FETCH_MODAL_ORDER_ERRORS,
  FETCH_MODAL_PAYMENT,
  FETCH_MODAL_PIX,
  FETCH_MODAL_PRIVACY,
  FETCH_MODAL_REFILE,
  FETCH_MODAL_REGISTER,
  FETCH_MODAL_REGISTER_ADDRESS,
  FETCH_MODAL_REOPEN_MESSAGE,
  FETCH_MODAL_RESEND_FILES,
  FETCH_MODAL_SHIPPING_CONTACT,
  FETCH_MODAL_TERMS_OF_USE,
  FETCH_MODAL_WITHDRAWAL,
} from './types';

/**
 * Modal Listar Endereços
 */

export const modalListAddress = () => {
  return {
    type: FETCH_MODAL_LIST_ADDRESS,
  };
};

/**
 * Modal Central de Provacidade
 */

export const modalPrivacy = () => {
  return {
    type: FETCH_MODAL_PRIVACY,
  };
};

/**
 * Modal de Login
 */

export const modalLogin = componentName => {
  return {
    type: FETCH_MODAL_LOGIN,
    payload: {
      componentName,
    },
  };
};

/**
 * Modal de Mensagem
 */

export const modalMessage = () => {
  return {
    type: FETCH_MODAL_MESSAGE,
  };
};

/**
 * Modal de Pagamento
 */

export const modalPayment = () => {
  return {
    type: FETCH_MODAL_PAYMENT,
  };
};

/**
 * Modal de cadastro do usuario
 */

export const modalRegister = () => {
  return {
    type: FETCH_MODAL_REGISTER,
  };
};

/**
 * Modal de cadastro de endereço
 */

export const modalRegisterAddress = callback => {
  return {
    type: FETCH_MODAL_REGISTER_ADDRESS,
    payload: callback,
  };
};

/**
 * Modal de cancelamento do pedido
 */

export const modalCancel = data => {
  return {
    type: FETCH_MODAL_CANCEL,
    payload: data,
  };
};

/**
 * Modal de Autorização da produção.
 */

export const modalAuthProd = data => {
  return {
    type: FETCH_MODAL_AUTH_PROD,
    payload: data,
  };
};

/**
 * Toggle da area de recuperação de senha
 */

export const toggleRecovery = () => {
  return {
    type: FETCH_MODAL_LOGIN_RECOVERY,
  };
};

/**
 * Modal de Contato [ Balcões ]
 */

export const modalWithdrawal = () => {
  return {
    type: FETCH_MODAL_WITHDRAWAL,
  };
};

/**
 * Modal de Contato [ Normal ]
 */

export const modalShippingContact = () => {
  return {
    type: FETCH_MODAL_SHIPPING_CONTACT,
  };
};

/**
 * Modal de Erros do Pedido
 */

export const modalOrderErrors = data => {
  return {
    type: FETCH_MODAL_ORDER_ERRORS,
    payload: data,
  };
};

/**
 * Modal generico de Confirmação
 */

export const modalConfirm = data => {
  return {
    type: FETCH_MODAL_CONFIRM,
    payload: data,
  };
};

/**
 * Modal BKN BANK
 */

export const modalBknbank = data => {
  return {
    type: FETCH_MODAL_BKNBANK,
    payload: data,
  };
};

/**
 * Modal PIX
 */

export const modalPix = data => {
  return {
    type: FETCH_MODAL_PIX,
    payload: data,
  };
};

/**
 * Modal de reabertura do caso
 */

export const modalReopenMessage = data => {
  return {
    type: FETCH_MODAL_REOPEN_MESSAGE,
    payload: data,
  };
};

/**
 * Modal de Feedback das Mensagens
 */

export const modalMessageFeedback = data => {
  return {
    type: FETCH_MODAL_MESSAGE_FEEDBACK,
    payload: data,
  };
};

/**
 * Modal de Arquivos Obrigatorios
 */

export const modalFilesAuth = data => {
  return {
    type: FETCH_MODAL_FILES_AUTH,
    payload: data,
  };
};

/**
 * Modal de Refile
 */

export const modalRefile = data => {
  return {
    type: FETCH_MODAL_REFILE,
    payload: data,
  };
};

/**
 * Modal envio de arquivo ou criação
 */

export const modalEditor = () => {
  return {
    type: FETCH_MODAL_EDITOR,
  };
};

/**
 * Modal de Contestação
 */

export const modalContest = data => {
  return {
    type: FETCH_MODAL_CONTEST,
    payload: data,
  };
};

/**
 * Modal de Lançamentos Futuros [Crédito]
 */
export const modalFutureReleases = () => {
  return {
    type: FETCH_MODAL_FUTURE_RELEASES,
  };
};

/**
 * Clear Modal Cancel
 */

export const clearModalCancel = () => {
  return {
    type: CLEAR_MODAL_CANCEL,
  };
};

/**
 * Modal Balcões
 */

export const modalBalcony = data => {
  return {
    type: FETCH_MODAL_BALCONY,
    payload: data,
  };
};

/**
 * Modal Termos de uso
 */

export const modalTermsOfUse = () => {
  return {
    type: FETCH_MODAL_TERMS_OF_USE,
  };
};

/**
 * Modal Reenviar arquivos
 */
export const modalResendFiles = () => {
  return {
    type: FETCH_MODAL_RESEND_FILES,
  };
};

/**
 * Modal Confirmação DE ENVIO DE ARQUIVOS
 */

export const modalConfirmFileUpload = data => {
  return {
    type: FETCH_MODAL_CONFIRM_FILE_UPLOAD,
    payload: data,
  };
};

export const modalDeliverySteps = callback => {
  return {
    type: FETCH_MODAL_DELIVERY_STEPS,
    payload: callback,
  };
};
