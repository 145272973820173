import loja from '../apis/loja';

import { toast } from 'react-toastify';
import {
  CLEAR_ADDRESS,
  CLEAR_CITIES,
  FETCH_ADDRESS,
  FETCH_ADDRESSES,
  FETCH_CITIES,
  FETCH_STATES,
} from './types';

/**
 * Busca todos os endereços do usuário logado
 */
export const fetchAddresses = () => async dispatch => {
  try {
    const response = await loja.get('/endereco/listar');

    dispatch({ type: FETCH_ADDRESSES, payload: response.data });

    // console.log(response.data);
  } catch (error) {
    //toast.error(error.response.data.message);
    
  }
};

/**
 * Busca todos os endereços, de acordo com os filtros, do usuário logado
 * @param {integer} pk_estado
 * @param {integer} pk_cidade
 * @param {string} nome_ou_cep
 */
export const fetchFiltredAddresses = (
  pk_estado,
  pk_cidade,
  nome_ou_cep = ''
) => async dispatch => {
  const data =
    nome_ou_cep !== ''
      ? {
          pk_estado,
          pk_cidade,
          nome_ou_cep,
        }
      : {
          pk_estado,
          pk_cidade,
        };
  try {
    const response = await loja.get('/endereco/listar', {
      params: data,
    });


    dispatch({ type: FETCH_ADDRESSES, payload: response.data });
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

/**
 * Busca o endereço de acordo com a pk enviada
 * @param {integer} pk_usuario_endereco
 */
export const fetchAddress = pk_usuario_endereco => async dispatch => {
  try {
    const response = await loja.get('/endereco/buscar-endereco', {
      params: { pk_usuario_endereco },
    });
     

    dispatch({ type: FETCH_ADDRESS, payload: response.data });
  } catch (error) {
    toast.error(error.response.data.message);
  }

};

/**
 * Reseta os dados do endereco no store
 */
export const clearAddress = () => {
  return {
    type: CLEAR_ADDRESS,
  };
};

/**
 * Busca todos os estados da api
 */
export const fetchStates = () => async dispatch => {
  try {
    const response = await loja.get('/estados');

    dispatch({ type: FETCH_STATES, payload: response.data });
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

/**
 * Busca todas as cidades do estado enviado por parâmetro
 * @param {integer} estado
 * @param {integer} is_balcao [0/1]
 */
export const fetchCities = (estado, is_balcao = 0) => async dispatch => {
  try {
    const response = await loja.get('/cidades', {
      params: { estado, is_balcao },
    });
    dispatch({ type: FETCH_CITIES, payload: response.data });
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

/**
 * Limpa o select de cidades
 */
export const clearCities = () => {
  return {
    type: CLEAR_CITIES,
  };
};
