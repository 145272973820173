import React from "react";
import { Field } from "redux-form";

// Validate
import renderField from "../../../formValidate/renderField";

// Styles
import { Row } from "../../../styles/align";
import { Wrapper, Label } from "../../../styles/forms";

const RenderType = ({ disabled }) => {
  return (
    <>
      <Row>
        <Wrapper padding={".5rem"}>
          <Field
            align={"center"}
            name={"fk_tipo_usuario"}
            id={"fk_tipo_usuario[0]"}
            type={"radio"}
            typefield={"radio"}
            component={renderField}
            value={"2"}
            disabled={disabled.fk_tipo_usuario}
          />
          <Label htmlFor={"fk_tipo_usuario[0]"}>Pessoa Física</Label>
        </Wrapper>
        <Wrapper padding={".5rem"}>
          <Field
            align={"center"}
            name={"fk_tipo_usuario"}
            id={"fk_tipo_usuario[1]"}
            type={"radio"}
            component={renderField}
            typefield={"radio"}
            value={"1"}
            disabled={disabled.fk_tipo_usuario}
          />
          <Label htmlFor={"fk_tipo_usuario[1]"}>Pessoa Jurídica</Label>
        </Wrapper>
      </Row>
    </>
  );
};

export default RenderType;
