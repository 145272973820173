import styled from 'styled-components';
import { colors, fontSize } from '../../styles/variables';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Wireo = styled.div`
  width: 100%;
  height: 15px;
  display: flex;
  justify-content: space-around;
  position: absolute;
  top: -10px;

  div {
    width: 5px;
    height: 15px;
    border-radius: 10px;
    background-color: ${colors.secondary};
  }
`;

export const Calendar = styled.div`
  width: 115px;
  height: 120px;
  border: 3px solid ${colors.secondary};
  border-radius: 10px;
  position: relative;

  &.entregue {
    border-color: #0d804b;

    ${Wireo} {
      div {
        background-color: #0d804b;
      }
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  color: ${colors.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: ${fontSize.lg};
    color: #0d804b;
    font-weight: 700;
    text-transform: uppercase;
  }

  svg {
    fill: #0d804b;
  }

  div {
    padding: 0.3rem 0;
  }
`;

export const DayDesc = styled.div`
  font-size: ${colors.primary};
  text-align: center;
  font-size: 0.8rem;
  font-weight: 900;
  text-transform: uppercase;
`;
export const Day = styled.div`
  width: 100%;
  border: 2px solid ${colors.secondary};
  font-size: 36px;
  font-weight: 900;
  text-align: center;
`;
export const Month = styled.div`
  font-size: ${colors.primary};
  text-align: center;
  font-size: 0.8rem;
  font-weight: 900;
  text-transform: uppercase;
`;
