import React from 'react';
import { Link } from 'react-router-dom';

// styles
import * as Style from './style';

const tagA = data => {
  return (
    <a href={data.a} title={data.desc} target={data.target} rel={data.rel}>
      {data.desc}
    </a>
  );
};

const componentLink = data => {
  return (
    <Link
      to={{
        pathname: data.link,
        state: { prev: data.prevHistory ? data.prevHistory : false },
      }}
      title={data.desc}
    >
      {data.desc}
    </Link>
  );
};

const tagText = data => {
  if (data.label) {
    return (
      <p>
        <strong>{data.label} </strong>
        {data.desc}
      </p>
    );
  } else {
    return <>{data.desc}</>;
  }
};

// o link caso venha preenchido imprime uma tag a,
// caso contrario imprime o texto
const Card = props => {
  const aList = props.list.map((item, i) =>
    item.class ? (
      <li
        key={i}
        title={item.title}
        className={`${item.class} ${item.parentClass}`}
      ></li>
    ) : (
      <li key={i}>
        {item.a ? tagA(item) : item.link ? componentLink(item) : tagText(item)}
      </li>
    )
  );

  return (
    <Style.ListStyle>
      <h3>{props.title}</h3>
      {props.row ? <Style.ListRow>{aList}</Style.ListRow> : <ul>{aList}</ul>}
    </Style.ListStyle>
  );
};

export default Card;
