import React, { Component } from 'react';
import { connect } from 'react-redux';

// actions
import { getViewPayments } from '../../actions/payment';

// shared
import {
  IconBB,
  IconBradesco,
  IconCabal,
  IconCaixa,
  IconCredit,
  IconDiners,
  IconElo,
  IconHiper,
  IconItau,
  IconJcb,
  IconMaster,
  IconPix,
  IconSorocred,
  IconUnionpay,
  IconVisa,
} from '../../shared/icons';

// Helpers
import Seo from '../../helpers/Seo';

// utils
import { slugify, toCapitalize } from '../../utils';

// Styles
import { Col } from '../../styles/align';
import { TitlePage } from '../../styles/titles';
import {
  ContentTypePayment,
  IconTypePayment,
  PaymentContainer,
  PaymentContent,
  PaymentHeader,
  PaymentStyle,
} from './style';

// Options
import { seo } from './options';

const icons = {
  mastercard: <IconMaster />,
  dinersclub: <IconDiners />,
  visa: <IconVisa />,
  pix: <IconPix />,
  hipercard: <IconHiper />,
  bradesco: <IconBradesco />,
  itau: <IconItau />,
  caixaeconomicafederal: <IconCaixa />,
  bancodobrasil: <IconBB />,
  elo: <IconElo />,
  sorocred: <IconSorocred />,
  cabal: <IconCabal />,
  unionpay: <IconUnionpay />,
  jcb: <IconJcb />,
};

class Payment extends Component {
  componentDidMount() {
    this.props.getViewPayments();
  }

  renderCreditCard(payment) {
    return (
      <PaymentContainer key={payment.pk_tipo_pagamento}>
        <PaymentHeader>
          <Col>
            <h3>Cartão de Crédito</h3>
          </Col>
          <Col>
            <span>Liberação financeira imediata</span>
            <p>
              Aceitamos as seguintes bandeiras: Mastercard, Diners, Visa e
              Hipercard.
            </p>
            <p>
              Compras até R$ 100,00 podem ser parceladas em até 3x, para valores
              acima de R$ 100,00 o parcelamento pode ser estendido até 6x.
            </p>
          </Col>
        </PaymentHeader>
        <PaymentContent>
          <ul>
            {payment.dependencia_tipo_pagamento.map((dependencia, index) => {
              return (
                <li key={index}>
                  <IconTypePayment>
                    {
                      icons[
                        slugify(
                          dependencia.desc_dependencia_tipo_pagamento
                        ).replace(/-+/g, '')
                      ]
                    }
                  </IconTypePayment>
                  <ContentTypePayment>
                    <p>
                      {toCapitalize(
                        dependencia.desc_dependencia_tipo_pagamento
                      )}
                    </p>
                  </ContentTypePayment>
                </li>
              );
            })}
          </ul>
        </PaymentContent>
      </PaymentContainer>
    );
  }

  renderBankSlip(payment) {
    return (
      <PaymentContainer key={payment.pk_tipo_pagamento}>
        <PaymentHeader>
          <Col>
            <h3>Boleto Bancário</h3>
          </Col>
          <Col>
            <span>Liberação financeira de 30 minutos a 72 horas úteis</span>
            <p>
              O boleto poderá ser pago em qualquer agência bancária ou lotérica,
              desde que o documento esteja dentro do prazo de vencimento. Uma
              vez que o boleto estiver vencido o pagamento só será aceito no
              Banco Itaú.
            </p>
            <p>
              Para saber mais informações sobre as Formas de Pagamento acesse a
              nossa Central de Instruções.
            </p>
          </Col>
        </PaymentHeader>
      </PaymentContainer>
    );
  }

  renderBankSlipBkn(payment) {
    return (
      <PaymentContainer key={payment.pk_tipo_pagamento}>
        <PaymentHeader>
          <Col>
            <h3>Boleto Bkn Bank</h3>
          </Col>
          <Col>
            <span>Liberação financeira em até 72 horas úteis</span>
            <p>
              O boleto poderá ser pago em qualquer agência bancária ou lotérica,
              desde que o documento esteja dentro do prazo de vencimento. Uma
              vez que o boleto estiver vencido deve ser gerado outro com um novo prazo de vencimento.
            </p>
            <p>
              Para saber mais informações sobre as Formas de Pagamento acesse a
              nossa Central de Instruções.
            </p>
          </Col>
        </PaymentHeader>
      </PaymentContainer>
    );
  }

  renderPixSlip(payment) {
    return (
      <PaymentContainer key={payment.pk_tipo_pagamento}>
        <PaymentHeader>
          <Col>
            <h3>Pix</h3>
          </Col>
          <Col>
            <span>Liberação Financeira Imediata</span>
            <p>
              Forma de transferência automática realizada em poucos minutos de uma conta para outra sem
              que seja cobrada taxa e sem horário para realização da transação. O Pix deve ser feito
              utilizando o QR Code gerado no momento da sua compra e para fazer o pagamento basta
              utilizar o aplicativo do seu banco ou o caixa eletrônico para fazer leitura deste QR Code, sendo
              a aprovação feita rapidamente.
            </p>
            <p>
              <strong>Obs.:</strong> caso haja alguma falha no envio da notificação do pagamento, será realizada uma
              segunda consulta (esta feita de 15 em 15 minutos) que realizará a aprovação. A Fábrica do
              Livro será informada pelo banco sobre o pagamento realizado.
            </p>
          </Col>
        </PaymentHeader>
        <PaymentContent>
          <ul>
            {payment.dependencia_tipo_pagamento.map((dependencia, index) => {
              return (
                <li key={index}>
                  <IconTypePayment>
                    {
                      icons[
                        slugify(
                          dependencia.desc_dependencia_tipo_pagamento
                        ).replace(/-+/g, '')
                      ]
                    }
                  </IconTypePayment>
                  <ContentTypePayment>
                    <p>
                      {toCapitalize(
                        dependencia.desc_dependencia_tipo_pagamento
                      )}
                    </p>
                  </ContentTypePayment>
                </li>
              );
            })}
          </ul>
        </PaymentContent>
      </PaymentContainer>
    );
  }

  renderBankDeposit(payment, loja) {
    return (
      <React.Fragment key={payment.pk_tipo_pagamento}>
        {this.renderBankTransfer()}
        <PaymentContainer>
          <PaymentHeader>
            <Col>
              <h3>Depósito Bancário</h3>
            </Col>
            <Col>
              <span>
                Liberação financeira em até 3 horas úteis na boca do caixa ou
                até 72 horas úteis no caixa eletrônico
              </span>
              <p>
                Assim como a transferência, o Depósito Bancário necessita do
                envio do comprovante de pagamento anexado junto ao pedido para
                liberação do prazo.
              </p>
              <p>
                O pedido só seguirá para produção mediante comprovação do
                pagamento, favor enviar o comprovante em um dos seguintes
                formatos: PDF, JPG, CDR, JPEG e PNG.
              </p>
              <p>
                <strong>Titular: </strong>
                {loja.titular}
              </p>
              <p>
                <strong>CNPJ: </strong>
                {loja.cnpj}
              </p>
            </Col>
          </PaymentHeader>
          <PaymentContent>
            <ul>
              {payment.dependencia_tipo_pagamento.map((dependencia, index) => {
                return (
                  <li key={index}>
                    <IconTypePayment>
                      {
                        icons[
                          slugify(
                            dependencia.desc_dependencia_tipo_pagamento
                          ).replace(/-+/g, '')
                        ]
                      }
                    </IconTypePayment>
                    <ContentTypePayment>
                      <p>
                        {toCapitalize(
                          dependencia.desc_dependencia_tipo_pagamento
                        )}
                      </p>
                      <span>
                        <p>{dependencia.info_dependencia_tipo_pagamento}</p>
                      </span>
                    </ContentTypePayment>
                  </li>
                );
              })}
            </ul>
          </PaymentContent>
        </PaymentContainer>
      </React.Fragment>
    );
  }

  renderBankTransfer() {
    return (
      <PaymentContainer>
        <PaymentHeader>
          <Col>
            <h3>Transferência Bancária</h3>
          </Col>
          <Col>
            <span>
              Liberação financeira de até 3 horas úteis (mesmo banco) / DOC –
              até 72 horas úteis / TED – até 3 horas (realizado dentro do
              expediente bancário)
            </span>
            <p>
              O prazo de liberação tem início mediante envio do comprovante de
              transferência, este deverá estar legível e conter todos os dados
              da transação realizada.
            </p>
            <p>
              O envio do comprovante é realizado diretamente no site, acesse
              "Minha conta", "Meus pedidos" e anexe o documento no campo
              indicado.
            </p>
          </Col>
        </PaymentHeader>
      </PaymentContainer>
    );
  }

  renderCredit(payment) {
    return (
      <PaymentContainer key={payment.pk_tipo_pagamento}>
        <PaymentHeader>
          <Col>
            <h3>Créditos Fábrica do Livro</h3>
          </Col>
          <Col>
            <span>Prazo de Liberação: Confirmação imediata.</span>
          </Col>
        </PaymentHeader>
        <PaymentContent>
          <ul>
            <li>
              <IconTypePayment>{<IconCredit />}</IconTypePayment>
              <ContentTypePayment>
                <span>
                  <p>Crédito Em Moeda</p>
                </span>
              </ContentTypePayment>
            </li>
          </ul>
        </PaymentContent>
      </PaymentContainer>
    );
  }

  renderPayment(payment, loja) {
    switch (payment.pk_tipo_pagamento) {
      case 1:
        return this.renderBankDeposit(payment, loja);
      case 3:
        return this.renderCreditCard(payment);
      //Removido temporariamente.
      // case 4:
      //   return this.renderCredit(payment);
      case 6:
        return this.renderBankSlip(payment);
      case 10:
        return this.renderBankSlipBkn(payment);
      case 13:
        return this.renderPixSlip(payment);
      default:
        break;
    }
  }

  render() {
    const { payments } = this.props;

    if (!payments) {
      return '';
    }

    return (
      <>
        <Seo props={seo} />
        <PaymentStyle>
          <TitlePage>
            <span>Formas de Pagamento</span>
          </TitlePage>
          <Col>
            {payments.pagamento.map(payment => {
              return this.renderPayment(payment, payments.loja);
            })}
          </Col>
        </PaymentStyle>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    payments: state.payment.viewPayments,
  };
};

Payment = connect(mapStateToProps, { getViewPayments })(Payment);

export default Payment;
