import React from 'react';
import { Field } from 'redux-form';

// hocs
import CardFilter from '../../../../hocs/CardFilter';

// validate
import renderField from '../../../../formValidate/renderField';

// config
import config from '../../../../config';

// Styles
import { Label } from '../../../../styles/forms';
import { BookFinishe } from './style';
import { numberFormat } from '../../../../utils';
import { Col } from '../../../../styles/align';

const renderDescWithDeadline = item => {
    let prazo = '';
    if (item.qtd_dias_acabamento !== 0) {
      prazo =
        item.qtd_dias_acabamento > 1
          ? `(+ ${item.qtd_dias_acabamento} dias úteis)`
          : `(+ ${item.qtd_dias_acabamento} dia útil)`;
    }

    return (
      <>
        <span>
          {item.desc_acabamento} <small> {prazo}</small>
        </span>
        <span>R$ {numberFormat(item.valor_acabamento, 2)}</span>
      </>
    );
}

const BookFinishes = ({ data, updateFinishBlocked, finishesBlocked, updateService }) => {
  const { acabamentos_opcionais } = data;
  const classFilter180 = data.dados_produto.fk_tipo_produto === config.TYPE_BOOK
    ? 'limit__box-180 limit__box'
    : 'limit__box';

  return (
    <Col className={classFilter180}>
      {acabamentos_opcionais
        ? Object.values(acabamentos_opcionais).map((item, i) => {
            if (updateService) {
              updateService(item.pk_acabamento);
            }
            return (
              <BookFinishe key={i}>
                <Field
                  name={`acabamento_opcional[${item.pk_acabamento}]`}
                  id={`acabamento_opcional[${i}]`}
                  type={'checkbox'}
                  typefield={'checkbox'}
                  component={renderField}
                  disabled={finishesBlocked.includes(item.pk_acabamento)}
                  onChange={() => updateFinishBlocked(item)}
                  value={`${item.pk_acabamento}`}
                />
                <Label htmlFor={`acabamento_opcional[${i}]`}>
                  {renderDescWithDeadline(item)}
                </Label>
              </BookFinishe>
            );
          })
        : ''}
    </Col>
  );
};

export default CardFilter(BookFinishes);
