import React, { Component } from 'react';
import { connect } from 'react-redux';

// hocs
import Modal from '../../hocs/Modal';

// components
import Loader from '../../components/Loader';

// actions
import { fetchFutureReleases } from '../../actions/credits';

// utils
import { numberFormat, dateTransform } from '../../utils';
import { TextAlign } from '../../styles/align';

// styles
import {
  TableStyle,
  TableContainer,
  TableTbody,
  TableTr,
  TableTd,
} from '../../styles/table';
import { StyleFutureReleases } from './style';

class FutureReleases extends Component {
  componentDidMount() {
    this.props.fetchFutureReleases();
  }

  render() {
    const { futureReleases } = this.props;

    if (!futureReleases) {
      return (
        <StyleFutureReleases>
          <Loader padding={'0'} />
        </StyleFutureReleases>
      );
    }

    if (!futureReleases.data.length) {
      return (
        <StyleFutureReleases>
          <TextAlign>Não há lançamentos futuros</TextAlign>
        </StyleFutureReleases>
      );
    }

    return (
      <StyleFutureReleases>
        {futureReleases.data.map((item, i) => {
          return (
            <div key={i}>
              <TableStyle>
                <TableContainer>
                  <thead>
                    <tr>
                      <TableTr>Data da Compra</TableTr>
                      <TableTr>Valor do Pedido</TableTr>
                      <TableTr>Valor Pago</TableTr>
                      <TableTr>Valor a Debitar</TableTr>
                    </tr>
                  </thead>
                  <TableTbody>
                    <tr>
                      <TableTd>{dateTransform(item.dta_inc_pedido)}</TableTd>
                      <TableTd>
                        R$ {numberFormat(item.valor_total_pedido)}
                      </TableTd>
                      <TableTd>R$ {numberFormat(item.valor_pago)}</TableTd>
                      <TableTd>R$ {numberFormat(item.vlr_bonus)}</TableTd>
                    </tr>
                  </TableTbody>
                </TableContainer>
              </TableStyle>
              <div>
                <span>
                  Observação: <strong>{item.obs_bonus}</strong>
                </span>
              </div>
            </div>
          );
        })}
      </StyleFutureReleases>
    );
  }
}

const mapStateToProps = state => {
  return {
    futureReleases: state.credits.futureReleases,
  };
};

FutureReleases = connect(mapStateToProps, { fetchFutureReleases })(
  FutureReleases
);
FutureReleases = Modal(FutureReleases);

export default FutureReleases;
