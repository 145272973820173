import React from "react";
// node-modules
import Icon from "@mdi/react";
import { mdiCheck } from "@mdi/js";
// styles
import { Container } from "../../styles/align";
import { Button } from "../../styles/forms";
import { StyleSuccess } from "./style";

const Success = props => {
  const { pk_pedido } = props.match.params;
  return (
    <Container>
      <StyleSuccess>
        <Icon path={mdiCheck} title={"Sucesso"} size={5} />
        <h2>Seu pedido foi finalizado com sucesso</h2>
        <p>
          Número do pedido:<strong>{pk_pedido}</strong>
        </p>
        <Button onClick={() => props.history.push(`/pedido/${pk_pedido}`)}>
          Acompanhe seu pedido
        </Button>
      </StyleSuccess>
    </Container>
  );
};

export default Success;
