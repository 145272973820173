import loja from '../apis/loja';

import { getToken } from '../services/cart';

import { FETCH_REDIRECT } from './types';

/**
 * Consulta o retorno do Editor no backend
 */

export const fetchRedirect = data => async dispatch => {
  const formData = new FormData();
  formData.set('hash_job', data);
  formData.set('token_cart', getToken());
  try {
    const response = await loja.post(`/editor/retorno`, formData);
    localStorage.setItem('dados', JSON.stringify(response))
    dispatch({ type: FETCH_REDIRECT, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_REDIRECT, payload: {} });
  }
};
