import styled from 'styled-components';

// Style
import { Summary } from '../../components/SummaryCart/style';
import { MainPage } from '../../styles/align';
import { CardInfo } from '../../styles/cards';
import { BtnGroup, Button, Label, Input, Textarea } from '../../styles/forms';
import { colors, fontSize } from '../../styles/variables';

import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Control } from '../../components/NewComponents/Button/Control';
import { Root } from '../../components/NewComponents/Button/Root';

/**
 * Formatação o Card Info Pagamento
 * @extends {CardInfo}
 *
 * Herda estilo para formatar o Card
 * @extends {Label}
 * @extends {BtnGroup}
 * @extends {Button}
 * @extends {colors}
 *
 */
export const CardPayment = styled(CardInfo)`
  margin-left: 1rem;

  @media (max-width: 768px) {
    margin-left: 0;
  }

  ul {
    list-style-type: none;
    line-height: 1.5;

    li {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  p {
    &:last-of-type {
      margin-bottom: 1rem;
    }
  }

  ${Label} {
    color: ${colors.primary};
    padding: 0;
    margin-bottom: 1rem;
    font-weight: 700;
  }

  input {
    background-color: ${colors.default};
  }

  ${BtnGroup} {
    justify-content: center;
    align-items: center;
    padding: 1rem 0;

    ${Button} {
      min-width: fit-content;
      background-color: ${colors.primary};
      color: ${colors.default};
      text-decoration: none;
    }
  }

  .obs-highlight {
    background-color: red;
    color: white;
  }
`;

/**
 * Formata o bloco Main da Pagina Detalhe do Pedido
 * @extends {MainPage}
 *
 */
export const Main = styled(MainPage)`
  justify-content: initial;
`;

/**
 * Formata o Container Principal da Pagina do Detalhe
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o Container
 * @extends {Summary}
 */
export const OrderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  ${Summary} {
    max-width: 100%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

/**
 * Formata os items do pedido
 * @extends {div} Tag div nativa do HTML
 */
export const Items = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
`;

/**
 * Formata o container do Item
 * @extends {div} Tag div nativa do HTML
 */
export const ItemContainer = styled.div`
  width: 100%;
`;

/**
 * Formata a legenda da autorização de produção
 * @extends {div} Tag div nativa do HTML
 */
export const CaptionMessage = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: ${fontSize.sm};
  color: ${colors.red.primary};
  padding: 0rem 0.2rem;
  width: 15rem;
  margin-left: 2rem;
`;

/**
 * Formata o Item
 * @extends {div} Tag div nativa do HTML
 */
export const Item = styled.div`
  margin-bottom: 1rem;
`;

/**
 * Formata o Header do Item [nº | desc]
 * @extends {div} Tag div nativa do HTML
 */
export const ItemHeader = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: ${colors.grey.light};
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: auto;
  cursor: pointer;

  span {
    display: flex;
    align-items: center;
    color: ${colors.primary};

    span {
      color: ${colors.error};
      font-size: ${fontSize.lg};
      margin-right: 1rem;
    }
  }
`;

// export const ContainerResendFiles = styled.div`
//   width: 100%;
//   height: 100%;
//   padding: 1rem;
//   background-color: ${colors.grey.light};
//   display: flex;
//   align-items: center;
//   border-bottom: 2px solid ${colors.grey.lightPrimary};
//   pointer-events: auto;
//   cursor: pointer;
//   gap: 1rem;

//   span {
//     display: flex;
//     align-items: center;
//     color: ${colors.primary};

//     span {
//       color: ${colors.error};
//       font-size: ${fontSize.lg};
//       margin-right: 1rem;
//     }

//   }
// `;

export const ContainerResendFiles = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: ${colors.grey.light};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 2px solid ${colors.grey.lightPrimary};
  pointer-events: auto;
  cursor: pointer;
  gap: 1rem;

  span {
    display: flex;
    align-items: center;
    color: ${colors.primary};

    span {
      color: ${colors.error};
      font-size: ${fontSize.lg};
      margin-right: 1rem;
    }
  }
`;

/**
 * Formata Conteudo do Item [resumo | arquivos]
 * @extends {div} Tag div nativa do HTML
 */
export const ItemContent = styled.div`
  max-height: 0;
  overflow: hidden;

  &.active {
    max-height: 800px;
    transition: max-height 400ms ease-in-out;
    overflow: auto;
  }

  &.hidden {
    max-height: 0;
    overflow: hidden;
  }

  hr {
    margin: 1rem 0;
    color: ${colors.grey.lightPrimary};
  }

  & > div:first-child {
    display: flex;
    flex-direction: row;
  }

  & > div {
    display: flex;
    flex-direction: row;
    background-color: ${colors.grey.lightPrimary};
    padding: 1rem;
    width: 100%;

    .actions__item {
      padding: 1rem;
      width: 50%;
      color: ${colors.secondary};
      word-wrap: break-word;
    }

    h3 {
      color: ${colors.primary};
      font-weight: 400;
      font-size: ${fontSize.sm};
      margin-bottom: 0.8rem;
    }

    p {
      display: flex;
      flex-direction: column;

      span {
        line-height: 1.5;
      }
    }
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const TiltleArcives = styled.p`
  color: ${colors.primary};
  text-align: center;
  font-weight: 700;
  font-size: ${fontSize.lg};
  margin-bottom: 1rem;
`;

export const TitleAnexos = styled.p`
  color: ${colors.red.primary};
  font-size: ${fontSize.sm};
`;

/**
 * Formata o Botão de Cancelamento do Pedido
 * @extends {BtnGroup}
 *
 * Herda estilo para formatar o Botão
 * @extends {Button}
 * @extends {fontSize}
 * @extends {colors}
 */

export const CancelOrder = styled(BtnGroup)`
  justify-content: center;
  margin-bottom: 1rem;

  ${Button} {
    min-width: fit-content;
    font-size: ${fontSize.sm};
    font-weight: 700;
    color: ${colors.secondary};
    font-style: normal;
  }
`;

/**
 * Formata as ações do Pedido Item
 * @extends {BtnGroup}
 *
 * Herda estilo para formatar o ações
 * @extends {Button}
 * @extends {fontSize}
 * @extends {colors}
 */
export const ItemActionsobs = styled(BtnGroup)`
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;

  ${Button} {
    text-transform: capitalize;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-size: ${fontSize.sm};
    text-decoration: none;
    color: ${colors.default};
    min-width: fit-content;
    margin-right: 1rem;

    @media (max-width: 768px) {
      max-width: 100%;
      width: 100%;
      margin-bottom: 1rem;
    }
  }

  p {
    margin: 0;
    padding: 5px;
  }
  .warning {
    color: red;
    font-weight: bold;
  }
  .status-label {
    font-weight: bold;
    margin-right: 5px;
    color: #0e4e96;
  }

  .status-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    font-size: 12px;
    color: #666;
  }

  .status-label {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    margin-right: 5px;
  }
`;
export const ItemActions = styled(BtnGroup)`
  width: 100%;
  justify-content: flex-start;
  background-color: ${colors.grey.lightPrimary};
  padding: 0rem;
  flex-wrap: wrap;

  ${Button} {
    text-transform: capitalize;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-size: ${fontSize.sm};
    text-decoration: none;
    color: ${colors.default};
    min-width: fit-content;
    margin-right: 1rem;

    @media (max-width: 768px) {
      max-width: 100%;
      width: 100%;
      margin-bottom: 1rem;
    }
  }
`;

/**
 * Formata ao botao de Contestação
 * @extends {Button}
 *
 * Herda estilo para formatar o botao
 * @extends {colors}
 */
export const ButtonContest = styled(Button)`
  background-color: ${colors.grey.darkPrimary};
`;

/**
 * Formata ao botao de reenviar Contestação
 * @extends {Button}
 *
 * Herda estilo para formatar o botao
 * @extends {colors}
 */
export const ButtonResendContest = styled(Button)`
  background-color: ${colors.grey.darkPrimary};
`;

/**
 * Formata ao botao solicitar reenvio
 *
 *
 * Herda estilo para formatar o botao
 * @extends {colors}
 */
export const ButtonRequestResendFiles = styled(Control)`
  width: 16rem;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  color: ${colors.default};
  transition: background-color 0.1s ease, color 0.1s ease, box-shadow 0.2s ease,
    transform 0.2s ease;
  box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: ${colors.hover.primary};
    color: ${colors.default};
  }
`;

export const ButtonResendFiles = styled(Control)`
  width: 14rem;
  color: ${colors.secondary};
  background-color: ${colors.blue.blue50};
  //border: 1px solid ${colors.grey.lightPrimary};;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: ${colors.hover.blue100};
    color: ${colors.secondary};
  }
`;

export const ButtonAuthProd = styled(Control)`
  width: 14rem;
`;

export const ButtonCancelItem = styled(Control)`
  width: 12rem;
  background-color: ${colors.grey.darkPrimary};
  color: ${colors.default};

  &:hover {
    background-color: ${colors.red.primary};
  }
`;

export const RootButtonResendFiles = styled(Root)`
  justify-content: flex-end;
`;

export const Separator = styled.div`
  height: 2px; /* Altura do separador */
`;

/**
 * Formata ao botao reenvio de arquivos
 * //@extends {Button}
 *
 * Herda estilo para formatar o botao
 * @extends {colors}padding: 0.2rem 0.2rem;
 */

/**
 * Formata ao botao Cancelamento do Item
 * @extends {Button}
 *
 * Herda estilo para formatar o botao
 * @extends {colors}
 */

/**
 * Formata ao botao Autorizar Produção
 * @extends {Button}
 *
 * Herda estilo para formatar o botao
 * @extends {colors}
 */

/**
 * Formata as Observações do Pedido Item
 * @extends {Col}
 *
 * Herda estilo para formatar o Observações
 * @extends {colors}
 */
export const Obs = styled.div`
  word-wrap: break-word;
  p {
    margin-bottom: 0.75rem;
    word-wrap: break-word;
    span {
      &:last-of-type {
        color: ${colors.grey.dark};
      }
    }
  }
`;

export const Observations = styled.span`
  display: flex;
  text-decoration: underline;
  //gap: 0.2rem;
  align-items: center;
  //margin-right: 1rem;

  &:hover {
    color: ${colors.hover.primary900};
    svg {
      color: ${colors.hover.primary900};
    }
  }
`;

export const FiChevronDownIcon = styled(FiChevronDown, FiChevronUp)`
  color: ${colors.red.primary};
  margin-top: 4px;
  gap: 0.2rem;
`;

export const DateObservation = styled.span`
  font-size: 0.8rem;
  color: ${colors.grey.darkSecondary};
`;

/**
 * Formata o status do Pedido
 * @extends {span} Tag span Nativa do HTML
 *
 * Herda estilo para formatar o Observações
 * @extends {colors}
 *
 * Recebe parametros para definição
 * @param {props.colorStatus}
 */
export const Status = styled.span`
  color: ${props => (props.colorStatus ? props.colorStatus : colors.grey.dark)};
`;

/**
 * Formata o estilo para o detalhe do Pagamento
 * @extends {p} Tag p Nativa do HTML
 *
 * Herda estilo para formatar o detalhe
 * @extends {fontSize}
 * @extends {colors}
 */
export const DetailPayment = styled.p`
  color: ${colors.primary};
  font-size: ${fontSize.md};

  strong {
    color: ${colors.primary};
    font-weight: 700;
  }
`;

/**
 * Formata O Preço
 * @extends {span} Tag span Nativa do HTML
 *
 * Herda estilo para formatar o Preço
 * @extends {colors}
 */
export const Price = styled.span`
  color: ${colors.secondary};
`;

export const ButtonGroup = styled(BtnGroup)`
  display: flex;
`;

export const FieldEditObservation = styled.div`
  display: flex;
  flex-direction: row;

  p {
    display: flex;
    align-items: center;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${BtnGroup} {
    margin-top: 15px;
    width: 100%;
  }

  ${Input} {
    width: 100%;
    height: 32px;
    text-indent: 0.5rem;
    margin-top: 10px;
  }

  ${Textarea} {
    font-family: 'Open Sans', sans-serif;
    height: 105px;
    font-size: 0.875rem;
    color: ${colors.grey.darkPrimary};
  }
`;

export const ErrorMessage = styled.div`
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  text-align: center;
`;
