import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// actions
import { closeMenu, fetchMenus } from '../../actions/menu';
// styles
import { Container } from '../../styles/align';
// components
import Cart from '../Cart';
import Controls from '../Controls';
import SearchBar from '../SearchBar';
// skeleton
import SkeletonMenu from './skeleton';
import { CartMenu, nav as NavStyle, NavStyleMobile, Separator, SeparatorMenu, ServicesInstructionsContainer } from './style';

class Nav extends Component {
  componentDidMount() {
    this.props.fetchMenus();
  }

  renderList() {
    const { menus } = this.props;
    if (!menus) {
      return <SkeletonMenu />;
    }

    return (
      <ul>
        {menus
          .filter(menu => menu.link !== null && menu.link !== '')
          .map((menu, index) => {
            return (
              <React.Fragment key={index}>
                <li onClick={this.props.closeMenu}>
                  <Link to={`/${menu.link}`} title={menu.desc}>
                    {menu.desc}
                  </Link>
                </li>
                <SeparatorMenu></SeparatorMenu>
              </React.Fragment>
            );
          })}
      </ul>
    );
  }

  render() {
    return (
      <>
        <NavStyle>
          <Container>{this.renderList()}</Container>
        </NavStyle>
        <NavStyleMobile>
          <Controls />
          <ServicesInstructionsContainer>
              <a
                target={'_blank'}
                rel={'noreferrer'}
                href="https://blog.fabricadolivro.com.br/serviços-editoriais"
                title={'Serviços Editoriais Fábrica do Livro'}
              >
                SERVIÇOS EDITORIAIS
              </a>
              <a
                target={'_blank'}
                rel={'noreferrer'}
                href="https://instrucoes.fabricadolivro.com.br"
                title={'Instruções Fábrica do Livro'}
              >
                INSTRUÇÕES
              </a>
            </ServicesInstructionsContainer>
          <Separator/>
          <CartMenu onClick={this.props.closeMenu}>
            <Cart />
            <Link to={'/carrinho'}>
              <span>Meu Carrinho</span>
            </Link>


          </CartMenu>
          
          <ul>{this.renderList()}</ul>
          <SearchBar onCloseMenu={this.props.closeMenu} />
        </NavStyleMobile>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    menus: state.menu.menus,
  };
};

export default connect(mapStateToProps, { fetchMenus, closeMenu })(Nav);
