import styled from 'styled-components';

import { colors, fontSize } from '../../../styles/variables';

export const ControlButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${colors.primary};
  color: ${colors.default};
  border-radius: 8px;
  padding: 0.4rem 0.4rem;
  font-size: ${fontSize.md};
  cursor: pointer;
  height: fit-content;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  border: none;
  transition: background-color 0.1s ease, color 0.1s ease, transform 0.1s ease;

  &:hover {
    background-color: ${colors.hover.primary900};
    color: ${colors.default};
  }

  &:focus {
    outline: none;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

export const RootButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
