import styled from "styled-components";

// Styles
import { Container, Row, Col } from "../../styles/align";
import { TitlePage } from "../../styles/titles";
import { colors, fontSize } from "../../styles/variables";

/**
 * Formata o Container da Página de Contato
 * @extends {Container}
 * 
 * Herda estilo para formatar o interior do Container
 * @extends {TitlePage}
 * @extends {Row}
 * @extends {Col}
 * @extends {fontSize}
 * @extends {colors}
 */
export const StyleContact = styled(Container)`
  flex-direction: column;
  min-height: 400px;

  ${TitlePage} {
    span {
      color: ${colors.secondary};
    }
  }

  ${Row} {
    padding: 2rem 0;

    ${Col} {
      background-color: ${colors.grey.light};
      padding: 1rem;

      h3 {
        font-size: ${fontSize.lg};
        color: ${colors.primary};
        font-weight: 700;
        margin-bottom: 2rem;
      }

      ul {
        list-style: none;
        width: 100%;

        li {
          line-height: 2;
          display: flex;
          flex-direction: row;

          a {
            text-decoration: none;
            color: inherit;
          }

          span,
          p {
            display: inline;
            text-decoration: none;
          }

          span {
            font-weight: 700;
            min-width: 120px;
          }

          svg {
            width: 2.5rem;
            height: 2.5rem;
            margin-right: 0.5rem;
          }
        }
      }

      &:first-of-type {
        margin-right: 1rem;
      }

      &:last-of-type {
        flex-wrap: wrap;
        flex-direction: row;

        ul {
          display: flex;
          flex-wrap: wrap;

          li {
            width: 50%;
            margin-bottom: 1rem;

            a {
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
  }
`;
