import loja from '../apis/loja';

import {
  FETCH_FILES,
  CLEAR_FILES,
  SUBMIT_FORM_FILES,
  FILES_AUTH,
  SUBMIT_CREATE_ONLINE,
} from './types';
import { toast } from 'react-toastify';

/**
 * Busca os dados pra montar a página de "Enviar Arquivos"
 * No objeto precisa ter obrigatoriamente a chave 'produto' [integer]
 * @param {object} data
 */
export const fetchFiles = data => async dispatch => {
  try {
    const response = await loja.get('/produto/buscar-arquivos', {
      params: data,
    });

    dispatch({ type: FETCH_FILES, payload: response.data });
  } catch (error) {
    toast.error(error.response.data.message);
    return Promise.reject(error);
  }
};

/**
 * Reseta os dados do filtro no store
 */
export const clearFiles = () => {
  return {
    type: CLEAR_FILES,
  };
};

/**
 * Flag que verifica se o form precisa do submit
 */
export const isCreateOnline = value => {
  return {
    type: SUBMIT_CREATE_ONLINE,
    payload: value,
  };
};

/**
 * Flag que verifica se o form precisa do submit
 */
export const isSubmitForm = value => {
  return {
    type: SUBMIT_FORM_FILES,
    payload: value,
  };
};

/**
 * Flag para mostrar a modal de autorização de arquivos não enviados
 */
export const filesAuth = value => {
  return {
    type: FILES_AUTH,
    payload: value,
  };
};
