import styled from 'styled-components';

// Styles
import { colors } from '../../styles/variables';
import { Row } from '../../styles/align';
import { BtnGroup, Button } from '../../styles/forms';
import { Container } from '../style';

/**
 * Formata o Container da Modal Bkn Bank
 *
 * @extends {Container}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Row}
 * @extends {BtnGroup}
 * @extends {Button}
 * @extends {colors}
 */
export const StyleBknBank = styled(Container)`
  position: relative;
  background-color: ${colors.grey.light};
  padding: 1rem;

  ${Row} {
    text-align: left;
    color: ${colors.grey.darkPrimary};

    p {
      color: ${colors.grey.darkPrimary};
      padding: 2rem 0;
      line-height: 1.5;

      strong {
        font-weight: 700;
        color: ${colors.grey.dark};
      }
    }

    ${BtnGroup} {
      width: 100%;
      justify-content: flex-end;

      ${Button} {
        text-decoration: none;

        &:last-of-type {
          background-color: ${colors.primary};
          color: ${colors.default};
          max-width: 230px;
        }
      }
    }
  }
`;
