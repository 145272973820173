import styled from 'styled-components';

// Styles
import { colors } from '../../styles/variables';

/**
 * Formata o Overlay dos Modais
 *
 * @extends {div} Tag div nativa do HTML
 */
export const ModalOverlay = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 998;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

/**
 * Formata o Wrapper dos Modais
 *
 * @extends {div} Tag div nativa do HTML
 *
 * Recebe parametros de configuração
 * @param {props.width} "props.width || 60" vw
 */
export const ModalWrapper = styled.div`
  width: ${props => (props.width ? props.width : '60')}vw;

  @media (max-width: 768px) {
    width: 90vw;
  }

  overflow: hidden;
`;

/**
 * Formata o Header dos Modais
 *
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do Header
 * @extends {colors}
 */
export const ModalHeader = styled.div`
  width: 100%;
  color: ${colors.default};
  padding: 0.5rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-style: italic;
    font-weight: 400;
    font-size: 1.4rem;
  }

  svg {
    fill: ${colors.default};
  }
`;

/**
 * Formata o Content dos Modais
 *
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do Content
 * @extends {colors}
 */
export const ModalContent = styled.div`
  background: ${colors.default};
  width: 100%;
  height: 100%;
  overflow: auto;
  max-height: 90vh;
`;
