import React, { Component } from 'react';
import { Form, Field, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';

// node
import moment from 'moment';

// Actions
import { fetchHistoryCredits } from '../../../actions/credits';
import { modalFutureReleases } from '../../../actions/modals';

// validate
import renderField from '../../../formValidate/renderField';
import { maskDate } from '../../../formValidate/masks';
import { date } from '../../../formValidate';

// Styles
import { StyleFilter, FeatureReleases } from './style';
import { Row, Col } from '../../../styles/align';
import {
  Wrapper,
  Label,
  BtnGroup,
  Button,
  ButtonPrimary,
} from '../../../styles/forms';
import { TitleCards } from '../../../styles/titles';

// options
import { periodo } from './options';

class FilterCredits extends Component {
  submit = async values => {
    const data = {
      dta_ini: moment(values.dta_ini, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      dta_fim: moment(values.dta_fim, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    };
    this.props.fetchHistoryCredits(data);
  };

  handleDefineDate = value => {
    const { dispatch } = this.props;
    const dta_fim = moment().format('L');
    const dta_ini = moment()
      .subtract(value, 'days')
      .calendar();

    dispatch(change('filterCredits', 'dta_ini', dta_ini));
    dispatch(change('filterCredits', 'dta_fim', dta_fim));
  };

  render() {
    const { handleSubmit, reset } = this.props;

    return (
      <StyleFilter>
        <TitleCards>Filtro de busca</TitleCards>
        <Form onSubmit={handleSubmit(this.submit)}>
          <Wrapper>
            <Label htmlFor={'periodo'}>Periodo</Label>
            <Field
              name={'periodo'}
              type={'select'}
              typefield={'select'}
              component={renderField}
              onChange={e => this.handleDefineDate(e.target.value)}
              firstOption={
                <option value={0} disabled>
                  Selecione
                </option>
              }
              options={periodo.map((item, i) => {
                return (
                  <option key={i} value={item.days}>
                    {item.text}
                  </option>
                );
              })}
            />
          </Wrapper>
          <Col className={'date__picker-container'}>
            <Row className={'date__picker-content'}>
              <Wrapper>
                <Label>Data</Label>
                <Field
                  name={'dta_ini'}
                  type={'mask'}
                  typefield={'mask'}
                  component={renderField}
                  mask={maskDate}
                  placeholder={'dd/mm/aaaa'}
                  validate={date}
                />
              </Wrapper>
              <p className={'date__picker-content-text'}>Até</p>
              <Wrapper>
                <Field
                  name={'dta_fim'}
                  type={'mask'}
                  typefield={'mask'}
                  component={renderField}
                  mask={maskDate}
                  placeholder={'dd/mm/aaaa'}
                  validate={date}
                />
              </Wrapper>
            </Row>
            <BtnGroup>
              <Button onClick={handleSubmit(reset)}>Limpar</Button>
              <ButtonPrimary >Filtrar</ButtonPrimary>
            </BtnGroup>
          </Col>
        </Form>
        <FeatureReleases>
          <ButtonPrimary onClick={this.props.modalFutureReleases}>
            Lançamentos Futuros
          </ButtonPrimary>
        </FeatureReleases>
      </StyleFilter>
    );
  }
}

FilterCredits = reduxForm({
  form: 'filterCredits',
  initialValues: {
    periodo: 0,
  },
})(FilterCredits);

FilterCredits = connect(null, {
  fetchHistoryCredits,
  modalFutureReleases,
})(FilterCredits);

export default FilterCredits;
