import styled from 'styled-components';

//styles
import { colors } from '../../styles/variables';
import { Col } from '../../styles/align';
import { Label, BtnGroup } from '../../styles/forms';
import { Container } from '../style';

/**
 * Formata o Container da Modal Feedback
 *
 * @extends {Container}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Col}
 * @extends {Label}
 * @extends {BtnGroup}
 * @extends {colors}
 */
export const StyleMessageFeedback = styled(Container)`
  ${Col} {
    margin-bottom: 1rem;

    p {
      color: ${colors.grey.dark};
    }

    ${Label} {
      padding-left: 0;
      color: ${colors.primary};
      font-weight: 700;
    }

    textarea {
      min-width: 100%
      max-width: 100%;
      min-height: 7rem;
      max-height: 7rem;
    }

    .dv-star-rating {
      label {
        font-size: 1.5rem;
      }
    }
  }

  ${BtnGroup} {
    align-items: center;
    justify-content: space-between;

    span {
      &:first-of-type {
        font-weight: 700;
        color: ${colors.secondary};
      }
    }
  }
`;
