import baixaArquivos from '../apis/baixaArquivos';
import editor from '../apis/editor';
import loja from '../apis/loja';
import config from '../config';
import _ from 'lodash';
// utils
import { getExtension } from '../utils';
import {
  EXTENSIONS_ERROR,
  CONNECTION_ERROR,
  REQUIRED_FILES,
  RECEIPT_SENDED,
  SEND_EDITOR_ERROR,
} from '../utils/constMessage';
import storage from '../utils/Storage';
import { DATA_PRODUCT, INFO_EDITOR, CART_TOKEN } from '../utils/Storage/types';
// services
import { getToken } from './cart';
import { init as initEditor } from './editor';
import { addProduct, gabarito } from './filter';
import { toast } from 'react-toastify';
import TagManager from 'react-gtm-module';

export const getDataProduct = () =>
  JSON.parse(localStorage.getItem(DATA_PRODUCT));

/**
 * Envia os arquivos para o script "arquivos.php" da baixa
 * @param {*} arquivos
 */
export const sendFiles = async arquivos => {
  const formData = new FormData();
  arquivos.forEach((arquivo, index) => {
    formData.append(index, arquivo);
  });

  try {
    let response = await baixaArquivos.post('/arquivos.php', formData, {});

    return response;
  } catch (error) {
    return { error: 'CONNECTION_ERROR' };
  }
};

/**
 * Adiciona o produto no storage pra ir para a pagina de envio
 * @param {array} itens do carrinho
 * @param {int} index
 */
export const resendFiles = async (item, index) => {
  let itemSubmit = item;
  // verifica se o item é do tipo livro para adicionar as
  // informações especificas
  if (config.TYPE_BOOK === item.produto.fk_tipo_produto) {
    const dataBookItem = { ...item.pedidoItemAtributoAdicional };
    const dataBook = {
      orientacao: dataBookItem.orientacao,
      tipos_papel_miolo: dataBookItem.fk_dependencia_atributo,
      paginas_coloridas: dataBookItem.paginas_color,
      paginas_preto_branco: dataBookItem.paginas_pb,
      exemplares: item.qtd_pedido_item,
    };
    itemSubmit = { ...item, ...dataBook };
  }

  await addProduct(itemSubmit, itemSubmit.fk_produto, index);
};

const createPayloadEditor = () => {
  const produto = storage.get(DATA_PRODUCT);
  localStorage.setItem('product', JSON.stringify(produto));
  let data = { ...produto };
  let route_success = '/carrinho';
  let adicionar_storage = {
    nome: CART_TOKEN,
    dado: '', // tem que ser preenchido no back, ou com informações que vem do back
  };
  let action = '';

  if (data.referencia || data.referencia === 0) {
    action = '/carrinho/salvar-arquivos';
  } else {
    action = '/carrinho/adicionar';
  }

  if (data.pedido) {
    action = '/reenviar-arquivos';
    route_success = `/pedido/${data.pedido}`;
    adicionar_storage = null;
  }

  const payload = {
    rota: action,
    limpar_storage: DATA_PRODUCT,
    rota_sucesso: route_success,
    rota_erro: '/envio',
    adicionar_storage,
    data,
  };

  return payload;
};

const redirectToEditor = hashJob => {
  const form = document.createElement('form');
  form.method = 'post';
  form.action = `${config.editor}${hashJob}`;

  const editor = storage.get(INFO_EDITOR);

  form.appendChild(createHiddenField('token', JSON.stringify(editor.token)));
  form.appendChild(createHiddenField('urlApi', editor.urlApi));
  form.appendChild(createHiddenField('urlAssets', editor.urlAssets));
  form.appendChild(createHiddenField('hashJob', hashJob));

  document.body.appendChild(form);
  form.submit();
};

export const createFiles = async arquivos => {
  addFilesToStorage(arquivos);

  const payload = createPayloadEditor();

  const analyze = await sendEditor(arquivos, null, payload, true);

  redirectToEditor(analyze.hashJob);

  return analyze;
};

export const sendEditor = async (
  arquivos,
  dataFiles,
  payload,
  createFiles = false
) => {
  let data = [];

  if (dataFiles) {
    Object.keys(arquivos).forEach(key => {
      data = [
        ...data,
        ...dataFiles.filter(
          arquivo => arquivo.pk_produto_arquivo === parseInt(key)
        ),
      ];
      data.sort((a, b) =>
        a.ordem_produto_arquivo > b.ordem_produto_arquivo ? 1 : -1
      );
    });
  }

  try {
    const titleJob =
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15);

    await initEditor();

    const createData = new FormData();
    createData.set('titleJob', titleJob);
    createData.set('payload', JSON.stringify(payload));

    const response = await editor.post(
      '/api/engine/job/create',
      createData,
      {}
    );

    const hashJob = response.data.data;

    const instrucoesDuvida =
      'https://instrucoes.fabricadolivro.com.br/perguntas-frequentes/como-manter-arte-dentro-dos-padroes-da-grafica/';

    if (createFiles) {
      let filesPdf = {};
      arquivos.map(async (item, i) => {
        let filesPdfInfo = {
          countPages: item.editor.paginasCMYK + item.editor.paginasPB,
          width: item.editor.largura,
          height: item.editor.altura,
          urlRetorno: `${window.location.origin}/retorno/${hashJob}`,
          instrucoesDuvida,
        };
        Object.entries(item.editor).forEach((value, index) => {
          if (value[0] === 'gabaritos') {
            const gabaritosFormatado = gabarito(value[1]);
            let gabaritos = [];
            gabaritosFormatado.forEach(
              (gabarito, index) =>
                (gabaritos[index] = [gabarito.desc, gabarito.value])
            );
            filesPdfInfo = {
              ...filesPdfInfo,
              gabaritos,
            };
          } else {
            filesPdfInfo = {
              ...filesPdfInfo,
              [value[0]]: Array.isArray(value[1]) ? `[${value[1]}]` : value[1],
            };
          }
        });
        filesPdfInfo = {
          ...filesPdfInfo,
          mode: 'edit',
        };

        filesPdf = { ...filesPdf, [item.editor.type]: filesPdfInfo };
      });

      const addWhitePdf = {
        hashJob,
        filesPdf,
      };

      await editor.post('/api/engine/make/add-white-pdf', addWhitePdf, {});
    } else {
      const a = [];
      data.map(async (item, i) => {
        const filesData = new FormData();
        filesData.set('fileUpload', arquivos[item.pk_produto_arquivo]);
        filesData.set('original', arquivos[item.pk_produto_arquivo]['name']);
        Object.entries(item.editor).forEach((value, index) => {
          if (value[0] === 'gabaritos') {
            const gabaritos = gabarito(value[1]);
            gabaritos.forEach((gabarito, index) => {
              filesData.set(`${value[0]}[${index}][0]`, gabarito.desc);
              filesData.set(`${value[0]}[${index}][1]`, gabarito.value);
            });
          } else {
            filesData.set(
              value[0],
              Array.isArray(value[1]) ? `[${value[1]}]` : value[1]
            );
          }
        });
        filesData.set('mode', 'review');
        filesData.set('hashJob', hashJob);
        filesData.set(
          'urlRetorno',
          `${window.location.origin}/retorno/${hashJob}`
        );
        filesData.set('instrucoesDuvida', instrucoesDuvida);
        a.push(filesData);
      });

      await processArray(a, processItem);
    }

    const analyze = await editor.post('/api/engine/analyze/analyze-pdf', {
      hashJob,
    });

    analyze.hashJob = hashJob;

    return analyze;
  } catch (error) {
    return error;
  }
};

const processItem = item => {
  return editor.post('/api/engine/upload', item, {});
};

const processArray = async (array, fn) => {
  let results = [];
  for (let i = 0; i < array.length; i++) {
    let r = await fn(array[i]);
    results.push(r);
  }
  return results; // will be resolved value of promise
};

export const sendReceipt = async (arquivos, pedido, extensoes) => {
  const verifyExt = verifyExtension(arquivos, extensoes);
  if (!verifyExt) {
    return { status: 'error', message: EXTENSIONS_ERROR };
  }

  const formData = new FormData();

  formData.append('1', arquivos);
  formData.append('tipo', 'comprovante');

  try {
    let responseFiles = await baixaArquivos.post('/arquivos.php', formData, {});
    if (responseFiles.status === 200) {
      const comprovante = responseFiles.data.data[1];
      try {
        await loja.post('/pedido/enviar-comprovante', {
          pedido,
          comprovante,
        });
        return { status: 'success', message: RECEIPT_SENDED };
      } catch (error) {
        return { status: 'error', message: CONNECTION_ERROR };
      }
    }

    return responseFiles;
  } catch (error) {
    return { status: 'error', message: CONNECTION_ERROR };
  }
};

/**
 * Faz o download de um arquivo a partir do blob
 * @param {string} blob
 * @param {string} name
 */
export const downloadBlob = (blob, name) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  // setTimeout(() => window.URL.revokeObjectURL(url), 7000);
};

/**
 * Faz o download de um arquivo
 * @param {string} link
 * @param {Object} initParams
 */
export const downloadFile = (link, initParams = null) => {
  if (link) {
    let fetchObj = fetch(link);
    if (initParams) {
      fetchObj = fetch(link, initParams);
    }

    fetchObj
      .then(resp => resp.blob())
      .then(blob => {
        const name = link.split('/').slice(-1);
        downloadBlob(blob, name);
      })
      .catch(() => toast.error(CONNECTION_ERROR));
  }
};

/**
 * Verifica as extensões dos arquivos com os arquivos enviados pelo cliente
 * @param {array} inputFiles
 * @param {array} files
 */
const verifyExtensions = (inputFiles, files) => {
  let result = true;
  let errorFiles = [];

  for (let i = 0; i < files.length; i++) {
    if (inputFiles[files[i].pk_produto_arquivo] !== undefined) {
      const fileExt = getExtension(
        inputFiles[files[i].pk_produto_arquivo].name
      );

      const fileExtLowercase = files[i].extensoes.map(val => {
        val.toLowerCase();
        if (val === 'pdf x1a') {
          return val.replace(/pdf x1a/i, 'pdf');
        }
        return val;
      });

      if (!fileExtLowercase.includes(fileExt.toLowerCase())) {
        errorFiles = [...errorFiles, files[i].pk_produto_arquivo];
        result = false;
      }
    }
  }

  const data = {
    result,
    errorFiles,
  };

  return data;
};

/**
 * Verifica a extensão de um arquivo
 * @param {*} file
 * @param {*} extension
 */
const verifyExtension = (file, extensions) => {
  const fileExt = getExtension(file.name);
  let result = true;
  if (!extensions.includes(fileExt)) {
    result = false;
  }

  return result;
};

const addFilesToStorage = (files, inputFiles) => {
  let arquivos = {};
  Object.keys(files).forEach(key => {
    const pkProdutoArquivo = files[key].fk_acabamento
      ? files[key].fk_acabamento
      : files[key].pk_produto_arquivo;

    let arquivo = {
      is_acabamento: files[key].fk_acabamento ? 1 : 0,
      pk_acabamento_arquivo: files[key].pk_acabamento_arquivo
        ? files[key].pk_acabamento_arquivo
        : null,
      original: '',
      novo: '',
    };

    if (
      inputFiles !== undefined &&
      inputFiles[pkProdutoArquivo] !== undefined
    ) {
      arquivo.original = inputFiles[pkProdutoArquivo]['name'];
      arquivos[pkProdutoArquivo] = arquivo;
    }
  });

  storage.update(DATA_PRODUCT, {
    arquivos,
  });
};

export const submitForm = async (
  dataFiles,
  files,
  obs,
  fileAuth,
  editor_validacao
) => {
  let inputFiles = [];

  Object.keys(_.pickBy(dataFiles, _.identity)).forEach(key => {
    inputFiles[key] = dataFiles[key];
  });

  const verifyExt = verifyExtensions(inputFiles, files);
  if (!verifyExt.result) {
    return {
      status: 'error',
      message: EXTENSIONS_ERROR,
      errors: verifyExt.errorFiles,
    };
  }

  // verifica se todos os arquivos obrigatórios foram enviados
  const verifyReq = verifyRequired(inputFiles, files);
  if (verifyReq.required) {
    return { status: 'error', message: REQUIRED_FILES };
  }
  if (verifyReq.notRequired && fileAuth === '') {
    return { status: 'error', showModalFileAuth: true };
  }

  addFilesToStorage(files, inputFiles);

  storage.update(DATA_PRODUCT, {
    observacao: obs,
  });

  // insere a mensagem de autorizacao de arquivos não enviados
  if (fileAuth !== '') {
    storage.update(DATA_PRODUCT, { autorizacao_arquivo: fileAuth });
  }

  // Verifica se os Arquivos são todos PDFs e se a validação
  // esta ativa para o produto
  let openEditor = false;

  if (
    Object.values(inputFiles).filter(
      filter => filter.type === 'application/pdf'
    ).length === Object.keys(inputFiles).length &&
    editor_validacao
  ) {
    openEditor = true;
  }

  const payload = createPayloadEditor();

  // enviar arquivos para o editor
  const responseSendEditor = await sendEditor(inputFiles, files, payload);

  let hashJob = null;
  if (responseSendEditor.status === 200 && responseSendEditor.data.success) {
    hashJob = responseSendEditor.hashJob;
  } else if (
    responseSendEditor.status === 200 &&
    !responseSendEditor.data.success
  ) {
    hashJob = responseSendEditor.hashJob;
    openEditor = false;
  } else {
    return { status: 'error', message: SEND_EDITOR_ERROR };
  }

  if (openEditor) {
    // se todos os arquivos são PDF, então abre o editor
    redirectToEditor(hashJob);
    return { status: 'success', message: '' };
  } else {
    // [begin] Dados que devem ser setados no backend no retorno do editor
    if (hashJob) {
      payload.data = { ...payload.data, hash_editor: hashJob };
    }

    const tokenCart = getToken();
    if (tokenCart) {
      payload.data = { ...payload.data, token: tokenCart };
    }
    // [end] Dados que devem ser setados no backend no retorno do editor

    payload.data = { ...payload.data, openEditor };

    const responseProcessSubmit = await processSubmit(payload);

    if (responseProcessSubmit.status === 200) {
      const product = responseProcessSubmit.data.data.resposta.data.carrinho.itens.find(
        item => item.itemEditor.hash_editor === hashJob
      );
      if (product) {
        const { vlr_total_item, qtd_pedido_item } = product;
        const { desc_categoria, pk_produto } = product.produto;

        TagManager.dataLayer({
          dataLayer: {
            event: 'add_to_cart',
            ecommerce: {
              items: {
                item_name: desc_categoria,
                item_id: pk_produto,
                price: vlr_total_item,
                quantity: qtd_pedido_item,
              },
            },
          },
        });
      }

      return returnEditor(responseProcessSubmit.data.data);
    } else {
      return {
        status: 'error',
        message: CONNECTION_ERROR,
        redirect: payload.rota_erro,
      };
    }
  }
};

const processSubmit = async payload => {
  try {
    const response = await loja.post('/redirecionar', payload);
    return response;
  } catch (error) {
    return { error: 'CONNECTION_ERROR' };
  }
};

export const returnEditor = response => {
  if (response.resposta.status === 200) {
    if (response.payload.limpar_storage) {
      localStorage.removeItem(response.payload.limpar_storage);
    }

    if (response.payload.adicionar_storage) {
      response.payload.adicionar_storage.dado = response.resposta.data.token;
      localStorage.setItem(
        response.payload.adicionar_storage.nome,
        response.payload.adicionar_storage.dado
      );
    }

    return {
      status: 'success',
      message: '',
      redirect: response.payload.rota_sucesso,
    };
  } else {
    return {
      status: 'error',
      message: CONNECTION_ERROR,
      redirect: response.payload.rota_erro,
    };
  }
};

function createHiddenField(name, value, type = 'hidden') {
  const field = document.createElement('input');
  field.type = type;
  field.name = name;
  field.value = value;

  return field;
}

/**
 * verifica se tem arquivos não enviados
 * @param {object} inputFiles
 * @param {object} files
 */
function verifyRequired(inputFiles, files) {
  let required = false;
  let notRequired = false;
  for (let i = 0; i < files.length; i++) {
    if (!inputFiles[files[i].pk_produto_arquivo]) {
      if (files[i].obrigatorio_produto_arquivo === 1) {
        required = true;
      } else {
        notRequired = true;
      }
    }
  }
  return { required, notRequired };
}
