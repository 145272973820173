import styled from 'styled-components';

// style
import { Row } from '../../styles/align';
import { colors } from '../../styles/variables';

/**
 * Formata o Loader em um bloco
 *
 * @extends {Row}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {colors}
 *
 * Recebe Parametros para definit o padding
 * @param {props.padding} "padding || 4rem 0"
 */
export const LoaderStyle = styled(Row)`
  display: flex;
  max-height: 400px;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  padding: ${props => (props.padding ? props.padding : '4rem 0')};

  &.no-space {
    padding: 0;
    margin: 0;

    .loader {
      padding: 0;
      margin: 0;
    }
  }

  .loader {
    height: fit-content;
    display: flex;
    justify-content: center;
    position: relative;
    margin: 2rem 0;

    &:after {
      position: absolute;
      content: 'Carregando';
      bottom: -1.2rem;
      color: ${colors.primary};
      font-weight: 600;
    }
  }
`;

/**
 * Formata em Botoes e Selects do Form
 *
 * @extends {span} Tag span Nativa do HTML
 */
export const ContainerLoadingButton = styled.span`
  margin-left: 0.5rem;
`;
