import styled from 'styled-components';

// Style
import { colors } from '../../styles/variables';

/**
 * Formata o Container do Multiplicador
 *
 * @extends {div} Tag div Nativa do HTML
 */
export const ContainerMultiply = styled.div`
  display: flex;
  flex-direction: row;

  .__react_component_tooltip {
    max-width: 250px;
  }
`;

/**
 * Formata o Bloco de Mensagens de Alerta [Tooltip]
 *
 * @extends {span} Tag span Nativa do HTML
 *
 * Herda estilo para formatar o interior do Bloco
 * @extends {colors}
 */
export const Message = styled.span`
  font-size: 0.775rem;
  text-transform: initial;
  max-width: 170px;
  color: ${colors.grey.darkPrimary};
  display: flex;
  align-items: center;

  p {
    svg {
      fill: red;
    }
  }
`;
