/**
 * Seo da Página de Pagamento
 * @type {Object}
 */

export const seo = {
  title: 'Pagamento | Impressão Sob Demanda | Fábrica do Livro',
  meta: [
    {
      name: 'description',
      content:
        'Saiba mais sobre as Formas de Pagamento disponíveis na Fábrica do Livro, a gráfica online ideal para impressão de livros e muito mais. Acesse Já!',
    },
  ],
};
