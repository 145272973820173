import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, Form, reduxForm, SubmissionError } from 'redux-form';
import { toast } from 'react-toastify';

// utils
import { SIGN_UP_SUCCESS } from '../../../utils/constMessage';

// styles
import {
  StyledFormWrapper,
  SeparatorForm,
  Label,
  BtnGroup,
  Button,
  HeaderForm,
  H5,
} from '../Home/style';
import { Wrapper, Label as LabelForm } from '../../../styles/forms';
import { TextLabel } from './style';

import Loader from '../../../components/Loader';

// Validatef
import renderField from '../../../formValidate/renderField';
import { required, lengthInput } from '../../../formValidate';

//options
import { LANDING_PAGE } from '../option';

// actions
import { landingRegisterComplete } from '../../../actions/landingPage';

let FormRegistration = props => {
  const { handleSubmit, submitting, landingRegister } = props;

  if (submitting) {
    return <Loader />;
  }

  const renderProd = () => {
    return (
      <>
        <option key={1} value="Produção Própria">
          Produção Própria
        </option>
        <option key={2} value="Terceirizada">
          Terceirizada
        </option>
        <option key={3} value="Parcialmente terceirizada">
          Parcialmente terceirizada
        </option>
      </>
    );
  };

  const renderEcommerce = () => {
    return (
      <>
        <option key={1} value="E-commerce">
          E-commerce
        </option>
        <option key={2} value="Prospecção">
          Prospecção
        </option>
      </>
    );
  };

  const renderAlcance = () => {
    return (
      <>
        <option key={1} value="Apenas regional">
          Apenas regional
        </option>
        <option key={2} value="Nacional">
          Nacional
        </option>
      </>
    );
  };

  const renderIndica = () => {
    return (
      <>
        <option key={1} value="1">
          Sim
        </option>
        <option key={2} value="0">
          Não
        </option>
      </>
    );
  };

  const submit = async formValues => {
    if (
      !formValues.producao_landing &&
      !formValues.entrega_landing &&
      !formValues.menor_custo_landing &&
      !formValues.impressao_landing &&
      !formValues.design_landing
    ) {
      toast.warn('Selecione Uma Opção de sua Editora');
      throw new SubmissionError({
        _error: 'Selecione Uma Opção de sua Editora',
      });
    }
    if (
      formValues.alcance_landing === '0' ||
      formValues.ecommerce_landing === '0' ||
      formValues.indica_landing === '0' ||
      formValues.producao_func_landing === '0'
    ) {
      toast.warn('Selecione Uma Opção de sua Editora');
      throw new SubmissionError({
        _error: 'Selecione Uma Opção de sua Editora',
      });
    }

    return props
      .landingRegisterComplete(
        landingRegister.pk_usuario,
        landingRegister.pk_usuario_editora,
        formValues.cargo_landing,
        formValues.producao_landing,
        formValues.entrega_landing,
        formValues.menor_custo_landing,
        formValues.impressao_landing,
        formValues.design_landing,
        formValues.producao_func_landing,
        formValues.ecommerce_landing,
        formValues.alcance_landing,
        formValues.indica_landing
      )
      .then(resp => {
        if (resp.status === 200) {
          toast.success(SIGN_UP_SUCCESS);
          props.history.push(LANDING_PAGE.ten);
        } else {
          toast.error(resp);
        }
      });
  };

  return (
    <StyledFormWrapper>
      <HeaderForm />
      <Form onSubmit={handleSubmit(submit)}>
        <Wrapper>
          <Label htmlFor={'cargo_landing'}>Qual seu Cargo *</Label>
          <Field
            name={'cargo_landing'}
            type={'text'}
            id={'cargo_landing'}
            component={renderField}
            placeholder={'Qual seu Cargo'}
            validate={[required, lengthInput]}
            maxLength={60}
          />
        </Wrapper>
        <SeparatorForm />
        <TextLabel>
          <p>
            Qual dessas soluções mais somaria no crescimento da sua editora?
          </p>
          <p>Se for necessário, marque mais de uma opção *</p>
        </TextLabel>
        <Wrapper>
          <Field
            name={'producao_landing'}
            id={'producao_landing'}
            type={'checkbox'}
            typefield={'checkbox'}
            component={renderField}
            label={''}
          />
          <LabelForm htmlFor={'producao_landing'}>
            Produção com Maquinário de Última Geração
          </LabelForm>
        </Wrapper>
        <Wrapper>
          <Field
            name={'entrega_landing'}
            id={'entrega_landing'}
            type={'checkbox'}
            typefield={'checkbox'}
            component={renderField}
            label={''}
          />
          <LabelForm htmlFor={'entrega_landing'}>Entrega rápida</LabelForm>
        </Wrapper>
        <Wrapper>
          <Field
            name={'menor_custo_landing'}
            id={'menor_custo_landing'}
            type={'checkbox'}
            typefield={'checkbox'}
            component={renderField}
            label={''}
          />
          <LabelForm htmlFor={'menor_custo_landing'}>
            Menor custo de impressão
          </LabelForm>
        </Wrapper>
        <Wrapper>
          <Field
            name={'impressao_landing'}
            id={'impressao_landing'}
            type={'checkbox'}
            typefield={'checkbox'}
            component={renderField}
            label={''}
          />
          <LabelForm htmlFor={'impressao_landing'}>
            Impressão sob demanda (pequenas quantidades)
          </LabelForm>
        </Wrapper>
        <Wrapper>
          <Field
            name={'design_landing'}
            id={'design_landing'}
            type={'checkbox'}
            typefield={'checkbox'}
            component={renderField}
            label={''}
          />
          <LabelForm htmlFor={'design_landing'}>
            Design, diagramação e revisão
          </LabelForm>
        </Wrapper>
        <SeparatorForm />
        <Wrapper>
          <Label htmlFor={'producao_func_landing'}>
            Como funciona sua produção? *
          </Label>
          <Field
            name="producao_func_landing"
            id={'producao_func_landing'}
            typefield={'select'}
            component={renderField}
            validate={required}
            firstOption={
              <option key={0} value="0">
                Selecione uma opção
              </option>
            }
            options={renderProd()}
          />
        </Wrapper>
        <SeparatorForm />
        <Wrapper>
          <Label htmlFor={'ecommerce_landing'}>
            Sua editora trabalha por e-commerce e indicações ou faz prospecções?
            *
          </Label>
          <Field
            name="ecommerce_landing"
            id={'ecommerce_landing'}
            typefield={'select'}
            component={renderField}
            validate={required}
            firstOption={
              <option key={0} value="0">
                Selecione uma opção
              </option>
            }
            options={renderEcommerce()}
          />
        </Wrapper>
        <SeparatorForm />
        <Wrapper>
          <Label htmlFor={'alcance_landing'}>
            Qual seu alcance logístico? Distribuição regional ou nacional? *
          </Label>
          <Field
            name="alcance_landing"
            id={'alcance_landing'}
            typefield={'select'}
            component={renderField}
            validate={required}
            firstOption={
              <option key={0} value="0">
                Selecione uma opção
              </option>
            }
            options={renderAlcance()}
          />
        </Wrapper>
        <SeparatorForm />
        <Wrapper>
          <Label htmlFor={'indica_landing'}>
            Você indicaria a Fábrica do Livro para ganhar comissão ou cashback?
            *
          </Label>
          <Field
            name="indica_landing"
            id={'indica_landing'}
            typefield={'select'}
            component={renderField}
            validate={required}
            firstOption={
              <option key={0} value="0">
                Selecione uma opção
              </option>
            }
            options={renderIndica()}
          />
        </Wrapper>
        <SeparatorForm />
        <BtnGroup>
          <Button type={'submit'} disabled={submitting}>
            Receber Cupom 10%{submitting ? <Loader /> : ''}
          </Button>
        </BtnGroup>
      </Form>
      <H5>
        Campos marcados com asterisco (*) são de preenchimento obrigatório.
      </H5>
    </StyledFormWrapper>
  );
};

const mapStateToProps = store => {
  const initialValues = {
    producao_landing: false,
    entrega_landing: false,
    menor_custo_landing: false,
    impressao_landing: false,
    design_landing: false,
  };

  return {
    landingRegister: store.landingPage.landingRegister,
    initialValues,
  };
};

FormRegistration = reduxForm({
  form: 'FormRegistration',
})(FormRegistration);

FormRegistration = connect(mapStateToProps, { landingRegisterComplete })(
  FormRegistration
);

export default withRouter(FormRegistration);
