import styled from 'styled-components';

// Styles
import { Wrapper, Label } from '../../../../styles/forms';
import { colors } from '../../../../styles/variables';

/**
 * Formata os Wrappers de Acabamento do Livro
 *
 * @extends {Wrapper}
 *
 * Herda estilo para formatar o interior do Wrapper
 * @extends {Label}
 * @extends {colors}
 */

export const BookFinishe = styled(Wrapper)`
  border: 1px solid ${colors.grey.darkPrimary};
  max-width: 48%;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;

  ${Label} {
    display: flex;
    justify-content: space-between;

    span {

      &:last-of-type {
        font-weight: 700;
        color: ${colors.secondary};
      }
    }
  }
`;
