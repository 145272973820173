/**
 * Array com as opções do filtro de Créditos
 * @type {Array}
 */
export const periodo = [
  {
    days: 15,
    text: 'Últimos 15 dias',
  },
  {
    days: 30,
    text: 'Últimos 30 dias',
  },
  {
    days: 60,
    text: 'Últimos 60 dias',
  },
];
