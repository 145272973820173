import loja from '../apis/loja';

import { toast } from 'react-toastify';
import {
  CLEAR_ORDER,
  FETCH_FILTER_ORDERS,
  FETCH_ORDER,
  FETCH_ORDERS,
  PROTOCOL_TIME_ORDER,
} from './types';

//utils
import * as constMessage from '../utils/constMessage';

/**
 * Busca os dados pra montar o filtro dos pedidos
 * (status, formas de pagamento...)
 */
export const fetchFilterOrders = () => async dispatch => {
  try {
    const response = await loja.get('/filtro-meus-pedidos');

    dispatch({ type: FETCH_FILTER_ORDERS, payload: response.data });
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

/**
 * COM O CAMPO DATA VAZIO
 * Busca todos os pedidos do usuário logado
 * COM O CAMPO DATA POPULADO
 * Busca todos os pedidos, de acordo com os filtros, do usuário logado
 * @param {object} data
 */
export const fetchOrders = data => async dispatch => {
  try {
    const response = await loja.get('/meus-pedidos', {
      params: data,
    });

    dispatch({ type: FETCH_ORDERS, payload: response.data });
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

/**
 * Busca os dados do pedido
 * @param {integer} pk_pedido
 */
export const fetchOrder = pk_pedido => async dispatch => {
  try {
    const response = await loja.get(`/pedido/${pk_pedido}`);

    dispatch({ type: FETCH_ORDER, payload: response.data });
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Reseta os dados do pedido no store
 */
export const clearOrder = () => {
  return {
    type: CLEAR_ORDER,
  };
};

/**
 * Recebe protocolo e data e horário do pedido (order)
 */
export const protocolTimeOrder = (dateTime, protocol) => {
  return {
    type: PROTOCOL_TIME_ORDER,
    payload: {
      dateTime: dateTime,
      protocol: protocol,
    },
  };
};

/**
 * Cria um caso de solicitação de PIX
 * @param {integer} pkPedido
 */
export const retrieveOrderPaymentOk = async pkPedido => {
  try {
    return await loja.get(`/pedido/recupera-pedido-pagamento-ok/${pkPedido}`);
  } catch (error) {
    return {
      error:
        error.response.data.message || 'Ocorreu um erro ao consultar o pedido.',
    };
  }
};

/**
 * Confirmar pagamento PIX
 * @param {integer} pkPedido
 */
export const confirmPaymentPix = async pkPedido => {
  try {
    return await loja.get(`/pedido/confirmacao-compra/pedido/${pkPedido}`);
  } catch (error) {
    return {
      error:
        error.response.data.message || 'Ocorreu um erro ao consultar o pedido.',
    };
  }
};

export const saveObservations = (pk_obs_pedido, obs_pedido) => {
  return async () => {
    try {
      const response = await loja.post('/pedido/alterar-obs-nota-fiscal', {
        pk_obs_pedido,
        obs_pedido,
      });

      if (response.status === 200) {
        toast.success(constMessage.ORDER_OBSNF_SUCCESS);
      } else {
        toast.error(constMessage.ORDER_OBSNF_ERROR);
      }

      return response;
    } catch (error) {
      toast.error(error.response.data.message || 'Erro ao salvar observações:');
      throw error;
    }
  };
};
