import loja from '../apis/loja';
import { dateTransform, onlyNumbers, removePlusFromString } from '../utils';
import storage from '../utils/Storage';
import {
  TOKEN_KEY,
  DATA_USER,
  DATA_PRODUCT,
  arrayClearLogout,
  OPERATOR_SIM,
  CLIENT_SIM,
  COOKIE,
} from '../utils/Storage/types';
import { privateRoutes } from '../routes/config';
import 'lodash';

// verifica se o usuário está autenticado
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

// recupera o token da sessão
export const getToken = () => localStorage.getItem(TOKEN_KEY);

// insere o token na sessão
export const setToken = token => localStorage.setItem(TOKEN_KEY, token);

// insere o cookie na sessão
export const setCookie = cookie => localStorage.setItem(COOKIE, cookie);

// verifica se tem cookie
export const checkStorage = () => {
  const cookie = localStorage.getItem(COOKIE);
  if (cookie) {
    return true;
  }
};

// Captura os dados do usuario no localStorage
export const getDataUser = () => storage.get(DATA_USER);

// Insere os dados do usuario no localStorage
export const setDataUser = data => storage.set(DATA_USER, data);

// recupera o pk do usuario
export const getPkUser = () => {
  const user = getDataUser();
  if (user) {
    return user.pk_usuario;
  }
  return '';
};

// Login do usuario no backend
export const login = async (login, senha) => {
  try {
    const response = await loja.post('/login', {
      login,
      senha,
    });

    setToken(response.data.data.access_token);
    storage.set(DATA_USER, response.data.data.user_data);

    const dateTransformed = dateTransform(
      response.data.data.user_data.dta_nasc_usuario
    );

    storage.update(DATA_USER, { dta_nasc_usuario: dateTransformed });
    return response;
  } catch (error) {
    if (error.response) {
      return { errors: error.response.data };
    }
    return { error: 'CONNECTION_ERROR' };
  }
};

export const logout = () => {
  const actualRoute = window.location.href;
  // remove as chaves do storage de acordo
  // com a configuração do arrayClearLogout
  arrayClearLogout.forEach(key => {
    localStorage.removeItem(key);
  });

  // verifica se a rota atual é uma rota
  // privada para redirecionar para a home
  privateRoutes.forEach(route => {
    if (actualRoute.includes(route)) {
      window.location.href = '/';
    }
  });

  // remove a referencia de reenvio
  if (storage.has(DATA_PRODUCT)) {
    storage.update(DATA_PRODUCT, { referencia: false });
  }
};

/**
 * verifica o cpf e a data de nascimento na receita federal e busca o nomo
 */
export const checkCpfCnpj = async (
  cpf,
  data = '',
  fk_tipo_usuario,
  landingPage = false
) => {
  const cpf_cnpj_usuario = onlyNumbers(cpf);
  let params = { fk_tipo_usuario, cpf_cnpj_usuario, landingPage };
  if (data) {
    const dta_nasc_usuario = data.replace(/\//g, '-');
    params = { ...params, dta_nasc_usuario };
  }
  try {
    const response = await loja.get('/usuario/validar-cpf-cnpj', {
      params,
      headers: { 'Content-Type': 'application/json' },
    });
    return response;
  } catch (error) {
    if (error.response) {
      return { errors: error.response.data };
    }
    return { error: 'CONNECTION_ERROR' };
  }
};

/**
 * Ação para cadastrar usuário
 */
export const createUser = async data => {
  // setup
  let dataSubmit = { ...data };
  if (data.nr_fone) {
    const aPhone = data.nr_fone.split(' ');
    dataSubmit.ddd_fone = aPhone[0].replace(/[^\d]+/g, '');
    dataSubmit.nr_fone = aPhone[1].replace(/[^\d]+/g, '');
    if (!data.nr_celular){
    dataSubmit.ddd_celular = aPhone[0].replace(/[^\d]+/g, '');
    dataSubmit.nr_celular = aPhone[1].replace(/[^\d]+/g, '');
    }
  } else {
    const aPhone = data.nr_celular.split(' ');
    dataSubmit.ddd_celular = aPhone[0].replace(/[^\d]+/g, '');
    dataSubmit.nr_celular = aPhone[1].replace(/[^\d]+/g, '');

    dataSubmit.ddd_fone = aPhone[0].replace(/[^\d]+/g, '');
    dataSubmit.nr_fone = aPhone[1].replace(/[^\d]+/g, '').slice(1);
  }
  const aCelPhone = data.nr_celular.split(' ');
  dataSubmit.ddd_celular = aCelPhone[0].replace(/[^\d]+/g, '');
  dataSubmit.nr_celular = aCelPhone[1].replace(/[^\d]+/g, '');
  dataSubmit.cpf_cnpj_usuario = data.cpf_cnpj_usuario.replace(/\D+/g, '');
  dataSubmit.dta_nasc_usuario = dateTransform(data.dta_nasc_usuario);

  try {
    const response = await loja.post('/usuario/salvar', dataSubmit);
    return response;
  } catch (error) {
    if (error.response) {
      return { errors: error.response.data };
    }
    return { error: 'CONNECTION_ERROR' };
  }
};

/**
 * Ação para alterar usuário
 */
export const updateUser = async (data, landingPage = false) => {
  // setup
  let dataSubmit = { ...data };
  if (data.nr_fone) {
    const aPhone = data.nr_fone.split(' ');
    dataSubmit.ddd_fone = aPhone[0].replace(/[^\d]+/g, '');
    dataSubmit.nr_fone = aPhone[1].replace(/[^\d]+/g, '');
    if (!data.nr_celular){
      dataSubmit.ddd_celular = aPhone[0].replace(/[^\d]+/g, '');
      dataSubmit.nr_celular = aPhone[1].replace(/[^\d]+/g, '');
    }
  } else {
    const aPhone = data.nr_celular.split(' ');
    dataSubmit.ddd_celular = aPhone[0].replace(/[^\d]+/g, '');
    dataSubmit.nr_celular = aPhone[1].replace(/[^\d]+/g, '');

    dataSubmit.ddd_fone = aPhone[0].replace(/[^\d]+/g, '');
    dataSubmit.nr_fone = aPhone[1].replace(/[^\d]+/g, '').slice(1);
  }
  const aCelPhone = data.nr_celular.split(' ');
  dataSubmit.ddd_celular = aCelPhone[0].replace(/[^\d]+/g, '');
  dataSubmit.nr_celular = aCelPhone[1].replace(/[^\d]+/g, '');
  dataSubmit.cpf_cnpj_usuario = data.cpf_cnpj_usuario.replace(/\D+/g, '');
  dataSubmit.dta_nasc_usuario = dateTransform(data.dta_nasc_usuario);
  dataSubmit = {
    ...dataSubmit,
    pk_usuario: storage.getWithKey(DATA_USER, 'pk_usuario'),
  };
  dataSubmit.landingPage = landingPage;

  try {
    const response = await loja.put('/usuario/salvar', dataSubmit);
    return response;
  } catch (error) {
    if (error.response) {
      return { errors: error.response.data };
    }
    return { error: 'CONNECTION_ERROR' };
  }
};

/**
  Simula o cliente
 * @param {*} token
 * @param {*} usuario
 * @param {*} operador
 */
export const simulateLogin = async ({ token, usuario, operador }) => {
  setToken(token);
  storage.set(CLIENT_SIM, removePlusFromString(usuario).toUpperCase());
  storage.set(OPERATOR_SIM, removePlusFromString(operador).toUpperCase());
  return;
};

/**
 * Busca as informações da simulação de login
 */
export const getSimulate = () => {
  const result = {};
  result.client = storage.get(CLIENT_SIM) || '';
  result.operator = storage.get(OPERATOR_SIM) || '';

  if (result.client && result.operator) {
    localStorage.removeItem(DATA_USER);
  }

  return result;
};
