import styled from 'styled-components';

/**
 * Formata a Logo
 *
 * @extends {img} Tag img Nativa do HTML
 */

export const logo = styled.div`
  max-width: 190px;

  @media (max-width: 768px) {
    margin: 0;
  }
`;
