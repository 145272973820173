import styled from 'styled-components';

// Styles
import { colors, fontSize } from '../../../styles/variables';
import { Row, Col } from '../../../styles/align';
import { Wrapper, BtnGroup, Button } from '../../../styles/forms';

/**
 * Formata o bloco de orçamento
 * @extends {Col}
 *
 * Herda estilo para formatar o interior do bloco
 * @extends {Col}
 * @extends {Row}
 * @extends {Wrapper}
 * @extends {BtnGroup}
 * @extends {Button}
 * @extends {fontSize}
 * @extends {colors}
 */
export const FormBudget = styled(Col)`
  padding: 2rem;

  form {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    h3 {
      font-size: ${fontSize.md}
      color: ${colors.secondary}
      text-align: center;
      margin-bottom: 2rem;
    }
    
    ${Col} {
      width: 70%;
      align-items: center;

      @media (max-width: 768px) {
				width: 100%;;
			}


      ${Row} {
        ${Wrapper} {
          width: 100%;
          margin: .5rem;

          @media (max-width: 768px) {
            margin: 0 0 .5rem 0;
          }
        }
      }

      & > ${Wrapper} {
        width: fit-content;
        margin: .5rem 0;
      }

      ${BtnGroup} {
        ${Button} {
          background-color: ${colors.primary};
          text-decoration: none;
          color: ${colors.default}
        }
      }
    }
  }
`;
