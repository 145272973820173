import {
  LANDING_REGISTER,
  LANDING_REGISTER_COMPLETE,
  LANDING_NOT_LIKE,
} from '../actions/types';

export default (state = {}, action) => {
  switch (action.type) {
    case LANDING_REGISTER:
      return {
        ...state,
        landingRegister: action.payload.data,
      };
    case LANDING_REGISTER_COMPLETE:
      return {
        ...state,
        landingRegisterComplete: action.payload.data,
      };
    case LANDING_NOT_LIKE:
      return {
        ...state,
        landingNotLike: action.payload.data,
      };
    default:
      return state;
  }
};
