import React from "react";
import { Field } from "redux-form";

// Validate
import renderField from "../../../formValidate/renderField";
import { required, email } from "../../../formValidate";

// Styles
import { Row, Col } from "../../../styles/align";
import { TitleCards } from "../../../styles/titles";
import { Wrapper, Label } from "../../../styles/forms";

const RenderDataLogin = ({
  onVerifyEmail,
  onVerifyPassword,
  errors,
  onHandleClearErrors
}) => {
  return (
    <Col>
      <div>
        <TitleCards>
          <span>{"Dados De Login"}</span>
        </TitleCards>
      </div>
      <Row>
        <Wrapper>
          <Label htmlFor={"email_usuario"}>
            E-mail<strong>*</strong>
          </Label>
          <Field
            name={"email_usuario"}
            id={"email_usuario"}
            type={"text"}
            component={renderField}
            placeholder={"nome@email.com.br"}
            validate={[required, email]}
            responseError={errors.email_usuario}
            onFocus={e => onHandleClearErrors(e.target.name)}
          />
        </Wrapper>
        <Wrapper>
          <Label htmlFor={"email_usuario_confirmation"}>
            Confirme seu E-mail<strong>*</strong>
          </Label>
          <Field
            name={"email_usuario_confirmation"}
            id={"email_usuario_confirmation"}
            type={"text"}
            component={renderField}
            placeholder={"nome@email.com.br"}
            validate={[required, email, onVerifyEmail]}
          />
        </Wrapper>
      </Row>
      <Row>
        <Wrapper>
          <Label htmlFor={"senha_usuario"}>
            Senha<strong>*</strong>
          </Label>
          <Field
            name={"senha_usuario"}
            id={"senha_usuario"}
            type={"password"}
            component={renderField}
            placeholder={"*******"}
            validate={required}
            responseError={errors.senha_usuario}
            onFocus={e => onHandleClearErrors(e.target.name)}
          />
        </Wrapper>
        <Wrapper>
          <Label htmlFor={"senha_usuario_confirmation"}>
            Confirme sua Senha<strong>*</strong>
          </Label>
          <Field
            name={"senha_usuario_confirmation"}
            id={"senha_usuario_confirmation"}
            type={"password"}
            component={renderField}
            label={"Confirme sua Senha"}
            placeholder={"*******"}
            validate={[required, onVerifyPassword]}
          />
        </Wrapper>
      </Row>
    </Col>
  );
};

export default RenderDataLogin;
