import React from 'react';
import { Field } from 'redux-form';

// hocs
import CardFilter from '../../../../hocs/CardFilter';

// Validate
import renderField from '../../../../formValidate/renderField';

// Styles
import { Row, Col, SeparatorH } from '../../../../styles/align';
import { Wrapper, Label } from '../../../../styles/forms';
import { TitleCards } from '../../../../styles/titles';
import { BookSizeStyle, Figure } from './style';

const BookSize = ({ data, onChangeSubmit }) => {
  const { dados_produto, tamanho, orientacao } = data;
  const hasOrientationOptions = orientacao && orientacao.length > 1
  
  return (
    // Props para ajustar estilo se não tiver opção de orientação
    <BookSizeStyle hasOrientationOptions={hasOrientationOptions}>
      <Row>
        <Col>
          <Figure>
            <img
              alt={dados_produto.nome_menu_imagem}
              src={`${dados_produto.url_base}${dados_produto.nome_menu_imagem}`}
              title={dados_produto.nome_menu_imagem}
            />
          </Figure>
        </Col>
        <Col>
          {tamanho.opcoes.map((item, i) => {
            return (
              <Wrapper key={i}>
                <Field
                  name={'tamanho'}
                  id={`tamanho[${i}]`}
                  type={'radio'}
                  typefield={'radio'}
                  onChange={(event, newValue, previousValue, name) =>
                    onChangeSubmit(event, newValue, previousValue, name)
                  }
                  component={renderField}
                  value={`${item.pk_dependencia_atributo}`}
                />
                <Label htmlFor={`tamanho[${i}]`}>
                  {item.texto_dependencia_atributo}
                </Label>
              </Wrapper>
            );
          })}
        </Col>
      </Row>
      {hasOrientationOptions ? (
        <>
          <SeparatorH />
          <TitleCards>Orientação</TitleCards>
          <Row>
            {orientacao.map((item, i) => {
              return (
                <Wrapper key={i}>
                  <Field
                    name={'orientacao'}
                    id={`orientacao_${i}`}
                    type={'radio'}
                    typefield={'radio'}
                    component={renderField}
                    onChange={(event, newValue, previousValue, name) =>
                      onChangeSubmit(event, newValue, previousValue, name)
                    }
                    value={`${item.pk_dependencia_atributo}`}
                  />
                  <Label htmlFor={`orientacao_${i}`}>
                    {item.texto_dependencia_atributo}
                  </Label>
                </Wrapper>
              );
            })}
          </Row>
        </>
      ) : (
        ''
      )}
    </BookSizeStyle>
  );
};

export default CardFilter(BookSize);
