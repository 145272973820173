import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, Form, reduxForm } from 'redux-form';

// Node
import { toast } from 'react-toastify';

// hocs
import Modal from '../../hocs/Modal';

// Components
import LoaderForm from '../../components/Loader/loaderForm';

// actions
import { modalMessage } from '../../actions/modals';
import { fetchMessages } from '../../actions/messages';

// services
import { createMessage, formatSubmit } from '../../services/message';

// Validate
import renderField from '../../formValidate/renderField';
import { mapErrors } from '../../formValidate/handleErrors';
import { required } from '../../formValidate';

// utils
import {
  MESSAGE_SUCCESS,
  CONNECTION_ERROR,
  ERROR,
} from '../../utils/constMessage';

// Style
import { Row, Col } from '../../styles/align';
import { Wrapper, BtnGroup, Button, Label } from '../../styles/forms';
import { MessageContainer } from './style';

class Message extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {
        titulo: '',
        mensagem: '',
      },
    };
  }

  handleErrors = response => {
    if (response.errosValidacao) {
      const errors = mapErrors(response.errosValidacao);
      this.setState({ errors });
    }

    if (response.message) {
      toast.warn(response.message);
    } else {
      toast.error(ERROR);
    }
  };

  submit = async data => {
    createMessage(data).then(response => {
      if (response.status === 200) {
        this.props.modalMessage();
        toast.success(MESSAGE_SUCCESS);
        let dataSubmit = formatSubmit(this.props.filterMessages.values);
        this.props.fetchMessages(dataSubmit);
      } else if (response.errors) {
        this.handleErrors(response.errors);
      } else {
        toast.error(CONNECTION_ERROR);
      }
    });
  };

  render() {
    const { handleSubmit, pristine, submitting } = this.props;

    return (
      <MessageContainer>
        <Form onSubmit={handleSubmit(this.submit)}>
          <Row margin={'0 0 1rem 0'}>
            <Col>
              <Wrapper>
                <Label>
                  Assunto <strong>*</strong>
                </Label>
                <Field
                  name={'titulo'}
                  placeholder={'Assunto'}
                  component={renderField}
                  validate={required}
                  responseError={this.state.errors.titulo}
                />
              </Wrapper>
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Wrapper>
              <Label>
                Descrição <strong>*</strong>
              </Label>
              <Field
                name={'mensagem'}
                typefield={'textarea'}
                heightContent={'10rem'}
                component={renderField}
                validate={required}
                responseError={this.state.errors.mensagem}
              />
            </Wrapper>
          </Row>
          <BtnGroup row>
            <span className={'required'}>* Campos Obrigatórios</span>
            <div>
              <Button primary onClick={this.props.modalMessage}>
                Cancelar
              </Button>
              <Button secondary disabled={pristine || submitting}>
                Enviar{submitting ? <LoaderForm /> : ''}
              </Button>
            </div>
          </BtnGroup>
        </Form>
      </MessageContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    filterMessages: state.form.filterMessage,
  };
};

Message = reduxForm({
  form: 'formModalMessage',
})(Message);

Message = connect(mapStateToProps, { modalMessage, fetchMessages })(Message);

Message = Modal(Message);

export default Message;
