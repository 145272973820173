import styled from 'styled-components';

// Style
import { Wrapper, Label, BtnGroup } from '../../styles/forms';
import { colors } from '../../styles/variables';

/**
 * Formata o container da Pagina de Pedidos
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do container
 * @extends {Wrapper}
 * @extends {Label}
 * @extends {BtnGroup}
 * @extends {colors}
 *
 */
export const StyleOrders = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: ${colors.grey.light};
  padding: 1rem;
  margin: 1rem 0;

  ${Wrapper} {
    padding: 0 1rem 1rem 1rem;

    ${Label} {
      padding: 0;
      color: ${colors.primary};
    }
  }

  ${BtnGroup} {
    flex-direction: row-reverse;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
