import styled from 'styled-components';

// Variables
import { Button } from './forms';
import { colors, fontSize } from './variables';

export const TitlePage = styled.h1`
  text-align: left;
  display: flex;
  align-items: flex-end;
  margin: 1rem 0;

  span {
    font-size: ${fontSize.xl};
    color: ${props => (props.internal ? colors.secondary : colors.primary)};
    font-weight: ${props => (props.internal ? '600' : '900')};
    min-width: fit-content;
    font-style: ${props => (props.internal ? 'normal' : 'italic')};
  }

  &:after {
    content: '';
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed
      ${props => (props.internal ? colors.secondary : colors.primary)};
  }
`;

export const TitleCategory = styled.h2`
  margin: 3rem 0;
  display: flex;
  align-items: center;

  span {
    background-color: ${colors.primary};
    color: ${colors.default};
    min-width: 250px;
    width: fit-content;
    padding: 0.5rem 1rem;
    border-radius: 50px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: ${fontSize.md};
    font-style: italic;
    text-align: center;
    
    // estilos de natal:
    display: flex;
    justify-content: center; 
    position: relative;

   img {
      width: 100px;
      position: absolute;
      top: -50px;
      left: 50%;
      transform: translateX(-50%);
    }
  
  }

  &::before {
    content: '';
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed ${colors.primary};
  }

  &::after {
    content: '';
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed ${colors.primary};
  }

  
`;

export const TitleCards = styled.h2`
  color: ${colors.secondary};
  font-weight: bold;
  font-size: ${fontSize.md};
  text-transform: uppercase;
  padding: ${props => (props.order ? '0' : '0.5rem 1rem')};
  margin-bottom: 0.5rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;

    & > span {
      margin: 1rem 0;
    }
  }

  ${TitlePage} {
    width: 100%;
  }

  & > span {
    min-width: fit-content;
    font-size: ${fontSize.sm};
    color: ${colors.grey.dark};

    span {
      font-size: ${fontSize.md};
      font-weight: 700;
    }

    ${Button} {
      display: flex;
      align-items: center;
      max-width: max-content;
      text-decoration: none;
      font-size: ${fontSize.sm};
      color: ${colors.secondary};
      font-weight: 700;
      text-transform: capitalize;

      svg {
        fill: ${colors.secondary};
      }
    }
  }
`;
