import React, { Component } from "react";
import { connect } from "react-redux";

// Actions
import { modalResendFiles } from "../../actions/modals";

// Styles
import { Row } from "../../styles/align";
import { Loading } from "../style";
import { StyleResendFiles, StyleImage, H2 } from "./style";

// Hocs
import Modal from "../../hocs/Modal";
import Loader from "../../components/Loader";

import ImageDemonstracao from '../../assets/images/ex-reenvio-arquivos.png'

class ResendFiles extends Component {
  state = {
    loading: false
  };

  render() {
    return (
      <StyleResendFiles>
        {this.state.loading ? (
          <Loading>
            <Loader />
          </Loading>
        ) : (
          ""
        )}
        <p><H2>AVISO!</H2></p>
        <Row>
        <p>Para repetir a compra do pedido anterior, é necessário reenviar seus arquivos no item que estiver com a marcação azul no carrinho!</p>
        </Row>
        <StyleImage src={ImageDemonstracao} />
      </StyleResendFiles>
    );
  }
}

const mapStateToProps = state => {
  return {
    callback: state.modals.modalResendFiles.callback,
    text: state.modals.modalResendFiles.text
  };
};

ResendFiles = connect(mapStateToProps, { modalResendFiles })(ResendFiles);

ResendFiles = Modal(ResendFiles);

export default ResendFiles;
