import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, Form, reduxForm } from 'redux-form';

// components
import LoaderForm from '../../../components/Loader/loaderForm';

// actions
import { fetchOrders, fetchFilterOrders } from '../../../actions/orders';

// validate
import renderField from '../../../formValidate/renderField';
import { maskDate } from '../../../formValidate/masks';
import { date } from '../../../formValidate';

// utils
import { onlyNumbers, dateTransform } from '../../../utils';

// styles
import { Col, Row } from '../../../styles/align';
import {
  StyleForm,
  Wrapper,
  Label,
  BtnGroup,
  Button,
  ButtonPrimary,
} from '../../../styles/forms';

class OrdersFilter extends Component {
  componentDidMount() {
    this.props.fetchFilterOrders();
  }
  renderFormasDePagamento() {
    const filter = this.props.filter;
    if (!filter) {
      return '';
    }

    return (
      <Wrapper>
        <Label htmlFor={'pk_tipo_pagamento'}>Forma de Pagamento</Label>
        <Field
          name="pk_tipo_pagamento"
          id={'pk_tipo_pagamento'}
          typefield={'select'}
          component={renderField}
          options={Object.values(filter.formasPagamento).map(item => {
            return (
              <option
                key={item.pk_tipo_pagamento}
                value={item.pk_tipo_pagamento}
              >
                {item.desc_tipo_pagamento}
              </option>
            );
          })}
        />
      </Wrapper>
    );
  }

  renderStatus() {
    const filter = this.props.filter;
    if (!filter) {
      return '';
    }

    return (
      <Wrapper>
        <Label htmlFor={'pk_status_pedido'}>Status do Pedido</Label>
        <Field
          name="pk_status_pedido"
          id={'pk_status_pedido'}
          typefield={'select'}
          component={renderField}
          options={Object.values(filter.statusPedido).map(item => {
            return (
              <option key={item.pk_status_pedido} value={item.pk_status_pedido}>
                {item.desc_status_pedido}
              </option>
            );
          })}
        />
      </Wrapper>
    );
  }

  renderForm() {
    return (
      <StyleForm>
        <Col>
          <h2>Filtro de Busca</h2>
        </Col>
        <Wrapper>
          <Label htmlFor={'pk_pedido'}>Número do Pedido</Label>
          <Field
            name={'pk_pedido'}
            id={'pk_pedido'}
            type={'text'}
            placeholder={'Ex: 1987'}
            component={renderField}
            normalize={onlyNumbers}
          />
        </Wrapper>
        {this.renderFormasDePagamento()}
        {this.renderStatus()}
        <Col className={'date__picker-container'}>
          <Row className={'date__picker-content'}>
            <Wrapper>
              <Label htmlFor={'data_ini'}>Data</Label>
              <Field
                name="data_ini"
                id={'data_ini'}
                type={'text'}
                typefield={'mask'}
                label={'Data Inicial'}
                formatDate={'dd/MM/yyyy'}
                placeholderText="Data inicial"
                mask={maskDate}
                validate={date}
                component={renderField}
              />
            </Wrapper>
            <p className={'date__picker-content-text'}>Até</p>
            <Wrapper>
              <Field
                name="data_fim"
                type={'text'}
                typefield={'mask'}
                label={'Data Inicial'}
                formatDate={'dd/MM/yyyy'}
                placeholderText="Data Final"
                mask={maskDate}
                validate={date}
                component={renderField}
              />
            </Wrapper>
          </Row>
        </Col>
        <Wrapper>
          <Label>Nome do Pedido</Label>
          <Field
            name={'nome_pedido'}
            id={'nome_pedido'}
            type={'text'}
            label={''}
            component={renderField}
          />
        </Wrapper>
      </StyleForm>
    );
  }

  onSubmit = data => {
    // aguardando a implementação do react-datepicker
    let dataSubmit = { ...data };

    if (dataSubmit.data_ini) {
      dataSubmit.data_ini = dateTransform(dataSubmit.data_ini);
      // dataSubmit.data_ini = moment(dataSubmit.data_ini).format("YYYY/MM/DD");
    }
    if (dataSubmit.data_fim) {
      dataSubmit.data_fim = dateTransform(dataSubmit.data_fim);
      // dataSubmit.data_fim = moment(dataSubmit.data_fim).format("YYYY/MM/DD");
    }
    this.props.fetchOrders(dataSubmit);
  };

  render() {
    const { handleSubmit, reset, submitting } = this.props;

    return (
      <Form onSubmit={handleSubmit(this.onSubmit)}>
        {this.renderForm()}
        <BtnGroup>
          <ButtonPrimary type={'submit'} disabled={submitting}>
            Filtrar{submitting ? <LoaderForm /> : ''}
          </ButtonPrimary>
          <Button onClick={reset}>Limpar Filtro</Button>
        </BtnGroup>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
    filter: state.orders.filterOrders,
  };
};

OrdersFilter = reduxForm({
  form: 'filterOrders',
})(OrdersFilter);

OrdersFilter = connect(mapStateToProps, { fetchOrders, fetchFilterOrders })(
  OrdersFilter
);

export default OrdersFilter;
