import styled from 'styled-components';

export const ContainerButton = styled.button`
  background-color: #25d366;
  border: none;
  color: #fff;
  text-align: center;
  cursor: pointer;
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 1rem;
  right: 1rem;
  border-radius: 50%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s, transform 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out, opacity 0.3s ease-in-out;

  :hover {
    background-color: #34af23;
    transform: translateY(-4px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.15);
  }

  :active {
    opacity: 0.8;
  }

  @media (max-width: 480px) {
    right: 3rem;
    bottom: 3rem;
  }
`;

export const Icon = styled.img`
  width: 3.2rem;
  height: 3.2rem;
`;
