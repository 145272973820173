import React from 'react';

// Forms
import FormBalcony from '../../forms/Balcony';

// Images
import deliveryImage from '../../assets/images/entrega.jpg';

// Helpers
import Seo from '../../helpers/Seo';

// Styles
import { Row, TextAlign } from '../../styles/align';
import {
  DeliveryStyle,
  TextFeature,
  ImageDelivery,
  TextDelivery,
  Filter,
} from './style';

// Options
import { delivery, seo } from './options';

const Delivery = () => {
  const { description, text, options } = delivery;

  return (
    <>
      <Seo props={seo} />
      <DeliveryStyle>
        {/* <TitlePage>
        <span>{title}</span>
      </TitlePage> */}
        <TextFeature>
          <p>{description}</p>
        </TextFeature>
        <TextAlign>{text}</TextAlign>
        <Row>
          <ImageDelivery>
            <img src={deliveryImage} alt={'Entrega'} title={'Entrega'} />
          </ImageDelivery>
          <TextDelivery>
            <ul>
              {options.map((item, i) => {
                return (
                  <li>
                    <p>
                      <strong>{item.title}: </strong>
                      {item.text}
                    </p>
                  </li>
                );
              })}
            </ul>
          </TextDelivery>
        </Row>
        <Filter>
          <FormBalcony />
        </Filter>
      </DeliveryStyle>
    </>
  );
};

export default Delivery;
