import styled from 'styled-components';
import { colors, fontSize } from '../../styles/variables';

import { Col, Row } from '../../styles/align';
import { Wrapper, Label, Radio, BtnGroup, Button } from '../../styles/forms';
import { Container } from '../style';

/**
 * Formata o Bloco de informações do Item
 *
 * @extends {Col}
 *
 * Herda estilo para formatar o interior do Bloco
 * @extends {Row}
 * @extends {Wrapper}
 * @extends {Label}
 * @extends {fontSize}
 * @extends {colors}
 */

export const ItemContent = styled(Col)`
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 400ms ease-in-out;

  ${Row} {
    ${Wrapper} {
      width: 100%;

      padding: 0 1rem;

      ${Label} {
        padding: 0 0 0.5rem 0;
      }
    }

    padding: 1rem 0;
  }

  h3 {
    font-size: ${fontSize.md};
    font-weight: 700;
    color: ${colors.primary};
  }
`;

/**
 * Formata o Container da Modal Contato de Retirada
 *
 * @extends {Container}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Label}
 * @extends {Radio}
 * @extends {ItemContent}
 * @extends {BtnGroup}
 * @extends {Button}
 * @extends {colors}
 */

export const StyleWithdrawal = styled(Container)`
  background-color: ${colors.grey.light};
  padding: 1rem;

  ${Label} {
    strong {
      color: ${colors.secondary};
    }
  }

  form {
    background-color: ${colors.grey.lightPrimary};
    padding: 1rem;
  }

  ${Radio} {
    &:checked {
      & ~ ${ItemContent} {
        max-height: 400px;
        transition: max-height 400ms ease-in-out;

        @media (max-width: 768px) {
          max-height: fit-content;
        }
      }
    }
  }

  ${BtnGroup} {
    padding: 1rem 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    span {
      display: flex;
      flex-direction: row;
      color: ${colors.secondary};

      ${Button} {
        &:last-of-type {
          background-color: ${colors.primary};
          color: ${colors.default};
          text-decoration: none;
        }
      }
    }
  }
`;
