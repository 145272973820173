import React from 'react';

// utils
import { numberFormat } from '../../utils';

const Credit = ({ totalGeral, saldo }) => {
  const saldoFinal = saldo - totalGeral < 0 ? 0 : saldo - totalGeral;
  return (
    <span>
      <p>Saldo disponível: R$ {numberFormat(saldo)}</p>
      <p>Saldo restante após a compra: R$ {numberFormat(saldoFinal)}</p>
    </span>
  );
};

export default Credit;
