import React from 'react';
import { Helmet } from 'react-helmet';

const Seo = ({ props }) => {
  return (
    <Helmet>
      {props.title ? <title>{props.title}</title> : ''}
      {props.meta ? (
        props.meta.map((item, i) => {
          return item.name ? (
            <meta key={i} name={item.name} content={item.content} />
          ) : item.property ? (
            <meta key={i} property={item.property} content={item.content} />
          ) : (
            ''
          );
        })
      ) : (
        <meta
          name={'description'}
          content={
            'Imprimir livros, revistas, catálogos, apostilas, trabalhos acadêmicos sob demanda, com alta qualidade e curto prazo, comodidade e preço baixo!'
          }
        />
      )}
      {props.canonical ? <link href={props.canonical} rel={'canonical'} /> : ''}
    </Helmet>
  );
};

export default Seo;
