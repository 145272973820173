import styled from 'styled-components';

// Variables
import { colors, fontSize } from '../../styles/variables';
import { TitleCategory } from '../../styles/titles';

/**
 * Formata o Container de Produtos
 *
 * @extends {div} Tag div nativa do HTML
 */

export const ProductsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

/**
 * Formata o bloco do Icon no container do Produto
 * Ativado por hover
 *
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do bloco
 * @extends {colors}
 */

export const Icon = styled.div`
  display: none;
  width: 3rem;
  height: 6rem;
  position: absolute;
  right: -1rem;
  bottom: -2rem;
  background-color: ${colors.secondary};
  padding: 2rem;
  transform: rotate(45deg);
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  svg {
    position: absolute;
    width: 1.6rem;
    height: 1.6rem;
    transform: rotate(-45deg);
    fill: ${colors.default};
  }
`;

/**
 * Formata o Container do Produto
 *
 * @extends {article} Tag article nativa do HTML
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Icon}
 * @extends {fontSize}
 * @extends {colors}
 */

export const ProductStyle = styled.article`
  text-align: center;
  width: 20%;
  padding: 2rem;
  border-right: 1px solid ${colors.grey.lightSecondary};
  margin-bottom: 1rem;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 100%;
    border: none;
  }

  &:hover {
    box-shadow: 1px 1px 10px ${colors.grey.lightSecondary};

    div {
      img {
        transition: height 2ms;
        height: 100%;
      }
    }

    ${Icon} {
      display: flex;
    }
  }

  a {
    text-decoration: none;
  }

  &:nth-child(5n) {
    border-right: none;
  }

  &:last-of-type {
    border-right: none;
  }

  header {
    height: 80%;

    div {
      width: auto;
      height: 150px;

      img {
        height: 90%;
      }
    }

    h1 {
      font-size: ${fontSize.md};
      margin: 1rem 0;
      color: ${colors.grey.dark};
      min-height: 2rem;
    }

    p {
      font-size: ${fontSize.sm};
      color: ${colors.grey.darkPrimary};
      line-height: 1.5;
      min-height: 3rem;
    }
  }

  footer {
    font-size: ${fontSize.sm};
    display: block;
    margin: 1rem 0;
    text-transform: uppercase;
    color: ${colors.grey.dark};
    font-weight: bold;
    height: 10%;

    div {
      padding: 0.5rem 0;
    }

    strong {
      font-size: ${fontSize.md};
      color: ${colors.secondary};
    }
  }
`;

/**
 * Formata o Skeleton do Container de produtos
 *
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do Skeleton
 * @extends {TitleCategory}
 * @extends {ProductStyle}
 */

export const Style = styled.div`
  swidth: 100%;

  ${TitleCategory} {
    & > span {
      background: transparent;
      padding: 0;
    }

    &::after {
      border-color: #eee;
    }

    &::before {
      border-color: #eee;
    }
  }

  ${ProductStyle} {
    border-color: #eee;
  }

  header {
    height: auto;
    margin-bottom: 1rem;

    span {
      width: 100%;
    }
  }

  h1 {
    margin-bottom: 1rem;
  }

  p {
    line-height: 1.5;
  }
`;

/**
 * Formata o Bloco Image no Skeleton
 *
 * @extends {div} Tag div nativa do HTML
 */

export const Image = styled.div`
  width: 40px;
  height: 40px;
  display: flex;

  span {
    widht: 100%;
    height: 100%;
  }
`;
