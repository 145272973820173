import styled from 'styled-components';

import { StyleSummary, StylePrice } from '../Summary/style';
import { fontSize, colors } from '../../styles/variables';

/**
 * Formata o Container do Resumo
 * @extends {StyleSummary}
 */

export const Summary = styled(StyleSummary)`
  // max-width: 30%;

  span {
    display: flex;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

/**
 * Formata o bloco do total no Resumo
 * @extends {StylePrice}
 * Herda estilo para formatar o interior do Container
 * @extends {fontSize}
 */
export const Price = styled(StylePrice)`
  span {
    font-size: ${fontSize.lg};
  }
`;

export const Deadline = styled(StyleSummary)`
  padding: 0.5rem 0;

  span {
    font-weight: 700;
    color: ${colors.primary};
    text-transform: none;
  }
`
