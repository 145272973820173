import React from "react";

// Styles
import { TitleCard, CardFilter as StyleCardFilter } from "./style";

const CardFilter = Component => {
  const CardFilter = ({
    title,
    order = null,
    data,
    onChangeSubmit,
    updateFinishBlocked,
    finishesBlocked,
    updateService,
    onChangeValues,
    activeRequest
  }) => (
    <StyleCardFilter>
      {title ? (
        <TitleCard>
          {order ? <span>{order}</span> : ""}
          {title}
        </TitleCard>
      ) : ('')}
      <Component
        data={data}
        onChangeSubmit={onChangeSubmit}
        updateFinishBlocked={updateFinishBlocked}
        finishesBlocked={finishesBlocked}
        updateService={updateService}
        onChangeValues={onChangeValues}
        activeRequest={activeRequest}
      ></Component>
    </StyleCardFilter>
  );

  return CardFilter;
};

export default CardFilter;
