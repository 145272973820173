import styled from 'styled-components';

import { colors } from '../../../styles/variables';

import { Container as ContainerHome } from '../Home/style';

export const Container = styled(ContainerHome)`
  background-position: center;

  @media only screen and (max-width: 768px) {
    display: block;
    background-size: cover;
    height: 16rem;
  }
`;

export const Garanta = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  h1 {
    color: ${colors.secondary};
    font-size: 3rem;
  }

  p {
    color: ${colors.default};
    font-size: 1.25rem;
    line-height: 2rem;
  }

  p:last-child {
    margin-bottom: 2rem;
  }
`;

export const Info = styled.p`
  margin-top: 2rem;
`;

export const TextLabel = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0 1rem 0;

  p:first-child {
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
`;
