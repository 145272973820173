import React from 'react';
import YouTube from 'react-youtube';

// styles
import {
  ColText,
  ColVideo,
  Container,
  Content,
  Editora,
  H1,
  InfoForm,
  Logo as LogoPage,
  RowVideo,
  StyleLogo,
  Title,
  Together,
} from './style';

// images
import ImageLogo from '../../../assets/images/logo.png';

const Header = () => {
  const renderYouTube = () => {
    let opts = {
      height: '220',
      width: '420',
      enablejsapi: 1,
      playerVars: {
        autoplay: 1,
      },
    };
    return <YouTube videoId="1k3uXRKcjBY" opts={opts} onReady={() => {}} />;
  };

  return (
    <>
      <Container>
        <LogoPage>
          <StyleLogo
            src={ImageLogo}
            alt={'Fábrica do Livro'}
            title={'Fábrica do Livro'}
          />
        </LogoPage>
        <Content>
          <ColText>
            <Title>
              <H1>Fábrica do</H1>
              <H1>Livro</H1>
              <Editora> + Sua Editora</Editora>
              <Together>
                <p>Vamos escrever juntos</p>
                <p>uma história de sucesso!</p>
              </Together>
              <InfoForm>
                <p>
                  Impressão sob demanadas de livros e apostilas com agilidade e
                  custos
                </p>
                <p>
                  reduzidos para editoras e escritores! Preencha o formulário
                  abaixo e
                </p>
                <p>pegue seu cupom de desconto!</p>
              </InfoForm>
            </Title>
          </ColText>
          <ColVideo>
            <RowVideo>{renderYouTube()}</RowVideo>
          </ColVideo>
        </Content>
      </Container>
    </>
  );
};

export default Header;
