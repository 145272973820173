import _ from 'lodash';
import {
  FETCH_ADDRESSES,
  FETCH_ADDRESS,
  FETCH_STATES,
  FETCH_CITIES,
  CLEAR_CITIES,
  CLEAR_ADDRESS,
} from '../actions/types';

// state padrão para inicio de interação com o formulário
const INITIAL_STATE = {
  address: {
    pk_usuario_endereco: '',
    desc_usuario_endereco: '',
    default_usuario_endereco: '',
    cpf_cnpj_usuario: '',
    razao_social_endereco_usuario: '',
    contato_usuario_endereco: '',
    cep_usuario_endereco: '',
    logradouro_usuario_endereco: '',
    nr_usuario_endereco: '',
    complemento_usuario_endereco: '',
    bairro_usuario_endereco: '',
    fk_estado: '',
    fk_cidade: '',
    has_billing_address: false,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ADDRESSES:
      return {
        ...state,
        listAddress: action.payload.data,
        address: {
          ...state.address,
          has_billing_address: _.some(action.payload.data, { faturamento: 1 }),
        },
      };
    case FETCH_ADDRESS:
      // remove as chaves desnecessárias do backend
      const address = _.omit({ ...action.payload.data }, [
        'cidade',
        'cnpj_usuario_endereco',
        'cpf_usuario_endereco',
        'data_atualizacao',
        'desc_cidade',
        'desc_estado',
        'dta_inc_usuario_endereco',
        'fk_usuario',
        'uf_estado',
      ]);
      return { ...state, address };
    case CLEAR_ADDRESS:
      return { ...state, address: INITIAL_STATE.address };
    case FETCH_STATES:
      return { ...state, states: action.payload.data };
    case FETCH_CITIES:
      return { ...state, cities: action.payload.data };
    case CLEAR_CITIES:
      return { ...state, cities: {} };
    default:
      return state;
  }
};
