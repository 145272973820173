/**
 * Adiciona um novo objeto na sessão
 */
const set = (key, value) => localStorage.setItem(key, JSON.stringify(value));

/**
 * Busca um objeto da sessão
 */
const get = key => JSON.parse(localStorage.getItem(key));

/**
 * Busca um o valor de uma chave do objeto da sessão
 */
const getWithKey = (key, objKey) => {
  const obj = JSON.parse(localStorage.getItem(key));
  return obj[objKey];
};

/**
 * Altera as propriedades recebidas no objeto da sessão
 */
const update = (key, data) => {
  set(key, { ...get(key), ...data });
};

/**
 * Remove uma propriedade de um objeto
 */
const remove = (key, keyChild) => {
  const data = JSON.parse(localStorage.getItem(key));

  delete data[keyChild];

  set(key, data);

  return data;
};

/**
 * Limpa uma lista de objetos da sessão
 */
const clear = data => {
  if (!Array.isArray(data)) return;

  data.forEach(item => localStorage.setItem(item, "{}"));
};

/**
 * Verifica se existe o objeto na session
 */
const has = key => Boolean(get(key));

export default { set, get, getWithKey, update, remove, clear, has };
