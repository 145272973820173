import styled from 'styled-components';

// variables
import { colors } from '../../styles/variables';

/**
 * Container - Estrutura Base do Carousel
 *
 * @extends {div} Tag div Nativa Html
 */

export const CarouselContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  height: 100%;
  margin-bottom: 2rem;

  source {
    width: 100%;
  }

  img {
    width: 100%;
  }
`;

/**
 * Content do Carousel
 *
 * @extends {div} Tag div Nativa Html
 */

export const CarouselWrap = styled.div`
  .slick-dots {
    bottom: 8px;
    li {
      margin: 0;
      &.slick-active {
        button {
          &:before {
            color: ${colors.default};
          }
        }

        &:hover {
          &:before {
            color: ${colors.default};
          }
        }

        &:clicked {
          &:before {
            color: ${colors.default};
          }
        }
      }
    }
  }
`;

/**
 * Style Control Arrows
 *
 * @extends {span} Tag span Nativa Html
 */

export const SamplePrevArrow = styled.span`
  background-color: red;
  width: 30px;
  height: 30px;
`;

export const SampleNextArrow = styled.span`
  background-color: red;
  width: 30px;
  height: 30px;
`;

/**
 * Skeleton Style
 *
 * @extends {div} Tag div Nativa Html
 */

export const SkeletonStyle = styled.div`
  height: 300px;

  span {
    height: 300px;
  }

  @media (max-width: 768px) {
    height: auto;

    span {
      height: auto;
    }
  }
`;
