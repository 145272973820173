import React from 'react';
import { connect } from 'react-redux';

// style
import { Row, Col } from '../../styles/align';
import { TitlePage } from '../../styles/titles';
import { StyledBlockedAccount } from './style';

// Options
import { socialMedias } from './options';

const renderContacts = contacts => {
  if (!contacts) {
    return '';
  }

  const contactsList = contacts.filter(
    contact => contact.label !== 'WhatsApp:'
  );

  return contactsList.map((contact, index) => {
    return (
      <li key={index}>
        <span>{contact.label}</span>
        <p>{contact.desc}</p>
      </li>
    );
  });
};

const renderSocialMedia = () => {
  return socialMedias.map((socialMedia, index) => {
    return (
      <li key={index}>
        <a href={socialMedia.href} target={'_blank'} rel="noopener">
          {socialMedia.icon}
          <p>{socialMedia.desc}</p>
        </a>
      </li>
    );
  });
};

const BlockedAccount = ({ contacts }) => (
  <StyledBlockedAccount>
    <TitlePage>
      <span>Cadastro desativado</span>
    </TitlePage>
    <Col>
      Por favor, entre em contato conosco para regularizar seu cadastro através
      de um dos nossos canais de atendimento.
    </Col>
    <Row>
      <Col>
        <h3>Horários de Atendimento</h3>
        <ul>{renderContacts(contacts)}</ul>
      </Col>
      <Col>
        <h3>Conecte-se Conosco</h3>
        <ul>{renderSocialMedia()}</ul>
      </Col>
    </Row>
  </StyledBlockedAccount>
);

const mapStateToProps = state => {
  return {
    contacts: state.footer.contacts,
  };
};

export default connect(mapStateToProps, null)(BlockedAccount);
