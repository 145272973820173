import styled from "styled-components";

// Styles
import { Row, Col } from "../../styles/align";
import { Wrapper, Label, Radio } from "../../styles/forms";
import { TitleCards } from "../../styles/titles";
import { colors, borderRadius } from "../../styles/variables";

/**
 * Formata o container CardFilter
 *
 * @extends {Col}
 *
 * Herda estilo para formatar o interior do container
 * @extends {Row}
 * @extends {Col}
 * @extends {Radio}
 * @extends {Label}
 * @extends {Wrapper}
 * @extends {colors}
 */
export const CardFilter = styled(Col)`
	background-color: ${colors.grey.lightPrimary};
	padding: 0.8rem;
	margin-right: 1rem;
	height: initial;

	@media (max-width: 768px) {
		margin-right: 0;
		margin-bottom: 1rem;
	}

	${Row} {
		flex-wrap: wrap;
		justify-content: space-between;

		${Col} {
			width: 50%;
		}

		${Radio} {
			&:checked {
				& ~ ${Label} {
					background-color: ${colors.primary}
					color: ${colors.default}
				}

				& ~ span::after {
					background-color: ${colors.secondary};
				}
			}
		}

		${Wrapper} {
			min-width: 48%;
			margin-bottom: 0.5rem;
			margin-right: 0.5rem;
			flex-direction: row;
			border: 1px solid ${colors.grey.darkSecondary}

			@media (max-width: 768px) {
				margin-right: 0;
			}

			${Label} {
				color: ${colors.grey.dark};
			}

			&:nth-child(even) {
				margin-right: 0
			}
		}
	}

	${Col} {
		${Radio} {
			&:checked {
				& ~ span::after {
					background-color: ${colors.secondary};
				}
			}
		}
	}

	&:last-of-type {
		margin-right: 0;
	}
`;

/**
 * Formata o Title do CardFilter
 *
 * @extends {TitleCards}
 *
 * Herda estilo para formatar o interior do Title
 * @extends {colors}
 */
export const TitleCard = styled(TitleCards)`
  span {
    width: 35px;
    height: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: ${colors.default};
    border-radius: ${borderRadius};
    background-color: ${colors.primary};
    margin-right: 1rem;
  }
`;
