import loja from '../apis/loja';

import { FETCH_CREDITS, FETCH_FUTURE_RELEASES } from './types';
import { toast } from 'react-toastify';

/**
 * Consulta o histórico de movimentações de Credito.
 */

export const fetchHistoryCredits = data => async dispatch => {
  try {
    const response = await loja.get('/extrato-credito', {
      params: { ...data },
    });

    dispatch({ type: FETCH_CREDITS, payload: response.data });
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

/**
 * Consulta os lançamentos futuros.
 */

export const fetchFutureReleases = () => async dispatch => {
  try {
    const response = await loja.get('/lancamentos-futuros');

    dispatch({ type: FETCH_FUTURE_RELEASES, payload: response.data });
  } catch (error) {
    toast.error(error.response.data.message);
  }
};
