import styled from "styled-components";

// Styles
import { colors, fontSize } from "../../styles/variables";
import { Row, Col } from "../../styles/align";
import { Wrapper, Label, BtnGroup, Button, Input } from "../../styles/forms";
import { TitleCards } from "../../styles/titles";

export const LoginStyle = styled.div`
  background-color: ${colors.grey.light};
  padding: 1rem;
`;

export const LoginContent = styled(Col)`
  background-color: ${colors.grey.lightPrimary};
  padding: 1rem;

  ${Label},
  ${TitleCards} {
    padding-left: 0;
  }

  ${Input} {
    border: 1px solid ${colors.grey.darkSecondary};
  }

  ${Label} {
    color: ${colors.primary};
  }

  ${BtnGroup} {
    padding: 1rem 0;
    flex-direction: column;

    span {
      margin: 1rem 0;
      font-size: ${fontSize.sm};
      color: ${colors.secondary};
      font-weight: 700;
      cursor: pointer;

      &.required {
        padding: 1rem;
        width: 100%;
      }
    }
  }

  ${Button} {
    background-color: ${colors.primary};
    color: ${colors.default};
    text-decoration: none;
  }
`;

export const LoginRegisterContent = styled(Col)`
  width: 100%;
  padding: 1rem;

  ${Label},
  ${TitleCards} {
    padding-left: 0;
  }

  p {
    color: ${colors.primary};
  }

  ${Input} {
    border: 1px solid ${colors.grey.darkSecondary};
  }

  ${Label} {
    color: ${colors.primary};
  }

  ${BtnGroup} {
    padding: 1rem 0;
    flex-direction: column;

    p {
      color: ${colors.primary};
      font-weight: 700;
      cursor: pointer;
    }
  }

  ${Button} {
    background-color: ${colors.secondary};
    color: ${colors.default};
    text-decoration: none;
  }
`;

export const LoginRecoverContent = styled(Col)`
  width: 100%;
  padding: 1rem;
  border-top: 1px solid ${colors.grey.lightPrimary};

  ${TitleCards} {
    padding-left: 0;
  }

  ${Label} {
    padding: 0 0 0.6rem 0;
    color: ${colors.primary};
  }

  ${Input} {
    border: 1px solid ${colors.grey.darkSecondary};
  }

  ${Button} {
    background-color: ${colors.primary};
    max-width: max-content;
    color: ${colors.default};
    font-weight: 700;
    text-decoration: none;
  }

  ${Row} {
    width: 100%;

    ${Wrapper} {
      width: 100%;
    }
  }
`;
