import { FETCH_BALCONIES, CLEAR_BALCONY } from '../actions/types';

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_BALCONIES:
      return { ...state, balconies: action.payload.data };
    case CLEAR_BALCONY:
      return { ...state, balconies: undefined };
    default:
      return state;
  }
};
