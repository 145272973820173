import React from 'react';
import { Link } from 'react-router-dom';

// images
import LogoSVG from '../../assets/svg/logo';

// styles
import { logo as StyleLogo } from './style';

// ao clicar no logo, move a tela ate o topo
function scrollToTop() {
  window.scrollTo(0, 0);
}

const Logo = () => {
  return (
    <Link onClick={scrollToTop} to="/" title={'Fábrica do Livro'}>
      <StyleLogo>
        <LogoSVG />
      </StyleLogo>
      {/* <LogoSVG /> */}

    </Link>
  );
};

export default Logo;
