import { FETCH_SEARCH, CLEAR_SEARCH } from './types';
import loja from '../apis/loja';
import { toast } from 'react-toastify';

/**
 * Busca de produtos
 * @param {String} pesquisa
 */

export const fetchSearch = pesquisa => async dispatch => {
  try {
    const response = await loja.post('/pesquisar-produto', pesquisa);

    dispatch({ type: FETCH_SEARCH, payload: response.data });
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

/**
 * Limpa Pesquisa no redux
 */

export const clearSearch = () => {
  return {
    type: CLEAR_SEARCH,
  };
};
