import React from 'react';

// node
import Skeleton from 'react-loading-skeleton';

// styles
import * as Style from './style';

const SkeletonCarousel = () => {
  return (
    <Style.CarouselContainer>
      <Style.CarouselWrap>
        <Style.SkeletonStyle>
          <Skeleton />
        </Style.SkeletonStyle>
      </Style.CarouselWrap>
    </Style.CarouselContainer>
  );
};

export default SkeletonCarousel;
