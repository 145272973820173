import styled from 'styled-components';

// styles
import { colors } from '../../styles/variables';
import { Wrapper, Files } from '../../styles/forms';

/**
 * Formata o Files do Card Payment [Deposito]
 *
 * @extends {Files}
 */
export const StyleFiles = styled(Files)`
  padding: .8rem;
  
  &::-webkit-file-upload-button {
    width:0;
    height:0;
    margin:0;
    position: absolute;
    display: flex;
    border: none;
    padding: 0;
    text-align: left;
    flex-direction: column;
    align-items: flex-start
    text-align: left;
  }

  &::after {
    content: "";
    display: flex
    align-items: flex-start;
    text-align: left;
    float: left;
  }

  &:before {
    /* content: ""; */
    display: flex
    align-items: flex-start;
    text-align: left;
    float: left;
}
`;

/**
 * Formata o Container do Files [Deposito]
 *
 * @extends {Wrapper}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {colors}
 */
export const FileContainer = styled(Wrapper)`
  & > span {
    margin: 0.5rem;
    color: #000;

    strong {
      color: ${colors.primary};
    }
  }
`;
