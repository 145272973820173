import {
  FETCH_MESSAGES,
  MESSAGE_HISTORY,
  TYPE_MESSAGE,
  PAGE_MESSAGE,
  SHOW_MESSAGE
} from "../actions/types";

const INITIAL_STATE = {
  tipo: "pendente",
  currentPage: 1,
  messageSelected: ""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_MESSAGES:
      return { ...state, data: action.payload.data };
    case MESSAGE_HISTORY:
      return { ...state, history: action.payload.data };
    case TYPE_MESSAGE:
      return { ...state, tipo: action.payload };
    case PAGE_MESSAGE:
      return { ...state, currentPage: action.payload };
    case SHOW_MESSAGE:
      return { ...state, messageSelected: action.payload };
    default:
      return state;
  }
};
