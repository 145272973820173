import React, { Component } from 'react';
import { connect } from 'react-redux';

// node-modules
import { toast } from 'react-toastify';

// components
import Loader from '../../components/Loader';
import LoaderForm from '../../components/Loader/loaderForm';

// actions
import { fetchStates, fetchCities, clearCities } from '../../actions/address';
import { fetchBalconies, clearBalcony } from '../../actions/balcony';

// utils
import { CHOOSE_STATE, CHOOSE_CITY } from '../../utils/constMessage';
import { numberFormat } from '../../utils';

// styles
import { Row, Col } from '../../styles/align';
import {
  Wrapper,
  Label,
  BtnGroup,
  Button,
  Select,
  Checkbox,
} from '../../styles/forms';
import { Form, WrapperCheckbox, ContentBalconies, StyleBalcony } from './style';

class Balcony extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pk_estado: this.props.pk_estado ? this.props.pk_estado : '0',
      pk_cidade: this.props.pk_cidade ? this.props.pk_cidade : '0',
      frete_gratis: false,
      submitting: false,
      ...(this.props.orientacao && { orientacao: this.props.orientacao }),
      ...(this.props.produto && { produto: this.props.produto }),
      ...(this.props.exemplares && { exemplares: this.props.exemplares }),
      ...(this.props.paginas_coloridas && { paginas_coloridas: this.props.paginas_coloridas }),
      ...(this.props.paginas_preto_branco && { paginas_preto_branco: this.props.paginas_preto_branco }),
      ...(this.props.papel && { papel: this.props.papel }),
      ...(this.props.pk_papel_miolo && { pk_papel_miolo: this.props.pk_papel_miolo }),
      ...(this.props.quantidade && { quantidade: this.props.quantidade }),
    };
  }

  componentDidMount() {
    this.props.fetchStates();
  }

  componentWillUnmount() {
    this.props.clearCities();
    this.props.clearBalcony();
  }

  renderStates() {
    const { states } = this.props;
    if (!states) {
      return '';
    }

    return (
      <>
        {Object.values(states).map(state => {
          return (
            <option key={state.pk_estado} value={state.pk_estado}>
              {state.desc_estado}
            </option>
          );
        })}
      </>
    );
  }

  renderCities() {
    const { cities } = this.props;
    if (!cities) {
      return '';
    }
    return (
      <>
        {Object.values(cities).map(city => {
          return (
            <option key={city.pk_cidade} value={city.pk_cidade}>
              {city.desc_cidade}
            </option>
          );
        })}
      </>
    );
  }

  renderBalconies() {
    const { balconies } = this.props;
    if (!balconies) {
      return this.props.loader ? <Loader /> : '';
    }

    return (
      <Row>
        {balconies.map((balcony, i) => {
          return (
            <StyleBalcony key={i}>
              <h3>{balcony.desc_balcao}</h3>
              <strong>
                {!balcony.vlr_balcao
                  ? 'GRÁTIS'
                  : `R$ ${numberFormat(balcony.vlr_balcao)}`}
              </strong>
              <p>
                {balcony.logradouro_balcao} - {balcony.nr_balcao}
              </p>
              <p>
                {balcony.desc_cidade} - {balcony.desc_estado} -{' '}
                {balcony.cep_balcao}
              </p>
              <p>{balcony.prazo_balcao} dias úteis</p>
              <p>Fone: {balcony.fone_balcao}</p>
              <p><strong>Horário de atendimento:</strong> {balcony.complemento_balcao}</p>
              <p>{balcony.info_atendimento}</p>
              {/* <p>Horário de atendimento: {balcony.fone_balcao}</p> */}
            </StyleBalcony>
          );
        })}
      </Row>
    );
  }

  handleStateChange(e) {
    const { value } = e.target;
    let objState = { pk_estado: value };
    if (value === '0') {
      this.props.clearCities();
      objState = { pk_cidade: '0' };
      this.setState({ pk_estado: value });
    } else {
      this.props.fetchCities(value, 1);
    }

    this.setState(objState);
  }

  submit = () => {
    if (this.state.pk_estado === '0') {
      toast.warn(CHOOSE_STATE);
      return false;
    }

    if (this.state.pk_cidade === '0') {
      toast.warn(CHOOSE_CITY);
      return false;
    }

    this.setState({
      submitting: true,
    });

    this.props.fetchBalconies({
      pk_estado: this.state.pk_estado,
      pk_cidade: this.state.pk_cidade,
      frete_gratis: this.state.frete_gratis,
      ...(this.state.orientacao && { orientacao: this.state.orientacao }),
      ...(this.state.produto && { produto: this.state.produto }),
      ...(this.state.exemplares && { exemplares: this.state.exemplares }),
      ...(this.state.paginas_coloridas && { paginas_coloridas: this.state.paginas_coloridas }),
      ...(this.state.paginas_preto_branco && { paginas_preto_branco: this.state.paginas_preto_branco }),
      ...(this.state.papel && { papel: this.state.papel }),
      ...(this.state.pk_papel_miolo && { pk_papel_miolo: this.state.pk_papel_miolo }),
      ...(this.state.quantidade && { quantidade: this.state.quantidade }),
      ...(this.state.exemplares && { exemplares: this.state.exemplares }),   
    }).then(() => {
      this.setState({
        submitting: false,
      });
    });
  };

  render() {
    const { submitting } = this.state;

    return (
      <Col>
        <Form>
          <Wrapper>
            <Label htmlFor={'estados'}>Estado</Label>
            <Select
              id={'estados'}
              name="pk_estado"
              onChange={e => this.handleStateChange(e)}
              defaultValue={this.state.pk_estado}
            >
              <option value="0">Selecione um Estado</option>
              {this.renderStates()}
            </Select>
          </Wrapper>
          <Wrapper>
            <Label htmlFor={'cidades'}>Cidade</Label>
            <Select
              id={'cidades'}
              name="pk_cidade"
              onChange={e => this.setState({ pk_cidade: e.target.value })}
              defaultValue={this.state.pk_cidade}
            >
              <option value="0">Selecione uma Cidade</option>
              {this.renderCities()}
            </Select>
          </Wrapper>
          <WrapperCheckbox>
            <Row>
              <Checkbox
                id={'frete'}
                name={'frete_gratis'}
                onChange={e =>
                  this.setState({ frete_gratis: !this.state.frete_gratis })
                }
              />
              <span></span>
              <Label htmlFor={'frete'}>Frete Gratis</Label>
            </Row>
          </WrapperCheckbox>
          <BtnGroup>
            <Button onClick={this.submit} disabled={submitting}>
              Filtrar{submitting ? <LoaderForm /> : ''}
            </Button>
          </BtnGroup>
        </Form>
        <ContentBalconies>{this.renderBalconies()}</ContentBalconies>
      </Col>
    );
  }
}

const mapStateToProps = state => {
  return {
    states: state.address.states,
    cities: state.address.cities,
    balconies: state.balcony.balconies,
  };
};

Balcony = connect(mapStateToProps, {
  fetchStates,
  fetchCities,
  clearCities,
  fetchBalconies,
  clearBalcony,
})(Balcony);

export default Balcony;
