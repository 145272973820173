import styled from 'styled-components';

// variables
import { colors, fontSize } from '../../styles/variables';

/**
 * Container Category
 *
 * @extends {section} Tag section Nativa Html
 */
export const CategoryWrapper = styled.section`
  background-color: ${colors.grey.light};
  padding: 2rem 3rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    padding: 1rem 1.5rem;
  }
`;

/**
 * Content Category
 *
 * @extends {div} Tag div Nativa Html
 */

export const CategoryContent = styled.div`
  background-color: ${colors.grey.lightPrimary};
  padding: 1rem 3rem;

  @media (max-width: 768px) {
    width: 100%;
  }

  img {
    width: 500px;
    height: fit-content;
  }

  @media (max-width: 768px) {
    padding: 0.5rem 1.5rem;
    img {
      width: 100%;
    }
  }
`;

/**
 * Container de Imagem da Categoria
 *
 * @extends {div} Tag div Nativa Html
 */

export const Image = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

/**
 * Container de Formatação do texto da categoria
 * Formata as tags HTML que vem do cadastro de produtos.
 *
 * @extends {div} Tag div Nativa Html
 */

export const Text = styled.div`
  width: 100%;

  h1 {
    font-size: ${fontSize.xl};
    color: ${colors.primary};
    font-weight: 800;
    font-style: italic;
    text-transform: uppercase;
    margin-bottom: 2rem;
  }

  h2 {
    color: ${colors.grey.dark};
    font-size: ${fontSize.lg};
    font-weight: 600;
  }

  hr {
    margin: 1rem 0;
  }

  span {
    max-width: 500px;

    div,
    ul,
    p,
    span {
      font-size: ${fontSize.md};
      color: ${colors.grey.dark};
      line-height: 1.5;
      margin-top: 1rem;
    }

    b {
      font-weight: 700;
    }
  }

  @media (max-width: 768px) {
    h1 {
      margin: 1rem;
      text-align: center;
    }
  }
`;
