import styled from 'styled-components';

// Style
import { colors, fontSize } from '../../styles/variables';

/**
 * Formata o Container do Resumo
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do Container
 * @extends {fontSize}
 * @extends {colors}
 */
export const StyleSummary = styled.div`
  width: 100%;
  height: fit-content;
  padding: 1rem;
  background-color: ${colors.grey.light};
  margin-bottom: 1rem;

  h2 {
    color: ${colors.primary};
    font-size: ${fontSize.lg};
    text-align: center;
    margin-bottom: 1rem;
  }

  ul {
    list-style: none;
    border-bottom: 2px solid ${colors.primary};

    li {
      padding: 0.8rem 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: ${fontSize.sm};
      color: ${colors.primary};

      span {
        line-height: 1.4;

        &:first-of-type {
          font-weight: bold;
        }

        &:last-of-type {
          max-width: 160px;
        }
      }

      &:nth-child(odd) {
        background-color: ${colors.grey.lightPrimary};
      }
    }
  }
`;

/**
 * Formata o bloco de Total do Resumo
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do bloco
 * @extends {fontSize}
 * @extends {colors}
 */
export const StylePrice = styled.div`
  font-size: ${fontSize.md};
  color: ${colors.primary};
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 0.5rem;

  & > span {
    font-size: ${fontSize.sm};

    &:first-of-type {
      text-transform: uppercase;
      font-weight: 900;

      span {
        font-weight: 400;
        font-size: ${fontSize.md};
        margin-left: 1rem;
      }
    }

    &:last-of-type {
      font-weight: 900;
      color: ${colors.secondary};
    }
  }
`;
