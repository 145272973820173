// import _ from "lodash";
import { slugify } from "../utils";
import { FETCH_CONTACTS, FETCH_PAYMENTS } from "../actions/types";

const INITIAL_STATE = {
  login: false,
  address: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CONTACTS:
      const contacts = action.payload.data.map(value => {
        return { desc: value.valor, label: value.nome };
      });
      return { ...state, contacts };
    case FETCH_PAYMENTS:
      const payments = action.payload.data.pagamento.map(value => {
        return {
          desc: value.desc_dependencia_tipo_pagamento,
          icon: value.ico_dependencia_tipo_pagamento,
          parentClass: "card-list",
          class: slugify(value.desc_dependencia_tipo_pagamento),
          title:
            value.fk_tipo_pagamento === 1
              ? `${action.payload.data.titular}\nCNPJ: ${action.payload.data.cnpj}\n${value.info_dependencia_tipo_pagamento}`
              : value.desc_dependencia_tipo_pagamento
        };
      });
      return { ...state, payments };
    default:
      return state;
  }
};
