import styled from 'styled-components';

// Styles
import { Row, Col } from '../../../../styles/align';
import { Wrapper, BtnGroup, Button } from '../../../../styles/forms';
import { colors } from '../../../../styles/variables';

/**
 * Formata o Container de Quantidades do Livro
 *
 * @extends {Col}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Row}
 * @extends {Wrapper}
 * @extends {colors}
 */
export const QuantityBook = styled(Col)`
	${Row} {
		padding: 0.5rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: nowrap;
		color: ${colors.secondary};
		font-weight: 600;
		text-transform: uppercase;
    
    & > span {
      @media (max-width: 768px) {
        margin-bottom: 1rem;
      }
    }

		${Wrapper} {
			width: 100%;
			
			span {
				color: ${colors.primary}
      }
		}
	}
`;

/**
 * Formata os Totais
 *
 * @extends {Row}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {colors}
 */

export const QuantityBookValues = styled(Row)`
  background-color: #ddd;
  padding-right: 1rem;
  margin-bottom: 1rem;

  span {
    display: flex;
    align-items: center;
    color: ${colors.primary};

    p {
      display: inline;
    }

    span {
      color: ${colors.default};
    }
  }
`;

/**
 * Formata as opções de quantodades
 * [Multiplicador ou Boneco]
 *
 * @extends {Row}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Col}
 * @extends {Wrapper}
 */

export const OptionsQuantity = styled(Row)`
  margin-bottom: 0;

  ${Col} {
    flex-direction: row;
    align-items: center;

    ${Wrapper} {
      margin-bottom: 0;

      @media (max-width: 768px) {
        margin-bottom: 1rem;
      }
    }

    &:first-of-type {
      width: 75%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    &:last-of-type {
      width: 25%;
      display: flex;
      justify-content: center;

      @media (max-width: 768px) {
        width: 100%;
        align-items: center;
      }

      text-align: center;
      padding-left: 1rem;

      div {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
`;

/**
 * Formata o Bloco que chama a Pagina Calcular Lombada
 *
 * @extends {Row}
 *
 * Herda estilo para formatar o interior do Bloco
 * @extends {BtnGroup}
 * @extends {Button}
 */

export const Calculator = styled(Row)`
  ${BtnGroup} {
    width: 100%;
    justify-content: flex-start;

    a,
    a:visited {
      width: inherit;
      font-weight: 700;
      color: ${colors.primary};
    }

    ${Button} {
      min-width: fit-content;
      font-weight: 700;
      font-style: normal;
      margin-left: 0;
    }
  }
`;
