import styled from 'styled-components';

// Style
import { Container } from '../style';
import { colors, fontSize } from '../../styles/variables';

/**
 * Formata o Container da Modal Pedidos com Erro
 *
 * @extends {Container}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {fontSize}
 * @extends {colors}
 */
export const OrderErrorsStyle = styled(Container)`
  h2 {
    font-size: ${fontSize.md};
    color: ${colors.primary};
    font-weight: 700;
  }

  span {
    display: flex;
    font-weight: 700;
    color: ${colors.secondary};
    margin: 2rem 0;
    width: 100%;
    justify-content: center;

    p {
      margin-left: 0.5rem;
    }
  }
`;
