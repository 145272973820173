import React, { Component } from 'react';

class InfinityScroll extends Component {
  constructor(props) {
    super(props);

    this.refContainer = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleInfinityScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleInfinityScroll, true);
  }

  handleInfinityScroll = () => {
    const scrollHeight = this.refContainer.scrollHeight;
    const { maxItems, limit, stepItems } = this.props;

    if (window.scrollY > scrollHeight / 2 && !limit) {
      this.props.updateInfinity(maxItems + stepItems);
    }
  };

  render() {
    const { ComponentData, data, base, otherPath } = this.props;

    return (
      <div ref={ref => (this.refContainer = ref)}>
        <ComponentData key={0} data={data} base={base} otherPath={otherPath} />
      </div>
    );
  }
}

export default InfinityScroll;
