import React from 'react'

import InstagramIcon from '../../assets/images/icons/instagram.png'
import TikTokIcon from '../../assets/images/icons/tiktok.png'
import LinkedinIcon from '../../assets/images/icons/linkedin.png'
import BlogFabricaIcon from '../../assets/images/icons/blog-fabrica.png'

export const socialMedias = [
  {
    image: InstagramIcon,
    href: 'https://www.instagram.com/fabricadolivro/',
    alt: 'Ícone Instagram',
    rel: 'noopener noreferrer'
  },
  {
    image: TikTokIcon,
    href: 'https://www.tiktok.com/@fabricadolivro',
    alt: 'Ícone TikTok',
    rel: 'noopener noreferrer'
  },
  {
    image: LinkedinIcon,
    href: 'https://www.linkedin.com/company/f%C3%A1brica-do-livro/',
    alt: 'Ícone LinkedIn',
    rel: 'noopener noreferrer'
  },
  {
    image: BlogFabricaIcon,
    href: 'https://blog.fabricadolivro.com.br/',
    alt: 'Ícone Blog Fábrica do Livro',
    rel: 'noopener noreferrer'
  },
]