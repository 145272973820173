import styled from 'styled-components';

// Styles
import { Container, Col } from '../../styles/align';
import { TitlePage } from '../../styles/titles';
import { Wrapper, Label, BtnGroup, Button } from '../../styles/forms';
import { colors, fontSize } from '../../styles/variables';

/**
 * Formata o Container da Pagina Calculo de Lombada
 * @extends {Container}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {TitlePage}
 * @extends {BtnGroup}
 * @extends {Button}
 * @extends {fontSize}
 */
export const StyleCalcLombada = styled(Container)`
  flex-direction: column;
  margin-bottom: 1rem;

  ${TitlePage} {
    width: 100%;
  }

  ${BtnGroup} {
    padding: 1rem 0;

    @media (max-width: 768px) {
      justify-content: center;
    }

    ${Button} {
      min-width: fit-content;
      font-size: ${fontSize.md};
      font-weight: 700;
    }
  }
`;

/**
 * Formata o Wrapper do Numero de Paginas
 * @extends {Wrapper}
 */

export const WrapperPages = styled(Wrapper)`
  width: 150px;
`;

/**
 * Formata o Wrapper do Checkbox
 * @extends {Wrapper}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Label}
 */
export const WrapperCheckbox = styled(Wrapper)`
  ${Label} {
    padding-left: 1.5rem;
  }
`;

/**
 * Formata o formulario do Calculo da Lombada
 * @extends {Col}
 *
 * Herda estilo para formatar o interior do formulario
 * @extends {Col}
 * @extends {Wrapper}
 * @extends {Label}
 * @extends {WrapperCheckbox}
 * @extends {BtnGroup}
 * @extends {Button}
 * @extends {colors}
 */
export const StyleForm = styled(Col)`
  width: 40%;
  padding: 1rem;
  border-right: 1px solid ${colors.grey.lightPrimary};

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }

  p {
    margin: 1rem 0;
    color: ${colors.primary};
    font-size: ${fontSize.md};
  }

  ${Col} {
    background-color: ${colors.grey.light};
    padding: 1rem;

    form {
      padding: 1rem;
      background-color: ${colors.grey.lightPrimary};

      ${Wrapper} {
        margin-bottom: 1rem;

        ${Label} {
          padding-left: 0;
          color: ${colors.primary};
          text-transform: uppercase;
          font-weight: 700;
        }
      }

      ${WrapperCheckbox} {
        span {
          margin-left: 0;
        }

        ${Label} {
          padding-left: 1.5rem;
        }
      }

      ${BtnGroup} {
        justify-content: flex-start;

        ${Button} {
          margin-left: 0;
        }
      }
    }
  }
`;

/**
 * Formata o Bloco de texto informativo da Pagina
 * @extends {Col}
 *
 * Herda estilo para formatar o interior do Bloco
 * @extends {fontSize}
 * @extends {colors}
 */
export const StyleText = styled(Col)`
  width: 60%;

  @media (max-width: 768px) {
    width: 100%;
  }

  ${Col} {
    padding: 2rem;

    h2 {
      font-size: ${fontSize.md};
      color: ${colors.primary};
      font-weight: 700;
      margin-bottom: 2rem;
      text-transform: uppercase;
    }

    ul {
      list-style: none;

      li {
        color: ${colors.primary};
        line-height: 1.5;
        margin-bottom: 2rem;
      }
    }
  }
`;

/**
 * Formata o Resultado do Calculo da lombada
 * @extends {div} Tag nativa do HTML
 *
 * Herda estilo para formatar o interior do Resultado
 * @extends {fontSize}
 * @extends {colors}
 */
export const Result = styled.div`
  color: ${colors.primary};

  span {
    font-size: ${fontSize.lg};
    text-transform: uppercase;
  }

  p {
    font-size: 30pt;
    font-weight: 900;
    line-height: 1.5;
  }
`;
