import React, { Component } from 'react';
import { connect } from 'react-redux';

// Node
import { toast } from 'react-toastify';

// config
import config from '../../config';

// components
import NotFound from '../../components/NotFound';
import SimulateDelivery from '../../components/SimulateDelivery';
import Summary from '../../components/Summary';
import TemplateFiles from '../../components/TemplateFiles';

// actions
import {
  activeRequest,
  clearFilter,
  fetchFilter,
  lastProduct,
} from '../../actions/filter';

// partials
import FilterBook from './FilterBook';
import FilterProduct from './FilterProduct';
import Budget from './partials/Budget';
import SkeletonFilter from './skeleton';

// Helpers
import Seo from '../../helpers/Seo';

// services
import { addProduct, getFinishesFiltered } from '../../services/filter';

// utils
import { PRODUCT_NOT_FOUND } from '../../utils/constMessage';

// styles
import { Col, Container, MainPage, Row } from '../../styles/align';
import { BtnGroup, Button, Checkbox, Label, Wrapper } from '../../styles/forms';
import { TitlePage } from '../../styles/titles';
import { ActionsContainer, Anchor, SidebarFilter } from './style';
import TagManager from 'react-gtm-module';
import { modalDeliverySteps } from '../../actions/modals';

class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      terms: false,
      additionalPrice: 0,
      additionalDeadline: 0,
      finish: null,
    };
  }

  fetchData() {
    const { menu, pk_produto } = this.props.match.params;
    this.props.fetchFilter(menu, pk_produto);
    this.props.lastProduct(this.props.match.url);
  }

  componentDidMount() {
    this.fetchData();
  }

  /**
   * Verifica a mudança no filtro para atualizar
   * a url com a nova pk do produto
   * @param {object} prevProps
   */
  componentDidUpdate(prevProps) {
    // Verificando se prevProps.data é undefined, garante que será o primeiro rander, apos isso, this.props.data sempre terá valor
    // eslint-disable-next-line
    if (prevProps.data == undefined && this.props.data.dados_produto) {
      const {
        pk_produto,
        vlr_preco,
        desc_menu,
        nome_produto,
      } = this.props.data.dados_produto;
      const {itens} = this.props.data.resumo

      const product_price = itens.length > 0 ? itens[0].vlr_total_item : vlr_preco

      TagManager.dataLayer({
        dataLayer: {
          event: 'select_item',
          ecommerce: {
            items: [
              {
                item_id: pk_produto,
                item_name: nome_produto,
                item_category: desc_menu,
                price: product_price,
              },
            ],
          },
        },
      });
    }
    if (prevProps.data && this.props.data) {
      // Verifica se houve mudança de produto
      if (
        prevProps.data.dados_produto.pk_produto !==
        this.props.data.dados_produto.pk_produto
      ) {
        const {
          pk_produto,
          vlr_preco,
          desc_menu,
          nome_produto,
        } = this.props.data.dados_produto;
        const {itens} = this.props.data.resumo

        const product_price = itens.length > 0 ? itens[0].vlr_total_item : vlr_preco

        TagManager.dataLayer({
          dataLayer: {
            event: 'select_item',
            ecommerce: {
              items: [
                {
                  item_id: pk_produto,
                  item_name: nome_produto,
                  item_category: desc_menu,
                  price: product_price,
                },
              ],
            },
          },
        });

        this.props.history.push(
          `/${this.props.data.dados_produto.url_amigavel}`
        );
      }
    }
  }

  componentWillUnmount() {
    this.props.clearFilter();
  }

  toggleTerms = () => {
    this.setState({
      terms: !this.state.terms,
    });
  };

  renderTitle(data) {
    return (
      <TitlePage>
        <span>{data.dados_produto.desc_menu}</span>
      </TitlePage>
    );
  }

  /**
   * verifica qual tipo de filtro deve renderizar [livro/normal]
   * @param {object} data [dados do filtro]
   */
  renderTypeFilter(data) {
    return data.dados_produto.fk_tipo_produto === config.TYPE_BOOK ? (
      <FilterBook
        menu={this.props.match.params.menu}
        onAdditional={this.onAdditional}
        onFinishSelect={this.onFinishSelect}
      />
    ) : (
      <FilterProduct
        menu={this.props.match.params.menu}
        onAdditional={this.onAdditional}
        onFinishSelect={this.onFinishSelect}
      />
    );
  }

  renderSummary(data) {
    if (!data || !data.resumo || !data.resumo.itens || !data.resumo.itens[0]) {
      return '';
    }

    let optionalfinishes = [];
    if (
      this.props.filterProductsOnChange &&
      this.props.filterProductsOnChange.values.acabamento_opcional
    ) {
      optionalfinishes = getFinishesFiltered(
        data.acabamentos_opcionais,
        this.props.filterProductsOnChange.values.acabamento_opcional
      );
    }
    return (
      <Summary
        data={data.resumo.itens[0]}
        title={data.dados_produto.desc_menu}
        additionalPrice={this.state.additionalPrice}
        additionalDeadline={this.state.additionalDeadline}
        optionalfinishes={optionalfinishes}
        finish={this.state.finish}
        subTipoProduto={data.dados_produto.fk_sub_tipo_produto}
        order={false}
        selecteds={data.atributos_selecionados}
      />
    );
  }

  onAdditional = (additionalPrice, additionalDeadline) => {
    this.setState({ additionalPrice, additionalDeadline });
  };

  onFinishSelect = finish => {
    this.setState({ finish });
  };

  onSubmit = async () => {
    const { filterProductsOnChange, data } = this.props;

    if (!this.state.terms) {
      toast.warn('É necessário aceitar os termos de garantia');
      return false;
    }

    if (
      filterProductsOnChange.registeredFields.acabamento_obrigatorio &&
      !filterProductsOnChange.values.acabamento_obrigatorio
    ) {
      toast.warn('Necessário Selecionar o tipo do acabamento');
      return false;
    }

    const result = await addProduct(
      filterProductsOnChange.values,
      data.dados_produto.pk_produto
    );

    if (!result) {
      toast.error(PRODUCT_NOT_FOUND);
      return false;
    }

    this.props.modalDeliverySteps(() => this.props.history.push('/envio'));
  };

  renderGabaritos() {
    const { gabaritos } = this.props;
    if (!gabaritos || !gabaritos.length) {
      return '';
    }

    return (
      <ActionsContainer>
        <TemplateFiles gabaritos={gabaritos} />
      </ActionsContainer>
    );
  }

  render() {
    const { data } = this.props;

    if (!data) {
      return <SkeletonFilter />;
    }

    if (data === 'Not Found') {
      return <NotFound />;
    }

    return (
      <>
        <Seo
          props={{
            title: data.dados_produto.titulo_tags,
            meta: [
              {
                name: 'description',
                content: data.dados_produto.descricao_tags,
              },
            ],
            canonical: data.dados_produto.url_canonical,
          }}
        />
        <Container>
          <Col>
            <span>{this.renderTitle(data)}</span>
            <Row>
              <MainPage>
                {this.renderTypeFilter(data)}
                <Budget />
              </MainPage>
              <SidebarFilter>
                {this.renderSummary(data)}
                <SimulateDelivery />
                <Wrapper>
                  <Checkbox id={'termos'} onChange={this.toggleTerms} />
                  <span></span>
                  <Label htmlFor={'termos'}>
                    Li e aceito os{' '}
                    <Anchor
                      href="https://instrucoes.fabricadolivro.com.br/politica-empresa/termos-de-uso/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Termos de Uso do Site
                    </Anchor>
                  </Label>
                </Wrapper>
                <BtnGroup>
                  <Button
                    onClick={() => this.onSubmit()}
                    disabled={this.props.request ? true : false}
                  >
                    Ir para envio de arquivos {'>'}
                  </Button>
                </BtnGroup>
                {this.renderGabaritos()}
              </SidebarFilter>
            </Row>
          </Col>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.filter.filter,
    gabaritos: state.filter.gabaritos,
    filterProductsOnChange: state.form.filterProductsOnChange,
    request: state.filter.activeRequest,
  };
};

Filter = connect(mapStateToProps, {
  fetchFilter,
  clearFilter,
  lastProduct,
  activeRequest,
  modalDeliverySteps,
})(Filter);

export default Filter;
