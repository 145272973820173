import styled from 'styled-components';

// Styles
import { Col } from '../../../../styles/align';
import { Wrapper, Label, Radio } from '../../../../styles/forms';
import { colors } from '../../../../styles/variables';

/**
 * Formata o Container de Escolha da Cor do Produto 4x0 4x4
 *
 * @extends {Col}
 *
 * Herda estilo para formatar o interior do container
 * @extends {Wrapper}
 * @extends {Radio}
 * @extends {Label}
 * @extends {colors}
 */

export const Colors = styled(Col)`
  width: 100%;
  align-items: center;
  justify-content: space-around;
  padding: 0.6rem;
  border: 1px solid ${colors.grey.darkSecondary};
  border-radius: 5px;
  flex-direction: row;
  margin-bottom: 1rem;

  ${Wrapper} {
    min-width: auto;
    border: 0;
    margin: 0;

    ${Radio} {
      &:checked {
        & ~ ${Label} {
          background-color: transparent;
          color: ${colors.primary};
        }
      }
    }
  }
`;
