import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, Form, reduxForm, submit } from 'redux-form';

// Node
import { toast } from 'react-toastify';

// hocs
import Modal from '../../hocs/Modal';

// Components
import LoaderForm from '../../components/Loader/loaderForm';

// actions
import { modalWithdrawal } from '../../actions/modals';
import {
  saveWithdrawal as saveWithdrawalAction,
  isSubmitForm,
} from '../../actions/cart';

// validate
import renderField from '../../formValidate/renderField';
import { mapErrors } from '../../formValidate/handleErrors';
import { maskCpfCnpj } from '../../formValidate/masks';
import { cpfCnpj } from '../../formValidate';

// services
import { saveWithdrawal } from '../../services/cart';
import { checkCpfCnpj } from '../../services/user';

// utils
import { onlyNumbers } from '../../utils';
import {
  SUCCESS,
  ERROR,
  CONNECTION_ERROR,
  ACCEPT_WITHDRAWAL,
  VERIFY_WITHDRAWAL,
} from '../../utils/constMessage';

// Style
import { TitleCards } from '../../styles/titles';
import { Row, Col } from '../../styles/align';
import { Wrapper, BtnGroup, Button, Label } from '../../styles/forms';
import { StyleWithdrawal, ItemContent } from './style';

class Withdrawal extends Component {
  state = {
    errors: {
      nome_razao_retirada_balcao: '',
      cpf_cnpj_retirada_balcao: '',
    },
    disabled: {
      cpf_cnpj_retirada_balcao: false,
      nome_razao_retirada_balcao: true,
    },
    loadingCpfCnpj: false,
  };

  componentWillUnmount() {
    this.props.isSubmitForm(false);
  }

  handleErrors = response => {
    if (response.errosValidacao) {
      const errors = mapErrors(response.errosValidacao);
      this.setState({ errors });
      return false;
    }

    if (response.message) {
      toast.warn(response.message);
    } else {
      toast.error(ERROR);
    }
  };

  onBlurCpfCnpj = e => {
    let fk_tipo_usuario = 0;
    let nome_razao_retirada_balcao = false;

    const cpfOnlyNumbers = onlyNumbers(e.target.value);

    if (cpfOnlyNumbers.length === 11) {
      fk_tipo_usuario = 2;
    } else if (cpfOnlyNumbers.length === 14) {
      fk_tipo_usuario = 1;
    }

    if (fk_tipo_usuario) {
      this.setState({
        disabled: {
          cpf_cnpj_retirada_balcao: true,
        },
        loadingCpfCnpj: true,
      });

      checkCpfCnpj(cpfOnlyNumbers, '', fk_tipo_usuario).then(response => {
        if (response.status === 200) {
          if (response.data.data.nome_usuario) {
            this.props.change(
              'nome_razao_retirada_balcao',
              response.data.data.nome_usuario
            );
            nome_razao_retirada_balcao = true;
          }
        } else if (response.errors) {
          this.handleErrors(response.errors);
        } else {
          toast.error(CONNECTION_ERROR);
        }
        this.setState({
          disabled: {
            cpf_cnpj_retirada_balcao: false,
            nome_razao_retirada_balcao,
          },
          loadingCpfCnpj: false,
        });
      });
    }
  };

  submit = data => {
    const { dispatch, isSubmitCart } = this.props;

    if (!data.verificar) {
      toast.warn(VERIFY_WITHDRAWAL);
      return false;
    }

    if (data.tipo === '2' && !data.aprovar) {
      toast.warn(ACCEPT_WITHDRAWAL);
      return false;
    }

    saveWithdrawal(data).then(response => {
      if (response.status === 200) {
        this.props.modalWithdrawal();
        this.props.saveWithdrawalAction();
        toast.success(SUCCESS);
        if (isSubmitCart) {
          dispatch(submit('cart'));
        }
      } else if (response.errors) {
        this.handleErrors(response.errors);
      } else {
        toast.error(CONNECTION_ERROR);
      }
    });
  };

  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <StyleWithdrawal>
        <TitleCards>Selecione o responsável pela retirada:</TitleCards>
        <Form onSubmit={handleSubmit(this.submit)}>
          <Col>
            <Wrapper>
              <Field
                name={'tipo'}
                type={'radio'}
                typefield={'radio'}
                id={'comprador'}
                component={renderField}
                value={'1'}
              />
              <Label htmlFor={'comprador'}>O Comprador</Label>
            </Wrapper>
            <Wrapper>
              <Field
                name={'tipo'}
                type={'radio'}
                typefield={'radio'}
                id={'outro'}
                component={renderField}
                value={'2'}
              />
              <Label htmlFor={'outro'}>Outra Pessoa</Label>
              <ItemContent>
                <h3>Dados do reponsável pela retirada:</h3>
                <Row>
                  <Wrapper>
                    <Label htmlFor={'cpf'}>
                      CPF/CNPJ <strong>*</strong>
                    </Label>
                    <Field
                      name={'cpf_cnpj_retirada_balcao'}
                      id={'cpf'}
                      typefield={'mask'}
                      mask={maskCpfCnpj}
                      onBlur={this.onBlurCpfCnpj}
                      component={renderField}
                      validate={[cpfCnpj]}
                      responseError={this.state.errors.cpf_cnpj_retirada_balcao}
                      disabled={this.state.disabled.cpf_cnpj_retirada_balcao}
                      loading={this.state.loadingCpfCnpj}
                    />
                  </Wrapper>
                  <Wrapper>
                    <Label htmlFor={'responsavel'}>
                      Responsável <strong>*</strong>
                    </Label>
                    <Field
                      name={'nome_razao_retirada_balcao'}
                      id={'responsavel'}
                      component={renderField}
                      responseError={
                        this.state.errors.nome_razao_retirada_balcao
                      }
                      disabled={this.state.disabled.nome_razao_retirada_balcao}
                    />
                  </Wrapper>
                </Row>
                <Wrapper>
                  <Field
                    name={'aprovar'}
                    type={'checkbox'}
                    typefield={'checkbox'}
                    id={'aprovar'}
                    component={renderField}
                  />
                  <Label htmlFor={'aprovar'}>
                    Confirmo que os dados informados são válidos e referentes a
                    mesma pessoa <strong>*</strong>
                  </Label>
                </Wrapper>
              </ItemContent>
            </Wrapper>
            <Wrapper>
              <Field
                name={'verificar'}
                type={'checkbox'}
                typefield={'checkbox'}
                id={'verificar'}
                component={renderField}
              />
              <Label htmlFor={'verificar'}>
                Confirmo que a encomenda será verificada no ato da retirada,
                fazendo uma ressalva se necessário <strong>*</strong>
              </Label>
            </Wrapper>
          </Col>
          <BtnGroup row>
            <span className={'required'}>* Campos Obrigatórios</span>
            <span>
              <Button onClick={this.props.modalWithdrawal}>Cancelar</Button>
              <Button secondary type={'submit'} disabled={submitting}>
                Enviar{submitting ? <LoaderForm /> : ''}
              </Button>
            </span>
          </BtnGroup>
        </Form>
      </StyleWithdrawal>
    );
  }
}

// valores iniciais do form
const initialValues = { tipo: '1' };

const mapStateToProps = state => {
  return {
    isSubmitCart: state.cart.isSubmitForm,
  };
};

Withdrawal = reduxForm({
  form: 'formModalWithdrawal',
  initialValues,
})(Withdrawal);

Withdrawal = connect(mapStateToProps, {
  modalWithdrawal,
  saveWithdrawalAction,
  isSubmitForm,
})(Withdrawal);

Withdrawal = Modal(Withdrawal);

export default Withdrawal;
