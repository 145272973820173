import React, { Component } from "react";
import { connect } from "react-redux";
// node-modules
import ReactHtmlParser from "react-html-parser";
import { getYear } from 'date-fns'
// actions
import { fetchContacts, fetchPayments } from "../../actions/layout";
// components
import CardList from "../Cards";
// styles
import { Col, Container } from "../../styles/align";
import { StyleFooter, ContainerText } from "./style";
//options
import { duvidas, institucional, texto_publicitario } from "./options";

class Footer extends Component {
  componentDidMount() {
    this.props.fetchContacts();
    this.props.fetchPayments();
  }

  renderCards() {
    const { contacts, payments } = this.props;

    return (
      <>
        <CardList title="Dúvidas Comuns" list={duvidas.list} />
        <CardList title="Institucional" list={institucional.list} />
        {payments ? (
          <CardList row title="Formas de Pagamento" list={payments} />
        ) : (
          ""
        )}
        {contacts ? <CardList title="Contato" list={contacts} /> : ""}
      </>
    );
  }

  render() {
    return (
      <StyleFooter>
        <Container>{this.renderCards()}</Container>

         <span>Fábrica do Livro {getYear(new Date())} - Todos os Direitos Reservados</span> <br />
          <span>CNPJ 49.411.385/0001-79 - FABRICA DO LIVRO GRAFICA EDITORIAL LTDA</span>

        <ContainerText>
          {texto_publicitario.map((item, i) => {
            return (
              <Col key={i}>
                <h4>{item.title}</h4>
                <div>{ReactHtmlParser(item.text)}</div>
              </Col>
            );
          })}
        </ContainerText>
      </StyleFooter>
    );
  }
}

const mapStateToProps = state => {
  return {
    contacts: state.footer.contacts,
    payments: state.footer.payments
  };
};

export default connect(
  mapStateToProps,
  { fetchContacts, fetchPayments }
)(Footer);
