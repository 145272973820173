import styled from 'styled-components';

// Styles
import { colors } from '../styles/variables';
import { Row } from '../styles/align';
import { Wrapper, Label, BtnGroup, Button } from '../styles/forms';

/**
 * Formata o Container Global das Modal
 * @extends {div} Tag div nativa do HTML
 */
export const Container = styled.div`
  background-color: ${colors.grey.light};
  padding: 1rem;
`;

/**
 * Formata Um Loading interno no Content da Modal
 * @extends {div} Tag div nativa do HTML
 */
export const Loading = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: ${colors.default};
  opacity: 1;
  z-index: 100;
  position: absolute;
  pointer-events: stroke;
  left: 0;
  top: 0;
`;

/**
 * Formata um padrão para os Forms das Modais
 * @extends {Container}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Label}
 * @extends {Row}
 * @extends {Wrapper}
 * @extends {BtnGroup}
 * @extends {Button}
 * @extends {colors}
 */
export const FormContainer = styled(Container)`
  p {
    font-weight: 700;
    color: ${colors.grey.darkPrimary};
    line-height: 1.3;
    margin-top: 0.5rem;
  }

  ${Label} {
    strong {
      color: ${colors.secondary};
    }
  }

  form {
    background-color: ${colors.grey.lightPrimary};
    padding: 1rem;

    ${Row} {
      ${Wrapper} {
        width: 100%;

        padding: 0 1rem;

        ${Label} {
          padding: 0 0 0.5rem 0;
        }
      }

      padding: 1rem 0;
    }
  }

  ${BtnGroup} {
    padding: 1rem 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    span {
      display: flex;
      flex-direction: row;
      color: ${colors.secondary};

      ${Button} {
        &:last-of-type {
          background-color: ${colors.primary};
          color: ${colors.default};
          text-decoration: none;
        }
      }
    }
  }
`;
