import React from 'react';

// Node
import ReactTooltip from 'react-tooltip';
import Icon from '@mdi/react';
import { mdiInformation } from '@mdi/js';

// node-modules
import ReactHtmlParser from 'react-html-parser';

// style
import { Content, Container } from './style';

const Tooltip = ({ text, size, dataId, icon }) => {
  return (
    <>
      <Container data-tip="" data-for={dataId}>
        {icon ? icon : <Icon size={size ? size : 1} path={mdiInformation} />}
      </Container>
      <ReactTooltip id={dataId} type={'dark'} place={'top'} effect={'solid'}>
        <Content>{ReactHtmlParser(text)}</Content>
      </ReactTooltip>
    </>
  );
};

export default Tooltip;
