import React, { Component } from 'react';
import { connect } from 'react-redux';

// Node
import {
  mdiArrowDown,
  mdiClose,
  mdiContentDuplicate,
  mdiFileSendOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import { toast } from 'react-toastify';

// actions
import {
  balconySelected,
  clearDeliveryOptions,
  deliverySelected,
  fetchCart,
  fetchDeliveryOptions,
  setSummary,
  updateCart,
} from '../../../actions/cart';
import { modalConfirm } from '../../../actions/modals';
import {
  clearPayment,
  clearPayments,
  setPayments,
} from '../../../actions/payment';

// services
import config from '../../../config';
import {
  duplicateItem,
  getToken,
  multiplyItem,
  removeItem,
} from '../../../services/cart';
import { resendFiles } from '../../../services/files';

// utils
import { numberFormat } from '../../../utils';
import { ERROR, REMOVE_ITEM_SUCCESS } from '../../../utils/constMessage';

// styles
import {
  Item,
  ItemContainer,
  ItemContent,
  ItemsContainer,
  ItemsHeader,
} from '../style';
import { colors } from '../../../styles/variables';

// options
import { productColumns } from '../options';

class Itens extends Component {
  state = {
    active: '',
  };

  handleDetails(active) {
    if (active === this.state.active) {
      this.setState({ active: '' });
    } else {
      this.setState({ active });
    }
  }

  handleResendFiles = async index => {
    let { itens } = this.props.cart.carrinho;
    await resendFiles(itens[index], index);

    this.props.onRedirect('/envio');
  };

  handleDuplicate = async index => {
    const { onUpdate, toggleLoading } = this.props;
    toggleLoading();
    const response = await duplicateItem(index);

    if (response.status === 200) {
      onUpdate({
        clearPayment: true,
        clearDelivery: true,
        resumo: response.data.data.resumo,
        pagamento: response.data.data.pagamento,
      });

      const token = getToken();
      await this.props.fetchCart(token).then(() => {
        this.props.fetchDeliveryOptions(token);
      });
      toggleLoading();
      toast.success(response.data.message);
    } else {
      toast.error(response.data.message);
    }
  };

  handleRemoveItem = async ({ index }) => {
    const { onUpdate } = this.props;
    const response = await removeItem(index);

    if (response.status === 200) {
      onUpdate({
        clearPayment: true,
        clearDelivery: true,
        resumo: response.data.data.resumo,
        pagamento: response.data.data.pagamento,
      });

      const token = getToken();
      await this.props.fetchCart(token).then(() => {
        this.props.fetchDeliveryOptions(token);
      });

      //toast.success(REMOVE_ITEM_SUCCESS);
    } else {
      window.location.href = '/';
    }

    toast.success(REMOVE_ITEM_SUCCESS);
  };

  handleMultiplyItem = async (index, quantidade) => {
    const { onUpdate } = this.props;
    const token = getToken();

    const response = await multiplyItem(index, quantidade);

    if (response.status === 200) {
      onUpdate({
        clearPayment: true,
        clearDelivery: true,
        resumo: response.data.data.resumo,
        pagamento: response.data.data.pagamento,
      });

      await this.props.updateCart(response.data);
      this.props.fetchDeliveryOptions(token);

      toast.success(response.data.message);
    } else {
      toast.error(ERROR);
    }
  };

  renderItem = (itens) => {
    return itens.map((item, index) => {
      const lombada =
        (item.pedidoItemAtributoAdicional &&
          item.pedidoItemAtributoAdicional.lomba) ||
        '';
      //const isRecompra = item.obsPedidoItem.some(observacaoPedido => observacaoPedido.obs_pedido_item === 'comprar novamente');
      const isRecompra = Array.isArray(item.obsPedidoItem) && item.obsPedidoItem.some(observacaoPedido => observacaoPedido.obs_pedido_item === 'comprar novamente');

      return (
        <div key={index}>
          <Item>
            <div>
              <Icon
                style={{ background: 'none', marginRight: '10px' }}
                size={1}
                path={mdiArrowDown}
                title={'detalhes'}
                onClick={() => this.handleDetails(index)}
              />
              <span style={{ textAlign: 'start', width: '90%' }}>
                {item.produto.descricao}
              </span>
            </div>
            <div>
              <span className={'title_action'}>Duplicar Item</span>
              <Icon
                  style={{ background: 'none' }}
                  path={mdiContentDuplicate}
                  title={'Duplicar Item'}
                  size={1}
                  onClick={() => this.handleDuplicate(index)}
                />
            </div>
            <div>
              <span className={'title_action'}>Reenviar Arquivos</span>
              {isRecompra ? (
                <Icon
                  style={{ opacity: 0.5, cursor: 'not-allowed', }}
                  path={mdiFileSendOutline}
                  title={'Não é possível reenviar arquivos na recompra'}
                  size={1}
                />
              ) : (

              <Icon
                  style={{ background: 'none' }}
                  path={mdiFileSendOutline}
                  title={'Reenviar Arquivos'}
                  size={1}
                  onClick={() => this.handleResendFiles(index)}
                />
                
              )}
              
            </div>

            <div>
              <span className={'title_action'}>Qtd.</span>
              {item.qtd_pedido_item}
            </div>

            <div>
              <span className={'title_action'}>Vlr. Un</span>
              R$ {numberFormat(item.vlr_pedido_item)}
            </div>
            <div>
              <span className={'title_action'}>Vlr. Total</span>
              R$ {numberFormat(item.vlr_total_item)}
            </div>
            <div>
              <span className={'title_action'}>Excluir</span>
              <Icon
                style={{ background: 'none' }}
                onClick={() =>
                  this.props.modalConfirm({
                    callback: this.handleRemoveItem,
                    data: {
                      index,
                    },
                    title: 'Confirmação de exclusão',
                    text: `Tem certeza que deseja remover o produto <strong>${item.produto.descricao}</strong> do seu carrinho de compras?`,
                  })
                }
                path={mdiClose}
                title={'excluir'}
                size={1}
              />
            </div>
          </Item>
          <ItemContent
            className={this.state.active === index ? 'active' : 'hidden'}
          >
            <ul>
              {Object.values(item.dependencias).map((dependencia, index) => {
                if (
                  item.produto.fk_tipo_produto === config.TYPE_BOOK &&
                  dependencia.fk_atributo === 1
                ) {
                  dependencia.texto_atributo = 'Papel da Capa';
                }

                if (
                  item.qtd_pedido_item === 1 &&
                  dependencia.fk_atributo === 3 &&
                  item.produto.fk_tipo_produto === 7
                ) {
                  return '';
                }

                return (
                  <li key={index}>
                    <span>{`${dependencia.texto_atributo} - ${dependencia.texto_aux_dependencia_atributo}`}</span>
                  </li>
                );
              })}
              {item.produto.fk_tipo_produto === config.TYPE_BOOK &&
              item.pedidoItemAtributoAdicional &&
              item.pedidoItemAtributoAdicional.dependenciaAtributo ? (
                <li>
                  <span>{`Papel do Miolo - ${item.pedidoItemAtributoAdicional.dependenciaAtributo.texto_dependencia_atributo}`}</span>
                </li>
              ) : (
                ''
              )}
              {Object.values(item.acabamentos).map((acabamento, index) => {
                return (
                  <li key={index}>
                    <span>
                      {acabamento.desc_acabamento}{' '}
                      {acabamento.vlr_item_pedido_acabamento > 0
                        ? ` - R$ ${numberFormat(
                            acabamento.vlr_item_pedido_acabamento
                          )}`
                        : ''}
                      {acabamento.qtd_dias_acabamento > 0
                        ? acabamento.qtd_dias_acabamento > 1
                          ? ` (+ ${acabamento.qtd_dias_acabamento} dias)`
                          : ` (+ ${acabamento.qtd_dias_acabamento} dia)`
                        : ''}
                    </span>
                  </li>
                );
              })}
              {lombada ? (
                <li>
                  <span>
                    Cálculo lombada aproximada: {numberFormat(lombada, 2)}mm
                  </span>
                </li>
              ) : (
                ''
              )}
              <li>
                <span>Produto - R$ {numberFormat(item.vlr_pedido_item)}</span>
              </li>
            </ul>
          </ItemContent>
        </div>
      );
    });
  };

  render() {
    const { itens } = this.props.cart.carrinho;

    if (!itens) {
      return '';
    }

    return (
      <ItemsContainer>
        <ItemsHeader>
          {productColumns.map((title, i) => {
            return <div key={i}>{title}</div>;
          })}
        </ItemsHeader>
        <ItemContainer>{this.renderItem(itens)}</ItemContainer>
      </ItemsContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart.data,
    cupom: state.cart.data.resumo,
  };
};

Itens = connect(mapStateToProps, {
  fetchCart,
  setSummary,
  setPayments,
  modalConfirm,
  clearPayments,
  clearPayment,
  clearDeliveryOptions,
  fetchDeliveryOptions,
  balconySelected,
  deliverySelected,
  updateCart,
})(Itens);

export default Itens;
