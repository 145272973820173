import React, { useMemo } from 'react';
import { connect } from 'react-redux';

// Node
import Icon from '@mdi/react';
import { mdiAlertOutline } from '@mdi/js';

// components
import VirtualKeyboard from '../../VirtualKeyboard';
import Tooltip from '../../Tooltip';

// actions
import { updateTwoCards } from '../../../actions/payment';

// utils
import { numberFormat, slugify } from '../../../utils';
import { INVALID_CARD } from '../../../utils/constMessage';

// services
import { validateCard } from '../../../services/payment';

// styles
import { Wrapper, Input, Select, Option, Label } from '../../../styles/forms';
import {
  WrapperParcels,
  WrapperDate,
  ListCards,
  ContainerNumberCard,
} from '../style';
import {
  LessMarginLabel,
  WrapperCvcInstallments,
  WrapperExpirationDate,
  FirstInstallmentsMessage,
} from './style';

const brands = (payment, cardType, flagActive) => {
  if (!payment) {
    return '';
  }

  let cards;
  if (cardType === 'firstCard') {
    cards = payment.twoCards.installments.primeiroCartao.formasPagamento.filter(
      pagamento => pagamento.pk_tipo_pagamento === 3
    );
  } else {
    cards = payment.twoCards.installments.segundoCartao.formasPagamento.filter(
      pagamento => pagamento.pk_tipo_pagamento === 3
    );
  }
  return (
    <ListCards>
      <ul>
        {cards[0].dependencia_tipo_pagamento.map((item, i) => {
          return (
            <li
              key={i}
              className={`${slugify(
                item.desc_dependencia_tipo_pagamento
              )} card-list ${
                slugify(item.desc_dependencia_tipo_pagamento) === flagActive &&
                payment.twoCards[cardType].dependency ===
                  item.pk_dependencia_tipo_pagamento
                  ? 'active'
                  : 'disabled'
              }`}
            ></li>
          );
        })}
      </ul>
    </ListCards>
  );
};

function CardForm(props) {
  const {
    dependencia_tipo_pagamento,
    hashPayment,
    payment,
    firstCard = false,
    updateTwoCards,
    disabled = false,
  } = props;
  const cardType = firstCard ? 'firstCard' : 'secondCard';
  const cardNumber = payment.twoCards[cardType].numero_cartao
  const oppositeCardType = firstCard ? 'secondCard' : 'firstCard';

  const flagActive = useMemo(
    () => slugify(validateCard(payment.twoCards[cardType].numero_cartao).flag),
    [cardNumber]
  );

  // mesmo aplicado o Crédito (saldo), no caso de dois cartões, o correto é
  // pegar as parcelas de .parcela
  const parcelas = disabled ? [] : dependencia_tipo_pagamento[0].parcela;

  const invalidCardNumber = useMemo(
    () => !flagActive && payment.twoCards[cardType].numero_cartao.length > 20,
    [payment.twoCards, flagActive, cardType]
  );

  const handleNumberCard = (value, cardType) => {
    const card = validateCard(value);

    updateTwoCards(cardType, 'dependency', card.dependency);

    if (card.number.length <= 20) {
      updateTwoCards(cardType, 'numero_cartao', card.number);
    }
  };

  const handleChangeInstallment = e => {
    const installment = e.target.value;
    updateTwoCards(cardType, 'num_parcelas', installment);

    // se for selecionado 1x em alguma parcela com Dois Cartões, as duas parcelas devem ser 1x
    // eslint-disable-next-line
    if (installment == '1') {
      updateTwoCards(oppositeCardType, 'num_parcelas', 1);
      return;
    }
    // eslint-disable-next-line
    if (payment.twoCards[oppositeCardType].num_parcelas == 1) {
      updateTwoCards(oppositeCardType, 'num_parcelas', 2);
      return;
    }
  };

  const fields = {
    number: `numero_cartao_${cardType}`,
    name: `nome_titular_${cardType}`,
    month: `mes_${cardType}`,
    year: `ano_${cardType}`,
    cvc: `cvc_${cardType}`,
    installments: `parcela_${cardType}`,
  };

  if (!hashPayment) {
    return '';
  }

  return (
    <>
      <ContainerNumberCard>
        <Wrapper>
          <LessMarginLabel htmlFor={fields.number}>
            Número do Cartão
          </LessMarginLabel>

          <Input
            id={fields.number}
            className={invalidCardNumber ? 'invalid' : ''}
            onChange={e => handleNumberCard(e.target.value, cardType)}
            value={payment.twoCards[cardType].numero_cartao}
            disabled={disabled}
          />
          {invalidCardNumber ? (
            <div className="text-error">
              <Tooltip
                text={INVALID_CARD}
                dataId={'invalidCard'}
                icon={<Icon path={mdiAlertOutline} title={'error'} size={1} />}
              />
            </div>
          ) : (
            ''
          )}
        </Wrapper>
        {!disabled && brands(payment, cardType, flagActive)}
      </ContainerNumberCard>
      <Wrapper>
        <LessMarginLabel htmlFor={fields.name}>Nome do titular</LessMarginLabel>
        <Input
          id={fields.name}
          onChange={e => updateTwoCards(cardType, 'portador', e.target.value)}
          disabled={disabled}
          value={payment.twoCards[cardType].portador}
        />
      </Wrapper>
      <WrapperExpirationDate>
        <WrapperDate>
          <LessMarginLabel htmlFor={fields.month}>Mês</LessMarginLabel>
          <Select
            id={fields.month}
            defaultValue={0}
            onChange={e => updateTwoCards(cardType, 'mes', e.target.value)}
            disabled={disabled}
          >
            {Object.entries(hashPayment.mes).map((item, i) => {
              return (
                <Option
                  key={i}
                  value={item[0]}
                  disabled={item[0] === '0' ? true : false}
                >
                  {item[1]}
                </Option>
              );
            })}
          </Select>
        </WrapperDate>
        <WrapperDate>
          <LessMarginLabel htmlFor={fields.year}>Ano</LessMarginLabel>
          <Select
            id={fields.year}
            defaultValue={0}
            onChange={e => updateTwoCards(cardType, 'ano', e.target.value)}
            disabled={disabled}
          >
            {Object.entries(hashPayment.ano).map((item, i) => {
              return (
                <Option
                  key={i}
                  value={item[0]}
                  disabled={item[0] === '0' ? true : false}
                >
                  {item[1]}
                </Option>
              );
            })}
          </Select>
        </WrapperDate>
      </WrapperExpirationDate>
      <WrapperCvcInstallments>
        <VirtualKeyboard cardType={cardType} />
        <WrapperParcels>
          <Label htmlFor={fields.installments}>Parcelas</Label>
          <Select
            id={fields.installments}
            disabled={!flagActive ? true : false}
            onChange={handleChangeInstallment}
            value={payment.twoCards[cardType].num_parcelas}
          >
            {parcelas &&
              parcelas.map((parcela, i) => {
                return (
                  <Option value={`${i + 1}`} key={i}>
                    {`${i + 1}x de R$ ${numberFormat(parcela.valor, 2)}`}
                    {parcela.juros && parcela.juros !== 0
                      ? ` (com juros de R$ ${numberFormat(parcela.juros, 2)})`
                      : ''}
                  </Option>
                );
              })}
          </Select>
        </WrapperParcels>
      </WrapperCvcInstallments>
      {/* eslint-disable-next-line */}
      {payment.twoCards[cardType].num_parcelas == 1 && parcelas.length > 0 && (
        <FirstInstallmentsMessage>
          Para <strong>Crédito à vista</strong>, selecionar <strong>1x</strong>{' '}
          em ambos os cartões
        </FirstInstallmentsMessage>
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    multiplePayments: state.payment.multiplePayments,
  };
};

export default connect(mapStateToProps, {
  updateTwoCards,
})(CardForm);
