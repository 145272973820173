import React, { Component } from 'react';
import { connect } from 'react-redux';
import { submit, reduxForm } from 'redux-form';

// Node
import { toast } from 'react-toastify';

// hocs
import Modal from '../../hocs/Modal';

// actions
import { modalFilesAuth } from '../../actions/modals';
import { filesAuth, isSubmitForm } from '../../actions/files';

// Utils
import { SUCCESS, AUTH_FILE } from '../../utils/constMessage';

// styles
import { FilesAuthContainer } from './style';
import { Col } from '../../styles/align';
import {
  Wrapper,
  BtnGroup,
  Button,
  ButtonSecondary,
  Label,
} from '../../styles/forms';

class FilesAuth extends Component {
  state = {
    message: '',
  };

  componentWillUnmount() {
    this.props.isSubmitForm(false);
  }

  onSubmit = async () => {
    const { dispatch, isSubmitFiles } = this.props;
    if (this.state.message.length < 2) {
      toast.warn(AUTH_FILE);
      return false;
    }
    await this.props.filesAuth(this.state.message);
    this.props.modalFilesAuth();

    toast.success(SUCCESS);
    if (isSubmitFiles) {
      dispatch(submit('sendFiles'));
    }
  };

  render() {
    return (
      <FilesAuthContainer>
        <Col>
          <h4>
            Alguns arquivos dos seu produto não foram anexados. Gostaria de
            enviar mesmo assim?
          </h4>
          <Wrapper>
            <Label htmlFor={'mensagem'}>
              Digite sua autorização para impressão
              <strong>*</strong>
            </Label>
            <textarea
              id={'mensagem'}
              onChange={e => this.setState({ message: e.target.value })}
              value={this.state.message}
            ></textarea>
          </Wrapper>
        </Col>
        <BtnGroup row>
          <span className={'required'}>* Campos Obrigatórios</span>
          <span>
            <Button onClick={this.props.modalFilesAuth}>Cancelar</Button>
            <ButtonSecondary type={'submit'} onClick={() => this.onSubmit()}>
              Enviar
            </ButtonSecondary>
          </span>
        </BtnGroup>
      </FilesAuthContainer>
    );
  }
}

const mapStateToProps = (state, algo) => {
  return {
    isSubmitFiles: state.files.isSubmitForm,
  };
};

FilesAuth = connect(mapStateToProps, {
  modalFilesAuth,
  filesAuth,
  isSubmitForm,
})(FilesAuth);

FilesAuth = reduxForm({
  form: 'filesAuth',
})(FilesAuth);

FilesAuth = Modal(FilesAuth);

export default FilesAuth;
