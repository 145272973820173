import loja from '../apis/loja';
import { onlyNumbers } from '../utils';
import _ from 'lodash';

/**
 * Ação para cadastrar o endereço
 */
export const createAddress = async (data, numberAddress) => {
  // setup
  let dataSubmit = {
    ...data,
    valida_cpf_cnpj: true,
  };
  dataSubmit.cpf_cnpj_usuario = onlyNumbers(dataSubmit.cpf_cnpj_usuario);
  dataSubmit.fk_tipo_usuario = dataSubmit.cpf_cnpj_usuario.length > 11 ? 1 : 2;
  dataSubmit.cep_usuario_endereco = dataSubmit.cep_usuario_endereco.replace(
    /\./g,
    ''
  );
  if (numberAddress) {
    dataSubmit.default_usuario_endereco = dataSubmit.default_usuario_endereco
      ? 1
      : 0;
  } else {
    dataSubmit.default_usuario_endereco = 1;
  }
  dataSubmit = _.omit(dataSubmit, 'pk_usuario_endereco');
  try {
    const response = await loja.post('/endereco/salvar', dataSubmit);
    return response;
  } catch (error) {
    if (error.response) {
      return { errors: error.response.data };
    }
    return { error: 'CONNECTION_ERROR' };
  }
};

/**
 * Ação para alterar o endereço
 */
export const updateAddress = async data => {
  // setup
  let dataSubmit = { ...data, valida_cpf_cnpj: true };
  dataSubmit.cpf_cnpj_usuario = onlyNumbers(dataSubmit.cpf_cnpj_usuario);
  dataSubmit.fk_tipo_usuario = dataSubmit.cpf_cnpj_usuario.length > 11 ? 1 : 2;
  dataSubmit.cep_usuario_endereco = dataSubmit.cep_usuario_endereco.replace(
    /\./g,
    ''
  );
  dataSubmit.default_usuario_endereco = dataSubmit.default_usuario_endereco
    ? 1
    : 0;
  try {
    const response = await loja.put('/endereco/salvar', dataSubmit);
    return response;
  } catch (error) {
    if (error.response) {
      return { errors: error.response.data };
    }
    return { error: 'CONNECTION_ERROR' };
  }
};

/**
 * Ação para remover o endereço
 */
export const removeAddress = async pk_usuario_endereco => {
  try {
    const response = await loja.post(
      `/endereco/remover?pk_usuario_endereco=${pk_usuario_endereco}`
    );
    return response;
  } catch (error) {
    return { error: 'CONNECTION_ERROR' };
  }
};

/**
 * Ação para buscar o cpf nos correios
 */
export const buscarCep = async cep => {
  cep = onlyNumbers(cep);
  try {
    const response = await loja.get(`/endereco/buscar-cep?cep=${cep}`);
    return response;
  } catch (error) {
    if (error.response) {
      return { errors: error.response.data };
    }
    return { error: 'CONNECTION_ERROR' };
  }
};

/**
 * Ação para tornar o endereço padrão
 */
export const tornarPadrao = async pk_usuario_endereco => {
  try {
    const response = await loja.get(
      `/endereco/definir-padrao?pk_usuario_endereco=${pk_usuario_endereco}`
    );
    return response;
  } catch (error) {
    return { error: error.response.data.message };
  }
};
