import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Node
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';

// actions
import { fetchRedirect } from '../../actions/redirect';

// services
import { returnEditor } from '../../services/files';

//styles
import { ContainerLoading } from '../../styles/loader';
import TagManager from 'react-gtm-module';

class Redirect extends Component {
  componentDidMount() {
    const hashJob = this.props.match.params.hash_job;
    this.props.fetchRedirect(hashJob).then(async result => {
      if (
        typeof this.props.response === 'undefined' ||
        typeof this.props.response.resposta === 'undefined'
      ) {
        // caso tenha dado erro (caido no catch) no fetchRedirect, volta para a tela de envio
        this.props.history.push('/envio');
        return;
      }

      // this.props.response pode chegar com algum erro do retorno (Timeout API ou Edição descatada pelo usuário)
      if (_.isString(this.props.response.resposta)) {
        // Removido conforme a resposta do backend, pois o mesmo não retorna mais o erro.
        //toast.warn(this.props.response.resposta);
        if (this.props.response.payload.rota_erro) {
          this.props.history.push(this.props.response.payload.rota_erro);
        } else {
          this.props.history.push('/envio');
        }
        return;
      }

      // Faz o tratamento do retorno do '/redirecionar' que é rodado no backend dentro da rota '/editor/retorno'
      const response = returnEditor(this.props.response);

      if (response) {
        if (response.message !== '') {
          toast[response.status](response.message);
        }
        if (response.redirect) {
          if(this.props.response.data && this.props.response.resposta.data.carrinho &&this.props.response.resposta.data.carrinho.itens) {
          const product = this.props.response.resposta.data.carrinho.itens.find(
            item => item.itemEditor.hash_editor === hashJob
          );
          if (product) {
            const { vlr_total_item, qtd_pedido_item } = product;
            const { desc_categoria, pk_produto } = product.produto;
            TagManager.dataLayer({
              dataLayer: {
                event: 'add_to_cart',
                ecommerce: {
                  items: {
                    item_name: desc_categoria,
                    item_id: pk_produto,
                    price: vlr_total_item,
                    quantity: qtd_pedido_item,
                  },
                },
              },
            });
          }
        }

          this.props.history.push(response.redirect);
        } else {
          this.props.history.push('/envio');
        }
      } else {
        this.props.history.push('/envio');
      }
    });
  }

  render() {
    return (
      <ContainerLoading>
        <Loader />
      </ContainerLoading>
    );
  }
}

const mapStateToProps = state => {
  return {
    response: state.redirect.response,
  };
};

export default connect(mapStateToProps, { fetchRedirect })(Redirect);
