import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, reduxForm } from 'redux-form';

import { isCreateOnline } from '../../actions/files';
// actions
import { modalEditor, modalLogin } from '../../actions/modals';
// components
import Loader from '../../components/Loader';
// hocs
import Modal from '../../hocs/Modal';
// services
import { createFiles } from '../../services/files.js';
import { isAuthenticated } from '../../services/user';
// shared
import { IconCreateFiles, IconSendFiles } from '../../shared/icons';
// Styles
import { ContainerLoading } from '../../styles/loader';
import { Container } from '../style';
import { Btn, Option, OptionsFiles } from './style';

class Editor extends Component {
  state = {
    loading: false,
  };

  handleCreateFiles = () => {
    if (!isAuthenticated()) {
      this.props.modalEditor();
      this.props.isCreateOnline(true);
      this.props.modalLogin();
    } else {
      this.setState({ loading: true });
      createFiles(this.props.data.arquivos);
    }
  };

  componentDidMount() {
    this.props.isCreateOnline(false);
  }

  componentWillUnmount() {
    this.setState({ loading: false });
  }

  render() {
    const { handleSubmit } = this.props;

    if (this.state.loading) {
      return (
        <ContainerLoading>
          <Loader />
          <p>
            Aguarde um instante. Estamos preparando o ambiente de criação...
          </p>
        </ContainerLoading>
      );
    }

    return (
      <Container>
        <Form onSubmit={handleSubmit(this.handleCreateFiles)}>
          <OptionsFiles>
            <Option
              type={'button'}
              onClick={() => {
                this.props.isCreateOnline(false);
                this.props.modalEditor();
              }}
            >
              <IconSendFiles />
              <Btn>Enviar Arte</Btn>
              <p>
                Quero enviar minha arte
                <br /> finalizada e dentro dos padrões
                <br /> para impressão.
              </p>
            </Option>

            <Option type={'submit'}>
              <IconCreateFiles />
              <Btn>Criar Online</Btn>
              <p>
                Desejo criar minha arte
                <br /> no editor Online.
              </p>
            </Option>
          </OptionsFiles>
        </Form>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.files.data,
  };
};

Editor = reduxForm({ form: 'createOnline' })(Editor);
Editor = connect(mapStateToProps, {
  modalEditor,
  modalLogin,
  isCreateOnline,
})(Editor);

Editor = Modal(Editor);

export default Editor;
