import styled from 'styled-components';

// variables
import { colors, fontSize } from '../../styles/variables';


export const Card = styled.div`
    width: 100%;
	height: ${props => (props.height ? props.height : 'fit-content')}
	background-color: ${colors.grey.lightPrimary};
	border-bottom: ${props =>
    props.border ? `1px dashed ${colors.grey.darkSecondary}` : 'none'};
    text-align: ${props => (props.textalign ? props.textalign : 'left')};
    padding: ${props => (props.padding ? '1rem' : '1.5rem 0 1rem 0')};
    margin: ${props => (props.margin ? props.margin : '0')};

    h3 {
        font-size: ${fontSize.md};
        color: ${colors.primary};
        font-weight: 600;
        margin-bottom: 1rem;
        text-transform: uppercase;
        text-align: center;
    }

    p {
        font-size: ${fontSize.sm};
        line-height: 1.5;

        strong {
            text-transform: uppercase;
        }

        &.title__info {
            color: ${colors.primary};
            font-size: ${fontSize.md};
            text-transform: uppercase;
        }

        &.feature,
        &.price {
            color: ${colors.secondary};
            font-weight: 600;
            text-transform: uppercase;
        }
    }

    & > ul {
        list-style: none;

        li {
            margin-bottom: 1rem;
            display: flex;
            flex-direction: column;

            span {
                line-height: 1.5;
                font-size: ${fontSize.sm};

                &:first-of-type {
                    color: ${colors.secondary};
                    margin-bottom: .5rem;
                }
            }
        }
    }

    @media (max-width: 768px) {
		margin-bottom: 1rem;
	}
`;