import React, { Component } from 'react';
import { connect } from 'react-redux';

// Node
import { toast } from 'react-toastify';

// hocs
import Modal from '../../hocs/Modal';

// Actions
import {
  clearCities,
  fetchAddress,
  fetchAddresses,
  fetchCities,
  fetchFiltredAddresses,
  fetchStates,
} from '../../actions/address';
import {
  balconySelected,
  clearDeliveryOptions,
  deliverySelected,
  fetchDeliveryOptions,
} from '../../actions/cart';
import { modalListAddress, modalRegisterAddress } from '../../actions/modals';

// services
import { removeAddress, tornarPadrao } from '../../services/address';
import { getToken } from '../../services/cart';

// utils
import { CONNECTION_ERROR } from '../../utils/constMessage';

// Styles
import { Col } from '../../styles/align';
import {
  BtnGroup,
  Button,
  ButtonPrimary,
  Input,
  Label,
  Radio,
  Select,
  Wrapper,
} from '../../styles/forms';
import {
  FilterTrashIcon,
  ListAddress,
  ListAddressActions,
  ListAddressContainer,
  ListAddressForm,
  RemoveAddressButton,
  RemoveTrashIcon,
  ContainerNewEdit
} from './style';

class Address extends Component {
  async componentDidMount() {
    await this.props.fetchAddresses();
    this.props.fetchStates();

    if(!this.props.hasBillingAddress && this.props.isCompany) {
      this.props.modalRegisterAddress();
      this.props.modalListAddress();
    }
  }

  componentWillUnmount() {
    this.props.clearCities();
  }

  state = {
    isOpenRegisterAddress: false,
    state: '0',
    city: '0',
    descAddress: '',
    selected: null,
    showActions: false,
  };

  handleStateChange(e) {
    const { value } = e.target;
    let objState = { state: value };
    if (value === '0') {
      this.props.clearCities();
      objState = { city: '0' };
      this.setState({ state: value });
    } else {
      this.props.fetchCities(value);
    }

    this.setState(objState);
  }

  handleEditarButton = async () => {
  const { addresses, fetchAddress, modalRegisterAddress } = this.props;
  const { selected } = this.state;
  const selectedAddress = addresses && addresses.find(address => address.pk_usuario_endereco === selected);

  if (selectedAddress) {
    const { pk_usuario_endereco } = selectedAddress;
    await fetchAddress(pk_usuario_endereco);
    modalRegisterAddress();
  }
}


  handleNovoButton() {
    this.props.modalRegisterAddress();
  }

  handleBuscarButton() {
    this.props.fetchFiltredAddresses(
      this.state.state,
      this.state.city,
      this.state.descAddress
    );
  }

  handleClearFilter = () => {
    this.setState({
      descAddress: '',
      state: '0',
      city: '0',
    });
    this.props.fetchAddresses();
  };

  handleSelecionar = () => {
  const selectedAddress = this.props.addresses.find(address => address.pk_usuario_endereco === this.state.selected);

  if (!selectedAddress) {
    console.log("Erro: Endereço selecionado não encontrado");
    return;
  }

  const pk_usuario_endereco = selectedAddress.pk_usuario_endereco;

  this.props.balconySelected({
    balconySelected: false,
    balconyType: false,
  });

  this.props.deliverySelected({
    deliverySelected: false,
    deliveryType: false,
  });

  this.props.clearDeliveryOptions();
  this.props.fetchDeliveryOptions(getToken(), pk_usuario_endereco);
  this.props.modalListAddress();
}


onTornarPadraoSubmit = async () => {
  if (!this.props.addresses || !this.state.selected) return;

  console.log(this.props.addresses)

  const selectedAddress = this.props.addresses.find(
    address => address.pk_usuario_endereco === this.state.selected
  );


  const { pk_usuario_endereco } = selectedAddress;

  try {
    const response = await tornarPadrao(pk_usuario_endereco);

    if (response.status === 200) {
      await this.props.fetchAddresses();
      this.setState({ selected: '' });
      toast.success('Endereço alterado com sucesso!');
    } else {
      throw new Error();
    }
  } catch (error) {
    toast.error(CONNECTION_ERROR);
  }
}


onRemoveSubmit = async () => {
  const { addresses, fetchAddresses } = this.props;
  const { selected } = this.state;

  const addressToRemove = addresses.find(address => address.pk_usuario_endereco === selected);

  const pk_usuario_endereco = addressToRemove.pk_usuario_endereco;

  try {
    const response = await removeAddress(pk_usuario_endereco);

    if (response.status === 200) {
      await fetchAddresses();
      toast.success('Endereço removido com sucesso!');
    } else {
      toast.error(CONNECTION_ERROR);
    }
  } catch (error) {
    toast.error('Erro ao remover endereço!');
  }
}

  renderStates() {
    const { states } = this.props;
    if (!states) {
      return '';
    }
    return (
      <>
        {Object.values(states).map(state => {
          return (
            <option key={state.pk_estado} value={state.pk_estado}>
              {state.desc_estado}
            </option>
          );
        })}
      </>
    );
  }

  renderCities() {
    const { cities } = this.props;
    if (!cities) {
      return '';
    }
    return (
      <>
        {Object.values(cities).map(city => {
          return (
            <option key={city.pk_cidade} value={city.pk_cidade}>
              {city.desc_cidade}
            </option>
          );
        })}
      </>
    );
  }

 renderAddresses() {
    const { addresses } = this.props;

    if (!addresses) {
      return '';
    }

    return (
      <ListAddressContainer>
        {Object.values(addresses).map((address, index) => {
          return (
            <Col key={index}>
              <Wrapper>
                <Radio
                    name="endereco"
                    id={`endereco[${address.pk_usuario_endereco}]`}
                    onChange={() => this.setState({ selected: address.pk_usuario_endereco })}
                    value={address.pk_usuario_endereco}
                    checked={this.state.selected === address.pk_usuario_endereco}
                  />
                <span></span>
                <Label htmlFor={`endereco[${address.pk_usuario_endereco}]`}>
                  {address.desc_usuario_endereco}
                  <strong>
                    {address.default_usuario_endereco ? `Padrão ${!!address.faturamento ? '& ' : ''}` : ''}
                    {!!address.faturamento ? 'Faturamento' : ''}
                  </strong>
                </Label>
              </Wrapper>
              <p>{address.logradouro_usuario_endereco}</p>
              <p>{address.bairro_usuario_endereco}</p>
              {address.complemento_usuario_endereco ? (
                <p>Complemento: {address.complemento_usuario_endereco}</p>
              ) : (
                ''
              )}
              <p>
                {address.desc_cidade} - {address.desc_estado}
              </p>
              <p>CEP: {address.cep_usuario_endereco}</p>
            </Col>
          );
        })}
      </ListAddressContainer>
    );
  }

  renderActions() {
  const selectedAddress = this.getSelectedAddress();
  const isDefault = this.isDefaultAddress(selectedAddress);
  const isCart = this.isCartPage();

  return (
    <ListAddressActions>
      {isDefault && this.renderDefaultActions()}
      {this.renderCommonActions(selectedAddress, isCart)}
    </ListAddressActions>
  );
}

getSelectedAddress() {
  const { addresses } = this.props;
  const { selected } = this.state;
  return addresses ? addresses.find(address => address.pk_usuario_endereco === selected) : undefined;
}

isDefaultAddress(selectedAddress) {
  return selectedAddress && !selectedAddress.default_usuario_endereco;
}

isCartPage() {
  return window.location.pathname === '/carrinho';
}

renderDefaultActions() {
  const { addresses } = this.props;
  const moreThanOneAddress = addresses && Object.keys(addresses).length > 1;
  const selectedAddress = this.getSelectedAddress()

  if(selectedAddress && selectedAddress.faturamento === 1) {
    return (
      <RemoveAddressButton>
      <BtnGroup>
        <Button onClick={this.onTornarPadraoSubmit} >
          Tornar Padrão
        </Button>
      </BtnGroup>
    </RemoveAddressButton>
    )
  }

  return moreThanOneAddress ? (
    <RemoveAddressButton>
      <BtnGroup>
        <Button onClick={this.onTornarPadraoSubmit} >
          Tornar Padrão
        </Button>
        <Button onClick={this.onRemoveSubmit} className='secondary'>
          <RemoveTrashIcon title={'Excluir endereço'} />
        </Button>
      </BtnGroup>
      
    </RemoveAddressButton>
  ) : null;
}

renderCommonActions(selectedAddress, isCart) {
  const isSelected = !!selectedAddress;
  if(selectedAddress && !!selectedAddress.faturamento) return
  return (
    <ContainerNewEdit>
      <BtnGroup>
      <Button secondary onClick={this.props.modalRegisterAddress}>
        Novo
      </Button>
      {isSelected && (
        <Button onClick={this.handleEditarButton} className="secondary">
          Editar
        </Button>
      )}
      {isSelected && isCart && (
        <Button onClick={this.handleSelecionar} secondary>
          Selecionar
        </Button>
      )}
    </BtnGroup>
    </ContainerNewEdit>
  );
}

  render() {
    return (
      <>
        <ListAddress>
          <ListAddressForm>
            <Wrapper>
              <Label htmlFor={'pk_estado'}>Estado</Label>
              <Select
                name="pk_estado"
                id={'pk_estado'}
                onChange={e => this.handleStateChange(e)}
              >
                <option value="0">Selecione um Estado</option>
                {this.renderStates()}
              </Select>
            </Wrapper>
            <Wrapper>
              <Label htmlFor={'pk_cidade'}>Cidade</Label>
              <Select
                onChange={e => this.setState({ city: e.target.value })}
                name="pk_cidade"
              >
                <option value="0">Selecione uma Cidade</option>
                {this.renderCities()}
              </Select>
            </Wrapper>
            <Wrapper justify="flex-end" margin={'0 0 .5rem 0'}>
              <Input
                name="nome_ou_cep"
                value={this.state.descAddress}
                onChange={e => this.setState({ descAddress: e.target.value })}
                placeholder={'Filtro por Nome/Cep'}
                onKeyUp={e =>
                  e.keyCode === 13 ? this.handleBuscarButton() : ''
                }
              />
            </Wrapper>
            <BtnGroup>
              <ButtonPrimary onClick={() => this.handleBuscarButton()} primary>
                Buscar
              </ButtonPrimary>
              <Button onClick={() => this.handleClearFilter()} className='secondary'>
                <FilterTrashIcon  title={'Excluir endereço'} onClick={this.handleClearFilter}/>
              </Button>
            </BtnGroup>
          </ListAddressForm>
          <h3>Endereços Cadastrados</h3>
          {this.renderAddresses()}
          {this.renderActions()}
        </ListAddress>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    addresses: state.address.listAddress,
    states: state.address.states,
    cities: state.address.cities,
    hasBillingAddress: state.address.address.has_billing_address,
    isCompany: state.user.user.fk_tipo_usuario === 1
  };
};

const actionCreators = {
  fetchAddresses,
  fetchFiltredAddresses,
  fetchAddress,
  fetchStates,
  fetchCities,
  clearCities,
  fetchDeliveryOptions,
  modalRegisterAddress,
  modalListAddress,
  balconySelected,
  deliverySelected,
  clearDeliveryOptions,
};

Address = connect(mapStateToProps, actionCreators)(Address);

Address = Modal(Address);

export default Address;
