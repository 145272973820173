import React, { Component } from 'react';
import { connect } from 'react-redux';

// Skeleton
import SkeletonCarousel from '../../components/Carousel/skeleton';
import SkeletonProducts from '../../components/Products/skeleton';

// components
import Feature from '../../components/Cards/Feature';
import Option from '../../components/Cards/Option';
import Carousel from '../../components/Carousel';
import Products from '../../components/Products';
import Register from '../../modals/Register/';

// actions
import { fetchFeaturedProducts, fetchPubsites } from '../../actions/home';

// Seo
import Seo from '../../helpers/Seo';

// styles
import { Col, Container, Section } from '../../styles/align';

// options
import { features, seo, services } from './option';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRegisterModal: false,
    };
  }

  componentDidMount() {
    const userData = localStorage.getItem('usuario');
    const user = userData ? JSON.parse(userData) : null;

    if (
      user &&
      (user.icms_st === null ||
        user.comercializacao === null ||
        user.industrializacao === null ||
        user.consumidor_final === null)
    ) {
      this.setState({
        showRegisterModal: true,
      });
    }

    if (!this.props.carousel.data) {
      this.props.fetchPubsites();
    }

    if (!this.props.featuredProducts.data) {
      this.props.fetchFeaturedProducts();
    }
  }

  onSubmitModal = () => {
    this.setState({ showRegisterModal: false });
  };

  renderCarousel = () => {
    const carousel = this.props.carousel;
    if (!carousel.data) {
      return <SkeletonCarousel />;
    }

    return (
      <Section>
        <Carousel data={carousel.data} />
      </Section>
    );
  };

  renderFeature = () => {
    return <Feature title={features.title} data={features.data} />;
  };

  renderFeaturedProducts = () => {
    const featuredProducts = this.props.featuredProducts;

    if (!featuredProducts.data) {
      return <SkeletonProducts title={true} />;
    }

    return (
      <>
        {featuredProducts.data.map((category, index) => {
          if (!category.products.length) {
            return '';
          }
          return (
            <Products
              key={index}
              data={category.products}
              title={category.title}
              base={category.url_base}
            />
          );
        })}
      </>
    );
  };

  renderServices = () => {
    return (
      <Container>
        <Col>
          <Option
            data={services.data}
            title={services.title}
            desc={services.desc}
          />
        </Col>
      </Container>
    );
  };

  render() {
    const { showRegisterModal } = this.state;

    return (
      <>
        <Seo props={seo} />
        <Container>
          <Col>
            {this.renderCarousel()}
            {this.renderFeaturedProducts()}
            <Section>{this.renderFeature()}</Section>
            {/* <Section>{this.renderServices()}</Section> */}
            <Section>
              {showRegisterModal && (
                <Register
                  width={'40'}
                  title={'Cadastro'}
                  show={showRegisterModal}
                  closeRegisterModal={this.closeRegisterModal}
                />
              )}
            </Section>
          </Col>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    carousel: state.carousel,
    featuredProducts: state.featuredProducts,
  };
};

export default connect(mapStateToProps, {
  fetchPubsites,
  fetchFeaturedProducts,
})(Home);
