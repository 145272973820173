import styled from 'styled-components';

// Styles
import { Row } from '../../styles/align';
import { colors, fontSize } from '../../styles/variables';
import { Button } from '../../styles/forms';

/**
 * Formata o Container da Modal Editor
 *
 * @extends {Row}
 */
export const OptionsFiles = styled(Row)`
  justify-content: space-between;
`;

/**
 * Formata a Opção da Modal Editor
 *
 * @extends {Button}
 *
 * Herda estilo para formatar o interior do Opção
 * @extends {fontSize}
 * @extends {colors}
 */
export const Option = styled(Button)`
  min-width: 49%;
  padding: 2rem 1rem;
  background-color: ${colors.grey.lightPrimary};
  align-items: space-between;
  border-radius: 0;
  margin-left: 0;
  height: 100%;
  display: flex;
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &:hover {
    box-shadow: 0px 0px 6px ${colors.grey.darkPrimary};
    cursor: pointer;
  }

  svg {
    margin-bottom: 2rem;
  }

  p {
    font-size: ${fontSize.sm};
    color: ${colors.primary};
    text-align: center;
    line-height: 1.5;
    text-transform: initial;
  }
`;

/**
 * Formata a Representação do Btn interno,
 * O Bloco todo é um Btn logo não podemos ter um BTN dentro do outro.
 *
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do Btn interno
 * @extends {colors}
 */
export const Btn = styled.div`
  background-color: ${colors.primary};
  color: ${colors.default};
  padding: 0.5rem 1rem;
  border-radius: 50px;
  margin-bottom: 1rem;
`;
