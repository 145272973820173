import React, { useState } from 'react';
import { connect } from 'react-redux';

import CardForm from './CardForm';

import { fetchInstallments } from '../../../actions/payment';
import {
  WrapperTwoForms,
  WrapperCardForm,
  TitleCard,
  Separator,
} from './style';
import MaskedValueInput from './MaskedValueInput';

const TwoCreditCard = ({ hashPayment, payment }) => {
  // Estado para verificar se algum dos inputs do valo tem erro
  const [someInputValueHasError, setSomeInputValueHasError] = useState(false);
  if (!hashPayment) {
    return '';
  }

  const dependenciaPrimeiroCartao = payment.twoCards.installments.primeiroCartao
    ? payment.twoCards.installments.primeiroCartao.formasPagamento.filter(
        forma => forma.pk_tipo_pagamento === 3
      )[0].dependencia_tipo_pagamento
    : undefined;
  const dependenciaSegundoCartao = payment.twoCards.installments.segundoCartao
    ? payment.twoCards.installments.segundoCartao.formasPagamento.filter(
        forma => forma.pk_tipo_pagamento === 3
      )[0].dependencia_tipo_pagamento
    : undefined;

  return (
    <WrapperTwoForms>
      <WrapperCardForm>
        <TitleCard>Cartão 1</TitleCard>
        <MaskedValueInput
          card="firstCard"
          isCreditApplied={payment.multiplePayments}
          setSomeInputValueHasError={setSomeInputValueHasError}
          someInputValueHasError={someInputValueHasError}
        />
        <CardForm
          dependencia_tipo_pagamento={dependenciaPrimeiroCartao}
          payment={payment}
          hashPayment={hashPayment}
          firstCard
          disabled={!dependenciaPrimeiroCartao}
        />
      </WrapperCardForm>
      <Separator />
      <WrapperCardForm>
        <TitleCard>Cartão 2</TitleCard>
        <MaskedValueInput
          card="secondCard"
          isCreditApplied={payment.multiplePayments}
          setSomeInputValueHasError={setSomeInputValueHasError}
          someInputValueHasError={someInputValueHasError}
        />
        <CardForm
          dependencia_tipo_pagamento={dependenciaSegundoCartao}
          payment={payment}
          hashPayment={hashPayment}
          disabled={!dependenciaSegundoCartao}
        />
      </WrapperCardForm>
    </WrapperTwoForms>
  );
};

const mapStateToProps = state => {
  return {
    multiplePayments: state.payment.multiplePayments,
    hashPayment: state.payment.hashPayment,
    payment: state.payment,
    totalValue: state.payment.payment.totalGeral,
  };
};

export default connect(mapStateToProps, { fetchInstallments })(TwoCreditCard);
