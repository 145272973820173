import React from 'react';

// utils
import { dateTransform } from '../../../utils';

// styles
import { CardInfo } from '../../../styles/cards';

const History = ({ data }) => {
  if (!data) {
    return '';
  }

  return (
    <CardInfo bg padding margin={'0 0 1rem 0'}>
      <h3>Histórico do Pedido</h3>
      {data.map((log, i) => {
        return (
          <React.Fragment key={i}>
            <p className={'feature'}>
              {dateTransform(log.dta_inc_log_status_pedido)}
            </p>
            <p>{log.descricao_status_loja}</p>
          </React.Fragment>
        );
      })}
    </CardInfo>
  );
};

export default History;
