import styled from "styled-components";

// Style
import { colors } from "../../styles/variables";

/**
 * Formata o container do tooltip
 * @extends {p} Tag p nativa do HTML
 * 
 * Herda estilo para formatar o interior do container
 * @extends {colors}
 */
export const Container = styled.p`
  width: fit-content;
  padding: 0 0.5rem;

  svg {
    fill: ${colors.primary};
  }
`;

/**
 * Formata o Content do tooltip
 * @extends {span} Tag span nativa do HTML
 * 
 * Herda estilo para formatar o interior do Content
 * @extends {colors}
 */
export const Content = styled.span`
  font-size: 0.775rem;
  text-transform: initial;
  color: ${colors.default};
  max-width: 150px;
  line-height: 1.5;

  ul {
    margin: 0.5rem 0;
    margin-left: 1rem;

    li {
      margin-bottom: 0.3rem;
    }
  }
`;
