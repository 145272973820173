import React from 'react';
import { connect } from 'react-redux';

// components
import Credit from './Credit';
import BankDeposit from './BankDeposit';
import BankSlip from './BankSlip';
import BankSlipBkn from './BankSlipBkn';
import CreditCard from './CreditCard';
import Pix from './Pix';
import TwoCreditCard from './TwoCreditCard/index.jsx';

// actions
import {
  changeMultiplePayment,
  changeTypePayment,
  handlePaymentWithTwoCards,
  updateTwoCards,
  cleanTwoCards,
} from '../../actions/payment';

// styles
import { Checkbox, Label, Radio, Wrapper } from '../../styles/forms';
import { TitlePage } from '../../styles/titles';
import {
  CreditCardStyle,
  Item,
  PaymentItem,
  PaymentItemContent,
} from './style';
import { PaymentTypes } from '../../enums/payment.js';

const renderType = (payments, payment, paymentWithTwoCards = false) => {
  switch (payment.pk_tipo_pagamento) {
    case 1:
      return (
        <BankDeposit
          dependencia_tipo_pagamento={payment.dependencia_tipo_pagamento}
          titular={payments.titular_deposito}
          cnpj={payments.cnpj}
        />
      );
    case 3:
      return (
        <CreditCardStyle>
          {paymentWithTwoCards ? (
            <TwoCreditCard
              dependencia_tipo_pagamento={payment.dependencia_tipo_pagamento}
            />
          ) : (
            <CreditCard
              dependencia_tipo_pagamento={payment.dependencia_tipo_pagamento}
            />
          )}
        </CreditCardStyle>
      );
    case 4:
      return <Credit saldo={payments.saldo} totalGeral={payments.totalGeral} />;
    case 6:
      return <BankSlip />;
    case 10:
      return <BankSlipBkn />;
    case 13:
      return <Pix />;
    default:
      break;
  }
};

function shouldShowCheckboxTwoCards(
  selectedPayment,
  payment,
  payments,
  multiplePayments
) {
  const totalValue = multiplePayments
    ? payments.totalGeral - payments.saldo
    : payments.totalGeral;

  return (
    (selectedPayment.toString() === PaymentTypes.CREDIT_CARD ||
      selectedPayment === PaymentTypes.TWO_CARDS) &&
    payment.pk_tipo_pagamento.toString() === PaymentTypes.CREDIT_CARD &&
    totalValue > 100
  );
}

const Payments = ({
  payments,
  boolMultiplePayments,
  changeTypePayment,
  changeMultiplePayment,
  handlePaymentWithTwoCards,
  paymentWithTwoCards,
  selectedPayment,
  cleanTwoCards,
  order
}) => {
  if (Object.entries(payments).length === 0) {
    return '';
  }

  function handleCheckCredit() {
    if (paymentWithTwoCards) {
      cleanTwoCards();
      changeTypePayment(PaymentTypes.CREDIT_CARD);
      handlePaymentWithTwoCards(false);
    }
    changeMultiplePayment();
  }

  // O Backend retorna payments.radio e nos usamos isso para renderizar como 'radio' ou 'checkbox' para a opção Crédito (Saldo)
  // Radio: significa que tem Crédito suficiente para pagar a compra inteira com ele
  // Checkbox: significa que tem crédito parcial para pagamento e terá que selecionar outra opção ainda para complementar
  const shouldShowCreditAsRadio = payments.radio;

  const filteredBlockedFormasPagamento = payments.formasPagamento.filter(payment =>
    !(payments.bloqueio_usuario_pagamento &&
      payments.bloqueio_usuario_pagamento.some(
        pagamento => pagamento.pk_tipo_pagamento === payment.pk_tipo_pagamento
      )
    )
  );

  // Verifica para tela de /pedido/id se o Pedido foi pago com Pix
  //const orderPayiedWithPix = order.pagamento.some(p => p.fk_tipo_pagamento == 13)

  return (
    <>
      <TitlePage>
        <span>Formas de Pagamento</span>
      </TitlePage>


      {filteredBlockedFormasPagamento.map((payment, index) => {
        // Condição especial para exibir Crédito (Saldo). Explicação acima de "shouldShowCreditAsRadio"
        if (payment.pk_tipo_pagamento === 4) {
          return (
            <PaymentItem key={index}>
              <Wrapper>
                {shouldShowCreditAsRadio ? (
                  <Radio
                    onChange={e => changeTypePayment(e.target.value)}
                    id={`pagamento_${payment.pk_tipo_pagamento}`}
                    name={'pagamento'}
                    value={payment.pk_tipo_pagamento}
                    defaultChecked={
                      selectedPayment ===
                        payment.pk_tipo_pagamento.toString() ||
                      (selectedPayment === PaymentTypes.TWO_CARDS &&
                        payment.pk_tipo_pagamento.toString() ===
                          PaymentTypes.CREDIT_CARD)
                    }
                  />
                ) : (
                  <Checkbox
                    id={`pagamento_${payment.pk_tipo_pagamento}`}
                    name={'credito_parcial'}
                    onChange={e => handleCheckCredit()}
                    checked={boolMultiplePayments}
                  />
                )}
                <span></span>
                <Label htmlFor={`pagamento_${payment.pk_tipo_pagamento}`}>
                  {payment.desc_tipo_pagamento}
                </Label>
                <PaymentItemContent>
                  <Item>
                    {renderType(payments, payment, paymentWithTwoCards)}
                  </Item>
                </PaymentItemContent>
              </Wrapper>
            </PaymentItem>
          );
        }
        // Listagem normal dos métodos de pagamentos como Radio
        return (
          <PaymentItem key={index}>
            <Wrapper>
              <Radio
                onChange={e => changeTypePayment(e.target.value)}
                id={`pagamento_${payment.pk_tipo_pagamento}`}
                name={'pagamento'}
                value={payment.pk_tipo_pagamento}
                defaultChecked={
                  selectedPayment === payment.pk_tipo_pagamento.toString() ||
                  (selectedPayment === PaymentTypes.TWO_CARDS &&
                    payment.pk_tipo_pagamento.toString() ===
                      PaymentTypes.CREDIT_CARD)
                }
              />
              <span></span>
              <Label htmlFor={`pagamento_${payment.pk_tipo_pagamento}`}>
                {payment.desc_tipo_pagamento}
              </Label>
              {/* checkbox pagar com dois cartões */}
              {shouldShowCheckboxTwoCards(
                selectedPayment,
                payment,
                payments,
                boolMultiplePayments
              ) && (
                <Wrapper>
                  <Checkbox
                    id={'pagamento_dois_cartoes'}
                    onChange={e => {
                      changeTypePayment(e.target.checked ? PaymentTypes.TWO_CARDS : PaymentTypes.CREDIT_CARD);
                      handlePaymentWithTwoCards(!paymentWithTwoCards);
                    }}
                    checked={paymentWithTwoCards}
                  />
                  <span></span>
                  <Label htmlFor={'pagamento_dois_cartoes'}>
                    Pagar com dois cartões
                  </Label>
                </Wrapper>
              )}
              <PaymentItemContent>
                <Item>
                  {renderType(payments, payment, paymentWithTwoCards)}
                </Item>
              </PaymentItemContent>
            </Wrapper>
          </PaymentItem>
        );
      })}
    </>
  );
};

const mapStateToProps = state => {
  return {
    payments: state.payment.payments,
    boolMultiplePayments: state.payment.multiplePayments,
    selectedPayment: state.payment.payment.tipo_pagamento,
    paymentWithTwoCards: state.payment.twoCards.selected
  };
};

export default connect(mapStateToProps, {
  changeTypePayment,
  changeMultiplePayment,
  handlePaymentWithTwoCards,
  updateTwoCards,
  cleanTwoCards,
})(Payments);
