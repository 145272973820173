import React, { Component } from 'react';
import { Form, Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

// Components
import LoaderForm from '../../../components/Loader/loaderForm';

// validate
import renderField from '../../../formValidate/renderField';
import { maskCelPhone } from '../../../formValidate/masks';
import { required, lengthPhone } from '../../../formValidate';

// styles
import * as StyleModalContest from '../style';
import * as StyleForm from '../../../styles/forms';

class SendContact extends Component {
  render() {
    const { handleSubmit, previousPage, submitting, active } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <StyleModalContest.StepContact className={active}>
          <p>
            Você solicitou o serviço de contestação. Por favor, informe um
            número de telefone atualizado para que possamos fazer contato com
            você.
          </p>
          <StyleForm.Wrapper>
            <StyleForm.Label htmlFor={'nr_fone'}>
              Telefone <strong>*</strong>
            </StyleForm.Label>
            <Field
              type={'text'}
              typefield={'mask'}
              name={'nr_fone'}
              id={'nr_fone'}
              component={renderField}
              mask={maskCelPhone}
              validate={[required, lengthPhone]}
            />
          </StyleForm.Wrapper>
          <p>Horário para contato (segunda à sexta-feira)</p>
          <StyleModalContest.GroupWrapper>
            <StyleForm.Wrapper>
              <Field
                type={'radio'}
                name={'periodo'}
                id={'periodo_1'}
                typefield={'radio'}
                component={renderField}
                value={'Manhã (08:30 — 12:00)'}
              />
              <StyleForm.Label htmlFor={'periodo_1'}>
                Manhã (08:30 — 12:00)
              </StyleForm.Label>
            </StyleForm.Wrapper>
            <StyleForm.Wrapper>
              <Field
                type={'radio'}
                name={'periodo'}
                id={'periodo_2'}
                typefield={'radio'}
                component={renderField}
                value={'Tarde (13:00 — 17:30)'}
              />
              <StyleForm.Label htmlFor={'periodo_2'}>
                Tarde (13:00 — 17:30)
              </StyleForm.Label>
            </StyleForm.Wrapper>
            <StyleForm.Wrapper>
              <Field
                type={'radio'}
                name={'periodo'}
                id={'periodo_3'}
                typefield={'radio'}
                component={renderField}
                value={'Qualquer período'}
              />
              <StyleForm.Label htmlFor={'periodo_3'}>
                Qualquer período
              </StyleForm.Label>
            </StyleForm.Wrapper>
          </StyleModalContest.GroupWrapper>
        </StyleModalContest.StepContact>
        <StyleForm.BtnGroup>
          <span>* Campos Obrigatórios</span>
          <span>
            <StyleForm.Button onClick={previousPage}>Voltar</StyleForm.Button>
            <StyleForm.ButtonPrimary type={'submit'} disabled={submitting}>
              Confirmar{submitting ? <LoaderForm /> : ''}
            </StyleForm.ButtonPrimary>
          </span>
        </StyleForm.BtnGroup>
      </Form>
    );
  }
}

const mapStateToProps = () => {
  return {
    initialValues: {
      periodo: 'Manhã (08:30 — 12:00)',
    },
  };
};

SendContact = reduxForm({
  form: 'formContest',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  keepDirtyOnReinitialize: true,
})(SendContact);

SendContact = connect(mapStateToProps, {})(SendContact);

export default SendContact;
