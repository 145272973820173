import React from 'react';
import { connect } from 'react-redux';
import { Field, Form, reduxForm, change } from 'redux-form';
import { toast } from 'react-toastify';

// components
import Loader from '../../components/Loader';

// actions
import { updatePrivacyUser } from '../../actions/user';
import { modalPrivacy } from '../../actions/modals';

// Validatef
import renderField from '../../formValidate/renderField';

// Styles
import { RegisterStyle, Anchor, H5, Span, BtnGroup } from './style';
import { Button, Wrapper, Label } from '../../styles/forms';
import { Col } from '../../styles/align';

// services
import { getPkUser } from '../../services/user';

// utils
import { UPDATE_PRIVACY_SUCCESS } from '../../utils/constMessage';

let PrivacyForm = props => {
  const { handleSubmit, submitting, dispatch } = props;

  const AVISOS = 1;
  const NEWS_USUARIO = 2;
  const COMERCIAIS = 3;

  if (submitting) {
    return <Loader />;
  }

  const submit = async formValues => {
    return props
      .updatePrivacyUser(
        formValues.pk_usuario,
        formValues.avisos,
        formValues.news_usuario,
        formValues.comerciais
      )
      .then(resp => {
        if (resp === 200) {
          toast.success(UPDATE_PRIVACY_SUCCESS);
          props.modalPrivacy();
        }
      });
  };

  const handleCloseModal = () => {
    props.modalPrivacy();
  };

  if (!props.list) return <Loader />;

  if (props.list) {
    props.list.filter(value => {
      switch (value.fk_privacidade) {
        case AVISOS:
          dispatch(
            change('FormPrivacy', 'avisos', value.aceito ? true : false)
          );
          break;
        case NEWS_USUARIO:
          dispatch(
            change('FormPrivacy', 'news_usuario', value.aceito ? true : false)
          );
          break;
        case COMERCIAIS:
          dispatch(
            change('FormPrivacy', 'comerciais', value.aceito ? true : false)
          );
          break;
        default:
          break;
      }
      return true;
    });
  }

  return (
    <RegisterStyle>
      <Form onSubmit={handleSubmit(submit)}>
        <Col className={'content__data-check'}>
          <H5>OPÇÕES DE PRIVACIDADE</H5>
          <Wrapper>
            <Field
              name={'avisos'}
              id={'avisos'}
              type={'checkbox'}
              typefield={'checkbox'}
              component={renderField}
              label={''}
            />
            <Label htmlFor={'avisos'}>
              Receber avisos com informações sobre os meus pedidos e o meu
              cadastro.
            </Label>
          </Wrapper>
          <Wrapper>
            <Field
              name={'news_usuario'}
              id={'news_usuario'}
              type={'checkbox'}
              typefield={'checkbox'}
              component={renderField}
              label={''}
            />
            <Label htmlFor={'news_usuario'}>
              Quero receber as novidades e lançamentos por e-mail
            </Label>
          </Wrapper>
          <Wrapper>
            <Field
              name={'comerciais'}
              id={'comerciais'}
              type={'checkbox'}
              typefield={'checkbox'}
              component={renderField}
              label={''}
            />
            <Label htmlFor={'comerciais'}>
              Eu autorizo que seja feito o tratamento de dados para a definição
              de perfis, de forma a oferecer um melhor serviço e prestar
              informações e ofertas comerciais adequadas.
            </Label>
          </Wrapper>
          <Wrapper>
            <Field
              name={'agree_terms'}
              id={'agree_terms'}
              type={'checkbox'}
              typefield={'checkbox'}
              component={renderField}
              disabled={true}
            />
            <Label htmlFor={'agree_terms'}>
              Declaro que li e concordo com os{' '}
              <Anchor
                href="https://instrucoes.fabricadolivro.com.br/politica-empresa/termos-de-uso/"
                target="_blank"
                title="Política de Privacidade"
              >
                <strong>termos de uso</strong>
              </Anchor>{' '}
              da Fábrica do livro e que tenho mais de 18 anos.
            </Label>
          </Wrapper>
          <Span>
            <p>
              Para mais informações, entre em contato com nossa{' '}
              <Anchor href="/contato">central de atendimento</Anchor>
            </p>
          </Span>
        </Col>
        <BtnGroup>
          <span>
            <p onClick={handleCloseModal}>Cancelar</p>
            <Button type={'submit'}>
              Salvar{submitting ? <Loader /> : ''}
            </Button>
          </span>
        </BtnGroup>
      </Form>
    </RegisterStyle>
  );
};

const mapStateToProps = store => {
  const initialValues = {
    pk_usuario: getPkUser(),
    agree_terms: true,
  };

  return {
    list: store.userPrivacy.listPrivacy,
    initialValues,
  };
};

const actionCreators = {
  updatePrivacyUser,
  modalPrivacy,
};

PrivacyForm = reduxForm({
  form: 'FormPrivacy',
})(PrivacyForm);

PrivacyForm = connect(mapStateToProps, actionCreators)(PrivacyForm);

export default PrivacyForm;
