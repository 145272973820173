// Images
import preview from '../../assets/images/preview.jpg';

// Shared
import {
  // IconCadastro,
  IconBanners,
  IconCapas,
  IconCarDelivery,
  IconDiagramacao,
  IconMousePersonalize,
  IconSmartPhone,
} from '../../shared/icons';

/**
 * Serviços da Home
 * @type {Object}
 */

export const services = {
  data: [
    {
      icon: IconCapas,
      title: 'Criação de Capa',
    },
    {
      icon: IconDiagramacao,
      title: 'Diagramação Simples de Livros',
    },
    // {
    //   icon: IconCadastro,
    //   title: "Cadastro ISBN Código de Barras"
    // },
    // {
    //   icon: IconBanners,
    //   title: 'Banners Impressos',
    // },
    // {
    //   icon: IconBook,
    //   title: "Book Trailer",
    //   link: "/"
    // },
    // {
    // 	icon: IconRevisao,
    // 	title: "Revisão de Textos"
    // },
    // {
    //   icon: IconConsultoria,
    //   title: 'Consultoria Editorial',
    // },
  ],
  title: 'Nossos Serviços',
  desc: [
    'Confira nossos serviços editoriais. Com muito cuidado e agilidade na execução, ',
    'proporcionamos uma vida mais fácil aos autores independentes. Solicite agora um orçamento.',
  ],
};

/**
 * Chamadas da Home
 * @type {Object}
 */

export const features = {
  data: [
    {
      icon: IconMousePersonalize,
      title: 'Personalize',
      desc: 'Editor Online',
      text: 'Insira suas fotos e textos',
      link: '/',
    },
    {
      icon: IconSmartPhone,
      title: 'Dúvidas',
      desc: 'Fale Conosco',
      text: 'De Seg. á Sex das 8:30 às 12:00 e 13:00 as 17:30',
      link: '/',
    },
    {
      icon: IconCarDelivery,
      title: 'Entrega Rápida',
      desc: 'Em até 10 dias',
      text: '',
      link: '/',
    },
  ],
  title: '',
};

/**
 * Seo da Home
 * @type {Object}
 */

export const seo = {
  title: 'Fábrica do Livro | Impressão sob demanda rápida e acessível',
  meta: [
    {
      name: 'description',
      content:
        'Imprimir livros, revistas, catálogos, apostilas, trabalhos acadêmicos sob demanda, com alta qualidade e curto prazo, comodidade e preço baixo!',
    },
  ],
  canonical: '',
};

/**
 * Config OG TAG
 */

export const ogSeo = {
  title: 'Fábrica do Livro | Impressão sob demanda rápida e acessível',
  meta: [
    {
      property: 'og:url',
      content: `${window.location.href}`,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:title',
      content: 'Fábrica do Livro | Impressão sob demanda rápida e acessível',
    },
    {
      property: 'og:description',
      content:
        'Imprimir livros, revistas, catálogos, apostilas, trabalhos acadêmicos sob demanda, com alta qualidade e curto prazo, comodidade e preço baixo!',
    },
    {
      property: 'og:image',
      content: `${preview}`,
    },
    {
      property: 'og:image:type',
      content: 'image/jpeg',
    },
  ],
  canonical: '',
};
