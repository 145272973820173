import React, { Component } from 'react';
import { Form, Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

// Node
import Icon from '@mdi/react';
import { mdiCheckCircle, mdiPlusCircle, mdiCloseCircle } from '@mdi/js';

// Components
import LoaderForm from '../../../components/Loader/loaderForm';

// validate
import renderField from '../../../formValidate/renderField';
import FileInput from '../../../formValidate/FileInput';
import { required } from '../../../formValidate';

// Styles
import * as StyleModalContest from '../style';
import * as StyleForm from '../../../styles/forms';

class SendFiles extends Component {
  renderInputFile = () => {
    const { onState, onUpdateState } = this.props;

    return (
      <>
        <StyleForm.Label htmlFor={'arquivos'}>
          Arquivos <strong>*</strong>
        </StyleForm.Label>
        {onState.inputs.map(index => {
          return (
            <StyleModalContest.File key={index}>
              <StyleForm.WrapperFiles>
                {this.props.formSendFiles &&
                this.props.formSendFiles.values &&
                this.props.formSendFiles.values.arquivos &&
                this.props.formSendFiles.values.arquivos[index] ? (
                  <>
                    <span className={'sent'}>
                      <Icon path={mdiCheckCircle} title={'send'} size={1} />
                    </span>
                    <span className={'value'}>
                      {this.props.formSendFiles.values.arquivos[index].name}
                    </span>
                  </>
                ) : (
                  <span className={'send'}>Enviar</span>
                )}

                <Field
                  name={`arquivos[${index}]`}
                  id={`arquivos_${index}`}
                  component={FileInput}
                  validate={required}
                  onChange={() => onUpdateState({ changed: index })}
                />
              </StyleForm.WrapperFiles>
              {this.props.formSendFiles &&
              this.props.formSendFiles.values &&
              this.props.formSendFiles.values.arquivos &&
              this.props.formSendFiles.values.arquivos[index] &&
              onState.changed === index ? (
                <StyleModalContest.DuplicateInput>
                  <Icon
                    size={1.2}
                    path={mdiPlusCircle}
                    title={'Enviar Mais Arquivos'}
                    onClick={() => {
                      const value = Math.max(...onState.inputs);

                      onUpdateState({
                        inputs: [...onState.inputs, value + 1],
                      });
                    }}
                  />
                </StyleModalContest.DuplicateInput>
              ) : (
                <>
                  {onState.inputs.length > 1 ? (
                    <StyleModalContest.DuplicateInput>
                      <Icon
                        size={1.2}
                        path={mdiCloseCircle}
                        title={'Remover Campo de envio'}
                        onClick={() => {
                          const inputsFiles = onState.inputs.filter(
                            input => input !== index
                          );

                          onUpdateState({ inputs: [...inputsFiles] });
                        }}
                      />
                    </StyleModalContest.DuplicateInput>
                  ) : (
                    ''
                  )}
                </>
              )}
            </StyleModalContest.File>
          );
        })}
      </>
    );
  };

  render() {
    const { handleSubmit, submitting, onClose, active } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <StyleModalContest.StepFiles className={active}>
          <p>
            Para confirmar a contestação preencha o motivo e faça o upload dos
            arquivos.
          </p>
          {this.renderInputFile()}
          <StyleForm.Wrapper>
            <StyleForm.Label htmlFor={'motivo'}>
              Motivo <strong>*</strong>
            </StyleForm.Label>
            <Field
              type={'textarea'}
              typefield={'textarea'}
              id={'motivo'}
              name={'motivo'}
              component={renderField}
              validate={required}
            />
          </StyleForm.Wrapper>
        </StyleModalContest.StepFiles>
        <StyleForm.BtnGroup>
          <span>* Campos Obrigatórios</span>
          <span>
            <StyleForm.Button type={'button'} onClick={onClose}>
              Cancelar
            </StyleForm.Button>
            <StyleForm.ButtonPrimary type={'submit'} disabled={submitting}>
              Confirmar{submitting ? <LoaderForm /> : ''}
            </StyleForm.ButtonPrimary>
          </span>
        </StyleForm.BtnGroup>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
    formSendFiles: state.form.formContest,
  };
};

SendFiles = reduxForm({
  form: 'formContest',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  keepDirtyOnReinitialize: true,
})(SendFiles);

SendFiles = connect(mapStateToProps, {})(SendFiles);

export default SendFiles;
