import styled from 'styled-components';

// styles
import { colors } from '../../styles/variables';
import { Row } from '../../styles/align';
import { Wrapper, Label, BtnGroup, Button } from '../../styles/forms';

/**
 * Formata o Container da Modal de Novo Endereço
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do Container
 * @extends {BtnGroup}
 * @extends {Button}
 * @extends {colors}
 */
export const RegistaAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.grey.light};
  padding: 1rem;

  ${BtnGroup} {
    display: flex;
    padding: 1rem 0;
    flex-direction: column;
    align-items: center;

    span {
      align-self: flex-start;
      display:flex;
      font-size: 12px;
      flex-direction: row;
      color: ${colors.red.primary};
      width: 100%;

      &:last-of-type {
        display: flex;
        justify-content: flex-end;
      }

      ${Button} {
        transition: background-color 0.2s ease, color 0.2s ease;
        &:hover {
          background-color: ${colors.secondary}; 
          color: ${colors.default};
        }
        &:last-of-type {
          background-color: ${colors.primary};
          color: ${colors.default};
          text-decoration: none;
          transition: background-color 0.1s ease, color 0.1s ease;

            &:hover {
              background-color: ${colors.blue.light};  
              color: ${colors.default};
            }

          
          
        }
      }
    }
  }
`;

/**
 * Formata o Wrapper do checkbox Endereço padrão
 * @extends {Wrapper}
 */
export const WrapperCheck = styled(Wrapper)`
  width: 100%;
  padding: 0 1rem;
  flex-direction: row;
`;

/**
 * Formata o Formulario de Cadastro de um novo endereço
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Row}
 * @extends {Wrapper}
 * @extends {Label}
 * @extends {WrapperCheck}
 * @extends {colors}
 */
export const RegisterAddressForm = styled.div`
  background-color: ${colors.grey.lightPrimary};

  ${Row} {
    padding: 1rem 0;

    ${Wrapper} {
      width: 100%;
      padding: 0 0.5rem;
      justify-content: flex-end;

      @media (max-width: 768px) {
        margin-bottom: 1rem;
      }

      ${Label} {
        padding: 0;
        margin-bottom: 0.5rem;
        color: ${colors.primary};
        font-weight: 700;

        strong {
          color: ${colors.red.primary};
          margin-left: 0.1rem;
          font-size: 10px;
        }
      }
    }

    ${WrapperCheck} {
      justify-content: flex-start;
      align-items: flex-end;

      span {
        margin: 0;
      }

      ${Label} {
        padding: 0 0 0 2.5rem;
        margin: 0;

        strong {
          margin-left: 1rem;
          color: ${colors.secondary};
        }

        & ~ span {
          margin: 0;
        }
      }
    }
  }
`;

const ContainerCnpjInfo = styled.div`
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  text-align: center;
  margin-bottom: 1rem;
`

export const ErrorGetCnpjAddress = styled(ContainerCnpjInfo)`
  color: ${colors.red.secondary};
  background-color: ${colors.red.secondary}25;
  border-color: ${colors.red.secondary}80;
  margin-top: 12px;
`

export const InfoGetCnpjAddress = styled(ContainerCnpjInfo)`
  color: ${colors.primary};
  background-color: ${colors.primary}25;
  border-color: ${colors.primary}80;
`

