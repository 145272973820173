import React from 'react';

// components
import Filter from '../../components/Credits/Filter';
import Table from '../../components/Credits/Table';

// Style
import { StyleCredits } from './style';
import { Container, Col, MainPage, Sidebar } from '../../styles/align';
import { TitlePage } from '../../styles/titles';

const Credits = () => {
  return (
    <Container>
      <Col>
        <TitlePage internal>
          <span>Meus Créditos</span>
        </TitlePage>
        <StyleCredits>
          <Sidebar dark>
            <Filter />
          </Sidebar>
          <MainPage>
            <Table />
          </MainPage>
        </StyleCredits>
      </Col>
    </Container>
  );
};

export default Credits;
