import React, { Component } from 'react';

// components
import Filter from './Filter';
import Table from './Table';
import Details from './Details';

// styles
import { TitlePage } from '../../styles/titles';
import { StyleMessage } from './style';
import { Container, MainPage, Col } from '../../styles/align';

class Message extends Component {
  render() {
    return (
      <Container>
        <Col>
          <TitlePage internal>
            <span>Minhas Mensagens</span>
          </TitlePage>
          <StyleMessage>
            <Filter />
            <MainPage widthContent="76%">
              <Table />
              <Details />
            </MainPage>
          </StyleMessage>
        </Col>
      </Container>
    );
  }
}

export default Message;
