import styled from 'styled-components';
import { colors } from '../../styles/variables';

/**
 * Formata o Container NotFound
 *
 * @extends {div} Tag div Nativa do HTML
 */
export const StyleNotFound = styled.div`
  background-color: ${colors.grey.light};
  padding: 5rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 300px;
  }
`;
