import styled from 'styled-components';

// Style
import { Row } from '../../styles/align';
import { colors } from '../../styles/variables';
import { Wrapper } from '../../styles/forms';

/**
 * Formata o Container da Página de Créditos
 * @extends {Row}
 * 
 * Herda estilo para formatar o interior do Container
 * @extends {Wrapper}
 * @extends {colors}
 */
export const StyleCredits = styled(Row)`
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: ${colors.grey.light};

  ${Wrapper} {
    margin-bottom: 1rem;
  }
`;
