import React, { Component } from 'react';
import { connect } from 'react-redux';

// Node
import ReactPaginate from 'react-paginate';

// components
import Loader from '../../components/Loader';
import RatingStars from '../../components/RatingStars';

// actions
import {
  fetchMessages,
  fetchHistory,
  show,
  page,
} from '../../actions/messages';

// services
import { formatSubmit } from '../../services/message';

// styles
import {
  TableStyle,
  TableContainer,
  TableTbody,
  TableTr,
  TableTd,
} from '../../styles/table';

class Table extends Component {
  state = {
    currentPage: 1,
  };

  handlePageClick = data => {
    const nextPage = data.selected + 1;

    let dataSubmit = formatSubmit(this.props.filterMessages.values);
    dataSubmit = { ...dataSubmit, paginaAtual: nextPage };

    this.props.fetchMessages(dataSubmit);
    this.props.show('');
    this.props.page(nextPage);
  };

  handleMessageClick = (index, idCaso) => {
    this.props.show(index);
    this.props.fetchHistory({ idCaso });
  };

  render() {
    const { messages, type, currentPage } = this.props;
    if (!messages) {
      return <Loader />;
    }

    if (messages[type].total === 0) {
      return (
        <TableStyle>
          <span>Você não possui mensagem com status {type}!</span>
        </TableStyle>
      );
    }
    return (
      <TableStyle>
        <TableContainer>
          <thead>
            <tr>
              <TableTr>Data</TableTr>
              <TableTr>Protocolo</TableTr>
              <TableTr>Assunto</TableTr>
              <TableTr>Status</TableTr>
              {type === 'resolvido' ? (
                <TableTr>Avaliação</TableTr>
              ) : (
                // <TableTr></TableTr>
                ''
              )}
            </tr>
          </thead>
          <TableTbody>
            {messages[type].data.map((message, i) => {
              return (
                <tr
                  key={i}
                  className={'action-link'}
                  onClick={() =>
                    this.handleMessageClick(i, message.protocoloId)
                  }
                >
                  <TableTd>{message.data_exibir}</TableTd>
                  <TableTd>{message.protocolo}</TableTd>
                  <TableTd>{message.assunto}</TableTd>
                  <TableTd>
                    {message.status === 'Novo' ? 'Novo' : 'Pendente'}
                  </TableTd>
                  {type === 'resolvido' ? (
                    message.rating ? (
                      <TableTd>
                        <RatingStars
                          rating={parseInt(message.rating)}
                          editing={false}
                        />
                      </TableTd>
                    ) : (
                      <TableTd>Avaliar</TableTd>
                    )
                  ) : (
                    // <TableTd></TableTd>
                    ''
                  )}
                </tr>
              );
            })}
          </TableTbody>
        </TableContainer>
        <ReactPaginate
          previousLabel={'← Anterior'}
          nextLabel={'Próxima →'}
          breakLabel={'...'}
          pageCount={messages[type].last_page}
          marginPagesDisplayed={3}
          pageRangeDisplayed={3}
          forcePage={currentPage - 1}
          onPageChange={this.handlePageClick}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
      </TableStyle>
    );
  }
}

const mapStateToProps = state => {
  return {
    messages: state.messages.data,
    type: state.messages.tipo,
    currentPage: state.messages.currentPage,
    filterMessages: state.form.filterMessage,
  };
};

Table = connect(mapStateToProps, { fetchMessages, fetchHistory, show, page })(
  Table
);

export default Table;
