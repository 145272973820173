/**
 * Textos da página de entrega
 * @type {Object}
 */

export const delivery = {
  title: 'Entrega',
  description: `Para facilitar o dia a dia dos nossos clientes e tornar o processo de entregas de pedidos
    mais ágil, a Fábrica do Livro oferece diversas formas de envio.`,
  text: `Confira a seguir todas as modalidades de transporte:`,
  options: [
    {
      title: 'Normal (PAC)',
      text: `realizada pelos Correios, esta opção de entrega tem prazos variados que devem ser conferidos 
        durante a compra.`,
    },
    {
      title: 'Sedex',
      text: `também realizada através dos Correios, esta entrega tem como diferencial prazos mais curtos.`,
    },
    {
      title: 'Entrega Express',
      text: `quem busca agilidade com preço acessível pode contar com esta
        modalidade, nela uma cotação é feita entre transportadoras e Correios e o pedido é
        enviado pela opção que oferecer o melhor custo-benefício.`,
    },
    {
      title: '.COM Especial e Package Especial',
      text: `estas duas categorias especiais realizam
        entregas mais rápidas através de transporte rodoviário e aéreo. Confira, no ato da
        compra, se elas estão disponíveis para a sua região.`,
    },
    {
      title: 'Porta a Porta e Motoboy',
      text: `nestas opções a entrega do pedido é feita em um local
        de sua escolha, seja um endereço comercial ou residencial. Os prazos podem variar de
        acordo com o local de entrega.`,
    },
    {
      title: 'Balcões de Retirada',
      text: `os pedidos são encaminhados por transportadoras até um
        ponto de distribuição específico pré-selecionado durante a compra. Os Balcões de
        Retirada estão presentes em diversas cidades, confira abaixo o prazo de entrega e as
        localidades disponíveis de acordo com cada região:`,
    },
  ],
};

/**
 * Seo da página de entrega
 * @type {Object}
 */
export const seo = {
  title: 'Entrega | Impressão Sob Demanda | Fábrica do Livro',
  meta: [
    {
      name: 'description',
      content:
        'Conheça as modalidades de Entrega disponíveis na Fábrica do Livro. A melhor opção para impressão e produção de livros sob demanda. Confira!',
    },
  ],
};
