import {
  CHANGE_NUMBER,
  CHANGE_NAME_CARD,
  CHANGE_MONTH,
  CHANGE_YEAR,
  CHANGE_CVC,
  CLEAR_CVC,
  CLEAR_DATA_CARD,
  CHANGE_PARCELS,
  CLEAR_PAYMENT,
  TYPE_PAYMENT,
  PAYMENT,
  PAYMENTS,
  HASH_CARD,
  MULTIPLE_PAYMENTS,
  CLEAR_PAYMENTS,
  VIEW_PAYMENTS,
  CLEAR_HASH_CARD,
  CHANGE_DEPENDENCY,
  PAYMENT_WITH_TWO_CARDS,
  FETCH_INSTALLMENTS_WITH_TWO_CARDS,
  UPDATE_TWO_CARDS,
  CLEAN_TWO_CARDS,
} from '../actions/types';

const INITIAL_STATE = {
  payment: {
    tipo_pagamento: '',
    pagamento: '',
    numero_cartao: '',
    portador: '',
    mes: '',
    ano: '',
    cvc: '',
    num_parcelas: '1',
    dependency: 0,
  },
  payments: {},
  hashCard: {},
  multiplePayments: false,
  twoCards: {
    selected: false,
    installments: {
      firstCard: [],
      secondCard: [],
    },
    firstCard: {
      pagamento: '',
      valor: '',
      numero_cartao: '',
      portador: '',
      mes: '',
      ano: '',
      cvc: '',
      num_parcelas: '1',
      dependency: 0,
    },
    secondCard: {
      pagamento: '',
      valor: '',
      numero_cartao: '',
      portador: '',
      mes: '',
      ano: '',
      cvc: '',
      num_parcelas: '1',
      dependency: 0,
    },
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_NUMBER:
      return {
        ...state,
        payment: { ...state.payment, numero_cartao: action.payload },
      };
    case CHANGE_NAME_CARD:
      return {
        ...state,
        payment: { ...state.payment, portador: action.payload },
      };
    case CHANGE_MONTH:
      return { ...state, payment: { ...state.payment, mes: action.payload } };
    case CHANGE_YEAR:
      return { ...state, payment: { ...state.payment, ano: action.payload } };
    case CHANGE_CVC:
      return { ...state, payment: { ...state.payment, cvc: action.payload } };
    case CLEAR_CVC:
      return {
        ...state,
        payment: { ...state.payment, cvc: INITIAL_STATE.payment.cvc },
      };
    case CHANGE_PARCELS:
      return {
        ...state,
        payment: { ...state.payment, num_parcelas: action.payload },
      };
    case CLEAR_PAYMENT:
      return {
        ...state,
        payment: INITIAL_STATE.payment,
        twoCards: INITIAL_STATE.twoCards,
      };
    case CLEAR_DATA_CARD:
      const { portador, mes, ano, cvc } = INITIAL_STATE.payment;
      return {
        ...state,
        payment: {
          ...state.payment,
          portador: portador,
          mes: mes,
          ano: ano,
          cvc: cvc,
        },
      };
    case TYPE_PAYMENT:
      return {
        ...state,
        payment: { ...state.payment, tipo_pagamento: action.payload },
      };
    case PAYMENT:
      return {
        ...state,
        payment: { ...state.payment, pagamento: action.payload },
      };
    case PAYMENTS:
      return { ...state, payments: action.payload };
    case HASH_CARD:
      return { ...state, hashPayment: action.payload };
    case MULTIPLE_PAYMENTS:
      return { ...state, multiplePayments: !state.multiplePayments };
    case CLEAR_PAYMENTS:
      return { ...state, payments: INITIAL_STATE.payments };
    case CLEAR_HASH_CARD:
      return { ...state, hashCard: INITIAL_STATE.hashCard };
    case VIEW_PAYMENTS:
      return { ...state, viewPayments: action.payload.data };
    case CHANGE_DEPENDENCY:
      return {
        ...state,
        payment: { ...state.payment, dependency: action.payload },
      };
    case PAYMENT_WITH_TWO_CARDS:
      return {
        ...state,
        twoCards: {
          ...state.twoCards,
          selected: action.payload,
        },
      };
    case FETCH_INSTALLMENTS_WITH_TWO_CARDS:
      return {
        ...state,
        twoCards: {
          ...state.twoCards,
          installments: action.payload,
        },
      };
    case UPDATE_TWO_CARDS:
      return {
        ...state,
        twoCards: {
          ...state.twoCards,
          [action.payload.card]: {
            ...state.twoCards[action.payload.card],
            [action.payload.field]: action.payload.value,
          },
        },
      };
    case CLEAN_TWO_CARDS:
      return {
        ...state,
        twoCards: {
          ...INITIAL_STATE.twoCards,
        },
      };
    default:
      return state;
  }
};
