import styled from 'styled-components';

// Style
import { Container } from '../style';
import {
  Label,
  BtnGroup,
  Wrapper,
  WrapperFiles,
  Textarea,
} from '../../styles/forms';
import { colors } from '../../styles/variables';
import { Col } from '../../styles/align';

/**
 * Formata o Container geral da Modal de Contestação
 *
 * @extends {Container}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Label}
 * @extends {BtnGroup}
 * @extends {colors}
 */
export const StyleContest = styled(Container)`
  p {
    margin: 1rem 0;
    color: ${colors.grey.dark};
  }

  ${Label} {
    strong {
      color: ${colors.secondary};
      margin-left: 0.5rem;
    }
  }

  ${BtnGroup} {
    padding: 1rem 0;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;

      span {
        margin-bottom: 1rem;
      }
    }

    span {
      color: ${colors.secondary};
      font-weight: 700;
    }
  }
`;

/**
 * Formata o Step de envio de arquivos
 *
 * @extends {div} Tag div Nativa do HTML
 *
 * Herda estilo para formatar o interior do Step
 * @extends {Label}
 * @extends {WrapperFiles}
 * @extends {Wrapper}
 * @extends {Textarea}
 * @extends {colors}
 */
export const StepFiles = styled.div`
  &.active {
    display: block;
  }

  &.hidden {
    display: none;
  }

  ${Label} {
    padding-left: 0;
    color: ${colors.primary};
    font-weight: 700;
    display: flex;
  }

  ${WrapperFiles} {
    max-width: 50%;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      max-width: 100%;
    }

    div {
      display: flex;
    }

    span {
      &.value {
        width: 100%;
        top: 0;
        right: 0;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 1rem;
      }

      & ~ input {
        &::-webkit-file-upload-button {
          display: none;
        }
      }
    }
  }

  ${Wrapper} {
    ${Textarea} {
      max-width: 100%;
      min-width: 100%;
      height: 8rem;
    }
  }
`;

/**
 * Formata o Step de Contato
 *
 * @extends {div} Tag div Nativa do HTML
 *
 * Herda estilo para formatar o interior do Step
 * @extends {Wrapper}
 * @extends {Label}
 * @extends {colors}
 */
export const StepContact = styled.div`
  &.active {
    display: block;
  }

  &.hidden {
    display: none;
  }

  ${Wrapper} {
    max-width: 50%;

    @media (max-width: 768px) {
      max-width: 100%;
    }

    ${Label} {
      padding-left: 0;
      color: ${colors.primary};
      font-weight: 700;
    }
  }
`;

/**
 * Formata o Grupo de Radios de horario
 *
 * @extends {Col}
 *
 * Herda estilo para formatar o interior do Grupo
 * @extends {Wrapper}
 * @extends {Label}
 * @extends {colors}
 */
export const GroupWrapper = styled(Col)`
  ${Wrapper} {
    max-width: 50%;

    @media (max-width: 768px) {
      max-width: 100%;
    }

    span {
      margin-left: 0;
    }

    ${Label} {
      padding-left: 2rem;
      color: ${colors.primary};
      font-weight: 700;
    }
  }
`;

/**
 * Formata o container do input file e do Icon add Input
 *
 * @extends {div} Tag div nativa do HTML
 */
export const File = styled.div`
  display: flex;
  flex-direction: row;
`;

/**
 * Formata o botão para duplicar o Input de envio de arquivos
 *
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do botão
 * @extends {colors}
 */
export const DuplicateInput = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${colors.primary};
    cursor: pointer;
  }
`;
