import styled from 'styled-components';

import { colors } from '../../styles/variables';

export const AcceptCookies = styled.div`
  display: ${props => (props.hide ? 'flex' : 'none')};
  justify-content: center;
  max-width: 77.5%;
  height: auto;
  padding: 0.75rem;
  position: fixed;
  bottom: 1rem;
  left: 0.625rem;
  right: 0.625rem;
  margin: 0 auto;
  background-color: ${colors.primary};
  color: ${colors.default};
  transition: opacity 300ms ease-in, transform 300ms ease-in,
    z-index 400ms ease-in;
  opacity: 0;
  transform: translate3d(0, 5rem, 0);
  z-index: 9999;
  box-shadow: 2px 1px 13px 2px ${colors.secondary};
  border-radius: 5px;

  opacity: ${props => (props.hide ? 1 : 0)};
  transform: translate3d(0, 0, 0);

  a {
    color: ${colors.default};
  }

  @media (max-width: 767px) {
    display: block;
    padding: 0.625rem;
    bottom: 0.625rem;
    text-align: -webkit-center;
  }
`;

export const Anchor = styled.a`
  cursor: pointer;
  color: ${colors.default};
`;

export const CookieVisible = styled.div`
  opacity: 1;
  transform: translate3d(0, 0, 0);
`;

export const Text = styled.span`
  font-family: Arial, Helvetica, Sans-serif;
  max-width: 44rem;
  font-size: 0.85rem;
  line-height: 1.33;

  @media (min-width: 992px) {
    min-width: auto;
    max-width: 48rem;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  padding: 1rem;
  background: ${colors.secondary};
  color: ${colors.default};
  display: flex;
  border: none;
  border-radius: 5px;
  justify-content: center;
  transition: all 0.5s ease-in-out;
  height: fit-content;
  font-size: 1rem;
  margin: 0 1rem;
  width: 25%;

  @media (max-width: 767px) {
    width: 100%;
    margin-left: 0;
    margin-top: 1rem;
  }
`;
