import styled from 'styled-components';

// Style
import { colors, fontSize } from '../../styles/variables';
import { Row, Col } from '../../styles/align';
import {
  Wrapper,
  Label,
  Radio,
  BtnGroup as BtnGroupForm,
  Button,
  Input,
} from '../../styles/forms';
import { TitleCards } from '../../styles/titles';

export const Anchor = styled.a`
  cursor: pointer;
  color: ${colors.primary};
  text-decoration: none;
`;

export const H5 = styled.h5`
  padding: 1rem;
  color: ${colors.primary};
`;

export const Span = styled.span`
  padding: 0.75rem 1rem 0rem;

  & > p {
    font-size: 0.75rem;
  }
`;

export const BtnGroup = styled(BtnGroupForm)`
  float: right;
  margin-right: 1rem;
`;

/**
 * Formata da Modal Cadastro de Usuario
 * @extends {Col}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Row}
 * @extends {Wrapper}
 * @extends {Radio}
 * @extends {Label}
 * @extends {Col}
 * @extends {TitleCards}
 * @extends {Input}
 * @extends {BtnGroup}
 * @extends {Button}
 * @extends {colors}
 */
export const RegisterStyle = styled(Col)`
	padding: 0.5rem;
	background-color: ${colors.grey.light};

	${Row} {
		${Wrapper} {
			min-width: 50%;
		
			${Radio} {
				&:checked {
					& ~ span {
						&::after {
							background-color: ${colors.secondary};
						}
					}
				}

				& ~ ${Label} {
					color: ${colors.primary};
					font-weight: 700;
				}
			}
		}
	}

	${Col} {
		background-color: ${colors.grey.lightPrimary};
		margin-bottom: 1rem;
		padding: 1rem 0;
	
		${TitleCards} {
			padding-left: 0.5rem;
		}

		${Row} {
			width: 100%;

			${Wrapper} {
				padding: 0 .5rem;
				min-width: 50%;
				width: 100%;
							
				${Label} {
					padding-left: 0;
					text-transform: uppercase;
					color: ${colors.primary};
					font-weight: 700
					font-size: ${fontSize.sm};
				
					strong {
						margin-left: .5rem;
						color: ${colors.secondary};
					}
				}

				${Input} {
					border: 1px solid ${colors.grey.darkSecondary};
				}
			}
		}

		&.content__data-check {
			padding: 0;
			background-color: transparent;

			${Wrapper} {
				margin-right: -1rem;
				${Label} {
					color: ${colors.primary}
				}
			}
		}
	}

	${BtnGroup} {
		flex-direction: row;
		justify-content: space-between;
		padding: 1rem 0;

		span {
			&:first-of-type {
				
				color: ${colors.secondary};
				font-weight: 700;
			}

			&:last-of-type {
				display: flex;
				align-items: center;
				p {
					color: ${colors.primary};
					text-decoration: underline;
					cursor: pointer;
				}

				${Button} {
					text-decoration: none;
					background-color: ${colors.secondary};
					color: ${colors.default};
					font-weight: 700;
				}
			}
		}
	}
`;
