import React from 'react';
import styled from 'styled-components';

const StyledSVG = styled.svg`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 5px;
`;

export const IconEdit = ({ title }) => (
  <StyledSVG
    width="35" 
    height="35" 
    viewBox="0 0 35 35" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    title={title}
  >
    <svg
      width="35" 
      height="35" 
      viewBox="0 0 35 35" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      title={title}
    >
    <rect 
      width="35" 
      height="35" 
      rx="17.5" 
      fill="#00244D"
    />
    <path 
      d="M21.5833 10.1502C21.7977 9.93569 22.0524 9.76554 22.3326 9.64946C22.6129 9.53338 22.9132 9.47363 23.2166 9.47363C23.5199 9.47363 23.8203 9.53338 24.1005 9.64946C24.3808 9.76554 24.6354 9.93569 24.8499 10.1502C25.0644 10.3647 25.2346 10.6193 25.3506 10.8996C25.4667 11.1798 25.5265 11.4802 25.5265 11.7835C25.5265 12.0869 25.4667 12.3872 25.3506 12.6675C25.2346 12.9477 25.0644 13.2024 24.8499 13.4168L13.8249 24.4418L9.33325 25.6668L10.5583 21.1752L21.5833 10.1502Z" 
      stroke="white" 
      stroke-width="1.4" 
      stroke-linecap="round" 
      stroke-linejoin="round"
    />
    </svg>
  </StyledSVG>
);
