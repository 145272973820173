import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// styles
import { Separator, ContainerForm } from '../Home/style';

// components
import Header from './header';
import FormRegistration from './form';

//options
import { LANDING_PAGE } from '../option';

let Registration = props => {
  useEffect(() => {
    const { landingRegister } = props;
    if (!landingRegister) {
      return props.history.push(LANDING_PAGE.home);
    }
  });

  return (
    <>
      <Header />
      <Separator />
      <ContainerForm>
        <FormRegistration />
      </ContainerForm>
      <Separator />
    </>
  );
};

const mapStateToProps = store => {
  return {
    landingRegister: store.landingPage.landingRegister,
  };
};

Registration = connect(mapStateToProps, {})(Registration);

export default withRouter(Registration);
