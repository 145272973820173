import styled from 'styled-components';

// styles
import { colors } from '../../styles/variables';
import { Wrapper, Label, BtnGroup, Button } from '../../styles/forms';

/**
 * Formata o Container da Modal Nova Mensagem
 *
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Wrapper}
 * @extends {Label}
 * @extends {BtnGroup}
 * @extends {Button}
 * @extends {colors}
 */
export const MessageContainer = styled.div`
  background-color: ${colors.grey.light};
  padding: 1rem;

  form {
    background-color: ${colors.grey.lightPrimary};
    padding: 1rem;

    ${Wrapper} {
      width: 100%;
      margin-bottom: 1rem;

      ${Label} {
        padding: 0;
        margin-bottom: 0.5rem;
        font-weight: 700;
        color: ${colors.primary};

        strong {
          color: ${colors.secondary};
        }
      }
    }

    ${BtnGroup} {
      justify-content: space-between;
      align-items: center;

      @media (max-width: 768px) {
        flex-direction: column;

        span {
          margin-bottom: 1rem;
        }

        button {
          &:first-of-type {
            margin-left: 0;
          }
        }
      }

      span {
        color: ${colors.secondary};
        font-weight: 700;
      }

      div {
        ${Button} {
          text-decoration: none;

          &:last-of-type {
            background-color: ${colors.primary};
            color: ${colors.default};
          }
        }
      }
    }
  }
`;
