import styled from 'styled-components';

// Styles
import { Wrapper, Label } from '../../../../styles/forms';
import { colors } from '../../../../styles/variables';

/**
 * Formata os Wrappers de Acabamento do Livro
 *
 * @extends {Wrapper}
 *
 * Herda estilo para formatar o interior do Wrapper
 * @extends {Label}
 * @extends {colors}
 */

export const FilterMandatory = styled(Wrapper)`
    border: 1px solid ${colors.grey.darkPrimary};
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;

    &.limit__box-50 {
        max-width: 48%;
    }

    ${Label} {
        display: flex;
        justify-content: space-between;

        span {
            &:last-of-type {
                font-weight: 700;
                color: ${colors.secondary};
            }
        }
    }
`;
