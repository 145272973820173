import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, Form, reduxForm } from 'redux-form';

// Node
import { toast } from 'react-toastify';

// hocs
import Modal from '../../hocs/Modal';

// Components
import LoaderForm from '../../components/Loader/loaderForm';

// actions
import { modalRefile } from '../../actions/modals';

// validate
import renderField from '../../formValidate/renderField';
import { required } from '../../formValidate';

// services
import { saveRefile } from '../../services/filter';

// utils
import { SUCCESS } from '../../utils/constMessage';

// styles
import { FormContainer } from '../style';
import { TitleCards } from '../../styles/titles';
import { Row, Col } from '../../styles/align';
import { Wrapper, BtnGroup, Button, Label } from '../../styles/forms';

class Refile extends Component {
  submit = async data => {
    const { dataRefile } = this.props;

    const largura = parseFloat(data.largura.replace(',', '.'));
    const altura = parseFloat(data.altura.replace(',', '.'));

    if (largura < parseFloat(dataRefile.acabamento.largura_min)) {
      toast.warn('Largura informada menor que o limite!');
      return false;
    }
    if (largura > parseFloat(dataRefile.dadosProduto.largura_produto)) {
      toast.warn('Largura informada ultrapassa o limite!');
      return false;
    }
    if (altura < parseFloat(dataRefile.acabamento.altura_min)) {
      toast.warn('Altura informada menor que o limite!');
      return false;
    }
    if (altura > parseFloat(dataRefile.dadosProduto.altura_produto)) {
      toast.warn('Altura informada ultrapassa o limite!');
      return false;
    }

    saveRefile(altura, largura);
    toast.success(SUCCESS);

    await this.props.dataRefile.callbackSubmit(dataRefile.acabamento);

    this.props.modalRefile('');
  };

  render() {
    const { handleSubmit, submitting, dataRefile } = this.props;
    if (!dataRefile) {
      return '';
    }

    return (
      <FormContainer>
        <TitleCards>{dataRefile.acabamento.desc_acabamento}</TitleCards>
        <Form onSubmit={handleSubmit(this.submit)}>
          <p>Selecione a medida desejada para o acabamento:</p>
          <Row>
            <Col>
              <Wrapper>
                <Label htmlFor={'largura'}>
                  Largura <strong>*</strong>
                </Label>
                <Field
                  name={'largura'}
                  id={'largura'}
                  component={renderField}
                  validate={required}
                  normalize={v => v.replace(/[^\d,.]/g, '').slice(0, 5)}
                />
                <em>Mínimo: {dataRefile.acabamento.largura_min} cm</em>
                <em>Máximo: {dataRefile.dadosProduto.largura_produto} cm</em>
              </Wrapper>
            </Col>
            <Col>
              <Wrapper>
                <Label htmlFor={'altura'}>
                  Altura <strong>*</strong>
                </Label>
                <Field
                  name={'altura'}
                  id={'altura'}
                  component={renderField}
                  validate={required}
                  normalize={v => v.replace(/[^\d,.]/g, '').slice(0, 5)}
                />
                <em>Mínimo: {dataRefile.acabamento.altura_min} cm</em>
                <em>Máximo: {dataRefile.dadosProduto.altura_produto} cm</em>
              </Wrapper>
            </Col>
          </Row>
          <BtnGroup row>
            <span className={'required'}>* Campos Obrigatórios</span>
            <span>
              <Button onClick={this.props.dataRefile.callbackCancel}>
                Cancelar
              </Button>
              <Button secondary type={'submit'} disabled={submitting}>
                Enviar{submitting ? <LoaderForm /> : ''}
              </Button>
            </span>
          </BtnGroup>
        </Form>
      </FormContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    dataRefile: state.modals.modalRefile.data,
  };
};

Refile = reduxForm({
  form: 'refile',
})(Refile);

Refile = connect(mapStateToProps, { modalRefile })(Refile);

Refile = Modal(Refile);

export default Refile;
