import React from 'react';
import { CardAction } from '../style';

const FileVerification = () => {
  return (
    <CardAction>
      <span>* Prazo de verificação dos arquivos: até 2 dias úteis.</span>
    </CardAction>
  );
};

export default FileVerification;
