import loja from '../apis/loja';

import { FETCH_MENUS, TOGGLE_MENU, CLOSE_MENU } from './types';
import { toast } from 'react-toastify';

/**
 * Busca os links para montar o menu principal
 */
export const fetchMenus = () => async dispatch => {
  try {
    const response = await loja.get('/menu');

    dispatch({ type: FETCH_MENUS, payload: response.data });
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

/**
 * Realiza o toggle do Menu Mobile
 */

export const toggleMenu = () => {
  return {
    type: TOGGLE_MENU,
  };
};

/**
 * Fecha o Menu Mobile
 */

export const closeMenu = () => {
  return {
    type: CLOSE_MENU,
  };
};
