import React, { Component } from 'react';
import { connect } from 'react-redux';

// styles
import { Wrapper, Label, Checkbox } from '../../styles/forms';
import { Anchor } from '../../pages/Filter/style';
import * as Style from './style';
import * as FormStyle from '../../styles/forms';

// actions
import { modalTermsOfUse } from '../../actions/modals';

// utils
import { dateTransform } from '../../utils';

// hocs
import Modal from '../../hocs/Modal';


 class TermsOfUse extends Component {
    checkedTerms = () => {
        return (
            <>
                <Wrapper>
                    <Checkbox id={'termos'} defaultChecked={true} disabled={true}/>
                    <span></span>
                    <Label htmlFor={'termos'}>
                    Declaro que li e aceito os{' '}
                    <Anchor
                        href="https://instrucoes.fabricadolivro.com.br/politica-empresa/termos-de-uso/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Termos de Uso.
                    </Anchor>
                    </Label>
                </Wrapper>

                <Wrapper>
                    <Checkbox 
                    id={'produtos'}
                    defaultChecked={true} 
                    disabled={true}
                    />
                    <span></span>
                    <Label htmlFor={'produtos'}>
                    Declaro que as informações de contato e de recebimento do(s) produto(s) está correta.
                    </Label>
                </Wrapper>

                <Wrapper>
                    <Checkbox 
                    id={'envio_arquivos'}
                    defaultChecked={true}
                    disabled={true}
                    />
                    <span></span>
                    <Label htmlFor={'envio_arquivos'}>
                    Declaro que estou enviando os arquivos seguindo as normas Pé com Pé,{' '}
                    <Anchor
                    href="https://instrucoes.fabricadolivro.com.br/tutoriais/montagem-arquivos-frente-e-verso/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                    rotacionados pé com pé
                    </Anchor>
                    {' '}e estou ciente de que ao não seguir as normas, acabará resultando em erros de produção, 
                    pelos quais serei responsável.
                    </Label>
                </Wrapper>
            </>
        );
    };

    footer = ({date, protocol}) => {
        return (
            <>
                <Style.Card>
                    <h3>Aceito em: {date}</h3>
                    <h3>Protocolo: {protocol}</h3>
                </Style.Card>
            </>
        );
    };

    okButton = () => {
        return (
            <FormStyle.BtnGroup>
                <FormStyle.ButtonPrimary onClick={this.props.modalTermsOfUse} margin={'2rem'} type={'button'}>Entendi</FormStyle.ButtonPrimary>
            </FormStyle.BtnGroup>
        );
    };

    render() {
        return (
            <Style.Card>
                {this.checkedTerms()}
                {this.footer({date: dateTransform(this.props.date) , protocol: this.props.protocol})}
                {this.okButton()}
            </Style.Card>
        );
    }
};

const mapStateToProps = state => {
    return {
      date: state.orders.dateTime,
      protocol: state.orders.protocol,
    };
  };

TermsOfUse = connect(mapStateToProps, {
    modalTermsOfUse
})(TermsOfUse);
  
TermsOfUse = Modal(TermsOfUse);
  
export default TermsOfUse;