import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, Form, reduxForm } from 'redux-form';

// actions
import { fetchMessages, type, show, page } from '../../actions/messages';
import { modalMessage } from '../../actions/modals';

// validate
import renderField from '../../formValidate/renderField';
import { maskDate } from '../../formValidate/masks';
import { date } from '../../formValidate';

// services
import { formatSubmit } from '../../services/message';

// Style
import { Sidebar, Row, Col } from '../../styles/align';
import {
  StyleForm,
  Wrapper,
  Label,
  BtnGroup,
  ButtonPrimary,
  Button,
} from '../../styles/forms';
import { ListTabs } from './style';
import Loader from '../../components/Loader';

class Message extends Component {
  componentDidMount() {
    this.props.fetchMessages();
  }

  handleTab(tipo) {
    this.props.type(tipo);
    this.props.show('');
    this.props.page(1);
    const dataSubmit = formatSubmit(this.props.filterMessages.values);

    this.props.fetchMessages(dataSubmit);
  }

  onSubmit = data => {
    const dataFetch = formatSubmit(data);

    this.props.fetchMessages(dataFetch);
  };

  render() {
    const { handleSubmit, reset, pristine, messages } = this.props;

    if (!messages) {
      return (
        <Sidebar widthContent="24%">
          <Loader />
        </Sidebar>
      );
    }

    return (
      <Sidebar dark widthContent="24%">
        <BtnGroup align={'center'}>
          <ButtonPrimary onClick={this.props.modalMessage}>
            Nova Mensagem
          </ButtonPrimary>
        </BtnGroup>
        <ListTabs>
          <li
            className={this.props.tipo === 'pendente' ? 'active' : 'hidden'}
            onClick={() => this.handleTab('pendente')}
          >
            Pendentes <span>({messages.pendente.total})</span>
          </li>
          <li
            className={this.props.tipo === 'resolvido' ? 'active' : 'hidden'}
            onClick={() => this.handleTab('resolvido')}
          >
            Resolvidos <span>({messages.resolvido.total})</span>
          </li>
        </ListTabs>
        <Form onSubmit={handleSubmit(this.onSubmit)}>
          <StyleForm>
            <h2>Filtros de Busca</h2>
            <Col className={'date__picker-container'}>
              <Row className={'date__picker-content'}>
                <Wrapper padding={'0'}>
                  <Label htmlFor={'data_ini'}>Data</Label>
                  <Field
                    name="data_ini"
                    id={'data_ini'}
                    type={'text'}
                    typefield={'mask'}
                    label={'Data Inicial'}
                    formatDate={'dd/MM/yyyy'}
                    placeholderText="Data inicial"
                    mask={maskDate}
                    validate={date}
                    component={renderField}
                  />
                </Wrapper>
                <p className={'date__picker-content-text'}>Até</p>
                <Wrapper padding={'0'} align={'flex-end'}>
                  <span></span>
                  <Field
                    name="data_fim"
                    type={'text'}
                    typefield={'mask'}
                    label={'Data Inicial'}
                    formatDate={'dd/MM/yyyy'}
                    placeholderText="Data Final"
                    mask={maskDate}
                    validate={date}
                    component={renderField}
                  />
                </Wrapper>
              </Row>
            </Col>
            <Wrapper>
              <Label htmlFor={'numero_pedido'}>Nome do Pedido</Label>
              <Field
                name={'numero_pedido'}
                id={'numero_pedido'}
                type={'text'}
                component={renderField}
              />
            </Wrapper>
            <BtnGroup justify={'space-around'} row={'row-reverse'}>
              <ButtonPrimary disabled={pristine} type={'submit'} secondary>
                Filtrar
              </ButtonPrimary>
              <Button onClick={reset}>Limpar Filtro</Button>
            </BtnGroup>
          </StyleForm>
        </Form>
      </Sidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    messages: state.messages.data,
    filterMessages: state.form.filterMessage,
    tipo: state.messages.tipo,
  };
};

Message = reduxForm({
  form: 'filterMessage',
})(Message);

Message = connect(mapStateToProps, {
  fetchMessages,
  modalMessage,
  type,
  show,
  page,
})(Message);

export default Message;
