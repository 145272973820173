import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// styles
import { Separator } from '../Home/style';

// components
import Header from './header';
import Card from './card';

//options
import { LANDING_PAGE } from '../option';

let TenOff = props => {
  useEffect(() => {
    const { landingRegister } = props;
    if (!landingRegister) {
      return props.history.push(LANDING_PAGE.home);
    }
  });

  return (
    <>
      <Header />
      <Separator />
      <Card />
      <Separator />
    </>
  );
};

const mapStateToProps = store => {
  return {
    landingRegister: store.landingPage.landingRegister,
  };
};

TenOff = connect(mapStateToProps, {})(TenOff);

export default withRouter(TenOff);
