import React from 'react';

// config
import config from '../../config';
// utils
import { numberFormat } from '../../utils';
// styles
import { StylePrice, StyleSummary } from './style';

/**
 * Popula o resumo com o título e dependência selecionada no filtro
 * @param {array} dependencias
 * @param {string} title
 */
function renderSummary(
  dependencias,
  title,
  prazo_producao,
  optionalfinishes,
  finish,
  additionalDeadline,
  pedidoItemAtributoAdicional,
  qtdPedidoItem,
  typeProduct,
  vlr_peso_pedido_item,
  subTipoProduto,
  selecteds,
  data
) {
  const deadline = prazo_producao + additionalDeadline;
  const lombada =
    (pedidoItemAtributoAdicional && pedidoItemAtributoAdicional.lomba) || '';
  const paginas_pb =
    (pedidoItemAtributoAdicional && pedidoItemAtributoAdicional.paginas_pb) ||
    '';
  const paginas_color =
    (pedidoItemAtributoAdicional &&
      pedidoItemAtributoAdicional.paginas_color) ||
    '';

  const hasBonecoQtd =
    selecteds &&
    selecteds.opcoes_quantidade === config.TYPE_BONECO &&
    typeProduct === 7;
  return (
    <ul>
      <li>
        {title ? (
          <>
            <span>Produto</span>
            <span>{title}</span>
          </>
        ) : (
          ''
        )}
      </li>
      <li>
        <span>Prazo de Produção</span>
        <span>
          {deadline > 1 ? `${deadline} dias úteis` : `${deadline} dia útil`}
        </span>
      </li>
      {dependencias.map(atributo => {
        if (atributo.fk_atributo === 6) {
          if (typeProduct === config.TYPE_BOOK) {
            atributo = {
              ...atributo,
              texto_aux_dependencia_atributo: qtdPedidoItem,
            };
          } else {
            atributo = {
              ...atributo,
              texto_aux_dependencia_atributo: `${qtdPedidoItem} x ${atributo.texto_aux_dependencia_atributo}`,
            };
          }
        }

        if (atributo.fk_atributo === 1) {
          if (typeProduct === config.TYPE_BOOK) {
            atributo = {
              ...atributo,
              texto_atributo: `Papel da Capa`,
            };
          } else {
            atributo = {
              ...atributo,
              texto_atributo: `Papel`,
            };
          }
        }

        return (atributo.fk_atributo === 3 && hasBonecoQtd) ||
          (atributo.fk_atributo === 3 &&
            qtdPedidoItem === 1 &&
            typeProduct === 7) ? (
          ''
        ) : (
          <li key={atributo.fk_atributo}>
            <span>{atributo.texto_atributo}</span>
            <span>{atributo.texto_aux_dependencia_atributo}</span>
          </li>
        );
      })}

      {vlr_peso_pedido_item ? (
        <li>
          <span>Peso do Produto</span>
          <span>{numberFormat(vlr_peso_pedido_item, 2)} Kg</span>
        </li>
      ) : (
        ''
      )}

      {pedidoItemAtributoAdicional &&
      pedidoItemAtributoAdicional.dependenciaAtributo ? (
        <li>
          <span>Papel do Miolo</span>
          <span>
            {
              pedidoItemAtributoAdicional.dependenciaAtributo
                .texto_dependencia_atributo
            }
          </span>
        </li>
      ) : (
        ''
      )}

      {optionalfinishes.map((acabamento, key) => {
        return (
          <li key={key}>
            <span>
              {acabamento.desc_acabamento
                ? acabamento.desc_acabamento
                : acabamento.acabamento.desc_acabamento
                ? acabamento.acabamento.desc_acabamento
                : ''}

              {acabamento.altura && acabamento.largura ? (
                <p>
                  ({acabamento.altura}x{acabamento.largura} cm)
                </p>
              ) : (
                ''
              )}
            </span>
            <span>
              R${' '}
              {acabamento.vlr_item_pedido_acabamento
                ? numberFormat(acabamento.vlr_item_pedido_acabamento)
                : acabamento.valor_acabamento
                ? numberFormat(acabamento.valor_acabamento)
                : '0,00'}
            </span>
          </li>
        );
      })}
      {lombada ? (
        <li>
          <span>Cálculo lombada aproximada: </span>
          <span>{numberFormat(lombada, 3)}mm</span>
        </li>
      ) : (
        ''
      )}
      {paginas_pb ? (
        <li>
          <span>Páginas preto e branco</span>
          <span>{paginas_pb}</span>
        </li>
      ) : (
        ''
      )}
      {paginas_color ? (
        <li>
          <span>Páginas coloridas</span>
          <span>{paginas_color}</span>
        </li>
      ) : (
        ''
      )}
      {finish ? (
        <li>
          <span>{finish.desc_acabamento}</span>
          <span>R$ {numberFormat(finish.valor_acabamento)}</span>
        </li>
      ) : (
        ''
      )}
    </ul>
  );
}

/**
 * imprime o valor total do produto
 * @param {float} vlr_pedido_item
 * @param {objeto} data [informações gerais sobre o produto]
 */
function renderPrice(
  vlr_total_item,
  vlr_unitario,
  additionalPrice,
  vlr_total_original_item,
  order,
  qtd_pedido_item
) {
  const unitaryValue = numberFormat(
    vlr_unitario + additionalPrice / qtd_pedido_item,
    2
  );
  const totalValue = numberFormat(vlr_total_item + additionalPrice, 2);

  return (
    <>
      <StylePrice>
        <span>Produto</span>
        <span>
          R${' '}
          {numberFormat(
            order ? vlr_total_original_item - additionalPrice : vlr_total_item,
            2
          )}
        </span>
      </StylePrice>
      {vlr_total_original_item !== vlr_total_item && order ? (
        <StylePrice>
          <span>Desconto</span>
          <span>
            R$ {numberFormat(vlr_total_original_item - vlr_total_item)}
          </span>
        </StylePrice>
      ) : (
        ''
      )}

      <StylePrice>
        <span>Valor Unitário</span>
        <span>R$ {unitaryValue}</span>
      </StylePrice>

      <StylePrice>
        <span>
          Valor Total<span>(Sem Frete)</span>
        </span>
        <span>R$ {totalValue}</span>
      </StylePrice>
    </>
  );
}

/**
 * Componente responsável por mostrar o resumo do produto
 * @param {objeto} data [informações gerais sobre o produto]
 * @param {string} title [nome do produto]
 * @param {float} additionalPrice [valores de servicos/acabamentos]
 */
const Summary = ({
  data,
  title,
  additionalPrice = 0,
  additionalDeadline = 0,
  optionalfinishes = [],
  finish = null,
  order = false,
  subTipoProduto,
  selecteds = {},
}) => {
  return (
    <StyleSummary>
      <h2>Resumo</h2>
      {renderSummary(
        data.dependencias,
        title,
        data.produto.qtd_dia_prazo_produto,
        optionalfinishes,
        finish,
        additionalDeadline,
        data.pedidoItemAtributoAdicional,
        data.qtd_pedido_item,
        data.produto.fk_tipo_produto,
        data.vlr_peso_pedido_item,
        selecteds,
        subTipoProduto
      )}
      {renderPrice(
        data.vlr_total_item,
        data.vlr_unitario,
        additionalPrice,
        data.vlr_total_original_item,
        order,
        data.qtd_pedido_item
      )}
    </StyleSummary>
  );
};
export default Summary;
