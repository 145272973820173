import React, { Component } from 'react';
import { connect } from 'react-redux';

// node-modules
import Icon from '@mdi/react';
import { mdiMenu, mdiClose } from '@mdi/js';

//actions
import { toggleMenu as openMenu } from '../../actions/menu';

// components
import Logo from '../Logo';
import SearchBar from '../SearchBar';
import Controls from '../Controls';
import Cart from '../Cart';

// styles
import { Container } from '../../styles/align';
import { header as StyleHeader, Intructions, MenuMobile, ContainerInstructions } from './style';
import { SocialMedias } from '../SocialMedias';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fixed: false,
      width: window.innerWidth,
    };
    this.handleResize = this.handleResize.bind(this);
  }

  header = React.createRef();

  handleResize() {
    this.setState({
      width: window.innerWidth,
    });
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  }

  // muda o estilo do header pra acompanhar a pagina ao rolar
  handleScroll = () => {
    const fixed = window.scrollY > 150;
    if (this.state.fixed !== fixed) {
      this.setState({ fixed });
    }
  };

  toggleMenu = () => {
    this.props.openMenu();
  };

  render() {
    return (
      <>
        <StyleHeader
          ref={this.header}
          position={this.state.fixed ? 'fixed' : 'relative'}
          className={this.props.open ? 'active' : 'hidden'}
        >
          <Container nopadding>
            <Logo />
            {this.state.width > 476 && <SocialMedias />}
            <SearchBar clear />
            <ContainerInstructions>
            <Intructions className={'header-link'}>
              <a
                target={'_blank'}
                rel={'noreferrer'}
                href="https://blog.fabricadolivro.com.br/serviços-editoriais"
                title={'Serviços Editoriais Fábrica do Livro'}
              >
                SERVIÇOS EDITORIAIS
              </a>
            </Intructions>
            <Intructions className={'header-link'}>
              <a
                target={'_blank'}
                rel={'noreferrer'}
                href="https://instrucoes.fabricadolivro.com.br"
                title={'Instruções Fábrica do Livro'}
              >
                INSTRUÇÕES
              </a>
            </Intructions>
            </ContainerInstructions>

            <Controls />
            <div>
              <Cart />
            </div>
            <MenuMobile onClick={this.toggleMenu}>
              {this.props.open ? (
                <Icon path={mdiClose} title={'Menu'} size={1.4} />
              ) : (
                <Icon path={mdiMenu} title={'Menu'} size={1.4} />
              )}
            </MenuMobile>
          </Container>
        </StyleHeader>
        {this.state.width <= 476 && <SocialMedias />}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    open: state.menu.open,
  };
};

export default connect(mapStateToProps, { openMenu })(Header);
