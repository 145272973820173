import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

//utils
import { dateTransform } from '../../../utils';

// styles
import { CardInfo } from '../../../styles/cards';
import { BtnGroup, ButtonPrimary, Textarea } from '../../../styles/forms';
import { IconEdit } from '../../../components/IconEdit';
import { ButtonGroup, FieldEditObservation, Wrapper } from '../style';

//actions
import { saveObservations } from '../../../actions/orders';

const ObservationNotaFiscal = ({ data }) => {
  const [editedObservation, setEditedObservation] = useState('');
  const [editedObservationIndex, setEditedObservationIndex] = useState(null);
  const dispatch = useDispatch();

  const handleEdit = (index, observation) => {
    setEditedObservationIndex(index);
    setEditedObservation(observation);
  };

  const handleSave = async (index, newValue) => {
    const updatedData = [...data];
    updatedData[index].obs_pedido = newValue;

    const idObservation = updatedData[index].pk_obs_pedido;

    setEditedObservation(newValue);
    setEditedObservationIndex(null);
    setEditedObservation('');

    try {
      dispatch(saveObservations(idObservation, newValue));
    } catch (error) {
      console.error('Erro ao salvar observações:', error);
    }
  };

  if (!data) {
    return '';
  }

  return (
    <CardInfo bg padding margin={'0 0 1rem 0'}>
      <h3>Observações da Nota Fiscal</h3>
      <ul>
        {data.map((item, i) => {
          return (
            <li key={i}>
              <span>
                {item.usuario.nome_usuario} -{' '}
                {dateTransform(item.dta_inc_obs_pedido)} :
              </span>
              {editedObservationIndex === i ? (
                <Wrapper>
                  <Textarea
                    value={editedObservation}
                    onChange={e => setEditedObservation(e.target.value)}
                  ></Textarea>
                  <BtnGroup>
                    <ButtonPrimary
                      onClick={() => handleSave(i, editedObservation)}
                    >
                      Salvar
                    </ButtonPrimary>
                  </BtnGroup>
                </Wrapper>
              ) : (
                <FieldEditObservation>
                  <p>
                    {item.obs_pedido}
                    {item.permite_editar_obs_nf && (
                      <ButtonGroup
                        onClick={() => handleEdit(i, item.obs_pedido)}
                      >
                        <IconEdit title="Editar" />
                      </ButtonGroup>
                    )}
                  </p>
                </FieldEditObservation>
              )}
            </li>
          );
        })}
      </ul>
    </CardInfo>
  );
};

export default ObservationNotaFiscal;
