import styled from 'styled-components';

// images
import Cvv from '../../assets/images/cvv.png';

// styles
import { colors, fontSize } from '../../styles/variables';
import { Wrapper, Input, Button } from '../../styles/forms';

/**
 * Formata o Container do KeyBoard [ Teclado ]
 * @extends {Wrapper}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {colors}
 */

export const Keyboard = styled.div`
  background-color: ${colors.default};
  padding: 0.5rem;
  position: absolute;
  display: none;
  justify-content: space-between;
  flex-wrap: wrap;
  left: 0;
  bottom: 40px;
  box-shadow: 1px 1px 10px ${colors.grey.darkSecondary};
  width: 8rem;
  z-index: 200;
  border-radius: 5px;
`;

/**
 * Formata o Botão do KeyBoard
 * @extends {button} Tag button nativa do HTML
 *
 * Herda estilo para formatar o interior do Container
 * @extends {colors}
 * @extends {fontSize}
 */
export const Key = styled.button`
  width: 2rem;
  height: 2rem;
  color: ${colors.default};
  background-color: ${colors.primary};
  font-size: ${fontSize.md};
  font-weight: 700;
  border-radius: 5px;
  border: none;
  margin-bottom: 0.5rem;
  cursor: pointer;
`;

/**
 * Formata o Grupo de Botões do KeyBoard
 * @extends {div} Tag div nativa do HTML
 */
export const GroupKey = styled.div`
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
`;

/**
 * Formata o Grupo de ações do Keyboard [ok ,Limpar]
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Button}
 * @extends {fontSize}
 * @extends {colors}
 */
export const GroupAction = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${Button} {
    margin: 0;
    text-transform: Capitalize;
    text-decoration: none;
    font-size: ${fontSize.sm};
    background-color: ${colors.default};
    color: ${colors.grey.darkSecondary};
    padding: 0;

    &:first-of-type {
      color: ${colors.secondary};
    }
  }
`;

/**
 * Formata o Container do KeyBoard [ Teclado + Input ]
 * @extends {Wrapper}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Input}
 * @extends {Cvv} => Image background
 */

export const WrapperKeyboard = styled(Wrapper)`
  cursor: pointer;
  max-width: 100px;

  @media (max-width: 768px) {
    max-width: 100%;
  }

  ${Input} {
    background-image: url(${Cvv});
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
  }

  .active {
    display: flex;
  }
`;
