import React from 'react';

// node
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

// Styles
import * as Align from '../../styles/align';
import * as Title from '../../styles/titles';
import * as Style from './style';
import * as Cards from '../../hocs/CardFilter/style';

const SkeletonFilter = () => {
  return (
    <SkeletonTheme color="#efefef" highlightColor="#f7f7f7">
      <Style.SkeletonStyle>
        <Align.Container>
          <Align.Col>
            <span>
              <Title.TitlePage>
                <Skeleton width={200} />
              </Title.TitlePage>
            </span>
            <Align.Row>
              <Align.MainPage>
                <Style.FilterContainer>
                  <Align.Row>
                    <Cards.CardFilter>
                      <Skeleton />
                    </Cards.CardFilter>
                    <Cards.CardFilter>
                      <Skeleton />
                    </Cards.CardFilter>
                  </Align.Row>
                  <Align.Row>
                    <Cards.CardFilter>
                      <Skeleton />
                    </Cards.CardFilter>
                    <Cards.CardFilter>
                      <Skeleton />
                    </Cards.CardFilter>
                  </Align.Row>
                </Style.FilterContainer>
              </Align.MainPage>
              <Style.SidebarFilter>
                <Skeleton />
              </Style.SidebarFilter>
            </Align.Row>
          </Align.Col>
        </Align.Container>
      </Style.SkeletonStyle>
    </SkeletonTheme>
  );
};

export default SkeletonFilter;
