import React, { useState, useEffect } from 'react';

import { Field } from 'redux-form';


// Validate
import { cpf, date, lastname, lengthCel, lengthPhone, required } from '../../../formValidate';
import {
  maskCelPhone,
  maskCpf,
  maskDate,
  maskPhone,
} from '../../../formValidate/masks';
import renderField from '../../../formValidate/renderField';
import { Col, Row } from '../../../styles/align';
import { Label, Wrapper } from '../../../styles/forms';
import { TitleCards } from '../../../styles/titles';

// api
import loja from '../../../apis/loja';



const RenderData = ({ onHandleCpf, onHandleDate, disabled, errors, onHandleClearErrors, loading }) => {
  const [segmentos, setSegmentos] = useState([]);

  useEffect(() => {
    const fetchSegmentos = async () => {
      try {
        const response = await loja.get('/usuario/buscar-segmento'); // Rota
        const segmentosData = response.data.data[2]; // Tipo "Pessoa Física"

        setSegmentos(segmentosData); // Atualize de acordo com a estrutura da sua resposta da API
      } catch (error) {
        console.error('Erro ao buscar segmentos:', error);
      }
    };

    fetchSegmentos();
  }, []);
  return (
    <Col>
      <div>
        <TitleCards>
          <span>{'Dados Pessoais'}</span>
        </TitleCards>
      </div>
      <Row>
        <Wrapper>
          <Label htmlFor={'cpf_cnpj_usuario'}>
            CPF<strong>*</strong>
          </Label>
          <Field
            name={'cpf_cnpj_usuario'}
            id={'cpf_cnpj_usuario'}
            type={'text'}
            placeholder={'Digite seu CPF'}
            validate={[required, cpf]}
            mask={maskCpf}
            onChange={onHandleCpf}
            disabled={disabled.cpf_cnpj_usuario}
            responseError={errors.cpf_cnpj_usuario}
            typefield={'firstField'}
            component={renderField}
            autoFocus
            loading={loading}
          />
        </Wrapper>
        <Wrapper>
          <Label htmlFor={'dta_nasc_usuario'}>
            Data de nascimento <strong>*</strong>
          </Label>
          <Field
            name={'dta_nasc_usuario'}
            id={'dta_nasc_usuario'}
            type={'text'}
            typefield={'mask'}
            component={renderField}
            placeholder={'99/99/9999'}
            validate={[required, date]}
            mask={maskDate}
            onChange={onHandleDate}
            disabled={disabled.dta_nasc_useuario}
            responseError={errors.dta_nasc_usuario}
            onFocus={e => onHandleClearErrors(e.target.name)}
            loading={loading}
            format={value => {
              if (!value) {
                return '';
              }
              const parts = value.split('-');
              if (parts.length === 3) {
                const [year, month, day] = parts;
                return `${day}/${month}/${year}`;
              }
              return value;
            }}
          />
        </Wrapper>
      </Row>
      <Row>
        <Wrapper>
          <Label htmlFor={'nome_usuario'}>
            Nome Completo<strong>*</strong>
          </Label>
          <Field
            name={'nome_usuario'}
            id={'nome_usuario'}
            type={'text'}
            component={renderField}
            placeholder={'Nome Completo'}
            validate={[required, lastname]}
            required
            disabled={disabled.nome_usuario}
            responseError={errors.nome_usuario}
            onFocus={e => onHandleClearErrors(e.target.name)}
            format={value => value ? value.replace(/[^\w\s.&áéíóúàèìòùâêîôûãẽĩõũäëïöüýÁÉÍÓÚÀÈÌÒÙÂÊÎÔÛÃẼĨÕŨÄËÏÖÜÝ&]/g, '') : ''}
            loading={loading}
          />
        </Wrapper>
      </Row>
      <Row>
        <Wrapper>
          <Label htmlFor={'nr_celular'}>
            Celular<strong>*</strong>
          </Label>
          <Field
            name={'nr_celular'}
            id={'nr_celular'}
            type={'text'}
            typefield={'mask'}
            component={renderField}
            placeholder={'(99) 99999-9999'}
            mask={maskCelPhone}
            validate={[required, lengthCel]}
            required
            responseError={errors.nr_celular}
            onFocus={e => onHandleClearErrors(e.target.name)}
          />

          
        </Wrapper>
        <Wrapper>
          <Label htmlFor={'nr_fone'}>Telefone</Label>
          <Field
            name={'nr_fone'}
            id={'nr_fone'}
            type={'text'}
            typefield={'mask'}
            component={renderField}
            placeholder={'(99) 9999-9999'}
            mask={maskPhone}
            validate={lengthPhone}
            responseError={errors.nr_fone}
            onFocus={e => onHandleClearErrors(e.target.name)}
          />
        </Wrapper>
      </Row>
      <Row>
        <Wrapper>
            <Label 
              htmlFor={'segmento'}>Segmento<strong>*</strong>
            </Label>
            <Field
              name={'segmento'}
              id={'segmento'}
              type={'select'}
              typefield={'select'}
              component={renderField}
              validate={required}
              required
              getGlobalFormStyle={true}
              disabled={disabled.segmento}
              firstOption={
                <option value='' disabled>
                  Selecione seu segmento
                </option>
              }
              options={segmentos.map(segmento => (
                <option key={segmento.id} value={segmento.id}>
                  {segmento.desc}
                </option>
              ))}
              
            >
              
            </Field>
          </Wrapper>
      </Row>
    </Col>
  );
};

export default RenderData;