import React from 'react';

// hocs
import DataPayment from '../../../hocs/DataPayment';

// Styles
import * as Cards from '../../../styles/cards';

const Payment = ({ payment }) => {
  return (
    <div>
      {Object.entries(payment).map((item, i) => {
        return (
          <Cards.Info key={i}>
            <Cards.Desc>{item[0]} : </Cards.Desc>
            <Cards.Value className={i === 0 ? 'price' : ''}>
              {item[1]}
            </Cards.Value>
          </Cards.Info>
        );
      })}
    </div>
  );
};

export default DataPayment(Payment);
