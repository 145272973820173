import styled from 'styled-components';

// Style
import { colors, fontSize } from '../../styles/variables';
import { BtnGroup, Button, Wrapper, Label } from '../../styles/forms';

/**
 * Formata o container da pagina de mensagens
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do container
 * @extends {Wrapper}
 * @extends {Label}
 * @extends {BtnGroup}
 * @extends {colors}
 *
 */
export const StyleMessage = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: ${colors.grey.light};
  margin-bottom: 1rem;
  padding: 1rem;

  ${Wrapper} {
    padding: 0 1rem 1rem 1rem;
    justify-content: flex-end;

    ${Label} {
      color: ${colors.primary};
      padding: 0;
    }
  }

  ${BtnGroup} {
    padding: 1rem;
    justify-content: center;
    flex-direction: row-reverse;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

/**
 * Formata as tabs [Resolvido | Pendente]
 * @extends {ul} Tag ul nativa do HTML
 *
 * Herda estilo para formatar o interior das tabs
 * @extends {fontSize}
 * @extends {colors}
 *
 */
export const ListTabs = styled.ul`
  margin: 2rem 0;
  list-style: none;
  color: ${colors.primary};
  font-size: ${fontSize.lg};
  padding: 0 1rem;

  li {
    line-height: 1.5;
    width: fit-content;
    margin-bottom: 0.5rem;
    cursor: pointer;

    &.active {
      border-bottom: 2px solid ${colors.error};
    }

    span {
      font-weight: 600;
      color: ${colors.secondary};
    }
  }
`;

/**
 * Formata o Container do Caso
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do Container
 * @extends {colors}
 *
 */
export const MessageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 1rem;
  background-color: ${colors.grey.light};
`;

/**
 * Formata o Header do Caso [caso | title]
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do Header
 * @extends {colors}
 *
 */
export const HeaderMessage = styled.div`
  padding: 2rem;
  font-size: ${colors.primary};
  border-bottom: 1px solid ${colors.grey.lightSecondary};
  background-color: ${colors.default};
`;

/**
 * Formata o Detalhes do Caso [data | avaliacao]
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do Detalhes
 * @extends {colors}
 *
 */
export const DetailsMessage = styled.div`
	width: 30%;
	padding: 2rem;
	background-color: ${colors.default};

	h3 {
        color: ${colors.primary};
        font-size: ${fontSize.lg}
        margin-bottom: 1rem;
    }
    
    ul {
      list-style: none;

      li {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        line-height: 1.5;
        padding: .5rem;
    
        &:nth-child(odd) {
          background-color: ${colors.grey.lightPrimary};
        }

        a {
          color: ${colors.primary};
          margin-left: .5rem;
        }
      }
    }

    @media (max-width: 768px) {
		width: 100%;
	}
`;

/**
 * Formata o conteudo do Caso [Operador | Mensagem | Data]
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do Detalhes
 * @extends {colors}
 *
 */
export const ContentMessage = styled.div`
  padding: 2rem;
  width: 70%;

  @media (max-width: 768px) {
    width: 100%;
  }

  ul {
    list-style: none;

    li {
      display: flex;
      flex-direction: row;
      padding: 2rem 0;
      border-bottom: 1px solid ${colors.grey.lightPrimary};

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }
  }
`;

/**
 * Formata o Autor da Mensagem
 * @extends {p} Tag p nativa do HTML
 *
 * Herda estilo para formatar o interior do Autor
 * @extends {fontSize}
 * @extends {colors}
 *
 */
export const Title = styled.p`
  color: ${colors.primary};
  font-size: ${fontSize.md};
  font-weight: 600;
  margin-bottom: 1rem;
`;

/**
 * Formata o Header da Mensagem [ autor | Data]
 * @extends {p} Tag p nativa do HTML
 *
 * Herda estilo para formatar o interior do Header
 * @extends {fontSize}
 * @extends {colors}
 *
 */
export const Header = styled.span`
  width: 40%;
  padding-right: 2rem;

  p {
    &:last-of-type {
      font-size: ${fontSize.sm};
      color: ${colors.grey.darkSecondary};
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 0 1rem 0;
    border-right: none;
  }
`;

/**
 * Formata o Conteudo da Mensagem
 * @extends {span} Tag span nativa do HTML
 *
 * Herda estilo para formatar o interior do Conteudo
 * @extends {colors}
 *
 */
export const Content = styled.span`
  width: 60%;
  padding: 1rem 2rem;
  color: ${colors.grey.darkSecondary};
  background-color: ${colors.default};
  border-radius: 10px;
  box-shadow: -4px 1px 16px #d9d9d9;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    padding: 1rem;
    border-bottom: 2px solid ${colors.grey.lightSecondary};
  }

  &::before {
    content: "";
    width: 20px;
    height: 20px;
    background-color: ${colors.default};
    left: -7px;
    position: absolute
    transform: rotate(45deg);

    @media (max-width: 768px) {
      left: 10px;
      top: -7px;
    }
  }
`;

/**
 * Formata os detalhes de uma nova mensagem
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do detalhes
 * @extends {fontSize}
 * @extends {colors}
 *
 */
export const NewMessageDetails = styled.div`
  h4 {
    margin: 1rem 0;
    color: ${colors.primary};
  }

  p {
    margin-bottom: 0.5rem;
    font-size: ${fontSize.sm};
  }

  textarea {
    min-width: 100%;
    max-width: 100%;
    min-height: 120px;
    max-height: 120px;
    border: 1px solid ${colors.grey.lightSecondary};
    border-radius: 5px;
  }
`;

/**
 * Formata a ação de reabrir um caso
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do ação
 * @extends {BtnGroup}
 * @extends {Button}
 * @extends {fontSize}
 * @extends {colors}
 *
 */
export const ReopenMessage = styled.div`
  h4 {
    margin: 1rem 0;
    color: ${colors.primary};
  }

  p {
    margin-bottom: 0.5rem;
    font-size: ${fontSize.sm};
  }

  ${BtnGroup} {
    flex-direction: column;
    align-items: center;

    ${Button} {
      font-size: ${fontSize.sm};
      width: fit-content;
      margin-bottom: 1rem;
    }
  }
`;
