import loja from '../apis/loja';

import {
  FETCH_CART,
  FETCH_DELIVERY_OPTIONS,
  CLEAR_CART,
  CLEAR_DELIVERY_OPTIONS,
  NUMBER_ITEMS,
  SUMMARY_CART,
  TYPE_DELIVERY,
  DELIVERY_SELECTED,
  BALCONY_SELECTED,
  SAVE_WITHDRAWAL,
  CLEAR_CART_SUMMARY,
  SAVE_SHIPPING_CONTACT,
  RESET_SHIPPING_CONTACT,
  RESET_WITHDRAWAL,
  SUBMIT_FORM_CART,
  SET_CUPOM,
  UPDATE_CART,
} from './types';
import { toast } from 'react-toastify';

/**
 * Busca o objeto do carrinho na API enviando o token de confirmação
 * @param {string} token
 */
export const fetchCart = token => async dispatch => {
  try {
    const response = await loja.get('/carrinho', {
      params: { token },
    });
    const numberItems = response.data.data.carrinho.itens
      ? response.data.data.carrinho.itens.length
      : 0;

    dispatch({
      type: NUMBER_ITEMS,
      payload: numberItems,
    });
    dispatch({ type: FETCH_CART, payload: response.data });
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

/**
 * Busca as opções de entrega para o endereco selecionado
 * - Caso nao envie o endereco, pega o padrao do usuario logado
 * @param {string} token token do carrinho
 * @param {integer} endereco pk_usuario_endereco
 */
export const fetchDeliveryOptions = (token, endereco = null) => async dispatch => {
  const maxAttempts = 3; // Número máximo de tentativas
  let attempts = 0; // Contador de tentativas
  const data = endereco ? { token, endereco } : { token };

  async function tryFetch() {
    try {
      const response = await loja.post('/carrinho/opcoes-de-entrega', data);
      dispatch({ type: FETCH_DELIVERY_OPTIONS, payload: response.data });
      return true; // Indica sucesso
    } catch (error) {
      if (attempts >= maxAttempts) {
        toast.error(error.response.data.message);
        return false; // Indica falha após a última tentativa
      }
      attempts++;
    }
  }

  while (attempts < maxAttempts) {
    const success = await tryFetch();
    if (success) break; // Sai do loop se a tentativa for bem-sucedida
  }
};

/**
 * Reseta os dados do carrinho no store
 */
export const clearCart = () => {
  return {
    type: CLEAR_CART,
  };
};

/**
 * Reseta os dados da entrega no carrinho
 */
export const clearDeliveryOptions = () => {
  return {
    type: CLEAR_DELIVERY_OPTIONS,
  };
};

/**
 * Contador de itens no carrinho
 */
export const numberItens = number => {
  return {
    type: NUMBER_ITEMS,
    payload: number,
  };
};

/**
 * atualiza as informações do resumo
 */
export const setSummary = value => {
  return {
    type: SUMMARY_CART,
    payload: value,
  };
};

/**
 * tipo de entrega selecionada
 */
export const typeDelivery = value => {
  return {
    type: TYPE_DELIVERY,
    payload: value,
  };
};

/**
 * verifica se foi selecionado entrega normal
 */
export const deliverySelected = value => {
  return {
    type: DELIVERY_SELECTED,
    payload: value,
  };
};

/**
 * verifica se foi selecionado entrega por balcao
 *
 */
export const balconySelected = value => {
  return {
    type: BALCONY_SELECTED,
    payload: value,
  };
};

/**
 * verifica se esta salvo os dados de retirada de balcão
 *
 */
export const saveWithdrawal = () => {
  return {
    type: SAVE_WITHDRAWAL,
  };
};

/**
 * verifica se esta salvo os dados de contato para entrega
 *
 */
export const saveShippingContact = () => {
  return {
    type: SAVE_SHIPPING_CONTACT,
  };
};

/**
 * Reseta os dados do resumo do carrinho
 */
export const clearCartSummary = () => {
  return {
    type: CLEAR_CART_SUMMARY,
  };
};

/**
 * Reseta os dados do contato para envio
 */
export const resetShippingContact = () => {
  return {
    type: RESET_SHIPPING_CONTACT,
  };
};

/**
 * Reseta os dados do responsavel pela retirada
 */
export const resetWithdrawal = () => {
  return {
    type: RESET_WITHDRAWAL,
  };
};

/**
 * Flag que verifica se o form precisa do submit
 */
export const isSubmitForm = value => {
  return {
    type: SUBMIT_FORM_CART,
    payload: value,
  };
};

/**
 * Mantem o cupom no redux do carrinho
 */

export const setCupom = ({ cupom }) => {
  return {
    type: SET_CUPOM,
    payload: cupom,
  };
};

/**
 * Atualiza o state do carrinho com base no retorno do request
 */

export const updateCart = data => {
  return {
    type: UPDATE_CART,
    payload: data,
  };
};
