import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// actions
import { modalOrderErrors } from "../../actions/modals";

//hocs
import Modal from "../../hocs/Modal";

// Styles
import { OrderErrorsStyle } from "./style";
import { BtnGroup, Button, ButtonPrimary } from "../../styles/forms";

class OrderErrors extends Component {
  handleRedirect = () => {
    this.props.modalOrderErrors();
  };

  render() {
    const { aprovacao, problema, msg_aprovacao, msg_pedido } = this.props.data;

    if (!this.props.data) {
      return "";
    }

    return (
      <OrderErrorsStyle>
        <h2>Foram encontrado(s):</h2>
        <span>
          {aprovacao ? (
            <>
              <p>{aprovacao}</p>
              <p>{msg_aprovacao}</p>
            </>
          ) : (
            ""
          )}
          {problema ? (
            <>
              <p>{problema}</p>
              <p>{msg_pedido}</p>
            </>
          ) : (
            ""
          )}
        </span>
        <BtnGroup>
          <Button onClick={this.props.modalOrderErrors}>Cancelar</Button>
          <Link to={"/pedidos"}>
            <ButtonPrimary onClick={this.handleRedirect}>
              Ver Pedidos
            </ButtonPrimary>
          </Link>
        </BtnGroup>
      </OrderErrorsStyle>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.modals.modalOrderErrors.data
  };
};

OrderErrors = connect(mapStateToProps, { modalOrderErrors })(OrderErrors);

OrderErrors = Modal(OrderErrors);

export default OrderErrors;
