import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

// components
import SearchBar from '../../components/SearchBar';
import Products from '../../components/Products';
import InfinityScroll from '../../components/infinityScroll';

// Skeleton
import SkeletonProducts from '../../components/Products/skeleton';

// actions
import { fetchSearch, clearSearch } from '../../actions/search';

// utils
import { desc, asc, alf } from '../../utils/arr-utils';

// Styles
import { Filter, Result } from './style';
import { Container, Row, Col } from '../../styles/align';
import { Wrapper, Select, Option } from '../../styles/forms';
import { TitlePage } from '../../styles/titles';

// Options
import { order } from './options';

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: 'menor',
      max: 20,
    };
  }

  componentDidMount() {
    const search = queryString.parse(this.props.location.search);

    this.props.fetchSearch({ pesquisa: search.keywords }).then(() => {
      this.setState({
        max: 20,
      });
    });
  }

  componentWillUnmount() {
    this.props.clearSearch();
  }

  handleProcess = data => {
    const { order, max } = this.state;
    let newData = data;

    if (order === 'menor') {
      newData = asc(data, 'vlr_preco');
    } else if (order === 'maior') {
      newData = desc(data, 'vlr_preco');
    } else {
      newData = alf(data, 'desc_menu');
    }

    newData = newData.slice(0, max);
    return newData;
  };

  updateInfinity = value => {
    this.setState({
      max: value,
    });
  };

  selectOrder = value => {
    this.setState({
      order: value,
      max: 20,
    });
  };

  renderFilter() {
    const { selectOrder } = this;
    const { result } = this.props;

    return (
      <React.Fragment>
        <TitlePage>
          <span>Busca: {result ? result.termo : ''}</span>
        </TitlePage>
        <Filter>
          <Row>
            <Col>
              <SearchBar />
            </Col>
            <Col>
              <Wrapper>
                <Select
                  name={'order_search'}
                  id={'order_search'}
                  defaultValue={this.state.order}
                  onChange={e => selectOrder(e.currentTarget.value)}
                >
                  {order.map((item, i) => {
                    return (
                      <Option key={i} value={item.value}>
                        {item.text}
                      </Option>
                    );
                  })}
                </Select>
              </Wrapper>
            </Col>
          </Row>
          <Result>
            <p>
              <strong>{result ? result.registros : 0} resultados</strong> para o
              termo: "{result ? result.termo : ''}"
            </p>
          </Result>
        </Filter>
      </React.Fragment>
    );
  }

  renderResult() {
    const { handleProcess, updateInfinity } = this;
    const { result } = this.props;

    if (!result || result.length === 0) {
      return <SkeletonProducts />;
    }

    return (
      <InfinityScroll
        updateInfinity={updateInfinity}
        maxItems={this.state.max}
        stepItems={20}
        limit={this.state.max > result.resultado.length}
        data={handleProcess(result.resultado)}
        base={result.url_base}
        otherPath={true}
        ComponentData={props => <Products {...props} />}
      />
    );
  }

  render() {
    return (
      <Container>
        <Col>
          {this.renderFilter()}
          {this.renderResult()}
        </Col>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    result: state.search.data,
  };
};

Search = connect(mapStateToProps, { fetchSearch, clearSearch })(Search);

export default Search;
