import loja from '../apis/loja';

import {
  LANDING_REGISTER,
  LANDING_REGISTER_COMPLETE,
  LANDING_NOT_LIKE,
} from './types';

/**
 * Salva dados da Landing page, pré-cadastro da home landing
 */
export const landingRegister = (
  nome_landing,
  email_usuario,
  ddd_celular,
  nr_celular,
  fk_estado,
  pk_cidade,
  pk_titulo,
  pk_tiragem
) => async dispatch => {
  try {
    const response = await loja.post('/usuario/landing-register', {
      nome_landing,
      email_usuario,
      ddd_celular,
      nr_celular,
      fk_estado,
      pk_cidade,
      pk_titulo,
      pk_tiragem,
    });

    dispatch({ type: LANDING_REGISTER, payload: response.data });
    return response;
  } catch (error) {
    return error.response.data.message;
  }
};

/**
 * Salva dados da Landing page, cadastro final
 */
export const landingRegisterComplete = (
  pk_usuario,
  pk_usuario_editora,
  cargo_landing,
  producao_landing,
  entrega_landing,
  menor_custo_landing,
  impressao_landing,
  design_landing,
  producao_func_landing,
  ecommerce_landing,
  alcance_landing,
  indica_landing
) => async dispatch => {
  try {
    const response = await loja.post('/usuario/landing-register-complete', {
      pk_usuario,
      pk_usuario_editora,
      cargo_landing,
      producao_landing,
      entrega_landing,
      menor_custo_landing,
      impressao_landing,
      design_landing,
      producao_func_landing,
      ecommerce_landing,
      alcance_landing,
      indica_landing,
    });

    dispatch({ type: LANDING_REGISTER_COMPLETE, payload: response.data });
    return response;
  } catch (error) {
    return error.response.data.message;
  }
};

/**
 * Caso o lead clique em "Não gosto de desconto"
 * O mesmo será notificado com e-mail do cupom de desconto 5off
 */
export const landingNotLikeMore = (
  pk_usuario,
  pk_usuario_editora
) => async dispatch => {
  try {
    const response = await loja.post('/usuario/landing-not-like', {
      pk_usuario,
      pk_usuario_editora,
    });

    dispatch({ type: LANDING_NOT_LIKE, payload: response.data });
    return response;
  } catch (error) {
    return error.response.data.message;
  }
};
