import React from "react";
import { PiQuestionLight } from "react-icons/pi";
import { Field } from "redux-form";

import Tooltip from '../../../components/Tooltip';
import { required } from "../../../formValidate";
// Validate
import renderField from "../../../formValidate/renderField";
// Styles
import { Row } from "../../../styles/align";
import { Label, Wrapper } from "../../../styles/forms";
import { OmieStyle, TitleCard, TitleRadio } from './../style';

const RenderDataOmie = ({
  errors,
  onHandleClearErrors
}) => {
  return (
    <OmieStyle>
      <Row>
        <TitleRadio >
          <TitleCard>
            Consumidor Final? <span>*</span>
          </TitleCard>
        </TitleRadio>
        <Row>
          <Wrapper>
            <Field
              name={"consumidor_final"}
              id={"consumidor_final[0]"}
              type={"radio"}
              typefield={"radio"}
              component={renderField}
              value={"1"}
              validate={[required]}
              onFocus={e => onHandleClearErrors(e.target.name)}
            />
            <Label htmlFor={"consumidor_final[0]"}>Sim</Label>
          </Wrapper>
          <Wrapper>
            <Field
              name={"consumidor_final"}
              id={"consumidor_final[1]"}
              type={"radio"}
              component={renderField}
              typefield={"radio"}
              value={"0"}
              validate={[required]}
              onFocus={e => onHandleClearErrors(e.target.name)}
            />
            <Label htmlFor={"consumidor_final[1]"}>Não</Label>
          </Wrapper>
        </Row>
        <Tooltip
          dataId={"consumidor_final"}
          text={'Consumidor Final: Quem adquire o produto como destinatário final, seja ele Pessoa Física ou Jurídica, cujo foco seja no uso próprio e não na revenda.'}
          icon={<PiQuestionLight size={18} />}
        />
      </Row>
      {/* <Row>
        <TitleRadio >
          <TitleCard>
            Importado? <span> *</span>
          </TitleCard>
        </TitleRadio>
        <Row>
          <Wrapper>
            <Field
              name={"importado"}
              id={"importado[0]"}
              type={"radio"}
              typefield={"radio"}
              component={renderField}
              value={"1"}
              validate={[required]}
              onFocus={e => onHandleClearErrors(e.target.name)}
            />
            <Label htmlFor={"importado[0]"}>Sim</Label>
          </Wrapper>
          <Wrapper>
            <Field
              name={"importado"}
              id={"importado[1]"}
              type={"radio"}
              component={renderField}
              typefield={"radio"}
              value={"0"}
              validate={[required]}
              onFocus={e => onHandleClearErrors(e.target.name)}
            />
            <Label htmlFor={"importado[1]"}>Não</Label>
          </Wrapper>
        </Row>          
      </Row> */}
      <Row>
        <TitleRadio >
          <TitleCard>
            ICMS ST? <span> *</span>
          </TitleCard>
        </TitleRadio>
        <Row>
          <Wrapper>
            <Field
              name={"icms_st"}
              id={"icms_st[0]"}
              type={"radio"}
              typefield={"radio"}
              component={renderField}
              value={"1"}
              validate={[required]}
              onFocus={e => onHandleClearErrors(e.target.name)}
            />
            <Label htmlFor={"icms_st[0]"}>Sim</Label>
          </Wrapper>
          <Wrapper>
            <Field
              name={"icms_st"}
              id={"icms_st[1]"}
              type={"radio"}
              component={renderField}
              typefield={"radio"}
              value={"0"}
              validate={[required]}
              onFocus={e => onHandleClearErrors(e.target.name)}
            />
            <Label htmlFor={"icms_st[1]"}>Não</Label>
          </Wrapper>
        </Row>
       <Tooltip
        dataId={"icms_st"}
        text={'ICMS ST: Caso seja Pessoa Jurídica, informe se sua empresa faz o recolhimento do ICMS através de substituição tributária.'}
        icon={<PiQuestionLight size={18} />}
      />
      </Row>
      <Row>     
        <TitleRadio >
          <TitleCard>
            Comercialização? <span> *</span>
          </TitleCard>
        </TitleRadio> 
        <Row>
          <Wrapper>
            <Field
              name={"comercializacao"}
              id={"comercializacao[0]"}
              type={"radio"}
              typefield={"radio"}
              component={renderField}
              value={"1"}
              validate={[required]}
              onFocus={e => onHandleClearErrors(e.target.name)}
            />
            <Label htmlFor={"comercializacao[0]"}>Sim</Label>
          </Wrapper>
          <Wrapper>
            <Field
              name={"comercializacao"}
              id={"comercializacao[1]"}
              type={"radio"}
              component={renderField}
              typefield={"radio"}
              value={"0"}
              validate={[required]}
              onFocus={e => onHandleClearErrors(e.target.name)}
            />
            <Label htmlFor={"comercializacao[1]"}>Não</Label>
          </Wrapper>
        </Row> 
        <Tooltip
          dataId={"comercializacao"}
          text={'Comercialização: Informe se os produtos serão revendidos após a compra.'}
          icon={<PiQuestionLight size={18} />}
        />         
      </Row>
      <Row>
        <TitleRadio >
          <TitleCard>
            Industrialização? <span> *</span>
          </TitleCard>
        </TitleRadio>
        <Row>
          <Wrapper>
            <Field
              name={"industrializacao"}
              id={"industrializacao[0]"}
              type={"radio"}
              typefield={"radio"}
              component={renderField}
              value={"1"}
              validate={[required]}
              onFocus={e => onHandleClearErrors(e.target.name)}
            />
            <Label htmlFor={"industrializacao[0]"}>Sim</Label>
          </Wrapper>
          <Wrapper>
            
            <Field
              name={"industrializacao"}
              id={"industrializacao[1]"}
              type={"radio"}
              component={renderField}
              typefield={"radio"}
              value={"0"}
              validate={[required]}
              onFocus={e => onHandleClearErrors(e.target.name)}
            />
            <Label htmlFor={"industrializacao[1]"}>Não</Label>
          </Wrapper>
        </Row>
        <Tooltip
          dataId={"industrializacao"}
          text={'Industrialização: Informe se os produtos passarão por algum tipo de industrialização após a compra.'}
          icon={<PiQuestionLight size={18} />}
        />
      </Row>
    </OmieStyle>
  );
};

export default RenderDataOmie;
