import React from 'react';

//utils
import { dateTransform } from '../../../utils';

// styles
import { CardInfo } from '../../../styles/cards';


const Observation = ({ data }) => {

  if (!data) {
    return '';
  }

  return (
    <>
      <CardInfo bg padding margin={'0 0 1rem 0'}>
        <h3>Observações gerais</h3>
        <ul>
          {data.map((item, i) => {
            return (
              <li key={i}>
                <span>
                  {item.usuario.nome_usuario} -{' '}
                  {dateTransform(item.dta_inc_obs_pedido)}
                </span>
                <p>
                  {item.obs_pedido}
                </p>
              </li>
            );
          })}
        </ul>
      </CardInfo>
    </>
  );
};

export default Observation;
