import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

//actions
import { fetchSearch } from '../../actions/search';

// icons
import { IconSearch } from '../../shared/icons';

// styles
import { StyleSearchBar, Button, Input } from './style';

class SearchBar extends Component {
  constructor(props) {
    super(props);

    const search = queryString.parse(window.location.search);
    const termo = search.keywords;

    this.state = {
      value: termo && !this.props.clear ? termo : '',
    };
  }

  handleSearch = () => {
    // Redirect necessario para que o google capture o carregamento da pagina
    window.location.href = `/pesquisa?keywords=${this.state.value}`;
  };

  render() {
    return (
      <StyleSearchBar>
        <Input
          type="text"
          placeholder="Digite aqui o produto que você deseja"
          onChange={e => this.setState({ value: e.target.value })}
          value={this.state.value}
          onKeyUp={e => (e.keyCode === 13 ? this.handleSearch() : '')}
        />
        <Button type="button" onClick={() => this.handleSearch()}>
          <IconSearch />
        </Button>
      </StyleSearchBar>
    );
  }
}

SearchBar = connect(null, { fetchSearch })(SearchBar);

export default withRouter(SearchBar);
