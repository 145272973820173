import React from 'react';
import { Field } from 'redux-form';

// hocs
import CardFilter from '../../../../hocs/CardFilter';

// Validate
import renderField from '../../../../formValidate/renderField';

// Styles
import { Row } from '../../../../styles/align';
import { Wrapper, Label } from '../../../../styles/forms';
import { TitleCards } from '../../../../styles/titles';
import { Colors } from './style';

const BookContent = ({ data, onChangeSubmit }) => {
  const {
    dependencias_adicionais_cores,
    tipos_papel_miolo,
    lados_impressao,
  } = data;

  return (
    <>
      <TitleCards>Cores da Impressão e tipo de Papel</TitleCards>
      <Colors>
        {dependencias_adicionais_cores ? (
          dependencias_adicionais_cores.length === 2 ? (
            dependencias_adicionais_cores.map((item, i) => {
              return (
                <Wrapper key={i}>
                  <Field
                    name={'dependencias_adicionais_cores'}
                    id={`dependencias_adicionais_cores[${i}]`}
                    type={'radio'}
                    typefield={'radio'}
                    component={renderField}
                    value={`${item.pk_dependencia_atributo}`}
                    onChange={(event, newValue, previousValue, name) =>
                      onChangeSubmit(event, newValue, previousValue, name)
                    }
                  />
                  <Label htmlFor={`dependencias_adicionais_cores[${i}]`}>
                    {item.cor_impressao}
                  </Label>
                </Wrapper>
              );
            })
          ) : (
            <p>{tipos_papel_miolo.cor_impressao}</p>
          )
        ) : (
          ''
        )}
      </Colors>
      <Row>
        {tipos_papel_miolo
          ? tipos_papel_miolo.opcoes.map((item, i) => {
              return (
                <Wrapper key={i}>
                  <Field
                    name={'tipos_papel_miolo'}
                    id={`tipos_papel_miolo[${i}]`}
                    type={'radio'}
                    typefield={'radio'}
                    component={renderField}
                    value={`${item.pk_dependencia_atributo}`}
                    onChange={(event, newValue, previousValue, name) =>
                      onChangeSubmit(event, newValue, previousValue, name)
                    }
                  />
                  <Label htmlFor={`tipos_papel_miolo[${i}]`}>
                    {item.texto_dependencia_atributo}
                  </Label>
                </Wrapper>
              );
            })
          : ''}
      </Row>
      {lados_impressao && lados_impressao.opcoes.length > 1 ? (
        <>
          <TitleCards>Lados da Impressão do Miolo</TitleCards>
          <Row>
            {lados_impressao.opcoes.map((item, i) => {
              return (
                <Wrapper key={i}>
                  <Field
                    name={'lados_impressao'}
                    id={`lados_impressao[${i}]`}
                    type={'radio'}
                    typefield={'radio'}
                    component={renderField}
                    value={`${item.pk_dependencia_atributo}`}
                    onChange={(event, newValue, previousValue, name) =>
                      onChangeSubmit(event, newValue, previousValue, name)
                    }
                  />
                  <Label htmlFor={`lados_impressao[${i}]`}>
                    {item.texto_dependencia_atributo}
                  </Label>
                </Wrapper>
              );
            })}
          </Row>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default CardFilter(BookContent);
