import styled from 'styled-components';

import ImageBack from '../../../assets/images/20039132_l.png';

import { ColGrid, getWidthGrid } from '../../../styles/align';
import { colors, fontSize } from '../../../styles/variables';

import { Label as LabelForm } from '../../../styles/forms';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  //height: 27.625rem;
  background: url(${ImageBack}) no-repeat center,
    linear-gradient(
      90deg,
      rgba(39, 164, 137, 255) 0%,
      rgba(15, 58, 76, 255) 100%
    );
  background-size: 100%;
  background-position: top;

  @media only screen and (max-width: 768px) {
    display: block;
    background-size: cover;
    height: 50.625rem;
  }
`;

export const Logo = styled.div`
  width: 100%;
  height: 3.125rem;
  display: flex;
  align-items: center;
  
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 25.125rem;
  margin-left: auto;

  @media only screen and (max-width: 768px) {
    display: block;
    text-align: justify;
  }
`;

export const H1 = styled.h1`
  color: ${colors.secondary};
  font-size: 4rem;
`;

export const Editora = styled.h1`
  color: ${colors.default};
  font-size: 4rem;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Together = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  max-width: 300px;

  p:first-child {
    margin-left: 1rem;
  }

  p {
    color: ${colors.default};
    font-size: ${fontSize.xl};
    font-weight: 600;
  }
`;

export const InfoForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding-top: 1rem;
  max-width: 500px;
  line-height: 1.75rem;

  p {
    color: ${colors.default};
    font-weight: 300;
  }
`;

export const ContainerForm = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  //height: 26.25rem;
  background: linear-gradient(
    90deg,
    rgba(145, 242, 248, 255) 0%,
    rgba(12, 29, 74, 255) 100%
  );
`;

export const ContainerCard = styled.div`
  width: 100%;
  //height: 25rem;
`;

export const ContainerFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  //height: 26.25rem;
  background: linear-gradient(
    90deg,
    rgba(2, 110, 98, 255) 0%,
    rgba(12, 29, 74, 255) 100%
  );
`;

export const HeaderFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  padding: 1rem;
  color: ${colors.secondary};
`;

export const TextFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 2rem;
  padding: 0.2rem;

  p {
    color: ${colors.default};
  }
`;

export const TextFinal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  h2 {
    color: ${colors.default};
    font-weight: 500;
  }

  a {
    color: ${colors.secondary};
    padding-top: 2rem;
    font-weight: 600;
  }
`;

export const StyleLogo = styled.img`
  max-width: 190px;
  margin: 0 5rem;

  @media (max-width: 768px) {
    margin: 1rem;
  }
`;

export const Separator = styled.hr`
  width: 100%;
  border-color: ${colors.secondary};
`;

export const SeparatorForm = styled(Separator)`
  margin: 0.5rem 0 0.5rem 0rem;
`;

export const SeparatorHalf = styled.hr`
  width: 20%;
  border-color: ${colors.secondary};
  margin: auto;
`;

export const SeparatorCard = styled.hr`
  width: 50%;
  border-color: ${colors.secondary};
  margin: auto;
`;

export const StyleImage = styled.img`
  width: 100%; /* or any custom size */
  height: 100%;
  object-fit: cover;
  position: absolute;

  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const StyleImageCard = styled.img`
  max-width: 150px;
  max-height: 150px;
`;

export const RowCard = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;
`;

export const RowImage = styled.div`
  margin-left: 5rem;
`;

export const RowText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 768px) {
    margin-left: 30%;
  }

  @media only screen and (min-width: 1000px) {
    margin-left: unset;
  }
`;

export const TextCard = styled.h5`
  font-weight: 500;
  color: ${colors.grey.darkPrimary};
  padding: 0.2rem;
`;

export const TitleContent = styled.div`
  h2:nth-child(2) {
    margin-bottom: 1rem;
  }

  h5:first-child {
    margin-bottom: 0.5rem;
  }
`;

export const H2 = styled.h2`
  margin-bottom: 1rem;
`;

export const ColText = styled(ColGrid)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    ${() => getWidthGrid(12)}
  }

  @media only screen and (min-width: 768px) {
    ${() => getWidthGrid(6)}
  }

  @media only screen and (min-width: 1000px) {
    ${() => getWidthGrid(6)}
  }
`;

export const ColCardR = styled(ColGrid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin-left: -20rem;

  @media only screen and (max-width: 768px) {
    ${() => getWidthGrid(6)}
    margin-left: 0;
  }

  @media only screen and (min-width: 768px) {
    ${() => getWidthGrid(6)}
  }

  @media only screen and (min-width: 1000px) {
    ${() => getWidthGrid(6)}
  }
`;

export const ColCard = styled(ColGrid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  //margin-right: 10rem;

  @media only screen and (max-width: 768px) {
    ${() => getWidthGrid(6)}
  }

  @media only screen and (min-width: 768px) {
    ${() => getWidthGrid(6)}
  }

  @media only screen and (min-width: 1000px) {
    ${() => getWidthGrid(6)}
  }
`;

export const ColVideo = styled(ColGrid)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    ${() => getWidthGrid(12)}
  }

  @media only screen and (min-width: 768px) {
    ${() => getWidthGrid(6)}
  }

  @media only screen and (min-width: 1000px) {
    ${() => getWidthGrid(6)}
  }
`;

export const RowVideo = styled.div`
  margin-top: 3rem;

  @media only screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.default};
  border-radius: 1rem;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  @media only screen and (min-width: 768px) {
    width: 100%;
  }

  @media only screen and (min-width: 1000px) {
    width: 100%;
  }
`;

export const TitleCard = styled.h4`
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
  padding: 1rem 0 0.5rem 0;
  color: ${colors.grey.dark};
`;

/* form */

export const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  margin: 2rem 2rem;
  background-color: ${colors.default};
  border-radius: 1rem;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  @media only screen and (min-width: 768px) {
    width: 100%;
  }

  @media only screen and (min-width: 1000px) {
    width: 50%;
  }
`;

export const BtnGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  width: 100%;
  background-color: ${colors.secondary};
  margin: 1rem;
  text-decoration: none;
  color: ${colors.default};
  border-radius: 50px;
  padding: 0.5rem 1rem;
  font-size: ${fontSize.md};
  border: none;
  text-transform: uppercase;
  font-style: italic;
  cursor: pointer;
  height: fit-content;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  justify-content: center;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

export const HeaderForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  h3:first-child {
    text-align: center;
  }
`;

export const Label = styled(LabelForm)`
  padding: 0 0 1rem 0;
  font-weight: 600;
`;

export const H5 = styled.h5`
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 1rem;
  font-style: italic;
  font-weight: 600;
  color: ${colors.grey.darkPrimary};
`;
