import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

// actions
import { changeCVC, clearCvc, updateTwoCards } from '../../actions/payment';

//utils
import { shuffle } from '../../utils';

// styles
import { Label, Input, Button } from '../../styles/forms';
import { WrapperKeyboard, GroupKey, GroupAction, Keyboard, Key } from './style';

class VirtualKeyboard extends Component {
  constructor(props) {
    super(props);
    this.isTwoCards = this.props.cardType;
    this.cardType = this.isTwoCards ? this.props.cardType : null;
    this.state = {
      active: false,
    };

    this.InputCvv = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        active: false,
      });
    }
  };

  handleActiveKeyboard = () => {
    this.setState({
      active: !this.state.active,
    });
  };

  handleKey = (input, payment) => {
    const code = input.currentTarget.dataset.code;
    const Input = this.InputCvv.current;

    if (Input.value.split('').length < 4) {
      const cvc = `${payment.cvc}${code}*`;
      Input.value = `${Input.value}0`;

      this.isTwoCards
        ? this.props.updateTwoCards(this.cardType, 'cvc', cvc)
        : this.props.changeCVC(cvc);
    }
  };

  handleClear = () => {
    this.props.clearCvc();
    const Input = this.InputCvv.current;
    Input.value = '';
  };

  render() {
    const { hashPayment, payment, twoCards } = this.props.payment;

    if (!hashPayment) {
      return '';
    }

    const keys = shuffle(Object.entries(hashPayment.teclado));

    return (
      <WrapperKeyboard>
        <Label htmlFor={`${this.isTwoCards ? 'CVC_' + this.cardType : 'CVC'}`}>
          CVC
        </Label>
        <Input
          maxLength={4}
          type={'password'}
          id={'CVC'}
          placeholder={'***'}
          readOnly
          onFocus={this.handleActiveKeyboard}
          ref={this.InputCvv}
        />
        <Keyboard className={this.state.active ? 'active' : 'hidden'}>
          <GroupKey>
            {keys.map((item, i) => {
              return (
                <Key
                  key={i}
                  onClick={e =>
                    this.handleKey(
                      e,
                      this.isTwoCards ? twoCards[this.cardType] : payment
                    )
                  }
                  data-code={item[1].id}
                  data-button={item[0]}
                >
                  {item[0]}
                </Key>
              );
            })}
          </GroupKey>
          <GroupAction>
            <Button onClick={this.handleClear}>Limpar</Button>
            <Button
              onClick={() => this.setState({ active: !this.state.active })}
            >
              Ok
            </Button>
          </GroupAction>
        </Keyboard>
      </WrapperKeyboard>
    );
  }
}

const mapStateToProps = state => {
  return {
    payment: state.payment,
    twoCards: state.payment.twoCards,
  };
};

VirtualKeyboard = connect(mapStateToProps, {
  changeCVC,
  clearCvc,
  updateTwoCards,
})(VirtualKeyboard);

export default VirtualKeyboard;
