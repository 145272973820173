import styled from 'styled-components';

// Styles
import { Wrapper, Label, Radio } from '../../../../styles/forms';
import { Row, Col } from '../../../../styles/align';
import { colors } from '../../../../styles/variables';

/**
 * Formata o Container de Acabamentos da capa do Livro
 *
 * @extends {Row}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Wrapper}
 * @extends {Label}
 * @extends {colors}
 */

export const OptionalFinishes = styled(Row)`
  ${Wrapper} {
    width: 100%;

    ${Label} {
      display: flex;
      justify-content: space-between;

      span {
        &:last-of-type {
          font-weight: 700;
          color: ${colors.secondary};
        }
      }
    }
  }
`;

export const Colors = styled(Col)`
  width: 100%;
  align-items: center;
  justify-content: space-around;
  padding: 0.6rem;
  border: 1px solid ${colors.grey.darkSecondary};
  border-radius: 5px;
  flex-direction: row;
  margin-bottom: 1rem;
  background-color: ${colors.primary};
  color: ${colors.default};

  ${Wrapper} {
    min-width: auto;
    border: 0;
    margin: 0;

    ${Radio} {
      &:checked {
        & ~ ${Label} {
          background-color: transparent;
          color: ${colors.primary};
        }
      }
    }
  }
`;
