import React from 'react';
import { connect } from 'react-redux';

// Node
import Icon from '@mdi/react';
import { mdiAlertOutline } from '@mdi/js';

// components
import VirtualKeyboard from '../VirtualKeyboard';
import Tooltip from '../Tooltip';

// actions
import {
  changeNumber,
  changeNameCard,
  changeMonth,
  changeYear,
  changeCVC,
  changeParcels,
  hashCard,
  changeDependency,
} from '../../actions/payment';

// utils
import { numberFormat, slugify } from '../../utils';
import { INVALID_CARD } from '../../utils/constMessage';

// services
import { validateCard } from '../../services/payment';

// styles
import { Wrapper, Label, Input, Select, Option } from '../../styles/forms';
import {
  WrapperParcels,
  WrapperDate,
  ListCards,
  ContainerNumberCard,
} from './style';

let flagActive = '';
let invalid = false;

const handleNumberCard = (value, changeNumber, changeDependency) => {
  flagActive = '';
  invalid = false;
  const card = validateCard(value);
  changeDependency(card.dependency);
  flagActive = slugify(card.flag);

  if (!flagActive && card.number.length > 15) {
    invalid = true;
  }
  if (card.number.length < 20) {
    changeNumber(card.number);
  }
};

const brands = payment => {
  if (!payment) {
    return '';
  }

  const cards = payment.payments.formasPagamento.filter(
    pagamento => pagamento.pk_tipo_pagamento === 3
  );
  return (
    <ListCards>
      <ul>
        {cards[0].dependencia_tipo_pagamento.map((item, i) => {
          return (
            <li
              key={i}
              className={`${slugify(
                item.desc_dependencia_tipo_pagamento
              )} card-list ${
                slugify(item.desc_dependencia_tipo_pagamento) === flagActive
                  ? 'active'
                  : 'disabled'
              }`}
            ></li>
          );
        })}
      </ul>
    </ListCards>
  );
};

const CreditCard = ({
  changeNumber,
  changeNameCard,
  changeMonth,
  changeYear,
  changeParcels,
  dependencia_tipo_pagamento,
  multiplePayments,
  hashPayment,
  payment,
  changeDependency,
}) => {
  let parcelas = multiplePayments
    ? dependencia_tipo_pagamento[0].parcela_credito_parcial
    : dependencia_tipo_pagamento[0].parcela;

  if (!parcelas) {
    parcelas = [];
  }

  if (!hashPayment) {
    return '';
  }

  return (
    <>
      <ContainerNumberCard>
        <Wrapper>
          <Label htmlFor={'number'}>Número do Cartão</Label>
          <Input
            id={'number'}
            className={invalid ? 'invalid' : ''}
            onChange={e =>
              handleNumberCard(e.target.value, changeNumber, changeDependency)
            }
            value={payment.payment.numero_cartao}
          />
          {invalid ? (
            <div className="text-error">
              <Tooltip
                text={INVALID_CARD}
                dataId={'invalidCard'}
                icon={<Icon path={mdiAlertOutline} title={'error'} size={1} />}
              />
            </div>
          ) : (
            ''
          )}
        </Wrapper>
        {brands(payment)}
      </ContainerNumberCard>
      <Wrapper>
        <Label htmlFor={'nome'}>Nome do titular</Label>
        <Input id={'nome'} onChange={e => changeNameCard(e.target.value)} />
      </Wrapper>
      <WrapperDate>
        <Label htmlFor={'mes'}>Mês</Label>
        <Select
          id={'mes'}
          defaultValue={0}
          onChange={e => changeMonth(e.target.value)}
        >
          {Object.entries(hashPayment.mes).map((item, i) => {
            return (
              <Option
                key={i}
                value={item[0]}
                disabled={item[0] === '0' ? true : false}
              >
                {item[1]}
              </Option>
            );
          })}
        </Select>
      </WrapperDate>
      <WrapperDate>
        <Label htmlFor={'ano'}>Ano</Label>
        <Select
          id={'ano'}
          defaultValue={0}
          onChange={e => changeYear(e.target.value)}
        >
          {Object.entries(hashPayment.ano).map((item, i) => {
            return (
              <Option
                key={i}
                value={item[0]}
                disabled={item[0] === '0' ? true : false}
              >
                {item[1]}
              </Option>
            );
          })}
        </Select>
      </WrapperDate>
      <VirtualKeyboard />
      <WrapperParcels>
        <Label htmlFor={'parcelas'}>Parcelas</Label>
        <Select
          id={'parcelas'}
          disabled={!flagActive ? true : false}
          onChange={e => changeParcels(e.target.value)}
        >
          {parcelas.map((parcela, i) => {
            return (
              <Option value={`${i + 1}`} key={i}>{`${i +
                1}x de R$ ${numberFormat(parcela.valor, 2)}`}{(parcela.juros && parcela.juros !== 0) ? ` (com juros de R$ ${numberFormat(parcela.juros, 2)})` : ''}</Option>
            );
          })}
        </Select>
      </WrapperParcels>
    </>
  );
};

const mapStateToProps = state => {
  return {
    multiplePayments: state.payment.multiplePayments,
    hashPayment: state.payment.hashPayment,
    payment: state.payment,
  };
};

export default connect(mapStateToProps, {
  changeNumber,
  changeNameCard,
  changeMonth,
  changeYear,
  changeCVC,
  changeParcels,
  hashCard,
  changeDependency,
})(CreditCard);
