import React, { Component } from 'react';
import TagManager from 'react-gtm-module';
import { connect } from 'react-redux';
// node-modules
import { toast } from 'react-toastify';
import { Form, reduxForm } from 'redux-form';

import { setSummary } from '../../actions/cart';
import {
  modalBknbank,
  modalCancel,
  modalPayment,
  modalPix,
  modalTermsOfUse,
} from '../../actions/modals';
// actions
import {
  clearOrder,
  confirmPaymentPix,
  fetchOrder,
  protocolTimeOrder,
  retrieveOrderPaymentOk,
} from '../../actions/orders';
import {
  changeTypePayment,
  clearPayment,
  hashCard,
  setPayments,
} from '../../actions/payment';
import CalendarDelivery from '../../components/CalendarDelivery';
import Loader from '../../components/Loader';
import DataPayment from '../../components/Order/DataPayment';
// Components
import SummaryCart from '../../components/SummaryCart';
// config
import config from '../../config';
// validate
import FileInput from '../../formValidate/FileInput';
import * as StyleDataPeyment from '../../hocs/DataPayment/style';
// services
import { downloadFile, sendReceipt } from '../../services/files';
import { requestReceipt } from '../../services/message';
import { generatePix, generateTicket } from '../../services/payment';
import { getToken } from '../../services/user';
// Styles
import * as Align from '../../styles/align';
import * as Card from '../../styles/cards';
import * as FormStyle from '../../styles/forms';
import * as Forms from '../../styles/forms';
import * as Title from '../../styles/titles';
import { numberFormat } from '../../utils';
// utils
import { RECEIPT_NOT_SENDED, PAYMENT_PIX_DONE } from '../../utils/constMessage';
import { File, FileContainer, Files, FilesContainer } from '../Files/style';
import { Anchor } from '../Filter/style';
import History from './partials/History';
// Partials
import Info from './partials/Info';
import Items from './partials/Items';
import Observation from './partials/Observation';
import ObservationNotaFiscal from './partials/ObservationNotaFiscal';
import * as Style from './style';

class Order extends Component {
  state = {
    active: '',
    receipt: '',
    disabledButton: false,
    errorPaymentAdditional: false,
  };

  componentDidMount() {
    const { pk_pedido } = this.props.match.params;
    this.props.fetchOrder(pk_pedido).then(
      res => {
        this.props.setSummary(this.props.order.resumo);
        if (this.props.order.hash) {
          this.props.hashCard(this.props.order.hash);
        }

          this.renderTagEcommerce(this.props.order);

        // PIX: Verifica se 15 em 15 segundos se o pedido foi pago
        if (this.props.order.fk_status_pedido === 46) {
          this.checkPaymentPix();
        }
      },
      reject => {
        toast.error(reject.response.data.message);
        this.props.history.push('/pedidos');
      }
    );
  }

  renderTagEcommerce = order => {
    let products = [];

    order.pedidoItens.forEach(item => {
      const data = {
        name: item.produto.categoria.desc_categoria,
        id: item.produto.pk_produto,
        price: numberFormat(item.vlr_pedido_item),
        quantity: item.qtd_pedido_item,
      };

      products = [...products, data];
    });

    const tagManagerArgs = {
      dataLayer: {
        ecommerce: {
          purchase: {
            actionField: {
              id: order.pk_pedido,
              affiliation: 'Fabrica do Livro',
              revenue: numberFormat(order.valor_total_pedido),
              shipping: numberFormat(order.resumo.entrega),
            },
            products: [...products],
          },
        },
      },
      dataLayerName: 'ecommerce',
    };

    TagManager.dataLayer(tagManagerArgs);
  };

  checkPaymentPix = () => {
    const { pk_pedido } = this.props.match.params;
    const self = this;

    const callbackNext = () => {
      window.setTimeout(() => {
        self.checkPaymentPix();
      }, 15000);
    };
    retrieveOrderPaymentOk(pk_pedido).then(
      res => {
        if (res.hasOwnProperty('error')) {
          callbackNext();
        } else {
          if (!res.data.data.status) {
            callbackNext();
          } else {
            self.componentDidMount();
          }
        }
      },
      reject => {
        callbackNext();
      }
    );
  };

  handleFileReceipt = file => {
    this.setState({
      receipt: file,
    });
  };

  handlePayment = (card = null) => {
    if (this.props.order.pagarCartao && card) {
      this.props.setPayments({
        ...this.props.order.pagarCartao,
        paymentCard: true,
      });

      this.props.changeTypePayment('3');
    }

    if (this.props.order.alterarPagamento && !card) {
      this.props.setPayments(this.props.order.alterarPagamento);
      this.props.changeTypePayment('');
    }

    this.props.modalPayment();
  };

  handlePaymentBknBank = () => {
    const GERAR_NOVO_BOLETO = 1;
    const CONTINUAR_MESMO_BOLETO = 2;
    const { pk_pedido } = this.props.match.params;
    const self = this;

    const setStateLoading = () => {
      self.setState({
        isLoading: true,
        disabledButton: true,
      });
    };

    const callbackTrue = () => {
      setStateLoading();
      this.props.modalBknbank();
      sendRequest(GERAR_NOVO_BOLETO);
    };

    const callbackFalse = () => {
      self.setState({
        isLoading: true,
        disabledButton: true,
      });
      this.props.modalBknbank();
      sendRequest(CONTINUAR_MESMO_BOLETO);
    };

    const sendRequest = (flag = 0) => {
      generateTicket(pk_pedido, flag).then(res => {
          if (res.hasOwnProperty('error')) {
            toast.warn(res.error);
          } else {
            if (res.data.data.hasOwnProperty('confirmacao')) {
              self.setState({
                isLoading: false,
              disabledButton: false,
              });

              this.props.modalBknbank({
                callbackTrue,
                callbackFalse,
              });
              return;
            }

            const headers = new Headers();
            headers.set('Authorization', `Bearer ${getToken()}`);
            headers.set('Content-Type', 'application/pdf');
            downloadFile(res.data.data.boleto_pdf, { headers });
          }

          self.setState({
            isLoading: false,
          disabledButton: false,
          });
      });
    };

    setStateLoading();
    sendRequest();
  };

  handlePaymentPix = () => {
    const { pk_pedido } = this.props.match.params;
    const self = this;

    const setStateLoading = type => {
      self.setState({
        isLoading: type,
        disabledButton: type,
      });
    };

    // Verifica o pagamento PIX usando a função checkPaymentPix
    const checkPaymentStatus = () => {
      return new Promise((resolve) => {
        retrieveOrderPaymentOk(pk_pedido).then(
          res => {
            if (res.hasOwnProperty('error') || !res.data.data.status) {
              resolve(false); // Pagamento não concluído
            } else {
              resolve(true); // Pagamento concluído
            }
          },
          reject => {
            resolve(false); // Se houver rejeição, trata como não pago
          }
        );
      });
    };
  
    const processPaymentPix = async () => {
      setStateLoading(true);
  
      const paymentDone = await checkPaymentStatus();
      if (paymentDone) {
        toast.warn(PAYMENT_PIX_DONE);
        setStateLoading(false);
        return;
      }
  
    const sendRequest = (flag = 0) => {
      generatePix(pk_pedido, flag).then(res => {
          if (res.hasOwnProperty('error')) {
            toast.warn(res.error);
          } else {
          if (
            res.hasOwnProperty('data') &&
            res.data.hasOwnProperty('data') &&
            res.data.data.link
          ) {
              const handleCopiar = () => {
                const el = document.createElement('textarea');
                el.value = res.data.data.linha_digitavel;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);

                toast.success('Linha digitável copiado.', {
                position: 'top-center',
                  autoClose: 2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              };

              this.props.modalPix({
                link: res.data.data.link,
                linhaDigitavel: res.data.data.linha_digitavel,
              handleCopiar,
              });
            } else {
              toast.warn(res.data.message);
            }
          }

          window.setTimeout(() => {
            setStateLoading(false);
          }, 1500);
      });
    };

    sendRequest();
  };

    processPaymentPix();
  };

  handlePaymentPixConfirmDev = () => {
    const { pk_pedido } = this.props.match.params;
    const self = this;
    confirmPaymentPix(pk_pedido).then(res => {
        if (res.hasOwnProperty('error')) {
          toast.warn(res.error);
        } else {
        if (
          res.hasOwnProperty('data') &&
          res.data.hasOwnProperty('data') &&
          res.data.data.status
        ) {
            self.componentDidMount();
          } else {
            toast.warn(res.data.message);
          }
        }
    });
  };

  componentWillUnmount() {
    this.props.clearOrder();
    this.props.clearPayment();

    const tagManagerArgs = {
      dataLayer: {},
      dataLayerName: 'ecommerce',
    };

    TagManager.dataLayer(tagManagerArgs);
  }

  handleSendReceipt = async e => {
    const { pk_pedido, enviarComprovante } = this.props.order;
    const { receipt } = this.state;

    if (receipt === '') {
      toast.warn(RECEIPT_NOT_SENDED);
      return false;
    }

    const response = await sendReceipt(
      receipt,
      pk_pedido,
      enviarComprovante.extensoes
    );

    if (response.message !== '') {
      toast[response.status](response.message);
    }

    if (response.status === 'error') {
      return false;
    }

    this.props.fetchOrder(pk_pedido);
    this.forceUpdate();
  };

  renderDelivery = () => {
    const { balcony, delivery } = this.props;

    if (!balcony && !delivery) {
      return '';
    }

    const deliveryData = balcony.length > 0 ? balcony : delivery;

    return deliveryData.map((data, index) => {
      return (
        <Card.CardInfo key={index} border>
          {data.label ? <h3>{data.label}</h3> : ''}
          {data.values
            .filter(value => value.value)
            .map((value, i) => {
              return (
                <p key={i}>
                  <strong>{value.label}</strong> {value.value}
                </p>
              );
            })}
        </Card.CardInfo>
      );
    });
  };

  renderDeadline(order) {
    if (
      !order.dta_entrega_pedido ||
      !order.pago_pedido ||
      order.fk_status_pedido === 3
    ) {
      return '';
    }

    return (
      <>
        <Card.CardInfo border>
          <h3>Previsão de Entrega</h3>
          <CalendarDelivery
            date={order.dta_entrega_pedido}
            status={order.status_pedido.pk_status_pedido}
          />
        </Card.CardInfo>
      </>
    );
  }

  renderTracking(order) {
    const TYPE_CORREIOS = 1;
    const TYPE_JADLOG = 2;
    const TYPE_TOTAL_EXPRESS = 3;

    let trackings = [];
    let type = null;
    if (order.rastreioJadlog.length) {
      trackings = order.rastreioJadlog;
      type = TYPE_JADLOG;
    } else if (order.rastreioTotalExpress.length) {
      trackings = order.rastreioTotalExpress;
      type = TYPE_TOTAL_EXPRESS;
    } else if (order.rastreioCorreio.length) {
      trackings = order.rastreioCorreio;
      type = TYPE_CORREIOS;
    } else if (!order.frete.conhecimento_frete) {
      trackings.push('Não informado');
    } else {
      trackings.push(order.frete.conhecimento_frete);
    }

    return (
      <Card.CardInfo border>
        <h3>Código de Rastreio</h3>
        <p>
          <strong>Código: </strong>
          {trackings
            .filter(value => value)
            .map((value, i) => {
              if (type === TYPE_CORREIOS) {
                const link = `https://www.linkcorreios.com.br/${value}`;
                return (
                  <Anchor
                    margin={'0px 0.2rem 0px 0px;'}
                    rel="noopener"
                    key={i}
                    href={link}
                    target={'_blank'}
                  >
                  {value}
                  </Anchor>
                );
              }

              if (type === TYPE_JADLOG) {
                const link = `https://www.jadlog.com.br/siteDpd/tracking.jad?lang=pt&cte=${value.rastreio};tipoConsulta=`;
                return (
                  <Anchor
                    margin={'0px 0.2rem 0px 0px;'}
                    rel="noopener"
                    key={i}
                    href={link}
                    target={'_blank'}
                  >
                  {value.rastreio}
                  </Anchor>
                );
              }

              if (type === TYPE_TOTAL_EXPRESS) {
                const link = value.tracking_loja
                  .replace('%s', value.reid)
                  .replace('%s', value.etiqueta)
                  .replace('%s', value.pad);

                return (
                  <Anchor
                    margin={'0px 0.2rem 0px 0px;'}
                    rel="noopener"
                    key={i}
                    href={link}
                    target={'_blank'}
                  >
                  {value.etiqueta}
                  </Anchor>
                );
              }

              return value;
            })}
        </p>
        <p>
          <a
            href="https://instrucoes.fabricadolivro.com.br/transporte/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Autorização de Retirada
          </a>
        </p>
      </Card.CardInfo>
    );
  }

  renderSummary(order) {
    return (
      <Card.CardInfo bg padding margin={'0 0 1rem 0'}>
        <h3>Resumo do Pedido</h3>
        <SummaryCart pagamentos={order && order.pagamento} />
      </Card.CardInfo>
    );
  }

  renderContact() {
    return (
      <Card.CardInfo bg padding textalign={'center'} height={'auto'}>
        <h3>Obrigado!</h3>
        <p>
          Qualquer dúvida nossa equipe está pronta para atendê-lo. Entre em
          contato através de um dos canais de atendimento
        </p>
        {/* <BtnGroup align={"center"}>
          <Button primary>Enviar Mensagem</Button>
        </BtnGroup> */}
      </Card.CardInfo>
    );
  }

  handleClickRequestReceipt(pkPedido) {
    this.setState({
      disabledButton: true,
    });
    requestReceipt(pkPedido).then(() => {
      window.location.reload(false);
    });
  }

  openNotaFiscalOnNewTab(nota_fiscal) {
    window.open(nota_fiscal, '_blank');
  }

  renderNotaFiscal(order) {
    if (order.nota_fiscal) {
      return (
        <Align.Col>
          <Forms.Label>Nota Fiscal</Forms.Label>
          <ul>
            {order.nota_fiscal.map((nota, i) => {
              console.log(nota.nota_fiscal);

              const slicedNota = nota.nota_fiscal.split('/');
              const link = `${config.pathImg}/${nota.nota_fiscal.slice(10)}`; // remove a string(/arquivos/)
              const file = slicedNota[slicedNota.length - 1];

              if(nota.nota_fiscal.substring(0,6) === 'https:') {
                return (
                  <li
                    onClick={() =>
                      this.openNotaFiscalOnNewTab(nota.nota_fiscal)
                    }
                      key={i}
                  >
                    {nota.nota_fiscal.indexOf('pdf') !== -1
                      ? 'NF - PDF'
                      : 'NF - XML'}
                  </li>
                );
              } else {
                return (
                  <li onClick={() => downloadFile(link)} key={i}>
                    {`NF_${nota.fk_dados_pagamento}_${file}`}
                  </li>
                );
              }
            })}
          </ul>
        </Align.Col>
      );
    } else if (order.solicitar_nota_fiscal) {
      // Se o pedido está em Expedição, não tem nota fiscal e ele ainda não fez a solicitação de nota fiscal
      return (
        <Forms.BtnGroup align={'center'}>
          <Forms.Button
            primary
            disabled={this.state.disabledButton}
            onClick={() => this.handleClickRequestReceipt(order.pk_pedido)}
          >
            Solicitar nota fiscal
          </Forms.Button>
        </Forms.BtnGroup>
      );
    } else if (order.solicita_nf) {
      // Caso ele já tenha feito a solicitação de nota fiscal
      return (
        <Align.Col>
          <Forms.Label>
            Solicitamos ao faturamento que anexe a nota fical em seu pedido. A
            mesma será anexada em até 5 dias úteis.
          </Forms.Label>
        </Align.Col>
      );
    }
  }

  renderPayment = order => {
    if (!order.pagamento) {
      return '';
    }

    const isTwoCards =
    // eslint-disable-next-line
      order.pagamento.filter(p => p.fk_tipo_pagamento == 3).length >= 2;

    return (
      <>
        <Style.CardPayment bg padding height={'auto'}>
          <Align.Col>
            <Forms.Label>Dados de Pagamento</Forms.Label>
            <p className={'title__info'}>Pagamento Selecionado </p>
            {order.pagamento.map((pagamento, i) => {
              return (
                <DataPayment
                  key={i}
                  payment={pagamento}
                  updatePayment={order.alterarPagamento}
                  isPagamentoAdicionalPendente={
                    order.isPagamentoAdicionalPendente
                  }
                  payByCard={order.pagarCartao}
                  receipt={{
                    data: order.enviarComprovante,
                    enviarComprovante: order.fk_status_pedido === 14,
                    titular: order.titular_deposito,
                    cnpj: order.cnpj,
                  }}
                  shopline={order.shopline}
                  status={order.fk_status_pedido}
                  handleSendReceipt={this.handleSendReceipt}
                  handlePayment={this.handlePayment}
                  btnBknBank={{
                    disabledButton: this.state.disabledButton,
                    isLoader: this.state.isLoading,
                    handlePaymentBknBank: this.handlePaymentBknBank,
                  }}
                  btnPix={{
                    disabledButton: this.state.disabledButton,
                    isLoader: this.state.isLoading,
                    handlePaymentPix: this.handlePaymentPix,
                    handlePaymentPixConfirmDev: this.handlePaymentPixConfirmDev,
                  }}
                  handleFileReceipt={this.handleFileReceipt}
                  isTwoCards={isTwoCards}
                />
              );
            })}
            {this.renderNotaFiscal(order)}
          </Align.Col>
          {/* eslint-disable-next-line */}
          {isTwoCards && order.fk_status_pedido == 16 && (
            <Style.ErrorMessage>
              Infelizmente seu pagamento não foi aprovado, por favor refaça seu
              pedido e tente novamente!
            </Style.ErrorMessage>
          )}
          {this.renderTermsOfUse(order.dta_inc_pedido, order.protocolo_aceite)}
        </Style.CardPayment>
      </>
    );
  };

  renderPaymentAdditional = order => {
    if (!order.pagamentosAdicionais.length) {
      return '';
    }

    const validateFile = (aExts, ext) => {
      const bValidate = aExts.indexOf(ext.split('/')[1]) === -1;
      if (bValidate) {
        toast.error('Arquivo informado inválido, verifique!');
      }
      return bValidate;
    };

    const handleSubmit = e => {
      e.preventDefault();
    };

    return (
      <>
        <Card.Additional bg padding textalign={'center'} height={'auto'}>
        <Files className="file-form">
            {order.pagamentosAdicionais.map((pagamento, i) => {
                if (pagamento.fk_tipo_pagamento === 13) {
                  return (
                    <FilesContainer>
                    <Forms.Label
                      htmlFor={'envio_comprovante'}
                    >{`Pagamento Adicional - Pix no valor de R$ ${numberFormat(
                      pagamento.vlr_pagamento
                    )}`}</Forms.Label>
                      <Align.Col>
                        <Card.Info>
                          <p>
                          Para prosseguir com o pagamento, clique em “Gerar QR
                          CODE”, realize a leitura do mesmo utilizando o
                          aplicativo do seu banco ou o caixa eletrônico e
                          aguarde a confirmação de pagamento que é feita em
                          instantes.
                          </p>
                          <p>&nbsp;</p>
                          <p>
                          <strong>Liberação Financeira Imediata</strong>
                          </p>
                          <p>&nbsp;</p>
                          <p>
                          <strong>
                            Não é necessário o envio de comprovante.
                          </strong>
                          </p>
                        </Card.Info>
                        <form method="post">
                          <FormStyle.BtnGroup>
                          <FormStyle.Button
                            type={'button'}
                            disabled={this.state.disabledButton}
                            onClick={() => this.handlePaymentPix()}
                          >
                            Gerar Qr Code
                          </FormStyle.Button>
                          </FormStyle.BtnGroup>
                          {this.state.isLoader ? (
                            <FormStyle.BtnGroup>
                            <Loader className={'no-space'} />
                            </FormStyle.BtnGroup>
                          ) : (
                            ''
                          )}
                        {process.env.NODE_ENV !== 'production' ? (
                              <FormStyle.BtnGroup>
                            <FormStyle.Button
                              type={'button'}
                              onClick={() => this.handlePaymentPixConfirmDev()}
                            >
                              Confirmar Pagamento
                            </FormStyle.Button>
                              </FormStyle.BtnGroup>
                            ) : (
                                ''
                          )}
                        </form>
                      </Align.Col>
                    </FilesContainer>
                  );
                } else if (pagamento.fk_tipo_pagamento === 1) {
                  return (
                    <Form onSubmit={e => handleSubmit(e)}>
                      <FilesContainer>
                      <Forms.Label
                        htmlFor={'envio_comprovante'}
                      >{`Pagamento Adicional - Depósito Bancário no valor de R$ ${numberFormat(
                        pagamento.vlr_pagamento
                      )}`}</Forms.Label>
                      <Card.Info>
                        <Card.Desc>Banco: </Card.Desc>
                        <Card.Value>
                          {pagamento.desc_dependencia_tipo_pagamento}
                          &nbsp;
                          {pagamento.info_dependencia_tipo_pagamento}
                        </Card.Value>
                      </Card.Info>
                      <Card.Info>
                        <Card.Desc>Titular: </Card.Desc>
                        <Card.Value>{order.titular_deposito}</Card.Value>
                      </Card.Info>
                      <Card.Info>
                        <Card.Desc>CNPJ: </Card.Desc>
                        <Card.Value>{order.cnpj}</Card.Value>
                      </Card.Info>
                      <File
                        key={i}
                        max-width={'100%'}
                        className="file-form-btn"
                      >
                          <FileContainer>
                            <Forms.WrapperFiles
                              className={
                              this.state.errorPaymentAdditional ? 'error' : ''
                            }
                          >
                                <StyleDataPeyment.StyleFiles
                                  id="envio_comprovante"
                                  name="envio_comprovante"
                                  component={FileInput}
                                  onChange={e => {
                                    this.handleFileReceipt(e.target.files[0]);
                                this.setState({
                                  errorPaymentAdditional: validateFile(
                                    order.enviarComprovante.extensoes,
                                    e.target.files[0].type
                                  ),
                                });
                                  }}
                              accept={`.${order.enviarComprovante.extensoes.join(
                                ',.'
                              )}`}
                                  required={true}
                                />
                            </Forms.WrapperFiles>
                          <span>
                            Formatos permitidos:
                            <strong>
                              {order.enviarComprovante.extensoes.join(', ')}
                            </strong>
                            </span>
                          </FileContainer>
                        </File>
                        <Forms.BtnGroup>
                        <Forms.Button primary onClick={this.handleSendReceipt}>
                          Enviar Comprovante
                        </Forms.Button>
                      </Forms.BtnGroup>
                      </FilesContainer>
                    </Form>
                  );
                } else {
                  return '';
                }
            })}
          </Files>
        </Card.Additional>
      </>
    );
  };

  renderItems = order => {
    if (order.length === 0) {
      return '';
    }

    return <Items items={order.pedidoItens} />;
  };

  renderTermsOfUse = (data_pedido, protocolo_aceite) => {
    return (
        <>
          <FormStyle.BtnGroup>
          <FormStyle.Button
            type={'button'}
            onClick={()=>{
              this.props.modalTermsOfUse();
              this.props.protocolTimeOrder(data_pedido, protocolo_aceite);
            }}
          >
              Termos de Aceite
            </FormStyle.Button>
          </FormStyle.BtnGroup>
        </>
    );
  };

  render() {
    const { order } = this.props;
    if (!order) {
      return <Loader />;
    }

    return (
      <>
        <Align.Container>
          <Align.Col>
            <Title.TitleContainer>
              <Title.TitlePage internal>
                <span className="title">Pedido {order.pk_pedido}</span>
              </Title.TitlePage>
              <span>
                status:{' '}
                <Style.Status
                  colorStatus={order.status_pedido.cor_status_pedido}
                >
                  {order.status_pedido.descricao_status_loja}
                </Style.Status>
              </span>
            </Title.TitleContainer>

            <Style.OrderContainer>
              <Align.Sidebar margin={'0 1rem 0 0'} widthContent={'20%'}>
                {this.renderDelivery()}
                {this.renderTracking(order)}
                {this.renderDeadline(order)}
              </Align.Sidebar>
              <Style.Main widthContent={'55%'} bg={'transparent'}>
                <Align.Row>
                  {this.renderContact()}
                  {this.renderPayment(order)}
                </Align.Row>
                <Align.Row>{this.renderPaymentAdditional(order)}</Align.Row>
                <Style.Items>{this.renderItems(order)}</Style.Items>
              </Style.Main>
              <Align.Sidebar margin={'0 0 0 1rem'} widthContent={'25%'}>
                {this.renderSummary(order)}
                <ObservationNotaFiscal data={order.obs_pedido_nota} />
                <Observation data={order.obs_pedido} />
                <History data={order.historico} />
                {order.cancelarPedido ? (
                  <Style.CancelOrder>
                    <Forms.Button
                      onClick={() =>
                        this.props.modalCancel({
                          title: 'Cancelar Pedido',
                          pk_pedido: order.pk_pedido,
                        })
                      }
                    >
                      Cancelar Pedido »
                    </Forms.Button>
                  </Style.CancelOrder>
                ) : (
                  ''
                )}

                <Info />
              </Align.Sidebar>
            </Style.OrderContainer>
          </Align.Col>
        </Align.Container>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    order: state.orders.order,
    delivery: state.orders.delivery,
    balcony: state.orders.balcony,
    modalBknbank: state.modals.modalBknbank,
    modalPix: state.modals.modalPix,
  };
};

Order = reduxForm({
  form: 'sendFiles',
})(Order);

Order = connect(mapStateToProps, {
  fetchOrder,
  retrieveOrderPaymentOk,
  clearOrder,
  protocolTimeOrder,
  clearPayment,
  setPayments,
  modalPayment,
  modalBknbank,
  modalPix,
  changeTypePayment,
  modalCancel,
  setSummary,
  hashCard,
  modalTermsOfUse,
})(Order);

export default Order;
