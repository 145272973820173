import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Field, reduxForm } from 'redux-form';

// Node
import { toast } from 'react-toastify';

// Hocs
import Modal from '../../hocs/Modal';

// Components
import LoaderForm from '../../components/Loader/loaderForm';

// actions
import { fetchOrder } from '../../actions/orders';
import { clearModalCancel, modalAuthProd } from '../../actions/modals';

// Validate
import { required } from '../../formValidate/index';
import renderField from '../../formValidate/renderField';

// services
import { cancelOrder, AuthProdItem } from '../../services/order';

// Styles
import { Col } from '../../styles/align';
import { BtnGroup, Button, Wrapper, Label } from '../../styles/forms';
import { StyleAuthProd } from './style';

class AuthProd extends Component {
  submit = async data => {
    const { pk_pedido, pk_pedido_item } = this.props.data;
    let response = {};

    if (pk_pedido_item) {
      data = { ...data, pedido_item: pk_pedido_item };
      response = await AuthProdItem({ ...data });
      
    } else {
      data = { ...data, pedido: pk_pedido };
      response = await cancelOrder({ ...data });
    }

    

    if (response.status === 200) {
      this.props.fetchOrder(pk_pedido);
      this.props.modalAuthProd({ title: '' });
      toast.success(response.data.message);
    } else {
      toast.error(response.data.message);
    }
  };

  componentWillUnmount() {
    this.props.clearModalCancel();
  }

  render() {
    const { handleSubmit, submitting, data } = this.props;
    
    if (!data) {
      return '';
    }

    return (
      <StyleAuthProd>
        <Form onSubmit={handleSubmit(this.submit)}>
          <Col>
            <Wrapper>
              <Label htmlFor={'obs_pedido_item'}>Observação</Label>
              <Field
                type={'textarea'}
                typefield={'textarea'}
                name={'obs_pedido_item'}
                id={'obs_pedido_item'}
                component={renderField}
                validate={required}
                placeholder={'Digite o motivo da autorização'}
              />
            </Wrapper>
          </Col>
          <BtnGroup>
            <Button onClick={this.props.modalCancel}>Cancelar</Button>
            <Button type={'submit'} disabled={submitting}>
              Confirmar{submitting ? <LoaderForm /> : ''}
            </Button>
          </BtnGroup>
        </Form>
      </StyleAuthProd>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.modals.modalAuthProd.data,
  };
};

AuthProd = connect(mapStateToProps, {
  modalAuthProd,
  clearModalCancel,
  fetchOrder,
})(AuthProd);
AuthProd = reduxForm({
  form: 'CancelOrder',
})(AuthProd);

AuthProd = Modal(AuthProd);

export default AuthProd;
