// address
export const FETCH_ADDRESSES = 'FETCH_ADDRESSES';
export const FETCH_ADDRESS = 'FETCH_ADDRESS';
export const FETCH_STATES = 'FETCH_STATES';

// balcony
export const FETCH_BALCONIES = 'FETCH_BALCONIES';
export const CLEAR_BALCONY = 'CLEAR_BALCONY';

// cart
export const FETCH_CART = 'FETCH_CART';
export const FETCH_DELIVERY_OPTIONS = 'FETCH_DELIVERY_OPTIONS';
export const CLEAR_CART = 'CLEAR_CART';
export const CLEAR_DELIVERY_OPTIONS = 'CLEAR_DELIVERY_OPTIONS';
export const NUMBER_ITEMS = 'NUMBER_ITEMS';
export const SUMMARY_CART = 'SUMMARY_CART';
export const TYPE_DELIVERY = 'TYPE_DELIVERY';
export const DELIVERY_SELECTED = 'DELIVERY_SELECTED';
export const BALCONY_SELECTED = 'BALCONY_SELECTED';
export const SAVE_WITHDRAWAL = 'SAVE_WITHDRAWAL';
export const CLEAR_CART_SUMMARY = 'CLEAR_CART_SUMMARY';
export const SAVE_SHIPPING_CONTACT = 'SAVE_SHIPPING_CONTACT';
export const RESET_SHIPPING_CONTACT = 'RESET_SHIPPING_CONTACT';
export const RESET_WITHDRAWAL = 'RESET_WITHDRAWAL';
export const SUBMIT_FORM_CART = 'SUBMIT_FORM_CART';
export const SET_CUPOM = 'SET_CUPOM';
export const UPDATE_CART = 'UPDATE_CART';
export const CHANGE_DEPENDENCY = 'CHANGE_DEPENDENCY';
export const UPDATE_TERMS_CHECKED = 'UPDATE_TERMS_CHECKED';
export const PRODUCTS_TERMS_CHECKED_STATUS = 'PRODUCTS_TERMS_CHECKED_STATUS';
export const FILES_TERMS_CHECKED_STATUS = 'FILES_TERMS_CHECKED_STATUS';

// category
export const FETCH_CATEGORY = 'FETCH_CATEGORY';

// payment
export const CHANGE_NUMBER = 'CHANGE_NUMBER';
export const CHANGE_NAME_CARD = 'CHANGE_NAME_CARD';
export const CHANGE_MONTH = 'CHANGE_MONTH';
export const CHANGE_YEAR = 'CHANGE_YEAR';
export const CHANGE_CVC = 'CHANGE_CVC';
export const CLEAR_CVC = 'CLEAR_CVC';
export const CLEAR_DATA_CARD = 'CLEAR_DATA_CARD';
export const CHANGE_PARCELS = 'CHANGE_PARCELS';
export const CLEAR_PAYMENT = 'CLEAR_PAYMENT';
export const TYPE_PAYMENT = 'TYPE_PAYMENT';
export const PAYMENT = 'PAYMENT';
export const PAYMENTS = 'PAYMENTS';
export const CLEAR_PAYMENTS = 'CLEAR_PAYMENTS';
export const MULTIPLE_PAYMENTS = 'MULTIPLE_PAYMENTS';
export const VIEW_PAYMENTS = 'VIEW_PAYMENTS';
export const HASH_CARD = 'HASH_CARD';
export const CLEAR_HASH_CARD = 'CLEAR_HASH_CARD';
export const PAYMENT_WITH_TWO_CARDS = 'PAYMENT_WITH_TWO_CARDS';
export const FETCH_INSTALLMENTS_WITH_TWO_CARDS = 'FETCH_INSTALLMENTS_WITH_TWO_CARDS';
export const UPDATE_TWO_CARDS = 'UPDATE_TWO_CARDS';
export const CLEAN_TWO_CARDS = 'CLEAN_TWO_CARDS'
export const CALCULATE_VALUE_TWO_CARDS = 'CALCULATE_VALUE_TWO_CARDS'

// files
export const FETCH_FILES = 'FETCH_FILES';
export const CLEAR_FILES = 'CLEAR_FILES';
export const SUBMIT_FORM_FILES = 'SUBMIT_FORM_FILES';
export const FILES_AUTH = 'FILES_AUTH';
export const SUBMIT_CREATE_ONLINE = 'SUBMIT_CREATE_ONLINE';

// filter
export const FETCH_FILTER = 'FETCH_FILTER';
export const FETCH_FILTER_ON_CHANGE = 'FETCH_FILTER_ON_CHANGE';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const LAST_PRODUCT = 'LAST_PRODUCT';
export const ACTIVE_REQUEST = 'ACTIVE_REQUEST';
export const FETCH_SIMULATE_DELIVERY = 'FETCH_DELIVERY_SIMULATE';
export const CLEAR_SIMULATE_DELIVERY = 'CLEAR_SIMULATE_DELIVERY';

// calc lombada
export const FETCH_DEPENDECIES = 'FETCH_DEPENDECIES';

// footer
export const FETCH_PAYMENTS = 'FETCH_PAYMENTS';
export const FETCH_CONTACTS = 'FETCH_CONTACTS';

// home
export const FETCH_PUBSITES = 'FETCH_PUBSITES';
export const FETCH_FEATURED_PRODUCTS = 'FETCH_FEATURED_PRODUCTS';

// index
export const FETCH_CITIES = 'FETCH_CITIES';
export const CLEAR_CITIES = 'CLEAR_CITIES';
export const CLEAR_ADDRESS = 'CLEAR_ADDRESS';

// menu
export const FETCH_MENUS = 'FETCH_MENUS';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const CLOSE_MENU = 'CLOSE_MENU';

// messages
export const FETCH_MESSAGES = 'FETCH_MESSAGES';
export const MESSAGE_HISTORY = 'MESSAGE_HISTORY';
export const TYPE_MESSAGE = 'TYPE_MESSAGE';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const PAGE_MESSAGE = 'PAGE_MESSAGE';

// orders
export const FETCH_FILTER_ORDERS = 'FETCH_FILTER_ORDERS';
export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_ORDER = 'FETCH_ORDER';
export const CLEAR_ORDER = 'CLEAR_ORDER';
export const PROTOCOL_TIME_ORDER = 'PROTOCOL_TIME_ORDER';

// credits
export const FETCH_CREDITS = 'FETCH_CREDITS';
export const FETCH_FUTURE_RELEASES = 'FETCH_FUTURE_RELEASES';

// user
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const EMAIL_REGISTER = 'EMAIL_REGISTER';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_PRIVACY_USER = 'FETCH_PRIVACY_USER';
export const CLEAR_PRIVACY_USER = 'CLEAR_PRIVACY_USER';
export const UPDATE_PRIVACY_USER = 'UPDATE_PRIVACY_USER';

// landing page
export const LANDING_REGISTER = 'LANDING_REGISTER';
export const LANDING_REGISTER_COMPLETE = 'LANDING_REGISTER_COMPLETE';
export const LANDING_NOT_LIKE = 'LANDING_NOT_LIKE';

// cookie
export const COOKIES_SUCCESS = 'COOKIES_SUCCESS';

// modals
export const FETCH_MODAL_LIST_ADDRESS = 'FETCH_MODAL_LIST_ADDRESS';
export const FETCH_MODAL_LOGIN = 'FETCH_MODAL_LOGIN';
export const FETCH_MODAL_MESSAGE = 'FETCH_MODAL_MESSAGE';
export const FETCH_MODAL_PAYMENT = 'FETCH_MODAL_PAYMENT';
export const FETCH_MODAL_REGISTER = 'FETCH_MODAL_REGISTER';
export const FETCH_MODAL_REGISTER_ADDRESS = 'FETCH_MODAL_REGISTER_ADDRESS';
export const FETCH_MODAL_LOGIN_RECOVERY = 'FETCH_MODAL_RECOVERY';
export const FETCH_MODAL_CANCEL = 'FETCH_MODAL_CANCEL';
export const FETCH_MODAL_AUTH_PROD = 'FETCH_MODAL_AUTH_PROD';
export const FETCH_MODAL_WITHDRAWAL = 'FETCH_MODAL_WITHDRAWAL';
export const FETCH_MODAL_CONFIRM = 'FETCH_MODAL_CONFIRM';
export const FETCH_MODAL_CONFIRM_FILE_UPLOAD =
  ' FETCH_MODAL_CONFIRM_FILE_UPLOAD';
export const FETCH_MODAL_SHIPPING_CONTACT = 'FETCH_MODAL_SHIPPING_CONTACT';
export const FETCH_MODAL_ORDER_ERRORS = 'FETCH_MODAL_ORDER_ERRORS';
export const FETCH_MODAL_REOPEN_MESSAGE = 'FETCH_MODAL_REOPEN_MESSAGE';
export const FETCH_MODAL_MESSAGE_FEEDBACK = 'FETCH_MODAL_MESSAGE_FEEDBACK';
export const FETCH_MODAL_FILES_AUTH = 'FETCH_MODAL_FILES_AUTH';
export const FETCH_MODAL_REFILE = 'FETCH_MODAL_REFILE';
export const FETCH_MODAL_EDITOR = 'FETCH_MODAL_EDITOR';
export const FETCH_MODAL_CONTEST = 'FETCH_MODAL_CONTEST';
export const FETCH_MODAL_FUTURE_RELEASES = 'FETCH_MODAL_FUTURE_RELEASES';
export const CLEAR_MODAL_CANCEL = 'CLEAR_MODAL_CANCEL';
export const FETCH_MODAL_BALCONY = 'FETCH_MODAL_BALCONY';
export const FETCH_MODAL_BKNBANK = 'FETCH_MODAL_BKNBANK';
export const FETCH_MODAL_PIX = 'FETCH_MODAL_PIX';
export const FETCH_MODAL_PRIVACY = 'FETCH_MODAL_PRIVACY';
export const FETCH_MODAL_TERMS_OF_USE = 'FETCH_MODAL_TERMS_OF_USE';
export const FETCH_MODAL_RESEND_FILES = 'FETCH_MODAL_RESEND_FILES';
export const FETCH_MODAL_DELIVERY_STEPS = 'FETCH_MODAL_DELIVERY_STEPS';

// redirect
export const FETCH_REDIRECT = 'FETCH_REDIRECT';

// search
export const FETCH_SEARCH = 'FETCH_SEARCH';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';
