import styled from 'styled-components';

// Style
import { colors, fontSize } from '../../styles/variables';
import { Col } from '../../styles/align';
import { Button } from '../../styles/forms';

/**
 * Formata o Container da Pagina de Successo
 * @extends {Col}
 *
 * Herda estilo para formatar o interior do resultado
 * @extends {Button}
 * @extends {fontSize}
 * @extends {colors}
 *
 */
export const StyleSuccess = styled(Col)`
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;

  h2 {
    font-size: ${fontSize.xl}
    font-weight: 400;
    color: ${colors.primary};
    margin: 2rem;
  }

  p {
    color: ${colors.grey.darkPrimary};
    margin: 1rem;

    strong {
      margin-left: 1rem;
      color: ${colors.dark};
    }
  }

  svg {
    fill: ${colors.success}
  }

  ${Button} {
    background-color: red;
    max-width: max-content;
    color: ${colors.default};
    text-decoration: none;
    font-weight: 600;
    margin: 2rem;
  }
`;
