import React, { Component } from "react";
import { connect } from "react-redux";

// Actions
import { modalPix } from "../../actions/modals";

// Styles
import { Row, Col } from "../../styles/align";
import { BtnGroup, Button } from "../../styles/forms";
import { Loading } from "../style";
import { StylePix } from "./style";
import { Input } from '../../styles/forms';

// Hocs
import Modal from "../../hocs/Modal";
import Loader from "../../components/Loader";

class Pix extends Component {
  state = {
    loading: true
  };

  render() {
    if (this.state.loading) {
      const self = this;
      window.setTimeout(() => {
        self.setState({ loading: false });
      }, 1500);
    }

    return (
      <StylePix>
        <Row>
          <Col>
            <Input
                readonly={'readonly'}
                value={this.props.linhaDigitavel}
                onClick={this.props.handleCopiar}
                placeholder={'Linha Digitável QR Code'}
              />
          </Col>
          <Button onClick={this.props.handleCopiar}>Copiar</Button>
        </Row>
        <Row>
          <Col>
            <p>Leia o código Qr Code no seu aplicativo bancário ou no caixa eletrônico para realizar o pagamento.</p>
            {this.state.loading ? (
              <Loading>
                <Loader />
              </Loading>
            ) : ('')}
            <img src={this.props.link} alt="Qr Code"/>
          </Col>
        </Row>
        <Row>
          <BtnGroup>
            <Button onClick={this.props.modalPix}>Fechar</Button>
          </BtnGroup>
        </Row>
      </StylePix>
    );
  }
}

const mapStateToProps = state => {
  return {
    link: state.modals.modalPix.data.link,
    linhaDigitavel: state.modals.modalPix.data.linhaDigitavel,
    handleCopiar: state.modals.modalPix.data.handleCopiar,
  };
};

Pix = connect(mapStateToProps, { modalPix })(Pix);

Pix = Modal(Pix);

export default Pix;
