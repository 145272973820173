import React from 'react';
import WebFont from 'webfontloader';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import TagManager from 'react-gtm-module';
import gtag, { install } from 'ga-gtag';
import { version } from '../package.json';
import { Routes } from './routes';

import reducers from './reducers';

// styles
import GlobalStyle from './styles/global';
import ReactPixel from 'react-facebook-pixel';

// Início facebook pixel
const options = {
  autoConfig: true, // set pixel's autoConfig.
  debug: false, // enable logs
};

console.log({ env: process.env.NODE_ENV, version });

ReactPixel.init('400964964142464', options);

ReactPixel.pageView(); // For tracking page view

// Fim facebook pixel

let store = '';
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  store = createStore(reducers, composeEnhancers(applyMiddleware(reduxThunk)));
} else {
  store = createStore(reducers, applyMiddleware(reduxThunk));
}

const tagManagerArgs = {
  gtmId: 'GTM-TTSFMBV',
};

const googleAdsId = 'AW-705761160';

WebFont.load({
  google: {
    families: [
      'Open Sans:300,400,600,700,800',
      'Open Sans Condensed:300,300i,600,700,800',
      'sans-serif',
    ],
  },
});

if (process.env.NODE_ENV === 'production') {
  console.log('GTM Initialized');
  TagManager.initialize(tagManagerArgs);

  // Global site tag (gtag.js) - Google Ads: 705761160
  install(googleAdsId);
  gtag('js', new Date());
  gtag('config', googleAdsId);
}

ReactDOM.render(
  <Provider store={store}>
    <GlobalStyle />
    <Routes />
  </Provider>,
  document.getElementById('root')
);
