import React from 'react';
import { socialMedias } from './options';
import { SocialMediaIcon, Container } from './styles';

export function SocialMedias() {

  const handleClick = (event, href) => {
    event.preventDefault();  
    window.open(href, '_blank');
  };

  return (
    <Container>
      {socialMedias.map(socialMedia => (
        <SocialMediaIcon
          key={socialMedia.href}
          href={socialMedia.href}
          imageUrl={socialMedia.image}
          onClick={(event) => handleClick(event, socialMedia.href)}
        />
      ))}
    </Container>
  );
}
