import React from 'react';

// styles
import { CardInfo } from '../../../styles/cards';

const Info = () => {
  return (
    <CardInfo bg padding margin={'0 0 1rem 0'}>
      <h3>Importante</h3>
      <p>
        <li>
          No processo gráfico pode haver variação de corte em todos os lados do
          material, tanto nas laterais quanto nos cabeçalhos e rodapés. Evite
          colocar bordas para que essas variações, caso ocorram, não fiquem
          visíveis. Produtos com verso escuro e frente clara (ou vice-versa)
          podem manchar.
        </li>
        <li>
          Não trabalhamos com prova de cor, por isso podem ocorrer variações
          mínimas de cor durante o processo de impressão.
        </li>
        <li>
          Em alguns produtos pode ocorrer pequena variação de quantidade,
          limitada a 10% do total de cada item, tanto para mais ou para menos.
        </li>
        <li>
          É de responsabilidade do cliente, em todas as modalidades de entrega,
          verificar a embalagem do material recebido, a fim de detectar
          possíveis avarias. Caso sejam identificadas divergências, o cliente
          deverá realizar uma ressalva na minuta da entrega, no ato do
          recebimento, e entrar em contato com a Central de Atendimento da
          Fábrica do Livro.
        </li>
      </p>
    </CardInfo>
  );
};

export default Info;
