const config = {
  editor: 'https://editor.fabricadolivro.com.br/index.php?trabalho=',
  apis: {
    loja: 'https://app.fabricadolivro.com.br/api',
    baixaArquivos: 'https://baixa.fabricadolivro.com.br',
  },
  baixaArquivosArtes: 'https://baixa.fabricadolivro.com.br/arquivos/artes/',
  baixaArquivosObs: 'https://baixa.fabricadolivro.com.br/arquivos/observacao/',
};

export default config;
