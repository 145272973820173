import { GoTrash } from 'react-icons/go';
import styled from 'styled-components';

// styles
import { Col, Row } from '../../styles/align';
import { BtnGroup, Button, Label, Wrapper } from '../../styles/forms';
import { colors, fontSize } from '../../styles/variables';

/**
 * Formata o Content Lista de Endereços
 *
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do Content
 * @extends {colors}
 */

export const ListAddress = styled.div`
  background-color: ${colors.grey.light};
  padding: 1rem;

  h3 {
    color: ${colors.primary};
    font-size: ${fontSize.md};
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  svg {
    fill: ${colors.secondary};
  }
`;

/**
 * Formata o Formulario de Pesquisa da MOdal Lista de Endereços
 *
 * @extends {Row}
 *
 * Herda estilo para formatar o interior do Formulario
 * @extends {Wrapper}
 * @extends {Label}
 * @extends {BtnGroup}
 * @extends {Button}
 * @extends {colors}
 */

export const ListAddressForm = styled(Row)`
  display: flex;
  background-color: ${colors.grey.lightPrimary};
  padding: 1rem;
  margin-bottom: 1rem;
  

  ${Wrapper} {
    width: 300px;
    padding: 0 1rem;
    justify-content: flex-end;

    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 1rem;
    }

    ${Label} {
      color: ${colors.primary};
      padding: 0;
      margin-bottom: 0.5rem;

      @media (max-width: 768px) {
        margin-bottom: 1rem;
      }
    }
  }

  ${BtnGroup} {
    ${Button} {
      width: 100%;
      text-decoration: none;
      font-size: ${fontSize.sm};
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.secondary {
        width: 100%;
        background-color: transparent;

        &:hover {
          background-color: ${colors.hover.secondary};
          & > svg {
            fill: ${colors.default} !important;
          }
        }
      }
    }
  }
`;

export const FilterTrashIcon = styled(GoTrash)`
  width: 1.2rem;
  height: auto;
  fill: ${colors.primary} !important;
`;

/**
 * Formata o Container da Modal de Lista de Endereços
 *
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Wrapper}
 * @extends {Label}
 * @extends {Col}
 * @extends {colors}
 */

export const ListAddressContainer = styled.div`
  max-height: 300px;
  overflow: auto;
  background-color: ${colors.grey.light};
  

  ${Wrapper} {
    flex-direction: row;
    ${Label} {
      color: ${colors.primary};
      font-weight: 700;

      strong {
        color: ${colors.secondary};
        font-size: ${fontSize.sm};
        margin-left: 1rem;
        font-weight: 600;
      }
    }
  }

  ${Col} {
    background-color: ${colors.grey.lightPrimary};
    padding: 1rem;
    margin-bottom: 1rem;

    p {
      padding: 0 1rem;
      line-height: 1.6;
      color: ${colors.black.secondary};
      font-size: ${fontSize.sm}
    }
  }
`;

/**
 * Formata o Container de Ações [Btns] da Modal de Lista de Endereços
 *
 * @extends {Row}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {BtnGroup}
 * @extends {Button}
 * @extends {colors}
 */

export const ListAddressActions = styled(Row)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding-right: 1rem

  ${BtnGroup} {
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    padding: 1rem 0;

    ${Button} {
      background-color: ${colors.secondary};
      color: ${colors.default};
      text-decoration: none;
    }

    svg {
      cursor: pointer;
    }

    &:first-of-type {
      justify-content: flex-start;

      svg ~ ${Button} {
        background-color: ${colors.primary};
      }
    }
  }

  & > ${Button} {
    background-color: ${colors.primary};
    color: ${colors.default};
    text-decoration: none;
  }
`;

export const RemoveAddressButton = styled.div`
  width: 380px;
  ${BtnGroup} {
    flex-direction: row;
    justify-content: center;
    padding: 1rem 0;

    ${Button} {
      background-color: ${colors.primary};
      color: ${colors.default};
      text-decoration: none;

      &:hover {
          background-color: ${colors.blue.light};
        }

      &.secondary {
        background-color: transparent;

        &:hover {
          background-color: ${colors.hover.secondary};
          & > svg {
            fill: ${colors.default} !important;
          }
        }
      }
    }
  }
`;

export const ContainerNewEdit = styled.div`

  ${BtnGroup} {
    flex-direction: row;
    justify-content: center;
    padding: 1rem 0;

    ${Button} {
      background-color: ${colors.primary};
      color: ${colors.default};
      text-decoration: none;

      &:hover {
          background-color: ${colors.blue.light};
        }

      &.secondary {
        background-color: transparent;
        color: ${colors.primary};

        &:hover {
          background-color: ${colors.hover.secondary};
          color: ${colors.default};
        }
      }
    }
  }
`;

export const RemoveTrashIcon = styled(GoTrash)`
  width: 1.2rem;
  height: auto;
  fill: ${colors.primary} !important;

  /* &:hover {
    fill: ${colors.default} !important;
  } */
`;
