import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// shared
import { IconCart } from '../../shared/icons';

// styles
import { cart as CartStyle } from './style';

class Cart extends Component {
  render() {
    return (
      <CartStyle>
        <Link to="/carrinho" title={'Carrinho'}>
          <IconCart />
          {this.props.numberItens ? <span>{this.props.numberItens}</span> : ''}
        </Link>
      </CartStyle>
    );
  }
}

const mapStateToProps = state => {
  return {
    numberItens: state.cart.numberItens,
  };
};

export default connect(mapStateToProps, null)(Cart);
