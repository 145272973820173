import styled from "styled-components";

// styles
import { Container } from "../style";
import { Label, BtnGroup } from "../../styles/forms";
import { colors } from "../../styles/variables";

/**
 * Formata o Container da Modal Alerta de Envio de Arquivos
 *
 * @extends {Container}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Label}
 * @extends {BtnGroup}
 * @extends {colors}
 */

export const FilesAuthContainer = styled(Container)`
  h4 {
    margin-bottom: 1rem;
  }

  ${Label} {
    padding-left: 0;
    strong {
      color: ${colors.secondary};
    }
  }

  textarea {
    max-width: 100%;
    min-width: 100%;
    max-height: 100px;
    min-height: 100px;
  }

  ${BtnGroup} {
    padding: 1rem 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    span {
      display: flex;
      flex-direction: row;
      color: ${colors.secondary};
    }
  }
`;
