// array com as rotas privadas
export const privateRoutes = [
  'sucesso',
  'carrinho',
  'pedidos',
  'pedido',
  'mensagem',
  'credito',
  'cadastro/sucesso',
];

export const redirect = {
  '/revista-catalogo': '/revista',
  '/imprimir-revista-catalogo-a5/2761': '/imprimir-revista-a5/2761',
  '/imprimir-revista-catalogo-a4/2791': '/imprimir-revista-a4/2791',
};
