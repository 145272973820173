import React from 'react';

// styles
import { HeaderFooter, TextFooter, TextFinal } from './style';

const Footer = () => {
  return (
    <>
      <HeaderFooter>
        <h1>Sua Empresa é a personagem</h1>
        <h1>principal por aqui.</h1>
      </HeaderFooter>
      <TextFooter>
        <p>
          Trabalhamos com impressão gráfica ajudando pequenas e médias editoras a
          crescerem cada
        </p>
        <p>
          vez mais. Nosso compromisso é oferecer uma solução completa e fazer
          cada vez mais
        </p>
        <p>histórias alcançarem quantos leitores puderem.</p>
      </TextFooter>
      <TextFinal>
        <h2>Com a Fábrica do Livro, muitas ideias vão para o papel!</h2>
        <a href="#form_landing">Acesse agora o cadastro rápido e ganhe seu cupom de desconto</a>
      </TextFinal>
    </>
  );
};

export default Footer;
