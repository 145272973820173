import React from 'react';

import { ControlButton } from './style';

export function Control({textField, ...props}) {
  return (
    <ControlButton {...props} >
      {textField}
    </ControlButton>
  )
}