import styled from 'styled-components';

// Variables
import { borderRadius, colors } from './variables';

export const TableStyle = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 1rem;

  @media (max-width: 768px) {
    overflow: auto;
  }
`;

export const TableContainer = styled.table`
  width: 100%
  padding: 1rem;
  border-collapse: collapse;
  margin: 1rem 1rem 3rem 1rem;
`;

export const TableTbody = styled.tbody`
  tr {
    td {
      border: none;
    }

    &:nth-child(odd) {
      background-color: ${colors.grey.lightPrimary};
    }
  }
`;

export const TableTr = styled.th`
  color: ${colors.primary};
  font-weight: 600;
  border-bottom: 2px solid ${colors.primary};
  text-align: left;
  padding: 0.5rem;
`;

export const TableTd = styled.td`
  width: 16.66%;
  max-width: 200px;
  padding: 0.5rem;
  text-align: left;
  border: none;
  height: 40px;
`;

export const TableTdCart = styled.td`
  width: 16.66%;
  max-width: 200px;
  padding: 0.5rem;
  text-align: left;
  border: none;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const TableSpanIcon = styled.span`
  width: 30px;
  height: 30px;
  background-color: ${colors.secondary};
  border-radius: ${borderRadius};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  position: relative;

@media (max-width: 768px) {
  display: none;
}

svg {
  width: 1rem;
  height: 1rem;
  .cls-1 {
    fill: ${colors.default};
  }
}

span {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -8px;
  right: -8px;
  text-align: center;
  font-weight: bold;
  line-height: 1.6;
  background-color: ${colors.primary};
  color: ${colors.default};
  border-radius: ${borderRadius};
}

`;

