import loja from '../apis/loja';

// utils
import { CART_TOKEN } from '../utils/Storage/types';

/**
 * Seta o Token do Carrinho no localStorage
 */
export const setToken = token => localStorage.setItem(CART_TOKEN, token);

/**
 * Captura o Token do Carrinho do localStorage
 */
export const getToken = () => localStorage.getItem(CART_TOKEN);

/**
 * Remove o Token do Carrinho do localStorage
 */
export const removeToken = () => localStorage.removeItem(CART_TOKEN);

/**
 * Finaliza o pedido
 * * @param {object} pagamento
 */
export const finishOrder = async pagamento => {
  try {
    return await loja.post('/carrinho/finalizar', {
      token: getToken(),
      ...pagamento,
    });
  } catch (error) {
    return { error: 'CONNECTION_ERROR' };
  }
};

/**
 * Altera o pagamento no Objeto do Carrinho
 * * @param {object} pedido, pagamento
 */
export const changePayment = async (pedido, pagamento) => {
  try {
    return await loja.post('/pedido/alterar-pagamento', {
      pedido,
      ...pagamento,
    });
  } catch (error) {
    if (error.response) {
      return { errors: error.response.data };
    }
    return { error: 'CONNECTION_ERROR' };
  }
};

/**
 * Remove o item do Carrinho
 * * @param {object} index
 */
export const removeItem = async index => {
  const data = {
    token: getToken(),
    referencia: index,
  };

  try {
    const response = await loja.post('/carrinho/excluir', data);

    return response;
  } catch (error) {
    return { error: 'CONNECTION_ERROR' };
  }
};

/**
 * Multiplica o Item do Carrinho
 * * @param {object} index, quantidade
 */
export const multiplyItem = async (index, quantidade) => {
  const data = {
    token: getToken(),
    referencia: index,
    quantidade,
  };

  try {
    const response = await loja.post('/carrinho/multiplicar', data);
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Duplica o Item do Carrinho
 * * @param {object} index
 */
export const duplicateItem = async index => {
  try {
    const response = await loja.post('/carrinho/duplicar', {
      token: getToken(),
      referencia: index,
    });
    return response;
  } catch (error) {
    return { error: 'CONNECTION_ERROR' };
  }
};

/**
 * Seleciona a entrega no objeto do carrinho
 * * @param {object} endereco, transporte
 */
export const selectDelivery = async (endereco, transporte) => {
  try {
    const response = await loja.post('/carrinho/selecionar-entrega', {
      token: getToken(),
      endereco,
      transporte,
    });
    return response;
  } catch (error) {
    return { error: 'CONNECTION_ERROR' };
  }
};

/**
 * Seleciona o balcão no objeto do carrinho
 * * @param {object} pk_balcao, pk_estado, pk_cidade
 */
export const selectBalcony = async (pk_balcao, pk_estado, pk_cidade) => {
  try {
    const response = await loja.post('/carrinho/selecionar-balcao', {
      carrinho_token: getToken(),
      pk_balcao,
      pk_estado,
      pk_cidade,
    });
    return response;
  } catch (error) {
    return { error: 'CONNECTION_ERROR' };
  }
};

/**
 * Salva o nome do pedido
 * @param {string} nome
 */
export const saveOrderName = async nome => {
  try {
    const response = await loja.post('/carrinho/nome-pedido', {
      token: getToken(),
      nome,
    });
    return response;
  } catch (error) {
    return { error: 'CONNECTION_ERROR' };
  }
};

/**
 * Salva as observações da nota fiscal
 * @param {string} nome
 */
export const saveOrderObservationNF = async observacoes => {
  try {
    const response = await loja.post('/carrinho/obs-nota-pedido', {
      token: getToken(),
      observacoes,
    });
    return response;
  } catch (error) {
    return { error: 'CONNECTION_ERROR' };
  }
};

/**
 * Salva o responsável pela retirada do produto no balcao
 * @param {object} data
 */
export const saveWithdrawal = async data => {
  try {
    const response = await loja.post('/carrinho/responsavel-retirada', {
      token: getToken(),
      ...data,
    });
    return response;
  } catch (error) {
    if (error.response) {
      return { errors: error.response.data };
    }
    return { error: 'CONNECTION_ERROR' };
  }
};

/**
 * Salva o os dados de contato para transporte
 * @param {object} data
 */
export const saveShippingContact = async data => {
  try {
    const response = await loja.post('/carrinho/contato-transporte', {
      token: getToken(),
      ...data,
    });
    return response;
  } catch (error) {
    if (error.response) {
      return { errors: error.response.data };
    }
    return { error: 'CONNECTION_ERROR' };
  }
};

/**
 * Limpa os items do Carrinho
 */

export const cartClear = async () => {
  try {
    const response = await loja.post('/carrinho/limpar', {
      token: getToken(),
    });
    return response;
  } catch (error) {
    if (error.response) {
      return { errors: error.response.data };
    }
    return { error: 'CONNECTION_ERROR' };
  }
};

/**
 * Aplica o cupom no objeto do Carrinho
 * @param {object} codigo_cupom
 */

export const applyCupom = async codigo_cupom => {
  try {
    const response = await loja.post('/carrinho/aplicar-cupom-desconto', {
      token: getToken(),
      codigo_cupom,
    });
    return response;
  } catch (error) {
    if (error.response) {
      return { errors: error.response.data };
    }
    return { error: 'CONNECTION_ERROR' };
  }
};
