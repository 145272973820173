import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, Form, reduxForm } from 'redux-form';
import { signIn } from '../../actions/user';


// Node
import _ from 'lodash';
import { toast } from 'react-toastify';

//hocs
import Modal from '../../hocs/Modal';

// components
import LoaderForm from '../../components/Loader/loaderForm';


// actions
import { modalLogin, modalRegister } from '../../actions/modals';

// Partials
import RenderData from './partials/Data';
import RenderDataBusiness from './partials/DataBusiness';
import RenderDataLogin from './partials/Login';
import RenderDataOmie from './partials/Omie';
import RenderType from './partials/Type';

// Validatef
import { cpf, cnpj, date, terms } from '../../formValidate';
import { mapErrors } from '../../formValidate/handleErrors';
import renderField from '../../formValidate/renderField';

// Services
import {
  checkCpfCnpj,
  createUser,
  login as loginService,
  updateUser,
} from '../../services/user';

// utils
import { objValuesToString } from '../../utils';
import {
  CONNECTION_ERROR,
  ERROR,
  SIGN_UP_SUCCESS,
  UPDATE_SUCCESS,
} from '../../utils/constMessage';

// Styles
import { Col } from '../../styles/align';
import { BtnGroup, Button, Label, Wrapper } from '../../styles/forms';
import { Anchor, RegisterStyle } from './style';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: {
        nome_usuario: props.isAuth,
        cpf_cnpj_usuario: props.userLogged.cpf_cnpj_usuario
          ? props.isAuth
          : false,
        fk_tipo_usuario: props.isAuth,
        dta_nasc_usuario:
          props.userLogged.dta_nasc_usuario !== 'false' ? props.isAuth : false,
      },
      errors: {
        cpf_cnpj_usuario: '',
        dta_nasc_usuario: '',
        nome_usuario: '',
        nr_celular: '',
        nr_fone: '',
        email_usuario: '',
        senha_usuario: '',
      },
      loadingSelect: false,
      landingPage: props.userLogged.cpf_cnpj_usuario ? false : props.isAuth,
      submitting: false,
    };
  }


  handleClearErrors = name => {
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        [name]: '',
      },
    }));
  };

  // trata os erros pra mostrar no input
  handleErrors = response => {
    if (response.errosValidacao) {
      const errors = mapErrors(response.errosValidacao);
      this.setState({ errors });
    }

    if (response.message) {
      toast.warn(response.message);
    } else {
      toast.error(ERROR);
    }
  };

  // Consulta CPF e CNPJ
  VerifyCpfCnpj = async (cpf, data) => {
    const { fk_tipo_usuario } = this.props.register.values;
    const landingPage = this.state.landingPage;
    let nome_usuario = false;

    this.setState({
      disabled: {
        nome_usuario: true,
        cpf_cnpj_usuario: true,
        dta_nasc_usuario: true,
      },
      loadingSelect: true,
    });

    checkCpfCnpj(cpf, data, fk_tipo_usuario, landingPage).then(response => {
      if (response.status === 200) {
        if (response.data.data.valido) {
          this.props.change('nome_usuario', response.data.data.nome_usuario);
          nome_usuario = true;
        }
      } else if (response.errors) {
        this.handleErrors(response.errors);
      } else {
        toast.error(CONNECTION_ERROR);
      }

      const errors = _.omit(this.state.errors, 'cpf_cnpj_usuario');
      this.setState(prevState => ({
        ...errors,
        disabled: {
          ...prevState.disabled,
          nome_usuario,
          cpf_cnpj_usuario: false,
          dta_nasc_usuario: false,
        },
        loadingSelect: false,
      }));
    });
  };

  validateCpfDate = (cpf_usuario, dta_nasc_usuario) => {
    return cpf_usuario.length === 14 &&
      dta_nasc_usuario.length === 10 &&
      cpf(cpf_usuario) === undefined &&
      date(dta_nasc_usuario) === undefined;
  };

  // Change Cpf (partials/Data)
  handleChangeCpf = e => {
    const { dta_nasc_usuario, fk_tipo_usuario } = this.props.register.values;

    const cpf_cnpj_usuario = e.target.value;

    if (fk_tipo_usuario === '2') { // Física
      if (!dta_nasc_usuario) {
        return false;
      }

      if (this.validateCpfDate(cpf_cnpj_usuario, dta_nasc_usuario) ) {
        this.VerifyCpfCnpj(cpf_cnpj_usuario, dta_nasc_usuario);
      }
    } else {
      if (cpf_cnpj_usuario.length === 18 && cnpj(cpf_cnpj_usuario) === undefined) {
        this.VerifyCpfCnpj(cpf_cnpj_usuario);
      }
    }
  };

  // Change data Nascimento (partials/Data)
  handleChangeDate = e => {
    const { cpf_cnpj_usuario, fk_tipo_usuario } = this.props.register.values;

    if (fk_tipo_usuario !== '2') { // Física
      return true;
    }

    if (!cpf_cnpj_usuario) {
      return false;
    }

    const dta_nasc_usuario = e.target.value;

    if (this.validateCpfDate(cpf_cnpj_usuario, dta_nasc_usuario) ) {
      this.VerifyCpfCnpj(cpf_cnpj_usuario, dta_nasc_usuario);
    }
  };

  // Confirmação de Email
  verifyEmail = value => {
    return value !== this.props.register.values.email_usuario
      ? 'Email não confere'
      : undefined;
  };

  // Confirmação de Senha
  verifyPassWord = value => {
    return value !== this.props.register.values.senha_usuario
      ? 'Senha não confere'
      : undefined;
  };

  submit = async data => {
    this.setState({
      submitting: true,
    });

    if (this.props.isAuth) {
      const landingPage = this.state.landingPage;
      await updateUser(data, landingPage).then(response => {
        if (response.status === 200) {
          loginService(data.email_usuario, data.senha_usuario).then(result => {
            this.props.signIn(result.data.data);
            //this.props.modalRegister();
            toast.success(UPDATE_SUCCESS);
              window.location.reload();
            //this.props.modalRegister();
          });
        } else if (response.errors) {
          this.handleErrors(response.errors);
        } else {
          toast.error(CONNECTION_ERROR);
        }

        this.setState({
          submitting: false,
        });
      });
    } else {
      await createUser(data).then(response => {
        if (response.status === 200) {
          localStorage.setItem('addUser', true);

          loginService(data.email_usuario, data.senha_usuario).then(result => {
            this.props.signIn(result.data.data);
            //this.props.modalRegister();
            this.props.modalLogin();
            toast.success(SIGN_UP_SUCCESS);

            window.location.href = `/cadastro/sucesso`;
          });

          if (
          response.data.data.user_data.consumidor_final === null || 
          response.data.data.user_data.icms_st === null || 
          response.data.data.industrializacao === null || 
          response.data.data.user_data.comercializacao === null
        ) {
        } else {
          this.props.modalRegister(); // Close the modal
        }

        } else if (response.errors) {
          this.handleErrors(response.errors);
        } else {
          toast.error(CONNECTION_ERROR);
        }

        this.setState({
          submitting: false,
        });
      });
    }
  };

  render() {
    if (!this.props.register) {
      return '';
    }

    const { handleSubmit } = this.props;
    const { values } = this.props.register;
    const { submitting } = this.state;

    return (
      <RegisterStyle>
        <Form onSubmit={handleSubmit(this.submit)}>
          <RenderType disabled={this.state.disabled} />
          {values && values.fk_tipo_usuario === '2' ? (
            <RenderData
              onHandleCpf={this.handleChangeCpf}
              onHandleDate={this.handleChangeDate}
              disabled={this.state.disabled}
              onHandleClearErrors={this.handleClearErrors}
              errors={this.state.errors}
              loading={this.state.loadingSelect}
            />
          ) : (
            <RenderDataBusiness
              onHandleCnpj={this.handleChangeCpf}
              disabled={this.state.disabled}
              onHandleClearErrors={this.handleClearErrors}
              errors={this.state.errors}
              loading={this.state.loadingSelect}
            />
          )}
          <RenderDataLogin
            onVerifyEmail={this.verifyEmail}
            onVerifyPassword={this.verifyPassWord}
            onHandleClearErrors={this.handleClearErrors}
            errors={this.state.errors}
          />
          <RenderDataOmie
            onHandleClearErrors={this.handleClearErrors}
            errors={this.state.errors}
          />
          {!this.props.isAuth || this.state.landingPage ? (
            <Col className={'content__data-check'}>
              <Wrapper>
                <Field
                  name={'avisos'}
                  id={'avisos'}
                  type={'checkbox'}
                  typefield={'checkbox'}
                  component={renderField}
                  label={''}
                />
                <Label htmlFor={'avisos'}>
                  Receber avisos com informações sobre os meus pedidos e o meu
                  cadastro.
                </Label>
              </Wrapper>
              <Wrapper>
                <Field
                  name={'news_usuario'}
                  id={'news_usuario'}
                  type={'checkbox'}
                  typefield={'checkbox'}
                  component={renderField}
                  label={''}
                />
                <Label htmlFor={'news_usuario'}>
                  Quero receber as novidades e lançamentos por e-mail
                </Label>
              </Wrapper>
              <Wrapper>
                <Field
                  name={'comerciais'}
                  id={'comerciais'}
                  type={'checkbox'}
                  typefield={'checkbox'}
                  component={renderField}
                  label={''}
                />
                <Label htmlFor={'comerciais'}>
                  Eu autorizo que seja feito o tratamento de dados para a
                  definição de perfis, de forma a oferecer um melhor serviço e
                  prestar informações e ofertas comerciais adequadas.
                </Label>
              </Wrapper>
              <Wrapper>
                <Field
                  name={'agree_terms'}
                  id={'agree_terms'}
                  type={'checkbox'}
                  typefield={'checkbox'}
                  component={renderField}
                  validate={terms}
                  required
                />
                <Label htmlFor={'agree_terms'}>
                  Declaro que li e concordo com os{' '}
                  <Anchor
                    href="https://instrucoes.fabricadolivro.com.br/politica-empresa/termos-de-uso/"
                    target="_blank"
                    title="Política de Privacidade"
                  >
                    <strong>termos de uso</strong>
                  </Anchor>{' '}
                  da Fábrica do livro e que tenho mais de 18 anos.
                </Label>
              </Wrapper>
            </Col>
          ) : (
            ''
          )}

          <BtnGroup>
            <span>* Campos Obrigatórios</span>
            <span>
              {values && (values.consumidor_final === '' || values.icms_st === '' || values.industrializacao === '' || values.comercializacao === '') ? (
                ''
              ) : (
                <Button type={'submit'} disabled={submitting} >
                  Salvar{submitting ? <LoaderForm /> : ''}
                </Button>
              )}           
            </span>
          </BtnGroup>
        </Form>
      </RegisterStyle>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuth: state.user.isAuthenticated,
    register: state.form.register,
    initialValues: objValuesToString(state.user.user),
    userLogged: objValuesToString(state.user.user),
  };
};

Register = reduxForm({
  form: 'register',
})(Register);

Register = connect(mapStateToProps, { modalLogin, modalRegister, signIn })(
  Register
);

Register = Modal(Register);

export default Register;
