import loja from '../apis/loja';
import {
  FETCH_FILTER,
  FETCH_FILTER_ON_CHANGE,
  CLEAR_FILTER,
  LAST_PRODUCT,
  ACTIVE_REQUEST,
  FETCH_DEPENDECIES,
  FETCH_SIMULATE_DELIVERY,
  CLEAR_SIMULATE_DELIVERY,
} from './types';
// services
import { getMenuAmigavel } from '../services/filter';
import { toast } from 'react-toastify';

/**
 * Busca os dados para popular o filtro na primeira vez que a página é aberta
 * @param {string} url [imprima-url-amigavel] (é necessário remover esse imprima)
 * @param {integer} pk_produto
 */
export const fetchFilter = (url, pk_produto) => async dispatch => {
  const url_amigavel = getMenuAmigavel(url);

  try {
    const response = await loja.post('/produto/filtrar', {
      url_amigavel,
      pk_produto,
    });

    dispatch({ type: FETCH_FILTER, payload: response.data });
  } catch (error) {
    dispatch({
      type: FETCH_FILTER,
      payload: { data: 'Not Found' },
    });
  }
};

export const fetchDependencies = data => async dispatch => {
  try {
    const response = await loja.post(
      '/produto/buscar-dependencias-miolo',
      data
    );

    dispatch({ type: FETCH_DEPENDECIES, payload: response.data });
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

/**
 * Busca os dados para alterar o filtro quando o usuário
 * está interagindo com o mesmo
 * @param {object} data
 */
export const fetchFilterOnChange = data => async dispatch => {
  try {
    const response = await loja.post('/produto/filtrar', data);

    dispatch({ type: FETCH_FILTER_ON_CHANGE, payload: response.data });
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

/**
 * Reseta os dados do filtro no store
 */
export const clearFilter = () => {
  return {
    type: CLEAR_FILTER,
  };
};

/**
 * url do ultimo produto acessado no filtro
 */
export const lastProduct = url => {
  return {
    type: LAST_PRODUCT,
    payload: url,
  };
};

/**
 * Seta a flag de request ativo no filtro
 */

export const activeRequest = flag => {
  return {
    type: ACTIVE_REQUEST,
    payload: flag,
  };
};

/**
 * Simular Frete
 * @param {Object} data
 */

export const simulateDelivery = data => async dispatch => {
  try {
    const response = await loja.post('/endereco/simular-frete', data);

    dispatch({
      type: FETCH_SIMULATE_DELIVERY,
      payload: response.data,
    });
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

export const clearSimulateDelivery = () => {
  return {
    type: CLEAR_SIMULATE_DELIVERY,
  };
};
