import axios from "axios";

import { getInfoEditor, init } from "../services/editor";

const apiEditor = axios.create({
  baseURL: getInfoEditor() ? getInfoEditor().urlApi : null
});

/**
 * Interceptor de toda requisição que o projeto irá fazer para essa API
 *
 * Envia o token de autenticação em todas as requisições desde que o
 * usuário esteja logado
 */
apiEditor.interceptors.request.use(async config => {
  config.baseURL = getInfoEditor() ? getInfoEditor().urlApi : null;

  const infoEditor = getInfoEditor();
  if (infoEditor && infoEditor.token) {
    config.headers.Authorization = `Bearer ${infoEditor.token.access_token}`;
  }
  return config;
});

apiEditor.interceptors.response.use(
  response => response,
	(error) => {
    if (error.config && error.response && error.response.status === 401) {
      return init().then(() => {
        error.config.baseURL = null;
        return apiEditor.request(error.config);
      });
    }
  
    return Promise.reject(error);
	}
);

export default apiEditor;
