import React from 'react';
import { connect } from 'react-redux';

// actions
import { changePayment } from '../../actions/payment';

// utils
import { slugify } from '../../utils';

// styles
import { Row, Col } from '../../styles/align';
import { Wrapper, Label, Radio } from '../../styles/forms';

const BankSlip = ({
  dependencia_tipo_pagamento,
  changePayment,
  cnpj,
  titular,
}) => {
  return (
    <Col className={'deposit'}>
      <Row>
        {dependencia_tipo_pagamento.map((dependencia, i) => {
          return (
            <Col key={i}>
              <Wrapper>
                <Radio
                  onChange={e => changePayment(e.target.value)}
                  id={dependencia.pk_dependencia_tipo_pagamento}
                  name={'pagamento_deposito'}
                  value={dependencia.pk_dependencia_tipo_pagamento}
                />
                <span
                  className={`${slugify(
                    dependencia.desc_dependencia_tipo_pagamento
                  )} card-list`}
                ></span>
                <Label htmlFor={dependencia.pk_dependencia_tipo_pagamento}>
                  {dependencia.desc_dependencia_tipo_pagamento}
                  <br />
                  {dependencia.info_dependencia_tipo_pagamento}
                </Label>
              </Wrapper>
            </Col>
          );
        })}
      </Row>
      <div className={'titular'}>
        <p>
          <strong>Titular: </strong>
          {titular}
        </p>
        <p>
          <strong>CNPJ: </strong>
          {cnpj}
        </p>
        <p>
          <strong>Depósito: </strong>Liberação financeira em até 3 horas úteis
          na boca do caixa ou até 72 horas úteis no caixa eletrônico
        </p>
        <p>
          <strong>Transferência Bancária: </strong>Liberação financeira de até 3
          horas úteis (mesmo banco) / DOC – até 72 horas úteis / TED – até 3
          horas (realizado dentro do expediente bancário)
        </p>
      </div>
    </Col>
  );
};

export default connect(null, { changePayment })(BankSlip);
