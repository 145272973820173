import React, { useRef } from 'react';
import { Field } from 'redux-form';
import { Link } from 'react-router-dom';

// config
import config from '../../../../config';

// hocs
import CardFilter from '../../../../hocs/CardFilter';

// validate
import renderField from '../../../../formValidate/renderField';

// Components
import Multiply from '../../../Multiply';
import Tooltip from '../../../Tooltip';

// Styles
import { Row, Col, SeparatorH } from '../../../../styles/align';
import { Wrapper, Label, BtnGroup } from '../../../../styles/forms';
import {
  QuantityBook,
  QuantityBookValues,
  OptionsQuantity,
  Calculator,
} from './style';

const BookQuantity = ({
  data,
  onChangeSubmit,
  onChangeValues,
  activeRequest,
}) => {
  const paginas_preto_branco = useRef();
  const paginas_coloridas = useRef();

  const {
    dependencias_adicionais_cores,
    opcoes_quantidade,
    atributos_selecionados,
    name_quantity,
    is_capa_dura
  } = data;

  return (
    <QuantityBook>
      {dependencias_adicionais_cores.length < 3 ? (
        <Row>
          <span>
            {data.atributos_selecionados.lados_impressao === config.TYPE_PAGES
              ? 'Folhas'
              : 'Páginas'}
          </span>
          <Multiply
            step={data.quantidades.multiplicador_paginas}
            name={name_quantity ? name_quantity : 'paginas_preto_branco'}
            data={data}
            onChangeSubmit={onChangeSubmit}
            identifyMultiply={'paginas'}
            onChangeValues={onChangeValues}
            activeRequest={activeRequest}
            type={'pages'}
          />
        </Row>
      ) : (
        <>
          <Row>
            <span>
              {data.atributos_selecionados.lados_impressao === config.TYPE_PAGES
                ? 'Folhas'
                : 'Páginas'}{' '}
              Preto e Branco
            </span>
            <Multiply
              step={data.quantidades.multiplicador_paginas}
              ref={paginas_preto_branco}
              name={'paginas_preto_branco'}
              data={data}
              onChangeSubmit={onChangeSubmit}
              identifyMultiply={'paginas'}
              otherValue={paginas_coloridas}
              onChangeValues={onChangeValues}
              activeRequest={activeRequest}
              type={'pages'}
            />
          </Row>
          <Row>
            <span>
              {data.atributos_selecionados.lados_impressao === config.TYPE_PAGES
                ? 'Folhas'
                : 'Páginas'}{' '}
              Coloridas
            </span>
            <Multiply
              step={data.quantidades.multiplicador_paginas}
              ref={paginas_coloridas}
              name={'paginas_coloridas'}
              data={data}
              onChangeSubmit={onChangeSubmit}
              identifyMultiply={'paginas'}
              otherValue={paginas_preto_branco}
              onChangeValues={onChangeValues}
              activeRequest={activeRequest}
              type={'pages'}
            />
          </Row>
        </>
      )}
      <QuantityBookValues>
        <span>
          Total de{' '}
          {data.atributos_selecionados.lados_impressao === config.TYPE_PAGES
            ? 'Folhas'
            : 'Páginas'}{' '}
          <Tooltip
            dataId={'pages_total'}
            text={`A quantidade mínima de ${
              data.atributos_selecionados.lados_impressao === config.TYPE_PAGES
                ? 'Folhas'
                : 'Páginas'
            } é ${data.quantidades.min_paginas} e a máxima é ${
              data.quantidades.max_paginas
            }`}
            size={0.8}
          />
        </span>
        <span>
          {atributos_selecionados.paginas_coloridas +
            atributos_selecionados.paginas_preto_branco}
        </span>
      </QuantityBookValues>
      <SeparatorH />
      {opcoes_quantidade.opcoes.map((item, i) => {
        return (
          <OptionsQuantity key={i}>
            <Col>
              <Wrapper>
                <Field
                  name={'opcoes_quantidade'}
                  id={`opcoes_quantidade_${i}`}
                  type={'radio'}
                  typefield={'radio'}
                  component={renderField}
                  onChange={(event, newValue, previousValue, name) =>
                    onChangeSubmit(event, newValue, previousValue, name)
                  }
                  value={`${item.fk_sub_tipo_produto}`}
                />
                <Label htmlFor={`opcoes_quantidade_${i}`}>
                  {item.texto_dependencia_atributo}
                </Label>
              </Wrapper>
              {item.fk_sub_tipo_produto === 1 ? (
                <Tooltip
                  dataId={'exemplares_id'}
                  text={`A quantidade mínima de exemplares é ${data.quantidades.min_exemplares} e a máxima é ${data.quantidades.max_exemplares}`}
                  size={0.8}
                />    
              ) : (
                <Tooltip
                  dataId={'boneco_id'}
                  text={`<p>O Boneco é uma prova de como ficará o livro após ser impresso. Este material é
                  utilizado para identificar e prevenir possíveis falhas.
                  </p>
                  <br />
                  <strong>Importante:</strong>
                  <br />
                  <ul>
                    <li>É possível adquirir apenas 01 unidade deste produto.</li>
                    <li>O tempo de produção do Boneco é de ${item.prazo_producao} dias úteis.</li>
                  </ul>`}
                  size={0.8}
                />
              )}
            </Col>
            <Col>
              {item.fk_sub_tipo_produto === config.TYPE_QUANTITY_COPIES ? (
                <>
                  <Multiply
                    step={data.quantidades.multiplicador_tiragem}
                    name={'exemplares'}
                    data={data}
                    onChangeSubmit={onChangeSubmit}
                    identifyMultiply={'exemplares'}
                    onChangeValues={onChangeValues}
                    activeRequest={activeRequest}
                  />
                </>
              ) : (
                '1 UN'
              )}
            </Col>
          </OptionsQuantity>
          
        );
      })}
      <SeparatorH />  
      
      {data.lombada ? (
        <Calculator>
          <BtnGroup>
            <Link to={`/calculo-de-lombada/${atributos_selecionados.tipos_papel_miolo}/${atributos_selecionados.paginas_coloridas + atributos_selecionados.paginas_preto_branco}/${is_capa_dura ? 1 : 0}`} target="_blank" rel="noopener noreferrer">
              Calcular Lombada »
            </Link>
          </BtnGroup>
        </Calculator>
      ) : (
        ''
      )}
    </QuantityBook>
  );
};

export default CardFilter(BookQuantity);
