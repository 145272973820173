import {
  FETCH_FILTER_ORDERS,
  FETCH_ORDERS,
  FETCH_ORDER,
  CLEAR_ORDER,
  PROTOCOL_TIME_ORDER,
} from "../actions/types";

import { numberFormat, cpfFormat } from "../utils";

export default (state = {}, action) => {
  
  switch (action.type) {
    case PROTOCOL_TIME_ORDER:
      return { ...state, dateTime: action.payload.dateTime, protocol: action.payload.protocol}
    case FETCH_FILTER_ORDERS:
      return { ...state, filterOrders: action.payload.data };
    case FETCH_ORDERS:
      return { ...state, myOrders: action.payload.data };
    case FETCH_ORDER:
      let delivery = {};
      let balcony = {};
      if (action.payload.data) {
        const { frete } = action.payload.data;
        if (frete.balcao) {
          balcony = getBalcony(frete);
        } else {
          delivery = getDelivery(frete);
        }
      }
      return { ...state, order: action.payload.data, delivery, balcony };
    case CLEAR_ORDER:
      return { ...state, order: undefined };
 
    default:
      return state;
  }
};

/**
 * Monta o objeto de entrega de balcão
 * @param {object} frete
 */
function getBalcony(frete) {
  return [
    {
      label: "Endereço Selecionado",
      values: [
        {
          label: "Nome: ",
          value: frete.balcao.desc_balcao
        },
        {
          label: "Endereço: ",
          value: `${frete.balcao.logradouro_balcao}, ${frete.balcao.nr_balcao}`
        },
        {
          label: "Bairro: ",
          value: frete.balcao.bairro_balcao
        },
        {
          label: "Observações: ",
          value: frete.balcao.complemento_balcao
        },
        {
          label: "Atendimento: ",
          value: frete.balcao.info_atendimento
        },
        { label: "Telefone", value: frete.balcao.fone_balcao },
        {
          label: "Cidade: ",
          value: `${frete.balcao.cidades.desc_cidade} - ${frete.balcao.cidades.estados.uf_estado}`
        },
        { label: "CEP: ", value: frete.balcao.cep_balcao }
      ]
    },
    {
      label: "Opção de Entrega",
      values: [
        {
          label: "Balcão: ",
          value:
            frete.vlr_frete === 0
              ? "Frete Grátis"
              : `R$ ${numberFormat(frete.vlr_frete)}`
        },
        {
          label: "Prazo de Entrega: ",
          value:
            frete.qtd_prazo_entrega_frete > 1
              ? `${frete.qtd_prazo_entrega_frete} dias úteis`
              : `${frete.qtd_prazo_entrega_frete} dia útil`
        }
      ]
    },
    {
      label: "Responsável pela retirada",
      values: [
        {
          label: "Nome: ",
          value: frete.nome_razao_retirada_balcao
            ? frete.nome_razao_retirada_balcao
            : "Não informado"
        },
        {
          label: "CPF/CNPJ: ",
          value: frete.cpf_cnpj_retirada_balcao
            ? cpfFormat(frete.cpf_cnpj_retirada_balcao)
            : "Não informado"
        }
      ]
    }
  ];
}

/**
 * Monta o objeto de entrega em casa
 * @param {object} frete
 */
function getDelivery(frete) {
  return [
    {
      label: "Endereço Selecionado",
      values: [
        {
          label: "Nome: ",
          value: frete.usuarioEndereco.desc_usuario_endereco
        },
        {
          label: "Endereço: ",
          value: `${frete.usuarioEndereco.logradouro_usuario_endereco}, ${frete.usuarioEndereco.nr_usuario_endereco}`
        },
        {
          label: "Bairro: ",
          value: frete.usuarioEndereco.bairro_usuario_endereco
        },
        {
          label: "Telefone: ",
          value: frete.usuarioEndereco.fone_usuario_endereco
        },
        {
          label: "Cidade: ",
          value: `${frete.usuarioEndereco.cidades.desc_cidade} - ${frete.usuarioEndereco.cidades.estados.uf_estado}`
        },
        {
          label: "CEP: ",
          value: frete.usuarioEndereco.cep_usuario_endereco
        }
      ]
    },
    {
      label: "Opção de Entrega",
      values: [
        {
          label: `${frete.tipoTransporte.desc_tipo_transporte}: `,
          value: `R$ ${numberFormat(frete.vlr_frete)}`
        },
        {
          label: "Prazo de Entrega: ",
          value:
            frete.qtd_prazo_entrega_frete > 1
              ? `${frete.qtd_prazo_entrega_frete} dias úteis`
              : `${frete.qtd_prazo_entrega_frete} dia útil`
        }
      ]
    },
    {
      label: "Contato para Transporte",
      values: [
        {
          label: "Contato: ",
          value: frete.contato_transporte
            ? frete.contato_transporte
            : "Não informado"
        },
        {
          label: "CPF: ",
          value: frete.documento_transporte
            ? frete.documento_transporte
            : "Não informado"
        },
        {
          label: "Telefone: ",
          value: frete.telefone_transporte
            ? frete.telefone_transporte
            : "Não informado"
        }
      ]
    }
  ];
}
