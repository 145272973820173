import _ from 'lodash';
import config from '../config';
import loja from '../apis/loja';
import storage from '../utils/Storage';
import { DATA_PRODUCT, REFILE } from '../utils/Storage/types';
import { toast } from 'react-toastify';

/**
 * Trata o produto para adicionar no storage
 * @param {object} data [dados do produto]
 * @param {integer} pk_produto
 * @param {boolean} referencia [indice do produto no carrinho]
 */
function mountDataProduct(
  data,
  pk_produto,
  referencia = false,
  pedido_item = null,
  pedido = null
) {
  const {
    papel, 
    orientacao,
    paginas_preto_branco,
    paginas_coloridas,
    opcoes_quantidade,
    exemplares,
    tipos_papel_miolo,
  } = data;
  let acabamentos = [];
  let servicos = [];
  let values = {};
  let quantidade = 1;

  if (data.quantidade_variavel) {
    quantidade = data.quantidade_variavel;
  }

  values = {
    ...values,
    papel,
    produto: pk_produto,
    paginas_preto_branco,
    paginas_coloridas,
    pk_papel_miolo: tipos_papel_miolo,
    acabamento_obrigatorio: data.acabamento_obrigatorio,
    quantidade,
    exemplares,
    orientacao,
    referencia,
    pedido_item,
    pedido,
  };

  const refile = storage.get(REFILE);
  if (refile) {
    values = { ...values, ...refile };
    removeRefile();
  }

  if (!orientacao) {
    values = { ...values, orientacao: '1' }; // retrato
  }

  if (opcoes_quantidade === config.TYPE_BONECO) {
    values = { ...values, exemplares: 1 };
  }

  if (data.servicos) {
    if (data.servicos) {
      Object.entries(data.servicos)
        .filter(servico => servico[1] === true)
        .forEach(retorno => {
          servicos = [...servicos, retorno[0]];
        });

      values = { ...values, servicos };
    }
  }

  if (!data.acabamentos) {
    if (data.acabamento_obrigatorio) {
      acabamentos = [...acabamentos, data.acabamento_obrigatorio];
    }

    if (data.acabamento_opcional) {
      Object.entries(data.acabamento_opcional)
        .filter(acabamento => acabamento[1] === true)
        .forEach(retorno => {
          acabamentos = [...acabamentos, retorno[0]];
        });
    }
  } else {
    data.acabamentos.forEach(acabamento => {
      acabamentos = [...acabamentos, acabamento.pk_acabamento];
    });
  }

  if (acabamentos.length > 0) {
    return {
      ...values,
      acabamentos,
    };
  }

  return values;
}

/**
 * Adiciona o produto no storage
 * @param {object} data [dados do produto]
 * @param {integer} pk_produto
 * @param {boolean} referencia [indice do produto no carrinho]
 * @param {integer} pedido [dados para o reenvio vindo da pagina do pedido]
 * @param {integer} pedido_item [dados para o reenvio vindo da pagina do pedido]
 * @param {boolean} useData [Utilizar dados do produto sem adicionar o mesmo no storage]
 */
export const addProduct = async (
  data,
  pk_produto,
  referencia = false,
  pedido_item = null,
  pedido = null
) => {
  const resultData = await mountDataProduct(
    data,
    pk_produto,
    referencia,
    pedido_item,
    pedido
  );

  if (data.useData) {
    return resultData;
  }

  storage.set(DATA_PRODUCT, resultData);

  return true;
};

export const getServiceSelected = (valuesForm, fk_service) => {
  let services = {};

  if (valuesForm.servicos) {
    services = {
      ...valuesForm.servicos,
      [fk_service]: !valuesForm.servicos[fk_service],
    };
  } else {
    services = { [fk_service]: true };
  }

  return services;
};

/**
 * Retorna um objeto com os acabamentos selecionados
 * @param {object} valuesForm [acabamentos previamente selecionados]
 * @param {integer} pk_acabamento [acabamento que acabou de ser selecionado]
 */
export const getFinishesSelected = (valuesForm, pk_acabamento, isModal) => {
  let acabamentos = {};
  if (valuesForm.acabamento_opcional) {
    if (isModal) {
      acabamentos = {
        ...valuesForm.acabamento_opcional,
      };
    } else {
      acabamentos = {
        ...valuesForm.acabamento_opcional,
        [pk_acabamento]: !valuesForm.acabamento_opcional[pk_acabamento],
      };
    }
  } else {
    acabamentos = { [pk_acabamento]: true };
  }
  return acabamentos;
};

/**
 * Retorna um array com os acabamentos quem devem ser bloqueados na view
 * @param {object} allBlocked
 * @param {array} finishesSelected
 */
export const finishesBlocked = (allBlocked, finishesSelected) => {
  let currentBlockeds = [];
  // eslint-disable-next-line no-unused-vars
  for (const key in finishesSelected) {
    if (finishesSelected[key] && allBlocked[key]) {
      currentBlockeds = [...currentBlockeds, ...allBlocked[key]];
    }
  }

  return [...new Set(currentBlockeds)];
};

export const maxPages = (Pages, maxPages) => {
  return maxPages - Pages;
};

export const minPages = (Pages, minPages) => {
  return minPages - Pages;
};

export const checkAdditionalService = (optionalServices, serviceSelected) => {
  let services = [];
  let result = {};

  Object.entries(serviceSelected)
    .filter(service => service[1] === true)
    .forEach(retorno => {
      services = [...services, +retorno[0]];
    });

  const serviceFiltered = optionalServices.filter(serviceOptional =>
    services.includes(serviceOptional.fk_servico)
  );

  result.price = serviceFiltered.reduce(function(prevVal, elem) {
    return prevVal + elem.vlr_total;
  }, 0);

  result.deadline = serviceFiltered.reduce(function(prevVal, elem) {
    return prevVal + elem.prazo;
  }, 0);

  return result;
};

export const checkAdditional = (
  optionalFinishes,
  finishesSelected,
  finishesBlocked = []
) => {
  let result = {};
  let finishesNonBlocked = [];
  // filtra os acabamentos selecionados
  const finishesFiltered = getFinishesFiltered(
    optionalFinishes,
    finishesSelected
  );
  // caso tenha o array com os acabamentos bloqueados
  // retira qualquer acabamento selecionado que será bloqueado
  if (finishesBlocked.length) {
    finishesNonBlocked = finishesFiltered.filter(
      finish => !finishesBlocked.includes(finish.pk_acabamento)
    );
  } else {
    finishesNonBlocked = finishesFiltered;
  }
  // soma o valor adicional
  result.price = finishesNonBlocked.reduce(function(prevVal, elem) {
    return prevVal + elem.valor_acabamento;
  }, 0);
  // soma o prazo adicional
  result.deadline = finishesNonBlocked.reduce(function(prevVal, elem) {
    return prevVal + elem.qtd_dias_acabamento;
  }, 0);

  return result;
};

/**
 * Envia os dados para a API enviar um email para o cliente
 * @param {object} dataBudget
 * @param {object} dataProduct
 * @param {integer} pk_produto
 */
export const sendBudget = async (dataBudget, dataProduct, pk_produto) => {
  // setup
  let dataSubmit = { ...dataBudget };
  dataSubmit = _.omit(dataSubmit, 'telefone_orcamento');

  const aCelPhone = dataBudget.telefone_orcamento.split(' ');
  dataSubmit.ddd_telefone_orcamento = aCelPhone[0].replace(/[^\d]+/g, '');
  dataSubmit.nr_telefone_orcamento = aCelPhone[1].replace(/[^\d]+/g, '');
  dataSubmit.news_orcamento = dataSubmit.news_orcamento ? 1 : 0;

  dataSubmit = { ...dataSubmit, ...mountDataProduct(dataProduct, pk_produto) };

  try {
    const response = await loja.post('/produto/orcamento', dataSubmit);
    return response;
  } catch (error) {
    if (error.response) {
      return { errors: error.response.data };
    }
    return { error: 'CONNECTION_ERROR' };
  }
};

/**
 * remove o "imprimir-" da url
 * @param {string} url
 */
export const getMenuAmigavel = url => {
  return url.replace(/imprimir-/g, '');
};

export const getFinishesFiltered = (optionalFinishes, finishesSelected) => {
  let finishes = [];
  Object.entries(finishesSelected)
    .filter(acabamento => acabamento[1] === true)
    .forEach(retorno => {
      finishes = [...finishes, +retorno[0]];
    });
  const finishesFiltered = optionalFinishes.filter(acabamentoOpcional =>
    finishes.includes(acabamentoOpcional.pk_acabamento)
  );
  return finishesFiltered;
};

/**
 * Salva os dados do tamanho do refile
 * @param {float} altura_refile
 * @param {float} largura_refile
 */
export const saveRefile = (altura_refile, largura_refile) => {
  storage.set(REFILE, { altura_refile, largura_refile });
};

/**
 * Remove os dados do tamanho do refile
 */
export const removeRefile = () => {
  localStorage.removeItem(REFILE);
};

/**
 * Calcula a Lombada do Livro
 * @param {Object} data
 */

export const calcularLombada = async data => {
  try {
    const response = await loja.post('/produto/calcular-lombada', data);

    return response;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

/**
 * Organização dos arquivos do gabarito
 * @param {Object} gabaritos
 * @return {Array}
 */
export const gabarito = (gabaritos) => {
  let arrayGabaritos = [];
  let desc = '';
  let value = '';
  if (gabaritos) {
    // eslint-disable-next-line no-unused-vars
    for (let tipo in gabaritos) {
      // eslint-disable-next-line no-unused-vars
      for (let index in gabaritos[tipo]) {
        desc = `${tipo} - ${gabaritos[tipo][index].desc_link} - ${gabaritos[tipo][index].desc_tipo_link}`;
        value = gabaritos[tipo][index].link;
        arrayGabaritos = [...arrayGabaritos, { desc, value }];
      }
    }
  }

  return arrayGabaritos;
};