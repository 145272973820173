import styled from 'styled-components';

import { Row, Col } from '../../../../styles/align';
import { Wrapper } from '../../../../styles/forms';

/**
 * Formata o bloco da imagem
 * @extends {figure} Tag figure nativa HTML
 */

export const Figure = styled.figure`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem 0;

  img {
    width: 300px;
    max-width: 80%;
  }
`;

/**
 * Formata o bloco de Opções de Tamanho do Livro
 * @extends {div} Tag div nativa HTML
 *
 * Herda estilo para formatar o interior do bloco
 * @extends {Row}
 * @extends {Col}
 * @extends {Wrapper}
 */

export const BookSizeStyle = styled.div`
  ${Row} {
    ${props => !props.hasOrientationOptions && 'flex-direction: column'}
    ${Col} {
      ${props => !props.hasOrientationOptions && 'width: auto'}
      @media (max-width: 768px) {
        width: 100%;
      }
      &:nth-of-type(2) {
        margin-top: 10px;
      }
      ${Figure} img {
        ${props => !props.hasOrientationOptions && 'max-width: 100%'}
      }
    }

    ${Wrapper} {
      margin-right: 0;
    }
  }
`;
