import { mdiCheckCircle } from '@mdi/js';
// Node
import Icon from '@mdi/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Field, Form, reduxForm } from 'redux-form';

// actions
import {
  clearFiles,
  fetchFiles,
  filesAuth,
  isSubmitForm,
} from '../../actions/files';
import {
  modalConfirm,
  modalConfirmFileUpload,
  modalEditor,
  modalFilesAuth,
  modalLogin,
  modalRegister,
} from '../../actions/modals';
// components
import Loader from '../../components/Loader';
import LoaderForm from '../../components/Loader/loaderForm';
import TemplateFiles from '../../components/TemplateFiles';
// validate
import FileInput from '../../formValidate/FileInput';
// services
import { getDataProduct, submitForm } from '../../services/files';
import { isAuthenticated } from '../../services/user';
// styles
import { Col } from '../../styles/align';
import {
  BtnGroup,
  Button,
  Label,
  Wrapper,
  WrapperFiles,
  Textarea,
} from '../../styles/forms';
import { TextPage } from '../../styles/text';
import { TitlePage } from '../../styles/titles';
// utils
import storage from '../../utils/Storage';
import { DATA_PRODUCT } from '../../utils/Storage/types';
import {
  ActionsContainer,
  File,
  FileContainer,
  Files,
  FilesContainer,
  Loading,
} from './style';

class SendFiles extends Component {
  state = {
    observation: '',
    loading: false,
    errorFiles: [],
    showRegisterModal: false,
  };

  componentDidMount() {
    const dataProduct = storage.get(DATA_PRODUCT);
    const userData = localStorage.getItem('usuario');
    const user = userData ? JSON.parse(userData) : null;

    if (
      user &&
      (user.icms_st === null ||
        user.comercializacao === null ||
        user.industrializacao === null ||
        user.consumidor_final === null)
    ) {
      this.setState({ showRegisterModal: true });
    }

    if (!dataProduct || !dataProduct.produto) {
      if (this.props.lastProduct !== '') {
        this.props.history.push(this.props.lastProduct);
      } else {
        this.props.history.push('/');
      }
    }

    this.props.fetchFiles(dataProduct).then(() => {
      const { editor_arte_online } = this.props.files;

      if (editor_arte_online) {
        this.props.modalEditor();
      }
    });

    this.props.modalConfirmFileUpload();
  }

  componentWillUnmount() {
    this.props.clearFiles();
    this.props.isSubmitForm(false);
    this.props.filesAuth(false);

    if (this.props.isSubmitForm) {
      this.props.handleSubmit(this.submit);
      this.forceUpdate();
    }
  }

  renderInputFiles(arquivos) {
    return Object.values(arquivos).map((arquivo, i) => {
      return (
        <File key={i}>
          <FileContainer>
            <Label htmlFor={`arquivos[${i}]`}>
              {arquivo.desc_arquivo.desc_arquivo
                ? arquivo.desc_arquivo.desc_arquivo
                : arquivo.acabamento_arquivo.desc_arquivo.desc_arquivo}
              {arquivo.obrigatorio_produto_arquivo ? <strong>*</strong> : ''}
            </Label>
            <WrapperFiles
              className={
                this.state.errorFiles.indexOf(arquivo.pk_produto_arquivo) > -1
                  ? 'error'
                  : ''
              }
            >
              {this.props.formSendFiles &&
              this.props.formSendFiles.values &&
              this.props.formSendFiles.values.arquivos[
                arquivo.pk_produto_arquivo
              ] ? (
                <span className={'sent'}>
                  <Icon path={mdiCheckCircle} title={'send'} size={1} />
                  <span>Reenviar</span>
                </span>
              ) : (
                <span className={'send'}>Enviar</span>
              )}
              <Field
                name={`arquivos[${arquivo.pk_produto_arquivo}]`}
                id={`arquivos[${i}]`}
                onChange={() =>
                  this.setState({
                    errorFiles: this.state.errorFiles.filter(
                      file => file !== arquivo.pk_produto_arquivo
                    ),
                  })
                }
                component={FileInput}
                required={arquivo.obrigatorio_produto_arquivo}
              />
            </WrapperFiles>
            <span>
              Arquivos Permitidos:
              <strong>{arquivo.extensoes.join(', ')}</strong>
            </span>
          </FileContainer>
        </File>
      );
    });
  }

  renderGabaritos() {
    const { gabaritos } = this.props;

    if (!gabaritos) {
      return '';
    }

    return <TemplateFiles gabaritos={gabaritos} />;
  }

  submit = async data => {
    const { arquivos, editor_validacao } = this.props.files;

    if (!isAuthenticated()) {
      this.props.isSubmitForm(true);
      this.props.modalLogin();
    } else {
      const userData = localStorage.getItem('usuario');
      const user = userData ? JSON.parse(userData) : null;

      if (
        user &&
        (user.icms_st === null ||
          user.comercializacao === null ||
          user.consumidor_final === null)
      ) {
        this.props.modalRegister();
        this.props.isSubmitForm(true);
        return;
      }

      this.setState({
        loading: false,
      });

      const response = await submitForm(
        data.arquivos,
        arquivos,
        this.state.observation,
        this.props.authMessage,
        editor_validacao
      );
      if (response) {
        localStorage.setItem('arquivos_nao_permitidos', 'false');
        localStorage.setItem('usar_arquivos_pedido_anterior', 'true');
        this.setState({
          loading: false,
        });
        if (response.showModalFileAuth) {
          this.props.isSubmitForm(true);
          this.props.modalFilesAuth();
        }
        if (response.showModalAnalyseError) {
          this.props.isSubmitForm(true);
          this.props.modalConfirm({
            title: 'Atenção',
            text: response.showModalAnalyseError,
          });
        }
        if (response.message && response.message !== '') {
          this.setState({
            errorFiles: response.errors,
          });
          toast[response.status](response.message);
        }
        if (response.redirect) {
          this.props.history.push(response.redirect);
        }
      }
    }
  };

  render() {
    const { files, handleSubmit, pristine, submitting } = this.props;
    const product = getDataProduct();

    if (!files) {
      return <Loader />;
    }
    return (
      <Files>
        {this.state.loading ? (
          <Loading>
            <Loader />
          </Loading>
        ) : (
          ''
        )}
        <Col>
          <TitlePage>
            <span>
              {product && (product.pedido_item || product.referencia)
                ? 'Reenvio de Arquivos'
                : 'Envio de Arquivos'}
            </span>
          </TitlePage>
          <TextPage>
            Selecione o arquivo correspondente a cada arte do produto. Veja as
            observações abaixo do produto.
          </TextPage>
          <Form onSubmit={handleSubmit(this.submit)}>
            <FilesContainer>
              {this.renderInputFiles(files.arquivos)}
            </FilesContainer>
            <ActionsContainer>
              <Col>{this.renderGabaritos()}</Col>
              <Col>
                <Wrapper bold>
                  <span>Observações do pedido</span>
                  <Textarea
                    onChange={e =>
                      this.setState({ observation: e.target.value })
                    }
                    placeholder="Inclua aqui as observações que você gostaria que tenha atenção sobre o seu pedido na hora da impressão..."
                  ></Textarea>
                </Wrapper>
              </Col>
            </ActionsContainer>
            <BtnGroup>
              <Button type="submit" disabled={pristine || submitting} secondary>
                Prosseguir »{submitting ? <LoaderForm /> : ''}
              </Button>
            </BtnGroup>
          </Form>
        </Col>
      </Files>
    );
  }
}

const mapStateToProps = state => {
  return {
    files: state.files.data,
    lastProduct: state.filter.lastProduct,
    gabaritos: state.files.gabaritos,
    formSendFiles: state.form.sendFiles,
    authMessage: state.files.authMessage,
  };
};

SendFiles = reduxForm({
  form: 'sendFiles',
})(SendFiles);

SendFiles = connect(mapStateToProps, {
  fetchFiles,
  modalLogin,
  modalRegister,
  clearFiles,
  isSubmitForm,
  modalFilesAuth,
  filesAuth,
  modalEditor,
  modalConfirm,
  modalConfirmFileUpload,
})(SendFiles);

export default SendFiles;
