// node-modules
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, Form, reduxForm } from 'redux-form';

// actions
import {
  activeRequest,
  clearSimulateDelivery,
  fetchFilterOnChange,
} from '../../../actions/filter';
import { modalRefile } from '../../../actions/modals';
import BookFinishes from '../../../components/Filter/FilterBook/FilterBookFinishes';
import FilterBookMandatory from '../../../components/Filter/FilterBook/FilterBookMandatory';
import Loader from '../../../components/Loader';
// components
import Multiply from '../../../components/Multiply';
// validate
import renderField from '../../../formValidate/renderField';
// services
import {
  checkAdditional,
  finishesBlocked,
  getFinishesSelected,
  getMenuAmigavel,
  removeRefile,
} from '../../../services/filter';
// Styles
import { Col, Row } from '../../../styles/align';
import { Label, Wrapper, WrapperCheckbox } from '../../../styles/forms';
// utils
import { numberFormat, objValuesToString, slugify } from '../../../utils';
import {
  CardFilter,
  Figure,
  FilterContainer,
  Loading,
  TitleCard,
} from '../style';
import { CardFilterList, Quantity } from './style';

class FilterProduct extends Component {
  state = {
    finishesBlocked: [],
    acabamentos_opcionais_preco: 0.0,
    acabamentos_opcionais_prazo: 0.0,
    acabamento_obrigatorio_preco: 0.0,
    acabamento_obrigatorio_prazo: 0.0,
    loading: false,
  };

  componentDidMount() {
    removeRefile();
  }

  renderFinishTypes() {
    const { dados_produto } = this.props.dataFilter;

    return (
      <CardFilter>
        <Figure>
          <img
            alt={dados_produto.nome_menu_imagem}
            src={`${dados_produto.url_base}${dados_produto.nome_menu_imagem}`}
            title={dados_produto.nome_menu_imagem}
          />
        </Figure>
        <FilterBookMandatory
          data={this.props.dataFilter}
          updateFinishBlocked={item => {
            this.handleChangeFinishType.apply(this, [item]);
          }}
        />
      </CardFilter>
    );
  }

  renderOptions() {
    const { atributos } = this.props.dataFilter;
    if (Object.entries(atributos).length === 0) {
      return '';
    }

    return (
      <CardFilterList>
        <TitleCard>
          <span>1</span>Opções do Produto
        </TitleCard>
        {Object.values(atributos)
          .filter(atributes => atributes.texto_atributo !== "Acabamento Incluso")
          .map((atributes, key1) => {
          return (
            <Col key={key1}>
              <TitleCard>{atributes.texto_atributo}</TitleCard>
              {Object.values(atributes.opcoes)
                .filter(opcao => opcao.fk_atributo)
                .map((opcao, key2) => {
                  return (
                    <Wrapper key={key2}>
                      <Field
                        id={`produto_${opcao.pk_dependencia_atributo}`}
                        name={slugify(atributes.texto_atributo)}
                        type={'radio'}
                        typefield={'radio'}
                        component={renderField}
                        value={`${opcao.pk_dependencia_atributo}`}
                        onChange={(event, newValue, previousValue, name) =>
                          this.handleChangeSubmit(
                            event,
                            newValue,
                            previousValue,
                            name
                          )
                        }
                      />
                      <Label
                        htmlFor={`produto_${opcao.pk_dependencia_atributo}`}
                      >
                        <span>{opcao.texto_dependencia_atributo}</span>
                        <span></span>
                      </Label>
                    </Wrapper>
                  );
                }
              )}
            </Col>
          );
        })}
      </CardFilterList>
    );
  }

  renderQuantity() {
    const { qtde_filtro } = this.props.dataFilter;
    if (Object.entries(qtde_filtro).length === 0) {
      return '';
    }

    return (
      <CardFilterList>
        <TitleCard>
          <span>2</span>Quantidade
        </TitleCard>
        <Col>
          {Object.values(qtde_filtro).map((item, i) => {
            return (
              <Wrapper key={item.pk_produto}>
                <Field
                  name={'quantidade'}
                  id={`quantidade[${i}]`}
                  type={'radio'}
                  typefield={'radio'}
                  component={renderField}
                  onChange={e => this.handleChangeSubmit(e)}
                  value={`${item.pk_dependencia_atributo}`}
                />
                <Label htmlFor={`quantidade[${i}]`}>
                  <span>{item.texto_dependencia_atributo}</span>
                  <span>R$ {numberFormat(item.valor, 2)}</span>
                </Label>
              </Wrapper>
            );
          })}
        </Col>
      </CardFilterList>
    );
  }

  renderVariableQuantity() {
    const { dados_produto, quantidades } = this.props.dataFilter;
    if (!dados_produto.quantidade_variavel) {
      return '';
    }

    return (
      <CardFilterList>
        <TitleCard>Quantidade do Produto</TitleCard>
        <Col>
          <Quantity>
            <Multiply
              step={quantidades.multiplicador_tiragem}
              name={'quantidade_variavel'}
              data={this.props.dataFilter}
              onChangeSubmit={this.handleChangeSubmit}
              identifyMultiply={'quantidade_variavel'}
              onChangeValues={this.props.change}
              activeRequest={this.props.activeRequest}
            />
          </Quantity>
        </Col>
      </CardFilterList>
    );
  }

  renderFinishes() {
    const { acabamentos_opcionais } = this.props.dataFilter;
    if (acabamentos_opcionais.length === 0) {
      return '';
    }

    return (
      <CardFilterList>
        <TitleCard>
          <span>3</span>Acabamentos Opcionais
        </TitleCard>

        {
          <BookFinishes
            data={this.props.dataFilter}
            onChangeSubmit={this.handleChangeSubmit}
            updateService={pkAcabamento => {
              if (this.state.finishesBlocked.includes(pkAcabamento)) {
                this.props.change(
                  `acabamento_opcional[${pkAcabamento}]`,
                  false
                );
              }
            }}
            updateFinishBlocked={item => this.checkRefile(item)}
            finishesBlocked={this.state.finishesBlocked}
          />
        }

        {/* { <Col className={'limit__box'}>
          {Object.values(acabamentos_opcionais).map((item, index) => {
            if (this.state.finishesBlocked.includes(item.pk_acabamento)) {
              this.props.change(
                `acabamento_opcional[${item.pk_acabamento}]`,
                false
              );
            }
            return (
              <Wrapper key={index} row border margin={'0'} padding={'.6rem'}>
                <Field
                  name={`acabamento_opcional[${item.pk_acabamento}]`}
                  type={'checkbox'}
                  id={`acabamento_opcional[${index}]`}
                  typefield={'checkbox'}
                  component={renderField}
                  disabled={this.state.finishesBlocked.includes(
                    item.pk_acabamento
                  )}
                  onChange={() => this.checkRefile(item)}
                />
                <Label htmlFor={`acabamento_opcional[${index}]`}>
                  {this.renderDescWithDeadline(item)}
                </Label>
              </Wrapper>
            );
          })}
        </Col> } */}
      </CardFilterList>
    );
  }

  handleChangeFinishType(item) {
    this.setState({
      acabamento_obrigatorio_preco: item.valor_acabamento,
      acabamento_obrigatorio_prazo: item.qtd_dias_acabamento,
    });

    const additionalPrice =
      this.state.acabamentos_opcionais_preco + item.valor_acabamento;

    const additionalDeadline =
      this.state.acabamentos_opcionais_prazo + item.qtd_dias_acabamento;

    this.props.onAdditional(additionalPrice, additionalDeadline);
    this.props.onFinishSelect(item);
  }

  checkRefile = item => {
    if (item.altura_min && item.largura_min) {
      this.props.modalRefile({
        acabamento: item,
        dadosProduto: this.props.dataFilter.dados_produto,
        callbackSubmit: () => this.updateFinishBlocked(item, true),
        callbackCancel: () => this.onCancelRefile(item),
      });
    } else {
      this.updateFinishBlocked(item);
    }
  };

  onCancelRefile(item) {
    removeRefile();
    this.props.change(`acabamento_opcional[${item.pk_acabamento}]`, false);
    this.props.modalRefile('');
  }

  updateFinishBlocked(item, isModal = false) {
    // verifica os acabamentos que devem ser bloqueados [inicio]
    let finishesSelected = getFinishesSelected(
      this.props.filterProductsOnChange.values,
      item.pk_acabamento,
      isModal
    );

    const newFinishesBlocked = finishesBlocked(
      this.props.dataFilter.acabamentos_bloqueados,
      finishesSelected
    );
    // verifica os acabamentos que devem ser bloqueados [fim]

    const additional = checkAdditional(
      this.props.dataFilter.acabamentos_opcionais,
      finishesSelected,
      newFinishesBlocked
    );

    this.setState({
      finishesBlocked: newFinishesBlocked,
      acabamentos_opcionais_preco: additional.price,
      acabamentos_opcionais_prazo: additional.deadline,
    });
    const additionalPrice =
      additional.price + this.state.acabamento_obrigatorio_preco;
    const additionalDeadline =
      additional.deadline + this.state.acabamento_obrigatorio_prazo;

    this.props.onAdditional(additionalPrice, additionalDeadline);
  }

  handleChangeSubmit = (
    event,
    newValue = null,
    previousValue = null,
    name = null
  ) => {
    this.setState({
      loading: true,
      finishesBlocked: [],
    });
    this.props.activeRequest(true);
    this.props.clearSimulateDelivery();

    // refile reset
    removeRefile();

    const { menu } = this.props;
    const menu_amigavel = getMenuAmigavel(menu);
    // remove o acabamento opcional e obrigatorio do formulario
    // que sera enviado para buscar as novas opções
    const opcoes_marcadas = _.omit(
      this.props.filterProductsOnChange.values,
      'acabamento_opcional',
      'acabamento_obrigatorio',
      'quantidade_variavel',
      'acabamento',
    );

    let dataSubmit = {};
    let quantidade_variavel = this.props.filterProductsOnChange.values
      .quantidade_variavel;

    if (newValue) {
      dataSubmit = {
        url_amigavel: menu_amigavel,
        opcoes_marcadas: {
          ...opcoes_marcadas,
          [name]: newValue,
        },
        quantidade_variavel,
      };
    } else {
      quantidade_variavel =
        event.target.name === 'quantidade' ? null : quantidade_variavel;
      dataSubmit = {
        url_amigavel: menu_amigavel,
        opcoes_marcadas: {
          ...opcoes_marcadas,
          [event.target.name]: event.target.value,
        },
        quantidade_variavel,
      };
    }

    this.props.fetchFilterOnChange(dataSubmit).then(() => {
      this.setState({
        loading: false,
      });
      this.props.activeRequest(false);
    });
  };

  render() {
    const { handleSubmit } = this.props;
    if (!this.props.dataFilter) {
      return <Loader />;
    }

    return (
      <Form onSubmit={handleSubmit}>
        <FilterContainer>
          {this.state.loading ? (
            <Loading>
              <Loader />
            </Loading>
          ) : (
            ''
          )}
          <Row>
            {this.renderFinishTypes()}
            {this.renderOptions()}
          </Row>
          <Row>
            <Col>
              {this.renderQuantity()}
              {this.renderVariableQuantity()}
            </Col>
          </Row>
          <Row>
            <Col>{this.renderFinishes()}</Col>
          </Row>
        </FilterContainer>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  var test = {
    dataFilter: state.filter.filter,
    filterProductsOnChange: state.form.filterProductsOnChange,
    initialValues: objValuesToString(
      state.filter.filter.atributos_selecionados
    ),
  };
  return test;
};

FilterProduct = reduxForm({
  form: 'filterProductsOnChange',
  enableReinitialize: true,
  destroyOnUnmount: true,
})(FilterProduct);

FilterProduct = connect(mapStateToProps, {
  fetchFilterOnChange,
  activeRequest,
  modalRefile,
  clearSimulateDelivery,
})(FilterProduct);

export default FilterProduct;
