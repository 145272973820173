import React, { Component } from 'react';
import { connect } from 'react-redux';
// actions
import { fetchCategory } from '../../actions/category';
// components
import Products from '../../components/Products';
import NotFound from '../../components/NotFound';
import CategoryCard from '../../components/Category';
// styles
import { Container, Col, TextAlign } from '../../styles/align';

// helpers
import Seo from '../../helpers/Seo';
import SkeletonProducts from '../../components/Products/skeleton';

class Category extends Component {
  fetchData() {
    const { menu } = this.props.match.params;
    this.props.fetchCategory(menu);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    // verifica se o usuário está trocando de menu
    if (prevProps.match.params.menu !== this.props.match.params.menu) {
      if (!this.props.menu[this.props.match.params.menu]) {
        this.fetchData();
      }
    }
  }

  renderProducts = (products, title, base) => {
    if (products.length === 0) {
      return (
        <TextAlign textalign={'center'}>
          <h4>Não há produtos cadastrados para essa categoria no momento!</h4>
        </TextAlign>
      );
    }

    return <Products title={title} data={products} base={base} />;
  };

  render() {
    const currentURL = this.props.match.params.menu;
    const { menu } = this.props;

    if (!menu[currentURL]) {
      return <SkeletonProducts title={true} />;
    }

    if (menu[currentURL] === 404) {
      return <NotFound />;
    }

    return (
      <>
        <Seo
          props={{
            title: menu[currentURL].category.titulo_tags,
            meta: [
              {
                name: 'description',
                content: menu[currentURL].category.descricao_tags,
              },
            ],
            canonical: menu[currentURL].category.url_canonical,
          }}
        />
        <Container>
          <Col>
            {this.renderProducts(
              menu[currentURL].products,
              menu[currentURL].category.desc_menu,
              menu[currentURL].category.url_base
            )}
            <CategoryCard category={menu[currentURL].category} />
          </Col>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    menu: state.products,
  };
};

export default connect(mapStateToProps, { fetchCategory })(Category);
