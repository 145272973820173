import styled from 'styled-components';

import { colors } from '../../../styles/variables';

import { Container as ContainerHome, ContainerForm } from '../Home/style';

export const Container = styled(ContainerHome)`
  background-position: center;

  @media only screen and (max-width: 768px) {
    display: block;
    background-size: cover;
    height: 30rem;
  }
`;

export const ContainerCard = styled(ContainerForm)`
  background: linear-gradient(
    90deg,
    rgba(2, 110, 98, 255) 0%,
    rgba(12, 29, 74, 255) 100%
  );
`;

export const Obrigado = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  p {
    font-size: 1.25rem;
    color: ${colors.default};
    line-height: 2rem;
  }
`;

export const PObrigado = styled.p`
  margin-top: 2rem;
  font-size: 1.25rem;
  color: ${colors.default};
`;

export const H2 = styled.h2`
  color: ${colors.secondary};
  font-size: 3rem;
`;

export const Cupom = styled.div`
  border: dashed 4px ${colors.default};
  padding: 0.5rem;
  margin: 2rem 2rem;
  display: inline-block;
  background-color: ${colors.blueDark};

  h1 {
    color: ${colors.default};
  }
`;

export const Parceria = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  p {
    color: ${colors.default};
    line-height: 2rem;
  }

  h2 {
    margin: 2rem 2rem;
    color: ${colors.secondary};
    font-size: 2rem;
  }
`;

export const LinkUseCupom = styled.div`
  display: flex;

  button {
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0.1rem;
    border-radius: 4px;
    border: none;
    text-transform: uppercase;
    margin: 0.5rem;
    height: 40px;
    font-weight: 600;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    background-color: ${colors.secondary};
    cursor: pointer;
    margin: 2rem 2rem;
    text-decoration: none;
    color: ${colors.default};
    padding: 12px 1rem;
    display: flex;
    justify-content: center;
  }

  button:hover {
    transform: scale(1.1);
    background-color: ${colors.secondary};
    color: ${colors.default};
    border-radius: 5px;
    transition: all 0.2s ease-out;
  }
`;
