import React, { Component } from 'react';

// components
import OrdersTable from '../../components/Orders/OrdersTable';
import OrdersFilter from '../../components/Orders/OrdersFilter';

// styles
import { Container, Sidebar, MainPage, Col } from '../../styles/align';
import { TitlePage } from '../../styles/titles';
import { StyleOrders } from './style';

class Orders extends Component {
  onSelectOrder = pedido => {
    this.props.history.push(`/pedido/${pedido}`);
  };

  render() {
    return (
      <Container>
        <Col>
          <TitlePage internal>
            <span>Meus Pedidos</span>
          </TitlePage>
          <StyleOrders>
            <Sidebar dark>
              <OrdersFilter />
            </Sidebar>
            <MainPage>
              <OrdersTable onSelectOrder={this.onSelectOrder} />
            </MainPage>
          </StyleOrders>
        </Col>
      </Container>
    );
  }
}

export default Orders;
