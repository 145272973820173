import React from 'react';
import { Link } from 'react-router-dom';

// node-modules
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// styles
import * as Style from './style';

// options
import { settings } from './options';

const renderSlides = data => {
  return data.map((slide, i) => {
    if (slide.link) {
      return (
        <Link to={slide.link} key={i} title={slide.title}>
          <picture>
            <source srcSet={slide.thumb_wp} type="image/webp" />
            <img src={slide.thumb} alt={slide.title} title={slide.title} />
          </picture>
        </Link>
      );
    } else {
      return (
        <picture key={i} title={slide.title}>
          <source srcSet={slide.thumb_wp} type="image/webp" />
          <img src={slide.thumb} alt={slide.title} title={slide.title} />
        </picture>
      );
    }
  });
};

const Carousel = ({ data }) => {
  return (
    <Style.CarouselContainer>
      <Style.CarouselWrap>
        <Slider {...settings}>{renderSlides(data)}</Slider>
      </Style.CarouselWrap>
    </Style.CarouselContainer>
  );
};

export default Carousel;
