import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { toast } from 'react-toastify';

// styles
import {
  ContainerCard,
  Row,
  ColText,
  ColCard,
  Dobro,
  MiniCard,
  LinkSure,
  LinkNot,
  List,
  Pcard,
} from './style';

//options
import { LANDING_PAGE } from '../option';

// actions
import { landingNotLikeMore } from '../../../actions/landingPage';

// utils
import { SUCCESS } from '../../../utils/constMessage';

let Card = props => {
  const handleNotLike = () => {
    const { landingRegister } = props;
    return props
      .landingNotLikeMore(
        landingRegister.pk_usuario,
        landingRegister.pk_usuario_editora
      )
      .then(resp => {
        if (resp.status === 200) {
          toast.success(SUCCESS);
          props.history.push('/');
        } else {
          toast.error(resp);
        }
      });
  };

  return (
    <>
      <ContainerCard>
        <Row>
          <ColText>
            <Dobro>
              <h3>Quer ganhar o Dobro</h3>
              <h3>de desconto?</h3>
              <p>É só se cadastrar no nosso site e aproveitar!</p>
              <p>
                Mas... Quer um spoiler? Na próxima página tem{' '}
                <strong>MAIS 5% OFF!</strong>
              </p>
              <p>
                Termine seu cadastro e tenha ainda mais desconto! É rapidinho.:)
              </p>
              <LinkSure title="Com certeza!">
                <Link to={LANDING_PAGE.registration}>{'Com certeza!'}</Link>
              </LinkSure>
              <LinkNot title="Não gosto de descontos em dobro:(">
                <button onClick={() => handleNotLike()} type="button">
                  {'Não gosto de descontos em dobro:('}
                </button>
              </LinkNot>
            </Dobro>
          </ColText>
          <ColCard>
            <MiniCard>
              <Pcard>Vantagens da Fábrica do</Pcard>
              <Pcard>Livro</Pcard>
              <List>
                <li>Parque Gráfico Moderno</li>
                <p>Produção ágil, com máxima qualidade e o menor preço</p>
                <li>Rígido Controle de Qualidade</li>
                <p>Impressão em alto padrão gráfico.</p>
                <li>Produção em Pequenas ou Grandes Tiragens</li>
                <p>De acordo com a necessidade da sua editora.</p>
                <li>Editor Online</li>
                <p>Ferramenta poderosa e gratuita no site.</p>
                <li>Transporte</li>
                <p>Entrega em todo o território nacional.</p>
                <li>Frete Grátis</li>
                <p>Balcões de Retirada com entrega grátis.</p>
              </List>
            </MiniCard>
          </ColCard>
        </Row>
      </ContainerCard>
    </>
  );
};

const mapStateToProps = store => {
  return {
    landingRegister: store.landingPage.landingRegister,
  };
};

Card = reduxForm({
  form: 'Card',
})(Card);

Card = connect(mapStateToProps, { landingNotLikeMore })(Card);

export default withRouter(Card);
