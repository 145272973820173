import {
  FETCH_CART,
  FETCH_DELIVERY_OPTIONS,
  CLEAR_CART,
  CLEAR_DELIVERY_OPTIONS,
  NUMBER_ITEMS,
  SUMMARY_CART,
  TYPE_DELIVERY,
  DELIVERY_SELECTED,
  BALCONY_SELECTED,
  SAVE_WITHDRAWAL,
  CLEAR_CART_SUMMARY,
  SAVE_SHIPPING_CONTACT,
  RESET_SHIPPING_CONTACT,
  RESET_WITHDRAWAL,
  SUBMIT_FORM_CART,
  SET_CUPOM,
  UPDATE_CART,
  UPDATE_TERMS_CHECKED,
  PRODUCTS_TERMS_CHECKED_STATUS,
  FILES_TERMS_CHECKED_STATUS,
} from '../actions/types';

const INITIAL_STATE = {
  numberItens: 0,
  typeDelivery: '1',
  terms_signed: 0,
  deliverySelected: false,
  balconySelected: false,
  withdrawalDone: false,
  shippingContactDone: false,
  deliveryType: false,
  balconyType: false,
  cupom: '',
  produtosChecked: false,
  envioArquivosChecked: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CART:
      const { carrinho } = action.payload.data;
      const cupom = carrinho.cupom ? carrinho.cupom.codigo : '';
      const updateCart = {
        data: action.payload.data,
        cupom: cupom,
      };
      return { ...state, ...updateCart };
    case FETCH_DELIVERY_OPTIONS:
      const data = {
        deliveryOptions: action.payload.data,
        data: action.payload.data,
        summaryCart: action.payload.data.resumo,
      };
      return { ...state, ...data };
    case CLEAR_CART:
      return { ...state, data: undefined };
    case CLEAR_DELIVERY_OPTIONS:
      return { ...state, deliveryOptions: undefined };
    case NUMBER_ITEMS:
      return { ...state, numberItens: action.payload };
    case SUMMARY_CART:
      return { ...state, summaryCart: action.payload };
    case TYPE_DELIVERY:
      return { ...state, typeDelivery: action.payload };
    case DELIVERY_SELECTED:
      return { ...state, ...action.payload };
    case BALCONY_SELECTED:
      return { ...state, ...action.payload };
    case SAVE_WITHDRAWAL:
      return { ...state, withdrawalDone: true };
    case SAVE_SHIPPING_CONTACT:
      return { ...state, shippingContactDone: true };
    case CLEAR_CART_SUMMARY:
      return { ...state, summaryCart: undefined };
    case RESET_WITHDRAWAL:
      return { ...state, withdrawalDone: false };
    case RESET_SHIPPING_CONTACT:
      return { ...state, shippingContactDone: false };
    case SUBMIT_FORM_CART:
      return { ...state, isSubmitForm: action.payload };
    case SET_CUPOM:
      return { ...state, cupom: action.payload };
    case UPDATE_CART:
      return { ...state, data: action.payload.data };
    case UPDATE_TERMS_CHECKED:
      return { ...state, terms_signed: action.payload };
    case PRODUCTS_TERMS_CHECKED_STATUS:
      return { ...state, produtosChecked: action.payload};
    case FILES_TERMS_CHECKED_STATUS:
      return { ...state, envioArquivosChecked: action.payload};
    default:
      return state;
  }
};
