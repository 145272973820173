import loja from '../apis/loja';

import { FETCH_PAYMENTS, FETCH_CONTACTS } from './types';

import config from '../config';


/**
 * Busca as formas de pagamento para mostrar no rodapé
 */
export const fetchPayments = () => async dispatch => {
  try {
    const response = await loja.get('/formas-de-pagamento');

    dispatch({ type: FETCH_PAYMENTS, payload: response.data });
  } catch (error) {
      console.error('Erro ao buscar formas de pagamento', error);
  }
};

/**
 * Busca as informações de contato da parametrização
 * para mostrar no rodapé
 */
export const fetchContacts = () => async dispatch => {
  try {
    const response = await loja.get(`/params/${config.CONTATOS}`);

    dispatch({ type: FETCH_CONTACTS, payload: response.data });
  } catch (error) {
    //toast.error(error.response.data.message);
  }
};
