// altera a string para url ex: "Cartão de Visita" para "cartao-de-visita"
export const slugify = string => {
  const a =
    'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;';
  const b =
    'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

// number format [begin]
function toFixedFix(n, prec) {
  const k = Math.pow(10, prec);
  const a = Math.round(n * k) / k;
  return a.toString();
}

export function numberFormat(number, decimals = 2, decPoint, thousandsSep) {
  // Strip all characters but numerical ones.
  let numbers = number;
  numbers = numbers.toString().replace(/[^0-9+\-Ee.]/g, '');

  const n = !isFinite(+numbers) ? 0 : +numbers;
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
  const sep = typeof thousandsSep === 'undefined' ? '.' : thousandsSep;
  const dec = typeof decPoint === 'undefined' ? ',' : decPoint;
  let s = '';

  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : Math.round(n).toString()).split('.');

  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }

  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }

  return s.join(dec);
}
// number format [end]

// usar em conjunto com ReactHtmlParser
// https://www.npmjs.com/package/react-html-parser
export function parseHtmlFromString(string) {
  const parser = new DOMParser();
  const result = parser.parseFromString(string, 'text/html');

  return result.body.innerText;
}

export function formatDate(data) {
  const parts = data.split('-');
  return `${parts[2]}/${parts[1]}/${parts[0]}`;
};

// altera a data de "2010-05-22" para "22/05/2010" ou vice-versa
export function dateTransform(data) {
  let result = false;
  let from = '';
  let to = '';

  if (data) {
    // setup
    if (data.indexOf('-') !== -1) {
      from = '-';
      to = '/';
    } else if (data.indexOf('/')) {
      from = '/';
      to = '-';
    }

    if (from !== '') {
      const dataSplit = data.split(' ');
      if (dataSplit.length === 2) {
        const dataFormated = [];
        dataFormated[0] = dataSplit[0]
          .split(from)
          .reverse()
          .join(to);

        // formatando a hora para mostrar apenas horas e minutos
        const time = new Date(`1970-01-01T${dataSplit[1]}Z`);
        const hours = ('0' + time.getUTCHours()).slice(-2); 
        const minutes = ('0' + time.getUTCMinutes()).slice(-2);
        dataFormated[1] = `${hours}:${minutes}`;

        result = dataFormated.join(' ');
      } else {
        result = data
          .split(from)
          .reverse()
          .join(to);
      }
    }
  }

  return result;
}

// recebe uma string e retorna somente números
export const onlyNumbers = value => value && value.replace(/[^\d]+/g, '');

// converte os valores de um objeto para string
export const objValuesToString = data => {
  let result = {};
  for (var prop in data) {
    if (data[prop] === null) {
      result = { ...result, [prop]: '' };
    } else {
      result = { ...result, [prop]: `${data[prop]}` };
    }
  }
  return result;
};

/**
 * retorna um array com os numerais dos meses
 */
export function getMonths() {
  let months = [];
  for (let index = 1; index < 13; index++) {
    const month = index < 10 ? `0${index}` : `${index}`;
    months.push(month);
  }
  return months;
}

/**
 * retorna um array com os numerais dos anos entre os valores informados
 * @param {integer} start
 * @param {integer} end
 */
export const getRangeYears = (start = 2019, end = 2029) => {
  let years = [];
  for (start; start <= end; start++) {
    const year = `${start}`;
    years.push(year);
  }
  return years;
};

/**
 * Retorna a extensão do arquivo
 * @param {string} text [nome do arquivo]
 */
export const getExtension = text => {
  const split = text.split('.');
  return split[split.length - 1].toLowerCase();
};

/**
 * Formata a string com a mascara do cpf
 * @param {string} cpf
 */
export const cpfFormat = value => {
  if (value !== undefined) {
    value = value.replace(/[^\d]+/g, '');
    const arrValue = value.split('');
    if (arrValue.length <= 11)
      return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    else
      return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
  }
};

/**
 * Transforma o primeiro caracter da string em maísculo e o resto em minúsculo
 * @param {string} value
 */
export const toCapitalize = value =>
  value.substr(0, 1).toUpperCase() + value.substr(1).toLowerCase();

/**
 * Remove os "+" da string
 * @param {string} value
 */
export const removePlusFromString = value => {
  return value.replace(/\+/g, ' ').trim();
};

export const shuffle = array => {
  var ctr = array.length,
    temp,
    index;
  while (ctr > 0) {
    index = Math.floor(Math.random() * ctr);
    ctr--;
    temp = array[ctr];
    array[ctr] = array[index];
    array[index] = temp;
  }
  return array;
};

// função para colocar ponto antes dos dois últimos dígitos
// Ex.: 123 => 1.23
// Ex.: 123456 => 1234.56
export function insertDot(number) {
  let str = number.toString();

  let result = str.slice(0, -2) + '.' + str.slice(-2);

  return parseFloat(result);
}

export function buildPayloadPaymentTwoCards({ primeiroCartao, segundoCartao }) {
  return {
    valor_cartao: insertDot(primeiroCartao.valor),
    pagamento: primeiroCartao.dependency,
    numero_cartao: primeiroCartao.numero_cartao,
    portador: primeiroCartao.portador,
    mes: primeiroCartao.mes,
    ano: primeiroCartao.ano,
    cvc: primeiroCartao.cvc,
    num_parcelas: primeiroCartao.num_parcelas,
    dependency: primeiroCartao.dependency,
    valor_cartao2: insertDot(segundoCartao.valor),
    pagamento2: segundoCartao.dependency,
    numero_cartao2: segundoCartao.numero_cartao,
    portador2: segundoCartao.portador,
    mes2: segundoCartao.mes,
    ano2: segundoCartao.ano,
    cvc2: segundoCartao.cvc,
    num_parcelas2: segundoCartao.num_parcelas,
    dependency2: segundoCartao.dependency,
  };
}

export function formatCnpjCpf(value)
{
  const cnpjCpf = value.replace(/\D/g, '');
  
  if (cnpjCpf.length === 11) {
    return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
  } 
  
  return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
}
