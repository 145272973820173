import {
  SIGN_IN,
  SIGN_OUT,
  EMAIL_REGISTER,
  FETCH_USER,
} from '../actions/types';
// node-modules
import _ from 'lodash';
// utils
import { dateTransform } from '../utils';
// services
import { isAuthenticated, getDataUser, setDataUser } from '../services/user';
import { setToken } from '../services/cart';

// state padrão para inicio de interação com o formulário
const INITIAL_STATE = {
  isAuthenticated: false,
  user: {
    fk_tipo_usuario: '2',
    cpf_cnpj_usuario: '',
    dta_nasc_usuario: '',
    nome_usuario: '',
    nr_fone: '',
    nr_celular: '',
    email_usuario: '',
    senha_usuario: '',
  },
};

// state que estava quando o usuário acabou de entrar no site
const VERIFY_INITIAL_STATE = {
  isAuthenticated: isAuthenticated(),
  user: isAuthenticated() ? getDataUser() : INITIAL_STATE.user,
};

export default (state = VERIFY_INITIAL_STATE, action) => {
  let user = {};
  switch (action.type) {
    case SIGN_IN:
      user = action.payload.user_data;
      if (action.payload.carrinho) {
        setToken(action.payload.carrinho.token_carrinho);
      }
      user = {
        ...user,
        dta_nasc_usuario: dateTransform(
          action.payload.user_data.dta_nasc_usuario
        ),
      };
      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    case SIGN_OUT:
      return { ...state, ...INITIAL_STATE };
    case EMAIL_REGISTER:
      return {
        ...state,
        user: { ...state.user, email_usuario: action.payload },
      };
    case FETCH_USER:
      if (!action.payload.data) {
        return INITIAL_STATE;
      }
      user = _.omit(action.payload.data, 'carrinho');
      setDataUser(user);
      if (action.payload.data.carrinho) {
        setToken(action.payload.data.carrinho.token_carrinho);
      }
      user = {
        ...user,
        dta_nasc_usuario: dateTransform(action.payload.data.dta_nasc_usuario),
      };
      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    default:
      return state;
  }
};
