import {
  COOKIES_SUCCESS,
  FETCH_PRIVACY_USER,
  CLEAR_PRIVACY_USER,
  UPDATE_PRIVACY_USER,
} from '../actions/types';

export default (state = {}, action) => {
  switch (action.type) {
    case COOKIES_SUCCESS:
      return {
        ...state,
        cookiesAction: action.payload.data,
      };
    case FETCH_PRIVACY_USER:
      return {
        ...state,
        listPrivacy: action.payload.data,
      };
    case CLEAR_PRIVACY_USER:
      return {
        ...state,
        listPrivacy: null,
      };
    case UPDATE_PRIVACY_USER:
      return {
        ...state,
        updatePrivacy: action.payload.data,
      };
    default:
      return state;
  }
};
