import React from 'react';

// styles
import { Row, Section, TextAlign } from '../../styles/align';
import { TitleCategory } from '../../styles/titles';


const GroupCards = Component => {
  const GroupCards = ({ title, desc, data, base, otherPath }) => (
    <Section>
      {title ? (
        <>
        <TitleCategory>
          {/* Estilos de natal: */}
            <span>  
            {title}
           
          </span> 
        </TitleCategory>
        </>
       
      ) : (
        ''
      )}

      {desc ? (
        <Row>
          <TextAlign textalign={'center'}>
            {desc.map((description, index) => {
              return <p key={index}>{description}</p>;
            })}
          </TextAlign>
        </Row>
      ) : (
        ''
      )}
      <Row>
        <Component data={data} base={base} otherPath={otherPath} />
      </Row>
    </Section>
  );

  return GroupCards;
};

export default GroupCards;
