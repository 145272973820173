import React from 'react';

// styles
import { Col } from '../../styles/align';

const BankSlip = () => {
    return (
      <Col className={'boleto'}>
        <div className={'bkn-bank'}>
          <p>
            (Liberação até 3 dias úteis)
          </p>
          <p>
            Pagável em qualquer agência.
          </p>
          <p>
            <strong>Não é necessário enviar comprovante de pagamento.</strong>
          </p>
        </div>
      </Col>
    );
  };

  export default BankSlip;
