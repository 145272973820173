import React from 'react';
import {
  BrowserRouter,
  Route as RouterRoute,
  Switch,
  Redirect,
} from 'react-router-dom';
import { redirect } from './config';

// node-modules
import { ToastContainer, toast } from 'react-toastify';

// services
import { isAuthenticated } from '../services/user';

// helpers
import ScrollToTop from '../helpers/ScrollToTop';
import InfoSimulation from '../helpers/InfoSimulation';

// pages
import Home from '../pages/Home';
import Category from '../pages/Category';
import Filter from '../pages/Filter';
import Files from '../pages/Files';
import Cart from '../pages/Cart';
import Success from '../pages/Success';
import Orders from '../pages/Orders';
import Order from '../pages/Order';
import Message from '../pages/Message';
import Delivery from '../pages/Delivery';
import Payment from '../pages/Payment';
import Contact from '../pages/Contact';
import RedirectEditor from '../pages/Redirect';
import SimulateClient from '../pages/SimulateClient';
import Search from '../pages/Search';
import CalcLombada from '../pages/CalcLombada';
import Credits from '../pages/Credits';
import BlockedAccount from '../pages/BlockedAccount';
import LandingPage from '../pages/LandingPage/Home';
import FiveOff from '../pages/LandingPage/FiveOff';
import TenOff from '../pages/LandingPage/TenOff';
import Registration from '../pages/LandingPage/Registration';

// components
import NotFound from '../components/NotFound';
import Header from '../components/Header';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import LinkedinInsightTag from '../components/LinkedinInsightTag';
import Newsletter from '../components/Newsletter';
import Modals from '../modals';
import Seo from '../helpers/Seo';
import Cookie from '../components/Cookie';
import { WhatsApp } from '../components/WhatsApp'

//options
import { ogSeo } from '../pages/Home/option';
import { LANDING_PAGE } from '../pages/LandingPage/option';

import { checkStorage } from '../services/user';

const Route = ({ component: Component, ...rest }) => (
  <RouterRoute
    {...rest}
    render={props =>
      redirect[rest.location.pathname] ? (
        <Redirect to={{ pathname: redirect[rest.location.pathname] }} />
      ) : (
        <Component {...props} />
      )
    }
  />
);

// as rotas privadas só serão acessadas caso o usuário esteja logado
// vide readme para mais informações
const PrivateRoute = ({ component: Component, ...rest }) => (
  <RouterRoute
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        // <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        <Redirect to={{ pathname: '/' }} />
      )
    }
  />
);

/**
 * o switch funciona de cima para baixo, respondendo na primeira instancia e termina
 * a execução, portanto respeite a ordem de colocar no topo as rotas exatas
 * e as com parametros pra baixo
 * IMPORTANTE
 * Ao adicionar uma rota privada, favor adicionar no ./routes/config
 */

const Routes = () => (
  <BrowserRouter>
    <Seo props={ogSeo} />
    {!checkStorage() && <Cookie />}
    <Header />
    <Menu />
    <InfoSimulation />
    <ScrollToTop>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/entrega" component={Delivery} />
        <Route exact path="/formas-de-pagamento" component={Payment} />
        <Route exact path="/envio" component={Files} />
        <Route exact path="/contato" component={Contact} />
        <Route exact path="/calculo-de-lombada/:tipo_papel?/:total_pag?/:is_capa_dura?" component={CalcLombada} />
        <Route
          exact
          path="/simular/:token/:usuario/:operador"
          component={SimulateClient}
        />
        <Route exact path={LANDING_PAGE.home} component={LandingPage} />
        <Route exact path={LANDING_PAGE.five} component={FiveOff} />
        <Route exact path={LANDING_PAGE.registration} component={Registration} />
        <Route exact path={LANDING_PAGE.ten} component={TenOff} />
        <PrivateRoute exact path="/sucesso/:pk_pedido" component={Success} />
        <PrivateRoute exact path="/carrinho" component={Cart} />
        <PrivateRoute exact path="/pedidos" component={Orders} />
        <PrivateRoute exact path="/credito" component={Credits} />
        <PrivateRoute exact path="/pedido/:pk_pedido" component={Order} />
        <PrivateRoute exact path="/mensagem" component={Message} />
        <PrivateRoute exact path="/desativado" component={BlockedAccount} />
        <PrivateRoute
          exact
          path="/cadastro/sucesso"
          component={() => {
            // regra feita para captura de novos cadastros.
            // addUser => modals/register

            const addUser = localStorage.getItem('addUser');

            if (addUser) {
              localStorage.removeItem('addUser');
              return <Home />;
            } else {
              return (window.location.href = '/');
            }
          }}
        />
        <Route exact path="/pesquisa" component={Search} />
        <Route exact path="/retorno/:hash_job" component={RedirectEditor} />
        <Route exact path="/:menu" component={Category} />
        <Route exact path="/:menu/:pk_produto/" component={Filter} />
        <Route path="*" component={NotFound} />
      </Switch>
    </ScrollToTop>{' '}
    <Newsletter />
    <Footer />
    <LinkedinInsightTag />
    <WhatsApp />
    <Modals />
    <ToastContainer
      closeButton={false}
      hideProgressBar={true}
      position={toast.POSITION.TOP_CENTER}
    />
  </BrowserRouter>
);

export { Routes, PrivateRoute };
