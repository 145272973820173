/**
 * Arquivo que define as mascaras com regex
 */

// utils
import { onlyNumbers } from '../utils';

/**
 * Exporta a variação de mascaras entre CPF e CNPJ
 */
export const maskCpfCnpj = props => {
  const propsNumbers = onlyNumbers(props);
  return propsNumbers.length > 11 ? maskCnpj : maskCpf;
};

/**
 * Mascara CPF
 */
export const maskCpf = [
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

/**
 * Mascara CPF
 */
export const maskCnpj = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

/**
 * Mascara DATA
 */
export const maskDate = [
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

/**
 * Mascara TELEFONE
 */
export const maskPhone = [
  '(',
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

/**
 * Mascara Celular
 */
export const maskCelPhone = props => {
  const value = props.replace(/[^\d]+/g, '');
  const lenght = value.split('').length;

  if (lenght < 11) {
    return [
      '(',
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  } else {
    return [
      '(',
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  }
};

/**
 * Mascara CEP
 */

export const cep = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
