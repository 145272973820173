import React, { Component } from 'react';
import { connect } from 'react-redux';
// Node
import { toast } from 'react-toastify';
import { Field, Form, reduxForm } from 'redux-form';

// actions
import {
  clearAddress,
  clearCities,
  fetchAddresses,
  fetchCities,
} from '../../actions/address';
import { fetchDeliveryOptions } from '../../actions/cart';
import { modalRegisterAddress } from '../../actions/modals';
// components
import LoaderForm from '../../components/Loader/loaderForm';
// Validate
import { lastname, razaoSocial, required } from '../../formValidate/';
import { mapErrors } from '../../formValidate/handleErrors';
import { cep, maskCpfCnpj } from '../../formValidate/masks';
import renderField from '../../formValidate/renderField';
// hocs
import Modal from '../../hocs/Modal';
// services
import {
  buscarCep,
  createAddress,
  updateAddress,
} from '../../services/address';
import { getToken } from '../../services/cart';
import { checkCpfCnpj } from '../../services/user';
// styles
import { Row } from '../../styles/align';
import { BtnGroup, Button, Label, Wrapper } from '../../styles/forms';

// utils
import { formatCnpjCpf, objValuesToString, onlyNumbers } from '../../utils';
import {
  ADDRESS_CREATE_SUCCESS,
  CONNECTION_ERROR,
  ERROR,
  UPDATE_SUCCESS,
} from '../../utils/constMessage';
import {
  RegistaAddressContainer,
  RegisterAddressForm,
  WrapperCheck,
  ErrorGetCnpjAddress,
  InfoGetCnpjAddress,
} from './style';

const addressData = [
  {
    apiField: 'nome_usuario',
    formField: 'razao_social_endereco_usuario',
    isDisabled: false,
  },
  {
    apiField: 'endereco',
    formField: 'logradouro_usuario_endereco',
    isDisabled: false,
  },
  {
    apiField: 'numero',
    formField: 'nr_usuario_endereco',
    isDisabled: false,
  },
  {
    apiField: 'complemento',
    formField: 'complemento_usuario_endereco',
    isDisabled: false,
  },
  {
    apiField: 'bairro',
    formField: 'bairro_usuario_endereco',
    isDisabled: false,
  },
  {
    apiField: 'fk_cidade',
    formField: 'fk_cidade',
    isDisabled: false,
  },
  {
    apiField: 'cep',
    formField: 'cep_usuario_endereco',
    isDisabled: false,
  },
  {
    apiField: 'fk_estado',
    formField: 'fk_estado',
    isDisabled: false,
  },
];

class RegisterAddress extends Component {
  state = {
    initialDisabled: {
      razao_social_endereco_usuario: false,
      logradouro_usuario_endereco: true,
      bairro_usuario_endereco: true,
      fk_cidade: true,
      fk_estado: true,
    },
    disabled: {
      cpf_cnpj_usuario: false,
      razao_social_endereco_usuario: false,
      logradouro_usuario_endereco: true,
      bairro_usuario_endereco: true,
      fk_cidade: true,
      fk_estado: true,
    },
    errors: {
      desc_usuario_endereco: '',
      cpf_cnpj_usuario: '',
      razao_social_endereco_usuario: '',
      contato_usuario_endereco: '',
      cep_usuario_endereco: '',
      nr_usuario_endereco: '',
      complemento_usuario_endereco: '',
    },
    loadingCep: false,
    loadingCpfCnpj: false,
    loadingCities: false,
    errorGetCnpjAddress: false,
    shouldRegisterBillingAddress:
      !this.props.hasBillingAddress &&
      this.props.isCompany &&
      !this.props.selectedAddress.pk_usuario_endereco,
  };

  componentDidMount() {
    if (this.props.initialValues.fk_estado !== '') {
      this.props.fetchCities(this.props.initialValues.fk_estado);
    }
    console.log(this.state);
    console.log(this.props);
    // Se for PJ && sem endereço de faturamento cadastrado
    if (this.state.shouldRegisterBillingAddress) {
      const formattedCnpj = formatCnpjCpf(this.props.cpfOrCnpj);
      this.onBlurCpfCnpj(formattedCnpj);
      this.props.change('cpf_cnpj_usuario', formattedCnpj);
    }
  }

  componentWillUnmount() {
    this.props.clearAddress();
    this.props.clearCities();
    this.props.fetchAddresses();
  }

  // trata os erros pra mostrar no input
  handleErrors = (response, customMessage) => {
    if (!response) {
      toast.error(customMessage);
      return;
    }

    if (response.errosValidacao) {
      const errors = mapErrors(response.errosValidacao);
      this.setState({ errors });
      return;
    }

    if (response.message) {
      toast.warn(response.message);
    } else {
      toast.error(ERROR);
    }
  };

  // limpa os erros apos selecionar o input
  handleClearErrors = name => {
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        [name]: '',
      },
    }));
  };

  updateState = (key, value) => {
    this.setState(prevState => ({
      disabled: {
        ...prevState.disabled,
        [key]: value,
      },
      errors: {
        ...prevState.errors,
      },
    }));
  };

  // valida o cpf e busca o nome na receita
  onBlurCpfCnpj = value => {
    this.setState(prevState => ({ ...prevState, errorGetCnpjAddress: false }));
    const cpfOnlyNumbers = onlyNumbers(value);
    let fk_tipo_usuario;

    if (cpfOnlyNumbers.length === 11) {
      fk_tipo_usuario = 2;
    } else if (cpfOnlyNumbers.length === 14) {
      fk_tipo_usuario = 1;
    }

    if (fk_tipo_usuario) {
      this.setState({
        loadingCpfCnpj: true,
        disabled: {
          cpf_cnpj_usuario: true,
        },
      });

      checkCpfCnpj(cpfOnlyNumbers, '', fk_tipo_usuario).then(response => {
        if (response.status === 200) {
          addressData.forEach((data, index) => {
            if (response.data.data[data.apiField]) {
              this.props.change(
                data.formField,
                response.data.data[data.apiField]
              );
              this.setState(prevState => ({
                ...prevState,
                disabled: {
                  ...prevState.disabled,
                  [data.formField]: true,
                },
              }));
              data.isDisabled = true;
            }
          });
          const { fk_estado, fk_cidade } = response.data.data;

          if (fk_estado) {
            this.props.fetchCities(fk_estado).then(() => {
              this.props.change('fk_cidade', fk_cidade);
            });
          }
        } else if (response.errors) {
          // console.log(response.errors.errosValidacao.cpf_cnpj_usuario[0],'errors CPF CNPJ');
          this.handleErrors(null, 'CPF/CNPJ inválidos');
        } else {
          toast.error(CONNECTION_ERROR);
          // console.log(response.data.data);
        }
        // Se for PJ &&
        if (fk_tipo_usuario == 1 && this.state.shouldRegisterBillingAddress) {
          // Se não tiver sucesso ao buscar "endereco" do CNPJ no SEFAZ
          // Dúvidas, favor conferir a tarefa https://app.clickup.com/t/86a3v4zgk
          if (response.status !== 200 || !response.data.data.endereco) {
            this.setState(prevState => ({
              ...prevState,
              errorGetCnpjAddress: true,
            }));
          }
        }

        this.setState(prevState => ({
          ...prevState,
          errors: {
            ...prevState.errors,
          },
          loadingCpfCnpj: false,
        }));
      });
    }
  };

  handleCepSuccess = async data => {
    const { change, clearCities, fetchCities } = this.props;
    const { logradouro, bairro, fk_estado, pk_cidade } = data;

    const updateAddress = (addressField, addressData) => {
      change(addressField, addressData || '');
      this.updateState(addressField, !!addressData);
    };

    updateAddress('logradouro_usuario_endereco', logradouro);
    updateAddress('bairro_usuario_endereco', bairro);

    if (!fk_estado) {
      this.updateState('fk_estado', false);
      this.updateState('fk_cidade', false);
      change('fk_estado', 0);
      clearCities();
    } else {
      change('fk_estado', fk_estado);
      await fetchCities(fk_estado).then(() => {
        change('fk_cidade', pk_cidade);
      });
    }
  };

  onBlurCep = async e => {
    this.setState({ loadingCep: true });

    const cepOnlyNumbers = onlyNumbers(e.target.value);
    if (cepOnlyNumbers.length !== 8) {
      return;
    }

    try {
      const response = await buscarCep(cepOnlyNumbers);

      this.setState({
        disabled: { ...this.state.disabled, ...this.state.initialDisabled },
      });

      if (response.status === 200) {
        if (response.data.data.status === 'failed') {
          this.handleErrors(null, 'CEP inválido');
        } else {
          await this.handleCepSuccess(response.data.data);
        }
      } else if (response.errors) {
        this.handleErrors(response.errors);
      } else {
        toast.error(CONNECTION_ERROR);
      }
    } catch (error) {
      this.handleErrors(error, 'Erro ao buscar CEP');
    } finally {
      this.setState({ loadingCep: false });
    }
  };

  onBlurState(e) {
    this.setState({
      loadingCities: true,
    });
    // caso selecione a opção "0" limpa o select das cidades
    if (e.target.value === '0') {
      this.props.clearCities();
      this.props.change('fk_cidade', '0');
    } else {
      this.props.fetchCities(e.target.value).then(() => {
        this.setState({
          loadingCities: false,
        });
      });
    }
  }

  renderStates() {
    if (!this.props.states) {
      return '';
    }
    return (
      <Wrapper>
        <Label htmlFor={'fk_estado'}>
          Estado<strong>*</strong>
        </Label>
        <Field
          name="fk_estado"
          id={'fk_estado'}
          typefield={'select'}
          component={renderField}
          disabled={this.state.disabled.fk_estado}
          onChange={e => this.onBlurState(e)}
          validate={required}
          firstOption={
            <option key={0} value="0">
              Selecione um Estado
            </option>
          }
          options={Object.values(this.props.states).map(state => {
            return (
              <option key={state.pk_estado} value={state.pk_estado}>
                {state.desc_estado}
              </option>
            );
          })}
          loading={this.state.loadingCep}
        />
      </Wrapper>
    );
  }

  renderCities() {
    return (
      <Wrapper>
        <Label htmlFor={'fk_cidade'}>
          Cidade<strong>*</strong>
        </Label>
        {this.props.cities ? (
          <Field
            name="fk_cidade"
            id={''}
            typefield={'select'}
            component={renderField}
            validate={required}
            disabled={
              this.state.disabled.fk_cidade || !this.props.hasBillingAddress
            }
            firstOption={
              <option key={0} value="0">
                Selecione uma Cidade
              </option>
            }
            options={Object.values(this.props.cities).map(city => {
              return (
                <option key={city.pk_cidade} value={city.pk_cidade}>
                  {city.desc_cidade}
                </option>
              );
            })}
            loading={this.state.loadingCep || this.state.loadingCities}
          />
        ) : (
          <Field
            name="fk_cidade"
            typefield={'select'}
            component={renderField}
            disabled={
              this.state.disabled.fk_cidade || !this.props.hasBillingAddress
            }
            options={<option value="0">Selecione uma Cidade</option>}
          />
        )}
      </Wrapper>
    );
  }
  //Detecta a tecla enter caso o usuário tecle ela ela fica sem ação ticket 26836
  handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  renderDefaultAddress() {
    if (!this.props.addresses) {
      return '';
    }

    if (this.props.initialValues.default_usuario_endereco === 'true') {
      return '';
    }

    if (!this.props.addresses.length) {
      return '';
    }

    return (
      <WrapperCheck>
        <Field
          name={'default_usuario_endereco'}
          id={'default_usuario_endereco'}
          type={'checkbox'}
          typefield={'checkbox'}
          component={renderField}
        />
        <Label htmlFor={'default_usuario_endereco'}>Salvar como padrão</Label>
      </WrapperCheck>
    );
  }

  renderForm() {
    const { submitting, registerAddress } = this.props;
    let cnpj = '';
    if (
      registerAddress &&
      registerAddress.values &&
      registerAddress.values.cpf_cnpj_usuario
    ) {
      cnpj = registerAddress.values.cpf_cnpj_usuario;
    }
    const isCompanyWithoutBillingAddress = this.state
      .shouldRegisterBillingAddress;

    return (
      <RegistaAddressContainer>
        {this.state.shouldRegisterBillingAddress ? (
          <InfoGetCnpjAddress>
            É necessário um endereço de faturamento cadastrado antes de
            prosseguir
          </InfoGetCnpjAddress>
        ) : (
          ''
        )}
        <RegisterAddressForm>
          <Row>
            <Wrapper>
              <Label htmlFor={'desc_usuario_endereco'}>
                Apelido do endereço<strong>*</strong>
              </Label>
              <Field
                name={'desc_usuario_endereco'}
                id={'desc_usuario_endereco'}
                placeholder={'Apelido do endereço'}
                type={'text'}
                typefield={'firstFieldEndereco'}
                component={renderField}
                validate={required}
                responseError={this.state.errors.desc_usuario_endereco}
                onFocus={e => this.handleClearErrors(e.target.name)}
              />
            </Wrapper>
            {this.renderDefaultAddress()}
          </Row>
          <Row>
            <Wrapper>
              <Label htmlFor={'cpf_cnpj_usuario'}>
                CPF/CNPJ<strong>*</strong>
              </Label>
              <Field
                name={'cpf_cnpj_usuario'}
                id={'cpf_cnpj_usuario'}
                placeholder={'Digite o CPF ou CNPJ'}
                type={'text'}
                typefield={'mask'}
                mask={maskCpfCnpj}
                component={renderField}
                disabled={
                  this.state.disabled.cpf_cnpj_usuario ||
                  isCompanyWithoutBillingAddress
                }
                validate={required}
                onBlur={e => this.onBlurCpfCnpj(e.target.value)}
                //responseError={this.state.errors.cpf_cnpj_usuario}
                onFocus={e => this.handleClearErrors(e.target.name)}
              />
            </Wrapper>
            <Wrapper>
              <Label htmlFor={'razao_social_endereco_usuario'}>
                Destinatário<strong>*</strong>
              </Label>
              <Field
                name={'razao_social_endereco_usuario'}
                id={'razao_social_endereco_usuario'}
                placeholder={'Digite o Destinatário'}
                type={'text'}
                typefield={'text'}
                validate={[required, razaoSocial]}
                component={renderField}
                disabled={this.state.disabled.razao_social_endereco_usuario}
                //responseError={this.state.errors.razao_social_endereco_usuario}
                onFocus={e => this.handleClearErrors(e.target.name)}
                loading={this.state.loadingCpfCnpj}
              />
            </Wrapper>
            <Wrapper>
              <Label htmlFor={'contato_usuario_endereco'}>
                Responsável pelo Recebimento<strong>*</strong>
              </Label>
              <Field
                name={'contato_usuario_endereco'}
                id={'contato_usuario_endereco'}
                placeholder={'Responsável pelo Recebimento'}
                type={'text'}
                typefield={'text'}
                component={renderField}
                validate={[required, lastname]}
                responseError={this.state.errors.contato_usuario_endereco}
                onFocus={e => this.handleClearErrors(e.target.name)}
              />
            </Wrapper>
          </Row>
          <Row>
            <Wrapper>
              <Label htmlFor={'cep_usuario_endereco'}>
                CEP<strong>*</strong>
              </Label>
              <Field
                name={'cep_usuario_endereco'}
                id={'cep_usuario_endereco'}
                placeholder={'CEP'}
                type={'text'}
                typefield={'mask'}
                mask={cep}
                component={renderField}
                validate={required}
                responseError={this.state.errors.cep_usuario_endereco}
                onFocus={e => this.handleClearErrors(e.target.name)}
                onBlur={this.onBlurCep}
                disabled={this.state.disabled.cep_usuario_endereco}
                loading={this.state.loadingCpfCnpj}
              />
            </Wrapper>
            <Wrapper>
              <Label htmlFor={'logradouro_usuario_endereco'}>
                Endereço<strong>*</strong>
              </Label>
              <Field
                name={'logradouro_usuario_endereco'}
                id={'logradouro_usuario_endereco'}
                placeholder={'Endereço'}
                type={'text'}
                typefield={'text'}
                component={renderField}
                validate={required}
                disabled={this.state.disabled.logradouro_usuario_endereco}
                loading={this.state.loadingCep || this.state.loadingCpfCnpj}
              />
            </Wrapper>
          </Row>
          <Row>
            <Wrapper>
              <Label htmlFor={'nr_usuario_endereco'}>
                Numero<strong>*</strong>
              </Label>
              <Field
                name={'nr_usuario_endereco'}
                id={'nr_usuario_endereco'}
                placeholder={'Numero'}
                type={'text'}
                typefield={'text'}
                component={renderField}
                validate={required}
                responseError={this.state.errors.nr_usuario_endereco}
                onFocus={e => this.handleClearErrors(e.target.name)}
                disabled={this.state.disabled.nr_usuario_endereco}
                loading={this.state.loadingCep || this.state.loadingCpfCnpj}
              />
            </Wrapper>
            <Wrapper>
              <Label htmlFor={'complemento_usuario_endereco'}>Compl.</Label>
              <Field
                name={'complemento_usuario_endereco'}
                id={'complemento_usuario_endereco'}
                placeholder={'Complemento'}
                type={'text'}
                typefield={'text'}
                component={renderField}
                responseError={this.state.errors.complemento_usuario_endereco}
                onFocus={e => this.handleClearErrors(e.target.name)}
                disabled={this.state.disabled.complemento_usuario_endereco}
                loading={this.state.loadingCep || this.state.loadingCpfCnpj}
              />
            </Wrapper>
            <Wrapper>
              <Label htmlFor={'bairro_usuario_endereco'}>
                Bairro<strong>*</strong>
              </Label>
              <Field
                name={'bairro_usuario_endereco'}
                id={'bairro_usuario_endereco'}
                placeholder={'Bairro'}
                type={'text'}
                typefield={'text'}
                component={renderField}
                validate={required}
                disabled={this.state.disabled.bairro_usuario_endereco}
                loading={this.state.loadingCep || this.state.loadingCpfCnpj}
              />
            </Wrapper>
          </Row>
          <Row padding={'1rem 0'}>
            {this.renderStates()}
            {this.renderCities()}
          </Row>

          <BtnGroup>
            <span className={'required'}>* Campos Obrigatórios</span>
            {this.state.errorGetCnpjAddress && (
              <ErrorGetCnpjAddress>
                A busca dos dados do endereço de seu CNPJ falharam. <br />
                Por favor, escolha alguma das opções abaixo:
              </ErrorGetCnpjAddress>
            )}
            <span>
              {this.state.errorGetCnpjAddress ? (
                <>
                  {' '}
                  <Button type="button">
                    <a
                      href={`http://api.whatsapp.com/send?1=pt_BR&phone=554195340058`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Entrar em contato
                    </a>
                  </Button>
                  <Button
                    type="button"
                    onClick={() => this.onBlurCpfCnpj(cnpj)}
                  >
                    Tentar Novamente
                  </Button>
                </>
              ) : (
                <>
                  <Button onClick={this.props.modalRegisterAddress}>
                    Cancelar
                  </Button>

                  <Button type={'submit'} disabled={submitting} secondary>
                    Salvar{submitting && <LoaderForm />}
                  </Button>
                </>
              )}
            </span>
          </BtnGroup>
        </RegisterAddressForm>
      </RegistaAddressContainer>
    );
  }

  handleClearErrors = name => {
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        [name]: '',
      },
    }));
  };

  submit = async data => {
    if (data.pk_usuario_endereco !== '') {
      await updateAddress(data, this.props.pk_usuario_endereco).then(
        response => {
          if (response.status === 200) {
            this.props.modalRegisterAddress();
            toast.success(UPDATE_SUCCESS);
          } else if (response.errors) {
            this.handleErrors(response.errors);
          } else {
            toast.error(CONNECTION_ERROR);
          }
        }
      );
    } else {
      if (this.state.shouldRegisterBillingAddress) {
        data.faturamento = 1;
      }
      await createAddress(
        data,
        this.props.addresses ? this.props.addresses.length : 0
      ).then(response => {
        if (response.status === 200) {
          if (
            !this.props.addresses ||
            (this.props.addresses !== 'undefined' &&
              !this.props.addresses.length)
          ) {
            if (typeof this.props.callback === 'function') {
              this.props.callback(getToken());
            }
            this.props.fetchDeliveryOptions(getToken());
          }

          this.props.modalRegisterAddress();
          toast.success(ADDRESS_CREATE_SUCCESS);
        } else if (response.errors) {
          this.handleErrors(response.errors);
        } else {
          toast.error(CONNECTION_ERROR);
        }
      });
    }
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <>
        <Form
          onKeyDown={this.handleKeyPress}
          onSubmit={handleSubmit(this.submit)}
        >
          {this.renderForm()}
        </Form>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    registerAddress: state.form.registerAddress,
    states: state.address.states,
    cities: state.address.cities,
    addresses: state.address.listAddress,
    initialValues: objValuesToString(state.address.address),
    callback: state.modals.modalRegisterAddress.callback,
    hasBillingAddress: state.address.address.has_billing_address,
    isCompany: state.user.user.fk_tipo_usuario === 1,
    cpfOrCnpj: state.user.user.cpf_cnpj_usuario,
    user: state.user.user,
    selectedAddress: state.address.address,
  };
};

RegisterAddress = reduxForm({
  form: 'registerAddress',
})(RegisterAddress);

RegisterAddress = connect(mapStateToProps, {
  modalRegisterAddress,
  fetchCities,
  clearCities,
  clearAddress,
  fetchAddresses,
  fetchDeliveryOptions,
})(RegisterAddress);

RegisterAddress = Modal(RegisterAddress);

export default RegisterAddress;
