import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import menuReducer from './menuReducer';
import footerReducer from './footerReducer';
import pubsitesReducer from './pubsitesReducer';
import featuredProductsReducer from './featuredProductsReducer';
import productsReducer from './productsReducer';
import userReducer from './userReducer';
import userPrivacy from './userPrivacyReducer';
import orderReducer from './ordersReducer';
import filterReducer from './filterReducer';
import filesReducer from './filesReducer';
import cartReducer from './cartReducer';
import addressReducer from './addressReducer';
import messageReducer from './messageReducer';
import balconyReducer from './balconyReducer';
import modalReducer from './modalReducer';
import paymentReducer from './paymentReducer';
import redirectReducer from './redirectReducer';
import creditsReducer from './creditsReducer';
import searchReducer from './searchReducer';
import landingPageReducer from './landingPageReducer';

export default combineReducers({
  carousel: pubsitesReducer,
  featuredProducts: featuredProductsReducer,
  footer: footerReducer,
  menu: menuReducer,
  products: productsReducer,
  form: formReducer,
  user: userReducer,
  userPrivacy: userPrivacy,
  filter: filterReducer,
  orders: orderReducer,
  files: filesReducer,
  cart: cartReducer,
  address: addressReducer,
  messages: messageReducer,
  balcony: balconyReducer,
  modals: modalReducer,
  payment: paymentReducer,
  redirect: redirectReducer,
  credits: creditsReducer,
  search: searchReducer,
  landingPage: landingPageReducer,
});
